import { action, computed, observable, runInAction } from "mobx";

import Store from "./Store";
import { AuditLogsModel } from "../models/AuditLogsModel";
import { PaginatedResponse } from "../responses/paginated.response";
import { auditLogsService } from "../services/AuditLogsService";

export class AuditLogsStore extends Store<AuditLogsModel> {
  private static _instance: AuditLogsStore;

  @observable isLoading = false;
  @observable isLoaded = false;
  @observable hasMore = true;
  @observable current_page = 0;

  constructor() {
    super();
    AuditLogsModel._store = this;
  }

  @computed
  get auditLogs(): AuditLogsModel[] {
    return [...this.entities];
  }
  @action
  fetchOrganizationAuditLogs(organization_id: number) {
    this.isLoading = true;
    this.current_page = this.current_page + 1;
    auditLogsService
      .getAll(organization_id, { page: this.current_page })
      .then((response: PaginatedResponse<AuditLogsModel>) => {
        runInAction(() => {
          this.hasMore = !(
            !response.data.length ||
            response.data.length < response.meta.page_size
          );
          this.current_page = response.meta.current_page;
          response.data.map((organization) =>
            AuditLogsModel.fromJson(organization),
          );
        });
        this.isLoading = false;
        this.isLoaded = true;
        return;
      });
  }

  @action
  clearItems() {
    runInAction(() => {
      this.isLoading = false;
      this.isLoaded = false;
      this.hasMore = true;
      this.current_page = 0;
      this.entities = [];
    });
    console.log("itr", this.entities, this.isLoaded);
  }

  static getInstance(): AuditLogsStore {
    if (!this._instance) {
      this._instance = new AuditLogsStore();
    }

    return this._instance;
  }
}
