import { ListResponse } from "../responses/list.response";
import { ShowResponse } from "../responses/show.response";
import { apiService } from "./ApiService";
import { TasksFormData, TasksModel } from "../models/TasksModel";

class TasksService {
  static getInstance(): TasksService {
    return new TasksService();
  }

  getAll(): Promise<TasksModel[]> {
    return apiService
      .get<ListResponse<TasksModel>>("/tasks", true, true)
      .then((response) => response.data);
  }

  updateBulk(data: Array<TasksFormData>): Promise<TasksModel[]> {
    return apiService
      .put<ListResponse<TasksModel>>(`/task/bulk-update`, true, false, data)
      .then((res) => res.data);
  }

  update(id: number, data: TasksFormData): Promise<TasksModel> {
    return apiService
      .put<ShowResponse<TasksModel>>(`/task/${id}`, true, false, data)
      .then((res) => res.data);
  }
}

export const tasksService = TasksService.getInstance();
