import { apiService } from "./ApiService";
import { ListResponse } from "../responses/list.response";
import { AdminOrganizationReport } from "../models/AdminOrganizationReport";
import { AdminUserReport } from "../models/AdminUserReport";
import {
  AdminCompanyReport,
  UserAssessmentData,
} from "../models/AdminCompanyReport";
import { ShowResponse } from "../responses/show.response";
import { AdminCumulativeReport } from "../models/AdminCumulativeReport";

class AdminReportService {
  static getInstance(): AdminReportService {
    return new AdminReportService();
  }

  fetchMonthlyUserReport(params: {
    [key: string]: any;
  }): Promise<AdminUserReport[]> {
    return apiService
      .get<ListResponse<AdminUserReport>>(
        "/admin/user-report",
        true,
        false,
        params,
      )
      .then((res) => res.data);
  }

  fetchOrganizationReport(): Promise<AdminOrganizationReport[]> {
    return apiService
      .get<ListResponse<AdminOrganizationReport>>(
        "/admin/organization-report",
        true,
        false,
      )
      .then((res) => res.data);
  }

  fetchCompanyReport(): Promise<AdminCompanyReport> {
    return apiService
      .get<ShowResponse<AdminCompanyReport>>(
        "/admin/company-report",
        true,
        false,
      )
      .then((res) => res.data);
  }

  getAssessmentReport(data?: {
    from_date: string;
    to_date: string;
  }): Promise<UserAssessmentData[]> {
    return apiService
      .get<ListResponse<UserAssessmentData>>(
        "/admin/assessment-report",
        true,
        false,
        data,
      )
      .then((res) => res.data);
  }

  fetchCumulativeReport(params: {
    [key: string]: any;
  }): Promise<AdminCumulativeReport[]> {
    return apiService
      .get<ListResponse<AdminCumulativeReport>>(
        "/admin/cumulative-report",
        true,
        false,
        params,
      )
      .then((res) => res.data);
  }
}

export const adminReportService = AdminReportService.getInstance();
