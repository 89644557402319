import { observable } from "mobx";
import Model from "./Model";
import { SurveyStore } from "../stores/SurveyStore";
import moment from "moment";
import { User } from "./UserModel";
import { ScoreRange } from "./EmterpriseReportModel";
import { DimensionLanguages } from "../enums/languages.enum";

export class Survey extends Model {
  static _store: SurveyStore;

  @observable id!: number;
  @observable user_id!: number;
  @observable is_completed!: boolean;
  @observable createdAt!: string;
  @observable updatedAt!: string;
  @observable dimension_wise_score!: any;
  @observable range!: ScoreRange;
  @observable completed_on!: string;
  @observable report_url!: {
    [name in DimensionLanguages]: string;
  };
  @observable fullReportUrl!: {
    [name in DimensionLanguages]: string;
  };
  @observable sessions!: number;
  @observable completion_time!: number;
  @observable is_lite!: boolean;
  @observable user!: User;

  getId(): string | number {
    return this.id;
  }

  get readableUpdatedAt() {
    return moment(this.updatedAt).format("MM/DD/YYYY");
  }
}
