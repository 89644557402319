import { action, computed, observable, runInAction } from "mobx";
import Store from "./Store";
import { apiService } from "../services/ApiService";
import {
  PromotionalImagesFormData,
  PromotionalImagesModel,
} from "../models/PromotionalImagesModel";
import { promotionalImagesService } from "../services/PromotionalImagesService";
import moment from "moment";
import { RecentSurveyCompletedActivityModel } from "../models/RecentSurveyCompletedActivityModel";
import { recentSurveyActivityService } from "../services/RecentSurveyCompletedActivityService";

export class RecentSurveyCompletedActivityStore extends Store<RecentSurveyCompletedActivityModel> {
  private static _instance: RecentSurveyCompletedActivityStore;

  @observable isLoading = false;
  @observable isLoaded = false;

  constructor() {
    super();
    RecentSurveyCompletedActivityModel._store = this;
  }

  @computed
  get recentSurveyCompletedActivity(): RecentSurveyCompletedActivityModel[] {
    return [...this.entities];
  }

  static getInstance(): RecentSurveyCompletedActivityStore {
    if (!this._instance) {
      this._instance = new RecentSurveyCompletedActivityStore();
    }

    return this._instance;
  }

  @action
  fetchRecentSurveyActivity() {
    this.isLoading = true;
    recentSurveyActivityService
      .getAll()
      .then((recentActivities: RecentSurveyCompletedActivityModel[]) => {
        console.log("recent", recentActivities);
        runInAction(() => {
          recentActivities.map((recentActivity) =>
            RecentSurveyCompletedActivityModel.fromJson(recentActivity),
          );

          this.isLoaded = true;
        });
      })
      .catch((err) => console.log(err))
      .finally(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
  }
}
