import { apiService } from "./ApiService";
import { Filters } from "../models/Filter";
import { PaginatedResponse } from "../responses/paginated.response";
import { AuditLogsModel } from "../models/AuditLogsModel";

class AuditLogsService {
  static getInstance(): AuditLogsService {
    return new AuditLogsService();
  }

  getAll(
    organization_id: number,
    filters?: Filters,
  ): Promise<PaginatedResponse<AuditLogsModel>> {
    return apiService
      .get<PaginatedResponse<AuditLogsModel>>(
        `/organization/${organization_id}/logs`,
        true,
        true,
        filters,
      )
      .then((response) => response);
  }
}

export const auditLogsService = AuditLogsService.getInstance();
