import Model from "./Model";
import { observable } from "mobx";
import { PromotionalScreens } from "../enums/promotional-screens.enum";
import { MilestoneStore } from "../stores/MilestoneStore";

export class MilestoneModel extends Model {
  static _store: MilestoneStore;

  @observable id!: number;
  @observable title!: string;
  @observable description!: string;
  @observable image_url!: string;
  @observable type!: PromotionalScreens;
  @observable display_order!: number;

  getId(): string | number {
    return this.id;
  }
}

export interface MilestoneFormData {
  title: string;
  description: string;
  image_url: string;
}
