import { Visibility } from "../enums/visibility.enum";

export interface EnterpriseReport {
  score: number;
  negative: number;
  positive: number;
  neutral: number;
  range: ScoreRange;
  upperLimit: number;
  lowerLimit: number;
  lowRangeUsers?: UsersData;
  mediumRangeUsers?: UsersData;
  highRangeUsers?: UsersData;
}
export type UsersData = {
  user_id: number;
  score: number;
  range: ScoreRange;
  firstName: string;
  lastName: string;
  visiblity: Visibility;
  profilePic: string;
}[];
export enum ScoreRange {
  LOW = "low",
  MEDIUM = "medium",
  HIGH = "high",
}
