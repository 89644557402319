import { action, computed, observable, runInAction } from "mobx";
import Store from "./Store";
import {
  PromotionalImagesFormData,
  PromotionalImagesModel,
} from "../models/PromotionalImagesModel";
import { promotionalImagesService } from "../services/PromotionalImagesService";
import { EventFormData, EventModel } from "../models/EventModel";
import { eventsService } from "../services/EventsService";

export class EventsStore extends Store<EventModel> {
  private static _instance: EventsStore;

  @observable isLoading = false;
  @observable isLoaded = false;

  constructor() {
    super();
    EventModel._store = this;
  }

  @computed
  get events(): EventModel[] {
    return [...this.entities];
  }

  static getInstance(): EventsStore {
    if (!this._instance) {
      this._instance = new EventsStore();
    }

    return this._instance;
  }

  @action
  fetchEvents() {
    this.isLoading = true;
    eventsService
      .getAll()
      .then((events: EventModel[]) => {
        runInAction(() => {
          events.map((event) => EventModel.fromJson(event));
          this.isLoaded = true;
        });
      })
      .catch((err) => console.log(err))
      .finally(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
  }

  @action
  deleteEvent(id: number) {
    this.isLoading = true;
    return eventsService
      .delete(id)
      .then(() => {
        runInAction(() => {
          this.remove(id);
        });
      })
      .catch((err) => console.log(err))
      .finally(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
  }

  @action
  async addEvent(data: EventFormData) {
    this.isLoading = true;
    try {
      const response = await eventsService.add({
        ...data,
      });
      runInAction(() => {
        EventModel.fromJson(response);
      });

      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
      throw e;
    }
  }

  @action
  async joinEvent(event_id: number) {
    const response = await eventsService.joinEvent(event_id);
    runInAction(() => {
      EventModel.fromJson(response);
    });
  }

  @action
  async updateEvent(id: number, data: EventFormData) {
    try {
      this.isLoading = true;

      const response = await eventsService.update(id, data);
      runInAction(() => {
        EventModel.fromJson(response);
      });
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
      throw e;
    }
  }
}
