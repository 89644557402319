import { Languages } from "../enums/languages.enum";

export class CommonLanguagesContent {
  static commonContent = {
    [Languages.ENGLISH]: {
      password: "Password",
      email_name_placeholder: "Enter your email address",
      email: "Email address",
      forgotPassword: "Forgot Password?",
      terms: "Terms and conditions",
      privacy: "Privacy Policy",
      accountNotPresent: "Don't have an AQai account yet? No problem you can",
      accountAlreadyPresent: "Already have an account?",
      signIn: "Sign in",
      signUp: "purchase & register here",
      first_name: "First Name",
      last_name: "Last Name",
      first_name_placeholder: "Enter your first name",
      last_name_placeholder: "Enter your last name",
      firstNameError: "First name is required",
      lastNameError: "Last name is required",
      cardNumber: "Card number",
      cvc: "CVC",
      continue: "Continue",
      currency: "Currency",
      nameOnCard: "Name on card",
      nameOnCardPlaceHolder: "Enter the name on the card...",
      expDate: "Exp date",
      checkout: "Checkout",
      discountCode: "Code",
      discountCodePlaceholder: "Enter discount code",
      paymentSuccessFull: "Payment successful",
      thankYouForChoosingAqai: "Thank you for choosing AQai!",
      nextButton: "Next",
      notSureProceed: "Not sure if you want to proceed?",
      cancel: "Cancel",
      organizationRequiredError: "Organization is required",
      organizationSizeRequired: "Organization size is required",
      organizationTitle: "Organization Name",
      organizationPlaceholder: "Enter your organization name",
      organizationSizeTitle: "Organization Size",
      noOfAssessmentTitle: "Number of Assessments",
      noOfAssessmentPlaceholder: "Enter number of assessments",
      noOfAssessmentRequiredError: "Number of assessment is required",
      captchaRequiredError: "Please complete the CAPTCHA to proceed.",
      continue_with_google: "Continue with Google",
      continue_with_linkedin: "Continue with LinkedIn",
      sign_up_with_email: "Sign up with email",
    },
    [Languages.VIETNAMESE]: {
      first_name: "Tên",
      first_name_placeholder: "Nhập tên của bạn",
      last_name_placeholder: "Nhập họ và tên đệm của bạn",
      email_name_placeholder: "Nhập địa chỉ email của bạn",
      last_name: "Họ và tên đệm",
      email: "Email",
      forgotPassword: "Quên mật khẩu?",
      password: "Mật khẩu",
      nextButton: "Tiếp theo",
      firstNameError: "Tên là trường bắt buộc",
      lastNameError: "Họ và tên đệm là trường bắt buộc",
      terms: "Điều khoản và điều kiện",
      privacy: "Chính sách bảo mật",
      accountNotPresent:
        "Chưa có tài khoản AQAI? Không có vấn đề gì bạn có thể",
      accountAlreadyPresent: "Đã có tài khoản",
      signIn: "Đăng nhập",
      signUp: "Mua và đăng ký tại đây",
      cardNumber: "Số thẻ",
      cvc: "CVC",
      continue: "Tiếp tục",
      currency: "Tiền tệ",
      nameOnCard: "Tên trên thẻ",
      nameOnCardPlaceHolder: "Nhập tên trên thẻ",
      expDate: "Ngày hết hạn",
      checkout: "Thanh toán",
      discountCode: "Mã",
      discountCodePlaceholder: "Nhập mã giảm giá",
      paymentSuccessFull: "Thanh toán thành công",
      thankYouForChoosingAqai: "Cảm ơn bạn đã chọn AQai!",
      notSureProceed: "Không chắc chắn tiếp tục",
      cancel: "Hủy bỏ",
      organizationRequiredError: "Tổ chức là trường bắt buộc",
      organizationSizeRequired: "Quy mô tổ chức là trường bắt buộc",
      organizationTitle: "Tên tổ chức",
      organizationPlaceholder: "Nhập tên tổ chức nơi bạn làm việc",
      organizationSizeTitle: "Quy mô tổ chức",
      noOfAssessmentTitle: "Số lượng bài đánh giá",
      noOfAssessmentPlaceholder: "Nhập số lượng bài đánh giá",
      noOfAssessmentRequiredError: "Số lượng bài đánh giá là trường bắt buộc",
      captchaRequiredError: "Vui lòng hoàn thành CAPTCHA để tiếp tục.",
      continue_with_google: "Tiếp tục với Google",
      continue_with_linkedin: "Tiếp tục với LinkedIn",
      sign_up_with_email: "Đăng ký với email",
    },
    [Languages.TURKISH]: {
      password: "Parola",
      email_name_placeholder: "E-posta adresinizi giriniz",
      email: "E-posta adresi",
      forgotPassword: "Parolanızı mı unuttunuz?",
      terms: "Şartlar ve koşullar",
      privacy: "Gizlilik Politikası",
      accountNotPresent:
        "Henüz bir AQai hesabınız yok mu? sorun değil yapabilirsin",
      accountAlreadyPresent: "Zaten hesabınız var mı?",
      signIn: "Kayıt olmak",
      signUp: "satın alın ve buradan kaydolun",
      first_name: "İlk adı",
      last_name: "Soyadı",
      first_name_placeholder: "İlk adınızı girin",
      last_name_placeholder: "Soyadınızı giriniz",
      firstNameError: "Ad gerekli",
      lastNameError: "Soyadı gerekli",
      cardNumber: "Kart numarası",
      cvc: "CVC",
      continue: "Devam et",
      currency: "Para birimi",
      nameOnCard: "Karttaki isim",
      nameOnCardPlaceHolder: "Karttaki adı girin...",
      expDate: "Son kullanma tarihi",
      checkout: "Ödeme",
      discountCode: "kod",
      discountCodePlaceholder: "indirim kodunu girin",
      paymentSuccessFull: "Ödeme başarılı",
      thankYouForChoosingAqai: "AQai'yi seçtiğiniz için teşekkür ederiz!",
      nextButton: "Sonraki",
      notSureProceed:
        "Devam etmek isteyip istemediğinizden emin değil misiniz?",
      cancel: "İptal",
      organizationRequiredError: "Organizasyon gerekli",
      organizationSizeRequired: "Kuruluş boyutu gerekli",
      organizationTitle: "Kuruluş Adı",
      organizationPlaceholder: "Kuruluşunuzun adını girin",
      organizationSizeTitle: "Organizasyon Büyüklüğü",
      noOfAssessmentTitle: "Değerlendirme Sayısı",
      noOfAssessmentPlaceholder: "Değerlendirme sayısını girin",
      noOfAssessmentRequiredError: "Değerlendirme sayısı gerekli",
      captchaRequiredError: "Devam etmek için lütfen CAPTCHA'yı doldurun.",
      continue_with_google: "Google ile devam",
      continue_with_linkedin: "LinkedIn ile devam et",
      sign_up_with_email: "E-postayla kaydolun",
    },

    [Languages.SPANISH]: {
      password: "Contraseña",
      email_name_placeholder: "Ingrese su dirección de correo electrónico",
      email: "Dirección de correo electrónico",
      forgotPassword: "¿Has olvidado tu contraseña?",
      terms: "Términos y condiciones",
      privacy: "Política de privacidad",
      accountNotPresent:
        "¿Aún no tienes una cuenta AQai? No hay problema, puedes",
      accountAlreadyPresent: "¿Ya tienes una cuenta?",
      signIn: "Registrarse",
      signUp: "comprar y registrarte aquí",
      first_name: "Primer nombre",
      first_name_placeholder: "Ponga su primer nombre",
      last_name_placeholder: "Ingresa tu apellido",
      last_name: "Apellido",
      firstNameError: "Se requiere el primer nombre",
      lastNameError: "Se requiere apellido",
      cardNumber: "Número de tarjeta",
      cvc: "CVC",
      continue: "Continuar",
      currency: "Divisa",
      nameOnCard: "Nombre en la tarjeta",
      nameOnCardPlaceHolder: "Ingrese el nombre en la tarjeta...",
      expDate: "Fecha de caducidad",
      checkout: "Checkout",
      discountCode: "Código",
      discountCodePlaceholder: "Introduzca el código de descuento",
      paymentSuccessFull: "Pago exitoso",
      thankYouForChoosingAqai: "¡Gracias por elegir AQai!",
      nextButton: "Siguiente",
      notSureProceed: "¿No estás seguro de si quieres continuar?",
      cancel: "Cancelar",
      organizationRequiredError: "Se requiere organización",
      organizationSizeRequired: "El tamaño de la organización es obligatorio",
      organizationTitle: "Nombre de la organización",
      organizationPlaceholder: "Ingrese el nombre de su organización",
      organizationSizeTitle: "Tamaño de la organización",
      noOfAssessmentTitle: "Número de evaluaciones",
      noOfAssessmentPlaceholder: "Ingrese el número de evaluaciones",
      noOfAssessmentRequiredError: "Se requiere número de evaluación",
      captchaRequiredError: "Complete el CAPTCHA para continuar.",
      continue_with_google: "continuar con Google",
      continue_with_linkedin: "Continuar con LinkedIn",
      sign_up_with_email: "Ingresa con e-mail",
    },
    [Languages.FRENCH]: {
      email_name_placeholder: "Entrez votre adresse email",
      password: "Mot de passe",
      email: "Adresse e-mail",
      forgotPassword: "Mot de passe oublié?",
      terms: "Conditions générales d'utilisation",
      privacy: "Politique de confidentialité",
      accountNotPresent:
        "Pas encore de compte AQai ? Pas de problème, vous pouvez",
      accountAlreadyPresent: "Vous avez déjà un compte?",
      signIn: "S'identifier",
      signUp: "acheter et vous inscrire ici",
      first_name: "Prénom",
      first_name_placeholder: "Entrez votre prénom",
      last_name_placeholder: "Entrez votre nom de famille",
      last_name: "Nom",
      firstNameError: "Le prénom est requis",
      lastNameError: "Le nom de famille est requis",
      cardNumber: "Numéro de carte",
      cvc: "CVC",
      continue: "Continuer",
      currency: "Devise",
      nameOnCard: "Nom sur la carte",
      nameOnCardPlaceHolder: "Entrez le nom sur la carte...",
      expDate: "Date d'expiration",
      checkout: "Checkout",
      discountCode: "Code",
      discountCodePlaceholder: "Entrez le code de réduction",
      paymentSuccessFull: "Paiement réussi",
      thankYouForChoosingAqai: "Merci d'avoir choisi AQai !",
      nextButton: "Suivant",
      notSureProceed: "Vous n'êtes pas sûr de vouloir continuer ?",
      cancel: "Annuler",
      organizationRequiredError: "L'organisation est requise",
      organizationSizeRequired: "La taille de l'organisation est requise",
      organizationTitle: "Nom de l'organisme",
      organizationPlaceholder: "Entrez le nom de votre organisation",
      organizationSizeTitle: "Taille de l'organisation",
      noOfAssessmentTitle: "Nombre d'évaluations",
      noOfAssessmentPlaceholder: "Entrez le nombre d'évaluations",
      noOfAssessmentRequiredError: "Le numéro d'évaluation est requis",
      captchaRequiredError: "Veuillez compléter le CAPTCHA pour continuer.",
      continue_with_google: "Continuer avec Google",
      continue_with_linkedin: "Continuer avec LinkedIn",
      sign_up_with_email: "S'enregistrer avec l'adresse e-mail",
    },
    [Languages.ITALIAN]: {
      email_name_placeholder: "Inserisci il tuo indirizzo email",
      password: "Parola d'ordine",
      email: "Indirizzo email",
      forgotPassword: "Ha dimenticato la password?",
      terms: "Termini e Condizioni",
      privacy: "politica sulla riservatezza",
      accountNotPresent:
        "Non hai ancora un account AQai? Nessun problema, puoi",
      accountAlreadyPresent: "Hai già un account?",
      signIn: "Registrazione",
      signUp: "acquistare e registrarti qui",
      first_name: "Nome",
      first_name_placeholder: "Inserisci il tuo nome di battesimo",
      last_name_placeholder: "Inserire il cognome",
      last_name: "Cognome",
      firstNameError: "Il nome è obbligatorio",
      lastNameError: "Il cognome è obbligatorio",
      cardNumber: "Numero di carta",
      cvc: "CVC",
      continue: "Continua",
      currency: "Moneta",
      nameOnCard: "nome sulla carta",
      nameOnCardPlaceHolder: "Inserisci il nome sulla carta...",
      expDate: "Data di scadenza",
      checkout: "Checkout",
      discountCode: "Codice",
      discountCodePlaceholder: "Inserisci il codice sconto",
      paymentSuccessFull: "Pagamento riuscito",
      thankYouForChoosingAqai: "Grazie per aver scelto AQai!",
      nextButton: "Prossimo",
      notSureProceed: "Vous n'êtes pas sûr de vouloir continuer ?",
      cancel: "Annulla",
      organizationRequiredError: "L'organizzazione è necessaria",
      organizationSizeRequired: "È richiesta la dimensione dell'organizzazione",
      organizationTitle: "Nome dell'Organizzazione",
      organizationPlaceholder: "Inserisci il nome della tua organizzazione",
      organizationSizeTitle: "Dimensione dell'organizzazione",
      noOfAssessmentTitle: "Numero di valutazioni",
      noOfAssessmentPlaceholder: "Inserisci il numero di valutazioni",
      noOfAssessmentRequiredError: "Il numero di valutazione è richiesto",
      captchaRequiredError: "Per favore completa il CAPTCHA per procedere.",
      continue_with_google: "Continua con Google",
      continue_with_linkedin: "Continua con LinkedIn",
      sign_up_with_email: "Iscriviti con l'e-mail",
    },
    [Languages.GERMAN]: {
      password: "Kennwort",
      email_name_placeholder: "Gib deine E-Mail-Adresse ein.",
      email: "E-Mail Adresse",
      forgotPassword: "Hast du das Passwort vergessen?",
      terms: "Bedingungen und Konditionen",
      privacy: "Datenschutzrichtlinien",
      accountNotPresent:
        "Du hast noch kein AQai-Konto? Kein Problem, du kannst ",
      accountAlreadyPresent: "Hast du schon ein Konto?",
      signIn: "Einloggen",
      signUp: "Hier kannst du dich registrieren und kaufen.",
      first_name: "Vorname",
      last_name: "Nachname",
      first_name_placeholder: "Bitte gib deinen Vornamen ein.",
      last_name_placeholder: "Bitte gib deinen Nachnamen ein.",
      firstNameError: "Vorname ist erforderlich",
      lastNameError: "Nachname ist erforderlich",
      cardNumber: "Kartennummer",
      cvc: "CVC",
      continue: "Weiter",
      currency: "Währung",
      nameOnCard: "Karteninhaber*in",
      nameOnCardPlaceHolder:
        "Bitte gib den Namen des/der Karteninhaber*in ein.",
      expDate: "Ablaufdatum",
      checkout: "Kassenbereich",
      discountCode: "Code",
      discountCodePlaceholder: "Bitte gib deinen Rabatt-Code ein.",
      paymentSuccessFull: "Zahlung erfolgreich",
      thankYouForChoosingAqai:
        "Danke, dass du dich für das AQai-Assesment entschieden hast.",
      nextButton: "Nächste",
      notSureProceed: "Du bist dir nicht sicher, ob du abschliessen willst?",
      cancel: "Abbrechen",
      organizationRequiredError: "Organisation ist erforderlich.",
      organizationSizeRequired: "Organisationsgröße ist erforderlich",
      organizationTitle: "Name deiner Organisation",
      organizationPlaceholder: "Bitte gib den Namen deiner Organisation ein.",
      organizationSizeTitle: "Größe der Organisation",
      noOfAssessmentTitle: "Zahl der Auswertungen",
      noOfAssessmentPlaceholder: "Bitte gib die Zahl der Auswertungen ein.",
      noOfAssessmentRequiredError: "Zahl der Auswertungen ist erforderlich.",
      captchaRequiredError:
        "Bitte füllen Sie das CAPTCHA aus, um fortzufahren.",
      continue_with_google: "Weiter mit Google",
      continue_with_linkedin: "Fahren Sie mit LinkedIn fort",
      sign_up_with_email: "Registrieren Sie sich mit Ihrer E-Mail Adresse",
    },
  };
}
