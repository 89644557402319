import * as _ from "lodash";
import { action, computed, observable, runInAction } from "mobx";
import { Organization } from "../models/OrganizationModel";
import { OrganizationName } from "../models/OrganizationNameModel";
import { People } from "../models/PeopleModel";
import { User } from "../models/UserModel";
import { PaginatedResponse } from "../responses/paginated.response";
import { apiService } from "../services/ApiService";
import { organizationService } from "../services/OrganizationService";
import { RootStore } from "./RootStore";
import Store, { EntityIdentifier } from "./Store";
import { Role } from "../enums/role.enum";
import { UserName } from "../models/UserNameModel";
import { OrganizationSize } from "../enums/organization-size.enum";

export class OrganizationStore extends Store<Organization> {
  private static _instance: OrganizationStore;

  @observable isLoading = false;
  @observable isLoaded = false;
  @observable isCompactOrgLoading = false;
  @observable isCompactOrgLoaded = false;
  @observable compactOrganization: OrganizationName[] = [];
  @observable hasMore = true;
  @observable offset = 0;
  @observable dataPerPage = 20;
  @observable private _filter: { [key: string]: any } = {};
  @observable noOfRetakeAssessment = 0;
  @observable isNoOfRetakeAssessmentLoading = false;
  @observable isNonCDPOrgLoading = false;
  @observable isNonCDPOrgLoaded = false;
  @observable nonCDPOrganizations: OrganizationName[] = [];

  @computed
  get filters() {
    return this._filter;
  }

  constructor() {
    super();
    Organization._store = this;
  }

  static getInstance(): OrganizationStore {
    if (!this._instance) {
      this._instance = new OrganizationStore();
    }

    return this._instance;
  }

  @action
  setFilters(filters: { [key: string]: any }) {
    if (!_.isEqual(this._filter, filters)) {
      this.offset = 0;
      this.entities = [];
      this._filter = { ...this._filter, ...filters };
      this.fetchOrganizations();
    }
  }

  @action
  fetchOrganizations() {
    this.isLoading = true;
    organizationService
      .getAll({ ...this._filter, offset: this.offset, limit: this.dataPerPage })
      .then((response: PaginatedResponse<Organization>) => {
        runInAction(() => {
          this.hasMore = !(
            !response.data.length || response.data.length < this.dataPerPage
          );
          this.offset = this.offset + this.dataPerPage;
          response.data.map((organization) => {
            Organization.fromJson(organization);
            this.isLoading = false;
            this.isLoaded = true;
          });
        });

        return;
      });
  }

  async fetchMyOrganizations() {
    try {
      this.isLoading = true;
      const response = await organizationService.getMyOrganizations();

      runInAction(() => {
        response.data.map((organization) =>
          Organization.fromJson(organization),
        );
      });
      this.isLoading = false;
      this.isLoaded = true;
    } catch (e: any) {
      this.isLoading = false;
      throw new Error(e?.message || "Something went wrong!");
    }
  }

  @action
  updatePendingCount(
    id: EntityIdentifier,
    teamId: EntityIdentifier,
    decrease?: boolean,
  ) {
    this.entities = this.organizations.map((org) =>
      org.id === id
        ? (Organization.fromJson({
            ...org,
            pendingInviteCount: decrease
              ? org.pendingInviteCount - 1
              : org.pendingInviteCount + 1,
            teams: org.teams.map((team) =>
              team.id === teamId
                ? {
                    ...team,
                    pendingInviteCount: decrease
                      ? team.pendingInviteCount - 1
                      : (team.pendingInviteCount || 0) + 1,
                  }
                : team,
            ),
          }) as Organization)
        : org,
    );
  }

  @action
  addTeam(id: EntityIdentifier, team: any) {
    const org = this.organizations.find((org) => org.id === id);
    if (org) {
      const updatedOrg =
        org &&
        ({
          ...org,
          teams: [...org.teams, team],
        } as Organization);
      Organization.fromJson(updatedOrg);
    }
  }

  createOrganization(data: {
    name: string;
    isLite: boolean;
    parent_organization_id?: number;
    from_org_id: number;
    credits?: number;
  }) {
    this.isLoading = true;
    return organizationService
      .createOrganization(data)
      .then(
        (data: {
          fromOrganization: Organization;
          toOrganization: Organization;
        }) => {
          runInAction(() => {
            Organization.fromJson(data.toOrganization);
            Organization.fromJson(data.fromOrganization);
            this.isLoading = false;
          });
          this.compactOrganization.push(data.toOrganization);
          return data.toOrganization;
        },
      )
      .catch((e: any) => {
        this.isLoading = false;
        throw e;
      });
  }

  @action
  deleteTeam(id: EntityIdentifier) {
    const org = this.organizations.find((org) =>
      org.teams.some((team) => team.id === id),
    );
    const updatedOrg =
      org &&
      ({
        ...org,
        teams: org.teams.filter((team) => team.id !== id),
      } as Organization);
    Organization.fromJson(updatedOrg);
  }

  @action
  updateTeam(id: EntityIdentifier, updatedTeam: any) {
    const org = this.organizations.find((org) =>
      org.teams.some((team) => team.id === id),
    );
    const updatedOrg =
      org &&
      ({
        ...org,
        teams: org.teams.map((team) => (team.id === id ? updatedTeam : team)),
      } as Organization);
    Organization.fromJson(updatedOrg);
  }

  @action
  fetchNonCDPOrganizations() {
    this.isNonCDPOrgLoading = true;
    organizationService
      .getNonCDPOrganizations()
      .then((res) => {
        runInAction(() => {
          this.nonCDPOrganizations = res;
        });
        this.isNonCDPOrgLoading = false;
        this.isNonCDPOrgLoaded = true;
      })
      .catch(() => (this.isNonCDPOrgLoading = false));
  }

  @action
  fetchCompactOrganizations() {
    this.isCompactOrgLoading = true;
    organizationService.getCompactOrganization().then((res) => {
      runInAction(() => {
        this.compactOrganization = res;
      });
      this.isCompactOrgLoading = false;
      this.isCompactOrgLoaded = true;
    });
  }

  @computed
  get organizations(): Organization[] {
    return [...this.entities];
  }

  @action
  getRetakeAssessmentCount(data: { org_id?: number; team_id?: number }) {
    this.isNoOfRetakeAssessmentLoading = true;
    organizationService
      .getRetakeAssessmentCount(data)
      .then((res) => {
        runInAction(() => {
          this.noOfRetakeAssessment = res.count;
        });
        this.isNoOfRetakeAssessmentLoading = false;
      })
      .catch(() => (this.isNoOfRetakeAssessmentLoading = false));
  }

  sendBulkInviteForRetakeAssessment(data: {
    org_id?: number;
    team_id?: number;
    user_id?: number;
    includeCC?: boolean;
  }) {
    return organizationService.bulkInviteForRetakeAssessment(data);
  }

  @action
  addOrganization(data: {
    name: string;
    is_active: boolean;
    assessment_purchased: number;
  }) {
    return organizationService.add(data).then((organization: Organization) => {
      runInAction(() => {
        Organization.fromJson(organization);
        this.compactOrganization.push(organization);
      });
      return organization.id;
    });
  }

  @action
  updateOrganization(
    id: number,
    data: {
      name: string;
      is_active: boolean;
      assessment_purchased?: number;
    },
  ) {
    return organizationService
      .update(id, data)
      .then((organization: Organization) => {
        runInAction(() => {
          Organization.fromJson(organization);
        });
        return;
      });
  }

  @action
  updateOrganizationUserLevel(
    id: number,
    data: {
      name: string;
      display_name: string;
      country_id?: number;
      allow_retake: boolean;
      activity_notifications: boolean;
      is_visibility_public: boolean;
      assessmentCompletionFrequency?: string;
    },
  ) {
    this.isLoading = true;
    return organizationService
      .updateNew(id, data)
      .then((organization: Organization) => {
        runInAction(() => {
          Organization.fromJson(organization);
        });
        this.isLoading = false;
        return;
      })
      .catch(() => {
        this.isLoading = false;
      });
  }

  @action
  deleteOrganization(id: number) {
    return organizationService.delete(id).then(() => {
      runInAction(() => {
        this.remove(id);
      });
      return;
    });
  }

  exportReportsAsCSV(id: number, type?: { [key: string]: string }) {
    return organizationService.exportReportsAsCSV(id, type);
  }

  @action
  updateOrganizationUsers(
    orgId: number,
    users: User[], // Assuming UserName interface holds user data
    role: Role.PARTNER | Role.OWNER,
    isAdd: boolean,
  ) {
    const userIds = users.map((user) => +user.id);
    const orgIndex = this.compactOrganization.findIndex(
      (org) => org.id === orgId,
    );
    if (orgIndex !== -1) {
      const org = this.compactOrganization[orgIndex];

      if (isAdd) {
        // Add users
        if (role === Role.PARTNER) {
          // Add users to partners array
          org.partners = [...(org.partners || []), ...users] as UserName[];
        } else {
          // Add users to champions array
          org.champions = [...(org.champions || []), ...users] as UserName[];
        }
      } else {
        // Remove users
        if (role === Role.PARTNER) {
          // Remove users from partners array
          org.partners = org.partners?.filter(
            (partner) => !userIds.includes(partner.id),
          );
        } else {
          // Remove users from champions array
          org.champions = org.champions?.filter(
            (champion) => !userIds.includes(champion.id),
          );
        }
      }

      // Update the organization in compactOrganization array
      this.compactOrganization[orgIndex] = org;
    }
  }

  async attachUsersToOrg(
    data: { org_id: number; user_ids: number[] },
    isPeoplePage?: boolean,
  ) {
    try {
      if (isPeoplePage) {
        RootStore.getInstance().people.isPeopleLoading = true;
      } else {
        RootStore.getInstance().user.isLoading = true;
      }
      const users = await organizationService.attachUserToOrganization(
        data.org_id,
        data.user_ids,
      );

      runInAction(() => {
        if (isPeoplePage) {
          users?.forEach((people) => People.fromJson(people));
          RootStore.getInstance().people.isPeopleLoading = false;
        } else {
          users?.forEach((people) => User.fromJson(people));
          const partnerUsers = users?.filter(
            (user) => user.role === Role.PARTNER,
          );
          if (partnerUsers?.length > 0) {
            this.updateOrganizationUsers(
              data.org_id,
              partnerUsers,
              Role.PARTNER,
              true,
            );
          }

          const championUsers = users?.filter(
            (user) => user.role === Role.OWNER,
          );
          if (championUsers?.length > 0) {
            this.updateOrganizationUsers(
              data.org_id,
              championUsers,
              Role.OWNER,
              true,
            );
          }
          RootStore.getInstance().user.isLoading = false;
        }
      });
      return users;
    } catch (e: any) {
      runInAction(() => {
        if (isPeoplePage) {
          RootStore.getInstance().people.isPeopleLoading = false;
        } else {
          RootStore.getInstance().user.isLoading = false;
        }
      });
      throw new Error(e?.message || "Something went wrong!");
    }
  }

  async removeUserFromOrg(
    data: { org_id: string; user_id: string },
    isPeoplePage?: boolean,
  ) {
    try {
      if (isPeoplePage) {
        RootStore.getInstance().people.isPeopleLoading = true;
      } else {
        RootStore.getInstance().user.isLoading = true;
      }
      const user = await organizationService.removeUserFromOrganization(
        data.org_id,
        data.user_id,
      );
      runInAction(() => {
        if (isPeoplePage) {
          People.fromJson(user);
          RootStore.getInstance().people.isPeopleLoading = false;
        } else {
          User.fromJson(user);
          if ([Role.OWNER, Role.PARTNER].includes(user.role)) {
            this.updateOrganizationUsers(
              +data.org_id,
              [user],
              user.role as any,
              false,
            );
          }
          RootStore.getInstance().user.isLoading = false;
        }
      });
      return user;
    } catch (e: any) {
      runInAction(() => {
        if (isPeoplePage) {
          RootStore.getInstance().people.isPeopleLoading = false;
        } else {
          RootStore.getInstance().user.isLoading = false;
        }
      });
      throw new Error(e?.message || "Something went wrong!");
    }
  }

  async uploadCompanyLogoAndGetUrl(file: File, imageName: string) {
    try {
      const response = await organizationService.getPresignedUrl(imageName);
      const url = response.url;
      await apiService.upload(url, file);
      return url.split("?")[0];
    } catch (e) {
      throw new Error("Error in uploading the file");
    }
  }

  @action
  async allocateCredits(data: {
    credits: number;
    to_org_id?: number;
    from_org_id?: number;
    comment?: string;
  }): Promise<any> {
    this.isLoading = true;

    try {
      const response = await organizationService.allocateCredits(data);

      runInAction(() => {
        if (response.fromOrganization)
          Organization.fromJson(response.fromOrganization);
        if (response.toOrganization)
          Organization.fromJson(response.toOrganization);
      });
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
      throw e;
    }
  }

  linkToExistingOrg(current_org_id: string, data: { org_ids: string[] }) {
    return organizationService
      .linkToExistingOrg(current_org_id, data)
      .then((res) => {
        this.isNonCDPOrgLoaded = false;
        this.isNonCDPOrgLoading = true;
        this.nonCDPOrganizations = this.nonCDPOrganizations.filter(
          (org) => !data.org_ids.includes(String(org.id)),
        );
        this.isNonCDPOrgLoaded = true;
        this.isNonCDPOrgLoading = false;
        return res;
      });
  }

  async createPublicOrganisation(data: {
    organization: string;
    organization_size: OrganizationSize;
  }) {
    return await organizationService
      .createPublicOrg(data)
      .then((res: any) => res);
  }
}
