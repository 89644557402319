import { CompactTeamModel, Team } from "../models/TeamModel";
import { ListResponse } from "../responses/list.response";
import { ShowResponse } from "../responses/show.response";
import { apiService } from "./ApiService";

class TeamService {
  static getInstance(): TeamService {
    return new TeamService();
  }

  getAll(): Promise<Team[]> {
    return apiService
      .get<ListResponse<Team>>("/teams", true, true)
      .then((res) => res.data);
  }

  getAllForAdmin(): Promise<Team[]> {
    return apiService
      .get<Team[]>("/admin/teams", true, true)
      .then((res) => res);
  }

  addTeam(data: {
    title: string;
    organization_id: number;
    organization_name?: string;
  }): Promise<Team> {
    return apiService
      .post<ShowResponse<Team>>("/teams", true, true, data)
      .then((res) => res.data);
  }

  attachUser(teamId: string, userId: number): Promise<Team> {
    return apiService
      .put<ShowResponse<Team>>(
        "/teams/" + teamId + "/attach-user",
        true,
        true,
        { user_id: userId },
      )
      .then((res) => res.data);
  }

  async updateTitle(
    id: number,
    data: { title: string; organization_name?: string },
  ): Promise<Team> {
    return apiService
      .put<ShowResponse<Team>>(`/teams/${id}`, true, true, data)
      .then((res) => res.data);
  }

  async updateTeam(
    id: number,
    data: {
      title: string;
      organization_name: string;
      activity_notifications: boolean;
      is_visibility_public: boolean;
    },
  ): Promise<Team> {
    return apiService
      .put<ShowResponse<Team>>(`/teams/${id}`, true, true, data)
      .then((res) => res.data);
  }

  deleteTeam(id: number): Promise<any> {
    return apiService.delete(`/teams/${id}`, true, true);
  }

  removeUser(teamId: string, userId: string): Promise<any> {
    return apiService.delete(`/teams/${teamId}/users/${userId}`, true, true);
  }

  async setTeamVisibility(
    id: number,
    data: { is_visibility_public: boolean },
  ): Promise<Team> {
    return apiService
      .post<ShowResponse<Team>>(`/teams/${id}/set-visibility`, true, true, data)
      .then((res) => res.data);
  }

  getCompactTeamsOfParticularOrg(
    organization_id: number,
  ): Promise<CompactTeamModel[]> {
    return apiService
      .get<ListResponse<CompactTeamModel>>(
        `/organization/${organization_id}/teams`,
        true,
        true,
      )
      .then((response) => response.data);
  }
}

export const teamService = TeamService.getInstance();
