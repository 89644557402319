import { observable } from "mobx";
import { AqScoreEnum } from "../enums/aq-score.enum";
import { Role } from "../enums/role.enum";
import { Visibility } from "../enums/visibility.enum";
import { PeopleStore } from "../stores/PeopleStore";
import Model from "./Model";
import { Organization } from "./OrganizationModel";
import { UserPlans } from "../enums/userPlan.enum";
import { AqliteUpgradeStatus } from "../enums/aqlite-upgrade-invite.enum";

export class People extends Model {
  static _store: PeopleStore;

  @observable id!: string;
  @observable first_name!: string;
  @observable last_name!: string;
  @observable email!: string;
  @observable role!: Role;
  @observable last_active!: string;
  @observable teams!: { id: number; title: string; allow_retake: boolean }[];
  @observable organization!: Organization;
  @observable organizations!: Organization[];
  @observable aqScore!: {
    status: AqScoreEnum;
    score?: number;
    completed_on: string;
  };
  @observable createdAt!: Date;
  @observable visibility!: Visibility;
  @observable survey!: any;
  @observable plan!: UserPlans;
  @observable upgradeStatus!: AqliteUpgradeStatus;
  @observable is_primary_contact!: boolean;
  @observable can_access_own_aqme_report!: boolean;
  @observable can_access_others_aqme_report!: boolean;
  @observable can_access_adv_reports!: boolean;
  @observable can_access_team_report!: boolean;
  @observable can_purchase_assessment_credits!: boolean;
  @observable can_export_scores!: boolean;
  @observable surveyCount = 0;
  @observable latestAqScore!: {
    status: AqScoreEnum;
    score?: number;
    completed_on: string;
  };

  getId(): string | number {
    return this.id;
  }
}
