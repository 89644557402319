import { saveAs } from "file-saver";
import { Constants } from "../constants";
import { CurrencyEnum } from "../enums/currency.enum";
import { Role } from "../enums/role.enum";
import { AqliteUpgradeInviteModel } from "../models/AqliteUpgradeInviteModel";
import { Organization } from "../models/OrganizationModel";
import { AdminUserUpdateModel, User } from "../models/UserModel";
import { UserName } from "../models/UserNameModel";
import { ListResponse } from "../responses/list.response";
import { PaginatedResponse } from "../responses/paginated.response";
import { ShowResponse } from "../responses/show.response";
import { apiService } from "./ApiService";
import { SsoAttachRequestDto, SsoDetailsDto } from "../models/SSOModel";
import { SSOPROVIDER } from "../enums/SSO.enum";

class UserService {
  static getInstance(): UserService {
    return new UserService();
  }

  getCompactUsers(role?: Role[]): Promise<UserName[]> {
    return apiService
      .get<ListResponse<UserName>>("/options/users", true, true, {
        role: role,
      })
      .then((response) => response.data);
  }

  getMyPartners(): Promise<UserName[]> {
    return apiService
      .get<ListResponse<UserName>>("/users/my-partners", true, true)
      .then((response) => response.data);
  }

  getAll(filters: { [key: string]: any }): Promise<PaginatedResponse<User>> {
    return apiService.get<PaginatedResponse<User>>(
      "/admin/users",
      true,
      true,
      filters,
    );
  }

  delete(id: number): Promise<Organization> {
    return apiService.delete("/admin/users/" + id, true, true).then();
  }

  exportAnswersAsCSV(
    id: number,
    type?: { [key: string]: string },
  ): Promise<any> {
    const fileName = type ? "result.csv" : "answers.csv";
    return apiService
      .get(`/users/${id}/answers`, true, false, type, "blob")
      .then((res) => {
        saveAs(res as any, fileName);
        return;
      });
  }

  exportAllAnswersAsCSV(): Promise<any> {
    return apiService
      .get("/admin/users/answers", true, false, {}, "blob")
      .then((res) => {
        saveAs(res as any, "company-report.xlsx");
        return;
      });
  }

  updateUserDetails(data: AdminUserUpdateModel, id: number): Promise<User> {
    return apiService
      .put<ShowResponse<User>>(`/admin/users/` + id, true, true, data)
      .then((response) => response.data);
  }

  toggleHideOverlay(): Promise<User> {
    return apiService
      .put<ShowResponse<User>>(`/overlay`, true, true, {})
      .then((response) => response.data);
  }

  getMutationToken(id: number): Promise<any> {
    return apiService
      .get<{ data: any }>(`/token/${id}`, true, true)
      .then((response) => response.data);
  }

  getGoodDataLoginClaims(): Promise<any> {
    return apiService
      .get<{ data: any }>(`/goodDataLoginClaims`, true, true)
      .then((response) => response.data);
  }

  skipAssessment(): Promise<any> {
    return apiService.post("/survey/skip", true, true);
  }

  initiateAssessmentPurchase(data: {
    assessment_purchased: number;
    currency: CurrencyEnum;
    organization_id: number;
    discount_code?: string;
  }): Promise<any> {
    return apiService
      .post<{ data: { stripe_payment_token: string } }>(
        `/assessment-purchase/initiate?stripeApiKey=${localStorage.getItem(
          Constants.APP_TEST_STRIPE_KEY,
        )}`,
        true,
        true,
        data,
      )
      .then((res) => res.data);
  }

  async initiateAqliteUpgrade(data: {
    currency: CurrencyEnum;
    token?: string;
    discount_code?: string;
  }): Promise<any> {
    return apiService
      .post<{ data: { stripe_payment_token: string } }>(
        `/aqlite/upgrade/initiate?stripeApiKey=${localStorage.getItem(
          Constants.APP_TEST_STRIPE_KEY,
        )}`,
        true,
        true,
        data,
      )
      .then((res) => res.data);
  }

  completeAssessmentPurchase(data: {
    stripe_payment_token: string;
    organization_id: number;
  }) {
    return apiService.post(
      `/assessment-purchase/complete?stripeApiKey=${localStorage.getItem(
        Constants.APP_TEST_STRIPE_KEY,
      )}`,
      true,
      true,
      data,
    );
  }

  completeAqliteUpgrade(data: {
    stripe_payment_token: string;
    token?: string;
  }) {
    return apiService.post(
      `/aqlite/upgrade/complete?stripeApiKey=${localStorage.getItem(
        Constants.APP_TEST_STRIPE_KEY,
      )}`,
      true,
      true,
      data,
    );
  }

  aqliteInviteUser(data: { name?: string; email: string }): Promise<any> {
    return apiService.post("/aqlite/email-invite", true, true, data);
  }

  requestUpgrade(data: AqliteUpgradeInviteModel): Promise<any> {
    return apiService.post("/aqlite-upgrade/request", true, true, data);
  }

  completionAfterSelfUpgrade(data: {
    to_organization_id: number;
    from_organization_id: number;
    user_id: number;
  }): Promise<any> {
    return apiService.post("/aqlite-upgrade/complete", true, true, data);
  }

  updateRestartSurvey(data: { restart_survey: boolean }): Promise<any> {
    return apiService.post(
      "/aqlite-upgrade/update/restart-survey",
      true,
      true,
      data,
    );
  }

  upgradeUsingAqCredits(data: {
    token: string;
    restart_survey: boolean;
  }): Promise<any> {
    return apiService.post<ShowResponse<any>>(
      "/aqlite-upgrade/aq-credits",
      true,
      true,
      data,
    );
  }

  async sendOTPForEmailUpdateVerify(data: { email: string }) {
    return await apiService.post("/email-code", true, true, data);
  }

  async sendOTP(data: { email: string }) {
    return await apiService.post("/verify-email", false, false, data);
  }

  async rejectUpgradeInvite(): Promise<any> {
    return await apiService.delete("/aqlite/invite", true, true);
  }

  getPresignedUrl(imageName: string): Promise<{ url: string }> {
    return apiService
      .post<ShowResponse<{ url: string }>>(
        `/profile-pic/presigned-url`,
        true,
        true,
        { imageName: imageName },
      )
      .then((res) => res.data);
  }

  fetchUserDetails(userId: number): Promise<User> {
    return apiService
      .get<ShowResponse<User>>(`/users/${userId}`, true, true)
      .then((res) => res.data);
  }

  getUserSSODetails(): Promise<SsoDetailsDto[]> {
    return apiService
      .get<ListResponse<SsoDetailsDto>>(`/sso/fetch`, true, true)
      .then((res) => res.data);
  }

  async updateUserSSO(attachData: SsoAttachRequestDto): Promise<SsoDetailsDto> {
    return await apiService
      .post<ShowResponse<SsoDetailsDto>>(`/sso/attach`, true, false, attachData)
      .then((res) => res.data);
  }

  deleteUserSso(sso_provider: SSOPROVIDER) {
    return apiService
      .post<ShowResponse<SsoDetailsDto>>("/sso/remove", true, false, {
        sso_provider,
      })
      .then((res) => res);
  }
}

export const userService = UserService.getInstance();
