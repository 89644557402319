export enum WorkTenure {
  RANGE_0_1 = "0-1",
  RANGE_1_2 = "1-2",
  RANGE_3_5 = "3-5",
  RANGE_6_9 = "6-9",
  RANGE_10_14 = "10-14",
  RANGE_ABOVE_15 = "15+",
}

export class WorkTenureEnumUtils {
  static getWorkTenureTitle(workTenure: WorkTenure): string {
    return workTenure.toString();
  }
}
