import { observable } from "mobx";
import Model from "./Model";
import { AuditLogsStore } from "../stores/AuditLogsStore";
import { OrganizationName } from "./OrganizationNameModel";
import { UserCompactModel } from "./UserComapactModel";

export class AuditLogsModel extends Model {
  static _store: AuditLogsStore;

  @observable id!: number;
  @observable activity!: string;
  @observable amount!: number;
  @observable assessment_count!: number;
  @observable fromOrganization!: OrganizationName;
  @observable toOrganization!: OrganizationName;
  @observable activityBy!: UserCompactModel;
  @observable createdAt!: string;
  @observable comment!: string;

  getId(): string | number {
    return this.id;
  }
}
