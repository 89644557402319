import Model from "./Model";
import { observable } from "mobx";
import { PromotionalImagesStore } from "../stores/PromotionalImagesStore";
import { PromotionalScreens } from "../enums/promotional-screens.enum";

export class PromotionalImagesModel extends Model {
  static _store: PromotionalImagesStore;

  @observable id!: number;
  @observable image_url!: string;
  @observable type!: PromotionalScreens;
  @observable page_no!: number;

  getId(): string | number {
    return this.id;
  }
}

export interface PromotionalImagesFormData {
  type: PromotionalScreens;
  image_url: string;
  page_no: number;
}
