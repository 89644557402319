import React from "react";
import { withRouter } from "react-router";
import { GlobalProps } from "../app/App";

type Props = GlobalProps;

const NoAuthorized: React.FC<Props> = (props: Props) => {
  const style = {
    width: "100vw",
    height: window.innerHeight,
    margin: "10px 30px",
  };

  return (
    <div style={style}>
      <h3>You are not authorized to access this page.</h3>
    </div>
  );
};

export default withRouter(NoAuthorized);
