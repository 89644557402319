import { RoutePath } from "../models/RoutePath";

export class RouteUtil {
  static getUrl(route: RoutePath, data?: any, isRelative = false): string {
    let url: string = route.path;
    let parent: RoutePath | null | undefined;

    do {
      parent = RouteUtil.getParent(parent ? parent : route);
      url = (parent && !isRelative ? parent.path + "/" : "") + url;
    } while (parent && !isRelative);

    url = (isRelative ? "" : "/") + url;

    if (data) {
      return RouteUtil.url(url, data);
    } else {
      return url;
    }
  }

  private static url(path: string, data: any): string {
    Object.keys(data).forEach((key) => {
      path = path.replace(`:${key}`, data[key]);
    });
    return path;
  }

  private static getParent(child: RoutePath): RoutePath | null {
    return child.parent;
  }
}
