import { observable } from "mobx";
import { TeamStore } from "../stores/TeamStore";
import Model from "./Model";

export class Team extends Model {
  static _store: TeamStore;

  @observable id!: number;
  @observable title!: string;
  @observable token!: string;
  @observable userCount!: number;
  @observable report!: any;
  @observable organization_name!: string | null;
  @observable is_visibility_public!: boolean;

  getId(): string | number {
    return this.id;
  }
}

export interface CompactTeamModel {
  id: number;
  title: string;
  organization_name: string;
}
