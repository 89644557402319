import { action, computed, observable } from "mobx";
import { EnterpriseReport } from "../models/EmterpriseReportModel";

export class AdminDashboardStore {
  private static _instance: AdminDashboardStore;

  @observable report: { [key: string]: EnterpriseReport } = {};
  @observable private _filter: { [key: string]: any } = {};
  @observable isLoaded = false;
  @observable isDataAvailable = true;

  @computed
  get filters() {
    return this._filter;
  }

  static getInstance(): AdminDashboardStore {
    if (!this._instance) {
      this._instance = new AdminDashboardStore();
    }

    return this._instance;
  }

  @action
  resetIsDataAvailable() {
    this.isDataAvailable = true;
  }
}
