import { ListResponse } from "../responses/list.response";
import { ShowResponse } from "../responses/show.response";
import { apiService } from "./ApiService";
import {
  PromotionalImagesFormData,
  PromotionalImagesModel,
} from "../models/PromotionalImagesModel";
import { EventModel } from "../models/EventModel";
import {
  CommunityCardFormData,
  CommunityModel,
} from "../models/CommunityModel";

class CommunityService {
  static getInstance(): CommunityService {
    return new CommunityService();
  }

  getAll(): Promise<CommunityModel[]> {
    return apiService
      .get<ListResponse<CommunityModel>>("/community-cards", true, true)
      .then((response) => response.data);
  }

  update(id: number, data: CommunityCardFormData): Promise<CommunityModel> {
    return apiService
      .put<ShowResponse<CommunityModel>>(
        `/community-cards/${id}`,
        true,
        false,
        data,
      )
      .then((res) => res.data);
  }
}

export const communityService = CommunityService.getInstance();
