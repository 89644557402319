import { observable, action, runInAction } from "mobx";
import { NotifyAssessmentCompletionsModel } from "../models/NotifyAssessmentCompletionsModel";
import { notificationService } from "../services/NotificationService";
import Store from "./Store";

export class NotifyAssessmentCompletionsStore extends Store<NotifyAssessmentCompletionsModel> {
  private static _instance: NotifyAssessmentCompletionsStore;

  @observable orgIds: { id: number; frequency: string }[] = [];
  @observable teamIds: { id: number; frequency: string }[] = [];
  @observable userIds: number[] = [];
  @observable userId = 0;
  @observable isLoading: string | null = null;

  constructor() {
    super();
    NotifyAssessmentCompletionsModel._store = this;
  }

  static getInstance(): NotifyAssessmentCompletionsStore {
    if (!this._instance) {
      this._instance = new NotifyAssessmentCompletionsStore();
    }

    return this._instance;
  }

  @action
  async fetchNotifyAssessmentCompletions(userId: string): Promise<void> {
    try {
      const response =
        await notificationService.getAssessmentCompletionNotifications(userId);
      runInAction(() => {
        this.orgIds = response.orgIds || [];
        this.teamIds = response.teamIds || [];
        this.userIds = response.userIds || [];
        this.userId = response.userId;
      });
    } catch (error) {
      console.error("Failed to fetch notify assessment completions", error);
    }
  }

  @action
  async updateNotifyAssessmentCompletions(
    data: {
      userId?: number;
      teamIds?: {
        id: number;
        frequency: string;
      }[];
      orgIds?: {
        id: number;
        frequency: string;
      }[];
      userIds?: number[];
      status: "add" | "remove";
    },
    loader: string,
  ): Promise<void> {
    this.isLoading = loader;
    try {
      const response =
        await notificationService.assessmentCompletionNotification(data);
      this.isLoading = null;

      runInAction(() => {
        this.orgIds = response.data.orgIds || [];
        this.teamIds = response.data.teamIds || [];
        this.userIds = response.data.userIds || [];
        this.userId = response.data.userId;
      });
    } catch (error) {
      console.error("Failed to update notify assessment completions", error);
    }
  }
}
