import { action, computed, observable, runInAction } from "mobx";
import { PromotionalImagesModel } from "../models/PromotionalImagesModel";
import { apiService } from "../services/ApiService";
import { promotionalImagesService } from "../services/PromotionalImagesService";
import Store from "./Store";

export class PromotionalImagesStore extends Store<PromotionalImagesModel> {
  private static _instance: PromotionalImagesStore;

  @observable isLoading = false;
  @observable isLoaded = false;

  constructor() {
    super();
    PromotionalImagesModel._store = this;
  }

  @computed
  get promotionalImages(): PromotionalImagesModel[] {
    return [...this.entities];
  }

  static getInstance(): PromotionalImagesStore {
    if (!this._instance) {
      this._instance = new PromotionalImagesStore();
    }

    return this._instance;
  }

  @action
  fetchPromotionalImages() {
    this.isLoading = true;
    promotionalImagesService
      .getAll()
      .then((promotionalImages: PromotionalImagesModel[]) => {
        runInAction(() => {
          promotionalImages.map((promotionalImage) =>
            PromotionalImagesModel.fromJson(promotionalImage),
          );
          this.isLoaded = true;
        });
      })
      .catch((err) => console.log(err))
      .finally(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
  }

  @action
  deletePromotionalImage(id: number) {
    this.isLoading = true;
    return promotionalImagesService
      .delete(id)
      .then(() => {
        runInAction(() => {
          this.remove(id);
        });
      })
      .catch((err) => console.log(err))
      .finally(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
  }

  @action
  async addPromotionalImage(data: any): Promise<void> {
    this.isLoading = true;

    try {
      const response = await promotionalImagesService.add(data);
      runInAction(() => {
        PromotionalImagesModel.fromJson(response);
      });
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
      throw e;
    }
  }

  @action
  async updatePromotionalImage(id: number, data: any): Promise<void> {
    try {
      this.isLoading = true;

      const response = await promotionalImagesService.update(id, data);
      runInAction(() => {
        PromotionalImagesModel.fromJson(response);
      });
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
      throw e;
    }
  }

  async uploadImageAndGetUrl(file: File, imageName: string) {
    try {
      const response = await promotionalImagesService.getPresignedUrl(
        imageName,
      );
      const url = response.url;
      await apiService.upload(url, file);
      return url.split("?")[0];
    } catch (e) {
      throw new Error("Error in uploading the file");
    }
  }
}
