import { action, computed, observable, runInAction } from "mobx";
import Store from "./Store";
import { AqCoachReportModal } from "../models/AqCoachReportModal";
import { aqCoachReportService } from "../services/AqCoachReportService";
import * as _ from "lodash";

export class AqCoachReportStore extends Store<AqCoachReportModal> {
  private static _instance: AqCoachReportStore;

  @observable isLoading = false;
  @observable isLoaded = false;
  @observable offset = 0;
  @observable dataPerPage = 50;
  @observable hasMore = true;
  @observable private _filter: { [key: string]: any } = {};

  constructor() {
    super();
    AqCoachReportModal._store = this;
  }

  @computed
  get aqCoachReports(): AqCoachReportModal[] {
    return [...this.entities];
  }

  static getInstance(): AqCoachReportStore {
    if (!this._instance) {
      this._instance = new AqCoachReportStore();
    }

    return this._instance;
  }

  @computed
  get filters() {
    return this._filter;
  }

  @action
  setFilters(filters: { [key: string]: any }) {
    if (!_.isEqual(this._filter, filters)) {
      this._filter = { ...this._filter, ...filters };
      this.offset = 0;
      this.entities = [];
      this.fetchAqCoachReport();
    }
  }

  @action
  fetchAqCoachReport() {
    this.isLoading = true;

    aqCoachReportService
      .fetchAqCoachReports({
        offset: this.offset,
        limit: this.dataPerPage,
        ...this._filter,
      })
      .then((aqReports: AqCoachReportModal[]) => {
        runInAction(() => {
          aqReports.map((aqReport) => AqCoachReportModal.fromJson(aqReport));
          this.hasMore = !(
            !aqReports.length || aqReports.length < this.dataPerPage
          );
          this.offset = this.offset + this.dataPerPage;
          this.isLoaded = true;
        });
      })
      .catch((err) => console.log(err))
      .finally(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
  }
}
