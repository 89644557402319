import React, { FunctionComponent } from "react";
import classNames from "classnames";

export interface Props {
  type?: "error" | "helper";
  text?: string;
}

const InputHelper: FunctionComponent<Props> = ({ text, type }) => {
  return text ? (
    <p
      className={classNames("mt-2  mb-0  text-13px font-normal", {
        "text-brandRed": type === "error",
        "text-brandDarkBlue": type === "helper",
      })}
      id="input-error"
    >
      {text}
    </p>
  ) : null;
};

export default InputHelper;

InputHelper.defaultProps = {
  type: "helper",
};
