import React from "react";
import BrandButton from "./shared/CustomComponents/BrandButton";
import { SvgIconType } from "./shared/svgIconComponent/svgIcons.list";
import { useGoogleLogin } from "@react-oauth/google";

interface Props {
  disabled?: boolean;
  leadingSvgIcon: SvgIconType;
  children: string;
  leadingSvgIconClassname?: string;
  className?: string;
  onSucess: (codeResponse: { code: string }) => void;
  onError: () => void;
}
const GoogleSSOButton: React.FC<Props> = ({
  disabled,
  leadingSvgIcon,
  children,
  leadingSvgIconClassname,
  className,
  onSucess,
  onError,
}) => {
  const login = useGoogleLogin({
    onSuccess: onSucess,
    onError: onError,
    flow: "auth-code",
  });
  return (
    <BrandButton
      type="button"
      children={children}
      className={className}
      disabled={disabled}
      leadingSvgIcon={leadingSvgIcon}
      leadingSvgIconClassname={leadingSvgIconClassname}
      onClick={() => login()}
    />
  );
};

export default GoogleSSOButton;
