import { ListResponse } from "../responses/list.response";
import { ShowResponse } from "../responses/show.response";
import { apiService } from "./ApiService";
import { Affiliate, AffiliateFormData } from "../models/AffiliatesModel";
import { TransactionModel } from "../models/TransactionModel";

class AffiliateService {
  static getInstance(): AffiliateService {
    return new AffiliateService();
  }

  getAll(): Promise<Affiliate[]> {
    return apiService
      .get<ListResponse<Affiliate>>("/admin/affiliates", true, false)
      .then((response) => response.data);
  }

  add(data: AffiliateFormData): Promise<Affiliate> {
    return apiService
      .post<ShowResponse<Affiliate>>("/admin/affiliate", true, false, data)
      .then((response) => response.data);
  }

  getPresignedUrl(affiliateName: string): Promise<{ url: string }> {
    return apiService
      .post<ShowResponse<{ url: string }>>(
        `/admin/presigned-url`,
        true,
        false,
        { affiliateName: affiliateName },
      )
      .then((res) => res.data);
  }

  getAffiliateTransactions(id: number): Promise<TransactionModel[]> {
    return apiService
      .get<ListResponse<TransactionModel>>(
        `/affiliate/${id}/transactions`,
        true,
        false,
      )
      .then((res) => res.data);
  }
}

export const affiliateService = AffiliateService.getInstance();
