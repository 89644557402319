import { ListResponse } from "../responses/list.response";
import { apiService } from "./ApiService";
import { ShowResponse } from "../responses/show.response";
import { NotificationModel } from "../models/NotificationModel";

class NotificationService {
  static getInstance(): NotificationService {
    return new NotificationService();
  }

  getNotifications(userId: string): Promise<NotificationModel[]> {
    return apiService
      .get<ListResponse<NotificationModel>>(
        `/notifications/${userId}`,
        true,
        false,
      )
      .then((response) => {
        return response.data;
      });
  }

  updateUserPreferences(userId: string, preferences: any): Promise<any> {
    return apiService
      .put<ShowResponse<any>>(
        `/notificationPreferences/${userId}`,
        true,
        false,
        preferences,
      )
      .then((response) => response.data);
  }

  updateNotificationStatus(notificationId: number, data: any): Promise<any> {
    return apiService
      .put<ShowResponse<any>>(
        `/notification/${notificationId}/status`,
        true,
        false,
        data,
      )
      .then((response) => response.data);
  }

  markAllAsRead(userId: string): Promise<any> {
    return apiService
      .put<ShowResponse<any>>(
        `/notifications/${userId}/markAllAsRead`,
        true,
        false,
        null,
      )
      .then((response) => response.data);
  }

  getNotificationPreferences(userId: string): Promise<any> {
    return apiService
      .get<ShowResponse<any>>(`/notificationPreferences/${userId}`, true, false)
      .then((response) => response);
  }

  assessmentCompletionNotification(data: {
    userId?: number;
    teamIds?: {
      id: number;
      frequency: string;
    }[];
    orgIds?: {
      id: number;
      frequency: string;
    }[];
    userIds?: number[];
    status: "add" | "remove";
  }): Promise<any> {
    return apiService
      .post<ShowResponse<any>>(
        `/assessment-completion-notification`,
        true,
        false,
        JSON.stringify(data),
      )
      .then((response) => response);
  }

  getAssessmentCompletionNotifications(userId: string): Promise<any> {
    return apiService
      .get<ShowResponse<any>>(
        `/assessment-completion-notification/${userId}`,
        true,
        false,
      )
      .then((response) => response);
  }
}

export const notificationService = NotificationService.getInstance();
