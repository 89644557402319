import { observable } from "mobx";
import Model from "./Model";
import { PeerStore } from "../stores/PeerStore";

export class PeerModel extends Model {
  static _store: PeerStore;

  @observable id!: string;
  @observable first_name!: string;
  @observable last_name!: string;
  @observable email!: string;
  @observable dimensionWiseScore!: any;

  getId(): string | number {
    return this.id;
  }
}
