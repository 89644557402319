import classNames from "classnames";
import { isArray } from "lodash";
import React, { useCallback, useMemo, useRef, useState } from "react";
import useId from "../../../Hooks/useId";
import useOutsideClickDetector from "../../../Hooks/useOutsideClickDetector";
import { EntityIdentifier } from "../../../stores/Store";
import { AutoCompleteItemType } from "../../../types/AutoComplete.type";
import InputLabel from "../InputLabel/InputLabel";
import { BrandToolTip } from "./BrandTooltip";
import { Spin } from "antd";

export type SelectMenuType = "default" | "multiple";

interface SelectMenuProps {
  label?: string;
  items: AutoCompleteItemType[];
  selected: EntityIdentifier | EntityIdentifier[];
  selectedString?: any;
  placeholder?: string;
  type?: SelectMenuType;
  autoComplete?: boolean;
  onChange: (value: EntityIdentifier | EntityIdentifier[] | boolean) => void;
  isSearch?: boolean;
  notRounded?: boolean;
  name?: string;
  allowClear?: boolean;
  addItemAction?: { title: string; onAddItem: () => void };
  disabled?: boolean;
  className?: string;
  svgClassName?: string;
  labelClassName?: string;
  onBlur?: () => void;
  error?: boolean;
  padding?: string;
  textColor?: string;
  isRequiredIndicator?: boolean;
  isLoading?: boolean;
}

const SelectMenu: React.FC<SelectMenuProps> = (props) => {
  const { label } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>();

  const wrapperRef = useOutsideClickDetector<HTMLDivElement>(() =>
    setOpen(false),
  );

  const buttonRef = useRef(null);

  const buttonDimensions: any =
    // @ts-ignore
    buttonRef && buttonRef.current && buttonRef.current.getBoundingClientRect();

  const selectedItem = useMemo(() => {
    const selectedItems = props.selected;
    if (isArray(selectedItems)) {
      return (props.items || []).filter((item: AutoCompleteItemType) =>
        selectedItems.includes(item.value as any),
      );
    }
    return (props.items || []).find(
      (item: AutoCompleteItemType) => item.value === selectedItems,
    );
  }, [props.selected, props.items]);

  const renderSelectedItem = () => {
    const selected = selectedItem;
    if (selected && isArray(selected) && selected.length) {
      const items = selected
        .map((item: AutoCompleteItemType) => item.label)
        .join(", ");
      return <span className="block truncate w-[90%]">{items}</span>;
    }
    if (selected && !isArray(selected)) {
      return (
        <>
          {selected.leadingLabel && (
            <div className={"w-4"}>{selected.leadingLabel}</div>
          )}
          <span className="block truncate ">{selected.label || ""}</span>
        </>
      );
    }
    if (props.selectedString) {
      return (
        <>
          <span className="block truncate ">{props.selectedString}</span>
        </>
      );
    }
    return (
      <span
        className={`block truncate font-normal ${
          props.error ? "text-brandRed" : "text-brandGrey"
        }`}
      >
        {props.placeholder || "Select..."}
      </span>
    );
  };

  const handleChange = useCallback(
    async (value: EntityIdentifier | string) => {
      if (props.type !== "multiple") {
        setOpen(false);
      }
      setSearch("");

      if (value === "") {
        props.onChange(props.type === "multiple" ? [] : "");
        return;
      }

      if (props.type === "multiple") {
        const previousSelected = props.selected;
        if (isArray(previousSelected)) {
          if (previousSelected.includes(value))
            props.onChange(previousSelected.filter((v) => v !== value));
          else props.onChange([...previousSelected, value]);
          return;
        }
        if (previousSelected !== undefined)
          props.onChange([previousSelected, value]);
        else props.onChange([value]);
        return;
      }

      props.onChange(value);
      return;
    },
    [props.type, props.selected, props.onChange],
  );

  const filteredList: AutoCompleteItemType[] = useMemo(() => {
    if (!search) return props.items;
    return props.items.filter((item: AutoCompleteItemType) =>
      item.label.toLowerCase().includes((search || "").toLowerCase().trim()),
    );
  }, [props.items, search]);

  const id = useId();

  const isItemSelected = (value: EntityIdentifier): boolean => {
    if (isArray(props.selected)) return props.selected.includes(value);
    else return value === props.selected;
  };

  const renderListItemContent = (item: AutoCompleteItemType, key: number) => {
    return (
      <li
        key={key}
        onClick={() =>
          item.value === "groupHeading" ? null : handleChange(item.value as any)
        }
        role="option"
        className={classNames(
          "select-none relative py-1  cursor-pointer  px-8",
          {
            "": item.value === ("groupHeading" as any),
          },
          {
            "hover:bg-brandGrey hover:bg-opacity-20":
              item.value !== ("groupHeading" as any),
          },
          isItemSelected(item.value) ? "text-brandGrey" : "text-brandDarkBlue",
        )}
      >
        {item.value === "groupHeading" ? (
          <span className="font-medium opacity-50 text-bannerDarkBlue">
            {item.label}
          </span>
        ) : props.type === "multiple" ? (
          <div className="flex w-full break-all space-x-2 items-center justify-between">
            {item.label}
            {isItemSelected(item.value) && (
              <svg
                className="w-6 h-6 shrink-0 text-brandDarkBlue pointer-events-none"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M5 13l4 4L19 7"></path>
              </svg>
            )}
          </div>
        ) : (
          item.label
        )}
      </li>
    );
  };

  return (
    <div className="w-full">
      {label && (
        <InputLabel
          htmlFor={id}
          label={label}
          className={`text-base font-medium ${
            props.error ? "text-brandRed" : "text-brandDarkBlue"
          } mb-2 ${props.labelClassName}`}
          isRequiredIndicator={props.isRequiredIndicator}
        />
      )}

      <div
        ref={buttonRef}
        className={`w-full relative border flex ${
          props.error ? "border-brandRed" : "border-brandGrey "
        } bg-transparent rounded-2xl cursor-pointer ${
          props.padding ? props.padding : "px-4 py-4"
        }  ${props.className} ${props.disabled && "disable_overlay"}`}
        onKeyPress={(): void => setOpen(!open)}
        onClick={(): void => setOpen(!open)}
        onKeyDown={(): void => setOpen(!open)}
        onBlur={props.onBlur}
      >
        {props.isLoading && (
          <Spin
            style={{
              position: "absolute",
              margin: "auto",
              top: "0",
              left: "0",
              right: "0",
              bottom: "0",
            }}
            className={"flex items-center justify-center"}
          />
        )}
        <button
          type="button"
          name={props.name}
          aria-haspopup="listbox"
          aria-expanded="true"
          aria-labelledby="listbox-label"
          className={`text-left w-full transition ease-in-out duration-150 text-base font-normal relative`}
        >
          <div
            className={`flex items-center space-x-1.5  ${
              props.textColor
                ? props.textColor
                : props.error
                ? "text-brandRed"
                : "text-brandDarkBlue"
            }  font-medium`}
          >
            {renderSelectedItem()}
          </div>
          <img
            src={
              require(open
                ? "../../../assets/upward-arrow.svg"
                : "../../../assets/downward-arrow.svg")?.default
            }
            alt="share-report"
            className={`absolute  top-1/2 -translate-y-1/2 right-0 cursor-pointer w-4 h-3 ${props.svgClassName}`}
          />
        </button>
      </div>
      <div className="relative flex flex-row" ref={wrapperRef}>
        {open && (
          <div
            className="absolute w-auto rounded-2xl border border-brandGrey bg-white"
            style={
              buttonDimensions.bottom + 212 > window.innerHeight
                ? {
                    zIndex: 999,
                    bottom: buttonDimensions.height + 6 + "px",
                  }
                : { zIndex: 999, top: "6px" }
            }
          >
            <ul
              tabIndex={-1}
              role="listbox"
              aria-labelledby="listbox-label"
              aria-activedescendant="listbox-item-3"
              className={`py-1 overflow-auto`}
              style={{ maxHeight: "212px" }}
            >
              {props.autoComplete && (
                <li
                  key={-1}
                  role="option"
                  className={classNames(
                    "text-brandNewText select-none  px-8 py-2",
                  )}
                >
                  <input
                    type="text"
                    value={search}
                    placeholder={"Search.."}
                    onChange={(e) => setSearch(e.target.value)}
                    className={`w-full px-3 py-2  rounded-xl  border border-brandGrey focus:outline-none focus:border-brandDarkBlue text-brandDarkBlue placeholder:text-brandGrey`}
                  />
                </li>
              )}
              {props.allowClear && (filteredList || []).length > 0 && (
                <li
                  key={-2}
                  role="option"
                  onClick={() => handleChange("")}
                  className={classNames(
                    "select-none text-brandGrey relative cursor-pointer italic  px-8 py-1",
                  )}
                >
                  Clear
                </li>
              )}
              {(filteredList || []).length > 0 ? (
                filteredList.map((item, index) => {
                  return item.toolTipContent ? (
                    <BrandToolTip
                      key={index}
                      text={item.toolTipContent}
                      placement="top"
                      trigger="hover"
                    >
                      {renderListItemContent(item, index)}
                    </BrandToolTip>
                  ) : (
                    renderListItemContent(item, index)
                  );
                })
              ) : (
                <li className="text-brandGrey cursor-not-allowed relative py-1 px-8">
                  No records found!
                </li>
              )}
              {props.addItemAction && (
                <li
                  key={-2}
                  role="option"
                  onClick={props.addItemAction.onAddItem}
                  className={classNames(
                    "select-none text-brandGrey relative cursor-pointer  px-8 py-1",
                  )}
                >
                  {props.addItemAction.title}
                </li>
              )}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectMenu;
