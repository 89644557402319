import { message, Modal } from "antd";
import { User } from "../models/UserModel";
import { LoginResponse } from "../responses/login.response";
import { ShowResponse } from "../responses/show.response";
import { apiService } from "./ApiService";
import { CurrencyEnum } from "../enums/currency.enum";
import { Constants } from "../constants";
import { SSOPROVIDER } from "../enums/SSO.enum";
import { SsoAttachRequestDto } from "../models/SSOModel";
import { DimensionLanguages } from "../enums/languages.enum";

const { confirm } = Modal;

class AuthService {
  static getInstance(): AuthService {
    return new AuthService();
  }

  individualSignup(data: any) {
    return apiService
      .post<ShowResponse<any>>("/individual-signup", false, false, data)
      .then((res) => res.data);
  }

  individualSsoSignup(data: {
    code: string;
    sso_provider: SSOPROVIDER;
    preferred_language: DimensionLanguages;
  }) {
    return apiService
      .post<any>("/sso/individual-signup", true, false, data)
      .then((res) =>
        localStorage.setItem("public_auth_token", res.data.public_auth_token),
      );
  }

  initiatePayment(data: {
    discount_code?: string;
    id: number;
    currency: CurrencyEnum;
    billing_details?: any;
  }) {
    return apiService
      .post<ShowResponse<any>>(
        `/initiate-payment?stripeApiKey=${localStorage.getItem(
          Constants.APP_TEST_STRIPE_KEY,
        )}`,
        false,
        false,
        data,
      )
      .then((res) => res.data);
  }

  completePayment(data: { id: number }) {
    return apiService
      .post<LoginResponse>(
        `/complete-payment?stripeApiKey=${localStorage.getItem(
          Constants.APP_TEST_STRIPE_KEY,
        )}`,
        false,
        false,
        data,
      )
      .then((response) => {
        if (response) {
          localStorage.setItem("auth_token", response.token);
          localStorage.setItem(
            "selected_org_id",
            response.user.organizations[0]
              ? "" + response.user.organizations[0].id
              : "",
          );
          return response.user;
        }
        return;
      });
  }

  updateUserDetails(data: Object, endPoint: string): Promise<User> {
    return apiService
      .put<ShowResponse<User>>(endPoint, true, true, data)
      .then((response) => response.data);
  }

  login(data: {
    email: string;
    password: string;
    recaptchaCode?: string;
  }): Promise<User> {
    return apiService
      .post<LoginResponse>("/authenticate", false, false, data)
      .then((response) => {
        localStorage.setItem("auth_token", response.token);
        localStorage.setItem(
          "selected_org_id",
          response.user.organizations[0]
            ? "" + response.user.organizations[0].id
            : "",
        );
        return response.user;
      });
  }

  ssoLogin(
    code: string,
    sso_provider: SSOPROVIDER,
    preferred_language: DimensionLanguages,
  ): Promise<any> {
    return apiService
      .post<LoginResponse>("/sso/login", false, false, {
        code,
        sso_provider,
        preferred_language,
      })
      .then((response) => {
        if (response) {
          return response;
        }
      });
  }

  logout() {
    confirm({
      title: "Are you sure you want to logout?",
      okText: "Yes",
      cancelText: "No",
      cancelButtonProps: { style: { backgroundColor: "transparent" } },
      onOk() {
        apiService.post("/logout", true, false).then(() => {
          localStorage.clear();
          window.location.reload();
        });
      },
      onCancel() {
        console.log("onCancel function is empty");
      },
    });
  }

  initiateForgotPassword(email: string): Promise<any> {
    return apiService.post<any>("/forgot-password", false, false, { email });
  }

  completeForgotPassword(token: string, password: string): Promise<any> {
    return apiService.post<any>("/reset-password", false, false, {
      token,
      password,
    });
  }

  me(useMutatedToken: boolean): Promise<User> {
    return apiService
      .get<ShowResponse<User>>("/me", true, useMutatedToken)
      .then((response) => response.data);
  }

  verifyPromocode(
    code: string,
    data: { assessment_purchased: number },
  ): Promise<any> {
    return apiService
      .post<ShowResponse<any>>(`/coupon/${code}`, false, false, data)
      .then((res) => res.data);
  }

  refreshToken() {
    apiService.post("/session/refresh", true, true).catch((e: any) => {
      message.error(e.message || "Something went wrong!");
    });
  }
}

export const authService = AuthService.getInstance();
