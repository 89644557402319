import Model from "./Model";
import { observable } from "mobx";
import { PushNotificationStore } from "../stores/PushNotificationStore";
import { Role } from "../enums/role.enum";
import { PushNotificationSendTo } from "../enums/push-notification-send-to.enum";

export class PushNotificationModel extends Model {
  static _store: PushNotificationStore;

  @observable id!: number;
  @observable parent_id!: number;
  @observable message!: string;
  @observable sent_to!: PushNotificationSendTo;
  @observable created_by!: Role;
  @observable isRead!: boolean;

  getId(): string | number {
    return this.id;
  }
}
