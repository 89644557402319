import { Formik } from "formik";
import { inject } from "mobx-react";
import React from "react";
import * as Yup from "yup";
import * as yup from "yup";
import { StoreProps } from "../app/App";
import { BrandButton } from "../shared/CustomComponents/BrandButton";
import BrandInput from "../shared/CustomComponents/BrandInput";
import { message } from "antd";

interface Props extends StoreProps {
  onSubmit: () => void;
  data: any;
}

const VerifyEmailModal: React.FC<Props> = (props: Props) => {
  const validationSchema = Yup.object().shape({
    otp: yup.string().required("Verification code is Required"),
  });

  return (
    <div>
      <Formik
        initialValues={{
          otp: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          props
            .store!.user.updateProfile({
              ...props.data,
              email_verification_code: values.otp,
            })
            .then(() => {
              message.success("Profile updated successfully");
              props.onSubmit();
            })
            .catch((e) => {
              message.error(
                e.message || "Some error occurred while updating your profile",
              );
            })
            .finally(() => {
              actions.setSubmitting(false);
            });
        }}
      >
        {(formikProps) => {
          return (
            <form
              onSubmit={formikProps.handleSubmit}
              className={"px-6 pt-6 pb-5 flex flex-col h-full  justify-between"}
            >
              <div className={""}>
                <p className={"text-22px font-medium text-brandDarkBlue mb-5"}>
                  Verify you email
                </p>

                <div className={"flex flex-col w-full space-y-4"}>
                  <BrandInput
                    name={"otp"}
                    label={"Verification Code"}
                    placeholder={"Enter verification code"}
                  />
                </div>
              </div>
              <div className={"flex justify-end mt-8"}>
                <BrandButton children={"Submit"} />
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default inject("store")(VerifyEmailModal);
