export enum Role {
  ADMIN = "admin",
  OWNER = "owner",
  EMPLOYEE = "employee",
  PARTNER = "partner",
  CDP = "cdp",
  CORPORATE = "corporate",
}

export class RoleEnumUtils {
  static getRoleTitle(v: Role): string {
    switch (v) {
      case Role.EMPLOYEE: {
        return "Individual";
      }
      case Role.OWNER: {
        return "Practitioner";
      }
      case Role.ADMIN: {
        return "Admin";
      }
      case Role.PARTNER: {
        return "Partner";
      }
      case Role.CDP: {
        return "Certified Delivery Partner";
      }
      case Role.CORPORATE: {
        return "Corporate";
      }
    }
    return "";
  }

  static getRoles() {
    return [
      {
        value: Role.ADMIN,
        label: "Admin",
      },
      {
        value: Role.OWNER,
        label: "Practitioner",
      },
      {
        value: Role.EMPLOYEE,
        label: "Individual",
      },
      {
        value: Role.PARTNER,
        label: "Partner",
      },
      {
        value: Role.CDP,
        label: "Certified Delivery Partner",
      },
      {
        value: Role.CORPORATE,
        label: "Corporate",
      },
    ];
  }
}
