import { inject, observer } from "mobx-react";
import React, { useCallback, useEffect, useState } from "react";
import { StoreProps } from "../app/App";
import { Formik } from "formik";
import {
  JobFunction,
  JobFunctionEnumUtils,
} from "../../enums/job-function.enum";
import { JobLevel, JobLevelEnumUtils } from "../../enums/job-level.enum";
import { message } from "antd";
import * as Yup from "yup";
import { CommonUtils } from "../../utils/common-utils";
import { Gender, GenderEnumUtils } from "../../enums/gender.enum";
import { Age, AgeEnumUtils } from "../../enums/age.enum";
import { WorkTenure, WorkTenureEnumUtils } from "../../enums/work-tenure.enum";
import {
  IndustryType,
  IndustryTypeEnumUtils,
} from "../../enums/industry-type.enum";
import Modal from "../shared/Modal/Modal";
import VerifyEmailModal from "./VerifyEmailModal";
import BrandInput from "../shared/CustomComponents/BrandInput";
import FormikSelectMenu from "../shared/CustomComponents/FormikSelectMenu";
import BrandButton from "../shared/CustomComponents/BrandButton";
import { RegisterPageLanguagesContent } from "../../languages/RegisterPageLanguagesContent";

interface Props extends StoreProps {
  onSubmit: () => void;
}

const ProfilePopUp: React.FC<Props> = (props: Props) => {
  const appStore = props.store!.appStore;
  const gender = CommonUtils.iterateEnum<Gender>(Gender);
  const age = CommonUtils.iterateEnum<Age>(Age);
  const job_function = CommonUtils.iterateEnum<JobFunction>(JobFunction);
  const job_level = CommonUtils.iterateEnum<JobLevel>(JobLevel);
  const work_tenure = CommonUtils.iterateEnum<WorkTenure>(WorkTenure);
  const industry_types = CommonUtils.iterateEnum<IndustryType>(IndustryType);
  const [updateProfileData, setUpdateProfileData] = useState<any>();
  const [showVerifyEmailModal, setShowVerifyEmailModal] =
    useState<boolean>(false);
  const countries = appStore.countries;
  const [locale, setlocale] = useState(props.store!.currentLocale);
  useEffect(() => {
    setlocale(props.store!.currentLocale);
  }, [props.store!.currentLocale]);

  const sendVerificationOTP = useCallback(async (email: string) => {
    try {
      await props.store!.user.sendOTPForEmailUpdateVerify({ email: email });
    } catch (err: any) {
      message.error(err.message || "Something went wrong!");
    }
  }, []);

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    email: Yup.string().email("Invalid Email").required("Email is required"),
    gender: Yup.string().required("Gender is required").oneOf(gender),
    age: Yup.string().required("Age is required").oneOf(age),
    country_id: Yup.string().required("Country is required"),
    job_function: Yup.string()
      .required("Occupation is required")
      .oneOf(job_function),
    other_job_function: Yup.string().when("job_function", {
      is: JobFunction.OTHER,
      then: Yup.string().required("Occupation is required"),
    }),
    job_level: Yup.string().required("Job Level is required").oneOf(job_level),
    other_job_level: Yup.string().when("job_level", {
      is: JobLevel.OTHER,
      then: Yup.string().required("Job Level is required"),
    }),
    industry_type: Yup.string()
      .required("Industry is required")
      .oneOf(industry_types),
    other_industry_type: Yup.string().when("industry_type", {
      is: IndustryType.OTHER,
      then: Yup.string().required("Industry is required"),
    }),
    work_tenure: Yup.string()
      .required("Time at current company is required")
      .oneOf(work_tenure),
  });

  useEffect(() => {
    if (!appStore.countries.length) {
      appStore.fetchCountries();
    }
  }, []);
  const regStep1 = RegisterPageLanguagesContent.RegisterStep1Content[locale];
  const regStep3 = RegisterPageLanguagesContent.RegisterStep3Content[locale];
  const regStep2 = RegisterPageLanguagesContent.RegisterStep2Content[locale];

  return (
    <div>
      <Formik
        initialValues={{
          first_name: props.store!.user.loggedInProfile!.first_name,
          last_name: props.store!.user.loggedInProfile!.last_name,
          email: props.store!.user.loggedInProfile!.email,
          gender: props.store!.user.loggedInProfile!.gender || "",
          age: props.store!.user.loggedInProfile!.age || "",
          country_id: props.store!.user.loggedInProfile!.country_id
            ? props.store!.user.loggedInProfile!.country_id
            : "",
          job_function: props.store!.user.loggedInProfile!.job_function || "",
          other_job_function:
            props.store!.user.loggedInProfile!.other_job_function || "",
          job_level: props.store!.user.loggedInProfile!.job_level || "",
          other_job_level:
            props.store!.user.loggedInProfile!.other_job_level || "",
          industry_type: props.store!.user.loggedInProfile!.industry_type || "",
          other_industry_type:
            props.store!.user.loggedInProfile!.other_industry_type || "",
          work_tenure: props.store!.user.loggedInProfile!.work_tenure || "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values: any, actions) => {
          if (values.job_function !== JobFunction.OTHER) {
            delete values["other_job_function"];
          }

          if (values.job_level !== JobLevel.OTHER) {
            delete values["other_job_level"];
          }

          if (values.industry_type !== IndustryType.OTHER) {
            delete values["other_industry_type"];
          }
          const data = {
            ...values,
            country_id: +values.country_id,
          };

          if (
            props.store!.user.loggedInProfile!.email !== values.email.trim()
          ) {
            sendVerificationOTP(values.email);
            setUpdateProfileData(data);
            setShowVerifyEmailModal(true);
            return;
          }

          props
            .store!.user.updateProfile(data)
            .then(() => {
              message.success("Profile updated successfully");
              props.onSubmit();
            })
            .catch((e) => {
              message.error(
                e.message || "Some error occurred while updating your profile",
              );
            })
            .finally(() => {
              actions.setSubmitting(false);
            });
        }}
      >
        {(formikProps) => {
          return (
            <form onSubmit={formikProps.handleSubmit}>
              <p
                className={
                  "text-30px font-medium text-brandDarkBlue mb-4 text-center py-8"
                }
              >
                Review your profile
              </p>
              <div className={"grid grid-cols-2 px-8 gap-x-8 gap-y-6 py-2"}>
                <BrandInput
                  name={"first_name"}
                  label={regStep1.first_name}
                  placeholder={""}
                />
                <BrandInput
                  name={"last_name"}
                  label={regStep1.last_name}
                  placeholder={""}
                />
                <BrandInput
                  name={"email"}
                  label={regStep1.email}
                  placeholder={""}
                />
                <FormikSelectMenu
                  name={"gender"}
                  items={gender.map((v) => {
                    return {
                      value: v,
                      label: GenderEnumUtils.getGenderTitle(v, locale),
                    };
                  })}
                  label={regStep2.gender}
                  autoComplete
                  allowClear
                />
                <FormikSelectMenu
                  name={"age"}
                  items={age.map((v) => {
                    return {
                      value: v,
                      label: AgeEnumUtils.getAgeTitle(v),
                    };
                  })}
                  label={regStep2.age}
                  autoComplete
                  allowClear
                />
                <FormikSelectMenu
                  name={"country_id"}
                  items={countries.map((v) => {
                    return {
                      value: v.id,
                      label: v.name,
                    };
                  })}
                  label={regStep2.country}
                  autoComplete
                  allowClear
                />
                <div className="flex flex-col">
                  <FormikSelectMenu
                    name={"job_function"}
                    items={job_function.map((v) => {
                      return {
                        value: v,
                        label: JobFunctionEnumUtils.getJobFunctionTitle(
                          v,
                          locale,
                        ),
                      };
                    })}
                    label={regStep3.job_function}
                    autoComplete
                    allowClear
                  />
                  {formikProps.values["job_function"] === JobFunction.OTHER && (
                    <div className="mt-4">
                      <BrandInput
                        name={"other_job_function"}
                        label={regStep3.job_function}
                        placeholder={""}
                      />
                    </div>
                  )}
                </div>
                <div className="flex flex-col">
                  <FormikSelectMenu
                    name={"industry_type"}
                    items={industry_types.map((v) => {
                      return {
                        value: v,
                        label: IndustryTypeEnumUtils.getIndustryTypeTitle(
                          v,
                          locale,
                        ),
                      };
                    })}
                    label={regStep3.industry_type}
                    autoComplete
                    allowClear
                  />

                  {formikProps.values["industry_type"] ===
                    IndustryType.OTHER && (
                    <div className="mt-4">
                      <BrandInput
                        name={"other_industry_type"}
                        label={regStep3.industry_type}
                        placeholder={""}
                      />
                    </div>
                  )}
                </div>
                <div className="flex flex-col">
                  <FormikSelectMenu
                    name={"job_level"}
                    items={job_level.map((v) => {
                      return {
                        value: v,
                        label: JobLevelEnumUtils.getJobLevelTitle(v, locale),
                      };
                    })}
                    label={regStep3.job_level}
                    autoComplete
                    allowClear
                  />
                  {formikProps.values["job_level"] === JobLevel.OTHER && (
                    <div className="mt-4">
                      <BrandInput
                        name={"other_job_level"}
                        label={regStep3.job_level}
                        placeholder={""}
                      />
                    </div>
                  )}
                </div>
                <FormikSelectMenu
                  name={"work_tenure"}
                  items={work_tenure.map((v) => {
                    return {
                      value: v,
                      label: WorkTenureEnumUtils.getWorkTenureTitle(v),
                    };
                  })}
                  label={regStep3.work_tenure}
                  autoComplete
                  allowClear
                />
              </div>

              <div className={"mt-8 mb-16 w-60 ml-auto mr-8"}>
                {!CommonUtils.isTrainingUser(
                  props.store!.user.loggedInProfile!.email,
                ) && (
                  <BrandButton
                    className={"w-full"}
                    children={"Submit"}
                    onClick={formikProps.handleSubmit}
                    type={"button"}
                  />
                )}
              </div>
            </form>
          );
        }}
      </Formik>
      <Modal
        modalStyle={{
          width: "400px",
          height: "280px",
          overflowY: "auto",
        }}
        open={showVerifyEmailModal}
        onOutsideClick={() => setShowVerifyEmailModal(false)}
      >
        {" "}
        <VerifyEmailModal
          data={updateProfileData}
          onSubmit={() => setShowVerifyEmailModal(false)}
        />
      </Modal>
    </div>
  );
};

export default inject("store")(observer(ProfilePopUp));
