import { observable } from "mobx";
import Model from "./Model";
import { OrganizationStore } from "../stores/OrganizationStore";
import { IncentivesType } from "../enums/incentivesType.enum";
import { UserName } from "./UserNameModel";

export class Organization extends Model {
  static _store: OrganizationStore;

  @observable id!: number;
  @observable parent_organization_id!: number;
  @observable owner_id!: number;
  @observable name!: string;
  @observable __type!: string;
  @observable is_active!: boolean;
  @observable createdAt!: string;
  @observable updatedAt!: string;
  @observable userCount!: number;
  @observable allow_retake!: boolean;
  @observable assessment_purchased!: number;
  @observable remainingSurveyCount!: number;
  @observable pendingInviteCount!: number;
  @observable invitePendingCount!: number;
  @observable completedSurveys!: number;
  @observable lastSignUpDate!: Date;
  @observable championCount!: number;
  @observable aqliteToken!: string;
  @observable isLite!: boolean;
  @observable partner_count!: number;
  @observable champion_count!: number;
  @observable incentivesType!: IncentivesType;
  @observable display_name!: IncentivesType;
  @observable logo!: string;
  @observable display_cobranding!: boolean;
  @observable cobranding_info!: string;
  @observable partners?: UserName[];
  @observable champions?: UserName[];

  @observable linkedToOrgAt!: string;
  @observable owner!: {
    id: number;
    name: string;
    email: string;
    role: string;
    last_active: string;
  };
  @observable teams!: {
    __type: string;
    id: number;
    title: string;
    allow_retake: boolean;
    token: string;
    userCount?: number;
    pendingInviteCount: number;
    organization_name: string;
  }[];

  getId(): string | number {
    return this.id;
  }
}
