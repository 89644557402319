import { Constants } from "../constants";
import { Colors } from "../enums/colors.enum";
import { Dimensions } from "../enums/dimension-id.enum";
import { DimensionLanguages } from "../enums/languages.enum";
import { ReportMetaType } from "../enums/report-metatypes.enum";
import { ScoreRange } from "../models/EmterpriseReportModel";
import { Organization } from "../models/OrganizationModel";
import { ReportMetaData } from "../models/ReportMetaDataModel";
import { User } from "../models/UserModel";

export class CommonUtils {
  static readonly ASC = "asc";
  static readonly DESC = "desc";

  static readonly LOW = "0";
  static readonly HIGH = "75";
  static readonly MEDIUM = "42";

  static isValidEmail(email: string) {
    // RFC 2822 standard email validation
    const mailFormat =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (email.match(mailFormat)) {
      return true;
    }
    return false;
  }

  static isValidURL(str: string) {
    const pattern = new RegExp(
      "^(http(s)?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i",
    ); // fragment locator
    return !!pattern.test(str);
  }

  static getUniqueId = () =>
    (
      Date.now().toString(36) + Math.random().toString(36).substr(2, 5)
    ).toUpperCase();

  static sortByKey(items: any[], key: string, direction = "asc") {
    if (!key || !direction) {
      return items;
    }

    return items.sort((a, b) => {
      const propertyA: number | string = a[key];
      const propertyB: number | string = b[key];

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (direction === "asc" ? 1 : -1);
    });
  }

  static normalize(entityArray: any[]) {
    const result: any = {};
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < entityArray.length; i++) {
      result[entityArray[i].id] = entityArray[i];
    }

    return result;
  }

  static normalizedObjToArray(object: { [id: string]: any }) {
    const result = [];
    for (let i = 1; i <= Object.keys(object).length; i++) {
      result.push(object[i]);
    }

    return result;
  }

  static getObjectValues(object: { [id: number]: any }) {
    const values = [];
    for (const key in object) {
      if (object.hasOwnProperty(key)) {
        values.push(object[key]);
      }
    }
    return values;
  }

  static iterateEnum<T>(enumRef: any): T[] {
    return Object.keys(enumRef).map((key) => enumRef[key]);
  }

  static checkInEnum<T>(enumRef: any, value: T): boolean {
    return (
      Object.keys(enumRef)
        .filter((k) => isNaN(Number(k))) // Removing reverse mapping in numeric enums.
        .filter((k) => enumRef[k] === value).length > 0
    );
  }

  static getInsightsByScore = (
    range: ScoreRange,
    insights: { [key: string]: string[] },
  ): string[] => {
    if (range === ScoreRange.HIGH && insights[CommonUtils.HIGH]) {
      return insights[CommonUtils.HIGH];
    } else if (range === ScoreRange.MEDIUM && insights[CommonUtils.MEDIUM]) {
      return insights[CommonUtils.MEDIUM];
    } else {
      return insights[CommonUtils.LOW];
    }
  };

  static getSummaryByScore = (
    score: number,
    description: { [key: string]: string[] },
  ): string[] => {
    if (score) {
      score = score / Constants.WEIGHT;
    }
    return description[CommonUtils.LOW];
    // if (score >= CommonUtils.HIGH) {
    //   return description[CommonUtils.HIGH];
    // } else if (score > CommonUtils.MEDIUM) {
    //   return description[CommonUtils.MEDIUM];
    // }  else {
    //   return description[CommonUtils.LOW];
    // }
  };

  static replaceNullKeysWithZero = (object: any) => {
    const newObject = { ...object };
    if (newObject) {
      Object.keys(newObject).forEach((key) => {
        if (newObject.hasOwnProperty(key) && !newObject[key]) {
          newObject[key] = 0;
        }
      });
    }
    return newObject;
  };

  static convertSecIntoMinSecString = (time: number) => {
    const t = Math.round(time);
    return `${Math.floor(t / 60)} Mins, ${t % 60} sec`;
  };

  static getPlanForAssessment = (finalAssessment: number) => {
    if (finalAssessment >= Constants.AQPIONEER_ASSESSMENT_LIMIT) {
      return "AQpioneer";
    } else if (finalAssessment >= Constants.MIN_TEAM_ASSESSMENT_LIMIT) {
      return "AQteam";
    } else {
      return "AQme";
    }
  };

  static getInsightLabel = (insightValue: string): string => {
    switch (insightValue) {
      case "0":
        return "Low";
      case "42":
        return "Medium";
      case "75":
        return "High";
      default:
        return insightValue;
    }
  };

  static getSelectedOrgId() {
    return localStorage.getItem("selected_org_id");
  }

  static toTitleCase(input: string) {
    return input
      .toLowerCase()
      .split(/[_ ]/)
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
      .join(" ");
  }

  static paddZero(length: number, value: number | string) {
    let updatedValue = "" + value;
    while (updatedValue.length < length) {
      updatedValue = "0" + updatedValue;
    }
    return updatedValue;
  }

  // this is not recursive and not generic. Applicable only for this particular usecase
  static hasNonNullValues(obj: any) {
    let hasNonNullValues = false;
    Object.keys(obj).forEach((key) => {
      if (obj[key]?.length > 0) {
        hasNonNullValues = true;
      }
    });
    return hasNonNullValues;
  }

  static isAqliteRegisterPage(location: any) {
    if (location.pathname === "/register/aqlite") {
      return true;
    }
    return false;
  }

  static isTrainingUser(userEmail: string) {
    const trainingUserEmails: string[] = ["training@adaptai.co"];
    return (
      process.env.REACT_APP_ENVIRONMENT !== "PRODUCTION" &&
      trainingUserEmails.includes(userEmail)
    );
  }

  static getUploadedFileName(url: string): string | undefined {
    const _chunk1 = url.split(".com/");
    const _chunks = _chunk1 && _chunk1[1] ? _chunk1[1].split("/") : [];
    return _chunks.join("/");
  }

  static getChacterDialPosition(
    score: number,
    range: ScoreRange,
    upperLimit: number,
    lowerLimit: number,
  ) {
    const maxScore = 100;
    const highRangeFactor = 50 / (maxScore - upperLimit);

    const lowRangeFactor = 50 / lowerLimit;

    let positionOnBar: number;
    if (range === ScoreRange.MEDIUM) {
      positionOnBar = 50;
    } else if (range === ScoreRange.LOW) {
      positionOnBar = 50 - (lowerLimit - score) * lowRangeFactor;
    } else {
      positionOnBar = 50 + (score - upperLimit) * highRangeFactor;
    }
    return positionOnBar + "%";
  }

  static getScorePositionOnTeamBars(
    score: number,
    upperLimit: number,
    lowerLimit: number,
  ) {
    // Calculate the percentage based on the score and limits
    if (score > upperLimit || score < lowerLimit) {
      return lowerLimit;
    }
    const difference = upperLimit - lowerLimit;
    const scoreOffset = score - lowerLimit;
    const positionOnBar = (scoreOffset / difference) * 100;
    return positionOnBar + "%";
  }

  static getSpiderNetScore(
    score: number,
    range: ScoreRange,
    upperLimit: number,
    lowerLimit: number,
  ) {
    const maxScore = 900;
    const highRangeFactor = 450 / (maxScore - upperLimit);

    const lowRangeFactor = 450 / lowerLimit;

    let spiderNetScore: number;
    if (range === ScoreRange.MEDIUM) {
      spiderNetScore = 450;
    } else if (range === ScoreRange.LOW) {
      spiderNetScore = 450 - (lowerLimit - score) * lowRangeFactor;
    } else {
      spiderNetScore = 450 + (score - upperLimit) * highRangeFactor;
    }
    return spiderNetScore || 0;
  }

  static getCharacterThreeBandLabels = (
    dimensionId: number,
    metaData: { [key: string]: ReportMetaData }, //pass this for metadata- props.store!.advancedInsights.reportMetaData
    locale: DimensionLanguages,
  ): string[] => {
    if (dimensionId === Dimensions.EMOTIONAL_RANGE_ID) {
      return [
        metaData[ReportMetaType.REACTIVE].title[locale],
        metaData[ReportMetaType.BALANCED].title[locale],
        metaData[ReportMetaType.COLLECTED].title[locale],
      ];
    } else if (dimensionId === Dimensions.HOPE_ID) {
      return [
        metaData[ReportMetaType.FEARFUL].title[locale],
        metaData[ReportMetaType.UNCERTAIN].title[locale],
        metaData[ReportMetaType.HOPEFUL].title[locale],
      ];
    } else if (dimensionId === Dimensions.EXTRAVERSION_ID) {
      return [
        metaData[ReportMetaType.INTROVERTED].title[locale],
        metaData[ReportMetaType.AMBIVERT].title[locale],
        metaData[ReportMetaType.EXTROVERTED].title[locale],
      ];
    } else if (dimensionId === Dimensions.MOTIVATION_STYLE_ID) {
      return [
        metaData[ReportMetaType.PLAYTOPROTECT].title[locale],
        metaData[ReportMetaType.CONTEXTUAL].title[locale],
        metaData[ReportMetaType.PLAYTOWIN].title[locale],
      ];
    } else if (dimensionId === Dimensions.THINKING_STYLE_ID) {
      return [
        metaData[ReportMetaType.DETAILS].title[locale],
        metaData[ReportMetaType.CONNECTOR].title[locale],
        metaData[ReportMetaType.BIGPICTURES].title[locale],
      ];
    } else {
      return [];
    }
  };
  static getCharacterExtremeTwoBandLabels = (
    dimensionId: number,
    metaData: { [key: string]: ReportMetaData }, //pass this for metadata- props.store!.advancedInsights.reportMetaData
    locale: DimensionLanguages,
  ): string[] => {
    if (dimensionId === Dimensions.EMOTIONAL_RANGE_ID) {
      return [
        metaData[ReportMetaType.REACTIVE].title[locale],
        metaData[ReportMetaType.COLLECTED].title[locale],
      ];
    } else if (dimensionId === Dimensions.HOPE_ID) {
      return [
        metaData[ReportMetaType.FEARFUL].title[locale],
        metaData[ReportMetaType.HOPEFUL].title[locale],
      ];
    } else if (dimensionId === Dimensions.EXTRAVERSION_ID) {
      return [
        metaData[ReportMetaType.INTROVERTED].title[locale],
        metaData[ReportMetaType.EXTROVERTED].title[locale],
      ];
    } else if (dimensionId === Dimensions.MOTIVATION_STYLE_ID) {
      return [
        metaData[ReportMetaType.PLAYTOPROTECT].title[locale],
        metaData[ReportMetaType.PLAYTOWIN].title[locale],
      ];
    } else if (dimensionId === Dimensions.THINKING_STYLE_ID) {
      return [
        metaData[ReportMetaType.DETAILS].title[locale],
        metaData[ReportMetaType.BIGPICTURES].title[locale],
      ];
    } else {
      return [];
    }
  };

  static getScoreInRange(score: number): number {
    return score <= 98 ? (score >= 10 ? score : 10) : 98;
  }

  static calculateThreePartBarPosition(
    score: number,
    range: ScoreRange,
    upperLimit: number,
    lowerLimit: number,
  ) {
    const maxScore = 100;
    const highRangeFactor = 33.33 / (maxScore - upperLimit);

    const lowRangeFactor = 33.33 / lowerLimit;

    const mediumRangeFactor = 33.33 / (upperLimit - lowerLimit);

    let positionOnBar: number;
    if (range === ScoreRange.MEDIUM) {
      positionOnBar = 33.33 + (score - lowerLimit) * mediumRangeFactor;
    } else if (range === ScoreRange.LOW) {
      positionOnBar = 33.33 - (lowerLimit - score) * lowRangeFactor;
    } else {
      positionOnBar = 66.66 + (score - upperLimit) * highRangeFactor;
    }
    return positionOnBar + "%";
  }

  static getParentOrgByOrganisationList(organization: Organization[]) {
    return organization.filter((org) => !org.parent_organization_id).length
      ? organization.filter((org) => !org.parent_organization_id)[0]
      : organization.sort((a, b) => {
          return new Date(a.linkedToOrgAt) > new Date(b.linkedToOrgAt) ? 1 : -1;
        })[0];
  }

  static formatUserName(first_name: string, last_name: string): string {
    return `${first_name} ${last_name}`;
  }

  static getTrafficBarColors = (dimensionId: Dimensions) => {
    if (
      [
        Dimensions.EMOTIONAL_RANGE_ID,
        Dimensions.EXTRAVERSION_ID,
        Dimensions.HOPE_ID,
        Dimensions.MOTIVATION_STYLE_ID,
        Dimensions.THINKING_STYLE_ID,
      ].includes(dimensionId) // Character dimension colours
    ) {
      const baseColors = {
        lowRange: Colors.BRIGHT_TRAFFIC_LIGHT_CHARACTER_LOW,
        midRange: Colors.BRIGHT_TRAFFIC_LIGHT_CHARACTER_MID,
        highRange: Colors.BRIGHT_TRAFFIC_LIGHT_CHARACTER_HIGH,
      };

      if (dimensionId === Dimensions.HOPE_ID) {
        return baseColors;
      } else {
        return {
          ...baseColors,
          lowRange: Colors.BRIGHT_TRAFFIC_LIGHT_CHARACTER_HIGH,
        };
      }
    } else if (dimensionId === Dimensions.WORK_STRESS_ID) {
      // Work-Stress : has green for low range and red for high range
      return {
        lowRange: Colors.HIGH_RANGE_BAND_COLOR,
        midRange: Colors.MID_RANGE_BAND_COLOR,
        highRange: Colors.LOW_RANGE_BAND_COLOR,
      };
    } else {
      return {
        lowRange: Colors.LOW_RANGE_BAND_COLOR,
        midRange: Colors.MID_RANGE_BAND_COLOR,
        highRange: Colors.HIGH_RANGE_BAND_COLOR,
      };
    }
  };

  static isLoggedInUserDetailsCompleted(loggedInProfile: User) {
    const requiredFields: Array<keyof User> = [
      "gender",
      "age",
      "country_id",
      "job_function",
      "job_level",
      "work_tenure",
    ];
    return requiredFields.every((field) => loggedInProfile?.[field]);
  }
}
