import { adminReportService } from "../services/AdminReportService";
import { AdminOrganizationReport } from "../models/AdminOrganizationReport";
import { AdminUserReport } from "../models/AdminUserReport";
import {
  AdminCompanyReport,
  UserAssessmentData,
} from "../models/AdminCompanyReport";
import { AdminCumulativeReport } from "../models/AdminCumulativeReport";

export class AdminReportStore {
  private static _instance: AdminReportStore;

  userData: { [key: string]: AdminUserReport[] } = {};
  organizationData: AdminOrganizationReport[] = [];
  companyData: AdminCompanyReport | null = null;
  cumulativeAssessmentData: { [key: string]: AdminCumulativeReport[] } = {};

  static getInstance(): AdminReportStore {
    if (!this._instance) {
      this._instance = new AdminReportStore();
    }

    return this._instance;
  }

  getMonthlyUserReport(params: {
    [key: string]: any;
  }): Promise<AdminUserReport[]> {
    const searchQuery = JSON.stringify(params);
    if (this.userData.hasOwnProperty(searchQuery)) {
      return Promise.resolve(this.userData[searchQuery]);
    }

    return adminReportService.fetchMonthlyUserReport(params).then((data) => {
      this.userData[searchQuery] = data;
      return data;
    });
  }

  getOrganizationReport(): Promise<AdminOrganizationReport[]> {
    if (this.organizationData.length) {
      return Promise.resolve(this.organizationData);
    }

    return adminReportService.fetchOrganizationReport().then((data) => {
      this.organizationData = data;
      return data;
    });
  }

  getCompanyReport(): Promise<AdminCompanyReport> {
    if (this.companyData) {
      return Promise.resolve(this.companyData);
    }

    return adminReportService.fetchCompanyReport().then((data) => {
      this.companyData = data;
      return data;
    });
  }

  getAssessmentReport(data?: {
    from_date: string;
    to_date: string;
  }): Promise<UserAssessmentData[]> {
    return adminReportService.getAssessmentReport(data).then((data) => {
      return data;
    });
  }

  getCumulativeAssessmentReport(params: {
    [key: string]: any;
  }): Promise<AdminCumulativeReport[]> {
    const searchQuery = JSON.stringify(params);
    if (this.cumulativeAssessmentData.hasOwnProperty(searchQuery)) {
      return Promise.resolve(this.cumulativeAssessmentData[searchQuery]);
    }

    return adminReportService.fetchCumulativeReport(params).then((data) => {
      this.cumulativeAssessmentData[searchQuery] = data;
      return data;
    });
  }
}
