import { action, computed, observable } from "mobx";
import { DashboardTitle } from "../enums/dashboard-title.enum";
import { Languages } from "../enums/languages.enum";
import { Organization } from "../models/OrganizationModel";
import { CommonUtils } from "../utils/common-utils";
import { AdminDashboardStore } from "./AdminDashboard";
import { AdminReportStore } from "./AdminReportStore";
import { AdvancedInsightsStore } from "./AdvancedInsightsStore";
import { AffiliatesStore } from "./AffiliatesStore";
import { AppStore } from "./AppStore";
import { CouponStore } from "./CouponStore";
import { DimensionStore } from "./DimensionStore";
import { InviteStore } from "./InviteStore";
import { OrganizationStore } from "./OrganizationStore";
import { PartnerOrganizationsStore } from "./PartnerOrganizationsStore";
import { PeopleStore } from "./PeopleStore";
import { PromotionalImagesStore } from "./PromotionalImagesStore";
import { QuestionStore } from "./QuestionStore";
import { ReportStore } from "./ReportsStore";
import { SurveyStore } from "./SurveyStore";
import { TasksStore } from "./TasksStore";
import { TeamStore } from "./TeamStore";
import { UserStore } from "./UserStore";
import { ViewRawdataStore } from "./ViewRawdataStore";

import { ScreenType } from "../mocks/DashboardScreenContent";
import { AqCoachReportStore } from "./AqCoachReportStore";
import { CommunityStore } from "./CommunityStore";
import { CompletedSurveyStore } from "./CompletedSurveyStore";
import { EventsStore } from "./EventsStore";
import { MilestoneStore } from "./MilestoneStore";
import { RecentSurveyCompletedActivityStore } from "./RecentSurveyCompletedActivityStore";

import { BotStore } from "./BotStore";
import { PeerStore } from "./PeerStore";
import { PlatformContentStore } from "./PlatformContentStore";
import { PushNotificationStore } from "./PushNotificationStore";

import { AuditLogsStore } from "./AuditLogsStore";

import { SettingsStore } from "./SettingsStore";
import { AssessmentStore } from "./AssessmentStore";
import { AssessmentInviteAuditStore } from "./AssessmentInviteAuditStore";
import { NotifyAssessmentCompletionsStore } from "./NotifyAssessmentCompletionsStore";

export class RootStore {
  private static _instance: RootStore;
  @observable user: UserStore;
  @observable appStore: AppStore;
  @observable dimension: DimensionStore;
  @observable team: TeamStore;
  @observable report: ReportStore;
  @observable adminDashboard: AdminDashboardStore;
  @observable people: PeopleStore;
  @observable question: QuestionStore;
  @observable organization: OrganizationStore;
  @observable invite: InviteStore;
  @observable isBootstrapped = false;
  @observable shouldShowDelightedSurvey = true;
  @observable isFirstSurveyCompleted = false;
  @observable adminReportStore: AdminReportStore;
  @observable coupon: CouponStore;
  @observable affiliates: AffiliatesStore;
  @observable partnerOrganizations: PartnerOrganizationsStore;
  @observable surveys: SurveyStore;
  @observable advancedInsights: AdvancedInsightsStore;
  @observable viewRawdata: ViewRawdataStore;
  @observable promotionalImagesStore: PromotionalImagesStore;
  @observable tasksStore: TasksStore;
  @observable assessmentStore: AssessmentStore;
  @observable assessmentInviteAuditStore: AssessmentInviteAuditStore;
  @observable
  notifyAssessmentCompletionsStore: NotifyAssessmentCompletionsStore;

  @observable milestoneStore: MilestoneStore;
  @observable eventStore: EventsStore;
  @observable communityStore: CommunityStore;
  @observable
  recentSurveyCompletedActivityStore: RecentSurveyCompletedActivityStore;

  @observable
  completedSurveyStore: CompletedSurveyStore;

  @observable
  aqCoachReportStore: AqCoachReportStore;

  @observable pushNotificationStore: PushNotificationStore;
  @observable platformContentStore: PlatformContentStore;

  @observable peerStore: PeerStore;

  @observable botStore: BotStore;

  @observable auditLogsStore: AuditLogsStore;

  @observable settingsStore: SettingsStore;

  @observable private _currentLocale: Languages = Languages.ENGLISH;

  constructor() {
    this.user = UserStore.getInstance();
    this.dimension = DimensionStore.getInstance();
    this.team = TeamStore.getInstance();
    this.report = ReportStore.getInstance();
    this.adminDashboard = AdminDashboardStore.getInstance();
    this.people = PeopleStore.getInstance();
    this.question = QuestionStore.getInstance();
    this.appStore = AppStore.getInstance();
    this.organization = OrganizationStore.getInstance();
    this.invite = InviteStore.getInstance();
    this.adminReportStore = AdminReportStore.getInstance();
    this.coupon = CouponStore.getInstance();
    this.affiliates = AffiliatesStore.getInstance();
    this.partnerOrganizations = PartnerOrganizationsStore.getInstance();
    this.surveys = SurveyStore.getInstance();
    this.advancedInsights = AdvancedInsightsStore.getInstance();
    this.viewRawdata = ViewRawdataStore.getInstance();
    this.promotionalImagesStore = PromotionalImagesStore.getInstance();
    this.tasksStore = TasksStore.getInstance();
    this.assessmentStore = AssessmentStore.getInstance();
    this.assessmentInviteAuditStore = AssessmentInviteAuditStore.getInstance();
    this.notifyAssessmentCompletionsStore =
      NotifyAssessmentCompletionsStore.getInstance();

    this.milestoneStore = MilestoneStore.getInstance();
    this.recentSurveyCompletedActivityStore =
      RecentSurveyCompletedActivityStore.getInstance();
    this.eventStore = EventsStore.getInstance();
    this.communityStore = CommunityStore.getInstance();

    this.pushNotificationStore = PushNotificationStore.getInstance();

    this.botStore = BotStore.getInstance();

    this.initializeCurrentLocale();
    this.completedSurveyStore = CompletedSurveyStore.getInstance();
    this.aqCoachReportStore = AqCoachReportStore.getInstance();
    this.platformContentStore = PlatformContentStore.getInstance();
    this.platformContentStore = PlatformContentStore.getInstance();
    this.peerStore = PeerStore.getInstance();
    this.auditLogsStore = AuditLogsStore.getInstance();

    this.settingsStore = SettingsStore.getInstance();
  }

  @observable private _dashboardTitle: DashboardTitle | string =
    DashboardTitle.AQ_ENTERPRISE;

  @observable private currentScreen: ScreenType = ScreenType.HOMEPAGE;

  @computed get currentLocale(): Languages {
    return this._currentLocale;
  }

  @action
  initializeCurrentLocale() {
    const switchLanguages = CommonUtils.iterateEnum<Languages>(Languages);
    if (!localStorage.getItem("current_locale")) {
      const language =
        (navigator.languages && navigator.languages[0]) || navigator.language;
      const locale = language.toLowerCase().split(/[_-]+/)[0] as Languages;
      this._currentLocale = switchLanguages.find((el) => el === locale)
        ? locale
        : Languages.ENGLISH;
      localStorage.setItem("current_locale", this._currentLocale);
    } else {
      this._currentLocale = localStorage.getItem(
        "current_locale",
      )! as Languages;
    }
  }

  @action
  setCurrentLocale(locale: Languages) {
    localStorage.setItem("current_locale", locale);
    this._currentLocale = locale;
  }

  @computed get dashboardTitle() {
    return this._dashboardTitle;
  }

  @computed get currentScreenType() {
    return this.currentScreen;
  }

  static getInstance(): RootStore {
    if (!this._instance) {
      this._instance = new RootStore();
    }

    return this._instance;
  }

  @action
  setDashboardTitle(title: DashboardTitle | string) {
    this._dashboardTitle = title;
  }

  @action
  setCurrentScreen(screen: ScreenType) {
    this.currentScreen = screen;
  }

  @action
  setOrganization(organization: Organization) {
    this.team.isTeamFetched = false;
    this.people.isPeopleFetched = false;
    this.people.isCompactPeopleLoaded = false;
    this.report.setFilters({});
    this.report.enterpriseReportLoaded = false;
    this.report.isReassessmentsCountsLoaded = false;
    this.user.loggedInProfile!.selected_organization = organization;
    this.setSelectedOrganization(organization.id);
  }

  setSelectedOrganization(id: number) {
    localStorage.setItem("selected_org_id", "" + id);
  }

  setCdpCorporateParentOrganization(id: number) {
    localStorage.setItem("cdp_corporate_parent_org_id", "" + id);
  }
}
