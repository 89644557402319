import { Survey } from "../models/Survey";
import Store from "./Store";
import { advancedInsightsService } from "../services/AdvancedInsightsService";
import { action, observable, runInAction } from "mobx";
import { AdvancedInsightsModel } from "../models/AdvancedInsightsModel";
import { surveyService } from "../services/SurveyService";
import { ReportMetaData } from "../models/ReportMetaDataModel";

export class SurveyStore extends Store<Survey> {
  private static _instance: SurveyStore;

  constructor() {
    super();
    Survey._store = this;
  }

  static getInstance(): SurveyStore {
    if (!this._instance) {
      this._instance = new SurveyStore();
    }

    return this._instance;
  }
  @action
  async fetchSurveyForSurveyId(surveyId: number) {
    return surveyService.fetchSurvey(surveyId).then((survey) => {
      runInAction(() => {
        Survey.fromJson(survey);
      });
      return Survey.fromJson(survey);
    });
  }
}
