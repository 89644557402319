import React from "react";

type Props = {};

const MobileView = (props: Props) => {
  return (
    <div className="flex h-screen w-screen justify-center items-center text-center px-8">
      <div className="flex flex-col items-center">
        <img
          alt="logo"
          src={require("../../assets/logo.svg")?.default}
          className="w-28 h-12"
        />
        <p className="mt-4 text-[16px]">
          We are working hard to support mobile view but till then, please use
          the app on your laptop or desktop.
        </p>
      </div>
    </div>
  );
};

export default MobileView;
