import { action, observable, runInAction } from "mobx";
import { advancedInsightsService } from "../services/AdvancedInsightsService";
import {
  ReportMetaData,
  UpdateReportMetaData,
} from "../models/ReportMetaDataModel";
import { AdvancedInsightsModel } from "../models/AdvancedInsightsModel";
import Store from "./Store";

export class AdvancedInsightsStore extends Store<AdvancedInsightsModel> {
  private static _instance: AdvancedInsightsStore;

  @observable reportMetaData: { [key: string]: ReportMetaData } = {};

  constructor() {
    super();
    AdvancedInsightsModel._store = this;
  }

  static getInstance(): AdvancedInsightsStore {
    if (!this._instance) {
      this._instance = new AdvancedInsightsStore();
    }
    return this._instance;
  }

  @action
  fetchReportMetaData() {
    advancedInsightsService
      .getAllMetadata()
      .then((metaData: ReportMetaData[]) => {
        runInAction(() => {
          this.reportMetaData = metaData.reduce((prev, current) => {
            prev[current.meta_type] = current;
            return prev;
          }, {} as any);
        });
      });
  }

  @action
  async fetchAdvancedInsightsForUserId(userId: number) {
    return advancedInsightsService
      .fetchAdvancedInsightsForUser(userId)
      .then((advancedInsights) => {
        runInAction(() => {
          (advancedInsights as any).id = advancedInsights.user.id;
          Object.keys(advancedInsights.survey.dimension_wise_score).forEach(
            (id) => {
              advancedInsights.survey.dimension_wise_score[id].score =
                advancedInsights.survey.dimension_wise_score[id].score <= 98
                  ? advancedInsights.survey.dimension_wise_score[id].score >= 10
                    ? advancedInsights.survey.dimension_wise_score[id].score
                    : 10
                  : 98;
            },
          );
          AdvancedInsightsModel.fromJson(advancedInsights);
        });
        return advancedInsights;
      });
  }

  updateMetaData(id: number, data: UpdateReportMetaData) {
    return advancedInsightsService.updateMetaData(id, data).then((metaData) => {
      runInAction(() => {
        this.reportMetaData[metaData.meta_type] = metaData;
      });
    });
  }
}
