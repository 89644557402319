import { action, computed, observable, runInAction } from "mobx";
import { CompactTeamModel, Team } from "../models/TeamModel";
import { teamService } from "../services/TeamService";
import { RootStore } from "./RootStore";
import Store from "./Store";

export class TeamStore extends Store<Team> {
  private static _instance: TeamStore;

  @observable isTeamFetched = false;
  @observable isTeamsLoading = false;
  @observable compactTeamsOfParticularOrg: CompactTeamModel[] = [];
  @observable isCompactTeamsOfParticularOrgLoaded = false;
  @observable isCompactTeamsOfParticularOrgLoading = false;

  @observable allTeam: Team[] | null = null;
  @observable isAllTeamFetched = false;
  @observable isAllTeamsLoading = false;

  constructor() {
    super();
    Team._store = this;
  }

  @computed get teams() {
    return [...this.entities];
  }

  static getInstance(): TeamStore {
    if (!this._instance) {
      this._instance = new TeamStore();
    }

    return this._instance;
  }

  @action
  fetchTeams() {
    this.isTeamFetched = false;
    this.isTeamsLoading = true;
    teamService.getAll().then((teams) => {
      this.entities = [];
      runInAction(() => {
        teams.map((team) => Team.fromJson(team));
      });
      this.isTeamFetched = true;
      this.isTeamsLoading = false;
    });
  }

  @action
  async fetchCompactTeamsOfParticularOrg(organization_id: number) {
    this.isCompactTeamsOfParticularOrgLoading = true;

    try {
      const response = await teamService.getCompactTeamsOfParticularOrg(
        organization_id,
      );
      runInAction(() => {
        this.compactTeamsOfParticularOrg = response;
        this.isCompactTeamsOfParticularOrgLoading = false;
        this.isCompactTeamsOfParticularOrgLoaded = true;
      });
    } catch (e) {
      this.isCompactTeamsOfParticularOrgLoading = false;
      throw e;
    }
  }

  @action
  async fetchAllTeamForAdmin() {
    this.isAllTeamsLoading = true;

    try {
      const response = await teamService.getAllForAdmin();
      runInAction(() => {
        this.allTeam = response;
        this.isAllTeamsLoading = false;
        this.isAllTeamFetched = true;
      });
    } catch (e) {
      this.isAllTeamsLoading = false;
    }
  }

  @action
  async addTeam(data: {
    title: string;
    organization_id: number;
    organization_name?: string;
  }) {
    this.isTeamsLoading = true;

    try {
      const team = await teamService.addTeam(data);
      runInAction(() => {
        Team.fromJson(team);
        RootStore.getInstance().organization.addTeam(
          data.organization_id,
          team,
        );
        this.compactTeamsOfParticularOrg.push(team as CompactTeamModel);
        this.isTeamsLoading = false;
      });
      return team.id;
    } catch (e) {
      this.isTeamsLoading = false;
      throw e;
    }
  }

  async attachUser(data: { team_id: string; user_id: string }) {
    return teamService.attachUser(data.team_id, +data.user_id).then(() => {
      RootStore.getInstance().people.addTeamToUser(data.team_id, data.user_id);
    });
  }

  // exportReportAsCSV(teamId: number, type?: {[key: string]: string}) {
  //   return teamService.exportReportAsCSV(teamId, type);
  // }

  async updateTitle(
    id: number,
    data: { title: string; organization_name?: string },
  ) {
    return teamService.updateTitle(id, data).then((team) => {
      runInAction(() => {
        Team.fromJson(team);
      });
      return;
    });
  }

  async updateTeam(
    id: number,
    data: {
      title: string;
      organization_name: string;
      activity_notifications: boolean;
      is_visibility_public: boolean;
      assessmentCompletionFrequency: string;
    },
  ) {
    return teamService.updateTeam(id, data).then((team) => {
      runInAction(() => {
        Team.fromJson(team);
        RootStore.getInstance().organization.updateTeam(id, team);
      });
      return;
    });
  }

  async deleteTeam(id: number) {
    return teamService.deleteTeam(id).then(() => {
      runInAction(() => {
        this.remove(id);
        RootStore.getInstance().organization.deleteTeam(id);
      });
      return;
    });
  }

  removeUser(team_id: string, user_id: string) {
    return teamService.removeUser(team_id, user_id).then(() => {
      RootStore.getInstance().people.removeTeamFromUser(team_id, user_id);
    });
  }

  async setTeamVisibility(id: number, data: { is_visibility_public: boolean }) {
    return teamService.setTeamVisibility(id, data).then((team) => {
      runInAction(() => {
        Team.fromJson(team);
      });
      return;
    });
  }
}
