import { People } from "../models/PeopleModel";
import { ListResponse } from "../responses/list.response";
import { apiService } from "./ApiService";
import { PeopleName } from "../models/PeopleNameModel";

class PeopleService {
  static getInstance(): PeopleService {
    return new PeopleService();
  }

  async fetchPeoples(filters: { [key: string]: any }): Promise<People[]> {
    return apiService
      .get<ListResponse<People>>("/users", true, true, filters)
      .then((response) => response.data);
  }

  async transferUser(
    data: {
      from_org_id: number;
      to_org_id: number;
      from_team_id?: number;
      to_team_id?: number;
      user_id: number;
    }[],
  ): Promise<People[]> {
    return apiService
      .put<ListResponse<People>>("/users/transfer", true, true, data)
      .then((response) => response.data);
  }

  async getCompactPeople(): Promise<PeopleName[]> {
    return apiService
      .get<ListResponse<PeopleName>>("/compact/users", true, true)
      .then((response) => response.data);
  }
}

export const peopleService = PeopleService.getInstance();
