import { action, computed, observable, runInAction } from "mobx";
import { AssessmentInviteModel } from "../models/AssessmentInviteModel";
import { assessmentInviteService } from "../services/AssessmentInviteService";
import Store from "./Store";
import * as _ from "lodash";

export interface AssessmentInviteBackendResponse {
  count: number;
  data: AssessmentInviteModel[];
}

export interface EmailSentBackendResponse {
  status: string;
}

export class AssessmentInviteAuditStore extends Store<AssessmentInviteModel> {
  private static _instance: AssessmentInviteAuditStore;
  @observable isLoading = false;
  @observable error: string | null = null;
  @observable assessmentInvite: AssessmentInviteModel[] | null = null;
  @observable private _filter: { [key: string]: any } = {};
  @observable hasMore = true;
  @observable offset = 0;
  @observable dataPerPage = 50;

  constructor() {
    super();
    AssessmentInviteModel._store = this;
  }

  @computed
  get filters() {
    return this._filter;
  }

  @computed get assessmentAudit(): AssessmentInviteModel[] {
    return [...this.entities];
  }

  static getInstance(): AssessmentInviteAuditStore {
    if (!this._instance) {
      this._instance = new AssessmentInviteAuditStore();
    }

    return this._instance;
  }

  @action
  setFilters(filters: { [key: string]: any }) {
    if (!_.isEqual(this._filter, filters)) {
      this._filter = { ...this._filter, ...filters };
      this.offset = 0;
      this.entities = [];
      this.fetchInviteListForPartner();
    }
  }

  @action
  setFiltersForAdmin(filters: { [key: string]: any }) {
    if (!_.isEqual(this._filter, filters)) {
      this._filter = { ...this._filter, ...filters };
      this.offset = 0;
      this.entities = [];
      this.fetchInviteListForAdmin();
    }
  }

  @action
  resetFilters() {
    this._filter = {};
    this.offset = 0;
    this.entities = [];
  }

  @action
  async fetchInviteListForAdmin() {
    try {
      this.isLoading = true;
      this.error = null;

      const result = await assessmentInviteService.getListForAdmin({
        offset: this.offset,
        limit: this.dataPerPage,
        ...this.filters,
      });

      this.hasMore = !(
        !result.data.length || result.data.length < this.dataPerPage
      );

      runInAction(() => {
        result.data.map((item) => AssessmentInviteModel.fromJson(item));
        this.offset = this.offset + this.dataPerPage;
        this.isLoading = false;
      });
    } catch (error) {
      this.isLoading = false;
      this.error = (error as any)?.message;
    }
  }

  @action
  async fetchInviteListForPartner() {
    try {
      this.isLoading = true;
      this.error = null;

      const result = await assessmentInviteService.getListForPartner({
        offset: this.offset,
        limit: this.dataPerPage,
        ...this.filters,
      });

      this.hasMore = !(
        !result.data.length || result.data.length < this.dataPerPage
      );

      runInAction(() => {
        result.data.map((item) => AssessmentInviteModel.fromJson(item));
        this.offset = this.offset + this.dataPerPage;
        this.isLoading = false;
      });
    } catch (error) {
      this.isLoading = false;
      this.error = (error as any)?.message;
    }
  }

  @action
  async exportCSVOnEmail(includedIds?: number[]) {
    try {
      const response = await assessmentInviteService.exportCSVForPartner(
        this.filters,
        includedIds,
      );
      return response;
    } catch (error) {
      console.log((error as any)?.message);
    }
  }

  @action
  async exportCSVOnEmailForAdmin(includedIds?: number[]) {
    try {
      const response = await assessmentInviteService.exportCSVForAdmin(
        this.filters,
        includedIds,
      );
      return response;
    } catch (error) {
      console.log((error as any)?.message);
    }
  }
}
