import { ListResponse } from "../responses/list.response";
import { ShowResponse } from "../responses/show.response";
import { apiService } from "./ApiService";
import { PushNotificationModel } from "../models/PushNotificationModel";
import { PushNotificationSendTo } from "../enums/push-notification-send-to.enum";

class PushNotificationService {
  static getInstance(): PushNotificationService {
    return new PushNotificationService();
  }

  async getAll(): Promise<PushNotificationModel[]> {
    return apiService
      .get<ListResponse<PushNotificationModel>>(
        "/push-notifications",
        true,
        true,
      )
      .then((response) => response.data);
  }

  async create(data: {
    sent_to: PushNotificationSendTo;
    message: string;
  }): Promise<PushNotificationModel> {
    return apiService
      .post<ShowResponse<PushNotificationModel>>(
        `/push-notifications`,
        true,
        false,
        data,
      )
      .then((res) => res.data);
  }

  async resend(id: number): Promise<PushNotificationModel> {
    return apiService
      .post<ShowResponse<PushNotificationModel>>(
        `/push-notification/${id}/resend`,
        true,
        false,
        {},
      )
      .then((res) => res.data);
  }

  async markReadNotifications(
    notification_ids: number[],
  ): Promise<PushNotificationModel[]> {
    return apiService
      .post<ListResponse<PushNotificationModel>>(
        `/push-notification/read`,
        true,
        false,
        { notification_ids: notification_ids },
      )
      .then((res) => res.data);
  }

  async unsend(id: number): Promise<PushNotificationModel> {
    return apiService
      .post<ShowResponse<PushNotificationModel>>(
        `/push-notification/${id}/unsend`,
        true,
        false,
        {},
      )
      .then((res) => res.data);
  }
}

export const pushNotificationService = PushNotificationService.getInstance();
