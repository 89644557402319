import { ViewRawdataModel } from "../models/viewRawDataModel";
import { ListResponse } from "../responses/list.response";
import { apiService } from "./ApiService";

class ViewRawdataService {
  static getInstance(): ViewRawdataService {
    return new ViewRawdataService();
  }

  async exportReportsAsCSV(data: {
    emails: string[];
    isExportAllUsers?: boolean;
    filters?: { [key: string]: any };
    includeDataForInvisibleUser?: boolean;
    isIncludeReassessment?: boolean;
    isTermsAndConditionAgreed?: boolean;
  }): Promise<any> {
    return apiService.post(`/export`, true, true, data, "blob").then((res) => {
      return;
    });
  }

  async getAllExportedData(): Promise<ViewRawdataModel[]> {
    return apiService
      .get<ListResponse<ViewRawdataModel>>("/admin/exported-data", true, false)
      .then((res) => {
        return res.data;
      });
  }
}

export const viewRawdataService = ViewRawdataService.getInstance();
