import {
  AssessmentInviteBackendResponse,
  EmailSentBackendResponse,
} from "../stores/AssessmentInviteAuditStore";
import { apiService } from "./ApiService";

class AssessmentInviteService {
  static getInstance(): AssessmentInviteService {
    return new AssessmentInviteService();
  }

  async getListForAdmin(filters: { [key: string]: any }) {
    const response = await apiService.get<AssessmentInviteBackendResponse>(
      "/admin/assessment-invite-audits",
      true,
      true,
      filters,
    );

    return response;
  }

  async getListForPartner(filters: { [key: string]: any }) {
    const response = await apiService.get<AssessmentInviteBackendResponse>(
      "/assessment-invite-audits",
      true,
      true,
      filters,
    );

    return response;
  }

  async exportCSVForPartner(
    filters: Record<string, any>,
    includedIds?: number[],
  ) {
    const response = await apiService.post<EmailSentBackendResponse>(
      "/assessment-invite-audits/export",
      true,
      true,
      {
        filters,
        includedIds,
      },
    );

    return response;
  }

  async exportCSVForAdmin(
    filters: Record<string, any>,
    includedIds?: number[],
  ) {
    const response = await apiService.post<EmailSentBackendResponse>(
      "/admin/assessment-invite-audits/export",
      true,
      true,
      {
        filters,
        includedIds,
      },
    );

    return response;
  }
}

export const assessmentInviteService = AssessmentInviteService.getInstance();
