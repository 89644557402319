import { ListResponse } from "../responses/list.response";
import { ShowResponse } from "../responses/show.response";
import { apiService } from "./ApiService";
import {
  PromotionalImagesFormData,
  PromotionalImagesModel,
} from "../models/PromotionalImagesModel";

class PromotionalImagesService {
  static getInstance(): PromotionalImagesService {
    return new PromotionalImagesService();
  }

  getAll(): Promise<PromotionalImagesModel[]> {
    return apiService
      .get<ListResponse<PromotionalImagesModel>>(
        "/promotional-images",
        false,
        false,
      )
      .then((response) => response.data);
  }

  delete(id: number): Promise<any> {
    return apiService.delete("/admin/promotional-image/" + id, true, false);
  }

  add(data: PromotionalImagesFormData): Promise<PromotionalImagesModel> {
    return apiService
      .post<ShowResponse<PromotionalImagesModel>>(
        "/admin/promotional-image",
        true,
        false,
        data,
      )
      .then((response) => response.data);
  }

  update(
    id: number,
    data: PromotionalImagesFormData,
  ): Promise<PromotionalImagesModel> {
    return apiService
      .put<ShowResponse<PromotionalImagesModel>>(
        `/admin/promotional-image/${id}`,
        true,
        false,
        data,
      )
      .then((res) => res.data);
  }

  getPresignedUrl(imageName: string): Promise<{ url: string }> {
    return apiService
      .post<ShowResponse<{ url: string }>>(
        `/admin/promotional-image/presigned-url`,
        true,
        false,
        { imageName: imageName },
      )
      .then((res) => res.data);
  }
}

export const promotionalImagesService = PromotionalImagesService.getInstance();
