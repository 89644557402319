import React, { forwardRef, FunctionComponent } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useField } from "formik";
import InputHelper from "../../Inputhelper/InputHelper";
import { Languages } from "../../../../enums/languages.enum";

export interface FormikReCAPTCHARef {
  reset: () => void;
}

interface BrandInputProps {
  onChange?: (value: string | null) => void;
  className?: string;
  name: string;
  ref?: React.Ref<FormikReCAPTCHARef>;
  locale?: Languages;
}

const FormikReCAPTCHA: FunctionComponent<BrandInputProps> = forwardRef(
  ({ name, onChange, locale }, ref) => {
    const [inputProps, meta, helpers] = useField(name);

    const { value: selection, error, touched } = meta;
    const { setValue: setSelection, setTouched } = helpers;
    return (
      <div className={"w-full"}>
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
          onBlur={() => setTouched(true)}
          onChange={(value): void =>
            onChange ? onChange(value) : setSelection(value)
          }
          ref={ref as any}
          hl={locale}
          key={locale}
        />
        {error && touched && <InputHelper type={"error"} text={error} />}
      </div>
    );
  },
);

export default FormikReCAPTCHA;
