import Model from "./Model";
import { observable } from "mobx";
import { TasksStore } from "../stores/TasksStore";
import { TasksBelongsTo } from "../enums/tasksBelongsTo.enum";
import { MilestoneModel } from "./MilestoneModel";

export class TasksModel extends Model {
  static _store: TasksStore;

  @observable id!: number;
  @observable title!: string;
  @observable description!: string;
  @observable rewards!: number;
  @observable onboarding_task!: boolean;
  @observable guide_video_url!: string;
  @observable button_redirect_url!: string;
  @observable for_type!: TasksBelongsTo[];
  @observable priority!: number;
  @observable isTaskCompleted!: boolean;
  @observable is_active!: boolean;
  @observable milestone!: MilestoneModel;

  getId(): string | number {
    return this.id;
  }
}

export interface TasksFormData {
  id?: number;
  title: string;
  description: string;
  rewards: number;
  onboarding_task: boolean;
  guide_video_url: string;
  button_redirect_url: string;
  for_type: TasksBelongsTo[];
  priority: number;
  milestone_id?: number;
  is_active: boolean;
}
