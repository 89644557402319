import { Button, Card, Col, Dropdown, Icon, Menu, Modal } from "antd";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Constants } from "../../constants";
import "./Bot.scss";
import ADSimpleButton from "../custom/ad-simple-button/ADSimpleButton";
import { Role } from "../../enums/role.enum";
import { GlobalProps } from "../app/App";
import { RouteUtil } from "../../utils/route-utils";
import { Routes } from "../../routes";
import { useLocation, withRouter } from "react-router";
import SwitchLanguage from "../shared/SwitchLanguage.tsx/SwitchLanguage";
import { UserPlans } from "../../enums/userPlan.enum";
import { RegisterPageLanguagesContent } from "../../languages/RegisterPageLanguagesContent";
import { Languages, LanguagesEnumUtils } from "../../enums/languages.enum";
import { ReportMetaType } from "../../enums/report-metatypes.enum";
import CustomModal from "../shared/Modal/Modal";
import ProfilePopUp from "../profile-page/ProfilePopUp";
import CenterLoader from "../shared/CenterLoader/CenterLoader";
import { authService } from "../../services/AuthService";
import { CommonUtils } from "../../utils/common-utils";
import { Link } from "react-router-dom";

interface Props extends GlobalProps {
  chatBotUrl: string;
}

const { confirm } = Modal;

const CommonBot: React.FC<Props> = (props: Props) => {
  const [progress, setProgress] = useState(0);
  const [locale, setlocale] = useState(props.store!.currentLocale);

  const [showLoader, setShowLoader] = useState<boolean>(false);

  const [showProfileUpdateModal, setShowProfileModal] =
    useState<boolean>(false);
  const [isProfileUpdated, setIsProFileUpdated] = useState<boolean>(false);
  const location = useLocation();
  const user = props.store!.user.loggedInProfile!;
  const botStore = props.store!.botStore;
  const organization = props.store!.user.loggedInProfile!.organizations.filter(
    (org) => !org.parent_organization_id,
  ).length
    ? props.store!.user.loggedInProfile!.organizations.filter(
        (org) => !org.parent_organization_id,
      )[0]
    : props.store!.user.loggedInProfile!.organizations.sort((a, b) => {
        return new Date(a.linkedToOrgAt) > new Date(b.linkedToOrgAt) ? 1 : -1;
      })[0];
  const role = props.store!.user.loggedInProfile!.role;
  const remainingSurveys = organization.remainingSurveyCount;
  const hasIncompleteSurveys = user.hasIncompleteSurveys;
  const userSurveys = user.surveys;
  const userSurveyCount = userSurveys.length;
  const isRetakeAllowed = props.store!.user.isRetakeAllowed;
  let skipLiteQuestions = false;
  const loggedInProfile = props.store!.user.loggedInProfile;
  const isUserDetailsCompleted = CommonUtils.isLoggedInUserDetailsCompleted(
    loggedInProfile as any,
  );
  const commonBotContainer =
    RegisterPageLanguagesContent.RegisterContent[locale];

  const returnToMainAccText =
    props.store!.advancedInsights.reportMetaData[
      ReportMetaType.RETURN_ACCOUNT
    ] || {};

  const isMyPartnersCount = !!user.organizations.find(
    (org) => org.partner_count,
  );

  const isMyChampionCount = !!user.organizations.find(
    (org) => org.champion_count,
  );

  const languageLocale = LanguagesEnumUtils.getDimensionLanguageFromLocale(
    props.store!.currentLocale,
  );

  const isClassicBotAllow = !!user.organizations.find(
    (org) =>
      org.parent_organization_id ===
        Number(process.env.REACT_APP_CLASSIC_BOT_ORG) ||
      org.id === Number(process.env.REACT_APP_CLASSIC_BOT_ORG),
  );

  const exhaustMessageText =
    user.role === Role.OWNER ||
    user.role === Role.PARTNER ||
    (!isMyPartnersCount && !isMyChampionCount)
      ? "You have exhausted your assessment credits. Please purchase more assessment credits."
      : "Please contact your AQ sponsor, this Account is out of Assessment credits.";

  useEffect(() => {
    setlocale(props.store!.currentLocale);
  }, [props.store!.currentLocale]);

  useEffect(() => {
    const language = LanguagesEnumUtils.getLanguageTitle(locale);
    botStore.fetchAllQuestions(language);
    if (user.plan === UserPlans.LITE) {
      skipLiteQuestions = false;
    } else {
      if (
        user.plan === UserPlans.PREMIUM &&
        userSurveyCount === 1 &&
        userSurveys.some((s) => s.is_lite === true)
      ) {
        skipLiteQuestions = !user.restart_survey;
      } else {
        skipLiteQuestions = false;
      }
    }
  }, []);

  useEffect(() => {
    const orgName =
      user.customPlaceholder && user.customPlaceholder.organization_name;
    const landbotUrl = props.chatBotUrl;

    if (
      isRetakeAllowed &&
      user.surveys.length > 0 &&
      (!user.hasIncompleteSurveys || !user.isSurveyCompleted) &&
      !isProfileUpdated
    ) {
      setShowProfileModal(true);
      localStorage.removeItem(
        `@landbot/core/storage/${
          location.pathname === "/aqlite"
            ? process.env.REACT_APP_CHATBOT_LITE_ID
            : process.env.REACT_APP_CHATBOT_ID
        }`,
      );
      return;
    }
    if (!isUserDetailsCompleted) {
      setShowProfileModal(true);
    }

    if (
      [
        Languages.ENGLISH,
        Languages.FRENCH,
        Languages.SPANISH,
        Languages.ITALIAN,
        Languages.TURKISH,

        Languages.GERMAN,
        Languages.VIETNAMESE,
      ].includes(locale)
    ) {
      if (!botStore.isLoading && botStore.convertedQuestions) {
        // @ts-ignore
        const landbotFrameWidget = new Landbot.Container({
          container: "#myLandbot",
          configUrl: `${landbotUrl}?full_name=${
            user.first_name + user.last_name
          }`,
          customData: {
            landbot_token: user.landbot_token,
            org_name: orgName ? orgName : organization.name,
            org_id: organization.id,
            has_upgraded_from_lite: `${skipLiteQuestions}`, // set to true if user upgraded their plan and doing first survey else false.
            ...botStore.convertedQuestions,
            full_name: user.first_name,
            user_email: user.email,
            locale: locale,
          },
        });

        landbotFrameWidget.core.events.on("load", () => {
          landbotFrameWidget.window.onComplete = function () {
            console.log("On complete was called");
            if ((window as any).Intercom) {
              (window as any).Intercom("track-event", "assessment-completed");
            }
            props.store!.user.me(false).then((res: any) => {
              props.store!.user.setLoggedInUser(res);
              props.store!.shouldShowDelightedSurvey = false;
              props.store!.isFirstSurveyCompleted = true;
              props.history.push(RouteUtil.getUrl(Routes.dashboard));
              props.store!.report!.generateAqmeFullReportUsingSSE(
                res?.surveys?.[0]?.id as number,
                languageLocale,
                +props.store!.user.loggedInProfile!.id,
                props.store!.user.loggedInProfile!.sseToken,
                "aqme",
              );
            });
          };

          landbotFrameWidget.window.showLoader = function () {
            setShowLoader(true);
          };

          const total = Constants.QUESTION_IDS.length;
          const aqliteTotal = Constants.AQLITE_QUESTION_IDS.length;
          const calculateProgressOnAnswer = (
            questionArray: string[],
            questionLength: number,
          ) => {
            landbotFrameWidget.window.receiveMessage = function (
              _identifier: string,
            ) {
              let index = null;
              console.log("ReceiveMessage [called for]: ", _identifier);
              if (!props.store!.user.loggedInProfile!.hasIncompleteSurveys) {
                props.store!.user.loggedInProfile!.hasIncompleteSurveys = true;
              }
              questionArray.forEach((identifier, i) => {
                if (identifier === _identifier) {
                  index = i + 1;
                }
              });
              if (index) {
                console.log("ReceiveMessage [index]:", index);
                setProgress((index / questionLength) * 100);
              }
              authService.refreshToken();
            };
          };

          if (location.pathname === "/aqlite") {
            calculateProgressOnAnswer(
              Constants.AQLITE_QUESTION_IDS,
              aqliteTotal,
            );
          } else {
            calculateProgressOnAnswer(Constants.QUESTION_IDS, total);
          }
        });
      }
    } else {
      console.log("Landbot Token at frontend", user.landbot_token);
      const url = `${landbotUrl}?full_name=${user.first_name}&landbot_token=${
        user.landbot_token
      }&org_name=${
        orgName ? orgName : organization.name
      }&has_upgraded_from_lite=${skipLiteQuestions}`;
      console.log("Index Url: ", url);
      // @ts-ignore
      const landbotFrameWidget = new window.LandbotFrameWidget({
        container: "#myLandbot",
        index: url,
        customData: {
          landbot_token: user.landbot_token,
          org_name: orgName ? orgName : organization.name,
          has_upgraded_from_lite: `${skipLiteQuestions}`, // set to true if user upgraded their plan and doing first survey else false.
        },
      });

      landbotFrameWidget.on("landbot-load", () => {
        landbotFrameWidget.send("containerVariables", {
          landbot_token: user.landbot_token,
          org_name: orgName ? orgName : organization.name,
          has_upgraded_from_lite: `${skipLiteQuestions}`,
        });
        landbotFrameWidget.on("onComplete", () => {
          if ((window as any).Intercom) {
            (window as any).Intercom("track-event", "assessment-completed");
          }

          props.store!.user.me(false).then((res: any) => {
            props.store!.user.setLoggedInUser(res);
            props.store!.shouldShowDelightedSurvey = false;
            props.store!.isFirstSurveyCompleted = true;
            props.history.push(RouteUtil.getUrl(Routes.dashboard));
          });
        });

        const total = Constants.QUESTION_IDS.length;
        const aqliteTotal = Constants.AQLITE_QUESTION_IDS.length;
        const calculateProgressOnAnswer = (
          questionArray: string[],
          questionLength: number,
        ) => {
          landbotFrameWidget.on("onAnswer", (_identifier: string) => {
            console.log("onAnswer", _identifier);
            let index = null;
            questionArray.forEach((identifier, i) => {
              if (identifier === _identifier) {
                index = i + 1;
              }
            });
            console.log("onAnswer [index]", index);
            if (index) {
              setProgress((index / questionLength) * 100);
            }
          });
        };

        if (location.pathname === "/aqlite") {
          calculateProgressOnAnswer(Constants.AQLITE_QUESTION_IDS, aqliteTotal);
        } else {
          calculateProgressOnAnswer(Constants.QUESTION_IDS, total);
        }
      });
    }
  }, [botStore.convertedQuestions, isProfileUpdated]);

  useEffect(() => {
    props.store!.setOrganization(
      props.store!.user.loggedInProfile!.organizations.filter(
        (org) => !org.parent_organization_id,
      ).length
        ? props.store!.user.loggedInProfile!.organizations.filter(
            (org) => !org.parent_organization_id,
          )[0]
        : props.store!.user.loggedInProfile!.organizations.sort((a, b) => {
            return new Date(a.linkedToOrgAt) > new Date(b.linkedToOrgAt)
              ? 1
              : -1;
          })[0],
    );
  }, []);

  const _skipAssessment = () => {
    confirm({
      title: "Are you sure you want to skip assessment?",
      content:
        "If you skip the assessment, you won't be able to view your AQme results.",
      okText: "Yes",
      cancelText: "No",
      onOk() {
        props.store!.user.skipAssessment().then((user: any) => {
          props.store!.user.setLoggedInUser(user);
          props.history.push(RouteUtil.getUrl(Routes.dashboard));
        });
      },
      onCancel() {
        console.log("onCancel function is empty");
      },
    });
  };

  const onClick = ({ key }: any) => {
    authService.logout();
  };

  const menu = (
    <Menu onClick={onClick}>
      <Menu.Item key="logout">Logout</Menu.Item>
    </Menu>
  );

  const botContainer = () => {
    return (
      <div className="bot">
        {role === Role.OWNER || role === Role.PARTNER ? (
          <div className="skip-survey-button">
            <Button size="large" onClick={_skipAssessment}>
              Skip Assessment
            </Button>
          </div>
        ) : (
          ""
        )}
        {localStorage.getItem("mutated_user_token") ? (
          <div className="stop-sharing-button">
            <ADSimpleButton
              label={returnToMainAccText.title[languageLocale]}
              onClick={() => {
                localStorage.removeItem("mutated_user_token");
                // To Set the selected org Id Back To CdpCorporateParentOrgId to avoid user Not belong to org error.
                if (localStorage.getItem("cdp_corporate_parent_org_id")) {
                  props.store!.setSelectedOrganization(
                    parseInt(
                      localStorage.getItem("cdp_corporate_parent_org_id") || "",
                    ),
                  );
                }
                props.history.push("/");
                window.location.reload();
              }}
            />
          </div>
        ) : (
          ""
        )}
        <Dropdown className="dropDown" overlay={menu} placement="bottomCenter">
          <Button>
            {commonBotContainer.hi}, {user.first_name} {user.last_name}
            <Icon type="down" />
          </Button>
        </Dropdown>

        {isClassicBotAllow && (
          <Link
            to={RouteUtil.getUrl(Routes.assessment)}
            className="switch-link"
          >
            Switch to classic bot
          </Link>
        )}

        <div id="myLandbot" className="landbot-container" />
        {console.log(progress, "below progress")}
        {progress > 0 ? (
          <div className="progress progress-striped">
            <div style={{ width: progress + "%" }} className="progress-bar" />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };

  const exhaustMessage = () => {
    return (
      <div className="bot">
        <Col md={{ span: 12, offset: 6 }}>
          <Card
            style={{ textAlign: "center", marginTop: "4rem" }}
            title="Buy Assessment"
          >
            {exhaustMessageText}
          </Card>
        </Col>
      </div>
    );
  };

  const displayChatBot = () => {
    if (hasIncompleteSurveys) {
      return botContainer();
    } else {
      if (userSurveyCount === 0) {
        if (remainingSurveys >= 1) {
          return botContainer();
        } else {
          return exhaustMessage();
        }
      } else {
        if (
          userSurveyCount > 0 &&
          !!isRetakeAllowed &&
          remainingSurveys >= 0.5
        ) {
          return botContainer();
        } else {
          return exhaustMessage();
        }
      }
    }
  };

  return (
    <>
      <CustomModal
        modalStyle={{
          width: "800px",
          height: "90%",
          overflowY: "auto",
        }}
        open={showProfileUpdateModal}
        onOutsideClick={() => null}
      >
        <ProfilePopUp
          onSubmit={() => {
            setShowProfileModal(false);
            setIsProFileUpdated(true);
          }}
        />
      </CustomModal>
      <SwitchLanguage />
      {displayChatBot()}
      {showLoader ? <CenterLoader /> : ""}
    </>
  );
};

export default withRouter(inject("store")(observer(CommonBot)));
