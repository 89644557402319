import React from "react";

interface Props {
  children: React.ReactElement;
}

const BgImageWrapper: React.FC<Props> = ({ children }) => {
  return (
    <div className="h-screen w-full p-12 relative   bg-brandGrey3">
      <img
        className="absolute top-0 left-0 w-36 h-48"
        src={require("../../assets/auth/bgFragment3.png")?.default}
        alt="promotional-image"
      />
      <img
        className="absolute bottom-0 left-0 w-72 h-72"
        src={require("../../assets/auth/bgFragment2.png")?.default}
        alt="promotional-image"
      />
      <img
        className="absolute bottom-0 right-0 w-44 h-36"
        src={require("../../assets/auth/bgFragment1.png")?.default}
        alt="promotional-image"
      />

      {children}
    </div>
  );
};

export default BgImageWrapper;
