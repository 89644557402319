import { action, computed, observable, runInAction } from "mobx";
import Store from "./Store";
import { MilestoneFormData, MilestoneModel } from "../models/MilestoneModel";
import { milestonesService } from "../services/MilestonesService";
import { promotionalImagesService } from "../services/PromotionalImagesService";
import { apiService } from "../services/ApiService";

export class MilestoneStore extends Store<MilestoneModel> {
  private static _instance: MilestoneStore;

  @observable isLoading = false;
  @observable isLoaded = false;

  constructor() {
    super();
    MilestoneModel._store = this;
  }

  @computed
  get mileStones(): MilestoneModel[] {
    return [...this.entities];
  }

  static getInstance(): MilestoneStore {
    if (!this._instance) {
      this._instance = new MilestoneStore();
    }

    return this._instance;
  }

  @action
  async fetchMilestones() {
    try {
      this.isLoading = true;

      const response = await milestonesService.getAll();
      runInAction(() => {
        response.map((task) => MilestoneModel.fromJson(task));
        this.isLoaded = true;
      });
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
      throw e;
    }
  }

  @action
  async addMilestone(data: MilestoneFormData): Promise<void> {
    this.isLoading = true;
    try {
      const response = await milestonesService.add({
        ...data,
      });
      runInAction(() => {
        console.log("response", response);
        MilestoneModel.fromJson(response);
        console.log("response2", this.entities);
      });
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
      throw e;
    }
  }

  @action
  async updateMilestone(id: number, data: MilestoneFormData): Promise<void> {
    try {
      this.isLoading = true;
      const response = await milestonesService.update(id, data);
      runInAction(() => {
        MilestoneModel.fromJson(response);
      });
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
      throw e;
    }
  }

  @action
  async deleteMilestone(id: number): Promise<any> {
    this.isLoading = true;
    return milestonesService
      .delete(id)
      .then(() => {
        runInAction(() => {
          this.remove(id);
        });
      })
      .catch((err) => {
        throw err;
      })
      .finally(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
  }

  @action
  async updateBulkMilestone(data: any[]) {
    try {
      this.isLoading = true;

      const response = await milestonesService.updateBulk(data);
      this.entities = [];
      runInAction(() => {
        response.map((milestone) => MilestoneModel.fromJson(milestone));
      });
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
      throw e;
    }
  }

  async uploadImageAndGetUrl(file: File, imageName: string) {
    try {
      const response = await promotionalImagesService.getPresignedUrl(
        imageName,
      );
      const url = response.url;
      await apiService.upload(url, file);
      return url.split("?")[0];
    } catch (e) {
      throw new Error("Error in uploading the file");
    }
  }
}
