import { Provider } from "mobx-react";
import React, { lazy, Suspense } from "react";
import {
  BrowserRouter,
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
} from "react-router-dom";
import AnonymousRoute from "../../guards/AnonymousRoute";
import BotProtectedRoute from "../../guards/BotProtectedRoute";
import DashboardProtectedRoute from "../../guards/DashboardProtectedRoute";
import "../../scss/theme.scss";
import { RootStore } from "../../stores/RootStore";
import Bot from "../bot/Bot";
import Loader from "../shared/loader/Loader";
import "./App.scss";
import LoginNew from "../login/Login-new";
import TagManager from "react-gtm-module";
import { RouteUtil } from "../../utils/route-utils";
import { Routes } from "../../routes";
import BotLite from "../bot/Bot-Lite";
import AdminCdpCorporateProtectedRoute from "../../guards/AdminCdpCorporateProtectedRoute";
import CustomLinkedInCallBack from "../CustomLinkedCallBack/CustomLinkedInCallBack";
import { useMediaQuery } from "../../utils/useMediaQuery";
import MobileView from "../MobileView";

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID || "",
};
TagManager.initialize(tagManagerArgs);
// Lazy Loading
const NoMatchFound = lazy(() => import("../no-route-match/NoMatchFound"));
const DashboardNew = lazy(() => import("../dashboard/DashboardNew"));
const RegisterNew = lazy(() => import("../register/RegisterNew"));
const OnBoardingTasksList = lazy(
  () => import("../onboarding-tasks/OnBoardingTasksList"),
);
const OnboardingTasksDescription = lazy(
  () => import("../onboarding-tasks/OnboardingTasksDescription"),
);
const AdminDashboard = lazy(() => import("../admin-new/AdminDashboard"));
const ResetPassword = lazy(() => import("../reset-password/ResetPasswordNew"));
const ForgotPassword = lazy(
  () => import("../forgot-password/ForgotPasswordNew"),
);
const UpgradeUsingAqCredits = lazy(
  () => import("../aqlite-upgrades/upgrade-using-aq-credits"),
);
const UpgradeUsingPaidInvite = lazy(
  () => import("../aqlite-upgrades/upgrade-using-paid-invite"),
);
const Signup = lazy(() => import("../signup/Signup"));
const updateProfileForm = lazy(
  () => import("../signup/signup-form/update-profile-form/UpdateProfileForm"),
);
const Assessment = lazy(() => import("../assessment/Assessment"));

export type StoreProps = {
  store?: RootStore;
};

export interface GlobalProps extends RouteComponentProps, StoreProps {
  //
}

const App: React.FC = () => {
  const isDesktop = useMediaQuery("(min-width: 768px)");

  const isLinkedInRoute = window.location.pathname === "/linkedin";

  if (!isDesktop && !isLinkedInRoute) {
    return <MobileView />;
  }

  return (
    <div className="app">
      <Provider store={RootStore.getInstance()}>
        <BrowserRouter>
          <Suspense fallback={<Loader />}>
            <Switch>
              <AnonymousRoute
                exact
                path={RouteUtil.getUrl(Routes.login)}
                component={LoginNew}
              />
              <AnonymousRoute
                exact
                path={RouteUtil.getUrl(Routes.affiliateKey, {
                  affiliateKey: "signup",
                })}
                isCheckSettings={true}
              >
                <Redirect
                  to={RouteUtil.getUrl(Routes.individualSignup, {
                    affiliateKey: "signup",
                  })}
                />
              </AnonymousRoute>

              <AnonymousRoute
                exact
                path={RouteUtil.getUrl(Routes.teamRegister)}
                component={RegisterNew}
                isTeamInvitation={true}
                isCheckSettings={true}
              />
              <AnonymousRoute
                exact
                path={RouteUtil.getUrl(Routes.register)}
                component={RegisterNew}
                isCheckSettings={true}
              />
              <AnonymousRoute
                exact
                path={RouteUtil.getUrl(Routes.individualSignup)}
                component={RegisterNew}
                isIndividualSignup={true}
                isCheckSettings={true}
              />
              <AnonymousRoute
                exact
                path={RouteUtil.getUrl(Routes.teamSignup)}
                isIndividualTeamSignup={true}
                component={RegisterNew}
                isCheckSettings={true}
              />

              <DashboardProtectedRoute
                exact
                path={RouteUtil.getUrl(Routes.assessment)}
                component={Assessment}
              />

              <DashboardProtectedRoute
                exact
                path={RouteUtil.getUrl(Routes.onBoardingTasks)}
                component={OnBoardingTasksList}
              />
              <DashboardProtectedRoute
                exact
                path={RouteUtil.getUrl(Routes.onBoardingTasksDescription)}
                component={OnboardingTasksDescription}
              />
              <AnonymousRoute
                exact
                path={RouteUtil.getUrl(Routes.forgotPassword)}
                component={ForgotPassword}
                isCheckSettings={true}
              />
              <Route
                exact
                path={RouteUtil.getUrl(Routes.resetPassword)}
                component={ResetPassword}
              />
              <DashboardProtectedRoute
                exact
                path={RouteUtil.getUrl(Routes.aqCredits)}
                component={UpgradeUsingAqCredits}
              />
              <DashboardProtectedRoute
                exact
                path={RouteUtil.getUrl(Routes.paidInvite)}
                component={UpgradeUsingPaidInvite}
              />
              <BotProtectedRoute
                path={RouteUtil.getUrl(Routes.aqMeChatBot)}
                component={Bot}
              />
              <BotProtectedRoute
                path={RouteUtil.getUrl(Routes.aqLiteChatBot)}
                component={BotLite}
              />
              <DashboardProtectedRoute
                path={RouteUtil.getUrl(Routes.dashboard)}
                component={DashboardNew}
              />
              <AdminCdpCorporateProtectedRoute
                path={RouteUtil.getUrl(Routes.admin)}
                component={AdminDashboard}
              />
              <Route
                exact
                path="/linkedin"
                component={CustomLinkedInCallBack}
              />
              <Route component={NoMatchFound} />
            </Switch>
          </Suspense>
        </BrowserRouter>
      </Provider>
    </div>
  );
};

export default App;
