import {
  AnswerInput,
  QuestionBackendResponse,
} from "../stores/AssessmentStore";
import { apiService } from "./ApiService";

class AssessmentService {
  static getInstance(): AssessmentService {
    return new AssessmentService();
  }

  async getQuestion(sequenceId?: number): Promise<QuestionBackendResponse> {
    const response = await apiService.get<QuestionBackendResponse>(
      `/chatbot/question/?sequenceId=${sequenceId ?? 1}`,
      true,
      false,
    );

    return response;
  }

  async postAnswer(input: AnswerInput): Promise<any> {
    const response = await apiService.post<any>(
      "/chatbot/answers",
      true,
      false,
      input,
    );

    return response;
  }

  async completeSurvey(orgId: number, locale: string): Promise<string> {
    const response = await apiService.post<string>(
      "/chatbot/surveys/complete",
      true,
      false,
      {
        org_id: orgId,
        locale,
      },
    );

    return response;
  }
}

export const assessmentService = AssessmentService.getInstance();
