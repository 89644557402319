import * as axios from "axios";
import { AxiosInstance } from "axios";
import { ErrorCode } from "../enums/error-code.enum";

class ApiService {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = axios.default.create();
  }

  static getInstance(): ApiService {
    return new ApiService();
  }

  get<T>(
    uri: string,
    useAuthHeaders: boolean,
    useMutationToken: boolean,
    queryParams?: { [key: string]: any },
    responseType?: any,
    usePublicAuthToken?: boolean,
  ): Promise<T> {
    return this.axiosInstance
      .get(this._fullyQualifiedUri(uri), {
        params: queryParams,
        ...this._getHttpHeaders(
          useAuthHeaders,
          useMutationToken,
          usePublicAuthToken,
        ),
        responseType: responseType || "json",
        withCredentials: true,
      })
      .then((response) => response.data)
      .catch(this.handleError);
  }

  post<T>(
    uri: string,
    useAuthHeaders: boolean,
    useMutationToken: boolean,
    data?: any,
    responseType?: any,
    usePublicAuthToken?: boolean,
  ): Promise<T> {
    return this.axiosInstance
      .post(this._fullyQualifiedUri(uri), data, {
        ...this._getHttpHeaders(
          useAuthHeaders,
          useMutationToken,
          usePublicAuthToken,
        ),
        responseType: responseType || "json",
        withCredentials: true,
      })
      .then((response) => response.data)
      .catch(this.handleError);
  }

  put<T>(
    uri: string,
    useAuthHeaders: boolean,
    useMutationToken: boolean,
    data: any,
    usePublicAuthToken?: boolean,
  ): Promise<T> {
    return this.axiosInstance
      .put(this._fullyQualifiedUri(uri), data, {
        ...this._getHttpHeaders(
          useAuthHeaders,
          useMutationToken,
          usePublicAuthToken,
        ),
        withCredentials: true,
      })
      .then((response) => response.data)
      .catch(this.handleError);
  }

  delete<T>(
    uri: string,
    useAuthHeaders: boolean,
    useMutationToken: boolean,
    usePublicAuthToken?: boolean,
  ): Promise<T> {
    return this.axiosInstance
      .delete(this._fullyQualifiedUri(uri), {
        ...this._getHttpHeaders(
          useAuthHeaders,
          useMutationToken,
          usePublicAuthToken,
        ),
        withCredentials: true,
      })
      .then((response) => response.data)
      .catch(this.handleError);
  }

  private _getHttpHeaders(
    useAuthHeaders: boolean,
    useMutatedToken: boolean,
    usePublicAuthToken?: boolean,
    additionalHeaders?: any,
  ) {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      ...additionalHeaders,
    };

    if (useAuthHeaders) {
      headers["Organization_id"] = localStorage.getItem("selected_org_id");
      if (useMutatedToken && localStorage.getItem("mutated_user_token")) {
        headers["Authorization"] = localStorage.getItem("mutated_user_token");
      } else if (
        usePublicAuthToken &&
        localStorage.getItem("public_auth_token")
      ) {
        headers["Authorization"] = localStorage.getItem("public_auth_token");
      } else {
        headers["Authorization"] = localStorage.getItem("auth_token");
      }
    }
    return { headers };
  }

  private _fullyQualifiedUri(uri: string) {
    return `${process.env.REACT_APP_BACKEND_URL}${uri}`;
  }

  upload<T>(uri: string, data: any): Promise<T> {
    const headers = {
      headers: {
        "Content-Type": "image/png",
      },
    };
    return this.axiosInstance
      .put(uri, data, headers)
      .then((response) => response.data)
      .catch(this.handleError);
  }

  private handleError = (err: any) => {
    if (err.response && err.response.data) {
      if (
        [ErrorCode.SESSION_EXPIRED, ErrorCode.UNUSUAL_ACTIVITY].includes(
          err.response.data.code,
        )
      ) {
        localStorage.removeItem("auth_token");
        localStorage.removeItem("mutated_user_token");
        sessionStorage.setItem("sessionError", err.response.data.message);
        window.location.reload();
      }
      throw err.response.data;
    } else {
      throw err;
    }
  };
}

export const apiService = ApiService.getInstance();
