import { Button, Card, Col, Modal } from "antd";
import { inject, observer } from "mobx-react";
import React from "react";
import "../bot/Bot.scss";
import { Role } from "../../enums/role.enum";
import { GlobalProps } from "../app/App";
import { RouteUtil } from "../../utils/route-utils";
import { Routes } from "../../routes";
import { withRouter } from "react-router";
import ADSimpleButton from "../custom/ad-simple-button/ADSimpleButton";
import { LanguagesEnumUtils } from "../../enums/languages.enum";
import { ReportMetaType } from "../../enums/report-metatypes.enum";

interface Props extends GlobalProps {
  isBotDown?: boolean;
}

const { confirm } = Modal;

const MaintenancePage: React.FC<Props> = (props: Props) => {
  const role = props.isBotDown ? props.store!.user.loggedInProfile!.role : {};
  const locale = LanguagesEnumUtils.getDimensionLanguageFromLocale(
    props.store!.currentLocale,
  );

  const returnToMainAccText =
    props.store!.advancedInsights.reportMetaData[
      ReportMetaType.RETURN_ACCOUNT
    ] || {};

  const _skipAssessment = () => {
    confirm({
      title: "Are you sure you want to skip assessment?",
      content:
        "If you skip the assessment, you won't be able to view your AQme results.",
      okText: "Yes",
      cancelText: "No",
      onOk() {
        props.store!.user.skipAssessment().then((user: any) => {
          props.store!.user.setLoggedInUser(user);
          props.history.push(RouteUtil.getUrl(Routes.dashboard));
        });
      },
      onCancel() {
        console.log("onCancel function is empty");
      },
    });
  };

  const landbotDownMessage = () => {
    return (
      <div className="bot">
        <Col md={{ span: 12, offset: 6 }}>
          <Card
            style={{ textAlign: "center", marginTop: "4rem" }}
            title={
              props.isBotDown
                ? "We'll be back soon!"
                : "We are making few upgrades to our system!"
            }
          >
            <div>
              <p>
                {props.isBotDown
                  ? `Apologies for the inconvenience but we are experiencing
                technical difficulties at the moment. Our team is working on the
                issue and we will be back online shortly.`
                  : "Our team is busy making few upgrades to the system and we will be back online shortly."}
              </p>
              <p>
                {" "}
                {`If you have any queries or concerns, please reach out to us at`}{" "}
                <a
                  target="_blank"
                  href={`mailto:support@aqai.io`}
                  rel="noopener noreferrer"
                >
                  {`support@aqai.io`}
                </a>{" "}
                {`.`}
              </p>
            </div>
          </Card>
        </Col>
        {props.isBotDown && (role === Role.OWNER || role === Role.PARTNER) ? (
          <div className="skip-survey-button">
            <Button size="large" onClick={_skipAssessment}>
              Skip Assessment
            </Button>
          </div>
        ) : (
          ""
        )}
        {localStorage.getItem("mutated_user_token") ? (
          <div className="stop-sharing-button">
            <ADSimpleButton
              label={returnToMainAccText.title[locale]}
              onClick={() => {
                localStorage.removeItem("mutated_user_token");
                if (localStorage.getItem("cdp_corporate_parent_org_id")) {
                  props.store!.setSelectedOrganization(
                    parseInt(
                      localStorage.getItem("cdp_corporate_parent_org_id") || "",
                    ),
                  );
                }
                window.location.reload();
              }}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };
  const displayChatBot = () => {
    return landbotDownMessage();
  };

  return <>{displayChatBot()}</>;
};

export default withRouter(inject("store")(observer(MaintenancePage)));
