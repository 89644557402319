import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { GlobalProps } from "../app/App";
import { inject, observer } from "mobx-react";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import { LoginState } from "../../enums/login-state.enum";
import Loader from "../shared/loader/Loader";

type Props = GlobalProps & RouteComponentProps;

const CustomLinkedInCallBack: React.FC<Props> = (props: Props) => {
  const loginState = props?.store?.user?.loginState;

  if (!loginState || loginState === LoginState.PENDING) {
    return <Loader />;
  }

  return <LinkedInCallback />;
};

export default inject("store")(withRouter(observer(CustomLinkedInCallBack)));
