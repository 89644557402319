import Model from "./Model";
import { observable } from "mobx";
import { PromotionalImagesStore } from "../stores/PromotionalImagesStore";
import { Role } from "../enums/role.enum";
import { EventsStore } from "../stores/EventsStore";

export class EventModel extends Model {
  static _store: EventsStore;

  @observable id!: number;
  @observable title!: string;
  @observable description!: string;
  @observable date!: string;
  @observable link!: string;
  @observable for_type!: [Role.PARTNER, Role.OWNER, Role.EMPLOYEE];
  @observable attendees!: number;
  @observable hasJoined!: boolean;

  getId(): string | number {
    return this.id;
  }
}

export interface EventFormData {
  title: string;
  description: string;
  date: string;
  link: string;
  for_type: [Role.PARTNER, Role.OWNER, Role.EMPLOYEE];
  attendees: number;
  hasJoined: boolean;
}
