import { apiService } from "./ApiService";
import { ShowResponse } from "../responses/show.response";
import { Survey } from "../models/Survey";

class SurveyService {
  static getInstance(): SurveyService {
    return new SurveyService();
  }

  fetchSurvey(surveyId: number): Promise<Survey> {
    return apiService
      .get<ShowResponse<Survey>>(`/surveys/${surveyId}`, true, true)
      .then((res) => res.data);
  }
}

export const surveyService = SurveyService.getInstance();
