import { ListResponse } from "../responses/list.response";
import { apiService } from "./ApiService";
import { BotModel } from "../models/BotModel";
import { Languages } from "../enums/languages.enum";
import { ShowResponse } from "../responses/show.response";

class BotService {
  static getInstance(): BotService {
    return new BotService();
  }

  async getAll(language: string): Promise<any> {
    return apiService
      .get<ShowResponse<any>>(
        `/chatbot-questions?language=${language}`,
        true,
        false,
      )
      .then((response) => response.data);
  }
}

export const botService = BotService.getInstance();
