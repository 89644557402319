import { action, computed, observable, runInAction } from "mobx";
import Store from "./Store";
import { Affiliate, AffiliateFormData } from "../models/AffiliatesModel";
import { affiliateService } from "../services/AffiliateService";
import { apiService } from "../services/ApiService";
import { message } from "antd";
import { TransactionModel } from "../models/TransactionModel";

export class AffiliatesStore extends Store<Affiliate> {
  private static _instance: AffiliatesStore;

  @observable isLoading = false;
  @observable isLoaded = false;

  constructor() {
    super();
    Affiliate._store = this;
  }

  @computed
  get affiliates(): Affiliate[] {
    return [...this.entities];
  }

  static getInstance(): AffiliatesStore {
    if (!this._instance) {
      this._instance = new AffiliatesStore();
    }

    return this._instance;
  }

  @action
  fetchAffiliates() {
    this.isLoading = true;
    affiliateService
      .getAll()
      .then((affiliates: Affiliate[]) => {
        runInAction(() => {
          affiliates.map((affiliate) => Affiliate.fromJson(affiliate));
          this.isLoaded = true;
        });
        return;
      })
      .catch((err) => console.log(err))
      .finally(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
  }

  @action
  async addAffiliate(data: AffiliateFormData, file: File) {
    const url = await this.uploadImageAndGetUrl(file, data.name);
    if (url) {
      return affiliateService
        .add({ ...data, image_url: url })
        .then((affiliate: Affiliate) => {
          runInAction(() => {
            Affiliate.fromJson(affiliate);
          });
          return;
        });
    }
  }

  private async uploadImageAndGetUrl(file: File, affiliateName: string) {
    try {
      const response = await affiliateService.getPresignedUrl(affiliateName);
      const url = response.url;
      await apiService.upload(url, file);
      return url.split("?")[0];
    } catch (error) {
      message.error("Error in uploading the file");
    }
  }

  @action
  getAffiliateTransactions(id: number) {
    return affiliateService
      .getAffiliateTransactions(id)
      .then((transactions: TransactionModel[]) => {
        runInAction(() => {
          const affiliate = this.get(id);
          if (affiliate) {
            affiliate.updateFromJson({ transactions: transactions });
          }
        });
      });
  }
}
