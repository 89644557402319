import { Question } from "../models/QuestionModel";
import { ListResponse } from "../responses/list.response";
import { apiService } from "./ApiService";

class QuestionService {
  static getInstance(): QuestionService {
    return new QuestionService();
  }

  getAll(): Promise<Question[]> {
    return apiService
      .get<ListResponse<Question>>("/questions", true, false)
      .then((response) => response.data);
  }

  getAllCompact(): Promise<Question[]> {
    return apiService
      .get<ListResponse<Question>>("/compact-questions", true, false)
      .then((response) => response.data);
  }

  syncQuestions(): Promise<any> {
    return apiService.get<ListResponse<Question>>(
      "/sync-questions",
      true,
      false,
    );
  }
}

export const questionService = QuestionService.getInstance();
