import { observable } from "mobx";
import Model from "./Model";
import { RecentSurveyCompletedActivityStore } from "../stores/RecentSurveyCompletedActivityStore";
import { User } from "./UserModel";
import { DimensionLanguages } from "../enums/languages.enum";

export class RecentSurveyCompletedActivityModel extends Model {
  static _store: RecentSurveyCompletedActivityStore;

  @observable id!: number;
  @observable user_id!: number;
  @observable is_completed!: boolean;
  @observable createdAt!: string;
  @observable updatedAt!: string;
  @observable dimension_wise_score!: any;
  @observable completed_on!: string;
  @observable report_url!: {
    [name in DimensionLanguages]: string;
  };
  @observable sessions!: number;
  @observable completion_time!: number;
  @observable is_lite!: boolean;
  @observable user!: User;
  @observable teams!: {
    __type: string;
    id: number;
    title: string;
    allow_retake: boolean;
  }[];

  getId(): string | number {
    return this.id;
  }
}
