export enum ReportMetaType {
  ABOUT = "ABOUT",
  AQ_INSIGHTS_SUMMARY = "AQ_INSIGHTS_SUMMARY",
  SMART_SIX = "SMART_SIX",
  AQ_METRICS = "AQ_METRICS",
  AQ_METRICS_TABLE = "AQ_METRICS_TABLE",
  AI_PREDICTIVE_OPTIMISATION = "AI_PREDICTIVE_OPTIMISATION",
  CONVERSATIONAL_INSIGHTS = "CONVERSATIONAL_INSIGHTS",
  HELPFUL_REFERENCES = "HELPFUL_REFERENCES",
  FEEDBACK_QUESTIONS = "FEEDBACK_QUESTIONS",
  QUERIES = "QUERIES",
  AQME_DIMENSIONS = "AQME_DIMENSIONS",
  AQAI_HEADING = "AQAI_HEADING",
  ASSESSMENT_REPORT = "ASSESSMENT_REPORT",
  COMPLETED_ON = "COMPLETED_ON",
  GLOBAL_AVERAGE = "GLOBAL_AVERAGE",
  GLOBAL_AVERAGE_POPUP = "GLOBAL_AVERAGE_POPUP",
  INSIGHTS = "INSIGHTS",
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
  EXPLORE_AND_TRANSFORM = "EXPLORE_AND_TRANSFORM",
  UTILIZE_AND_IMPROVE = "UTILIZE_AND_IMPROVE",
  WHATIS = "WHATIS",
  IMPROVEMY = "IMPROVEMY",
  DETAILS = "DETAILS",
  BIGPICTURES = "BIGPICTURES",
  PLAYTOPROTECT = "PLAYTOPROTECT",
  PLAYTOWIN = "PLAYTOWIN",
  FEARFUL = "FEARFUL",
  HOPEFUL = "HOPEFUL",
  INTROVERTED = "INTROVERTED",
  EXTROVERTED = "EXTROVERTED",
  REACTIVE = "REACTIVE",
  COLLECTED = "COLLECTED",
  NEUTRAL = "NEUTRAL",
  BALANCED = "BALANCED",
  CONNECTOR = "CONNECTOR",
  CONTEXTUAL = "CONTEXTUAL",
  UNCERTAIN = "UNCERTAIN",
  AMBIVERT = "AMBIVERT",
  AQTEAM_DIMENSIONS = "AQTEAM_DIMENSIONS",
  AQ_COACH_WARNING = "AQ_COACH_WARNING",
  CLIENT_INFORMATION = "CLIENT_INFORMATION",
  NAME = "NAME",
  GENDER = "GENDER",
  JOB_FUNCTION = "JOB_FUNCTION",
  LAST_ASSESSMENT_TAKEN = "LAST_ASSESSMENT_TAKEN",
  SESSIONS = "SESSIONS",
  ORGANIZATION = "ORGANIZATION",
  AGE_GROUP = "AGE_GROUP",
  JOB_LEVEL = "JOB_LEVEL",
  TIME_TO_COMPLETE = "TIME_TO_COMPLETE",
  OTHER_ASSESSMENTS_TAKEN = "OTHER_ASSESSMENTS_TAKEN",
  AQ_OVERVIEW = "AQ_OVERVIEW",
  STRONG_FOUR = "STRONG_FOUR",
  BE_AWARE_TWO = "BE_AWARE_TWO",
  USEFUL_FEEDBACK_QUESTIONS = "USEFUL_FEEDBACK_QUESTIONS",
  OWN_NOTES = "OWN_NOTES",
  SUMMARY_HEADING_DIMENSION = "SUMMARY_HEADING_DIMENSION",
  SUMMARY_HEADING_SUB_DIMENSION = "SUMMARY_HEADING_SUB_DIMENSION",
  SUMMARY_HEADING_SCORE = "SUMMARY_HEADING_SCORE",
  SUMMARY_HEADING_DIMENSION_BAND = "SUMMARY_HEADING_DIMENSION_BAND",
  SUMMARY_HEADING_MEAN = "SUMMARY_HEADING_MEAN",
  STRATEGY_EXPLORE = "STRATEGY_EXPLORE",
  STRATEGY_UTILIZE = "STRATEGY_UTILIZE",
  STRUCTURE_EXPLORE = "STRUCTURE_EXPLORE",
  STRUCTURE_UTILIZE = "STRUCTURE_UTILIZE",
  CULTURE_EXPLORE = "CULTURE_EXPLORE",
  CULTURE_UTILIZE = "CULTURE_UTILIZE",
  RISK_EXPLORE = "RISK_EXPLORE",
  RISK_UTILIZE = "RISK_UTILIZE",
  GOALS_EXPLORE = "GOALS_EXPLORE",
  GOALS_UTILIZE = "GOALS_UTILIZE",
  SUCCESS_EXPLORE = "SUCCESS_EXPLORE",
  SUCCESS_UTILIZE = "SUCCESS_UTILIZE",
  COPYRIGHT = "COPYRIGHT",
  AQ = "AQ",
  FEEDBACK_QUESTION_1 = "FEEDBACK_QUESTION_1",
  FEEDBACK_QUESTION_2 = "FEEDBACK_QUESTION_2",
  FEEDBACK_QUESTION_3 = "FEEDBACK_QUESTION_3",
  FEEDBACK_QUESTION_4 = "FEEDBACK_QUESTION_4",
  FEEDBACK_QUESTION_5 = "FEEDBACK_QUESTION_5",
  FEEDBACK_QUESTION_6 = "FEEDBACK_QUESTION_6",
  FEEDBACK_QUESTION_7 = "FEEDBACK_QUESTION_7",
  FEEDBACK_QUESTION_8 = "FEEDBACK_QUESTION_8",
  FEEDBACK_QUESTION_9 = "FEEDBACK_QUESTION_9",
  FEEDBACK_QUESTION_10 = "FEEDBACK_QUESTION_10",
  FEEDBACK_QUESTION_11 = "FEEDBACK_QUESTION_11",
  FEEDBACK_QUESTION_12 = "FEEDBACK_QUESTION_12",
  AI_INDEX_HEADING = "AI_INDEX_HEADING",
  SPECIFIC_QUESTIONING = "SPECIFIC_QUESTIONING",
  CONFIDENTIAL_INFORMATION = "CONFIDENTIAL_INFORMATION",
  HELP_INTRO = "HELP_INTRO",
  HELP_RAPPORT = "HELP_RAPPORT",
  HELP_OBJECTIVE = "HELP_OBJECTIVE",
  HELP_CONFIDENTIALITY = "HELP_CONFIDENTIALITY",
  HELP_INTRO_TO_AQAI = "HELP_INTRO_TO_AQAI",
  HELP_SELF_REFLECTION = "HELP_SELF_REFLECTION",
  HELP_FEEDBACK = "HELP_FEEDBACK",
  HELP_USE_FOLLOW_UP = "HELP_USE_FOLLOW_UP",
  HELP_KEY_STRENGTH = "HELP_KEY_STRENGTH",
  HELP_PATTERN = "HELP_PATTERN",
  HELP_EXPLORE = "HELP_EXPLORE",
  HELP_CONCLUSION = "HELP_CONCLUSION",
  HELP_SUMMARIES = "HELP_SUMMARIES",
  HELP_ACCOUNTABILITY = "HELP_ACCOUNTABILITY",
  HELP_PLANNING = "HELP_PLANNING",
  DOWNLOAD_REPORT = "DOWNLOAD_REPORT",
  CONVERSATION_QUES_1 = "CONVERSATION_QUES_1",
  CONVERSATION_QUES_3 = "CONVERSATION_QUES_3",
  CONVERSATION_QUES_4 = "CONVERSATION_QUES_4",
  CONVERSATION_QUES_5 = "CONVERSATION_QUES_5",
  CONVERSATION_QUES_6 = "CONVERSATION_QUES_6",
  CONVERSATION_QUES_8 = "CONVERSATION_QUES_8",
  CONVERSATION_QUES_9 = "CONVERSATION_QUES_9",
  CONVERSATION_QUES_10 = "CONVERSATION_QUES_10",
  CONVERSATION_QUES_38 = "CONVERSATION_QUES_38",
  CONVERSATION_QUES_39 = "CONVERSATION_QUES_39",
  CONVERSATION_QUES_61 = "CONVERSATION_QUES_61",
  CONVERSATION_QUES_80 = "CONVERSATION_QUES_80",
  CONVERSATION_QUES_81 = "CONVERSATION_QUES_81",
  CONVERSATION_QUES_91 = "CONVERSATION_QUES_91",
  CONVERSATION_QUES_92 = "CONVERSATION_QUES_92",
  CONVERSATION_QUES_93 = "CONVERSATION_QUES_93",
  CONVERSATION_QUES_94 = "CONVERSATION_QUES_94",
  CONVERSATION_QUES_95 = "CONVERSATION_QUES_95",
  CONVERSATION_QUES_106 = "CONVERSATION_QUES_106",
  CONVERSATION_QUES_55 = "CONVERSATION_QUES_55",
  DOWNLOAD_FULL = "DOWNLOAD_FULL",
  DOWNLOAD_SHORT = "DOWNLOAD_SHORT",
  SCORE_CALCULATOR = "SCORE_CALCULATOR",
  WHAT_WE_ASKED = "WHAT_WE_ASKED",
  THEIR_RESPONSES = "THEIR_RESPONSES",
  AQME_PROFILE_DESCRIPTION = "AQME_PROFILE_DESCRIPTION",
  AQTEAM_PROFILE_DESCRIPTION = "AQTEAM_PROFILE_DESCRIPTION",
  AQTEAM_LEVEL_DESCRIPTION = "AQTEAM_LEVEL_DESCRIPTION",
  INDEX = "INDEX",
  RESET = "RESET",
  SUBMIT = "SUBMIT",
  FILTERS = "FILTERS",
  TEAMS = "TEAMS",
  JOB_TYPE = "JOB_TYPE",
  WORK_TENURE = "WORK_TENURE",
  PARTNERSCORE = "PARTNERSCORE",
  CHAMPIONSCORE = "CHAMPIONSCORE",
  CHANGE_LANGUAGE = "CHANGE_LANGUAGE",
  ENVIRONMENT_DISTRIBUTION = "ENVIRONMENT_DISTRIBUTION",
  RETURN_ACCOUNT = "RETURN_ACCOUNT",
  COMPLETE_ASSESSMENT = "COMPLETE_ASSESSMENT",
  RETAKE_ASSESSMENT = "RETAKE_ASSESSMENT",
  SELECT_ALL = "SELECT_ALL",
  SEARCH = "SEARCH",
  ALL = "ALL",
  UNSELECT_ALL = "UNSELECT_ALL",
  CLOSE = "CLOSE",
  CONFIDENTIALITY_NOTICE = "CONFIDENTIALITY_NOTICE",
  DEAR = "DEAR",
  INTRODUCTION_TO_ADAPTABILITY_AND_AQ = "INTRODUCTION_TO_ADAPTABILITY_AND_AQ",
  TABLE_OF_CONTENTS = "TABLE_OF_CONTENTS",
  EXPLAINING_THE_AQ_MODEL1 = "EXPLAINING_THE_AQ_MODEL1",
  EXPLAINING_THE_AQ_MODEL2 = "EXPLAINING_THE_AQ_MODEL2",
  HOW_TO_USE_YOUR_REPORT = "HOW_TO_USE_YOUR_REPORT",
  AQ_DEVELOPMENT_PLAN_AND_MEASURING_PROGRESS = "AQ_DEVELOPMENT_PLAN_AND_MEASURING_PROGRESS",
  NOTES_AND_REFLECTIONS = "NOTES_AND_REFLECTIONS",
  WHAT_DOES_IT_MEAN = "WHAT_DOES_IT_MEAN",
  INTERPRETING_YOUR_SCORE = "INTERPRETING_YOUR_SCORE",
  HOW_DOES_IT_IMPACT = "HOW_DOES_IT_IMPACT",
  STRATEGIES_FOR_ACTION = "STRATEGIES_FOR_ACTION",
  AQ_SCORE_BREAKDOWN = "AQ_SCORE_BREAKDOWN",
  AQ_PROFILE_SNAPSHOT = "AQ_PROFILE_SNAPSHOT",
  YOUR_AQ_SCORE = "YOUR_AQ_SCORE",
  CONTACT_US = "CONTACT_US",
  TRUSTED_BY = "TRUSTED_BY",
  AQAI_ADAPTILITY_ASSESSMENTS = "AQAI_ADAPTILITY_ASSESSMENTS",
  AQME_REPORT = "AQME_REPORT",
  YOU_HAVE_QUESTIONS_OR_WANT_TO_LEARN_MORE = "YOU_HAVE_QUESTIONS_OR_WANT_TO_LEARN_MORE",
  ASSESSMENT_COMPLETED = "ASSESSMENT_COMPLETED",
  GENERATED = "GENERATED",
  CONTENT_AND_STRUCTURE = "CONTENT_AND_STRUCTURE",
  BAND_DIFFERENCE_TIP = "BAND_DIFFERENCE_TIP",
  SCORE_DIFFERENCE_TIP_BETTER = "SCORE_DIFFERENCE_TIP_BETTER",
  SCORE_DIFFERENCE_TIP_WORSE = "SCORE_DIFFERENCE_TIP_WORSE",
  SCORE_DIFFERENCE_TIP_SAME = "SCORE_DIFFERENCE_TIP_SAME",
  OVERVIEW_INTRODUCTION = "OVERVIEW_INTRODUCTION",
  ADD_STARTEGIES_BUTTON = "ADD_STARTEGIES_BUTTON",
  REMOVE_STARTEGIES_BUTTON = "REMOVE_STARTEGIES_BUTTON",
  DIMENSION_GRAPHS = "DIMENSION_GRAPHS",
  HOW_ADAPT = "HOW_ADAPT",
  WHO_WHY_ADAPT = "WHO_WHY_ADAPT",
  WHEN_ADAPT = "WHEN_ADAPT",
  NEXT_PAGE = "NEXT_PAGE",
  PREVIOUS_PAGE = "PREVIOUS_PAGE",
  PROGRESS_DISCLAIMER = "PROGRESS_DISCLAIMER",
  ASSESSMENT_DATE = "ASSESSMENT_DATE",
  AQTEAM_REGARDS = "AQTEAM_REGARDS",
  SHARE_REPORT = "SHARE_REPORT",
  CURRENT_SCORE = "CURRENT_SCORE",
  AQME_DASHBOARD_HEADER = "AQME_DASHBOARD_HEADER",
  READ_MORE = "READ_MORE",
  HOW_IT_WORKS = "HOW_IT_WORKS",
  COMPLETE_ASSESSMENT_POPUP = "COMPLETE_ASSESSMENT_POPUP",
  ADVANCED_REPORT_HEADING = "ADVANCED_REPORT_HEADING",
  CONTENT_AND_STRUCTURE_ADVANCE_INSIGHTS = "CONTENT_AND_STRUCTURE_ADVANCE_INSIGHTS",
  INTRODUCTION_ADVANCE_INSIGHT_REPORT = "INTRODUCTION_ADVANCE_INSIGHT_REPORT",
  SCORE_CHANGE_OVERTIME = "SCORE_CHANGE_OVERTIME",
  ADVANCE_INSIGHT_REPORT_HOW_TO_USE_YOUR_REPORT = "ADVANCE_INSIGHT_REPORT_HOW_TO_USE_YOUR_REPORT",
  AQ_PROFILE_SNAPSHOT_ADVANCE_INSIGHT_REPORT = "AQ_PROFILE_SNAPSHOT_ADVANCE_INSIGHT_REPORT",
  INFO_PREDECTIVE = "INFO_PREDECTIVE",
  AQ_TEAM_COVER_PAGE = "AQ_TEAM_COVER_PAGE",
  AQ_TEAM_DISTRIBUTION_PAGE = "AQ_TEAM_DISTRIBUTION_PAGE",
  CUSTOMER_SERVICE_PAGE = "CUSTOMER_SERVICE_PAGE",
  CONTENT_AND_STRUCTURE_TEAM_REPORT = "CONTENT_AND_STRUCTURE_TEAM_REPORT",
  INTRODUCTION_TEAM_REPORT = "INTRODUCTION_TEAM_REPORT",
  HOW_TO_USE_YOUR_REPORT_TEAM_REPORT = "HOW_TO_USE_YOUR_REPORT_TEAM_REPORT",
  AQ_PROFILE_SNAPSHOT_TEAM_REPORT = "AQ_PROFILE_SNAPSHOT_TEAM_REPORT",
  NO_OF_ASSESSMENTS_TAKEN = "NO_OF_ASSESSMENTS_TAKEN",
  ASSESSMENT_START_DATE = "ASSESSMENT_START_DATE",
  ASSESSMENT_END_DATE = "ASSESSMENT_END_DATE",
  REPORT_CONFIGURATION = "REPORT_CONFIGURATION",
  AQME_SUMMARY_PAGE = "AQME_SUMMARY_PAGE",
  AQTEAM_SUMMARY_PAGE = "AQTEAM_SUMMARY_PAGE",
}
