import Model from "./Model";
import { observable } from "mobx";
import { BotStore } from "../stores/BotStore";

export class BotModel extends Model {
  static _store: BotStore;

  @observable welcome!: string;
  @observable options!: string;
  @observable aida_pre_text_1!: string;
  @observable aida_interlude_1!: string;
  @observable aida_interlude_1q!: string;
  @observable aida_interlude_1r!: string;
  @observable aida_interlude_3!: string;
  @observable aida_interlude_1a!: string;
  @observable aida_interlude_1d!: string;
  @observable aida_interlude_1h!: string;
  @observable aida_interlude_1l!: string;
  @observable aida_a_flex_exp1!: string;
  @observable aida_a_flex_exp2!: string;
  @observable aida_a_flex_exp3!: string;
  @observable aida_a_flex_exp4!: string;
  @observable aida_a_flex_exp5!: string;
  @observable aida_a_flex_ext1!: string;
  @observable aida_a_flex_ext2!: string;
  @observable aida_a_flex_ext3!: string;
  @observable aida_a_flex_ext4!: string;
  @observable aida_a_flex_ext5!: string;
  @observable aida_a_res1!: string;
  @observable aida_a_res2_r!: string;
  @observable aida_a_res3_r!: string;
  @observable aida_a_res4!: string;
  @observable aida_a_lea_ul_1_r!: string;
  @observable aida_a_lea_ul_2!: string;
  @observable aida_a_lea_ul_3!: string;
  @observable aida_a_lea_para1!: string;
  @observable aida_a_lea_para2!: string;
  @observable aida_a_lea_para3!: string;
  @observable aida_a_lea_para4!: string;
  @observable aida_a_lea_para5!: string;
  @observable aida_a_lea_para6!: string;
  @observable aida_a_lea_para7!: string;
  @observable aida_a_lea_para8!: string;
  @observable aida_a_lea_para9!: string;
  @observable aida_interlude_4!: string;
  @observable aida_interlude_4b!: string;
  @observable aida_c_opt1!: string;
  @observable aida_c_opt2_f!: string;
  @observable aida_c_opt3_r!: string;
  @observable aida_c_opt4!: string;
  @observable aida_c_opt5_f!: string;
  @observable aida_c_opt6_f!: string;
  @observable aida_c_opt7_r!: string;
  @observable aida_c_opt8_f!: string;
  @observable aida_c_opt9_r!: string;
  @observable aida_c_opt10!: string;
  @observable aida_a_grit_pr1!: string;
  @observable aida_a_grit_pr2!: string;
  @observable aida_a_grit_pr3!: string;
  @observable aida_a_grit_pr4!: string;
  @observable aida_a_grit_pr5!: string;
  @observable aida_interlude_7!: string;
  @observable aida_c_per_e1_r!: string;
  @observable aida_c_per_e2!: string;
  @observable aida_c_per_n1_r!: string;
  @observable aida_c_per_n2!: string;
  @observable aida_c_per_post1!: string;
  @observable aida_interlude_8!: string;
  @observable aida_c_mot_pre1!: string;
  @observable aida_c_mot_pre2!: string;
  @observable aida_c_mot_pre3!: string;
  @observable aida_c_mot_pre4!: string;
  @observable aida_c_mot_pro1!: string;
  @observable aida_c_mot_pro2!: string;
  @observable aida_c_mot_pro3!: string;
  @observable aida_c_mot_pro4!: string;
  @observable aida_c_think1!: string;
  @observable aida_c_think2!: string;
  @observable aida_c_think3!: string;
  @observable aida_c_hopep1!: string;
  @observable aida_c_hopea1!: string;
  @observable aida_c_hopep2!: string;
  @observable aida_c_hopea2!: string;
  @observable aida_c_hopep3!: string;
  @observable aida_c_hopea3!: string;
  @observable aida_e_comm_txt1!: string;
  @observable aida_e_comm_txt2!: string;
  @observable aida_e_comm_txt3!: string;
  @observable aida_e_comm_cm1!: string;
  @observable aida_e_comm_cm4!: string;
  @observable aida_e_comm_cm5_r!: string;
  @observable aida_e_comm_cm_6!: string;
  @observable aida_e_comm_tm1!: string;
  @observable aida_e_comm_tm2_r!: string;
  @observable aida_e_comm_tm3!: string;
  @observable aida_e_comm_tm4_r!: string;
  @observable aida_e_comm_tm5_r!: string;
  @observable aida_e_en_place1!: string;
  @observable aida_e_en_place2!: string;
  @observable aida_e_en_place3!: string;
  @observable aida_e_en_re_1!: string;
  @observable aida_e_en_re_2!: string;
  @observable aida_e_well_ex1!: string;
  @observable aida_e_well_ex2!: string;
  @observable aida_e_well_ex3!: string;
  @observable aida_e_well_ex4!: string;
  @observable aida_e_well_wrk1!: string;
  @observable aida_e_well_wrk2!: string;
  @observable aida_e_well_wrk3!: string;
  @observable aida_e_ch1!: string;
  @observable aida_e_ch2!: string;
  @observable aida_e_ch3!: string;
  @observable aida_e_ch_post1!: string;
  @observable aida_others_adap1!: string;
  @observable aida_others_adap2!: string;
  @observable aida_others_adap3!: string;
  @observable aida_e_post1!: string;
  @observable aida_a_lea_ul_5!: string;
  @observable aida_a_lea_ul_6_r!: string;
  @observable aida_e_en_cult1!: string;
  @observable aida_e_en_cult2!: string;
  @observable aida_e_en_cult3_r!: string;
  @observable aida_e_en_cult4!: string;
  @observable aida_e_en_cult5!: string;
  @observable aida_e_en_cult6_r!: string;
  @observable aida_e_en_cult7!: string;
  @observable aida_a_lea_ul_3_r!: string;
  @observable recommend!: string;
  @observable message_1!: string;
  @observable message_2!: string;
  @observable message_3!: string;
  @observable message_4!: string;
  @observable message_5!: string;
  @observable message_6!: string;
  @observable message_7!: string;
  @observable message_8!: string;
  @observable message_9!: string;
  @observable message_10!: string;
  @observable message_11!: string;
  @observable message_12!: string;
  @observable message_13!: string;
  @observable message_14!: string;
  @observable message_15!: string;
  @observable message_16!: string;
  @observable message_17!: string;
  @observable message_18!: string;

  getId(): string | number {
    return this.id;
  }
}
