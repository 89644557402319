import { observable } from "mobx";
import { AqScoreEnum } from "../enums/aq-score.enum";
import { Role } from "../enums/role.enum";
import { Visibility } from "../enums/visibility.enum";
import Model from "./Model";
import { UserPlans } from "../enums/userPlan.enum";
import { AqliteUpgradeStatus } from "../enums/aqlite-upgrade-invite.enum";
import { AqCoachReportStore } from "../stores/AqCoachReportStore";
import { OrganizationName } from "./OrganizationNameModel";
import { Survey } from "./Survey";
import { DimensionLanguages } from "../enums/languages.enum";

export class AqCoachReportModal extends Model {
  static _store: AqCoachReportStore;

  @observable id!: number;
  @observable first_name!: string;
  @observable last_name!: string;
  @observable email!: string;
  @observable role!: Role;
  @observable last_active!: string;
  @observable teams!: { id: number; title: string }[];
  @observable compactOrganizations!: OrganizationName;
  @observable aqScore!: {
    status: AqScoreEnum;
    score?: number;
    completed_on?: string;
  };
  @observable createdAt!: Date;
  @observable visibility!: Visibility;
  @observable survey!: any;
  @observable plan!: UserPlans;
  @observable upgradeStatus!: AqliteUpgradeStatus;
  @observable latestSurveyWithoutIncludes!: Survey;
  @observable coachOnlyReportUrl!: {
    [name in DimensionLanguages]: string;
  };

  getId(): string | number {
    return this.id;
  }
}
