import React from "react";
import CommonBot from "./CommonBot";
import { Languages } from "../../enums/languages.enum";
import { StoreProps } from "../app/App";
import { observer, inject } from "mobx-react";

interface Props extends StoreProps {}

const BotLite: React.FC<Props> = (props: Props) => {
  let chatBotUrl;
  const [locale, setlocale] = React.useState(props.store!.currentLocale);

  React.useEffect(() => {
    // to prevent initial reload
    if (locale !== props.store!.currentLocale) {
      window.location.reload();
    }
  }, [props.store!.currentLocale]);

  switch (locale) {
    case Languages.ENGLISH:
      chatBotUrl = process.env.REACT_APP_CHATBOT_LITE_URL!;
      break;

    case Languages.FRENCH:
      chatBotUrl = process.env.REACT_APP_CHATBOT_LITE_URL!;
      break;

    case Languages.ITALIAN:
      chatBotUrl = process.env.REACT_APP_CHATBOT_LITE_URL!;
      break;

    case Languages.SPANISH:
      chatBotUrl = process.env.REACT_APP_CHATBOT_LITE_URL!;
      break;

    default:
      chatBotUrl = process.env.REACT_APP_CHATBOT_LITE_URL!;
      break;
  }

  return <CommonBot chatBotUrl={chatBotUrl} />;
};

export default inject("store")(observer(BotLite));
