import { observable } from "mobx";
import Model from "./Model";
import { AssessmentInviteAuditStore } from "../stores/AssessmentInviteAuditStore";
import { InviteType } from "../enums/invite-type.enum";
import { AssessmentStatus } from "../enums/assessment-invite.enum";
import { Role } from "../enums/role.enum";

interface User {
  first_name: string;
  last_name: string;
  email: string;
  role: Role;
}

interface Organization {
  name: string;
  display_name: string;
}

interface Team {
  title: string;
}

interface Survey {
  createdAt: string;
  completed_on: string;
}

export class AssessmentInviteModel extends Model {
  static _store: AssessmentInviteAuditStore;

  @observable first_name!: string;
  @observable last_name!: string;
  @observable email!: string;
  @observable status!: AssessmentStatus;
  @observable createdAt!: Date;
  @observable credits_consumed = 0;

  @observable user: User | null = null;
  @observable inviter: User | null = null;
  @observable organization: Organization | null = null;
  @observable team: Team | null = null;
  @observable survey: Survey | null = null;
  @observable invite_type: InviteType | null = null;

  getId(): string | number {
    return this.id;
  }
}
