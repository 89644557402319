import React from "react";
import { Tooltip } from "antd";
import { TooltipPlacement, TooltipTrigger } from "antd/lib/tooltip";

export interface Props {
  visible?: boolean;
  setIsVisible?: (visible: boolean) => void;
  text: any;
  children: any;
  placement?: TooltipPlacement;
  trigger?: TooltipTrigger;
}

export const BrandToolTip: React.FC<Props> = ({
  visible,
  setIsVisible,
  text,
  children,
  placement,
  trigger,
}) => {
  return (
    <Tooltip
      title={text}
      placement={placement ? placement : "topLeft"}
      trigger={trigger ? trigger : "hover"}
      {...(visible !== undefined ? { visible } : {})}
      onVisibleChange={
        setIsVisible ? (visible) => setIsVisible(visible) : undefined
      }
    >
      {children}
    </Tooltip>
  );
};

export default React.memo(BrandToolTip);
