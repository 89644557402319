import { observable } from "mobx";
import Model from "./Model";
import { NotifyAssessmentCompletionsStore } from "../stores/NotifyAssessmentCompletionsStore";

export class NotifyAssessmentCompletionsModel extends Model {
  static _store: NotifyAssessmentCompletionsStore;

  @observable userIds: number[] = [];
  @observable teamIds: number[] = [];
  @observable orgIds: number[] = [];
  @observable userId = 0;

  getId(): string | number {
    return this.userId;
  }
}
