import { inject, observer } from "mobx-react";
import React from "react";
import { Redirect, Route } from "react-router";
import Loader from "../components/shared/loader/Loader";
import { LoginState } from "../enums/login-state.enum";
import { Role } from "../enums/role.enum";
import { Routes } from "../routes";
import { RouteUtil } from "../utils/route-utils";
import { SettingType } from "../enums/SettingTypeEnum.enum";
import MaintenancePage from "../components/MaintainancePage/MaintainancePage";

const AdminCdpCorporateProtectedRoute: React.FC<any> = ({
  component: Component,
  ...rest
}) => {
  switch (rest.store.user.loginState) {
    case LoginState.LOGGED_IN: {
      switch (rest.store.isBootstrapped) {
        case true:
          // Maintenance Page check
          if (
            rest.store.user.loggedInProfile.role !== Role.ADMIN &&
            rest.store!.settingsStore.settingsData &&
            rest.store!.settingsStore.settingsData[SettingType.MAINTENANCE_PAGE]
              .value === "true"
          ) {
            return <Route {...rest} render={(props) => <MaintenancePage />} />;
          }
          if (
            rest.store.user.loggedInProfile.role === Role.ADMIN ||
            rest.store.user.loggedInProfile.role === Role.CDP ||
            rest.store.user.loggedInProfile.role === Role.CORPORATE
          ) {
            return (
              <Route {...rest} render={(props) => <Component {...props} />} />
            );
          }
          return (
            <Route
              {...rest}
              {...rest}
              render={(props) => (
                <Redirect
                  to={{
                    pathname: RouteUtil.getUrl(Routes.dashboard),
                  }}
                />
              )}
            />
          );

        case false:
        default:
          return <Route {...rest} render={() => <Loader />} />;
      }
    }

    case LoginState.LOGGED_OUT:
      return (
        <Route
          {...rest}
          render={(props) => (
            <Redirect
              to={{
                pathname: RouteUtil.getUrl(Routes.login),
                state: { from: props.location },
              }}
            />
          )}
        />
      );

    case LoginState.PENDING:
    default:
      return <Route {...rest} render={() => <Loader />} />;
  }
};

export default inject("store")(observer(AdminCdpCorporateProtectedRoute));
