import { Organization } from "../models/OrganizationModel";
import { ListResponse } from "../responses/list.response";
import { ShowResponse } from "../responses/show.response";
import { apiService } from "./ApiService";
import { saveAs } from "file-saver";
import { OrganizationName } from "../models/OrganizationNameModel";
import { Filters } from "../models/Filter";
import { PaginatedResponse } from "../responses/paginated.response";
import { User } from "../models/UserModel";
import { OrganizationSize } from "../enums/organization-size.enum";

class OrganizationService {
  static getInstance(): OrganizationService {
    return new OrganizationService();
  }

  getAll(filters: {
    [key: string]: any;
  }): Promise<PaginatedResponse<Organization>> {
    return apiService
      .get<PaginatedResponse<Organization>>(
        "/organizations",
        true,
        true,
        filters,
      )
      .then((response) => response);
  }

  getMyOrganizations(filters?: Filters): Promise<ListResponse<Organization>> {
    return apiService
      .get<ListResponse<Organization>>("/my-organizations", true, true, filters)
      .then((response) => response);
  }

  getCompactOrganization(): Promise<OrganizationName[]> {
    return apiService
      .get<ListResponse<OrganizationName>>("/options/organizations", true, true)
      .then((response) => response.data);
  }

  add(data: {
    name: string;
    is_active: boolean;
    assessment_purchased: number;
  }): Promise<Organization> {
    return apiService
      .post<ShowResponse<Organization>>("/organizations", true, true, data)
      .then((response) => response.data);
  }

  update(
    id: number,
    data: {
      name: string;
      is_active: boolean;
      assessment_purchased?: number;
    },
  ): Promise<Organization> {
    return apiService
      .put<ShowResponse<Organization>>("/organizations/" + id, true, true, data)
      .then((response) => response.data);
  }

  updateNew(
    id: number,
    data: {
      name: string;
      display_name: string;
      country_id?: number;
      allow_retake: boolean;
      activity_notifications: boolean;
      is_visibility_public: boolean;
    },
  ): Promise<Organization> {
    return apiService
      .put<ShowResponse<Organization>>("/organizations/" + id, true, true, data)
      .then((response) => response.data);
  }

  delete(id: number): Promise<Organization> {
    return apiService.delete("/organizations/" + id, true, true).then();
  }

  exportReportsAsCSV(
    id: number,
    type?: { [key: string]: string },
  ): Promise<any> {
    const fileName = type
      ? "organizationReports.csv"
      : "organizationAnswers.csv";
    return apiService
      .get(`/organizations/${id}/reports`, true, false, type, "blob")
      .then((res) => {
        saveAs(res as any, fileName);
        return;
      });
  }

  attachUserToOrganization(orgId: number, user_ids: number[]) {
    return apiService
      .put<ListResponse<User>>(
        "/organizations/" + orgId + "/attach-user",
        true,
        true,
        {
          user_ids: user_ids,
        },
      )
      .then((response) => response.data);
  }

  removeUserFromOrganization(orgId: string, userId: string) {
    return apiService
      .delete<ShowResponse<User>>(
        "/organizations/" + orgId + "/users/" + userId,
        true,
        true,
      )
      .then((response) => response.data);
  }

  getPresignedUrl(imageName: string): Promise<{ url: string }> {
    return apiService
      .post<ShowResponse<{ url: string }>>(
        `/organization-logo/presigned-url`,
        true,
        true,
        { imageName: imageName },
      )
      .then((res) => res.data);
  }

  allocateCredits(data: {
    credits: number;
    to_org_id?: number;
    from_org_id?: number;
    comment?: string;
  }) {
    return apiService
      .post<any>("/credits/transfer", true, true, data)
      .then((res) => res.data);
  }

  getRetakeAssessmentCount(data: { org_id?: number; team_id?: number }) {
    return apiService
      .get<any>("/retake-assessment-count", true, true, data)
      .then((res) => res.data);
  }

  bulkInviteForRetakeAssessment(data: {
    org_id?: number;
    team_id?: number;
    user_id?: number;
    includeCC?: boolean;
  }) {
    return apiService
      .post<any>("/invite/retake-assessment", true, true, data)
      .then((res) => res.data);
  }

  createOrganization(data: {
    name: string;
    isLite: boolean;
    parent_organization_id?: number;
    from_org_id: number;
    credits?: number;
  }): Promise<{
    fromOrganization: Organization;
    toOrganization: Organization;
  }> {
    return apiService
      .post<any>("/organizations/create-and-transfer", true, true, data)
      .then((res) => res.data);
  }

  getNonCDPOrganizations(): Promise<OrganizationName[]> {
    return apiService
      .get<ListResponse<OrganizationName>>(
        "/organizations/non-cdp",
        true,
        false,
      )
      .then((response) => response.data);
  }

  linkToExistingOrg(current_org_id: string, data: { org_ids: string[] }) {
    return apiService.post(
      "/cdp/" + current_org_id + "/add-organizations",
      true,
      true,
      data,
    );
  }

  createPublicOrg(data: {
    organization: string;
    organization_size: OrganizationSize;
  }) {
    return apiService
      .post<ShowResponse<any>>(
        "/public/organization",
        true,
        false,
        data,
        undefined,
        true,
      )
      .then((res) => res);
  }
}

export const organizationService = OrganizationService.getInstance();
