import * as _ from "lodash";
import { flatten } from "lodash";
import { action, computed, observable, runInAction } from "mobx";
import { AqliteUpgradeStatus } from "../enums/aqlite-upgrade-invite.enum";
import { People } from "../models/PeopleModel";
import { PeopleName } from "../models/PeopleNameModel";
import { peopleService } from "../services/PeopleService";
import { RootStore } from "./RootStore";
import Store from "./Store";
import { User } from "../models/UserModel";

export class PeopleStore extends Store<People> {
  private static _instance: PeopleStore;
  @observable isPeopleFetched = false;
  @observable isPeopleLoading = false;
  @observable isCompactPeopleLoading = false;
  @observable isCompactPeopleLoaded = false;
  @observable compactPeoples: PeopleName[] = [];
  @observable private _filter: { [key: string]: any } = {};
  @observable hasMore = true;
  @observable offset = 0;
  @observable dataPerPage = 50;

  constructor() {
    super();
    People._store = this;
  }

  @computed
  get filters() {
    return this._filter;
  }

  @computed get peoples(): People[] {
    return [...this.entities];
  }

  static getInstance(): PeopleStore {
    if (!this._instance) {
      this._instance = new PeopleStore();
    }

    return this._instance;
  }

  @action
  setFilters(filters: { [key: string]: any }) {
    if (!_.isEqual(this._filter, filters)) {
      this._filter = { ...this._filter, ...filters };
      this.offset = 0;
      this.entities = [];
      this.fetchPeoples();
    }
  }

  @action
  fetchPeoples() {
    this.isPeopleLoading = true;

    peopleService
      .fetchPeoples({
        offset: this.offset,
        limit: this.dataPerPage,
        ...this.filters,
      })
      .then((peoples: People[]) => {
        this.hasMore = !(!peoples.length || peoples.length < this.dataPerPage);
        runInAction(() => {
          peoples.map((people) => People.fromJson(people));
          this.offset = this.offset + this.dataPerPage;
          this.isPeopleLoading = false;
          this.isPeopleFetched = true;
        });
      })
      .catch(() => (this.isPeopleLoading = false));
  }

  @action
  fetchCompactPeoples() {
    this.isCompactPeopleLoading = true;
    peopleService.getCompactPeople().then((res) => {
      runInAction(() => {
        this.compactPeoples = res;
      });
      this.isCompactPeopleLoading = false;
      this.isCompactPeopleLoaded = true;
    });
  }

  @action
  async updateUserStatusOnSubmit(userId: number) {
    const updatedPeople = [...this.entities];
    updatedPeople.find((e) => +e.id === userId)!.upgradeStatus =
      AqliteUpgradeStatus.AWAITING_USER_RESPONSE;

    updatedPeople.map((people) => People.fromJson(people));
  }

  async transferPeople(
    data: {
      from_org_id: number;
      to_org_id: number;
      from_team_id?: number;
      to_team_id?: number;
      user_id: number;
    }[],
    isAdminCorporateCdp: boolean,
  ) {
    if (isAdminCorporateCdp) {
      RootStore.getInstance().user.isLoading = true;
    } else {
      this.isPeopleLoading = true;
    }

    return peopleService
      .transferUser(data)
      .then((people) => {
        if (isAdminCorporateCdp) {
          runInAction(() => {
            people.map((user) => User.fromJson(user));
            RootStore.getInstance().user.isLoading = false;
          });
        } else {
          runInAction(() => {
            people.map((user) => People.fromJson(user));
            this.isPeopleLoading = false;
          });
        }

        return;
      })
      .catch((e) => {
        runInAction(() => {
          isAdminCorporateCdp
            ? (RootStore.getInstance().user.isLoading = false)
            : (this.isPeopleLoading = false);
        });
        throw e;
      });
  }

  @action
  addTeamToUser(team_id: string, user_id: string) {
    const team = flatten(
      RootStore.getInstance().user.loggedInProfile!.organizations.map(
        (org) => org.teams,
      ),
    ).filter((team) => team.id.toString() === team_id.toString())[0];
    this.entities = this.entities.map((people) =>
      people.id.toString() === user_id.toString()
        ? ({
            ...people,
            teams: [...people.teams, team],
          } as People)
        : people,
    );
  }

  @action
  removeTeamFromUser(team_id: string, user_id: string) {
    this.entities = this.entities.map((people) =>
      people.id.toString() === user_id.toString()
        ? ({
            ...people,
            teams: people.teams.filter(
              (team) => team.id.toString() !== team_id.toString(),
            ),
          } as People)
        : people,
    );
  }
}
