import { observable } from "mobx";
import { QuestionStore } from "../stores/QuestionStore";
import Model from "./Model";

export class Question extends Model {
  static _store: QuestionStore;

  @observable id!: number;
  @observable identifier!: string;
  @observable description!: string;
  @observable type!: string;
  @observable options!: {
    key: string;
    score: number;
    value: string;
  };
  @observable dimensions!: {
    dimension_id: number;
    weight: string;
  }[];

  getId(): string | number {
    return this.id;
  }
}
