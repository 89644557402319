import { ListResponse } from "../responses/list.response";
import { apiService } from "./ApiService";
import { CompleteSurveysModel } from "../models/CompleteSurveysModel";
import { saveAs } from "file-saver";

class CompletedSurveyService {
  static getInstance(): CompletedSurveyService {
    return new CompletedSurveyService();
  }

  getAll(filters?: { [key: string]: any }): Promise<CompleteSurveysModel[]> {
    return apiService
      .get<ListResponse<CompleteSurveysModel>>(
        "/completed-surveys",
        true,
        true,
        filters,
      )
      .then((response) => response as any);
  }

  exportReportsAsZip(data: {
    survey_ids?: number[];
    user_ids?: number[];
    type: "full" | "short" | "coachOnly";
  }): Promise<any> {
    const fileName = "survey.zip";
    return apiService
      .get(`/reports/download`, true, true, data, "blob")
      .then((res) => {
        saveAs(res as any, fileName);
        return;
      });
  }
}

export const completedSurveyService = CompletedSurveyService.getInstance();
