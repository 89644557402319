import Model from "./Model";
import { observable } from "mobx";
import { PartnerOrganizationsStore } from "../stores/PartnerOrganizationsStore";
import { OrganizationStatus } from "../enums/partner-organization-status.enum";
import { User } from "./UserModel";

export class PartnerOrganization extends Model {
  static _store: PartnerOrganizationsStore;

  @observable id!: number;
  @observable name!: string;
  @observable user!: User;
  @observable status!: OrganizationStatus;
  @observable isLite!: boolean;

  getId(): string | number {
    return this.id;
  }
}
