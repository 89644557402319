import {
  ReportMetaData,
  UpdateReportMetaData,
} from "../models/ReportMetaDataModel";
import { apiService } from "./ApiService";
import { ListResponse } from "../responses/list.response";
import { AdvancedInsightsModel } from "../models/AdvancedInsightsModel";
import { ShowResponse } from "../responses/show.response";

class AdvancedInsightsService {
  static getInstance(): AdvancedInsightsService {
    return new AdvancedInsightsService();
  }

  getAllMetadata(): Promise<ReportMetaData[]> {
    return apiService
      .get<ListResponse<ReportMetaData>>("/reports/metadata", true, true)
      .then((res) => res.data);
  }

  fetchAdvancedInsightsForUser(userId: number): Promise<AdvancedInsightsModel> {
    return apiService
      .get<ShowResponse<AdvancedInsightsModel>>(
        `/users/${userId}/advance-insights`,
        true,
        true,
      )
      .then((res) => res.data);
  }

  updateMetaData(
    id: number,
    data: UpdateReportMetaData,
  ): Promise<ReportMetaData> {
    return apiService
      .put<ShowResponse<ReportMetaData>>(
        `/reports/metadata/${id}`,
        true,
        false,
        data,
      )
      .then((res) => res.data);
  }
}

export const advancedInsightsService = AdvancedInsightsService.getInstance();
