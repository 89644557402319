import { action, computed, observable, runInAction } from "mobx";
import Store from "./Store";
import { communityService } from "../services/CommunityService";
import {
  CommunityCardFormData,
  CommunityModel,
} from "../models/CommunityModel";

export class CommunityStore extends Store<CommunityModel> {
  private static _instance: CommunityStore;

  @observable isLoading = false;
  @observable isLoaded = false;

  @observable communityCardData: { [key: string]: CommunityModel } = {};

  constructor() {
    super();
    CommunityModel._store = this;
  }

  @computed
  get events(): CommunityModel[] {
    return [...this.entities];
  }

  static getInstance(): CommunityStore {
    if (!this._instance) {
      this._instance = new CommunityStore();
    }

    return this._instance;
  }

  @action
  fetchCommunityCardData() {
    this.isLoading = true;
    communityService
      .getAll()
      .then((data: CommunityModel[]) => {
        runInAction(() => {
          data.map((event) => CommunityModel.fromJson(event));
          this.communityCardData = this.entities.reduce((prev, current) => {
            prev[current.slug] = current;
            return prev;
          }, {} as any);
          this.isLoaded = true;
        });
      })
      .catch((err) => console.log(err))
      .finally(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
  }

  @action
  async updateContent(id: number, data: CommunityCardFormData) {
    const response = await communityService.update(id, data);
    runInAction(() => {
      CommunityModel.fromJson(response);
    });
  }
}
