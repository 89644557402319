import { Spin } from "antd";
import React from "react";
import "./CenterLoader.scss";

const CenterLoader: React.FC = () => {
  return (
    <div className="center-loader">
      <Spin size="large" />
    </div>
  );
};

export default CenterLoader;
