export enum Dimensions {
  OVERVIEW_ID = 100,
  ABILITY_ID = 101,
  GRIT_ID = 11,
  MENTAL_FLEXIBILITY_ID = 12,
  MINDSET_ID = 13,
  RESILIENCE_ID = 14,
  UNLEARN_ID = 15,

  CHARACTER_ID = 102,
  EMOTIONAL_RANGE_ID = 16,
  EXTRAVERSION_ID = 17,
  HOPE_ID = 18,
  MOTIVATION_STYLE_ID = 19,
  THINKING_STYLE_ID = 20,

  ENVIRONMENT_ID = 103,
  COMPANY_SUPPORT_ID = 21,
  EMOTIONAL_HEALTH_ID = 22,
  TEAM_SUPPORT_ID = 23,
  WORK_ENVIRONMENT_ID = 24,
  WORK_STRESS_ID = 25,

  AI_PREDICTIVE_OPTIMISATION_ID = 104,
  CHANGE_READINESS_ID = 26,
  RESKILL_INDEX_ID = 27,

  ADAPTABILITY_ID = 105,
  EXPLORATION_ID = 28,
  EXPLOITATION_ID = 29,

  CHANGEUNCERTAINITY = 3,
}
