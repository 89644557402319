import { Languages } from "./languages.enum";

export enum JobFunction {
  ACCOUNTANTS_AND_AUDITORS = "accountants_and_auditors",
  ACTORS_PRODUCERS_AND_DIRECTORS = "actors_producers_and_directors",
  ACTUARIES = "actuaries",
  ADMINISTRATIVE_SERVICES_MANAGERS = "administrative_services_managers",
  ADULT_LITERACY_AND_HIGH_SCHOOL_EQUIVALENCY_DIPLOMA_TEACHERS = "adult_literacy_and_high_school_equivalency_diploma_teachers",
  ADVERTISING_SALES_AGENTS = "advertising_sales_agents",
  ADVERTISING_MARKETING_PROMOTIONS_PUBLIC_RELATIONS_AND_SALES_MANAGERS = "advertising_marketing_promotions_public_relations_and_sales_managers",
  AEROSPACE_ENGINEERING_AND_OPERATIONS_TECHNICIANS = "aerospace_engineering_and_operations_technicians",
  AEROSPACE_ENGINEERS = "aerospace_engineers",
  AGRICULTURAL_AND_FOOD_SCIENCE_TECHNICIANS = "agricultural_and_food_science_technicians",
  AGRICULTURAL_AND_FOOD_SCIENTISTS = "agricultural_and_food_scientists",
  AGRICULTURAL_ENGINEERS = "agricultural_engineers",
  AGRICULTURAL_WORKERS = "agricultural_workers",
  AIR_TRAFFIC_CONTROLLERS = "air_traffic_controllers",
  AIRCRAFT_AND_AVIONICS_EQUIPMENT_MECHANICS_AND_SERVICE_TECHNICIANS = "aircraft_and_avionics_equipment_mechanics_and_service_technicians",
  AIRCRAFT_PILOTS_AND_FLIGHT_ENGINEERS = "aircraft_pilots_and_flight_engineers",
  ANIMAL_CARE_AND_SERVICE_WORKERS = "animal_care_and_service_workers",
  ANNOUNCERS = "announcers",
  ANTHROPOLOGISTS_AND_ARCHEOLOGISTS = "anthropologists_and_archeologists",
  APPRAISERS_AND_ASSESSORS_OF_REAL_ESTATE = "appraisers_and_assessors_of_real_estate",
  ARBITRATORS_MEDIATORS_AND_CONCILIATORS = "arbitrators_mediators_and_conciliators",
  ARCHITECTS_EXCEPT_LANDSCAPE_AND_NAVAL = "architects_except_landscape_and_naval",
  ARCHITECTURAL_AND_ENGINEERING_MANAGERS = "architectural_and_engineering_managers",
  ARCHIVISTS_CURATORS_AND_MUSEUM_TECHNICIANS = "archivists_curators_and_museum_technicians",
  ART_DIRECTORS = "art_directors",
  ARTISTS_AND_RELATED_WORKERS = "artists_and_related_workers",
  ASSEMBLERS_AND_FABRICATORS = "assemblers_and_fabricators",
  ATHLETES_COACHES_UMPIRES_AND_RELATED_WORKERS = "athletes_coaches_umpires_and_related_workers",
  ATHLETIC_TRAINERS = "athletic_trainers",
  ATMOSPHERIC_SCIENTISTS = "atmospheric_scientists",
  AUDIOLOGISTS = "audiologists",
  AUTOMOTIVE_BODY_AND_RELATED_REPAIRERS = "automotive_body_and_related_repairers",
  AUTOMOTIVE_SERVICE_TECHNICIANS_AND_MECHANICS = "automotive_service_technicians_and_mechanics",
  BAKERS = "bakers",
  BARBERS_COSMETOLOGISTS_AND_OTHER_PERSONAL_APPEARANCE_WORKERS = "barbers_cosmetologists_and_other_personal_appearance_workers",
  BARTENDERS = "bartenders",
  BILL_AND_ACCOUNT_COLLECTORS = "bill_and_account_collectors",
  BILLING_AND_POSTING_CLERKS_AND_MACHINE_OPERATORS = "billing_and_posting_clerks_and_machine_operators",
  BIOLOGICAL_SCIENTISTS = "biological_scientists",
  BIOLOGICAL_TECHNICIANS = "biological_technicians",
  BIOMEDICAL_ENGINEERS = "biomedical_engineers",
  BOILERMAKERS = "boilermakers",
  BOOKBINDERS_AND_BINDERY_WORKERS = "bookbinders_and_bindery_workers",
  BOOKKEEPING_ACCOUNTING_AND_AUDITING_CLERKS = "bookkeeping_accounting_and_auditing_clerks",
  BRICKMASONS_BLOCKMASONS_AND_STONEMASONS = "brickmasons_blockmasons_and_stonemasons",
  BROADCAST_AND_SOUND_ENGINEERING_TECHNICIANS_AND_RADIO_OPERATORS = "broadcast_and_sound_engineering_technicians_and_radio_operators",
  BROKERAGE_CLERKS = "brokerage_clerks",
  BUDGET_ANALYSTS = "budget_analysts",
  BUILDING_CLEANING_WORKERS = "building_cleaning_workers",
  BUS_DRIVERS = "bus_drivers",
  BUTCHERS = "butchers",
  CARDIOVASCULAR_TECHNOLOGISTS_AND_TECHNICIANS = "cardiovascular_technologists_and_technicians",
  CAREER_AND_TECHNICAL_EDUCATION_TEACHERS = "career_and_technical_education_teachers",
  CARGO_AND_FREIGHT_AGENTS = "cargo_and_freight_agents",
  CARPENTERS = "carpenters",
  CARPET_FLOOR_AND_TILE_INSTALLERS_AND_FINISHERS = "carpet_floor_and_tile_installers_and_finishers",
  CARTOGRAPHERS_AND_PHOTOGRAMMETRISTS = "cartographers_and_photogrammetrists",
  CASHIERS = "cashiers",
  CEMENT_MASONS_CONCRETE_FINISHERS_SEGMENTAL_PAVERS_AND_TERRAZZO_WORKERS = "cement_masons_concrete_finishers_segmental_pavers_and_terrazzo_workers",
  CHEFS_AND_HEAD_COOKS = "chefs_and_head_cooks",
  CHEFS_COOKS_AND_FOOD_PREPARATION_WORKERS = "chefs_cooks_and_food_preparation_workers",
  CHEMICAL_ENGINEERS = "chemical_engineers",
  CHEMICAL_TECHNICIANS = "chemical_technicians",
  CHEMISTS_AND_MATERIALS_SCIENTISTS = "chemists_and_materials_scientists",
  CHILD_CARE_WORKERS = "child_care_workers",
  CHIROPRACTORS = "chiropractors",
  CIVIL_ENGINEERING_TECHNICIANS = "civil_engineering_technicians",
  CIVIL_ENGINEERS = "civil_engineers",
  CLAIMS_ADJUSTERS_APPRAISERS_EXAMINERS_AND_INVESTIGATORS = "claims_adjusters_appraisers_examiners_and_investigators",
  CLINICAL_LABORATORY_TECHNOLOGISTS_AND_TECHNICIANS = "clinical_laboratory_technologists_and_technicians",
  COACHES_AND_SCOUTS = "coaches_and_scouts",
  COIN_VENDING_AND_AMUSEMENT_MACHINE_SERVICERS_AND_REPAIRERS = "coin_vending_and_amusement_machine_servicers_and_repairers",
  COMMERCIAL_AND_INDUSTRIAL_DESIGNERS = "commercial_and_industrial_designers",
  COMMERCIAL_MANAGEMENT = "commercial_management",
  COMMUNICATIONS_EQUIPMENT_OPERATORS = "communications_equipment_operators",
  COMPENSATION_AND_BENEFITS_MANAGERS = "compensation_and_benefits_managers",
  COMPENSATION_BENEFITS_AND_JOB_ANALYSIS_SPECIALISTS = "compensation_benefits_and_job_analysis_specialists",
  COMPUTER_AND_INFORMATION_RESEARCH_SCIENTISTS = "computer_and_information_research_scientists",
  COMPUTER_AND_INFORMATION_SYSTEMS_MANAGERS = "computer_and_information_systems_managers",
  COMPUTER_CONTROL_PROGRAMMERS_AND_OPERATORS = "computer_control_programmers_and_operators",
  COMPUTER_HARDWARE_ENGINEERS = "computer_hardware_engineers",
  COMPUTER_NETWORK_ARCHITECTS = "computer_network_architects",
  COMPUTER_OPERATORS = "computer_operators",
  COMPUTER_PROGRAMMERS = "computer_programmers",
  COMPUTER_SCIENTISTS_AND_DATABASE_ADMINISTRATORS = "computer_scientists_and_database_administrators",
  COMPUTER_SOFTWARE_ENGINEERS = "computer_software_engineers",
  COMPUTER_SUPPORT_SPECIALISTS = "computer_support_specialists",
  COMPUTER_SUPPORT_SPECIALISTS_AND_SYSTEMS_ADMINISTRATORS = "computer_support_specialists_and_systems_administrators",
  COMPUTER_SYSTEMS_ANALYSTS = "computer_systems_analysts",
  COMPUTER_AUTOMATED_TELLER_AND_OFFICE_MACHINE_REPAIRERS = "computer_automated_teller_and_office_machine_repairers",
  CONSERVATION_SCIENTISTS_AND_FORESTERS = "conservation_scientists_and_foresters",
  CONSTRUCTION_AND_BUILDING_INSPECTORS = "construction_and_building_inspectors",
  CONSTRUCTION_EQUIPMENT_OPERATORS = "construction_equipment_operators",
  CONSTRUCTION_LABORERS = "construction_laborers",
  CONSTRUCTION_LABORERS_AND_HELPERS = "construction_laborers_and_helpers",
  CONSTRUCTION_MANAGERS = "construction_managers",
  COOKS = "cooks",
  CONTRACT_MANAGEMENT = "contract_management",
  CORRECTIONAL_OFFICERS = "correctional_officers",
  CORRECTIONAL_OFFICERS_AND_BAILIFFS = "correctional_officers_and_bailiffs",
  COST_ESTIMATORS = "cost_estimators",
  COUNSELORS = "counselors",
  COUNTER_AND_RENTAL_CLERKS = "counter_and_rental_clerks",
  COURIERS_AND_MESSENGERS = "couriers_and_messengers",
  COURT_REPORTERS = "court_reporters",
  CRAFT_AND_FINE_ARTISTS = "craft_and_fine_artists",
  CREDIT_AUTHORIZERS_CHECKERS_AND_CLERKS = "credit_authorizers_checkers_and_clerks",
  CUSTOMER_SERVICE_REPRESENTATIVES = "customer_service_representatives",
  DANCERS_AND_CHOREOGRAPHERS = "dancers_and_choreographers",
  DATA_ENTRY_AND_INFORMATION_PROCESSING_WORKERS = "data_entry_and_information_processing_workers",
  DATABASE_ADMINISTRATORS = "database_administrators",
  DELIVERY_TRUCK_DRIVERS_AND_DRIVERSALES_WORKERS = "delivery_truck_drivers_and_driversales_workers",
  DEMONSTRATORS_PRODUCT_PROMOTERS_AND_MODELS = "demonstrators_product_promoters_and_models",
  DENTAL_AND_OPHTHALMIC_LABORATORY_TECHNICIANS_AND_MEDICAL_APPLIANCE_TECHNICIANS = "dental_and_ophthalmic_laboratory_technicians_and_medical_appliance_technicians",
  DENTAL_ASSISTANTS = "dental_assistants",
  DENTAL_HYGIENISTS = "dental_hygienists",
  DENTISTS = "dentists",
  DESKTOP_PUBLISHERS = "desktop_publishers",
  DIAGNOSTIC_MEDICAL_SONOGRAPHERS = "diagnostic_medical_sonographers",
  DIESEL_SERVICE_TECHNICIANS_AND_MECHANICS = "diesel_service_technicians_and_mechanics",
  DIETITIANS_AND_NUTRITIONISTS = "dietitians_and_nutritionists",
  DISPATCHERS = "dispatchers",
  DRAFTERS = "drafters",
  DRYWALL_AND_CEILING_TILE_INSTALLERS_AND_TAPERS = "drywall_and_ceiling_tile_installers_and_tapers",
  DRYWALL_INSTALLERS_CEILING_TILE_INSTALLERS_AND_TAPERS = "drywall_installers_ceiling_tile_installers_and_tapers",
  ECONOMISTS = "economists",
  EDITORS = "editors",
  EDUCATION_ADMINISTRATORS = "education_administrators",
  ELECTRICAL_AND_ELECTRONICS_ENGINEERING_TECHNICIANS = "electrical_and_electronics_engineering_technicians",
  ELECTRICAL_AND_ELECTRONICS_ENGINEERS = "electrical_and_electronics_engineers",
  ELECTRICAL_AND_ELECTRONICS_INSTALLERS_AND_REPAIRERS = "electrical_and_electronics_installers_and_repairers",
  ELECTRICIANS = "electricians",
  ELECTRO_MECHANICAL_TECHNICIANS = "electro_mechanical_technicians",
  ELECTRONIC_HOME_ENTERTAINMENT_EQUIPMENT_INSTALLERS_AND_REPAIRERS = "electronic_home_entertainment_equipment_installers_and_repairers",
  ELEMENTARY_MIDDLE_AND_HIGH_SCHOOL_PRINCIPALS = "elementary_middle_and_high_school_principals",
  ELEVATOR_INSTALLERS_AND_REPAIRERS = "elevator_installers_and_repairers",
  EMERGENCY_MANAGEMENT_DIRECTORS = "emergency_management_directors",
  EMERGENCY_MEDICAL_TECHNICIANS_AND_PARAMEDICS = "emergency_medical_technicians_and_paramedics",
  EMTS_AND_PARAMEDICS = "emts_and_paramedics",
  ENGINEERING_AND_NATURAL_SCIENCES_MANAGERS = "engineering_and_natural_sciences_managers",
  ENGINEERING_TECHNICIANS = "engineering_technicians",
  ENVIRONMENTAL_ENGINEERING_TECHNICIANS = "environmental_engineering_technicians",
  ENVIRONMENTAL_ENGINEERS = "environmental_engineers",
  ENVIRONMENTAL_SCIENCE_AND_PROTECTION_TECHNICIANS = "environmental_science_and_protection_technicians",
  ENVIRONMENTAL_SCIENTISTS_AND_HYDROLOGISTS = "environmental_scientists_and_hydrologists",
  ENVIRONMENTAL_SCIENTISTS_AND_SPECIALISTS = "environmental_scientists_and_specialists",
  EPIDEMIOLOGISTS = "epidemiologists",
  EXERCISE_PHYSIOLOGISTS = "exercise_physiologists",
  FARMERS_RANCHERS_AND_AGRICULTURAL_MANAGERS = "farmers_ranchers_and_agricultural_managers",
  FARMERS_RANCHERS_AND_OTHER_AGRICULTURAL_MANAGERS = "farmers_ranchers_and_other_agricultural_managers",
  FASHION_DESIGNERS = "fashion_designers",
  FILE_CLERKS = "file_clerks",
  FILM_AND_VIDEO_EDITORS_AND_CAMERA_OPERATORS = "film_and_video_editors_and_camera_operators",
  FINANCIAL_ANALYSTS = "financial_analysts",
  FINANCIAL_ANALYSTS_AND_PERSONAL_FINANCIAL_ADVISORS = "financial_analysts_and_personal_financial_advisors",
  FINANCIAL_CLERKS = "financial_clerks",
  FINANCIAL_EXAMINERS = "financial_examiners",
  FINANCIAL_MANAGERS = "financial_managers",
  FIRE_FIGHTING_OCCUPATIONS = "fire_fighting_occupations",
  FIRE_INSPECTORS = "fire_inspectors",
  FIREFIGHTERS = "firefighters",
  FISHERS_AND_FISHING_VESSEL_OPERATORS = "fishers_and_fishing_vessel_operators",
  FISHING_AND_HUNTING_WORKERS = "fishing_and_hunting_workers",
  FITNESS_TRAINERS_AND_INSTRUCTORS = "fitness_trainers_and_instructors",
  FITNESS_WORKERS = "fitness_workers",
  FLIGHT_ATTENDANTS = "flight_attendants",
  FLOORING_INSTALLERS_AND_TILE_AND_MARBLE_SETTERS = "flooring_installers_and_tile_and_marble_setters",
  FLORAL_DESIGNERS = "floral_designers",
  FOOD_AND_BEVERAGE_SERVING_AND_RELATED_WORKERS = "food_and_beverage_serving_and_related_workers",
  FOOD_AND_TOBACCO_PROCESSING_WORKERS = "food_and_tobacco_processing_workers",
  FOOD_PROCESSING_OCCUPATIONS = "food_processing_occupations",
  FOOD_SERVICE_MANAGERS = "food_service_managers",
  FORENSIC_SCIENCE_TECHNICIANS = "forensic_science_technicians",
  FOREST_AND_CONSERVATION_WORKERS = "forest_and_conservation_workers",
  FOREST_CONSERVATION_AND_LOGGING_WORKERS = "forest_conservation_and_logging_workers",
  FUNDRAISERS = "fundraisers",
  FUNERAL_DIRECTORS = "funeral_directors",
  FUNERAL_SERVICE_WORKERS = "funeral_service_workers",
  GAMING_CAGE_WORKERS = "gaming_cage_workers",
  GAMING_SERVICES_OCCUPATIONS = "gaming_services_occupations",
  GAMING_SERVICES_WORKERS = "gaming_services_workers",
  GENERAL_MAINTENANCE_AND_REPAIR_WORKERS = "general_maintenance_and_repair_workers",
  GENERAL_OFFICE_CLERKS = "general_office_clerks",
  GENETIC_COUNSELORS = "genetic_counselors",
  GEOGRAPHERS = "geographers",
  GEOLOGICAL_AND_PETROLEUM_TECHNICIANS = "geological_and_petroleum_technicians",
  GEOSCIENTISTS = "geoscientists",
  GLAZIERS = "glaziers",
  GRAPHIC_DESIGNERS = "graphic_designers",
  GROUNDS_MAINTENANCE_WORKERS = "grounds_maintenance_workers",
  HAND_LABORERS_AND_MATERIAL_MOVERS = "hand_laborers_and_material_movers",
  HAZARDOUS_MATERIALS_REMOVAL_WORKERS = "hazardous_materials_removal_workers",
  HEALTH_AND_SAFETY_ENGINEERS = "health_and_safety_engineers",
  HEALTH_EDUCATORS = "health_educators",
  HEALTH_EDUCATORS_AND_COMMUNITY_HEALTH_WORKERS = "health_educators_and_community_health_workers",
  HEATING_AIR_CONDITIONING_AND_REFRIGERATION_MECHANICS_AND_INSTALLERS = "heating_air_conditioning_and_refrigeration_mechanics_and_installers",
  HEAVY_AND_TRACTOR_TRAILER_TRUCK_DRIVERS = "heavy_and_tractor_trailer_truck_drivers",
  HEAVY_VEHICLE_AND_MOBILE_EQUIPMENT_SERVICE_TECHNICIANS = "heavy_vehicle_and_mobile_equipment_service_technicians",
  HEAVY_VEHICLE_AND_MOBILE_EQUIPMENT_SERVICE_TECHNICIANS_AND_MECHANICS = "heavy_vehicle_and_mobile_equipment_service_technicians_and_mechanics",
  HIGH_SCHOOL_TEACHERS = "high_school_teachers",
  HISTORIANS = "historians",
  HOME_APPLIANCE_REPAIRERS = "home_appliance_repairers",
  HOME_HEALTH_AIDES_AND_PERSONAL_CARE_AIDES = "home_health_aides_and_personal_care_aides",
  HOTEL_MOTEL_AND_RESORT_DESK_CLERKS = "hotel_motel_and_resort_desk_clerks",
  HUMAN_RESOURCES_ASSISTANTS_EXCEPT_PAYROLL_AND_TIMEKEEPING = "human_resources_assistants_except_payroll_and_timekeeping",
  HUMAN_RESOURCES_MANAGERS = "human_resources_managers",
  HUMAN_RESOURCES_SPECIALISTS = "human_resources_specialists",
  HUMAN_RESOURCES_TRAINING_AND_LABOR_RELATIONS_MANAGERS_AND_SPECIALISTS = "human_resources_training_and_labor_relations_managers_and_specialists",
  HYDROLOGISTS = "hydrologists",
  INDUSTRIAL_DESIGNERS = "industrial_designers",
  INDUSTRIAL_ENGINEERING_TECHNICIANS = "industrial_engineering_technicians",
  INDUSTRIAL_ENGINEERS = "industrial_engineers",
  INDUSTRIAL_MACHINERY_MECHANICS_AND_MAINTENANCE_WORKERS = "industrial_machinery_mechanics_and_maintenance_workers",
  INDUSTRIAL_PRODUCTION_MANAGERS = "industrial_production_managers",
  INFORMATION_CLERKS = "information_clerks",
  INFORMATION_SECURITY_ANALYSTS = "information_security_analysts",
  INSPECTORS_TESTERS_SORTERS_SAMPLERS_AND_WEIGHERS = "inspectors_testers_sorters_samplers_and_weighers",
  INSTRUCTIONAL_COORDINATORS = "instructional_coordinators",
  INSULATION_WORKERS = "insulation_workers",
  INSURANCE_SALES_AGENTS = "insurance_sales_agents",
  INSURANCE_UNDERWRITERS = "insurance_underwriters",
  INTERIOR_DESIGNERS = "interior_designers",
  INTERPRETERS_AND_TRANSLATORS = "interpreters_and_translators",
  IRONWORKERS = "ironworkers",
  JEWELERS_AND_PRECIOUS_STONE_AND_METAL_WORKERS = "jewelers_and_precious_stone_and_metal_workers",
  JOB_OPPORTUNITIES_IN_THE_ARMED_FORCES = "job_opportunities_in_the_armed_forces",
  JUDGES_AND_HEARING_OFFICERS = "judges_and_hearing_officers",
  JUDGES_MAGISTRATES_AND_OTHER_JUDICIAL_WORKERS = "judges_magistrates_and_other_judicial_workers",
  KINDERGARTEN_AND_ELEMENTARY_SCHOOL_TEACHERS = "kindergarten_and_elementary_school_teachers",
  LABOR_RELATIONS_SPECIALISTS = "labor_relations_specialists",
  LANDSCAPE_ARCHITECTS = "landscape_architects",
  LAWYERS = "lawyers",
  LIBRARIANS = "librarians",
  LIBRARY_TECHNICIANS_AND_ASSISTANTS = "library_technicians_and_assistants",
  LICENSED_PRACTICAL_AND_LICENSED_VOCATIONAL_NURSES = "licensed_practical_and_licensed_vocational_nurses",
  LINE_INSTALLERS_AND_REPAIRERS = "line_installers_and_repairers",
  LOAN_OFFICERS = "loan_officers",
  LODGING_MANAGERS = "lodging_managers",
  LOGGING_WORKERS = "logging_workers",
  LOGISTICIANS = "logisticians",
  MACHINE_SETTERS_OPERATORS_AND_TENDERS_METAL_AND_PLASTIC = "machine_setters_operators_and_tenders_metal_and_plastic",
  MACHINISTS = "machinists",
  MACHINISTS_AND_TOOL_AND_DIE_MAKERS = "machinists_and_tool_and_die_makers",
  MAINTENANCE_AND_REPAIR_WORKERS_GENERAL = "maintenance_and_repair_workers_general",
  MANAGEMENT_ANALYSTS = "management_analysts",
  MANICURISTS_AND_PEDICURISTS = "manicurists_and_pedicurists",
  MARINE_ENGINEERS_AND_NAVAL_ARCHITECTS = "marine_engineers_and_naval_architects",
  MARKET_AND_SURVEY_RESEARCHERS = "market_and_survey_researchers",
  MARKET_RESEARCH_ANALYSTS = "market_research_analysts",
  MARRIAGE_AND_FAMILY_THERAPISTS = "marriage_and_family_therapists",
  MASSAGE_THERAPISTS = "massage_therapists",
  MATERIAL_MOVING_MACHINE_OPERATORS = "material_moving_machine_operators",
  MATERIAL_MOVING_OCCUPATIONS = "material_moving_occupations",
  MATERIAL_RECORDING_CLERKS = "material_recording_clerks",
  MATERIALS_ENGINEERS = "materials_engineers",
  MATHEMATICIANS = "mathematicians",
  MATHEMATICIANS_AND_STATISTICIANS = "mathematicians_and_statisticians",
  MECHANICAL_ENGINEERING_TECHNICIANS = "mechanical_engineering_technicians",
  MECHANICAL_ENGINEERS = "mechanical_engineers",
  MEDICAL_AND_CLINICAL_LABORATORY_TECHNOLOGISTS_AND_TECHNICIANS = "medical_and_clinical_laboratory_technologists_and_technicians",
  MEDICAL_AND_HEALTH_SERVICES_MANAGERS = "medical_and_health_services_managers",
  MEDICAL_ASSISTANTS = "medical_assistants",
  MEDICAL_EQUIPMENT_REPAIRERS = "medical_equipment_repairers",
  MEDICAL_RECORDS_AND_HEALTH_INFORMATION_TECHNICIANS = "medical_records_and_health_information_technicians",
  MEDICAL_SCIENTISTS = "medical_scientists",
  MEDICAL_TRANSCRIPTIONISTS = "medical_transcriptionists",
  MEDICAL_DENTAL_AND_OPHTHALMIC_LABORATORY_TECHNICIANS = "medical_dental_and_ophthalmic_laboratory_technicians",
  MEETING_AND_CONVENTION_PLANNERS = "meeting_and_convention_planners",
  MEETING_CONVENTION_AND_EVENT_PLANNERS = "meeting_convention_and_event_planners",
  METAL_AND_PLASTIC_MACHINE_WORKERS = "metal_and_plastic_machine_workers",
  MICROBIOLOGISTS = "microbiologists",
  MIDDLE_SCHOOL_TEACHERS = "middle_school_teachers",
  MILITARY_CAREERS = "military_careers",
  MILLWRIGHTS = "millwrights",
  MINING_AND_GEOLOGICAL_ENGINEERS = "mining_and_geological_engineers",
  MODELS = "models",
  METER_READERS_UTILITIES = "meter_readers_utilities",
  MULTIMEDIA_ARTISTS_AND_ANIMATORS = "multimedia_artists_and_animators",
  MUSIC_DIRECTORS_AND_COMPOSERS = "music_directors_and_composers",
  MUSICIANS_AND_SINGERS = "musicians_and_singers",
  MUSICIANS_SINGERS_AND_RELATED_WORKERS = "musicians_singers_and_related_workers",
  NATURAL_SCIENCES_MANAGERS = "natural_sciences_managers",
  NETWORK_AND_COMPUTER_SYSTEMS_ADMINISTRATORS = "network_and_computer_systems_administrators",
  NEWS_ANALYSTS_REPORTERS_AND_CORRESPONDENTS = "news_analysts_reporters_and_correspondents",
  NUCLEAR_ENGINEERS = "nuclear_engineers",
  NUCLEAR_MEDICINE_TECHNOLOGISTS = "nuclear_medicine_technologists",
  NUCLEAR_TECHNICIANS = "nuclear_technicians",
  NURSE_ANESTHETISTS_NURSE_MIDWIVES_AND_NURSE_PRACTITIONERS = "nurse_anesthetists_nurse_midwives_and_nurse_practitioners",
  NURSING_ASSISTANTS_AND_ORDERLIES = "nursing_assistants_and_orderlies",
  NURSING_PSYCHIATRIC_AND_HOME_HEALTH_AIDES = "nursing_psychiatric_and_home_health_aides",
  OCCUPATIONAL_HEALTH_AND_SAFETY_SPECIALISTS_AND_TECHNICIANS = "occupational_health_and_safety_specialists_and_technicians",
  OCCUPATIONAL_THERAPIST_ASSISTANTS_AND_AIDES = "occupational_therapist_assistants_and_aides",
  OCCUPATIONAL_THERAPISTS = "occupational_therapists",
  OCCUPATIONAL_THERAPY_ASSISTANTS_AND_AIDES = "occupational_therapy_assistants_and_aides",
  OFFICE_AND_ADMINISTRATIVE_SUPPORT_WORKER_SUPERVISORS_AND_MANAGERS = "office_and_administrative_support_worker_supervisors_and_managers",
  OFFICE_CLERKS_GENERAL = "office_clerks_general",
  OPERATIONS_RESEARCH_ANALYSTS = "operations_research_analysts",
  OPTICIANS = "opticians",
  OPTICIANS_DISPENSING = "opticians_dispensing",
  OPTOMETRISTS = "optometrists",
  ORDER_CLERKS = "order_clerks",
  ORTHOTISTS_AND_PROSTHETISTS = "orthotists_and_prosthetists",
  PAINTERS_AND_PAPERHANGERS = "painters_and_paperhangers",
  PAINTERS_CONSTRUCTION_AND_MAINTENANCE = "painters_construction_and_maintenance",
  PAINTING_AND_COATING_WORKERS = "painting_and_coating_workers",
  PAINTING_AND_COATING_WORKERS_EXCEPT_CONSTRUCTION_AND_MAINTENANCE = "painting_and_coating_workers_except_construction_and_maintenance",
  PARALEGALS_AND_LEGAL_ASSISTANTS = "paralegals_and_legal_assistants",
  PAYROLL_AND_TIMEKEEPING_CLERKS = "payroll_and_timekeeping_clerks",
  PERSONAL_AND_HOME_CARE_AIDES = "personal_and_home_care_aides",
  PERSONAL_FINANCIAL_ADVISORS = "personal_financial_advisors",
  PEST_CONTROL_WORKERS = "pest_control_workers",
  PETROLEUM_ENGINEERS = "petroleum_engineers",
  PHARMACISTS = "pharmacists",
  PHARMACY_AIDES = "pharmacy_aides",
  PHARMACY_TECHNICIANS = "pharmacy_technicians",
  PHLEBOTOMISTS = "phlebotomists",
  PHOTOGRAPHERS = "photographers",
  PHYSICAL_THERAPIST_ASSISTANTS_AND_AIDES = "physical_therapist_assistants_and_aides",
  PHYSICAL_THERAPISTS = "physical_therapists",
  PHYSICIAN_ASSISTANTS = "physician_assistants",
  PHYSICIANS_AND_SURGEONS = "physicians_and_surgeons",
  PHYSICISTS_AND_ASTRONOMERS = "physicists_and_astronomers",
  PIPELAYERS_PLUMBERS_PIPEFITTERS_AND_STEAMFITTERS = "pipelayers_plumbers_pipefitters_and_steamfitters",
  PHOTOGRAPHIC_PROCESS_WORKERS_AND_PROCESSING_MACHINE_OPERATORS = "photographic_process_workers_and_processing_machine_operators",
  PLUMBERS_PIPEFITTERS_AND_STEAMFITTERS = "plumbers_pipefitters_and_steamfitters",
  PODIATRISTS = "podiatrists",
  POLICE_AND_DETECTIVES = "police_and_detectives",
  POLICE_FIRE_AND_AMBULANCE_DISPATCHERS = "police_fire_and_ambulance_dispatchers",
  POLITICAL_SCIENTISTS = "political_scientists",
  POSTAL_SERVICE_WORKERS = "postal_service_workers",
  POSTSECONDARY_EDUCATION_ADMINISTRATORS = "postsecondary_education_administrators",
  POSTSECONDARY_TEACHERS = "postsecondary_teachers",
  PLASTERERS_AND_STUCCO_MASONS = "plasterers_and_stucco_masons",
  POWER_PLANT_OPERATORS_DISTRIBUTORS_AND_DISPATCHERS = "power_plant_operators_distributors_and_dispatchers",
  PRESCHOOL_AND_CHILDCARE_CENTER_DIRECTORS = "preschool_and_childcare_center_directors",
  PRESCHOOL_TEACHERS = "preschool_teachers",
  PRIVATE_DETECTIVES_AND_INVESTIGATORS = "private_detectives_and_investigators",
  PROBATION_OFFICERS_AND_CORRECTIONAL_TREATMENT_SPECIALISTS = "probation_officers_and_correctional_treatment_specialists",
  PROCUREMENT_CLERKS = "procurement_clerks",
  PROCUREMENT_MANAGER = "procurement_manager",
  PRODUCERS_AND_DIRECTORS = "producers_and_directors",
  PRODUCTION_PLANNING_AND_EXPEDITING_CLERKS = "production_planning_and_expediting_clerks",
  PROPERTY_REAL_ESTATE_AND_COMMUNITY_ASSOCIATION_MANAGERS = "property_real_estate_and_community_association_managers",
  PRECISION_INSTRUMENT_AND_EQUIPMENT_REPAIRERS = "precision_instrument_and_equipment_repairers",
  PSYCHIATRIC_TECHNICIANS_AND_AIDES = "psychiatric_technicians_and_aides",
  PREPRESS_TECHNICIANS_AND_WORKERS = "prepress_technicians_and_workers",
  PSYCHOLOGISTS = "psychologists",
  PUBLIC_RELATIONS_AND_FUNDRAISING_MANAGERS = "public_relations_and_fundraising_managers",
  PUBLIC_RELATIONS_SPECIALISTS = "public_relations_specialists",
  PURCHASING_MANAGERS_BUYERS_AND_PURCHASING_AGENTS = "purchasing_managers_buyers_and_purchasing_agents",
  PRINTING_MACHINE_OPERATORS = "printing_machine_operators",
  QUALITY_CONTROL_INSPECTORS = "quality_control_inspectors",
  RADIATION_THERAPISTS = "radiation_therapists",
  RADIO_AND_TELECOMMUNICATIONS_EQUIPMENT_INSTALLERS_AND_REPAIRERS = "radio_and_telecommunications_equipment_installers_and_repairers",
  RADIOLOGIC_AND_MRI_TECHNOLOGISTS = "radiologic_and_mri_technologists",
  RADIOLOGIC_TECHNOLOGISTS_AND_TECHNICIANS = "radiologic_technologists_and_technicians",
  RAIL_TRANSPORTATION_OCCUPATIONS = "rail_transportation_occupations",
  RAILROAD_WORKERS = "railroad_workers",
  REAL_ESTATE_BROKERS_AND_SALES_AGENTS = "real_estate_brokers_and_sales_agents",
  RECEPTIONISTS = "receptionists",
  RECEPTIONISTS_AND_INFORMATION_CLERKS = "receptionists_and_information_clerks",
  RECREATION_WORKERS = "recreation_workers",
  RECREATIONAL_THERAPISTS = "recreational_therapists",
  REGISTERED_NURSES = "registered_nurses",
  REHABILITATION_COUNSELORS = "rehabilitation_counselors",
  REPORTERS_CORRESPONDENTS_AND_BROADCAST_NEWS_ANALYSTS = "reporters_correspondents_and_broadcast_news_analysts",
  RESERVATION_AND_TRANSPORTATION_TICKET_AGENTS_AND_TRAVEL_CLERKS = "reservation_and_transportation_ticket_agents_and_travel_clerks",
  RESPIRATORY_THERAPISTS = "respiratory_therapists",
  RETAIL_SALES_WORKERS = "retail_sales_workers",
  RETAIL_SALESPERSONS = "retail_salespersons",
  ROOFERS = "roofers",
  SALES_ENGINEERS = "sales_engineers",
  SALES_MANAGERS = "sales_managers",
  SALES_REPRESENTATIVES_WHOLESALE_AND_MANUFACTURING = "sales_representatives_wholesale_and_manufacturing",
  SCHOOL_AND_CAREER_COUNSELORS = "school_and_career_counselors",
  SECRETARIES_AND_ADMINISTRATIVE_ASSISTANTS = "secretaries_and_administrative_assistants",
  SECURITIES_COMMODITIES_AND_FINANCIAL_SERVICES_SALES_AGENTS = "securities_commodities_and_financial_services_sales_agents",
  SECURITY_GUARDS_AND_GAMING_SURVEILLANCE_OFFICERS = "security_guards_and_gaming_surveillance_officers",
  SHEET_METAL_WORKERS = "sheet_metal_workers",
  SHIPPING_RECEIVING_AND_TRAFFIC_CLERKS = "shipping_receiving_and_traffic_clerks",
  SKINCARE_SPECIALISTS = "skincare_specialists",
  SMALL_ENGINE_MECHANICS = "small_engine_mechanics",
  SOCIAL_AND_COMMUNITY_SERVICE_MANAGERS = "social_and_community_service_managers",
  SOCIAL_AND_HUMAN_SERVICE_ASSISTANTS = "social_and_human_service_assistants",
  SOCIAL_SCIENTISTS_OTHER = "social_scientists_other",
  SOCIAL_WORKERS = "social_workers",
  SALES_WORKER_SUPERVISORS = "sales_worker_supervisors",
  SOCIOLOGISTS = "sociologists",
  SOFTWARE_DEVELOPERS = "software_developers",
  SCIENCE_TECHNICIANS = "science_technicians",
  SOLAR_PHOTOVOLTAIC_INSTALLERS = "solar_photovoltaic_installers",
  SPECIAL_EDUCATION_TEACHERS = "special_education_teachers",
  SPEECH_LANGUAGE_PATHOLOGISTS = "speech_language_pathologists",
  STATIONARY_ENGINEERS_AND_BOILER_OPERATORS = "stationary_engineers_and_boiler_operators",
  SEMICONDUCTOR_PROCESSORS = "semiconductor_processors",
  STATISTICIANS = "statisticians",
  STOCK_CLERKS_AND_ORDER_FILLERS = "stock_clerks_and_order_fillers",
  STRUCTURAL_AND_REINFORCING_IRON_AND_METAL_WORKERS = "structural_and_reinforcing_iron_and_metal_workers",
  SUBSTANCE_ABUSE_BEHAVIORAL_DISORDER_AND_MENTAL_HEALTH_COUNSELORS = "substance_abuse_behavioral_disorder_and_mental_health_counselors",
  SUPPLY_MANAGEMENT = "supply_management",
  SURGICAL_TECHNOLOGISTS = "surgical_technologists",
  SURVEY_RESEARCHERS = "survey_researchers",
  SURVEYING_AND_MAPPING_TECHNICIANS = "surveying_and_mapping_technicians",
  SURVEYORS = "surveyors",
  SURVEYORS_CARTOGRAPHERS_PHOTOGRAMMETRISTS_AND_SURVEYING_AND_MAPPING_TECHNICIANS = "surveyors_cartographers_photogrammetrists_and_surveying_and_mapping_technicians",
  TAX_EXAMINERS_AND_COLLECTORS_AND_REVENUE_AGENTS = "tax_examiners_and_collectors_and_revenue_agents",
  TAX_EXAMINERS_COLLECTORS_AND_REVENUE_AGENTS = "tax_examiners_collectors_and_revenue_agents",
  TAXI_DRIVERS_AND_CHAUFFEURS = "taxi_drivers_and_chauffeurs",
  TAXI_DRIVERS_RIDE_HAILING_DRIVERS_AND_CHAUFFEURS = "taxi_drivers_ride_hailing_drivers_and_chauffeurs",
  TEACHER_ASSISTANTS = "teacher_assistants",
  TEACHERS_ADULT_LITERACY_AND_REMEDIAL_EDUCATION = "teachers_adult_literacy_and_remedial_education",
  TEACHERS_POSTSECONDARY = "teachers_postsecondary",
  TEACHERS_PRESCHOOL_KINDERGARTEN_ELEMENTARY_MIDDLE_AND_SECONDARY = "teachers_preschool_kindergarten_elementary_middle_and_secondary",
  TEACHERS_SPECIAL_EDUCATION = "teachers_special_education",
  TECHNICAL_WRITERS = "technical_writers",
  TELECOMMUNICATIONS_EQUIPMENT_INSTALLERS_AND_REPAIRERS = "telecommunications_equipment_installers_and_repairers",
  TELEVISION_VIDEO_AND_MOTION_PICTURE_CAMERA_OPERATORS_AND_EDITORS = "television_video_and_motion_picture_camera_operators_and_editors",
  TELLERS = "tellers",
  TOOL_AND_DIE_MAKERS = "tool_and_die_makers",
  TOP_EXECUTIVES = "top_executives",
  TRAINING_AND_DEVELOPMENT_MANAGERS = "training_and_development_managers",
  TRAINING_AND_DEVELOPMENT_SPECIALISTS = "training_and_development_specialists",
  TRAVEL_AGENTS = "travel_agents",
  TRUCK_DRIVERS_AND_DRIVERSALES_WORKERS = "truck_drivers_and_driversales_workers",
  UMPIRES_REFEREES_AND_OTHER_SPORTS_OFFICIALS = "umpires_referees_and_other_sports_officials",
  URBAN_AND_REGIONAL_PLANNERS = "urban_and_regional_planners",
  VETERINARIANS = "veterinarians",
  TEACHERS_SELF_ENRICHMENT_EDUCATION = "teachers_self_enrichment_education",
  VETERINARY_ASSISTANTS_AND_LABORATORY_ANIMAL_CARETAKERS = "veterinary_assistants_and_laboratory_animal_caretakers",
  VETERINARY_TECHNOLOGISTS_AND_TECHNICIANS = "veterinary_technologists_and_technicians",
  WAITERS_AND_WAITRESSES = "waiters_and_waitresses",
  WATER_AND_LIQUID_WASTE_TREATMENT_PLANT_AND_SYSTEM_OPERATORS = "water_and_liquid_waste_treatment_plant_and_system_operators",
  WATER_AND_WASTEWATER_TREATMENT_PLANT_AND_SYSTEM_OPERATORS = "water_and_wastewater_treatment_plant_and_system_operators",
  WATER_TRANSPORTATION_OCCUPATIONS = "water_transportation_occupations",
  TEXTILE_APPAREL_AND_FURNISHINGS_OCCUPATIONS = "textile_apparel_and_furnishings_occupations",
  WATER_TRANSPORTATION_WORKERS = "water_transportation_workers",
  WEB_DEVELOPERS = "web_developers",
  WEIGHERS_MEASURERS_CHECKERS_AND_SAMPLERS_RECORDKEEPING = "weighers_measurers_checkers_and_samplers_recordkeeping",
  WELDERS_CUTTERS_SOLDERERS_AND_BRAZERS = "welders_cutters_solderers_and_brazers",
  WELDING_SOLDERING_AND_BRAZING_WORKERS = "welding_soldering_and_brazing_workers",
  WHOLESALE_AND_MANUFACTURING_SALES_REPRESENTATIVES = "wholesale_and_manufacturing_sales_representatives",
  WIND_TURBINE_TECHNICIANS = "wind_turbine_technicians",
  WOODWORKERS = "woodworkers",
  WRITERS_AND_AUTHORS = "writers_and_authors",
  WRITERS_AND_EDITORS = "writers_and_editors",
  ZOOLOGISTS_AND_WILDLIFE_BIOLOGISTS = "zoologists_and_wildlife_biologists",
  OTHER = "other",
}

export class JobFunctionEnumUtils {
  private static getJobFunctionTitleInEnglish(
    jobFunction: JobFunction,
  ): string {
    switch (jobFunction) {
      case JobFunction.ACCOUNTANTS_AND_AUDITORS: {
        return "Accountants and Auditors";
      }
      case JobFunction.ACTORS_PRODUCERS_AND_DIRECTORS: {
        return "Actors, Producers, and Directors";
      }
      case JobFunction.ACTUARIES: {
        return "Actuaries";
      }
      case JobFunction.ADMINISTRATIVE_SERVICES_MANAGERS: {
        return "Administrative Services Managers";
      }
      case JobFunction.ADULT_LITERACY_AND_HIGH_SCHOOL_EQUIVALENCY_DIPLOMA_TEACHERS: {
        return "Adult Literacy and High School Equivalency Diploma Teachers";
      }
      case JobFunction.ADVERTISING_SALES_AGENTS: {
        return "Advertising Sales Agents";
      }
      case JobFunction.ADVERTISING_MARKETING_PROMOTIONS_PUBLIC_RELATIONS_AND_SALES_MANAGERS: {
        return "Advertising, Marketing, Promotions, Public Relations, and Sales Managers";
      }
      case JobFunction.AEROSPACE_ENGINEERING_AND_OPERATIONS_TECHNICIANS: {
        return "Aerospace Engineering and Operations Technicians";
      }
      case JobFunction.AEROSPACE_ENGINEERS: {
        return "Aerospace Engineers";
      }
      case JobFunction.AGRICULTURAL_AND_FOOD_SCIENCE_TECHNICIANS: {
        return "Agricultural and Food Science Technicians";
      }
      case JobFunction.AGRICULTURAL_AND_FOOD_SCIENTISTS: {
        return "Agricultural and Food Scientists";
      }
      case JobFunction.AGRICULTURAL_ENGINEERS: {
        return "Agricultural Engineers";
      }
      case JobFunction.AGRICULTURAL_WORKERS: {
        return "Agricultural Workers";
      }
      case JobFunction.AIR_TRAFFIC_CONTROLLERS: {
        return "Air Traffic Controllers";
      }
      case JobFunction.AIRCRAFT_AND_AVIONICS_EQUIPMENT_MECHANICS_AND_SERVICE_TECHNICIANS: {
        return "Aircraft and Avionics Equipment Mechanics and Service Technicians";
      }
      case JobFunction.AIRCRAFT_PILOTS_AND_FLIGHT_ENGINEERS: {
        return "Aircraft Pilots and Flight Engineers";
      }
      case JobFunction.ANIMAL_CARE_AND_SERVICE_WORKERS: {
        return "Animal Care and Service Workers";
      }
      case JobFunction.ANNOUNCERS: {
        return "Announcers";
      }
      case JobFunction.ANTHROPOLOGISTS_AND_ARCHEOLOGISTS: {
        return "Anthropologists and Archeologists";
      }
      case JobFunction.APPRAISERS_AND_ASSESSORS_OF_REAL_ESTATE: {
        return "Appraisers and Assessors of Real Estate";
      }
      case JobFunction.ARBITRATORS_MEDIATORS_AND_CONCILIATORS: {
        return "Arbitrators, Mediators, and Conciliators";
      }
      case JobFunction.ARCHITECTS_EXCEPT_LANDSCAPE_AND_NAVAL: {
        return "Architects, Except Landscape and Naval";
      }
      case JobFunction.ARCHITECTURAL_AND_ENGINEERING_MANAGERS: {
        return "Architectural and Engineering Managers";
      }
      case JobFunction.ARCHIVISTS_CURATORS_AND_MUSEUM_TECHNICIANS: {
        return "Archivists, Curators, and Museum Technicians";
      }
      case JobFunction.ART_DIRECTORS: {
        return "Art Directors";
      }
      case JobFunction.ARTISTS_AND_RELATED_WORKERS: {
        return "Artists and Related Workers";
      }
      case JobFunction.ASSEMBLERS_AND_FABRICATORS: {
        return "Assemblers and Fabricators";
      }
      case JobFunction.ATHLETES_COACHES_UMPIRES_AND_RELATED_WORKERS: {
        return "Athletes, Coaches, Umpires, and Related Workers";
      }
      case JobFunction.ATHLETIC_TRAINERS: {
        return "Athletic Trainers";
      }
      case JobFunction.ATMOSPHERIC_SCIENTISTS: {
        return "Atmospheric Scientists";
      }
      case JobFunction.AUDIOLOGISTS: {
        return "Audiologists";
      }
      case JobFunction.AUTOMOTIVE_BODY_AND_RELATED_REPAIRERS: {
        return "Automotive Body and Related Repairers";
      }
      case JobFunction.AUTOMOTIVE_SERVICE_TECHNICIANS_AND_MECHANICS: {
        return "Automotive Service Technicians and Mechanics";
      }
      case JobFunction.BAKERS: {
        return "Bakers";
      }
      case JobFunction.BARBERS_COSMETOLOGISTS_AND_OTHER_PERSONAL_APPEARANCE_WORKERS: {
        return "Barbers, Cosmetologists, and Other Personal Appearance Workers";
      }
      case JobFunction.BARTENDERS: {
        return "Bartenders";
      }
      case JobFunction.BILL_AND_ACCOUNT_COLLECTORS: {
        return "Bill and Account Collectors";
      }
      case JobFunction.BILLING_AND_POSTING_CLERKS_AND_MACHINE_OPERATORS: {
        return "Billing and Posting Clerks and Machine Operators";
      }
      case JobFunction.BIOLOGICAL_SCIENTISTS: {
        return "Biological Scientists";
      }
      case JobFunction.BIOLOGICAL_TECHNICIANS: {
        return "Biological Technicians";
      }
      case JobFunction.BIOMEDICAL_ENGINEERS: {
        return "Biomedical Engineers";
      }
      case JobFunction.BOILERMAKERS: {
        return "Boilermakers";
      }
      case JobFunction.BOOKBINDERS_AND_BINDERY_WORKERS: {
        return "Bookbinders and Bindery Workers";
      }
      case JobFunction.BOOKKEEPING_ACCOUNTING_AND_AUDITING_CLERKS: {
        return "Bookkeeping, Accounting, and Auditing Clerks";
      }
      case JobFunction.BRICKMASONS_BLOCKMASONS_AND_STONEMASONS: {
        return "Brickmasons, Blockmasons, and Stonemasons";
      }
      case JobFunction.BROADCAST_AND_SOUND_ENGINEERING_TECHNICIANS_AND_RADIO_OPERATORS: {
        return "Broadcast and Sound Engineering Technicians and Radio Operators";
      }
      case JobFunction.BROKERAGE_CLERKS: {
        return "Brokerage Clerks";
      }
      case JobFunction.BUDGET_ANALYSTS: {
        return "Budget Analysts";
      }
      case JobFunction.BUILDING_CLEANING_WORKERS: {
        return "Building Cleaning Workers";
      }
      case JobFunction.BUS_DRIVERS: {
        return "Bus Drivers";
      }
      case JobFunction.BUTCHERS: {
        return "Butchers";
      }
      case JobFunction.CARDIOVASCULAR_TECHNOLOGISTS_AND_TECHNICIANS: {
        return "Cardiovascular Technologists and Technicians";
      }
      case JobFunction.CAREER_AND_TECHNICAL_EDUCATION_TEACHERS: {
        return "Career and Technical Education Teachers";
      }
      case JobFunction.CARGO_AND_FREIGHT_AGENTS: {
        return "Cargo and Freight Agents";
      }
      case JobFunction.CARPENTERS: {
        return "Carpenters";
      }
      case JobFunction.CARPET_FLOOR_AND_TILE_INSTALLERS_AND_FINISHERS: {
        return "Carpet, Floor, and Tile Installers and Finishers";
      }
      case JobFunction.CARTOGRAPHERS_AND_PHOTOGRAMMETRISTS: {
        return "Cartographers and Photogrammetrists";
      }
      case JobFunction.CASHIERS: {
        return "Cashiers";
      }
      case JobFunction.CEMENT_MASONS_CONCRETE_FINISHERS_SEGMENTAL_PAVERS_AND_TERRAZZO_WORKERS: {
        return "Cement Masons, Concrete Finishers, Segmental Pavers, and Terrazzo Workers";
      }
      case JobFunction.CHEFS_AND_HEAD_COOKS: {
        return "Chefs and Head Cooks";
      }
      case JobFunction.CHEFS_COOKS_AND_FOOD_PREPARATION_WORKERS: {
        return "Chefs, Cooks, and Food Preparation Workers";
      }
      case JobFunction.CHEMICAL_ENGINEERS: {
        return "Chemical Engineers";
      }
      case JobFunction.CHEMICAL_TECHNICIANS: {
        return "Chemical Technicians";
      }
      case JobFunction.CHEMISTS_AND_MATERIALS_SCIENTISTS: {
        return "Chemists and Materials Scientists";
      }
      case JobFunction.CHILD_CARE_WORKERS: {
        return "Child Care Workers";
      }
      case JobFunction.CHIROPRACTORS: {
        return "Chiropractors";
      }
      case JobFunction.CIVIL_ENGINEERING_TECHNICIANS: {
        return "Civil Engineering Technicians";
      }
      case JobFunction.CIVIL_ENGINEERS: {
        return "Civil Engineers";
      }
      case JobFunction.CLAIMS_ADJUSTERS_APPRAISERS_EXAMINERS_AND_INVESTIGATORS: {
        return "Claims Adjusters, Appraisers, Examiners, and Investigators";
      }
      case JobFunction.CLINICAL_LABORATORY_TECHNOLOGISTS_AND_TECHNICIANS: {
        return "Clinical Laboratory Technologists and Technicians";
      }
      case JobFunction.COACHES_AND_SCOUTS: {
        return "Coaches and Scouts";
      }
      case JobFunction.COIN_VENDING_AND_AMUSEMENT_MACHINE_SERVICERS_AND_REPAIRERS: {
        return "Coin, Vending, and Amusement Machine Servicers and Repairers";
      }
      case JobFunction.COMMERCIAL_AND_INDUSTRIAL_DESIGNERS: {
        return "Commercial and Industrial Designers";
      }
      case JobFunction.COMMERCIAL_MANAGEMENT: {
        return "Commercial management";
      }
      case JobFunction.COMMUNICATIONS_EQUIPMENT_OPERATORS: {
        return "Communications Equipment Operators";
      }
      case JobFunction.COMPENSATION_AND_BENEFITS_MANAGERS: {
        return "Compensation and Benefits Managers";
      }
      case JobFunction.COMPENSATION_BENEFITS_AND_JOB_ANALYSIS_SPECIALISTS: {
        return "Compensation, Benefits, and Job Analysis Specialists";
      }
      case JobFunction.COMPUTER_AND_INFORMATION_RESEARCH_SCIENTISTS: {
        return "Computer and Information Research Scientists";
      }
      case JobFunction.COMPUTER_AND_INFORMATION_SYSTEMS_MANAGERS: {
        return "Computer and Information Systems Managers";
      }
      case JobFunction.COMPUTER_CONTROL_PROGRAMMERS_AND_OPERATORS: {
        return "Computer Control Programmers and Operators";
      }
      case JobFunction.COMPUTER_HARDWARE_ENGINEERS: {
        return "Computer Hardware Engineers";
      }
      case JobFunction.COMPUTER_NETWORK_ARCHITECTS: {
        return "Computer Network Architects";
      }
      case JobFunction.COMPUTER_OPERATORS: {
        return "Computer Operators";
      }
      case JobFunction.COMPUTER_PROGRAMMERS: {
        return "Computer Programmers";
      }
      case JobFunction.COMPUTER_SCIENTISTS_AND_DATABASE_ADMINISTRATORS: {
        return "Computer Scientists and Database Administrators";
      }
      case JobFunction.COMPUTER_SOFTWARE_ENGINEERS: {
        return "Computer Software Engineers";
      }
      case JobFunction.COMPUTER_SUPPORT_SPECIALISTS: {
        return "Computer Support Specialists";
      }
      case JobFunction.COMPUTER_SUPPORT_SPECIALISTS_AND_SYSTEMS_ADMINISTRATORS: {
        return "Computer Support Specialists and Systems Administrators";
      }
      case JobFunction.COMPUTER_SYSTEMS_ANALYSTS: {
        return "Computer Systems Analysts";
      }
      case JobFunction.COMPUTER_AUTOMATED_TELLER_AND_OFFICE_MACHINE_REPAIRERS: {
        return "Computer, Automated Teller, and Office Machine Repairers";
      }
      case JobFunction.CONSERVATION_SCIENTISTS_AND_FORESTERS: {
        return "Conservation Scientists and Foresters";
      }
      case JobFunction.CONSTRUCTION_AND_BUILDING_INSPECTORS: {
        return "Construction and Building Inspectors";
      }
      case JobFunction.CONSTRUCTION_EQUIPMENT_OPERATORS: {
        return "Construction Equipment Operators";
      }
      case JobFunction.CONSTRUCTION_LABORERS: {
        return "Construction Laborers";
      }
      case JobFunction.CONSTRUCTION_LABORERS_AND_HELPERS: {
        return "Construction Laborers and Helpers";
      }
      case JobFunction.CONSTRUCTION_MANAGERS: {
        return "Construction Managers";
      }
      case JobFunction.COOKS: {
        return "Cooks";
      }
      case JobFunction.CORRECTIONAL_OFFICERS: {
        return "Correctional Officers";
      }
      case JobFunction.CONTRACT_MANAGEMENT: {
        return "Contract management";
      }
      case JobFunction.CORRECTIONAL_OFFICERS_AND_BAILIFFS: {
        return "Correctional Officers and Bailiffs";
      }
      case JobFunction.COST_ESTIMATORS: {
        return "Cost Estimators";
      }
      case JobFunction.COUNSELORS: {
        return "Counselors";
      }
      case JobFunction.COUNTER_AND_RENTAL_CLERKS: {
        return "Counter and Rental Clerks";
      }
      case JobFunction.COURIERS_AND_MESSENGERS: {
        return "Couriers and Messengers";
      }
      case JobFunction.COURT_REPORTERS: {
        return "Court Reporters";
      }
      case JobFunction.CRAFT_AND_FINE_ARTISTS: {
        return "Craft and Fine Artists";
      }
      case JobFunction.CREDIT_AUTHORIZERS_CHECKERS_AND_CLERKS: {
        return "Credit Authorizers, Checkers, and Clerks";
      }
      case JobFunction.CUSTOMER_SERVICE_REPRESENTATIVES: {
        return "Customer Service Representatives";
      }
      case JobFunction.DANCERS_AND_CHOREOGRAPHERS: {
        return "Dancers and Choreographers";
      }
      case JobFunction.DATA_ENTRY_AND_INFORMATION_PROCESSING_WORKERS: {
        return "Data Entry and Information Processing Workers";
      }
      case JobFunction.DATABASE_ADMINISTRATORS: {
        return "Database Administrators";
      }
      case JobFunction.DELIVERY_TRUCK_DRIVERS_AND_DRIVERSALES_WORKERS: {
        return "Delivery Truck Drivers and Driver/Sales Workers";
      }
      case JobFunction.DEMONSTRATORS_PRODUCT_PROMOTERS_AND_MODELS: {
        return "Demonstrators, Product Promoters, and Models";
      }
      case JobFunction.DENTAL_AND_OPHTHALMIC_LABORATORY_TECHNICIANS_AND_MEDICAL_APPLIANCE_TECHNICIANS: {
        return "Dental and Ophthalmic Laboratory Technicians and Medical Appliance Technicians";
      }
      case JobFunction.DENTAL_ASSISTANTS: {
        return "Dental Assistants";
      }
      case JobFunction.DENTAL_HYGIENISTS: {
        return "Dental Hygienists";
      }
      case JobFunction.DENTISTS: {
        return "Dentists";
      }
      case JobFunction.DESKTOP_PUBLISHERS: {
        return "Desktop Publishers";
      }
      case JobFunction.DIAGNOSTIC_MEDICAL_SONOGRAPHERS: {
        return "Diagnostic Medical Sonographers";
      }
      case JobFunction.DIESEL_SERVICE_TECHNICIANS_AND_MECHANICS: {
        return "Diesel Service Technicians and Mechanics";
      }
      case JobFunction.DIETITIANS_AND_NUTRITIONISTS: {
        return "Dietitians and Nutritionists";
      }
      case JobFunction.DISPATCHERS: {
        return "Dispatchers";
      }
      case JobFunction.DRAFTERS: {
        return "Drafters";
      }
      case JobFunction.DRYWALL_AND_CEILING_TILE_INSTALLERS_AND_TAPERS: {
        return "Drywall and Ceiling Tile Installers, and Tapers";
      }
      case JobFunction.DRYWALL_INSTALLERS_CEILING_TILE_INSTALLERS_AND_TAPERS: {
        return "Drywall Installers, Ceiling Tile Installers, and Tapers";
      }
      case JobFunction.ECONOMISTS: {
        return "Economists";
      }
      case JobFunction.EDITORS: {
        return "Editors";
      }
      case JobFunction.EDUCATION_ADMINISTRATORS: {
        return "Education Administrators";
      }
      case JobFunction.ELECTRICAL_AND_ELECTRONICS_ENGINEERING_TECHNICIANS: {
        return "Electrical and Electronics Engineering Technicians";
      }
      case JobFunction.ELECTRICAL_AND_ELECTRONICS_ENGINEERS: {
        return "Electrical and Electronics Engineers";
      }
      case JobFunction.ELECTRICAL_AND_ELECTRONICS_INSTALLERS_AND_REPAIRERS: {
        return "Electrical and Electronics Installers and Repairers";
      }
      case JobFunction.ELECTRICIANS: {
        return "Electricians";
      }
      case JobFunction.ELECTRO_MECHANICAL_TECHNICIANS: {
        return "Electro-mechanical Technicians";
      }
      case JobFunction.ELECTRONIC_HOME_ENTERTAINMENT_EQUIPMENT_INSTALLERS_AND_REPAIRERS: {
        return "Electronic Home Entertainment Equipment Installers and Repairers";
      }
      case JobFunction.ELEMENTARY_MIDDLE_AND_HIGH_SCHOOL_PRINCIPALS: {
        return "Elementary, Middle, and High School Principals";
      }
      case JobFunction.ELEVATOR_INSTALLERS_AND_REPAIRERS: {
        return "Elevator Installers and Repairers";
      }
      case JobFunction.EMERGENCY_MANAGEMENT_DIRECTORS: {
        return "Emergency Management Directors";
      }
      case JobFunction.EMERGENCY_MEDICAL_TECHNICIANS_AND_PARAMEDICS: {
        return "Emergency Medical Technicians and Paramedics";
      }
      case JobFunction.EMTS_AND_PARAMEDICS: {
        return "EMTs and Paramedics";
      }
      case JobFunction.ENGINEERING_AND_NATURAL_SCIENCES_MANAGERS: {
        return "Engineering and Natural Sciences Managers";
      }
      case JobFunction.ENGINEERING_TECHNICIANS: {
        return "Engineering Technicians";
      }
      case JobFunction.ENVIRONMENTAL_ENGINEERING_TECHNICIANS: {
        return "Environmental Engineering Technicians";
      }
      case JobFunction.ENVIRONMENTAL_ENGINEERS: {
        return "Environmental Engineers";
      }
      case JobFunction.ENVIRONMENTAL_SCIENCE_AND_PROTECTION_TECHNICIANS: {
        return "Environmental Science and Protection Technicians";
      }
      case JobFunction.ENVIRONMENTAL_SCIENTISTS_AND_HYDROLOGISTS: {
        return "Environmental Scientists and Hydrologists";
      }
      case JobFunction.ENVIRONMENTAL_SCIENTISTS_AND_SPECIALISTS: {
        return "Environmental Scientists and Specialists";
      }
      case JobFunction.EPIDEMIOLOGISTS: {
        return "Epidemiologists";
      }
      case JobFunction.EXERCISE_PHYSIOLOGISTS: {
        return "Exercise Physiologists";
      }
      case JobFunction.FARMERS_RANCHERS_AND_AGRICULTURAL_MANAGERS: {
        return "Farmers, Ranchers, and Agricultural Managers";
      }
      case JobFunction.FARMERS_RANCHERS_AND_OTHER_AGRICULTURAL_MANAGERS: {
        return "Farmers, Ranchers, and Other Agricultural Managers";
      }
      case JobFunction.FASHION_DESIGNERS: {
        return "Fashion Designers";
      }
      case JobFunction.FILE_CLERKS: {
        return "File Clerks";
      }
      case JobFunction.FILM_AND_VIDEO_EDITORS_AND_CAMERA_OPERATORS: {
        return "Film and Video Editors and Camera Operators";
      }
      case JobFunction.FINANCIAL_ANALYSTS: {
        return "Financial Analysts";
      }
      case JobFunction.FINANCIAL_ANALYSTS_AND_PERSONAL_FINANCIAL_ADVISORS: {
        return "Financial Analysts and Personal Financial Advisors";
      }
      case JobFunction.FINANCIAL_CLERKS: {
        return "Financial Clerks";
      }
      case JobFunction.FINANCIAL_EXAMINERS: {
        return "Financial Examiners";
      }
      case JobFunction.FINANCIAL_MANAGERS: {
        return "Financial Managers";
      }
      case JobFunction.FIRE_FIGHTING_OCCUPATIONS: {
        return "Fire Fighting Occupations";
      }
      case JobFunction.FIRE_INSPECTORS: {
        return "Fire Inspectors";
      }
      case JobFunction.FIREFIGHTERS: {
        return "Firefighters";
      }
      case JobFunction.FISHERS_AND_FISHING_VESSEL_OPERATORS: {
        return "Fishers and Fishing Vessel Operators";
      }
      case JobFunction.FISHING_AND_HUNTING_WORKERS: {
        return "Fishing and Hunting Workers";
      }
      case JobFunction.FITNESS_TRAINERS_AND_INSTRUCTORS: {
        return "Fitness Trainers and Instructors";
      }
      case JobFunction.FITNESS_WORKERS: {
        return "Fitness Workers";
      }
      case JobFunction.FLIGHT_ATTENDANTS: {
        return "Flight Attendants";
      }
      case JobFunction.FLOORING_INSTALLERS_AND_TILE_AND_MARBLE_SETTERS: {
        return "Flooring Installers and Tile and Marble Setters";
      }
      case JobFunction.FLORAL_DESIGNERS: {
        return "Floral Designers";
      }
      case JobFunction.FOOD_AND_BEVERAGE_SERVING_AND_RELATED_WORKERS: {
        return "Food and Beverage Serving and Related Workers";
      }
      case JobFunction.FOOD_AND_TOBACCO_PROCESSING_WORKERS: {
        return "Food and Tobacco Processing Workers";
      }
      case JobFunction.FOOD_PROCESSING_OCCUPATIONS: {
        return "Food Processing Occupations";
      }
      case JobFunction.FOOD_SERVICE_MANAGERS: {
        return "Food Service Managers";
      }
      case JobFunction.FORENSIC_SCIENCE_TECHNICIANS: {
        return "Forensic Science Technicians";
      }
      case JobFunction.FOREST_AND_CONSERVATION_WORKERS: {
        return "Forest and Conservation Workers";
      }
      case JobFunction.FOREST_CONSERVATION_AND_LOGGING_WORKERS: {
        return "Forest, Conservation, and Logging Workers";
      }
      case JobFunction.FUNDRAISERS: {
        return "Fundraisers";
      }
      case JobFunction.FUNERAL_DIRECTORS: {
        return "Funeral Directors";
      }
      case JobFunction.FUNERAL_SERVICE_WORKERS: {
        return "Funeral Service Workers";
      }
      case JobFunction.GAMING_CAGE_WORKERS: {
        return "Gaming Cage Workers";
      }
      case JobFunction.GAMING_SERVICES_OCCUPATIONS: {
        return "Gaming Services Occupations";
      }
      case JobFunction.GAMING_SERVICES_WORKERS: {
        return "Gaming Services Workers";
      }
      case JobFunction.GENERAL_MAINTENANCE_AND_REPAIR_WORKERS: {
        return "General Maintenance and Repair Workers";
      }
      case JobFunction.GENERAL_OFFICE_CLERKS: {
        return "General Office Clerks";
      }
      case JobFunction.GENETIC_COUNSELORS: {
        return "Genetic Counselors";
      }
      case JobFunction.GEOGRAPHERS: {
        return "Geographers";
      }
      case JobFunction.GEOLOGICAL_AND_PETROLEUM_TECHNICIANS: {
        return "Geological and Petroleum Technicians";
      }
      case JobFunction.GEOSCIENTISTS: {
        return "Geoscientists";
      }
      case JobFunction.GLAZIERS: {
        return "Glaziers";
      }
      case JobFunction.GRAPHIC_DESIGNERS: {
        return "Graphic Designers";
      }
      case JobFunction.GROUNDS_MAINTENANCE_WORKERS: {
        return "Grounds Maintenance Workers";
      }
      case JobFunction.HAND_LABORERS_AND_MATERIAL_MOVERS: {
        return "Hand Laborers and Material Movers";
      }
      case JobFunction.HAZARDOUS_MATERIALS_REMOVAL_WORKERS: {
        return "Hazardous Materials Removal Workers";
      }
      case JobFunction.HEALTH_AND_SAFETY_ENGINEERS: {
        return "Health and Safety Engineers";
      }
      case JobFunction.HEALTH_EDUCATORS: {
        return "Health Educators";
      }
      case JobFunction.HEALTH_EDUCATORS_AND_COMMUNITY_HEALTH_WORKERS: {
        return "Health Educators and Community Health Workers";
      }
      case JobFunction.HEATING_AIR_CONDITIONING_AND_REFRIGERATION_MECHANICS_AND_INSTALLERS: {
        return "Heating, Air Conditioning, and Refrigeration Mechanics and Installers";
      }
      case JobFunction.HEAVY_AND_TRACTOR_TRAILER_TRUCK_DRIVERS: {
        return "Heavy and Tractor-trailer Truck Drivers";
      }
      case JobFunction.HEAVY_VEHICLE_AND_MOBILE_EQUIPMENT_SERVICE_TECHNICIANS: {
        return "Heavy Vehicle and Mobile Equipment Service Technicians";
      }
      case JobFunction.HEAVY_VEHICLE_AND_MOBILE_EQUIPMENT_SERVICE_TECHNICIANS_AND_MECHANICS: {
        return "Heavy Vehicle and Mobile Equipment Service Technicians and Mechanics";
      }
      case JobFunction.HIGH_SCHOOL_TEACHERS: {
        return "High School Teachers";
      }
      case JobFunction.HISTORIANS: {
        return "Historians";
      }
      case JobFunction.HOME_APPLIANCE_REPAIRERS: {
        return "Home Appliance Repairers";
      }
      case JobFunction.HOME_HEALTH_AIDES_AND_PERSONAL_CARE_AIDES: {
        return "Home Health Aides and Personal Care Aides";
      }
      case JobFunction.HOTEL_MOTEL_AND_RESORT_DESK_CLERKS: {
        return "Hotel, Motel, and Resort Desk Clerks";
      }
      case JobFunction.HUMAN_RESOURCES_ASSISTANTS_EXCEPT_PAYROLL_AND_TIMEKEEPING: {
        return "Human Resources Assistants, Except Payroll and Timekeeping";
      }
      case JobFunction.HUMAN_RESOURCES_MANAGERS: {
        return "Human Resources Managers";
      }
      case JobFunction.HUMAN_RESOURCES_SPECIALISTS: {
        return "Human Resources Specialists";
      }
      case JobFunction.HUMAN_RESOURCES_TRAINING_AND_LABOR_RELATIONS_MANAGERS_AND_SPECIALISTS: {
        return "Human Resources, Training, and Labor Relations Managers and Specialists";
      }
      case JobFunction.HYDROLOGISTS: {
        return "Hydrologists";
      }
      case JobFunction.INDUSTRIAL_DESIGNERS: {
        return "Industrial Designers";
      }
      case JobFunction.INDUSTRIAL_ENGINEERING_TECHNICIANS: {
        return "Industrial Engineering Technicians";
      }
      case JobFunction.INDUSTRIAL_ENGINEERS: {
        return "Industrial Engineers";
      }
      case JobFunction.INDUSTRIAL_MACHINERY_MECHANICS_AND_MAINTENANCE_WORKERS: {
        return "Industrial Machinery Mechanics and Maintenance Workers";
      }
      case JobFunction.INDUSTRIAL_PRODUCTION_MANAGERS: {
        return "Industrial Production Managers";
      }
      case JobFunction.INFORMATION_CLERKS: {
        return "Information Clerks";
      }
      case JobFunction.INFORMATION_SECURITY_ANALYSTS: {
        return "Information Security Analysts";
      }
      case JobFunction.INSPECTORS_TESTERS_SORTERS_SAMPLERS_AND_WEIGHERS: {
        return "Inspectors, Testers, Sorters, Samplers, and Weighers";
      }
      case JobFunction.INSTRUCTIONAL_COORDINATORS: {
        return "Instructional Coordinators";
      }
      case JobFunction.INSULATION_WORKERS: {
        return "Insulation Workers";
      }
      case JobFunction.INSURANCE_SALES_AGENTS: {
        return "Insurance Sales Agents";
      }
      case JobFunction.INSURANCE_UNDERWRITERS: {
        return "Insurance Underwriters";
      }
      case JobFunction.INTERIOR_DESIGNERS: {
        return "Interior Designers";
      }
      case JobFunction.INTERPRETERS_AND_TRANSLATORS: {
        return "Interpreters and Translators";
      }
      case JobFunction.IRONWORKERS: {
        return "Ironworkers";
      }
      case JobFunction.JEWELERS_AND_PRECIOUS_STONE_AND_METAL_WORKERS: {
        return "Jewelers and Precious Stone and Metal Workers";
      }
      case JobFunction.JOB_OPPORTUNITIES_IN_THE_ARMED_FORCES: {
        return "Job Opportunities in the Armed Forces";
      }
      case JobFunction.JUDGES_AND_HEARING_OFFICERS: {
        return "Judges and Hearing Officers";
      }
      case JobFunction.JUDGES_MAGISTRATES_AND_OTHER_JUDICIAL_WORKERS: {
        return "Judges, Magistrates, and Other Judicial Workers";
      }
      case JobFunction.KINDERGARTEN_AND_ELEMENTARY_SCHOOL_TEACHERS: {
        return "Kindergarten and Elementary School Teachers";
      }
      case JobFunction.LABOR_RELATIONS_SPECIALISTS: {
        return "Labor Relations Specialists";
      }
      case JobFunction.LANDSCAPE_ARCHITECTS: {
        return "Landscape Architects";
      }
      case JobFunction.LAWYERS: {
        return "Lawyers";
      }
      case JobFunction.LIBRARIANS: {
        return "Librarians";
      }
      case JobFunction.LIBRARY_TECHNICIANS_AND_ASSISTANTS: {
        return "Library Technicians and Assistants";
      }
      case JobFunction.LICENSED_PRACTICAL_AND_LICENSED_VOCATIONAL_NURSES: {
        return "Licensed Practical and Licensed Vocational Nurses";
      }
      case JobFunction.LINE_INSTALLERS_AND_REPAIRERS: {
        return "Line Installers and Repairers";
      }
      case JobFunction.LOAN_OFFICERS: {
        return "Loan Officers";
      }
      case JobFunction.LODGING_MANAGERS: {
        return "Lodging Managers";
      }
      case JobFunction.LOGGING_WORKERS: {
        return "Logging Workers";
      }
      case JobFunction.LOGISTICIANS: {
        return "Logisticians";
      }
      case JobFunction.MACHINE_SETTERS_OPERATORS_AND_TENDERS_METAL_AND_PLASTIC: {
        return "Machine Setters, Operators, and Tenders-Metal and Plastic";
      }
      case JobFunction.MACHINISTS: {
        return "Machinists";
      }
      case JobFunction.MACHINISTS_AND_TOOL_AND_DIE_MAKERS: {
        return "Machinists and Tool and Die Makers";
      }
      case JobFunction.MAINTENANCE_AND_REPAIR_WORKERS_GENERAL: {
        return "Maintenance and Repair Workers, General";
      }
      case JobFunction.MANAGEMENT_ANALYSTS: {
        return "Management Analysts";
      }
      case JobFunction.MANICURISTS_AND_PEDICURISTS: {
        return "Manicurists and Pedicurists";
      }
      case JobFunction.MARINE_ENGINEERS_AND_NAVAL_ARCHITECTS: {
        return "Marine Engineers and Naval Architects";
      }
      case JobFunction.MARKET_AND_SURVEY_RESEARCHERS: {
        return "Market and Survey Researchers";
      }
      case JobFunction.MARKET_RESEARCH_ANALYSTS: {
        return "Market Research Analysts";
      }
      case JobFunction.MARRIAGE_AND_FAMILY_THERAPISTS: {
        return "Marriage and Family Therapists";
      }
      case JobFunction.MASSAGE_THERAPISTS: {
        return "Massage Therapists";
      }
      case JobFunction.MATERIAL_MOVING_MACHINE_OPERATORS: {
        return "Material Moving Machine Operators";
      }
      case JobFunction.MATERIAL_MOVING_OCCUPATIONS: {
        return "Material Moving Occupations";
      }
      case JobFunction.MATERIAL_RECORDING_CLERKS: {
        return "Material Recording Clerks";
      }
      case JobFunction.MATERIALS_ENGINEERS: {
        return "Materials Engineers";
      }
      case JobFunction.MATHEMATICIANS: {
        return "Mathematicians";
      }
      case JobFunction.MATHEMATICIANS_AND_STATISTICIANS: {
        return "Mathematicians and Statisticians";
      }
      case JobFunction.MECHANICAL_ENGINEERING_TECHNICIANS: {
        return "Mechanical Engineering Technicians";
      }
      case JobFunction.MECHANICAL_ENGINEERS: {
        return "Mechanical Engineers";
      }
      case JobFunction.MEDICAL_AND_CLINICAL_LABORATORY_TECHNOLOGISTS_AND_TECHNICIANS: {
        return "Medical and Clinical Laboratory Technologists and Technicians";
      }
      case JobFunction.MEDICAL_AND_HEALTH_SERVICES_MANAGERS: {
        return "Medical and Health Services Managers";
      }
      case JobFunction.MEDICAL_ASSISTANTS: {
        return "Medical Assistants";
      }
      case JobFunction.MEDICAL_EQUIPMENT_REPAIRERS: {
        return "Medical Equipment Repairers";
      }
      case JobFunction.MEDICAL_RECORDS_AND_HEALTH_INFORMATION_TECHNICIANS: {
        return "Medical Records and Health Information Technicians";
      }
      case JobFunction.MEDICAL_SCIENTISTS: {
        return "Medical Scientists";
      }
      case JobFunction.MEDICAL_TRANSCRIPTIONISTS: {
        return "Medical Transcriptionists";
      }
      case JobFunction.MEDICAL_DENTAL_AND_OPHTHALMIC_LABORATORY_TECHNICIANS: {
        return "Medical, Dental, and Ophthalmic Laboratory Technicians";
      }
      case JobFunction.MEETING_AND_CONVENTION_PLANNERS: {
        return "Meeting and Convention Planners";
      }
      case JobFunction.MEETING_CONVENTION_AND_EVENT_PLANNERS: {
        return "Meeting, Convention, and Event Planners";
      }
      case JobFunction.METAL_AND_PLASTIC_MACHINE_WORKERS: {
        return "Metal and Plastic Machine Workers";
      }
      case JobFunction.MICROBIOLOGISTS: {
        return "Microbiologists";
      }
      case JobFunction.MIDDLE_SCHOOL_TEACHERS: {
        return "Middle School Teachers";
      }
      case JobFunction.MILITARY_CAREERS: {
        return "Military Careers";
      }
      case JobFunction.MILLWRIGHTS: {
        return "Millwrights";
      }
      case JobFunction.MINING_AND_GEOLOGICAL_ENGINEERS: {
        return "Mining and Geological Engineers";
      }
      case JobFunction.MODELS: {
        return "Models";
      }
      case JobFunction.METER_READERS_UTILITIES: {
        return "Meter Readers, Utilities";
      }
      case JobFunction.MULTIMEDIA_ARTISTS_AND_ANIMATORS: {
        return "Multimedia Artists and Animators";
      }
      case JobFunction.MUSIC_DIRECTORS_AND_COMPOSERS: {
        return "Music Directors and Composers";
      }
      case JobFunction.MUSICIANS_AND_SINGERS: {
        return "Musicians and Singers";
      }
      case JobFunction.MUSICIANS_SINGERS_AND_RELATED_WORKERS: {
        return "Musicians, Singers, and Related Workers";
      }
      case JobFunction.NATURAL_SCIENCES_MANAGERS: {
        return "Natural Sciences Managers";
      }
      case JobFunction.NETWORK_AND_COMPUTER_SYSTEMS_ADMINISTRATORS: {
        return "Network and Computer Systems Administrators";
      }
      case JobFunction.NEWS_ANALYSTS_REPORTERS_AND_CORRESPONDENTS: {
        return "News Analysts, Reporters, and Correspondents";
      }
      case JobFunction.NUCLEAR_ENGINEERS: {
        return "Nuclear Engineers";
      }
      case JobFunction.NUCLEAR_MEDICINE_TECHNOLOGISTS: {
        return "Nuclear Medicine Technologists";
      }
      case JobFunction.NUCLEAR_TECHNICIANS: {
        return "Nuclear Technicians";
      }
      case JobFunction.NURSE_ANESTHETISTS_NURSE_MIDWIVES_AND_NURSE_PRACTITIONERS: {
        return "Nurse Anesthetists, Nurse Midwives, and Nurse Practitioners";
      }
      case JobFunction.NURSING_ASSISTANTS_AND_ORDERLIES: {
        return "Nursing Assistants and Orderlies";
      }
      case JobFunction.NURSING_PSYCHIATRIC_AND_HOME_HEALTH_AIDES: {
        return "Nursing, Psychiatric, and Home Health Aides";
      }
      case JobFunction.OCCUPATIONAL_HEALTH_AND_SAFETY_SPECIALISTS_AND_TECHNICIANS: {
        return "Occupational Health and Safety Specialists and Technicians";
      }
      case JobFunction.OCCUPATIONAL_THERAPIST_ASSISTANTS_AND_AIDES: {
        return "Occupational Therapist Assistants and Aides";
      }
      case JobFunction.OCCUPATIONAL_THERAPISTS: {
        return "Occupational Therapists";
      }
      case JobFunction.OCCUPATIONAL_THERAPY_ASSISTANTS_AND_AIDES: {
        return "Occupational Therapy Assistants and Aides";
      }
      case JobFunction.OFFICE_AND_ADMINISTRATIVE_SUPPORT_WORKER_SUPERVISORS_AND_MANAGERS: {
        return "Office and Administrative Support Worker Supervisors and Managers";
      }
      case JobFunction.OFFICE_CLERKS_GENERAL: {
        return "Office Clerks, General";
      }
      case JobFunction.OPERATIONS_RESEARCH_ANALYSTS: {
        return "Operations Research Analysts";
      }
      case JobFunction.OPTICIANS: {
        return "Opticians";
      }
      case JobFunction.OPTICIANS_DISPENSING: {
        return "Opticians, Dispensing";
      }
      case JobFunction.OPTOMETRISTS: {
        return "Optometrists";
      }
      case JobFunction.ORDER_CLERKS: {
        return "Order Clerks";
      }
      case JobFunction.ORTHOTISTS_AND_PROSTHETISTS: {
        return "Orthotists and Prosthetists";
      }
      case JobFunction.PAINTERS_AND_PAPERHANGERS: {
        return "Painters and Paperhangers";
      }
      case JobFunction.PAINTERS_CONSTRUCTION_AND_MAINTENANCE: {
        return "Painters, Construction and Maintenance";
      }
      case JobFunction.PAINTING_AND_COATING_WORKERS: {
        return "Painting and Coating Workers";
      }
      case JobFunction.PAINTING_AND_COATING_WORKERS_EXCEPT_CONSTRUCTION_AND_MAINTENANCE: {
        return "Painting and Coating Workers, Except Construction and Maintenance";
      }
      case JobFunction.PARALEGALS_AND_LEGAL_ASSISTANTS: {
        return "Paralegals and Legal Assistants";
      }
      case JobFunction.PAYROLL_AND_TIMEKEEPING_CLERKS: {
        return "Payroll and Timekeeping Clerks";
      }
      case JobFunction.PERSONAL_AND_HOME_CARE_AIDES: {
        return "Personal and Home Care Aides";
      }
      case JobFunction.PERSONAL_FINANCIAL_ADVISORS: {
        return "Personal Financial Advisors";
      }
      case JobFunction.PEST_CONTROL_WORKERS: {
        return "Pest Control Workers";
      }
      case JobFunction.PETROLEUM_ENGINEERS: {
        return "Petroleum Engineers";
      }
      case JobFunction.PHARMACISTS: {
        return "Pharmacists";
      }
      case JobFunction.PHARMACY_AIDES: {
        return "Pharmacy Aides";
      }
      case JobFunction.PHARMACY_TECHNICIANS: {
        return "Pharmacy Technicians";
      }
      case JobFunction.PHLEBOTOMISTS: {
        return "Phlebotomists";
      }
      case JobFunction.PHOTOGRAPHERS: {
        return "Photographers";
      }
      case JobFunction.PHYSICAL_THERAPIST_ASSISTANTS_AND_AIDES: {
        return "Physical Therapist Assistants and Aides";
      }
      case JobFunction.PHYSICAL_THERAPISTS: {
        return "Physical Therapists";
      }
      case JobFunction.PHYSICIAN_ASSISTANTS: {
        return "Physician Assistants";
      }
      case JobFunction.PHYSICIANS_AND_SURGEONS: {
        return "Physicians and Surgeons";
      }
      case JobFunction.PHYSICISTS_AND_ASTRONOMERS: {
        return "Physicists and Astronomers";
      }
      case JobFunction.PIPELAYERS_PLUMBERS_PIPEFITTERS_AND_STEAMFITTERS: {
        return "Pipelayers, Plumbers, Pipefitters, and Steamfitters";
      }
      case JobFunction.PHOTOGRAPHIC_PROCESS_WORKERS_AND_PROCESSING_MACHINE_OPERATORS: {
        return "Photographic Process Workers and Processing Machine Operators";
      }
      case JobFunction.PLUMBERS_PIPEFITTERS_AND_STEAMFITTERS: {
        return "Plumbers, Pipefitters, and Steamfitters";
      }
      case JobFunction.PODIATRISTS: {
        return "Podiatrists";
      }
      case JobFunction.POLICE_AND_DETECTIVES: {
        return "Police and Detectives";
      }
      case JobFunction.POLICE_FIRE_AND_AMBULANCE_DISPATCHERS: {
        return "Police, Fire, and Ambulance Dispatchers";
      }
      case JobFunction.POLITICAL_SCIENTISTS: {
        return "Political Scientists";
      }
      case JobFunction.POSTAL_SERVICE_WORKERS: {
        return "Postal Service Workers";
      }
      case JobFunction.POSTSECONDARY_EDUCATION_ADMINISTRATORS: {
        return "Postsecondary Education Administrators";
      }
      case JobFunction.POSTSECONDARY_TEACHERS: {
        return "Postsecondary Teachers";
      }
      case JobFunction.PLASTERERS_AND_STUCCO_MASONS: {
        return "Plasterers and Stucco Masons";
      }
      case JobFunction.POWER_PLANT_OPERATORS_DISTRIBUTORS_AND_DISPATCHERS: {
        return "Power Plant Operators, Distributors, and Dispatchers";
      }
      case JobFunction.PRESCHOOL_AND_CHILDCARE_CENTER_DIRECTORS: {
        return "Preschool and Childcare Center Directors";
      }
      case JobFunction.PRESCHOOL_TEACHERS: {
        return "Preschool Teachers";
      }
      case JobFunction.PRIVATE_DETECTIVES_AND_INVESTIGATORS: {
        return "Private Detectives and Investigators";
      }
      case JobFunction.PROBATION_OFFICERS_AND_CORRECTIONAL_TREATMENT_SPECIALISTS: {
        return "Probation Officers and Correctional Treatment Specialists";
      }
      case JobFunction.PROCUREMENT_CLERKS: {
        return "Procurement Clerks";
      }
      case JobFunction.PROCUREMENT_MANAGER: {
        return "Procurement manager";
      }
      case JobFunction.PRODUCERS_AND_DIRECTORS: {
        return "Producers and Directors";
      }
      case JobFunction.PRODUCTION_PLANNING_AND_EXPEDITING_CLERKS: {
        return "Production, Planning, and Expediting Clerks";
      }
      case JobFunction.PROPERTY_REAL_ESTATE_AND_COMMUNITY_ASSOCIATION_MANAGERS: {
        return "Property, Real Estate, and Community Association Managers";
      }
      case JobFunction.PRECISION_INSTRUMENT_AND_EQUIPMENT_REPAIRERS: {
        return "Precision Instrument and Equipment Repairers";
      }
      case JobFunction.PSYCHIATRIC_TECHNICIANS_AND_AIDES: {
        return "Psychiatric Technicians and Aides";
      }
      case JobFunction.PREPRESS_TECHNICIANS_AND_WORKERS: {
        return "Prepress Technicians and Workers";
      }
      case JobFunction.PSYCHOLOGISTS: {
        return "Psychologists";
      }
      case JobFunction.PUBLIC_RELATIONS_AND_FUNDRAISING_MANAGERS: {
        return "Public Relations and Fundraising Managers";
      }
      case JobFunction.PUBLIC_RELATIONS_SPECIALISTS: {
        return "Public Relations Specialists";
      }
      case JobFunction.PURCHASING_MANAGERS_BUYERS_AND_PURCHASING_AGENTS: {
        return "Purchasing Managers, Buyers, and Purchasing Agents";
      }
      case JobFunction.PRINTING_MACHINE_OPERATORS: {
        return "Printing Machine Operators";
      }
      case JobFunction.QUALITY_CONTROL_INSPECTORS: {
        return "Quality Control Inspectors";
      }
      case JobFunction.RADIATION_THERAPISTS: {
        return "Radiation Therapists";
      }
      case JobFunction.RADIO_AND_TELECOMMUNICATIONS_EQUIPMENT_INSTALLERS_AND_REPAIRERS: {
        return "Radio and Telecommunications Equipment Installers and Repairers";
      }
      case JobFunction.RADIOLOGIC_AND_MRI_TECHNOLOGISTS: {
        return "Radiologic and MRI Technologists";
      }
      case JobFunction.RADIOLOGIC_TECHNOLOGISTS_AND_TECHNICIANS: {
        return "Radiologic Technologists and Technicians";
      }
      case JobFunction.RAIL_TRANSPORTATION_OCCUPATIONS: {
        return "Rail Transportation Occupations";
      }
      case JobFunction.RAILROAD_WORKERS: {
        return "Railroad Workers";
      }
      case JobFunction.REAL_ESTATE_BROKERS_AND_SALES_AGENTS: {
        return "Real Estate Brokers and Sales Agents";
      }
      case JobFunction.RECEPTIONISTS: {
        return "Receptionists";
      }
      case JobFunction.RECEPTIONISTS_AND_INFORMATION_CLERKS: {
        return "Receptionists and Information Clerks";
      }
      case JobFunction.RECREATION_WORKERS: {
        return "Recreation Workers";
      }
      case JobFunction.RECREATIONAL_THERAPISTS: {
        return "Recreational Therapists";
      }
      case JobFunction.REGISTERED_NURSES: {
        return "Registered Nurses";
      }
      case JobFunction.REHABILITATION_COUNSELORS: {
        return "Rehabilitation Counselors";
      }
      case JobFunction.REPORTERS_CORRESPONDENTS_AND_BROADCAST_NEWS_ANALYSTS: {
        return "Reporters, Correspondents, and Broadcast News Analysts";
      }
      case JobFunction.RESERVATION_AND_TRANSPORTATION_TICKET_AGENTS_AND_TRAVEL_CLERKS: {
        return "Reservation and Transportation Ticket Agents and Travel Clerks";
      }
      case JobFunction.RESPIRATORY_THERAPISTS: {
        return "Respiratory Therapists";
      }
      case JobFunction.RETAIL_SALES_WORKERS: {
        return "Retail Sales Workers";
      }
      case JobFunction.RETAIL_SALESPERSONS: {
        return "Retail Salespersons";
      }
      case JobFunction.ROOFERS: {
        return "Roofers";
      }
      case JobFunction.SALES_ENGINEERS: {
        return "Sales Engineers";
      }
      case JobFunction.SALES_MANAGERS: {
        return "Sales Managers";
      }
      case JobFunction.SALES_REPRESENTATIVES_WHOLESALE_AND_MANUFACTURING: {
        return "Sales Representatives, Wholesale and Manufacturing";
      }
      case JobFunction.SCHOOL_AND_CAREER_COUNSELORS: {
        return "School and Career Counselors";
      }
      case JobFunction.SECRETARIES_AND_ADMINISTRATIVE_ASSISTANTS: {
        return "Secretaries and Administrative Assistants";
      }
      case JobFunction.SECURITIES_COMMODITIES_AND_FINANCIAL_SERVICES_SALES_AGENTS: {
        return "Securities, Commodities, and Financial Services Sales Agents";
      }
      case JobFunction.SECURITY_GUARDS_AND_GAMING_SURVEILLANCE_OFFICERS: {
        return "Security Guards and Gaming Surveillance Officers";
      }
      case JobFunction.SHEET_METAL_WORKERS: {
        return "Sheet Metal Workers";
      }
      case JobFunction.SHIPPING_RECEIVING_AND_TRAFFIC_CLERKS: {
        return "Shipping, Receiving, and Traffic Clerks";
      }
      case JobFunction.SKINCARE_SPECIALISTS: {
        return "Skincare Specialists";
      }
      case JobFunction.SMALL_ENGINE_MECHANICS: {
        return "Small Engine Mechanics";
      }
      case JobFunction.SOCIAL_AND_COMMUNITY_SERVICE_MANAGERS: {
        return "Social and Community Service Managers";
      }
      case JobFunction.SOCIAL_AND_HUMAN_SERVICE_ASSISTANTS: {
        return "Social and Human Service Assistants";
      }
      case JobFunction.SOCIAL_SCIENTISTS_OTHER: {
        return "Social Scientists, Other";
      }
      case JobFunction.SOCIAL_WORKERS: {
        return "Social Workers";
      }
      case JobFunction.SALES_WORKER_SUPERVISORS: {
        return "Sales Worker Supervisors";
      }
      case JobFunction.SOCIOLOGISTS: {
        return "Sociologists";
      }
      case JobFunction.SOFTWARE_DEVELOPERS: {
        return "Software Developers";
      }
      case JobFunction.SCIENCE_TECHNICIANS: {
        return "Science Technicians";
      }
      case JobFunction.SOLAR_PHOTOVOLTAIC_INSTALLERS: {
        return "Solar Photovoltaic Installers";
      }
      case JobFunction.SPECIAL_EDUCATION_TEACHERS: {
        return "Special Education Teachers";
      }
      case JobFunction.SPEECH_LANGUAGE_PATHOLOGISTS: {
        return "Speech-Language Pathologists";
      }
      case JobFunction.STATIONARY_ENGINEERS_AND_BOILER_OPERATORS: {
        return "Stationary Engineers and Boiler Operators";
      }
      case JobFunction.SEMICONDUCTOR_PROCESSORS: {
        return "Semiconductor Processors";
      }
      case JobFunction.STATISTICIANS: {
        return "Statisticians";
      }
      case JobFunction.STOCK_CLERKS_AND_ORDER_FILLERS: {
        return "Stock Clerks and Order Fillers";
      }
      case JobFunction.STRUCTURAL_AND_REINFORCING_IRON_AND_METAL_WORKERS: {
        return "Structural and Reinforcing Iron and Metal Workers";
      }
      case JobFunction.SUBSTANCE_ABUSE_BEHAVIORAL_DISORDER_AND_MENTAL_HEALTH_COUNSELORS: {
        return "Substance Abuse, Behavioral Disorder, and Mental Health Counselors";
      }
      case JobFunction.SUPPLY_MANAGEMENT: {
        return "Supply management";
      }
      case JobFunction.SURGICAL_TECHNOLOGISTS: {
        return "Surgical Technologists";
      }
      case JobFunction.SURVEY_RESEARCHERS: {
        return "Survey Researchers";
      }
      case JobFunction.SURVEYING_AND_MAPPING_TECHNICIANS: {
        return "Surveying and Mapping Technicians";
      }
      case JobFunction.SURVEYORS: {
        return "Surveyors";
      }
      case JobFunction.SURVEYORS_CARTOGRAPHERS_PHOTOGRAMMETRISTS_AND_SURVEYING_AND_MAPPING_TECHNICIANS: {
        return "Surveyors, Cartographers, Photogrammetrists, and Surveying and Mapping Technicians";
      }
      case JobFunction.TAX_EXAMINERS_AND_COLLECTORS_AND_REVENUE_AGENTS: {
        return "Tax Examiners and Collectors, and Revenue Agents";
      }
      case JobFunction.TAX_EXAMINERS_COLLECTORS_AND_REVENUE_AGENTS: {
        return "Tax Examiners, Collectors, and Revenue Agents";
      }
      case JobFunction.TAXI_DRIVERS_AND_CHAUFFEURS: {
        return "Taxi Drivers and Chauffeurs";
      }
      case JobFunction.TAXI_DRIVERS_RIDE_HAILING_DRIVERS_AND_CHAUFFEURS: {
        return "Taxi Drivers, Ride-Hailing Drivers, and Chauffeurs";
      }
      case JobFunction.TEACHER_ASSISTANTS: {
        return "Teacher Assistants";
      }
      case JobFunction.TEACHERS_ADULT_LITERACY_AND_REMEDIAL_EDUCATION: {
        return "Teachers-Adult Literacy and Remedial Education";
      }
      case JobFunction.TEACHERS_POSTSECONDARY: {
        return "Teachers-Postsecondary";
      }
      case JobFunction.TEACHERS_PRESCHOOL_KINDERGARTEN_ELEMENTARY_MIDDLE_AND_SECONDARY: {
        return "Teachers-Preschool, Kindergarten, Elementary, Middle, and Secondary";
      }
      case JobFunction.TEACHERS_SPECIAL_EDUCATION: {
        return "Teachers-Special Education";
      }
      case JobFunction.TECHNICAL_WRITERS: {
        return "Technical Writers";
      }
      case JobFunction.TELECOMMUNICATIONS_EQUIPMENT_INSTALLERS_AND_REPAIRERS: {
        return "Telecommunications Equipment Installers and Repairers";
      }
      case JobFunction.TELEVISION_VIDEO_AND_MOTION_PICTURE_CAMERA_OPERATORS_AND_EDITORS: {
        return "Television, Video, and Motion Picture Camera Operators and Editors";
      }
      case JobFunction.TELLERS: {
        return "Tellers";
      }
      case JobFunction.TOOL_AND_DIE_MAKERS: {
        return "Tool and Die Makers";
      }
      case JobFunction.TOP_EXECUTIVES: {
        return "Top Executives";
      }
      case JobFunction.TRAINING_AND_DEVELOPMENT_MANAGERS: {
        return "Training and Development Managers";
      }
      case JobFunction.TRAINING_AND_DEVELOPMENT_SPECIALISTS: {
        return "Training and Development Specialists";
      }
      case JobFunction.TRAVEL_AGENTS: {
        return "Travel Agents";
      }
      case JobFunction.TRUCK_DRIVERS_AND_DRIVERSALES_WORKERS: {
        return "Truck Drivers and Driver/Sales Workers";
      }
      case JobFunction.UMPIRES_REFEREES_AND_OTHER_SPORTS_OFFICIALS: {
        return "Umpires, Referees, and Other Sports Officials";
      }
      case JobFunction.URBAN_AND_REGIONAL_PLANNERS: {
        return "Urban and Regional Planners";
      }
      case JobFunction.VETERINARIANS: {
        return "Veterinarians";
      }
      case JobFunction.TEACHERS_SELF_ENRICHMENT_EDUCATION: {
        return "Teachers-Self-Enrichment Education";
      }
      case JobFunction.VETERINARY_ASSISTANTS_AND_LABORATORY_ANIMAL_CARETAKERS: {
        return "Veterinary Assistants and Laboratory Animal Caretakers";
      }
      case JobFunction.VETERINARY_TECHNOLOGISTS_AND_TECHNICIANS: {
        return "Veterinary Technologists and Technicians";
      }
      case JobFunction.WAITERS_AND_WAITRESSES: {
        return "Waiters and Waitresses";
      }
      case JobFunction.WATER_AND_LIQUID_WASTE_TREATMENT_PLANT_AND_SYSTEM_OPERATORS: {
        return "Water and Liquid Waste Treatment Plant and System Operators";
      }
      case JobFunction.WATER_AND_WASTEWATER_TREATMENT_PLANT_AND_SYSTEM_OPERATORS: {
        return "Water and Wastewater Treatment Plant and System Operators";
      }
      case JobFunction.WATER_TRANSPORTATION_OCCUPATIONS: {
        return "Water Transportation Occupations";
      }
      case JobFunction.TEXTILE_APPAREL_AND_FURNISHINGS_OCCUPATIONS: {
        return "Textile, Apparel, and Furnishings Occupations";
      }
      case JobFunction.WATER_TRANSPORTATION_WORKERS: {
        return "Water Transportation Workers";
      }
      case JobFunction.WEB_DEVELOPERS: {
        return "Web Developers";
      }
      case JobFunction.WEIGHERS_MEASURERS_CHECKERS_AND_SAMPLERS_RECORDKEEPING: {
        return "Weighers, Measurers, Checkers, and Samplers, Recordkeeping";
      }
      case JobFunction.WELDERS_CUTTERS_SOLDERERS_AND_BRAZERS: {
        return "Welders, Cutters, Solderers, and Brazers";
      }
      case JobFunction.WELDING_SOLDERING_AND_BRAZING_WORKERS: {
        return "Welding, Soldering, and Brazing Workers";
      }
      case JobFunction.WHOLESALE_AND_MANUFACTURING_SALES_REPRESENTATIVES: {
        return "Wholesale and Manufacturing Sales Representatives";
      }
      case JobFunction.WIND_TURBINE_TECHNICIANS: {
        return "Wind Turbine Technicians";
      }
      case JobFunction.WOODWORKERS: {
        return "Woodworkers";
      }
      case JobFunction.WRITERS_AND_AUTHORS: {
        return "Writers and Authors";
      }
      case JobFunction.WRITERS_AND_EDITORS: {
        return "Writers and Editors";
      }
      case JobFunction.ZOOLOGISTS_AND_WILDLIFE_BIOLOGISTS: {
        return "Zoologists and Wildlife Biologists";
      }
      case JobFunction.OTHER: {
        return "Other";
      }
    }

    return "";
  }

  private static getJobFunctionTitleInVitenamese(
    jobFunction: JobFunction,
  ): string {
    switch (jobFunction) {
      case JobFunction.ACCOUNTANTS_AND_AUDITORS: {
        return "Kế toán và kiểm toán";
      }
      case JobFunction.ACTORS_PRODUCERS_AND_DIRECTORS: {
        return "Diễn viên, nhà sản xuất và đạo diễn";
      }
      case JobFunction.ACTUARIES: {
        return "Thẩm định rủi ro";
      }
      case JobFunction.ADMINISTRATIVE_SERVICES_MANAGERS: {
        return "Hành chính";
      }
      case JobFunction.ADULT_LITERACY_AND_HIGH_SCHOOL_EQUIVALENCY_DIPLOMA_TEACHERS: {
        return "Giáo viên trung học";
      }
      case JobFunction.ADVERTISING_SALES_AGENTS: {
        return "Chuyên viên quảng cáo";
      }
      case JobFunction.ADVERTISING_MARKETING_PROMOTIONS_PUBLIC_RELATIONS_AND_SALES_MANAGERS: {
        return "Quảng cáo, marketing, Quan hệ công chúng và Quản lý kinh doanh";
      }
      case JobFunction.AEROSPACE_ENGINEERING_AND_OPERATIONS_TECHNICIANS: {
        return "Kỹ thuật viên hàng không vũ trụ";
      }
      case JobFunction.AEROSPACE_ENGINEERS: {
        return "Kỹ sư hàng không vũ trụ";
      }
      case JobFunction.AGRICULTURAL_AND_FOOD_SCIENCE_TECHNICIANS: {
        return "Kỹ thuật viên khoa học nông nghiệp và thực phẩm";
      }
      case JobFunction.AGRICULTURAL_AND_FOOD_SCIENTISTS: {
        return "Khoa nông nông nghiệp và thực phẩn";
      }
      case JobFunction.AGRICULTURAL_ENGINEERS: {
        return "Kỹ sư nông nghiệp";
      }
      case JobFunction.AGRICULTURAL_WORKERS: {
        return "Nông dân";
      }
      case JobFunction.AIR_TRAFFIC_CONTROLLERS: {
        return "Nhân viên kiểm soát không lưu";
      }
      case JobFunction.AIRCRAFT_AND_AVIONICS_EQUIPMENT_MECHANICS_AND_SERVICE_TECHNICIANS: {
        return "Kỹ thuật viên máy bay và thiết bị hàng không";
      }
      case JobFunction.AIRCRAFT_PILOTS_AND_FLIGHT_ENGINEERS: {
        return "Phi công và kỹ sư máy bay";
      }
      case JobFunction.ANIMAL_CARE_AND_SERVICE_WORKERS: {
        return "Chăm sóc động vật";
      }
      case JobFunction.ANNOUNCERS: {
        return "Phát thanh viên";
      }
      case JobFunction.ANTHROPOLOGISTS_AND_ARCHEOLOGISTS: {
        return "Nhân chủng học và khảo cổ học";
      }
      case JobFunction.APPRAISERS_AND_ASSESSORS_OF_REAL_ESTATE: {
        return "Thẩm định giá bất động sản";
      }
      case JobFunction.ARBITRATORS_MEDIATORS_AND_CONCILIATORS: {
        return "Trọng tài viên, hòa giải viên";
      }
      case JobFunction.ARCHITECTS_EXCEPT_LANDSCAPE_AND_NAVAL: {
        return "Kiến trúc sư (ngoại trừ cảnh quan và hải quân)";
      }
      case JobFunction.ARCHITECTURAL_AND_ENGINEERING_MANAGERS: {
        return "Quản lý kiến trúc và kỹ thuật";
      }
      case JobFunction.ARCHIVISTS_CURATORS_AND_MUSEUM_TECHNICIANS: {
        return "Lưu trữ và kỹ thuật viên bảo tàng";
      }
      case JobFunction.ART_DIRECTORS: {
        return "Giám đốc nghệ thuật";
      }
      case JobFunction.ARTISTS_AND_RELATED_WORKERS: {
        return "Nghệ sĩ";
      }
      case JobFunction.ASSEMBLERS_AND_FABRICATORS: {
        return "Lắp ráp và chế tạo";
      }
      case JobFunction.ATHLETES_COACHES_UMPIRES_AND_RELATED_WORKERS: {
        return "Vận động viên, huấn luyện viên, trọng tài";
      }
      case JobFunction.ATHLETIC_TRAINERS: {
        return "Huấn luyện viên thể thao";
      }
      case JobFunction.ATMOSPHERIC_SCIENTISTS: {
        return "Khoa học khí quyển";
      }
      case JobFunction.AUDIOLOGISTS: {
        return "Chuyên gia thính học";
      }
      case JobFunction.AUTOMOTIVE_BODY_AND_RELATED_REPAIRERS: {
        return "Sửa chữa thân vỏ ô tô";
      }
      case JobFunction.AUTOMOTIVE_SERVICE_TECHNICIANS_AND_MECHANICS: {
        return "Kỹ thuật viên ô tô và động cơ";
      }
      case JobFunction.BAKERS: {
        return "Thợ làm bánh";
      }
      case JobFunction.BARBERS_COSMETOLOGISTS_AND_OTHER_PERSONAL_APPEARANCE_WORKERS: {
        return "Thợ cắt tóc và chăm sóc thẩm mỹ";
      }
      case JobFunction.BARTENDERS: {
        return "Pha chế";
      }
      case JobFunction.BILL_AND_ACCOUNT_COLLECTORS: {
        return "Thu tiền hóa đơn";
      }
      case JobFunction.BILLING_AND_POSTING_CLERKS_AND_MACHINE_OPERATORS: {
        return "Vận hành máy và tính toán hóa đơn";
      }
      case JobFunction.BIOLOGICAL_SCIENTISTS: {
        return "Khoa học sinh học";
      }
      case JobFunction.BIOLOGICAL_TECHNICIANS: {
        return "Kỹ thuật viên sinh học";
      }
      case JobFunction.BIOMEDICAL_ENGINEERS: {
        return "Kỹ sư y sinh";
      }
      case JobFunction.BOILERMAKERS: {
        return "Thợ nấu nồi hơi";
      }
      case JobFunction.BOOKBINDERS_AND_BINDERY_WORKERS: {
        return "Đóng gáy sách";
      }
      case JobFunction.BOOKKEEPING_ACCOUNTING_AND_AUDITING_CLERKS: {
        return "Kế toán và kiểm toán";
      }
      case JobFunction.BRICKMASONS_BLOCKMASONS_AND_STONEMASONS: {
        return "Thợ nề, thợ đá";
      }
      case JobFunction.BROADCAST_AND_SOUND_ENGINEERING_TECHNICIANS_AND_RADIO_OPERATORS: {
        return "Kỹ thuật viên phát sóng, kỹ thuật âm thanh và phát thanh";
      }
      case JobFunction.BROKERAGE_CLERKS: {
        return "Môi giới";
      }
      case JobFunction.BUDGET_ANALYSTS: {
        return "Phân tích ngân sách";
      }
      case JobFunction.BUILDING_CLEANING_WORKERS: {
        return "Công nhân dọn dẹp xây dựng";
      }
      case JobFunction.BUS_DRIVERS: {
        return "Tài xế xe buýt";
      }
      case JobFunction.BUTCHERS: {
        return "Nhân viên bán thịt";
      }
      case JobFunction.CARDIOVASCULAR_TECHNOLOGISTS_AND_TECHNICIANS: {
        return "Chuyên gia công nghệ và kỹ thuật viên tim mạch";
      }
      case JobFunction.CAREER_AND_TECHNICAL_EDUCATION_TEACHERS: {
        return "Giáo viên dạy nghề";
      }
      case JobFunction.CARGO_AND_FREIGHT_AGENTS: {
        return "Vận chuyển hàng hóa";
      }
      case JobFunction.CARPENTERS: {
        return "Thợ mộc";
      }
      case JobFunction.CARPET_FLOOR_AND_TILE_INSTALLERS_AND_FINISHERS: {
        return "Thợ lắp đặt thảm, sàn";
      }
      case JobFunction.CARTOGRAPHERS_AND_PHOTOGRAMMETRISTS: {
        return "Nhiếp ảnh và bản đồ";
      }
      case JobFunction.CASHIERS: {
        return "Thu ngân";
      }
      case JobFunction.CEMENT_MASONS_CONCRETE_FINISHERS_SEGMENTAL_PAVERS_AND_TERRAZZO_WORKERS: {
        return "Thợ xây dựng";
      }
      case JobFunction.CHEFS_AND_HEAD_COOKS: {
        return "Bếp trưởng";
      }
      case JobFunction.CHEFS_COOKS_AND_FOOD_PREPARATION_WORKERS: {
        return "Đầu bếp và Nhân viên chuẩn bị thực phẩm";
      }
      case JobFunction.CHEMICAL_ENGINEERS: {
        return "Kỹ sư hóa học";
      }
      case JobFunction.CHEMICAL_TECHNICIANS: {
        return "Kỹ thuật viên hóa học";
      }
      case JobFunction.CHEMISTS_AND_MATERIALS_SCIENTISTS: {
        return "Khoa học hóa học và vật liệu";
      }
      case JobFunction.CHILD_CARE_WORKERS: {
        return "Nhân viên chăm sóc trẻ em";
      }
      case JobFunction.CHIROPRACTORS: {
        return "Chuyên viên Trị liệu Thần kinh cột sống";
      }
      case JobFunction.CIVIL_ENGINEERING_TECHNICIANS: {
        return "Kỹ thuật viên Kỹ thuật dân dụng";
      }
      case JobFunction.CIVIL_ENGINEERS: {
        return "Kỹ sư dân sự";
      }
      case JobFunction.CLAIMS_ADJUSTERS_APPRAISERS_EXAMINERS_AND_INVESTIGATORS: {
        return "Thẩm định viên, giám khảo, điều tra viên";
      }
      case JobFunction.CLINICAL_LABORATORY_TECHNOLOGISTS_AND_TECHNICIANS: {
        return "Kỹ thuật viên phòng thí nghiệm lâm sàng";
      }
      case JobFunction.COACHES_AND_SCOUTS: {
        return "Huấn luyện viên và trinh sát";
      }
      case JobFunction.COIN_VENDING_AND_AMUSEMENT_MACHINE_SERVICERS_AND_REPAIRERS: {
        return "Sửa chữa và vận hành máy vui chơi";
      }
      case JobFunction.COMMERCIAL_AND_INDUSTRIAL_DESIGNERS: {
        return "Nhà thiết kế thương mại và công nghiệp";
      }
      case JobFunction.COMMERCIAL_MANAGEMENT: {
        return "Quản lý thương mại";
      }
      case JobFunction.COMMUNICATIONS_EQUIPMENT_OPERATORS: {
        return "Vận hành thiết bị truyền thông";
      }
      case JobFunction.COMPENSATION_AND_BENEFITS_MANAGERS: {
        return "Quản lý lương thưởng và phúc lợi";
      }
      case JobFunction.COMPENSATION_BENEFITS_AND_JOB_ANALYSIS_SPECIALISTS: {
        return "Chuyên viên lương thưởng và phân tích công việc";
      }
      case JobFunction.COMPUTER_AND_INFORMATION_RESEARCH_SCIENTISTS: {
        return "Nhà khoa học nghiên cứu thông tin và máy tính";
      }
      case JobFunction.COMPUTER_AND_INFORMATION_SYSTEMS_MANAGERS: {
        return "Quản lý Hệ thống thông tin máy tính";
      }
      case JobFunction.COMPUTER_CONTROL_PROGRAMMERS_AND_OPERATORS: {
        return "Vận hành và lập trình điều khiển máy tính";
      }
      case JobFunction.COMPUTER_HARDWARE_ENGINEERS: {
        return "Kỹ sư phần cứng máy tính";
      }
      case JobFunction.COMPUTER_NETWORK_ARCHITECTS: {
        return "Kỹ sư mạng máy tính";
      }
      case JobFunction.COMPUTER_OPERATORS: {
        return "Người vận hành máy tính";
      }
      case JobFunction.COMPUTER_PROGRAMMERS: {
        return "Lập trình viên máy tính";
      }
      case JobFunction.COMPUTER_SCIENTISTS_AND_DATABASE_ADMINISTRATORS: {
        return "Khoa học máy tính và quản trị cơ sở dữ liệu";
      }
      case JobFunction.COMPUTER_SOFTWARE_ENGINEERS: {
        return "Kỹ sư phần mềm máy tính";
      }
      case JobFunction.COMPUTER_SUPPORT_SPECIALISTS: {
        return "Chuyên gia Hỗ trợ Máy tính";
      }
      case JobFunction.COMPUTER_SUPPORT_SPECIALISTS_AND_SYSTEMS_ADMINISTRATORS: {
        return "Chuyên gia hỗ trợ máy tính và quản trị viên hệ thống";
      }
      case JobFunction.COMPUTER_SYSTEMS_ANALYSTS: {
        return "Chuyên viên phân tích hệ thống máy tính";
      }
      case JobFunction.COMPUTER_AUTOMATED_TELLER_AND_OFFICE_MACHINE_REPAIRERS: {
        return "Nhân viên sửa chữa Máy tính, Máy rút tiền tự động và Máy văn phòng";
      }
      case JobFunction.CONSERVATION_SCIENTISTS_AND_FORESTERS: {
        return "Nhà khoa học môi trường và Kỹ sư lâm sinh";
      }
      case JobFunction.CONSTRUCTION_AND_BUILDING_INSPECTORS: {
        return "GIám định xây dựng";
      }
      case JobFunction.CONSTRUCTION_EQUIPMENT_OPERATORS: {
        return "Giám định thiết bị xây dựng";
      }
      case JobFunction.CONSTRUCTION_LABORERS: {
        return "Công nhân xây dựng";
      }
      case JobFunction.CONSTRUCTION_LABORERS_AND_HELPERS: {
        return "Công nhân xây dựng và Ban hậu cần";
      }
      case JobFunction.CONSTRUCTION_MANAGERS: {
        return "Giám đốc xây dựng";
      }
      case JobFunction.COOKS: {
        return "Đầu bếp";
      }
      case JobFunction.CORRECTIONAL_OFFICERS: {
        return "Cán bộ cải huấn";
      }
      case JobFunction.CONTRACT_MANAGEMENT: {
        return "Quản lý hợp đồng";
      }
      case JobFunction.CORRECTIONAL_OFFICERS_AND_BAILIFFS: {
        return "Cán bộ cải huấn và Chấp hành viên Tòa án";
      }
      case JobFunction.COST_ESTIMATORS: {
        return "Chuyên gia dự toán chi phí";
      }
      case JobFunction.COUNSELORS: {
        return "Cố vấn";
      }
      case JobFunction.COUNTER_AND_RENTAL_CLERKS: {
        return "Nhân viên quầy và cho thuê";
      }
      case JobFunction.COURIERS_AND_MESSENGERS: {
        return "Chuyển phát nhanh";
      }
      case JobFunction.COURT_REPORTERS: {
        return "Phóng viên tòa án";
      }
      case JobFunction.CRAFT_AND_FINE_ARTISTS: {
        return "Nghệ nhân thủ công mỹ nghệ";
      }
      case JobFunction.CREDIT_AUTHORIZERS_CHECKERS_AND_CLERKS: {
        return "Uỷ quyền tín dụng và thư ký";
      }
      case JobFunction.CUSTOMER_SERVICE_REPRESENTATIVES: {
        return "Đại diện dịch vụ khách hàng";
      }
      case JobFunction.DANCERS_AND_CHOREOGRAPHERS: {
        return "Vũ công và biên đạo múa";
      }
      case JobFunction.DATA_ENTRY_AND_INFORMATION_PROCESSING_WORKERS: {
        return "Chuyên viên Nhập liệu và xử lý thông tin";
      }
      case JobFunction.DATABASE_ADMINISTRATORS: {
        return "Quản trị viên cơ sở dữ liệu";
      }
      case JobFunction.DELIVERY_TRUCK_DRIVERS_AND_DRIVERSALES_WORKERS: {
        return "Lái xe giao nhận hàng";
      }
      case JobFunction.DEMONSTRATORS_PRODUCT_PROMOTERS_AND_MODELS: {
        return "Quảng cáo sản phẩm và tiếp thị";
      }
      case JobFunction.DENTAL_AND_OPHTHALMIC_LABORATORY_TECHNICIANS_AND_MEDICAL_APPLIANCE_TECHNICIANS: {
        return "Kỹ thuật viên thiết bị y tế";
      }
      case JobFunction.DENTAL_ASSISTANTS: {
        return "Trợ lý nha khoa";
      }
      case JobFunction.DENTAL_HYGIENISTS: {
        return "Nhân viên vệ sinh nha khoa";
      }
      case JobFunction.DENTISTS: {
        return "Nha sĩ";
      }
      case JobFunction.DESKTOP_PUBLISHERS: {
        return "Nhà xuất bản";
      }
      case JobFunction.DIAGNOSTIC_MEDICAL_SONOGRAPHERS: {
        return "Chẩn đoán siêu âm y tế";
      }
      case JobFunction.DIESEL_SERVICE_TECHNICIANS_AND_MECHANICS: {
        return "Kỹ thuật viên dịch vụ máy móc và cơ khí";
      }
      case JobFunction.DIETITIANS_AND_NUTRITIONISTS: {
        return "Chuyên gia dinh dưỡng";
      }
      case JobFunction.DISPATCHERS: {
        return "Người điều phối";
      }
      case JobFunction.DRAFTERS: {
        return "Nhà Soạn thảo";
      }
      case JobFunction.DRYWALL_AND_CEILING_TILE_INSTALLERS_AND_TAPERS: {
        return "Nhân viên lắp đặt tấm trần và tường khô";
      }
      case JobFunction.DRYWALL_INSTALLERS_CEILING_TILE_INSTALLERS_AND_TAPERS: {
        return "Nhân viên lắp đặt tấm trần và tường khô";
      }
      case JobFunction.ECONOMISTS: {
        return "Nhà kinh tế";
      }
      case JobFunction.EDITORS: {
        return "Biên tập viên";
      }
      case JobFunction.EDUCATION_ADMINISTRATORS: {
        return "Quản trị viên giáo dục";
      }
      case JobFunction.ELECTRICAL_AND_ELECTRONICS_ENGINEERING_TECHNICIANS: {
        return "Kỹ thuật viên Kỹ thuật điện - điện tử";
      }
      case JobFunction.ELECTRICAL_AND_ELECTRONICS_ENGINEERS: {
        return "Kỹ sư điện và điện tử";
      }
      case JobFunction.ELECTRICAL_AND_ELECTRONICS_INSTALLERS_AND_REPAIRERS: {
        return "Thợ lắp đặt và sửa chữa Kỹ thuật điện - điện tử";
      }
      case JobFunction.ELECTRICIANS: {
        return "Thợ điện";
      }
      case JobFunction.ELECTRO_MECHANICAL_TECHNICIANS: {
        return "Kỹ thuật viên cơ điện";
      }
      case JobFunction.ELECTRONIC_HOME_ENTERTAINMENT_EQUIPMENT_INSTALLERS_AND_REPAIRERS: {
        return "Thợ lắp đặt và sửa chữa thiết bị gia dụng";
      }
      case JobFunction.ELEMENTARY_MIDDLE_AND_HIGH_SCHOOL_PRINCIPALS: {
        return "Hiệu trưởng - Tiểu học, Trung học cơ sở, Trung học";
      }
      case JobFunction.ELEVATOR_INSTALLERS_AND_REPAIRERS: {
        return "Thợ lắp đặt và bảo trì Thang máy";
      }
      case JobFunction.EMERGENCY_MANAGEMENT_DIRECTORS: {
        return "Giám đốc quản lý khẩn cấp";
      }
      case JobFunction.EMERGENCY_MEDICAL_TECHNICIANS_AND_PARAMEDICS: {
        return "Chuyên viên cấp cứu ngoài bệnh viện";
      }
      case JobFunction.EMTS_AND_PARAMEDICS: {
        return "Chuyên viên cấp cứu ngoài bệnh viện";
      }
      case JobFunction.ENGINEERING_AND_NATURAL_SCIENCES_MANAGERS: {
        return "Kỹ sư khoa học tự nhiên";
      }
      case JobFunction.ENGINEERING_TECHNICIANS: {
        return "Chuyên viên Kỹ thuật";
      }
      case JobFunction.ENVIRONMENTAL_ENGINEERING_TECHNICIANS: {
        return "Chuyên viên Kỹ thuật Môi trường";
      }
      case JobFunction.ENVIRONMENTAL_ENGINEERS: {
        return "Kỹ sư môi trường";
      }
      case JobFunction.ENVIRONMENTAL_SCIENCE_AND_PROTECTION_TECHNICIANS: {
        return "Kỹ thuật viên Khoa học và Bảo vệ Môi trường";
      }
      case JobFunction.ENVIRONMENTAL_SCIENTISTS_AND_HYDROLOGISTS: {
        return "Nhà Khoa học và Thủy văn Môi trường";
      }
      case JobFunction.ENVIRONMENTAL_SCIENTISTS_AND_SPECIALISTS: {
        return "Nhà Khoa học và Chuyên gia Môi trường";
      }
      case JobFunction.EPIDEMIOLOGISTS: {
        return "Nhà dịch tễ học";
      }
      case JobFunction.EXERCISE_PHYSIOLOGISTS: {
        return "Nhà Thể dục sinh lý";
      }
      case JobFunction.FARMERS_RANCHERS_AND_AGRICULTURAL_MANAGERS: {
        return "Nông dân, chủ trang trại và quản lý nông nghiệp";
      }
      case JobFunction.FARMERS_RANCHERS_AND_OTHER_AGRICULTURAL_MANAGERS: {
        return "Nông dân, chủ trang trại và các nhà quản lý nông nghiệp khác";
      }
      case JobFunction.FASHION_DESIGNERS: {
        return "Nhà thiết kế thời trang";
      }
      case JobFunction.FILE_CLERKS: {
        return "Nhân viên văn thư";
      }
      case JobFunction.FILM_AND_VIDEO_EDITORS_AND_CAMERA_OPERATORS: {
        return "Biên tập Phim và Video và người điều hành máy ảnh";
      }
      case JobFunction.FINANCIAL_ANALYSTS: {
        return "Nhà phân tích tài chính";
      }
      case JobFunction.FINANCIAL_ANALYSTS_AND_PERSONAL_FINANCIAL_ADVISORS: {
        return "Nhà Phân tích tài chính và Cố vấn tài chính cá nhân";
      }
      case JobFunction.FINANCIAL_CLERKS: {
        return "Thư ký tài chính";
      }
      case JobFunction.FINANCIAL_EXAMINERS: {
        return "Kiểm tra viên tài chính";
      }
      case JobFunction.FINANCIAL_MANAGERS: {
        return "Quản lý tài chính";
      }
      case JobFunction.FIRE_FIGHTING_OCCUPATIONS: {
        return "Ngành nghề chữa cháy";
      }
      case JobFunction.FIRE_INSPECTORS: {
        return "Giám định viên cứu hỏa";
      }
      case JobFunction.FIREFIGHTERS: {
        return "Lính cứu hỏa";
      }
      case JobFunction.FISHERS_AND_FISHING_VESSEL_OPERATORS: {
        return "Người đánh bắt và điều khiển tàu đánh cá";
      }
      case JobFunction.FISHING_AND_HUNTING_WORKERS: {
        return "Câu cá và săn bắt";
      }
      case JobFunction.FITNESS_TRAINERS_AND_INSTRUCTORS: {
        return "Huấn luyện viên và giảng viên thể dục";
      }
      case JobFunction.FITNESS_WORKERS: {
        return "Nhân viên thể dục";
      }
      case JobFunction.FLIGHT_ATTENDANTS: {
        return "Tiếp viên hàng không";
      }
      case JobFunction.FLOORING_INSTALLERS_AND_TILE_AND_MARBLE_SETTERS: {
        return "Lắp đặt sàn nhà";
      }
      case JobFunction.FLORAL_DESIGNERS: {
        return "Nhân viên cắm hoa";
      }
      case JobFunction.FOOD_AND_BEVERAGE_SERVING_AND_RELATED_WORKERS: {
        return "Nhân viên phục vụ Thực phẩm, Đồ uống và các sản phẩm liên quan";
      }
      case JobFunction.FOOD_AND_TOBACCO_PROCESSING_WORKERS: {
        return "Công nhân chế biến thực phẩm và thuốc lá";
      }
      case JobFunction.FOOD_PROCESSING_OCCUPATIONS: {
        return "Công nhân chế biến thực phẩm";
      }
      case JobFunction.FOOD_SERVICE_MANAGERS: {
        return "Quản trị dịch vụ ăn uống";
      }
      case JobFunction.FORENSIC_SCIENCE_TECHNICIANS: {
        return "Kỹ thuật viên khoa học pháp y";
      }
      case JobFunction.FOREST_AND_CONSERVATION_WORKERS: {
        return "Công nhân bảo tồn rừng";
      }
      case JobFunction.FOREST_CONSERVATION_AND_LOGGING_WORKERS: {
        return "Công nhân lâm nghiệp";
      }
      case JobFunction.FUNDRAISERS: {
        return "Người gây quỹ";
      }
      case JobFunction.FUNERAL_DIRECTORS: {
        return "Điều phối viên tang lễ";
      }
      case JobFunction.FUNERAL_SERVICE_WORKERS: {
        return "Nhân viên dịch vụ tang lễ";
      }
      case JobFunction.GAMING_CAGE_WORKERS: {
        return "Nhân viên tại sòng bạc";
      }
      case JobFunction.GAMING_SERVICES_OCCUPATIONS: {
        return "Kiểm thử game";
      }
      case JobFunction.GAMING_SERVICES_WORKERS: {
        return "Kiểm thử game";
      }
      case JobFunction.GENERAL_MAINTENANCE_AND_REPAIR_WORKERS: {
        return "Nhân viên bảo trì và sửa chữa chung";
      }
      case JobFunction.GENERAL_OFFICE_CLERKS: {
        return "Thư ký văn phòng chung";
      }
      case JobFunction.GENETIC_COUNSELORS: {
        return "Cố vấn Di truyền học";
      }
      case JobFunction.GEOGRAPHERS: {
        return "Nhà địa lý";
      }
      case JobFunction.GEOLOGICAL_AND_PETROLEUM_TECHNICIANS: {
        return "Kỹ thuật viên địa chất và dầu khí";
      }
      case JobFunction.GEOSCIENTISTS: {
        return "Nhà địa chất học";
      }
      case JobFunction.GLAZIERS: {
        return "Thợ tráng men";
      }
      case JobFunction.GRAPHIC_DESIGNERS: {
        return "Nhà thiết kế đồ họa";
      }
      case JobFunction.GROUNDS_MAINTENANCE_WORKERS: {
        return "Nhân viên Cảnh quan và Chăm sóc đất đai";
      }
      case JobFunction.HAND_LABORERS_AND_MATERIAL_MOVERS: {
        return "Công nhân và người vận chuyển hàng hóa";
      }
      case JobFunction.HAZARDOUS_MATERIALS_REMOVAL_WORKERS: {
        return "Nhân viên di dời vật liệu nguy hiểm";
      }
      case JobFunction.HEALTH_AND_SAFETY_ENGINEERS: {
        return "Giám sát viên An toàn lao động";
      }
      case JobFunction.HEALTH_EDUCATORS: {
        return "Nhà giáo dục sức khỏe";
      }
      case JobFunction.HEALTH_EDUCATORS_AND_COMMUNITY_HEALTH_WORKERS: {
        return "Nhà giáo dục sức khỏe và nhân viên y tế cộng đồng";
      }
      case JobFunction.HEATING_AIR_CONDITIONING_AND_REFRIGERATION_MECHANICS_AND_INSTALLERS: {
        return "Vận hành và sửa chữa điều hòa, tủ lạnh";
      }
      case JobFunction.HEAVY_AND_TRACTOR_TRAILER_TRUCK_DRIVERS: {
        return "Tài xế xe tải hạng nặng và máy kéo";
      }
      case JobFunction.HEAVY_VEHICLE_AND_MOBILE_EQUIPMENT_SERVICE_TECHNICIANS: {
        return "Kỹ sư sửa chữa xe tải hạng nặng và thiết bị di động";
      }
      case JobFunction.HEAVY_VEHICLE_AND_MOBILE_EQUIPMENT_SERVICE_TECHNICIANS_AND_MECHANICS: {
        return "Kỹ sư sửa chữa xe tải hạng nặng và thiết bị di động";
      }
      case JobFunction.HIGH_SCHOOL_TEACHERS: {
        return "Giáo viên trung học";
      }
      case JobFunction.HISTORIANS: {
        return "Nhà Sử học";
      }
      case JobFunction.HOME_APPLIANCE_REPAIRERS: {
        return "Thợ sửa chữa thiết bị gia dụng";
      }
      case JobFunction.HOME_HEALTH_AIDES_AND_PERSONAL_CARE_AIDES: {
        return "Trợ lý chăm sóc sức khỏe cá nhân";
      }
      case JobFunction.HOTEL_MOTEL_AND_RESORT_DESK_CLERKS: {
        return "Nhân viên Lễ tân Khách sạn, Nhà nghỉ và Khu Nghỉ dưỡng";
      }
      case JobFunction.HUMAN_RESOURCES_ASSISTANTS_EXCEPT_PAYROLL_AND_TIMEKEEPING: {
        return "Trợ lý nhân sự";
      }
      case JobFunction.HUMAN_RESOURCES_MANAGERS: {
        return "Quản lý nguồn nhân lực";
      }
      case JobFunction.HUMAN_RESOURCES_SPECIALISTS: {
        return "Chuyên gia nhân sự";
      }
      case JobFunction.HUMAN_RESOURCES_TRAINING_AND_LABOR_RELATIONS_MANAGERS_AND_SPECIALISTS: {
        return "Nhân sự, đào tạo và quản lý quan hệ lao động";
      }
      case JobFunction.HYDROLOGISTS: {
        return "Chuyên gia thủy văn";
      }
      case JobFunction.INDUSTRIAL_DESIGNERS: {
        return "Nhà thiết kế công nghiệp";
      }
      case JobFunction.INDUSTRIAL_ENGINEERING_TECHNICIANS: {
        return "Kỹ thuật viên Kỹ thuật công nghiệp";
      }
      case JobFunction.INDUSTRIAL_ENGINEERS: {
        return "Kỹ sư công nghiệp";
      }
      case JobFunction.INDUSTRIAL_MACHINERY_MECHANICS_AND_MAINTENANCE_WORKERS: {
        return "Bảo trì máy móc công nghiệp";
      }
      case JobFunction.INDUSTRIAL_PRODUCTION_MANAGERS: {
        return "Giám đốc sản xuất công nghiệp";
      }
      case JobFunction.INFORMATION_CLERKS: {
        return "Chuyên viên quầy thông tin";
      }
      case JobFunction.INFORMATION_SECURITY_ANALYSTS: {
        return "Chuyên viên phân tích bảo mật thông tin";
      }
      case JobFunction.INSPECTORS_TESTERS_SORTERS_SAMPLERS_AND_WEIGHERS: {
        return "Thanh tra, người kiểm tra, người phân loại, lấy mẫu và cân";
      }
      case JobFunction.INSTRUCTIONAL_COORDINATORS: {
        return "Điều phối viên";
      }
      case JobFunction.INSULATION_WORKERS: {
        return "Công nhân cách nhiệt";
      }
      case JobFunction.INSURANCE_SALES_AGENTS: {
        return "Đại lý bán bảo hiểm";
      }
      case JobFunction.INSURANCE_UNDERWRITERS: {
        return "Bảo hiểm bảo lãnh";
      }
      case JobFunction.INTERIOR_DESIGNERS: {
        return "Nhà thiết kế nội thất";
      }
      case JobFunction.INTERPRETERS_AND_TRANSLATORS: {
        return "Thông dịch viên và dịch giả";
      }
      case JobFunction.IRONWORKERS: {
        return "Thợ sắt";
      }
      case JobFunction.JEWELERS_AND_PRECIOUS_STONE_AND_METAL_WORKERS: {
        return "Thợ kim hoàn và công nhân đá và kim loại quý";
      }
      case JobFunction.JOB_OPPORTUNITIES_IN_THE_ARMED_FORCES: {
        return "Lực lượng vũ trang";
      }
      case JobFunction.JUDGES_AND_HEARING_OFFICERS: {
        return "Thẩm phán";
      }
      case JobFunction.JUDGES_MAGISTRATES_AND_OTHER_JUDICIAL_WORKERS: {
        return "Chuyên viên tư pháp";
      }
      case JobFunction.KINDERGARTEN_AND_ELEMENTARY_SCHOOL_TEACHERS: {
        return "Giáo viên Mẫu giáo và Tiểu học";
      }
      case JobFunction.LABOR_RELATIONS_SPECIALISTS: {
        return "Chuyên gia quan hệ lao động";
      }
      case JobFunction.LANDSCAPE_ARCHITECTS: {
        return "Kiến trúc sư cảnh quan";
      }
      case JobFunction.LAWYERS: {
        return "Luật sư";
      }
      case JobFunction.LIBRARIANS: {
        return "Thủ thư";
      }
      case JobFunction.LIBRARY_TECHNICIANS_AND_ASSISTANTS: {
        return "Kỹ thuật viên và trợ lý thư viện";
      }
      case JobFunction.LICENSED_PRACTICAL_AND_LICENSED_VOCATIONAL_NURSES: {
        return "Y tá";
      }
      case JobFunction.LINE_INSTALLERS_AND_REPAIRERS: {
        return "Thợ lắp đặt và sửa chữa dây điện";
      }
      case JobFunction.LOAN_OFFICERS: {
        return "Chuyên viên Tín dụng";
      }
      case JobFunction.LODGING_MANAGERS: {
        return "Quản lý bộ phận lưu trú";
      }
      case JobFunction.LOGGING_WORKERS: {
        return "Công nhân xẻ gỗ";
      }
      case JobFunction.LOGISTICIANS: {
        return "Nhân viên điều vận";
      }
      case JobFunction.MACHINE_SETTERS_OPERATORS_AND_TENDERS_METAL_AND_PLASTIC: {
        return "Cài đặt và vận hành máy móc";
      }
      case JobFunction.MACHINISTS: {
        return "Thợ máy";
      }
      case JobFunction.MACHINISTS_AND_TOOL_AND_DIE_MAKERS: {
        return "Thợ máy và Chuyên viên Gia công khuôn dập";
      }
      case JobFunction.MAINTENANCE_AND_REPAIR_WORKERS_GENERAL: {
        return "Nhân viên bảo trì và sửa chữa";
      }
      case JobFunction.MANAGEMENT_ANALYSTS: {
        return "Phân tích quản lý";
      }
      case JobFunction.MANICURISTS_AND_PEDICURISTS: {
        return "Kỹ thuật viên làm móng";
      }
      case JobFunction.MARINE_ENGINEERS_AND_NAVAL_ARCHITECTS: {
        return "Kỹ sư hàng hải và kiến ​​trúc sư hải quân";
      }
      case JobFunction.MARKET_AND_SURVEY_RESEARCHERS: {
        return "Nhà Nghiên cứu và khảo sát thị trường";
      }
      case JobFunction.MARKET_RESEARCH_ANALYSTS: {
        return "Nhà phân tích nghiên cứu thị trường";
      }
      case JobFunction.MARRIAGE_AND_FAMILY_THERAPISTS: {
        return "Nhà Trị liệu Hôn nhân và Gia đình";
      }
      case JobFunction.MASSAGE_THERAPISTS: {
        return "Nhân viên trị liệu Massage";
      }
      case JobFunction.MATERIAL_MOVING_MACHINE_OPERATORS: {
        return "Công nhân vận hành thiết bị di chuyển hàng hóa";
      }
      case JobFunction.MATERIAL_MOVING_OCCUPATIONS: {
        return "Phụ kho";
      }
      case JobFunction.MATERIAL_RECORDING_CLERKS: {
        return "Thủ kho";
      }
      case JobFunction.MATERIALS_ENGINEERS: {
        return "Kỹ sư vật liệu";
      }
      case JobFunction.MATHEMATICIANS: {
        return "Nhà toán học";
      }
      case JobFunction.MATHEMATICIANS_AND_STATISTICIANS: {
        return "Nhà toán học và Chuyên gia thống kê";
      }
      case JobFunction.MECHANICAL_ENGINEERING_TECHNICIANS: {
        return "Kỹ thuật viên Cơ khí";
      }
      case JobFunction.MECHANICAL_ENGINEERS: {
        return "Kỹ sư cơ khí";
      }
      case JobFunction.MEDICAL_AND_CLINICAL_LABORATORY_TECHNOLOGISTS_AND_TECHNICIANS: {
        return "Kỹ thuật viên thí nghiệm y tế và lâm sàng";
      }
      case JobFunction.MEDICAL_AND_HEALTH_SERVICES_MANAGERS: {
        return "Quản trị dịch vụ y tế";
      }
      case JobFunction.MEDICAL_ASSISTANTS: {
        return "Trợ lý y tế";
      }
      case JobFunction.MEDICAL_EQUIPMENT_REPAIRERS: {
        return "Sửa chữa thiết bị y tế";
      }
      case JobFunction.MEDICAL_RECORDS_AND_HEALTH_INFORMATION_TECHNICIANS: {
        return "Quản lý hồ sơ và thông tin y tế";
      }
      case JobFunction.MEDICAL_SCIENTISTS: {
        return "Nhà Khoa học Y tế";
      }
      case JobFunction.MEDICAL_TRANSCRIPTIONISTS: {
        return "Phiên dịch y tế";
      }
      case JobFunction.MEDICAL_DENTAL_AND_OPHTHALMIC_LABORATORY_TECHNICIANS: {
        return "Kỹ thuật viên y tế";
      }
      case JobFunction.MEETING_AND_CONVENTION_PLANNERS: {
        return "Nhà lên kế hoạch tổ chức hội nghị và hội thảo";
      }
      case JobFunction.MEETING_CONVENTION_AND_EVENT_PLANNERS: {
        return "Nhà lên kế hoạch tổ chức hội nghị, hội thảo và sự kiện";
      }
      case JobFunction.METAL_AND_PLASTIC_MACHINE_WORKERS: {
        return "Công nhân máy";
      }
      case JobFunction.MICROBIOLOGISTS: {
        return "Nhà vi trùng học";
      }
      case JobFunction.MIDDLE_SCHOOL_TEACHERS: {
        return "Giáo viên trung học";
      }
      case JobFunction.MILITARY_CAREERS: {
        return "Công tác Quân sự";
      }
      case JobFunction.MILLWRIGHTS: {
        return "Thợ cối xay";
      }
      case JobFunction.MINING_AND_GEOLOGICAL_ENGINEERS: {
        return "Kỹ sư khai thác địa chất";
      }
      case JobFunction.MODELS: {
        return "Người mẫu";
      }
      case JobFunction.METER_READERS_UTILITIES: {
        return "Dịch vụ tiện ích";
      }
      case JobFunction.MULTIMEDIA_ARTISTS_AND_ANIMATORS: {
        return "Nghệ sĩ đa phương tiện và hoạt hình";
      }
      case JobFunction.MUSIC_DIRECTORS_AND_COMPOSERS: {
        return "Giám đốc âm nhạc và nhà soạn nhạc";
      }
      case JobFunction.MUSICIANS_AND_SINGERS: {
        return "Nhạc sĩ và ca sĩ";
      }
      case JobFunction.MUSICIANS_SINGERS_AND_RELATED_WORKERS: {
        return "Nhạc sĩ, ca sĩ và các ngành nghề liên quan";
      }
      case JobFunction.NATURAL_SCIENCES_MANAGERS: {
        return "Quản lý khoa học tự nhiên";
      }
      case JobFunction.NETWORK_AND_COMPUTER_SYSTEMS_ADMINISTRATORS: {
        return "Quản trị viên mạng và hệ thống máy tính";
      }
      case JobFunction.NEWS_ANALYSTS_REPORTERS_AND_CORRESPONDENTS: {
        return "Phóng viên";
      }
      case JobFunction.NUCLEAR_ENGINEERS: {
        return "Kỹ sư hạt nhân";
      }
      case JobFunction.NUCLEAR_MEDICINE_TECHNOLOGISTS: {
        return "Chuyên gia Công nghệ Y học Hạt nhân";
      }
      case JobFunction.NUCLEAR_TECHNICIANS: {
        return "Kỹ thuật viên hạt nhân";
      }
      case JobFunction.NURSE_ANESTHETISTS_NURSE_MIDWIVES_AND_NURSE_PRACTITIONERS: {
        return "Y tá gây mê, Y tá Hộ sinh và Điều dưỡng viên";
      }
      case JobFunction.NURSING_ASSISTANTS_AND_ORDERLIES: {
        return "Trợ lý điều dưỡng và Nhân viên Hỗ trợ vệ sinh cho bệnh nhân";
      }
      case JobFunction.NURSING_PSYCHIATRIC_AND_HOME_HEALTH_AIDES: {
        return "Trợ lý sức khỏe tại nhà";
      }
      case JobFunction.OCCUPATIONAL_HEALTH_AND_SAFETY_SPECIALISTS_AND_TECHNICIANS: {
        return "Kỹ thuật viên sức khỏe nghề nghiệp";
      }
      case JobFunction.OCCUPATIONAL_THERAPIST_ASSISTANTS_AND_AIDES: {
        return "Trợ lý Chuyên gia vật lý trị liệu";
      }
      case JobFunction.OCCUPATIONAL_THERAPISTS: {
        return "Chuyên gia vật lý trị liệu";
      }
      case JobFunction.OCCUPATIONAL_THERAPY_ASSISTANTS_AND_AIDES: {
        return "Trợ lý trị liệu sức khỏe nghề nghiệp";
      }
      case JobFunction.OFFICE_AND_ADMINISTRATIVE_SUPPORT_WORKER_SUPERVISORS_AND_MANAGERS: {
        return "Nhân viên hành chính";
      }
      case JobFunction.OFFICE_CLERKS_GENERAL: {
        return "Thư ký văn phòng";
      }
      case JobFunction.OPERATIONS_RESEARCH_ANALYSTS: {
        return "Phân tích nghiên cứu hoạt động";
      }
      case JobFunction.OPTICIANS: {
        return "Bác sĩ nhãn khoa";
      }
      case JobFunction.OPTICIANS_DISPENSING: {
        return "Bác sĩ nhãn khoa";
      }
      case JobFunction.OPTOMETRISTS: {
        return "Bác sĩ đo thị lực";
      }
      case JobFunction.ORDER_CLERKS: {
        return "Thư ký nhận đơn đặt hàng";
      }
      case JobFunction.ORTHOTISTS_AND_PROSTHETISTS: {
        return "Bác sĩ chỉnh hình và Chuyên gia Dụng cụ chỉnh hình";
      }
      case JobFunction.PAINTERS_AND_PAPERHANGERS: {
        return "Công nhân sơn và dán tường";
      }
      case JobFunction.PAINTERS_CONSTRUCTION_AND_MAINTENANCE: {
        return "Công nhân xây dựng, sơn sửa và bảo trì";
      }
      case JobFunction.PAINTING_AND_COATING_WORKERS: {
        return "Công nhân sơn và bảo trì";
      }
      case JobFunction.PAINTING_AND_COATING_WORKERS_EXCEPT_CONSTRUCTION_AND_MAINTENANCE: {
        return "Công nhân sơn";
      }
      case JobFunction.PARALEGALS_AND_LEGAL_ASSISTANTS: {
        return "Trợ lý pháp chế và luật";
      }
      case JobFunction.PAYROLL_AND_TIMEKEEPING_CLERKS: {
        return "Nhân viên kiểm soát biên chế và tính công";
      }
      case JobFunction.PERSONAL_AND_HOME_CARE_AIDES: {
        return "Trợ lý Y sinh";
      }
      case JobFunction.PERSONAL_FINANCIAL_ADVISORS: {
        return "Cố vấn tài chính cá nhân";
      }
      case JobFunction.PEST_CONTROL_WORKERS: {
        return "Công nhân kiểm soát dịch hại";
      }
      case JobFunction.PETROLEUM_ENGINEERS: {
        return "Kỹ sư dầu khí";
      }
      case JobFunction.PHARMACISTS: {
        return "Dược sĩ";
      }
      case JobFunction.PHARMACY_AIDES: {
        return "Trợ lý dược";
      }
      case JobFunction.PHARMACY_TECHNICIANS: {
        return "Bác sĩ dược";
      }
      case JobFunction.PHLEBOTOMISTS: {
        return "Chuyên viên Truyền máu, lấy máu";
      }
      case JobFunction.PHOTOGRAPHERS: {
        return "Nhiếp ảnh gia";
      }
      case JobFunction.PHYSICAL_THERAPIST_ASSISTANTS_AND_AIDES: {
        return "Trợ lý bác sĩ vật lý trị liệu";
      }
      case JobFunction.PHYSICAL_THERAPISTS: {
        return "Bác sĩ vật lý trị liệu";
      }
      case JobFunction.PHYSICIAN_ASSISTANTS: {
        return "Phụ tá bác sĩ";
      }
      case JobFunction.PHYSICIANS_AND_SURGEONS: {
        return "Bác sĩ phẫu thuật";
      }
      case JobFunction.PHYSICISTS_AND_ASTRONOMERS: {
        return "Nhà viên văn học";
      }
      case JobFunction.PIPELAYERS_PLUMBERS_PIPEFITTERS_AND_STEAMFITTERS: {
        return "Thợ lắp ống nước";
      }
      case JobFunction.PHOTOGRAPHIC_PROCESS_WORKERS_AND_PROCESSING_MACHINE_OPERATORS: {
        return "Nhân viên xử lý ảnh";
      }
      case JobFunction.PLUMBERS_PIPEFITTERS_AND_STEAMFITTERS: {
        return "Thợ sửa máy, ống nước";
      }
      case JobFunction.PODIATRISTS: {
        return "Bác sĩ chuyên sâu các bệnh về chân";
      }
      case JobFunction.POLICE_AND_DETECTIVES: {
        return "Điều tra viên, cảnh sát";
      }
      case JobFunction.POLICE_FIRE_AND_AMBULANCE_DISPATCHERS: {
        return "Điều phối viên Cảnh sát, Cứu hỏa và Cứu thương";
      }
      case JobFunction.POLITICAL_SCIENTISTS: {
        return "Nhà Chính trị học";
      }
      case JobFunction.POSTAL_SERVICE_WORKERS: {
        return "Nhân viên dịch vụ bưu chính";
      }
      case JobFunction.POSTSECONDARY_EDUCATION_ADMINISTRATORS: {
        return "Giám thị trung học";
      }
      case JobFunction.POSTSECONDARY_TEACHERS: {
        return "Giáo viên trung học";
      }
      case JobFunction.PLASTERERS_AND_STUCCO_MASONS: {
        return "Thợ xây trát vữa";
      }
      case JobFunction.POWER_PLANT_OPERATORS_DISTRIBUTORS_AND_DISPATCHERS: {
        return "Nhân viên vận hành và phân phối nhà máy điện";
      }
      case JobFunction.PRESCHOOL_AND_CHILDCARE_CENTER_DIRECTORS: {
        return "Giám đốc trung tâm mẫu giáo và chăm sóc trẻ em";
      }
      case JobFunction.PRESCHOOL_TEACHERS: {
        return "Giáo viên mầm non";
      }
      case JobFunction.PRIVATE_DETECTIVES_AND_INVESTIGATORS: {
        return "Điều tra viên/Thám tử";
      }
      case JobFunction.PROBATION_OFFICERS_AND_CORRECTIONAL_TREATMENT_SPECIALISTS: {
        return "Nhân viên quản chế và trị liệu cải tạo";
      }
      case JobFunction.PROCUREMENT_CLERKS: {
        return "Nhân viên tạp vụ";
      }
      case JobFunction.PROCUREMENT_MANAGER: {
        return "Quản lý mua hàng";
      }
      case JobFunction.PRODUCERS_AND_DIRECTORS: {
        return "Nhà sản xuất và đạo diễn";
      }
      case JobFunction.PRODUCTION_PLANNING_AND_EXPEDITING_CLERKS: {
        return "Nhân viên xúc tiến sản xuất, kế hoạch";
      }
      case JobFunction.PROPERTY_REAL_ESTATE_AND_COMMUNITY_ASSOCIATION_MANAGERS: {
        return "Quản lý tài sản, bất động sản";
      }
      case JobFunction.PRECISION_INSTRUMENT_AND_EQUIPMENT_REPAIRERS: {
        return "Nhân viên sửa chữa thiết bị và dụng cụ đo đạc";
      }
      case JobFunction.PSYCHIATRIC_TECHNICIANS_AND_AIDES: {
        return "Kỹ thuật viên tâm thần";
      }
      case JobFunction.PREPRESS_TECHNICIANS_AND_WORKERS: {
        return "Kĩ thuật viên về tài liệu in ấn";
      }
      case JobFunction.PSYCHOLOGISTS: {
        return "Nhà tâm lý học";
      }
      case JobFunction.PUBLIC_RELATIONS_AND_FUNDRAISING_MANAGERS: {
        return "Quản lý gây quỹ và quan hệ công chúng";
      }
      case JobFunction.PUBLIC_RELATIONS_SPECIALISTS: {
        return "Chuyên gia quan hệ công chúng";
      }
      case JobFunction.PURCHASING_MANAGERS_BUYERS_AND_PURCHASING_AGENTS: {
        return "Chuyên viên/Quản lý mua, bán hàng";
      }
      case JobFunction.PRINTING_MACHINE_OPERATORS: {
        return "Nhân viên vận hành máy in";
      }
      case JobFunction.QUALITY_CONTROL_INSPECTORS: {
        return "Thanh tra kiểm soát chất lượng";
      }
      case JobFunction.RADIATION_THERAPISTS: {
        return "Nhà trị liệu bức xạ";
      }
      case JobFunction.RADIO_AND_TELECOMMUNICATIONS_EQUIPMENT_INSTALLERS_AND_REPAIRERS: {
        return "Nhân viên lắp đặt và sửa chữa thiết bị truyền thông";
      }
      case JobFunction.RADIOLOGIC_AND_MRI_TECHNOLOGISTS: {
        return "Kĩ thuật viên MRI";
      }
      case JobFunction.RADIOLOGIC_TECHNOLOGISTS_AND_TECHNICIANS: {
        return "Kĩ thuật viên về phóng xạ";
      }
      case JobFunction.RAIL_TRANSPORTATION_OCCUPATIONS: {
        return "Vận tải đường sắt";
      }
      case JobFunction.RAILROAD_WORKERS: {
        return "Công nhân đường sắt";
      }
      case JobFunction.REAL_ESTATE_BROKERS_AND_SALES_AGENTS: {
        return "Đại lý môi giới bất động sản";
      }
      case JobFunction.RECEPTIONISTS: {
        return "Nhân viên tiếp tân";
      }
      case JobFunction.RECEPTIONISTS_AND_INFORMATION_CLERKS: {
        return "Lễ tân";
      }
      case JobFunction.RECREATION_WORKERS: {
        return "Tổ chức sự kiện giải trí cộng đồng";
      }
      case JobFunction.RECREATIONAL_THERAPISTS: {
        return "Chuyên gia Giải trí trị liệu";
      }
      case JobFunction.REGISTERED_NURSES: {
        return "Y tá";
      }
      case JobFunction.REHABILITATION_COUNSELORS: {
        return "Cố vấn phục hồi chức năng";
      }
      case JobFunction.REPORTERS_CORRESPONDENTS_AND_BROADCAST_NEWS_ANALYSTS: {
        return "Phóng viên và nhà phân tích tin tức phát sóng";
      }
      case JobFunction.RESERVATION_AND_TRANSPORTATION_TICKET_AGENTS_AND_TRAVEL_CLERKS: {
        return "Nhân viên du lịch, đặt phòng và phương tiện di chuyển";
      }
      case JobFunction.RESPIRATORY_THERAPISTS: {
        return "Bác sĩ trị liệu về hô hấp";
      }
      case JobFunction.RETAIL_SALES_WORKERS: {
        return "Nhân viên bán lẻ";
      }
      case JobFunction.RETAIL_SALESPERSONS: {
        return "Nhân viên Bán lẻ";
      }
      case JobFunction.ROOFERS: {
        return "Thợ lợp nhà";
      }
      case JobFunction.SALES_ENGINEERS: {
        return "Kỹ sư bán hàng";
      }
      case JobFunction.SALES_MANAGERS: {
        return "Trưởng phòng Kinh doanh";
      }
      case JobFunction.SALES_REPRESENTATIVES_WHOLESALE_AND_MANUFACTURING: {
        return "Đại diện Kinh doanh, Bán buôn và Sản xuất";
      }
      case JobFunction.SCHOOL_AND_CAREER_COUNSELORS: {
        return "Cố vấn trường học và nghề nghiệp";
      }
      case JobFunction.SECRETARIES_AND_ADMINISTRATIVE_ASSISTANTS: {
        return "Thư ký và Trợ lý hành chính";
      }
      case JobFunction.SECURITIES_COMMODITIES_AND_FINANCIAL_SERVICES_SALES_AGENTS: {
        return "Kinh doanh dịch vụ tài chính, chứng khoán, hàng hóa";
      }
      case JobFunction.SECURITY_GUARDS_AND_GAMING_SURVEILLANCE_OFFICERS: {
        return "Nhân viên bảo vệ và giám sát trò chơi";
      }
      case JobFunction.SHEET_METAL_WORKERS: {
        return "Công nhân Kim loại tấm";
      }
      case JobFunction.SHIPPING_RECEIVING_AND_TRAFFIC_CLERKS: {
        return "Nhân viên giao nhận và vận chuyển";
      }
      case JobFunction.SKINCARE_SPECIALISTS: {
        return "Chuyên viên Da liễu";
      }
      case JobFunction.SMALL_ENGINE_MECHANICS: {
        return "Thợ cơ khí máy nhỏ";
      }
      case JobFunction.SOCIAL_AND_COMMUNITY_SERVICE_MANAGERS: {
        return "Quản lý dịch vụ xã hội";
      }
      case JobFunction.SOCIAL_AND_HUMAN_SERVICE_ASSISTANTS: {
        return "Trợ lý dịch vụ xã hội và con người";
      }
      case JobFunction.SOCIAL_SCIENTISTS_OTHER: {
        return "Nhà khoa học xã hội";
      }
      case JobFunction.SOCIAL_WORKERS: {
        return "Nhân viên xã hội";
      }
      case JobFunction.SALES_WORKER_SUPERVISORS: {
        return "Giám sát bán hàng";
      }
      case JobFunction.SOCIOLOGISTS: {
        return "Nhà Xã hội học";
      }
      case JobFunction.SOFTWARE_DEVELOPERS: {
        return "Lập trình viên";
      }
      case JobFunction.SCIENCE_TECHNICIANS: {
        return "Kỹ thuật viên khoa học";
      }
      case JobFunction.SOLAR_PHOTOVOLTAIC_INSTALLERS: {
        return "Nhân viên lắp đặt quang điện mặt trời";
      }
      case JobFunction.SPECIAL_EDUCATION_TEACHERS: {
        return "Giáo viên giáo dục đặc biệt";
      }
      case JobFunction.SPEECH_LANGUAGE_PATHOLOGISTS: {
        return "Nghiên cứu bệnh học ngôn ngữ";
      }
      case JobFunction.STATIONARY_ENGINEERS_AND_BOILER_OPERATORS: {
        return "Kĩ sư trạm và vận hành lò hơi";
      }
      case JobFunction.SEMICONDUCTOR_PROCESSORS: {
        return "Nhân viên xử lý ngành bán dẫn";
      }
      case JobFunction.STATISTICIANS: {
        return "Chuyên viên Thống kê";
      }
      case JobFunction.STOCK_CLERKS_AND_ORDER_FILLERS: {
        return "Nhân viên chứng khoán và điền đơn hàng";
      }
      case JobFunction.STRUCTURAL_AND_REINFORCING_IRON_AND_METAL_WORKERS: {
        return "Công nhân gia công kim loại";
      }
      case JobFunction.SUBSTANCE_ABUSE_BEHAVIORAL_DISORDER_AND_MENTAL_HEALTH_COUNSELORS: {
        return "Cố vấn về sức khỏe tinh thần, rối loạn hành vi và làm dụng thuốc";
      }
      case JobFunction.SUPPLY_MANAGEMENT: {
        return "Quản lý cung ứng";
      }
      case JobFunction.SURGICAL_TECHNOLOGISTS: {
        return "Kỹ thuật viên hỗ trợ phẫu thuật";
      }
      case JobFunction.SURVEY_RESEARCHERS: {
        return "Nghiên cứu khảo sát";
      }
      case JobFunction.SURVEYING_AND_MAPPING_TECHNICIANS: {
        return "Kĩ thuật viên đo vẽ bản đồ";
      }
      case JobFunction.SURVEYORS: {
        return "Khảo sát viên";
      }
      case JobFunction.SURVEYORS_CARTOGRAPHERS_PHOTOGRAMMETRISTS_AND_SURVEYING_AND_MAPPING_TECHNICIANS: {
        return "Kĩ thuật viên đo, khảo, chụp ảnh và vẽ bản đồ";
      }
      case JobFunction.TAX_EXAMINERS_AND_COLLECTORS_AND_REVENUE_AGENTS: {
        return "Nhân viên Kiểm tra, Thu thuế và Đại lý doanh thu";
      }
      case JobFunction.TAX_EXAMINERS_COLLECTORS_AND_REVENUE_AGENTS: {
        return "Nhân viên Kiểm tra, Thu thuế và Đại lý doanh thu";
      }
      case JobFunction.TAXI_DRIVERS_AND_CHAUFFEURS: {
        return "Tài xế Taxi";
      }
      case JobFunction.TAXI_DRIVERS_RIDE_HAILING_DRIVERS_AND_CHAUFFEURS: {
        return "Tài xế riêng";
      }
      case JobFunction.TEACHER_ASSISTANTS: {
        return "Trợ giảng";
      }
      case JobFunction.TEACHERS_ADULT_LITERACY_AND_REMEDIAL_EDUCATION: {
        return "Giáo viên - Trung tâm Giáo dục thường xuyên";
      }
      case JobFunction.TEACHERS_POSTSECONDARY: {
        return "Giáo viên - Giáo dục sau trung học";
      }
      case JobFunction.TEACHERS_PRESCHOOL_KINDERGARTEN_ELEMENTARY_MIDDLE_AND_SECONDARY: {
        return "Giáo viên - Mầm non, Mẫu giáo, Tiểu học, Trung học cơ sở, Trung học";
      }
      case JobFunction.TEACHERS_SPECIAL_EDUCATION: {
        return "Giáo viên - Giáo dục đặc biệt";
      }
      case JobFunction.TECHNICAL_WRITERS: {
        return "Phiên dịch viên tài liệu kĩ thuật";
      }
      case JobFunction.TELECOMMUNICATIONS_EQUIPMENT_INSTALLERS_AND_REPAIRERS: {
        return "Nhân viên lắp đặt và sửa chữa thiết bị viễn thông";
      }
      case JobFunction.TELEVISION_VIDEO_AND_MOTION_PICTURE_CAMERA_OPERATORS_AND_EDITORS: {
        return "Biên tập và quay phim";
      }
      case JobFunction.TELLERS: {
        return "Giao dịch viên";
      }
      case JobFunction.TOOL_AND_DIE_MAKERS: {
        return "Sản xuất công cụ và thuốc nhuộm";
      }
      case JobFunction.TOP_EXECUTIVES: {
        return "Giám đốc điều hành";
      }
      case JobFunction.TRAINING_AND_DEVELOPMENT_MANAGERS: {
        return "Quản lý đào tạo và phát triển";
      }
      case JobFunction.TRAINING_AND_DEVELOPMENT_SPECIALISTS: {
        return "Chuyên gia đào tạo và phát triển";
      }
      case JobFunction.TRAVEL_AGENTS: {
        return "Đại lý du lịch";
      }
      case JobFunction.TRUCK_DRIVERS_AND_DRIVERSALES_WORKERS: {
        return "Tài xế";
      }
      case JobFunction.UMPIRES_REFEREES_AND_OTHER_SPORTS_OFFICIALS: {
        return "Trọng tài thể thao";
      }
      case JobFunction.URBAN_AND_REGIONAL_PLANNERS: {
        return "Hoạch định đất đai";
      }
      case JobFunction.VETERINARIANS: {
        return "Bác sĩ Thú y";
      }
      case JobFunction.TEACHERS_SELF_ENRICHMENT_EDUCATION: {
        return "Giáo viên - Phát triển bản thân";
      }
      case JobFunction.VETERINARY_ASSISTANTS_AND_LABORATORY_ANIMAL_CARETAKERS: {
        return "Trợ ý thú y và chăm sóc động vật phòng thí nghiệm";
      }
      case JobFunction.VETERINARY_TECHNOLOGISTS_AND_TECHNICIANS: {
        return "Kĩ thuật viên thú ý";
      }
      case JobFunction.WAITERS_AND_WAITRESSES: {
        return "Bồi bàn";
      }
      case JobFunction.WATER_AND_LIQUID_WASTE_TREATMENT_PLANT_AND_SYSTEM_OPERATORS: {
        return "Nhân viên vận hành nhà máy và hệ thống nước và xử lý chất thải lỏng";
      }
      case JobFunction.WATER_AND_WASTEWATER_TREATMENT_PLANT_AND_SYSTEM_OPERATORS: {
        return "Nhân viên vận hành nhà máy và hệ thống nước và xử lý nước thải";
      }
      case JobFunction.WATER_TRANSPORTATION_OCCUPATIONS: {
        return "Nhân viên vận chuyển nước";
      }
      case JobFunction.TEXTILE_APPAREL_AND_FURNISHINGS_OCCUPATIONS: {
        return "Nhân viên may mặc";
      }
      case JobFunction.WATER_TRANSPORTATION_WORKERS: {
        return "Công nhân vận chuyển nước";
      }
      case JobFunction.WEB_DEVELOPERS: {
        return "Lập trình viên Web";
      }
      case JobFunction.WEIGHERS_MEASURERS_CHECKERS_AND_SAMPLERS_RECORDKEEPING: {
        return "Nhân viên lưu trữ và kiểm soát hồ sơ";
      }
      case JobFunction.WELDERS_CUTTERS_SOLDERERS_AND_BRAZERS: {
        return "Công nhân hàn";
      }
      case JobFunction.WELDING_SOLDERING_AND_BRAZING_WORKERS: {
        return "Thợ cắt kim loại";
      }
      case JobFunction.WHOLESALE_AND_MANUFACTURING_SALES_REPRESENTATIVES: {
        return "Đại diện Kinh doanh, Bán buôn và Sản xuất";
      }
      case JobFunction.WIND_TURBINE_TECHNICIANS: {
        return "Kỹ thuật viên tuabin gió";
      }
      case JobFunction.WOODWORKERS: {
        return "Thợ mộc";
      }
      case JobFunction.WRITERS_AND_AUTHORS: {
        return "Tác giả";
      }
      case JobFunction.WRITERS_AND_EDITORS: {
        return "Nhà văn và Biên tập viên";
      }
      case JobFunction.ZOOLOGISTS_AND_WILDLIFE_BIOLOGISTS: {
        return "Nhà động vật học và Nhà sinh vật học hoang dã";
      }
      case JobFunction.OTHER: {
        return "Khác";
      }
    }
    return "";
  }

  private static getJobFunctionTitleInTurkish(
    jobFunction: JobFunction,
  ): string {
    switch (jobFunction) {
      case JobFunction.ACCOUNTANTS_AND_AUDITORS: {
        return "Muhasebeciler ve denetçiler";
      }
      case JobFunction.ACTORS_PRODUCERS_AND_DIRECTORS: {
        return "Aktörler, yapımcılar ve yönetmenler";
      }
      case JobFunction.ACTUARIES: {
        return "Aktüerler";
      }
      case JobFunction.ADMINISTRATIVE_SERVICES_MANAGERS: {
        return "İdari Hizmetler Yöneticileri";
      }
      case JobFunction.ADULT_LITERACY_AND_HIGH_SCHOOL_EQUIVALENCY_DIPLOMA_TEACHERS: {
        return "Yetişkin okuryazarlığı ve lise eşdeğeri diploması";
      }
      case JobFunction.ADVERTISING_SALES_AGENTS: {
        return "Reklam satış acenteleri";
      }
      case JobFunction.ADVERTISING_MARKETING_PROMOTIONS_PUBLIC_RELATIONS_AND_SALES_MANAGERS: {
        return "Reklam, Pazarlama, Promosyonlar, Kamu Relatio";
      }
      case JobFunction.AEROSPACE_ENGINEERING_AND_OPERATIONS_TECHNICIANS: {
        return "Havacılık Mühendisliği ve Operasyonları Teknisyenleri";
      }
      case JobFunction.AEROSPACE_ENGINEERS: {
        return "Havacılık Mühendisleri";
      }
      case JobFunction.AGRICULTURAL_AND_FOOD_SCIENCE_TECHNICIANS: {
        return "Tarım ve Gıda Bilimi Teknisyenleri";
      }
      case JobFunction.AGRICULTURAL_AND_FOOD_SCIENTISTS: {
        return "Tarım ve Gıda Bilimcileri";
      }
      case JobFunction.AGRICULTURAL_ENGINEERS: {
        return "Tarım Mühendisleri";
      }
      case JobFunction.AGRICULTURAL_WORKERS: {
        return "Tarım işçileri";
      }
      case JobFunction.AIR_TRAFFIC_CONTROLLERS: {
        return "Hava Trafik Denetleyicileri";
      }
      case JobFunction.AIRCRAFT_AND_AVIONICS_EQUIPMENT_MECHANICS_AND_SERVICE_TECHNICIANS: {
        return "Uçak ve aviyonik ekipman mekaniği ve servisi";
      }
      case JobFunction.AIRCRAFT_PILOTS_AND_FLIGHT_ENGINEERS: {
        return "Uçak pilotları ve uçuş mühendisleri";
      }
      case JobFunction.ANIMAL_CARE_AND_SERVICE_WORKERS: {
        return "Hayvan Bakımı ve Hizmet Çalışanları";
      }
      case JobFunction.ANNOUNCERS: {
        return "Spiker";
      }
      case JobFunction.ANTHROPOLOGISTS_AND_ARCHEOLOGISTS: {
        return "Antropologlar ve arkeologlar";
      }
      case JobFunction.APPRAISERS_AND_ASSESSORS_OF_REAL_ESTATE: {
        return "Değerlendiriciler ve gayrimenkul değerlendiricileri";
      }
      case JobFunction.ARBITRATORS_MEDIATORS_AND_CONCILIATORS: {
        return "Hakemler, arabulucular ve uzlaştırıcılar";
      }
      case JobFunction.ARCHITECTS_EXCEPT_LANDSCAPE_AND_NAVAL: {
        return "Manzara ve deniz hariç mimarlar";
      }
      case JobFunction.ARCHITECTURAL_AND_ENGINEERING_MANAGERS: {
        return "Mimari ve Mühendislik Yöneticileri";
      }
      case JobFunction.ARCHIVISTS_CURATORS_AND_MUSEUM_TECHNICIANS: {
        return "Arşivciler, küratörler ve müze teknisyenleri";
      }
      case JobFunction.ART_DIRECTORS: {
        return "Sanat yönetmenleri";
      }
      case JobFunction.ARTISTS_AND_RELATED_WORKERS: {
        return "Sanatçılar ve ilgili işçiler";
      }
      case JobFunction.ASSEMBLERS_AND_FABRICATORS: {
        return "Montajcılar ve imalatçılar";
      }
      case JobFunction.ATHLETES_COACHES_UMPIRES_AND_RELATED_WORKERS: {
        return "Sporcular, antrenörler, hakemler ve ilgili işçiler";
      }
      case JobFunction.ATHLETIC_TRAINERS: {
        return "Atletik eğitmenler";
      }
      case JobFunction.ATMOSPHERIC_SCIENTISTS: {
        return "Atmosfer bilim adamları";
      }
      case JobFunction.AUDIOLOGISTS: {
        return "Odyologlar";
      }
      case JobFunction.AUTOMOTIVE_BODY_AND_RELATED_REPAIRERS: {
        return "Otomotiv gövdesi ve ilgili tamirciler";
      }
      case JobFunction.AUTOMOTIVE_SERVICE_TECHNICIANS_AND_MECHANICS: {
        return "Otomotiv Servis Teknisyenleri ve Mekanikleri";
      }
      case JobFunction.BAKERS: {
        return "Fırıncılar";
      }
      case JobFunction.BARBERS_COSMETOLOGISTS_AND_OTHER_PERSONAL_APPEARANCE_WORKERS: {
        return "Berberler, kozmetologlar ve diğer kişisel görünür";
      }
      case JobFunction.BARTENDERS: {
        return "Barmenler";
      }
      case JobFunction.BILL_AND_ACCOUNT_COLLECTORS: {
        return "Fatura ve hesap koleksiyoncuları";
      }
      case JobFunction.BILLING_AND_POSTING_CLERKS_AND_MACHINE_OPERATORS: {
        return "Faturalandırma ve Gönderme Katip ve Makine Operatörleri";
      }
      case JobFunction.BIOLOGICAL_SCIENTISTS: {
        return "Biyolojik bilim adamları";
      }
      case JobFunction.BIOLOGICAL_TECHNICIANS: {
        return "Biyolojik teknisyenler";
      }
      case JobFunction.BIOMEDICAL_ENGINEERS: {
        return "Biyomedikal mühendisleri";
      }
      case JobFunction.BOILERMAKERS: {
        return "Kazer üreticileri";
      }
      case JobFunction.BOOKBINDERS_AND_BINDERY_WORKERS: {
        return "Bookbinders ve Bindery çalışanları";
      }
      case JobFunction.BOOKKEEPING_ACCOUNTING_AND_AUDITING_CLERKS: {
        return "Defter tutma, muhasebe ve denetim katipleri";
      }
      case JobFunction.BRICKMASONS_BLOCKMASONS_AND_STONEMASONS: {
        return "Brickmasons, blokmasonlar ve taş";
      }
      case JobFunction.BROADCAST_AND_SOUND_ENGINEERING_TECHNICIANS_AND_RADIO_OPERATORS: {
        return "Yayın ve Ses Mühendisliği Teknisyenleri ve RA";
      }
      case JobFunction.BROKERAGE_CLERKS: {
        return "Aracılık Katipleri";
      }
      case JobFunction.BUDGET_ANALYSTS: {
        return "Bütçe Analistleri";
      }
      case JobFunction.BUILDING_CLEANING_WORKERS: {
        return "Temizlik işçileri bina";
      }
      case JobFunction.BUS_DRIVERS: {
        return "Otobüs şoförleri";
      }
      case JobFunction.BUTCHERS: {
        return "Kasap";
      }
      case JobFunction.CARDIOVASCULAR_TECHNOLOGISTS_AND_TECHNICIANS: {
        return "Kardiyovasküler teknoloji uzmanları ve teknisyenler";
      }
      case JobFunction.CAREER_AND_TECHNICAL_EDUCATION_TEACHERS: {
        return "Kariyer ve Teknik Eğitim Öğretmenleri";
      }
      case JobFunction.CARGO_AND_FREIGHT_AGENTS: {
        return "Kargo ve yük acenteleri";
      }
      case JobFunction.CARPENTERS: {
        return "Marangozlar";
      }
      case JobFunction.CARPET_FLOOR_AND_TILE_INSTALLERS_AND_FINISHERS: {
        return "Halı, zemin ve karo montajcılar ve sonlandırıcılar";
      }
      case JobFunction.CARTOGRAPHERS_AND_PHOTOGRAMMETRISTS: {
        return "Haritacılar ve fotogrametristler";
      }
      case JobFunction.CASHIERS: {
        return "Kasa";
      }
      case JobFunction.CEMENT_MASONS_CONCRETE_FINISHERS_SEGMENTAL_PAVERS_AND_TERRAZZO_WORKERS: {
        return "Çimento Masonları, Beton Sonlandırıcılar, Segmental Kaldırmacı";
      }
      case JobFunction.CHEFS_AND_HEAD_COOKS: {
        return "Şefler ve kafa aşçıları";
      }
      case JobFunction.CHEFS_COOKS_AND_FOOD_PREPARATION_WORKERS: {
        return "Şefler, aşçılar ve yemek hazırlama işçileri";
      }
      case JobFunction.CHEMICAL_ENGINEERS: {
        return "Kimya Mühendisleri";
      }
      case JobFunction.CHEMICAL_TECHNICIANS: {
        return "Kimyasal teknisyenler";
      }
      case JobFunction.CHEMISTS_AND_MATERIALS_SCIENTISTS: {
        return "Kimyagerler ve Malzeme Bilimcileri";
      }
      case JobFunction.CHILD_CARE_WORKERS: {
        return "Çocuk Bakım Çalışanları";
      }
      case JobFunction.CHIROPRACTORS: {
        return "Kayropraktörler";
      }
      case JobFunction.CIVIL_ENGINEERING_TECHNICIANS: {
        return "İnşaat Mühendisliği Teknisyenleri";
      }
      case JobFunction.CIVIL_ENGINEERS: {
        return "İnşaat mühendisleri";
      }
      case JobFunction.CLAIMS_ADJUSTERS_APPRAISERS_EXAMINERS_AND_INVESTIGATORS: {
        return "Talepler, Değerlendiriciler, Sınavcılar ve Inves";
      }
      case JobFunction.CLINICAL_LABORATORY_TECHNOLOGISTS_AND_TECHNICIANS: {
        return "Klinik Laboratuvar Teknologları ve Teknisyenleri";
      }
      case JobFunction.COACHES_AND_SCOUTS: {
        return "Antrenörler ve İzciler";
      }
      case JobFunction.COIN_VENDING_AND_AMUSEMENT_MACHINE_SERVICERS_AND_REPAIRERS: {
        return "Madeni para, otomat ve eğlence makinesi hizmetçileri ve";
      }
      case JobFunction.COMMERCIAL_AND_INDUSTRIAL_DESIGNERS: {
        return "Ticari ve Endüstriyel Tasarımcılar";
      }
      case JobFunction.COMMERCIAL_MANAGEMENT: {
        return "Ticari yönetim";
      }
      case JobFunction.COMMUNICATIONS_EQUIPMENT_OPERATORS: {
        return "İletişim Ekipmanları Operatörleri";
      }
      case JobFunction.COMPENSATION_AND_BENEFITS_MANAGERS: {
        return "Tazminat ve Faydalar Yöneticileri";
      }
      case JobFunction.COMPENSATION_BENEFITS_AND_JOB_ANALYSIS_SPECIALISTS: {
        return "Tazminat, faydalar ve iş analizi özel";
      }
      case JobFunction.COMPUTER_AND_INFORMATION_RESEARCH_SCIENTISTS: {
        return "Bilgisayar ve Bilgi Araştırma Bilimcileri";
      }
      case JobFunction.COMPUTER_AND_INFORMATION_SYSTEMS_MANAGERS: {
        return "Bilgisayar ve Bilgi Sistemleri Yöneticileri";
      }
      case JobFunction.COMPUTER_CONTROL_PROGRAMMERS_AND_OPERATORS: {
        return "Bilgisayar Kontrol Programcıları ve Operatörleri";
      }
      case JobFunction.COMPUTER_HARDWARE_ENGINEERS: {
        return "Bilgisayar Donanım Mühendisleri";
      }
      case JobFunction.COMPUTER_NETWORK_ARCHITECTS: {
        return "Bilgisayar Ağı Mimarları";
      }
      case JobFunction.COMPUTER_OPERATORS: {
        return "Bilgisayar operatörleri";
      }
      case JobFunction.COMPUTER_PROGRAMMERS: {
        return "Bilgisayar programcıları";
      }
      case JobFunction.COMPUTER_SCIENTISTS_AND_DATABASE_ADMINISTRATORS: {
        return "Bilgisayar Bilimcileri ve Veritabanı Yöneticileri";
      }
      case JobFunction.COMPUTER_SOFTWARE_ENGINEERS: {
        return "Bilgisayar Yazılım Mühendisleri";
      }
      case JobFunction.COMPUTER_SUPPORT_SPECIALISTS: {
        return "Bilgisayar Destek Uzmanları";
      }
      case JobFunction.COMPUTER_SUPPORT_SPECIALISTS_AND_SYSTEMS_ADMINISTRATORS: {
        return "Bilgisayar Destek Uzmanları ve Sistemleri Yöneticisi";
      }
      case JobFunction.COMPUTER_SYSTEMS_ANALYSTS: {
        return "Bilgisayar Sistemleri Analistleri";
      }
      case JobFunction.COMPUTER_AUTOMATED_TELLER_AND_OFFICE_MACHINE_REPAIRERS: {
        return "Bilgisayar, Otomatik Vezler ve Ofis Makinesi Temsilcisi";
      }
      case JobFunction.CONSERVATION_SCIENTISTS_AND_FORESTERS: {
        return "Koruma Bilim Adamları ve Ormancılar";
      }
      case JobFunction.CONSTRUCTION_AND_BUILDING_INSPECTORS: {
        return "İnşaat ve bina müfettişleri";
      }
      case JobFunction.CONSTRUCTION_EQUIPMENT_OPERATORS: {
        return "İnşaat Ekipmanları Operatörleri";
      }
      case JobFunction.CONSTRUCTION_LABORERS: {
        return "İnşaat işçileri";
      }
      case JobFunction.CONSTRUCTION_LABORERS_AND_HELPERS: {
        return "İnşaat işçileri ve yardımcıları";
      }
      case JobFunction.CONSTRUCTION_MANAGERS: {
        return "İnşaat Yöneticileri";
      }
      case JobFunction.COOKS: {
        return "Aşçılar";
      }
      case JobFunction.CORRECTIONAL_OFFICERS: {
        return "Düzeltme Görevlileri";
      }
      case JobFunction.CONTRACT_MANAGEMENT: {
        return "Sözleşme yönetimi";
      }
      case JobFunction.CORRECTIONAL_OFFICERS_AND_BAILIFFS: {
        return "Düzeltme görevlileri ve icra memurları";
      }
      case JobFunction.COST_ESTIMATORS: {
        return "Maliyet tahmincileri";
      }
      case JobFunction.COUNSELORS: {
        return "Danışmanlar";
      }
      case JobFunction.COUNTER_AND_RENTAL_CLERKS: {
        return "Sayaç ve kiralama katipleri";
      }
      case JobFunction.COURIERS_AND_MESSENGERS: {
        return "Kuryeler ve haberciler";
      }
      case JobFunction.COURT_REPORTERS: {
        return "Mahkeme muhabirleri";
      }
      case JobFunction.CRAFT_AND_FINE_ARTISTS: {
        return "Zanaat ve güzel sanatçılar";
      }
      case JobFunction.CREDIT_AUTHORIZERS_CHECKERS_AND_CLERKS: {
        return "Kredi yetkilileri, dama ve katipler";
      }
      case JobFunction.CUSTOMER_SERVICE_REPRESENTATIVES: {
        return "müşteri servisi temsilcileri";
      }
      case JobFunction.DANCERS_AND_CHOREOGRAPHERS: {
        return "Dansçılar ve koreograflar";
      }
      case JobFunction.DATA_ENTRY_AND_INFORMATION_PROCESSING_WORKERS: {
        return "Veri girişi ve bilgi işleme çalışanları";
      }
      case JobFunction.DATABASE_ADMINISTRATORS: {
        return "Veritabanı Yöneticileri";
      }
      case JobFunction.DELIVERY_TRUCK_DRIVERS_AND_DRIVERSALES_WORKERS: {
        return "Teslimat kamyonu sürücüleri ve sürücü/satış çalışanları";
      }
      case JobFunction.DEMONSTRATORS_PRODUCT_PROMOTERS_AND_MODELS: {
        return "Göstericiler, ürün destekleyicileri ve modeller";
      }
      case JobFunction.DENTAL_AND_OPHTHALMIC_LABORATORY_TECHNICIANS_AND_MEDICAL_APPLIANCE_TECHNICIANS: {
        return "Diş ve oftalmik laboratuvar teknisyenleri ve m";
      }
      case JobFunction.DENTAL_ASSISTANTS: {
        return "Diş Asistanları";
      }
      case JobFunction.DENTAL_HYGIENISTS: {
        return "Diş hijyenistleri";
      }
      case JobFunction.DENTISTS: {
        return "Diş hekimleri";
      }
      case JobFunction.DESKTOP_PUBLISHERS: {
        return "Masaüstü yayıncıları";
      }
      case JobFunction.DIAGNOSTIC_MEDICAL_SONOGRAPHERS: {
        return "Teşhis Tıbbi Sonografları";
      }
      case JobFunction.DIESEL_SERVICE_TECHNICIANS_AND_MECHANICS: {
        return "Dizel hizmet teknisyenleri ve mekaniği";
      }
      case JobFunction.DIETITIANS_AND_NUTRITIONISTS: {
        return "Diyetisyenler ve beslenme uzmanları";
      }
      case JobFunction.DISPATCHERS: {
        return "Dağıtım görevlileri";
      }
      case JobFunction.DRAFTERS: {
        return "Taslağı";
      }
      case JobFunction.DRYWALL_AND_CEILING_TILE_INSTALLERS_AND_TAPERS: {
        return "Alçıpan ve tavan karo montajcıları ve konikler";
      }
      case JobFunction.DRYWALL_INSTALLERS_CEILING_TILE_INSTALLERS_AND_TAPERS: {
        return "Alçıpan montajcılar, tavan karo montajcıları ve t";
      }
      case JobFunction.ECONOMISTS: {
        return "Ekonomistler";
      }
      case JobFunction.EDITORS: {
        return "Editörler";
      }
      case JobFunction.EDUCATION_ADMINISTRATORS: {
        return "Eğitim yöneticileri";
      }
      case JobFunction.ELECTRICAL_AND_ELECTRONICS_ENGINEERING_TECHNICIANS: {
        return "Elektrik ve Elektronik Mühendisliği Teknisyenleri";
      }
      case JobFunction.ELECTRICAL_AND_ELECTRONICS_ENGINEERS: {
        return "Elektrik ve Elektronik Mühendisler";
      }
      case JobFunction.ELECTRICAL_AND_ELECTRONICS_INSTALLERS_AND_REPAIRERS: {
        return "Elektrik ve elektronik montajcılar ve tamirci";
      }
      case JobFunction.ELECTRICIANS: {
        return "Elektrikçiler";
      }
      case JobFunction.ELECTRO_MECHANICAL_TECHNICIANS: {
        return "Elektro-mekanik teknisyenler";
      }
      case JobFunction.ELECTRONIC_HOME_ENTERTAINMENT_EQUIPMENT_INSTALLERS_AND_REPAIRERS: {
        return "Elektronik Ev Eğlence Ekipmanı Montajcıları";
      }
      case JobFunction.ELEMENTARY_MIDDLE_AND_HIGH_SCHOOL_PRINCIPALS: {
        return "İlköğretim, orta ve lise müdürleri";
      }
      case JobFunction.ELEVATOR_INSTALLERS_AND_REPAIRERS: {
        return "Asansör montajcıları ve tamircileri";
      }
      case JobFunction.EMERGENCY_MANAGEMENT_DIRECTORS: {
        return "Acil durum yönetim müdürleri";
      }
      case JobFunction.EMERGENCY_MEDICAL_TECHNICIANS_AND_PARAMEDICS: {
        return "Acil tıp teknisyenleri ve sağlık görevlileri";
      }
      case JobFunction.EMTS_AND_PARAMEDICS: {
        return "EMT'ler ve Sağlık Görevlileri";
      }
      case JobFunction.ENGINEERING_AND_NATURAL_SCIENCES_MANAGERS: {
        return "Mühendislik ve Doğa Bilimleri Yöneticileri";
      }
      case JobFunction.ENGINEERING_TECHNICIANS: {
        return "Mühendislik Teknisyenleri";
      }
      case JobFunction.ENVIRONMENTAL_ENGINEERING_TECHNICIANS: {
        return "Çevre Mühendisliği Teknisyenleri";
      }
      case JobFunction.ENVIRONMENTAL_ENGINEERS: {
        return "Çevre Mühendisleri";
      }
      case JobFunction.ENVIRONMENTAL_SCIENCE_AND_PROTECTION_TECHNICIANS: {
        return "Çevre Bilimi ve Koruma Teknisyenleri";
      }
      case JobFunction.ENVIRONMENTAL_SCIENTISTS_AND_HYDROLOGISTS: {
        return "Çevre bilimcileri ve hidrologlar";
      }
      case JobFunction.ENVIRONMENTAL_SCIENTISTS_AND_SPECIALISTS: {
        return "Çevre bilimcileri ve uzmanları";
      }
      case JobFunction.EPIDEMIOLOGISTS: {
        return "Epidemiyologlar";
      }
      case JobFunction.EXERCISE_PHYSIOLOGISTS: {
        return "Egzersiz Fizyologları";
      }
      case JobFunction.FARMERS_RANCHERS_AND_AGRICULTURAL_MANAGERS: {
        return "Çiftçiler, çiftlik sahipleri ve tarım yöneticileri";
      }
      case JobFunction.FARMERS_RANCHERS_AND_OTHER_AGRICULTURAL_MANAGERS: {
        return "Çiftçiler, çiftlik sahipleri ve diğer tarım yöneticileri";
      }
      case JobFunction.FASHION_DESIGNERS: {
        return "Moda tasarımcısı";
      }
      case JobFunction.FILE_CLERKS: {
        return "Dosya Katipleri";
      }
      case JobFunction.FILM_AND_VIDEO_EDITORS_AND_CAMERA_OPERATORS: {
        return "Film ve video editörleri ve kamera operatörleri";
      }
      case JobFunction.FINANCIAL_ANALYSTS: {
        return "Finansal Analistler";
      }
      case JobFunction.FINANCIAL_ANALYSTS_AND_PERSONAL_FINANCIAL_ADVISORS: {
        return "Finansal analistler ve kişisel finansal danışmanlar";
      }
      case JobFunction.FINANCIAL_CLERKS: {
        return "Finansal katipler";
      }
      case JobFunction.FINANCIAL_EXAMINERS: {
        return "Finansal Sınavcılar";
      }
      case JobFunction.FINANCIAL_MANAGERS: {
        return "Finansal Yöneticiler";
      }
      case JobFunction.FIRE_FIGHTING_OCCUPATIONS: {
        return "Yangınla mücadele meslekleri";
      }
      case JobFunction.FIRE_INSPECTORS: {
        return "Yangın müfettişleri";
      }
      case JobFunction.FIREFIGHTERS: {
        return "İtfaiyeciler";
      }
      case JobFunction.FISHERS_AND_FISHING_VESSEL_OPERATORS: {
        return "Balıkçılar ve balıkçı gemisi operatörleri";
      }
      case JobFunction.FISHING_AND_HUNTING_WORKERS: {
        return "Balıkçılık ve av işçileri";
      }
      case JobFunction.FITNESS_TRAINERS_AND_INSTRUCTORS: {
        return "Fitness eğitmenleri ve eğitmenler";
      }
      case JobFunction.FITNESS_WORKERS: {
        return "Fitness işçileri";
      }
      case JobFunction.FLIGHT_ATTENDANTS: {
        return "Uçuş görevlileri";
      }
      case JobFunction.FLOORING_INSTALLERS_AND_TILE_AND_MARBLE_SETTERS: {
        return "Döşeme montajcıları ve karo ve mermer ayarlayıcıları";
      }
      case JobFunction.FLORAL_DESIGNERS: {
        return "Çiçek Tasarımcıları";
      }
      case JobFunction.FOOD_AND_BEVERAGE_SERVING_AND_RELATED_WORKERS: {
        return "Yiyecek ve içecek servis ve ilgili çalışanlar";
      }
      case JobFunction.FOOD_AND_TOBACCO_PROCESSING_WORKERS: {
        return "Gıda ve tütün işleme işçileri";
      }
      case JobFunction.FOOD_PROCESSING_OCCUPATIONS: {
        return "Gıda İşleme Meslekleri";
      }
      case JobFunction.FOOD_SERVICE_MANAGERS: {
        return "Yemek servisi yöneticileri";
      }
      case JobFunction.FORENSIC_SCIENCE_TECHNICIANS: {
        return "Adli Bilim Teknisyenleri";
      }
      case JobFunction.FOREST_AND_CONSERVATION_WORKERS: {
        return "Orman ve koruma işçileri";
      }
      case JobFunction.FOREST_CONSERVATION_AND_LOGGING_WORKERS: {
        return "Orman, koruma ve tomrukçuluk işçileri";
      }
      case JobFunction.FUNDRAISERS: {
        return "Bağış toplama";
      }
      case JobFunction.FUNERAL_DIRECTORS: {
        return "Cenaze müdürleri";
      }
      case JobFunction.FUNERAL_SERVICE_WORKERS: {
        return "Cenaze hizmeti çalışanları";
      }
      case JobFunction.GAMING_CAGE_WORKERS: {
        return "Oyun kafesi işçileri";
      }
      case JobFunction.GAMING_SERVICES_OCCUPATIONS: {
        return "Oyun Hizmetleri Meslekleri";
      }
      case JobFunction.GAMING_SERVICES_WORKERS: {
        return "Oyun Hizmetleri Çalışanları";
      }
      case JobFunction.GENERAL_MAINTENANCE_AND_REPAIR_WORKERS: {
        return "Genel bakım ve onarım çalışanları";
      }
      case JobFunction.GENERAL_OFFICE_CLERKS: {
        return "Genel Ofis Katipleri";
      }
      case JobFunction.GENETIC_COUNSELORS: {
        return "Genetik danışmanlar";
      }
      case JobFunction.GEOGRAPHERS: {
        return "Coğrafi kişiler";
      }
      case JobFunction.GEOLOGICAL_AND_PETROLEUM_TECHNICIANS: {
        return "Jeolojik ve Petrol Teknisyenleri";
      }
      case JobFunction.GEOSCIENTISTS: {
        return "Jeobilimciler";
      }
      case JobFunction.GLAZIERS: {
        return "Parıltı";
      }
      case JobFunction.GRAPHIC_DESIGNERS: {
        return "Grafik tasarımcılar";
      }
      case JobFunction.GROUNDS_MAINTENANCE_WORKERS: {
        return "Zemin bakım çalışanları";
      }
      case JobFunction.HAND_LABORERS_AND_MATERIAL_MOVERS: {
        return "El işçileri ve malzeme taşıyıcıları";
      }
      case JobFunction.HAZARDOUS_MATERIALS_REMOVAL_WORKERS: {
        return "Tehlikeli Maddeler Kaldırma Çalışanları";
      }
      case JobFunction.HEALTH_AND_SAFETY_ENGINEERS: {
        return "Sağlık ve Güvenlik Mühendisleri";
      }
      case JobFunction.HEALTH_EDUCATORS: {
        return "Sağlık eğitimcileri";
      }
      case JobFunction.HEALTH_EDUCATORS_AND_COMMUNITY_HEALTH_WORKERS: {
        return "Sağlık eğitimcileri ve toplum sağlığı çalışanları";
      }
      case JobFunction.HEATING_AIR_CONDITIONING_AND_REFRIGERATION_MECHANICS_AND_INSTALLERS: {
        return "Isıtma, Klima ve Soğutma Mecha";
      }
      case JobFunction.HEAVY_AND_TRACTOR_TRAILER_TRUCK_DRIVERS: {
        return "Ağır ve traktör römork kamyon sürücüleri";
      }
      case JobFunction.HEAVY_VEHICLE_AND_MOBILE_EQUIPMENT_SERVICE_TECHNICIANS: {
        return "Ağır araç ve mobil ekipman hizmet tekniği";
      }
      case JobFunction.HEAVY_VEHICLE_AND_MOBILE_EQUIPMENT_SERVICE_TECHNICIANS_AND_MECHANICS: {
        return "Ağır araç ve mobil ekipman hizmet tekniği";
      }
      case JobFunction.HIGH_SCHOOL_TEACHERS: {
        return "Lise öğretmenleri";
      }
      case JobFunction.HISTORIANS: {
        return "Tarihçiler";
      }
      case JobFunction.HOME_APPLIANCE_REPAIRERS: {
        return "Ev Cihaz Tamircileri";
      }
      case JobFunction.HOME_HEALTH_AIDES_AND_PERSONAL_CARE_AIDES: {
        return "Evde Sağlık Yardımları ve Kişisel Bakım Yardımları";
      }
      case JobFunction.HOTEL_MOTEL_AND_RESORT_DESK_CLERKS: {
        return "Otel, motel ve tatil masası katipleri";
      }
      case JobFunction.HUMAN_RESOURCES_ASSISTANTS_EXCEPT_PAYROLL_AND_TIMEKEEPING: {
        return "Bordro ve Tim hariç insan kaynakları asistanları";
      }
      case JobFunction.HUMAN_RESOURCES_MANAGERS: {
        return "İnsan Kaynakları Yöneticileri";
      }
      case JobFunction.HUMAN_RESOURCES_SPECIALISTS: {
        return "İnsan Kaynakları Uzmanları";
      }
      case JobFunction.HUMAN_RESOURCES_TRAINING_AND_LABOR_RELATIONS_MANAGERS_AND_SPECIALISTS: {
        return "İnsan Kaynakları, Eğitim ve Çalışma İlişkileri Adam";
      }
      case JobFunction.HYDROLOGISTS: {
        return "Hidrologlar";
      }
      case JobFunction.INDUSTRIAL_DESIGNERS: {
        return "Endüstriyel tasarımcılar";
      }
      case JobFunction.INDUSTRIAL_ENGINEERING_TECHNICIANS: {
        return "Endüstri Mühendisliği Teknisyenleri";
      }
      case JobFunction.INDUSTRIAL_ENGINEERS: {
        return "Endüstri Mühendisleri";
      }
      case JobFunction.INDUSTRIAL_MACHINERY_MECHANICS_AND_MAINTENANCE_WORKERS: {
        return "Endüstriyel Makine Mekaniği ve Bakım Wor";
      }
      case JobFunction.INDUSTRIAL_PRODUCTION_MANAGERS: {
        return "Endüstriyel Üretim Yöneticileri";
      }
      case JobFunction.INFORMATION_CLERKS: {
        return "Bilgi memurları";
      }
      case JobFunction.INFORMATION_SECURITY_ANALYSTS: {
        return "Bilgi Güvenliği Analistleri";
      }
      case JobFunction.INSPECTORS_TESTERS_SORTERS_SAMPLERS_AND_WEIGHERS: {
        return "Müfettişler, testçiler, sırlayıcılar, örnekleyiciler ve tartma";
      }
      case JobFunction.INSTRUCTIONAL_COORDINATORS: {
        return "Öğretim koordinatörleri";
      }
      case JobFunction.INSULATION_WORKERS: {
        return "Yalıtım İşçileri";
      }
      case JobFunction.INSURANCE_SALES_AGENTS: {
        return "Sigorta Satış Acenteleri";
      }
      case JobFunction.INSURANCE_UNDERWRITERS: {
        return "Sigorta Sigortacıları";
      }
      case JobFunction.INTERIOR_DESIGNERS: {
        return "İç mimarlar";
      }
      case JobFunction.INTERPRETERS_AND_TRANSLATORS: {
        return "Tercümanlar ve çevirmenler";
      }
      case JobFunction.IRONWORKERS: {
        return "Demir işçileri";
      }
      case JobFunction.JEWELERS_AND_PRECIOUS_STONE_AND_METAL_WORKERS: {
        return "Kuyumcular ve değerli taş ve metal işçiler";
      }
      case JobFunction.JOB_OPPORTUNITIES_IN_THE_ARMED_FORCES: {
        return "Silahlı Kuvvetlerdeki İş Fırsatları";
      }
      case JobFunction.JUDGES_AND_HEARING_OFFICERS: {
        return "Hakimler ve duruşma görevlileri";
      }
      case JobFunction.JUDGES_MAGISTRATES_AND_OTHER_JUDICIAL_WORKERS: {
        return "Hakimler, sulh hakimleri ve diğer yargı işçileri";
      }
      case JobFunction.KINDERGARTEN_AND_ELEMENTARY_SCHOOL_TEACHERS: {
        return "Anaokulu ve ilkokul öğretmenleri";
      }
      case JobFunction.LABOR_RELATIONS_SPECIALISTS: {
        return "Çalışma İlişkileri Uzmanları";
      }
      case JobFunction.LANDSCAPE_ARCHITECTS: {
        return "Peyzaj mimarları";
      }
      case JobFunction.LAWYERS: {
        return "Avukat";
      }
      case JobFunction.LIBRARIANS: {
        return "Kütüphaneciler";
      }
      case JobFunction.LIBRARY_TECHNICIANS_AND_ASSISTANTS: {
        return "Kütüphane Teknisyenleri ve Asistanlar";
      }
      case JobFunction.LICENSED_PRACTICAL_AND_LICENSED_VOCATIONAL_NURSES: {
        return "Lisanslı pratik ve lisanslı mesleki hemşireler";
      }
      case JobFunction.LINE_INSTALLERS_AND_REPAIRERS: {
        return "Hat montajcıları ve tamircileri";
      }
      case JobFunction.LOAN_OFFICERS: {
        return "Kredi memurları";
      }
      case JobFunction.LODGING_MANAGERS: {
        return "Konaklama Yöneticileri";
      }
      case JobFunction.LOGGING_WORKERS: {
        return "Kayıt İşçileri";
      }
      case JobFunction.LOGISTICIANS: {
        return "Lojistikçiler";
      }
      case JobFunction.MACHINE_SETTERS_OPERATORS_AND_TENDERS_METAL_AND_PLASTIC: {
        return "Makine ayarlayıcıları, operatörler ve ihaleler-metal ve";
      }
      case JobFunction.MACHINISTS: {
        return "Makinistler";
      }
      case JobFunction.MACHINISTS_AND_TOOL_AND_DIE_MAKERS: {
        return "Makinistler ve Araç ve Ölüm Makineleri";
      }
      case JobFunction.MAINTENANCE_AND_REPAIR_WORKERS_GENERAL: {
        return "Bakım ve onarım çalışanları, genel";
      }
      case JobFunction.MANAGEMENT_ANALYSTS: {
        return "Yönetim Analistleri";
      }
      case JobFunction.MANICURISTS_AND_PEDICURISTS: {
        return "Manikürciler ve pedicuristler";
      }
      case JobFunction.MARINE_ENGINEERS_AND_NAVAL_ARCHITECTS: {
        return "Deniz Mühendisleri ve Deniz Mimarları";
      }
      case JobFunction.MARKET_AND_SURVEY_RESEARCHERS: {
        return "Pazar ve anket araştırmacıları";
      }
      case JobFunction.MARKET_RESEARCH_ANALYSTS: {
        return "Pazar Araştırma Analistleri";
      }
      case JobFunction.MARRIAGE_AND_FAMILY_THERAPISTS: {
        return "Evlilik ve Aile Terapistleri";
      }
      case JobFunction.MASSAGE_THERAPISTS: {
        return "Masaj terapistleri";
      }
      case JobFunction.MATERIAL_MOVING_MACHINE_OPERATORS: {
        return "Malzeme Hareketli Makine Operatörleri";
      }
      case JobFunction.MATERIAL_MOVING_OCCUPATIONS: {
        return "Malzeme Hareketli Meslekler";
      }
      case JobFunction.MATERIAL_RECORDING_CLERKS: {
        return "Malzeme Kayıt Memurları";
      }
      case JobFunction.MATERIALS_ENGINEERS: {
        return "Malzeme Mühendisleri";
      }
      case JobFunction.MATHEMATICIANS: {
        return "Matematikçiler";
      }
      case JobFunction.MATHEMATICIANS_AND_STATISTICIANS: {
        return "Matematikçiler ve istatistikçiler";
      }
      case JobFunction.MECHANICAL_ENGINEERING_TECHNICIANS: {
        return "Makine Mühendisliği Teknisyenleri";
      }
      case JobFunction.MECHANICAL_ENGINEERS: {
        return "Makine Mühendisleri";
      }
      case JobFunction.MEDICAL_AND_CLINICAL_LABORATORY_TECHNOLOGISTS_AND_TECHNICIANS: {
        return "Tıbbi ve klinik laboratuvar teknoloji uzmanları ve";
      }
      case JobFunction.MEDICAL_AND_HEALTH_SERVICES_MANAGERS: {
        return "Tıbbi ve Sağlık Hizmetleri Yöneticileri";
      }
      case JobFunction.MEDICAL_ASSISTANTS: {
        return "Tıbbi Asistanlar";
      }
      case JobFunction.MEDICAL_EQUIPMENT_REPAIRERS: {
        return "Tıbbi Ekipman Tamircileri";
      }
      case JobFunction.MEDICAL_RECORDS_AND_HEALTH_INFORMATION_TECHNICIANS: {
        return "Tıbbi Kayıtlar ve Sağlık Bilgi Teknisyenleri";
      }
      case JobFunction.MEDICAL_SCIENTISTS: {
        return "Tıp bilimcileri";
      }
      case JobFunction.MEDICAL_TRANSCRIPTIONISTS: {
        return "Tıbbi transkripsiyoncular";
      }
      case JobFunction.MEDICAL_DENTAL_AND_OPHTHALMIC_LABORATORY_TECHNICIANS: {
        return "Tıbbi, diş ve oftalmik laboratuvar tekniği";
      }
      case JobFunction.MEETING_AND_CONVENTION_PLANNERS: {
        return "Toplantı ve kongre planlayıcıları";
      }
      case JobFunction.MEETING_CONVENTION_AND_EVENT_PLANNERS: {
        return "Toplantı, kongre ve etkinlik planlayıcıları";
      }
      case JobFunction.METAL_AND_PLASTIC_MACHINE_WORKERS: {
        return "Metal ve plastik makine çalışanları";
      }
      case JobFunction.MICROBIOLOGISTS: {
        return "Mikrobiyologlar";
      }
      case JobFunction.MIDDLE_SCHOOL_TEACHERS: {
        return "Ortaokul öğretmenleri";
      }
      case JobFunction.MILITARY_CAREERS: {
        return "Askeri kariyer";
      }
      case JobFunction.MILLWRIGHTS: {
        return "Değirmenler";
      }
      case JobFunction.MINING_AND_GEOLOGICAL_ENGINEERS: {
        return "Madencilik ve Jeoloji Mühendisleri";
      }
      case JobFunction.MODELS: {
        return "Modeller";
      }
      case JobFunction.METER_READERS_UTILITIES: {
        return "Sayaç okuyucuları, yardımcı programlar";
      }
      case JobFunction.MULTIMEDIA_ARTISTS_AND_ANIMATORS: {
        return "Multimedya sanatçıları ve animatörler";
      }
      case JobFunction.MUSIC_DIRECTORS_AND_COMPOSERS: {
        return "Müzik yönetmenleri ve besteciler";
      }
      case JobFunction.MUSICIANS_AND_SINGERS: {
        return "Müzisyenler ve şarkıcılar";
      }
      case JobFunction.MUSICIANS_SINGERS_AND_RELATED_WORKERS: {
        return "Müzisyenler, şarkıcılar ve ilgili işçiler";
      }
      case JobFunction.NATURAL_SCIENCES_MANAGERS: {
        return "Doğa Bilimleri Yöneticileri";
      }
      case JobFunction.NETWORK_AND_COMPUTER_SYSTEMS_ADMINISTRATORS: {
        return "Ağ ve Bilgisayar Sistemleri Yöneticileri";
      }
      case JobFunction.NEWS_ANALYSTS_REPORTERS_AND_CORRESPONDENTS: {
        return "Haber analistleri, muhabirler ve muhabirler";
      }
      case JobFunction.NUCLEAR_ENGINEERS: {
        return "Nükleer mühendisler";
      }
      case JobFunction.NUCLEAR_MEDICINE_TECHNOLOGISTS: {
        return "Nükleer Tıp Teknologları";
      }
      case JobFunction.NUCLEAR_TECHNICIANS: {
        return "Nükleer teknisyenler";
      }
      case JobFunction.NURSE_ANESTHETISTS_NURSE_MIDWIVES_AND_NURSE_PRACTITIONERS: {
        return "Hemşire anestezistleri, hemşire ebeleri ve hemşire PRAC";
      }
      case JobFunction.NURSING_ASSISTANTS_AND_ORDERLIES: {
        return "Hemşirelik Asistanları ve Düzenleri";
      }
      case JobFunction.NURSING_PSYCHIATRIC_AND_HOME_HEALTH_AIDES: {
        return "Hemşirelik, Psikiyatrik ve Evde Sağlık Yardımları";
      }
      case JobFunction.OCCUPATIONAL_HEALTH_AND_SAFETY_SPECIALISTS_AND_TECHNICIANS: {
        return "İş Sağlığı ve Güvenlik Uzmanları ve TEC";
      }
      case JobFunction.OCCUPATIONAL_THERAPIST_ASSISTANTS_AND_AIDES: {
        return "Mesleki terapist asistanlar ve yardımcılar";
      }
      case JobFunction.OCCUPATIONAL_THERAPISTS: {
        return "Mesleki terapistler";
      }
      case JobFunction.OCCUPATIONAL_THERAPY_ASSISTANTS_AND_AIDES: {
        return "Mesleki terapi asistanları ve yardımcıları";
      }
      case JobFunction.OFFICE_AND_ADMINISTRATIVE_SUPPORT_WORKER_SUPERVISORS_AND_MANAGERS: {
        return "Ofis ve İdari Destek Çalışanı Superviso";
      }
      case JobFunction.OFFICE_CLERKS_GENERAL: {
        return "Ofis katipleri, genel";
      }
      case JobFunction.OPERATIONS_RESEARCH_ANALYSTS: {
        return "Operasyon Araştırma Analistleri";
      }
      case JobFunction.OPTICIANS: {
        return "Opçular";
      }
      case JobFunction.OPTICIANS_DISPENSING: {
        return "OVICICIELS, Dağıtım";
      }
      case JobFunction.OPTOMETRISTS: {
        return "Optometristler";
      }
      case JobFunction.ORDER_CLERKS: {
        return "Sipariş Katipleri";
      }
      case JobFunction.ORTHOTISTS_AND_PROSTHETISTS: {
        return "Ortotistler ve protezistler";
      }
      case JobFunction.PAINTERS_AND_PAPERHANGERS: {
        return "Ressamlar ve kağıttançılar";
      }
      case JobFunction.PAINTERS_CONSTRUCTION_AND_MAINTENANCE: {
        return "Ressamlar, inşaat ve bakım";
      }
      case JobFunction.PAINTING_AND_COATING_WORKERS: {
        return "Boyama ve kaplama işçileri";
      }
      case JobFunction.PAINTING_AND_COATING_WORKERS_EXCEPT_CONSTRUCTION_AND_MAINTENANCE: {
        return "İnşaat hariç resim ve kaplama işçileri";
      }
      case JobFunction.PARALEGALS_AND_LEGAL_ASSISTANTS: {
        return "Yasadışı ve hukuk asistanları";
      }
      case JobFunction.PAYROLL_AND_TIMEKEEPING_CLERKS: {
        return "Bordro ve zaman tutma katipleri";
      }
      case JobFunction.PERSONAL_AND_HOME_CARE_AIDES: {
        return "Kişisel ve evde bakım yardımcıları";
      }
      case JobFunction.PERSONAL_FINANCIAL_ADVISORS: {
        return "Kişisel Mali Danışmanlar";
      }
      case JobFunction.PEST_CONTROL_WORKERS: {
        return "Haşere Kontrol İşçileri";
      }
      case JobFunction.PETROLEUM_ENGINEERS: {
        return "Petrol mühendisleri";
      }
      case JobFunction.PHARMACISTS: {
        return "Eczacılar";
      }
      case JobFunction.PHARMACY_AIDES: {
        return "Eczane Yardımları";
      }
      case JobFunction.PHARMACY_TECHNICIANS: {
        return "Eczane teknisyenleri";
      }
      case JobFunction.PHLEBOTOMISTS: {
        return "Flebotomistler";
      }
      case JobFunction.PHOTOGRAPHERS: {
        return "Fotoğrafçılar";
      }
      case JobFunction.PHYSICAL_THERAPIST_ASSISTANTS_AND_AIDES: {
        return "Fizyoterapist asistanlar ve yardımcılar";
      }
      case JobFunction.PHYSICAL_THERAPISTS: {
        return "Fiziksel terapistler";
      }
      case JobFunction.PHYSICIAN_ASSISTANTS: {
        return "Doktor asistanları";
      }
      case JobFunction.PHYSICIANS_AND_SURGEONS: {
        return "Doktorlar ve cerrahlar";
      }
      case JobFunction.PHYSICISTS_AND_ASTRONOMERS: {
        return "Fizikçiler ve gökbilimciler";
      }
      case JobFunction.PIPELAYERS_PLUMBERS_PIPEFITTERS_AND_STEAMFITTERS: {
        return "Boru hizmetleri, tesisatçılar, pipefitters ve buharlaştırıcı";
      }
      case JobFunction.PHOTOGRAPHIC_PROCESS_WORKERS_AND_PROCESSING_MACHINE_OPERATORS: {
        return "Fotoğrafik süreç çalışanları ve işleme makineleri";
      }
      case JobFunction.PLUMBERS_PIPEFITTERS_AND_STEAMFITTERS: {
        return "Tesisatçılar, Pipefitters ve Steamfitters";
      }
      case JobFunction.PODIATRISTS: {
        return "Podiatristler";
      }
      case JobFunction.POLICE_AND_DETECTIVES: {
        return "Polis ve dedektifler";
      }
      case JobFunction.POLICE_FIRE_AND_AMBULANCE_DISPATCHERS: {
        return "Polis, yangın ve ambulans görevlileri";
      }
      case JobFunction.POLITICAL_SCIENTISTS: {
        return "Siyaset bilimcileri";
      }
      case JobFunction.POSTAL_SERVICE_WORKERS: {
        return "Posta Servis Çalışanları";
      }
      case JobFunction.POSTSECONDARY_EDUCATION_ADMINISTRATORS: {
        return "Ortaöğretim sonrası eğitim yöneticileri";
      }
      case JobFunction.POSTSECONDARY_TEACHERS: {
        return "Ortaöğretim sonrası öğretmenler";
      }
      case JobFunction.PLASTERERS_AND_STUCCO_MASONS: {
        return "Sıva ve sıva masonları";
      }
      case JobFunction.POWER_PLANT_OPERATORS_DISTRIBUTORS_AND_DISPATCHERS: {
        return "Enerji santrali operatörleri, distribütörleri ve dispatche";
      }
      case JobFunction.PRESCHOOL_AND_CHILDCARE_CENTER_DIRECTORS: {
        return "Okul Öncesi ve Çocuk Bakım Merkezi Direktörleri";
      }
      case JobFunction.PRESCHOOL_TEACHERS: {
        return "Okul öncesi öğretmenler";
      }
      case JobFunction.PRIVATE_DETECTIVES_AND_INVESTIGATORS: {
        return "Özel dedektifler ve araştırmacılar";
      }
      case JobFunction.PROBATION_OFFICERS_AND_CORRECTIONAL_TREATMENT_SPECIALISTS: {
        return "Denetimli Serbestlik Görevlileri ve Düzeltme Tedavi Özelliği";
      }
      case JobFunction.PROCUREMENT_CLERKS: {
        return "Tedarik memurları";
      }
      case JobFunction.PROCUREMENT_MANAGER: {
        return "Satın Alma Müdürü";
      }
      case JobFunction.PRODUCERS_AND_DIRECTORS: {
        return "Üreticiler ve yönetmenler";
      }
      case JobFunction.PRODUCTION_PLANNING_AND_EXPEDITING_CLERKS: {
        return "Üretim, planlama ve hızlandırıcı katip";
      }
      case JobFunction.PROPERTY_REAL_ESTATE_AND_COMMUNITY_ASSOCIATION_MANAGERS: {
        return "Mülkiyet, gayrimenkul ve topluluk derneği m";
      }
      case JobFunction.PRECISION_INSTRUMENT_AND_EQUIPMENT_REPAIRERS: {
        return "Hassas enstrüman ve ekipman tamircileri";
      }
      case JobFunction.PSYCHIATRIC_TECHNICIANS_AND_AIDES: {
        return "Psikiyatri Teknisyenleri ve Yardımları";
      }
      case JobFunction.PREPRESS_TECHNICIANS_AND_WORKERS: {
        return "Ön hazırlık teknisyenleri ve işçiler";
      }
      case JobFunction.PSYCHOLOGISTS: {
        return "Psikologlar";
      }
      case JobFunction.PUBLIC_RELATIONS_AND_FUNDRAISING_MANAGERS: {
        return "Halkla İlişkiler ve Bağış Toplama Yöneticileri";
      }
      case JobFunction.PUBLIC_RELATIONS_SPECIALISTS: {
        return "Halkla İlişkiler Uzmanları";
      }
      case JobFunction.PURCHASING_MANAGERS_BUYERS_AND_PURCHASING_AGENTS: {
        return "Satın alma yöneticileri, alıcılar ve satın alma acenteleri";
      }
      case JobFunction.PRINTING_MACHINE_OPERATORS: {
        return "Baskı makinesi operatörleri";
      }
      case JobFunction.QUALITY_CONTROL_INSPECTORS: {
        return "Kalite Kontrol Müfettişleri";
      }
      case JobFunction.RADIATION_THERAPISTS: {
        return "Radyasyon terapistleri";
      }
      case JobFunction.RADIO_AND_TELECOMMUNICATIONS_EQUIPMENT_INSTALLERS_AND_REPAIRERS: {
        return "Radyo ve Telekomünikasyon Ekipman Montajcıları";
      }
      case JobFunction.RADIOLOGIC_AND_MRI_TECHNOLOGISTS: {
        return "Radyolojik ve MRI teknoloji uzmanları";
      }
      case JobFunction.RADIOLOGIC_TECHNOLOGISTS_AND_TECHNICIANS: {
        return "Radyolojik teknoloji uzmanları ve teknisyenler";
      }
      case JobFunction.RAIL_TRANSPORTATION_OCCUPATIONS: {
        return "Demiryolu taşımacılığı meslekleri";
      }
      case JobFunction.RAILROAD_WORKERS: {
        return "Demiryolu işçileri";
      }
      case JobFunction.REAL_ESTATE_BROKERS_AND_SALES_AGENTS: {
        return "Emlak komisyoncuları ve satış acenteleri";
      }
      case JobFunction.RECEPTIONISTS: {
        return "Resepsiyonistler";
      }
      case JobFunction.RECEPTIONISTS_AND_INFORMATION_CLERKS: {
        return "Resepsiyonistler ve bilgi memurları";
      }
      case JobFunction.RECREATION_WORKERS: {
        return "Rekreasyon İşçileri";
      }
      case JobFunction.RECREATIONAL_THERAPISTS: {
        return "Eğlence terapistleri";
      }
      case JobFunction.REGISTERED_NURSES: {
        return "Kayıtlı Hemşireler";
      }
      case JobFunction.REHABILITATION_COUNSELORS: {
        return "Rehabilitasyon danışmanları";
      }
      case JobFunction.REPORTERS_CORRESPONDENTS_AND_BROADCAST_NEWS_ANALYSTS: {
        return "Muhabirler, Muhabirler ve Yayın Haberleri Anal";
      }
      case JobFunction.RESERVATION_AND_TRANSPORTATION_TICKET_AGENTS_AND_TRAVEL_CLERKS: {
        return "Rezervasyon ve Ulaşım Bilet Acenteleri ve T";
      }
      case JobFunction.RESPIRATORY_THERAPISTS: {
        return "Solunum terapistleri";
      }
      case JobFunction.RETAIL_SALES_WORKERS: {
        return "Perakende Satış Çalışanları";
      }
      case JobFunction.RETAIL_SALESPERSONS: {
        return "Perakende satış görevlileri";
      }
      case JobFunction.ROOFERS: {
        return "Çatıcular";
      }
      case JobFunction.SALES_ENGINEERS: {
        return "Satış mühendisleri";
      }
      case JobFunction.SALES_MANAGERS: {
        return "Satış müdürleri";
      }
      case JobFunction.SALES_REPRESENTATIVES_WHOLESALE_AND_MANUFACTURING: {
        return "Satış Temsilcileri, Toptan ve Üretim";
      }
      case JobFunction.SCHOOL_AND_CAREER_COUNSELORS: {
        return "Okul ve Kariyer Danışmanları";
      }
      case JobFunction.SECRETARIES_AND_ADMINISTRATIVE_ASSISTANTS: {
        return "Sekreterler ve İdari Asistanlar";
      }
      case JobFunction.SECURITIES_COMMODITIES_AND_FINANCIAL_SERVICES_SALES_AGENTS: {
        return "Menkul Kıymetler, Emtialar ve Finansal Hizmetler SA";
      }
      case JobFunction.SECURITY_GUARDS_AND_GAMING_SURVEILLANCE_OFFICERS: {
        return "Güvenlik görevlileri ve oyun gözetim memurları";
      }
      case JobFunction.SHEET_METAL_WORKERS: {
        return "Sac Metal İşçileri";
      }
      case JobFunction.SHIPPING_RECEIVING_AND_TRAFFIC_CLERKS: {
        return "Nakliye, alıcı ve trafik memurları";
      }
      case JobFunction.SKINCARE_SPECIALISTS: {
        return "Cilt Bakımı Uzmanları";
      }
      case JobFunction.SMALL_ENGINE_MECHANICS: {
        return "Küçük Motor Mekaniği";
      }
      case JobFunction.SOCIAL_AND_COMMUNITY_SERVICE_MANAGERS: {
        return "Sosyal ve toplum hizmeti yöneticileri";
      }
      case JobFunction.SOCIAL_AND_HUMAN_SERVICE_ASSISTANTS: {
        return "Sosyal ve İnsan Hizmet Asistanları";
      }
      case JobFunction.SOCIAL_SCIENTISTS_OTHER: {
        return "Sosyal bilimciler, diğer";
      }
      case JobFunction.SOCIAL_WORKERS: {
        return "Sosyal çalışanlar";
      }
      case JobFunction.SALES_WORKER_SUPERVISORS: {
        return "Satış Çalışanı Süpervizörleri";
      }
      case JobFunction.SOCIOLOGISTS: {
        return "Sosyologlar";
      }
      case JobFunction.SOFTWARE_DEVELOPERS: {
        return "Yazılım geliştiricileri";
      }
      case JobFunction.SCIENCE_TECHNICIANS: {
        return "Bilim Teknisyenleri";
      }
      case JobFunction.SOLAR_PHOTOVOLTAIC_INSTALLERS: {
        return "Güneş fotovoltaik montajcılar";
      }
      case JobFunction.SPECIAL_EDUCATION_TEACHERS: {
        return "Özel Eğitim Öğretmenleri";
      }
      case JobFunction.SPEECH_LANGUAGE_PATHOLOGISTS: {
        return "Konuşma dili patologları";
      }
      case JobFunction.STATIONARY_ENGINEERS_AND_BOILER_OPERATORS: {
        return "Sabit mühendisler ve kazan operatörleri";
      }
      case JobFunction.SEMICONDUCTOR_PROCESSORS: {
        return "Yarı iletken işlemciler";
      }
      case JobFunction.STATISTICIANS: {
        return "İstatistikçiler";
      }
      case JobFunction.STOCK_CLERKS_AND_ORDER_FILLERS: {
        return "Stok katipleri ve sipariş dolgu maddeleri";
      }
      case JobFunction.STRUCTURAL_AND_REINFORCING_IRON_AND_METAL_WORKERS: {
        return "Yapısal ve güçlendirici demir ve metal işçiler";
      }
      case JobFunction.SUBSTANCE_ABUSE_BEHAVIORAL_DISORDER_AND_MENTAL_HEALTH_COUNSELORS: {
        return "Madde bağımlılığı, davranış bozukluğu ve zihinsel h";
      }
      case JobFunction.SUPPLY_MANAGEMENT: {
        return "Tedarik yönetimi";
      }
      case JobFunction.SURGICAL_TECHNOLOGISTS: {
        return "Cerrahi Teknologlar";
      }
      case JobFunction.SURVEY_RESEARCHERS: {
        return "Anket araştırmacıları";
      }
      case JobFunction.SURVEYING_AND_MAPPING_TECHNICIANS: {
        return "Araştırma ve haritalama teknisyenleri";
      }
      case JobFunction.SURVEYORS: {
        return "Sörveyörler";
      }
      case JobFunction.SURVEYORS_CARTOGRAPHERS_PHOTOGRAMMETRISTS_AND_SURVEYING_AND_MAPPING_TECHNICIANS: {
        return "Surveyörler, haritacılar, fotogrametristler ve s";
      }
      case JobFunction.TAX_EXAMINERS_AND_COLLECTORS_AND_REVENUE_AGENTS: {
        return "Vergi Sınavları ve Koleksiyonerler ve Gelir Temsilcileri";
      }
      case JobFunction.TAX_EXAMINERS_COLLECTORS_AND_REVENUE_AGENTS: {
        return "Vergi Sınavları, Koleksiyonerler ve Gelir Temsilcileri";
      }
      case JobFunction.TAXI_DRIVERS_AND_CHAUFFEURS: {
        return "Taksi şoförleri ve şoförler";
      }
      case JobFunction.TAXI_DRIVERS_RIDE_HAILING_DRIVERS_AND_CHAUFFEURS: {
        return "Taksi şoförleri, sürüş selamlama sürücüleri ve şoförler";
      }
      case JobFunction.TEACHER_ASSISTANTS: {
        return "Öğretmen Asistanları";
      }
      case JobFunction.TEACHERS_ADULT_LITERACY_AND_REMEDIAL_EDUCATION: {
        return "Öğretmen-yetişkin okuryazarlığı ve iyileştirici eğitim";
      }
      case JobFunction.TEACHERS_POSTSECONDARY: {
        return "Öğretmenler-Post";
      }
      case JobFunction.TEACHERS_PRESCHOOL_KINDERGARTEN_ELEMENTARY_MIDDLE_AND_SECONDARY: {
        return "Öğretmenler-Okul, Anaokulu, İlköğretim, Midd";
      }
      case JobFunction.TEACHERS_SPECIAL_EDUCATION: {
        return "Öğretmenlere özgü eğitim";
      }
      case JobFunction.TECHNICAL_WRITERS: {
        return "Teknik yazarlar";
      }
      case JobFunction.TELECOMMUNICATIONS_EQUIPMENT_INSTALLERS_AND_REPAIRERS: {
        return "Telekomünikasyon Ekipmanı montajcıları ve onarımı";
      }
      case JobFunction.TELEVISION_VIDEO_AND_MOTION_PICTURE_CAMERA_OPERATORS_AND_EDITORS: {
        return "Televizyon, video ve sinema filmi kamera operası";
      }
      case JobFunction.TELLERS: {
        return "Sözlü";
      }
      case JobFunction.TOOL_AND_DIE_MAKERS: {
        return "Araç ve Ölüm Yapıcıları";
      }
      case JobFunction.TOP_EXECUTIVES: {
        return "Üst düzey yöneticiler";
      }
      case JobFunction.TRAINING_AND_DEVELOPMENT_MANAGERS: {
        return "Eğitim ve Gelişim Yöneticileri";
      }
      case JobFunction.TRAINING_AND_DEVELOPMENT_SPECIALISTS: {
        return "Eğitim ve Gelişim Uzmanları";
      }
      case JobFunction.TRAVEL_AGENTS: {
        return "Seyahat acenteleri";
      }
      case JobFunction.TRUCK_DRIVERS_AND_DRIVERSALES_WORKERS: {
        return "Kamyon sürücüleri ve sürücü/satış çalışanları";
      }
      case JobFunction.UMPIRES_REFEREES_AND_OTHER_SPORTS_OFFICIALS: {
        return "Hakemler, hakemler ve diğer spor yetkilileri";
      }
      case JobFunction.URBAN_AND_REGIONAL_PLANNERS: {
        return "Kentsel ve bölgesel planlamacılar";
      }
      case JobFunction.VETERINARIANS: {
        return "Veteriner";
      }
      case JobFunction.TEACHERS_SELF_ENRICHMENT_EDUCATION: {
        return "Öğretmenler-Kendinden Zenginlik Eğitimi";
      }
      case JobFunction.VETERINARY_ASSISTANTS_AND_LABORATORY_ANIMAL_CARETAKERS: {
        return "Veteriner Asistanlar ve Laboratuvar Hayvan Careta";
      }
      case JobFunction.VETERINARY_TECHNOLOGISTS_AND_TECHNICIANS: {
        return "Veteriner teknoloji uzmanları ve teknisyenler";
      }
      case JobFunction.WAITERS_AND_WAITRESSES: {
        return "Garsonlar ve garsonlar";
      }
      case JobFunction.WATER_AND_LIQUID_WASTE_TREATMENT_PLANT_AND_SYSTEM_OPERATORS: {
        return "Su ve Sıvı Atık Arıtma Tesisi ve Sistemi";
      }
      case JobFunction.WATER_AND_WASTEWATER_TREATMENT_PLANT_AND_SYSTEM_OPERATORS: {
        return "Su ve Atıksu Arıtma Tesisi ve Sistem OP";
      }
      case JobFunction.WATER_TRANSPORTATION_OCCUPATIONS: {
        return "Su Taşımacılığı Meslekleri";
      }
      case JobFunction.TEXTILE_APPAREL_AND_FURNISHINGS_OCCUPATIONS: {
        return "Tekstil, giyim ve mobilyalar meslekleri";
      }
      case JobFunction.WATER_TRANSPORTATION_WORKERS: {
        return "Su Taşımacılığı İşçileri";
      }
      case JobFunction.WEB_DEVELOPERS: {
        return "Web geliştiricileri";
      }
      case JobFunction.WEIGHERS_MEASURERS_CHECKERS_AND_SAMPLERS_RECORDKEEPING: {
        return "Tartıcılar, Ölçümler, Dama ve Örnekleyiciler, RECOR";
      }
      case JobFunction.WELDERS_CUTTERS_SOLDERERS_AND_BRAZERS: {
        return "Kaynakçılar, kesiciler, lehimler ve Brazers";
      }
      case JobFunction.WELDING_SOLDERING_AND_BRAZING_WORKERS: {
        return "Kaynak, lehimleme ve lehimleme işçileri";
      }
      case JobFunction.WHOLESALE_AND_MANUFACTURING_SALES_REPRESENTATIVES: {
        return "Toptan ve Üretim Satış Temsilcileri";
      }
      case JobFunction.WIND_TURBINE_TECHNICIANS: {
        return "Rüzgar türbini teknisyenleri";
      }
      case JobFunction.WOODWORKERS: {
        return "Ahşap işçileri";
      }
      case JobFunction.WRITERS_AND_AUTHORS: {
        return "Yazarlar ve yazarlar";
      }
      case JobFunction.WRITERS_AND_EDITORS: {
        return "Yazarlar ve editörler";
      }
      case JobFunction.ZOOLOGISTS_AND_WILDLIFE_BIOLOGISTS: {
        return "Zoologlar ve vahşi yaşam biyologları";
      }
      case JobFunction.OTHER: {
        return "Diğer";
      }
    }
    return "";
  }

  private static getJobFunctionTitleInSpanish(
    jobFunction: JobFunction,
  ): string {
    switch (jobFunction) {
      case JobFunction.ACCOUNTANTS_AND_AUDITORS: {
        return "Contadores y auditores";
      }
      case JobFunction.ACTORS_PRODUCERS_AND_DIRECTORS: {
        return "Actores, productores y directores";
      }
      case JobFunction.ACTUARIES: {
        return "Actuarios";
      }
      case JobFunction.ADMINISTRATIVE_SERVICES_MANAGERS: {
        return "Gerentes de servicios administrativos";
      }
      case JobFunction.ADULT_LITERACY_AND_HIGH_SCHOOL_EQUIVALENCY_DIPLOMA_TEACHERS: {
        return "Alfabetización de adultos y diploma de equivalencia de la escuela secundaria";
      }
      case JobFunction.ADVERTISING_SALES_AGENTS: {
        return "Agentes de ventas publicitarias";
      }
      case JobFunction.ADVERTISING_MARKETING_PROMOTIONS_PUBLIC_RELATIONS_AND_SALES_MANAGERS: {
        return "Publicidad, marketing, promociones, relatio público";
      }
      case JobFunction.AEROSPACE_ENGINEERING_AND_OPERATIONS_TECHNICIANS: {
        return "Técnicos de ingeniería y operaciones aeroespaciales";
      }
      case JobFunction.AEROSPACE_ENGINEERS: {
        return "Ingenieros aeroespaciales";
      }
      case JobFunction.AGRICULTURAL_AND_FOOD_SCIENCE_TECHNICIANS: {
        return "Técnicos de ciencias agrícolas y de alimentos";
      }
      case JobFunction.AGRICULTURAL_AND_FOOD_SCIENTISTS: {
        return "Científicos agrícolas y de alimentos";
      }
      case JobFunction.AGRICULTURAL_ENGINEERS: {
        return "Ingenieros agrícolas";
      }
      case JobFunction.AGRICULTURAL_WORKERS: {
        return "Trabajadores agrícolas";
      }
      case JobFunction.AIR_TRAFFIC_CONTROLLERS: {
        return "Controladores de tráfico aéreo";
      }
      case JobFunction.AIRCRAFT_AND_AVIONICS_EQUIPMENT_MECHANICS_AND_SERVICE_TECHNICIANS: {
        return "Mecánica de equipos de aeronaves y aviónica y servicio";
      }
      case JobFunction.AIRCRAFT_PILOTS_AND_FLIGHT_ENGINEERS: {
        return "Pilotos de aviones e ingenieros de vuelo";
      }
      case JobFunction.ANIMAL_CARE_AND_SERVICE_WORKERS: {
        return "Trabajadores del cuidado y servicio de animales";
      }
      case JobFunction.ANNOUNCERS: {
        return "Locutores";
      }
      case JobFunction.ANTHROPOLOGISTS_AND_ARCHEOLOGISTS: {
        return "Antropólogos y arqueólogos";
      }
      case JobFunction.APPRAISERS_AND_ASSESSORS_OF_REAL_ESTATE: {
        return "Evaluadores y evaluadores de bienes raíces";
      }
      case JobFunction.ARBITRATORS_MEDIATORS_AND_CONCILIATORS: {
        return "Árbitros, mediadores y conciliadores";
      }
      case JobFunction.ARCHITECTS_EXCEPT_LANDSCAPE_AND_NAVAL: {
        return "Arquitectos, excepto paisaje y naval";
      }
      case JobFunction.ARCHITECTURAL_AND_ENGINEERING_MANAGERS: {
        return "Gerentes de arquitectura e ingeniería";
      }
      case JobFunction.ARCHIVISTS_CURATORS_AND_MUSEUM_TECHNICIANS: {
        return "Archiveros, curadores y técnicos de museo";
      }
      case JobFunction.ART_DIRECTORS: {
        return "Directores de arte";
      }
      case JobFunction.ARTISTS_AND_RELATED_WORKERS: {
        return "Artistas y trabajadores relacionados";
      }
      case JobFunction.ASSEMBLERS_AND_FABRICATORS: {
        return "Ensambladores y fabricantes";
      }
      case JobFunction.ATHLETES_COACHES_UMPIRES_AND_RELATED_WORKERS: {
        return "Atletas, entrenadores, árbitros y trabajadores relacionados";
      }
      case JobFunction.ATHLETIC_TRAINERS: {
        return "Entrenadores de atletismo";
      }
      case JobFunction.ATMOSPHERIC_SCIENTISTS: {
        return "Científicos atmosféricos";
      }
      case JobFunction.AUDIOLOGISTS: {
        return "Audiólogos";
      }
      case JobFunction.AUTOMOTIVE_BODY_AND_RELATED_REPAIRERS: {
        return "Cuerpo automotriz y reparadores relacionados";
      }
      case JobFunction.AUTOMOTIVE_SERVICE_TECHNICIANS_AND_MECHANICS: {
        return "Técnicos y mecánicos de servicio automotriz";
      }
      case JobFunction.BAKERS: {
        return "Panadería";
      }
      case JobFunction.BARBERS_COSMETOLOGISTS_AND_OTHER_PERSONAL_APPEARANCE_WORKERS: {
        return "Barberos, cosmetólogos y otros aparecen personales";
      }
      case JobFunction.BARTENDERS: {
        return "Camareros";
      }
      case JobFunction.BILL_AND_ACCOUNT_COLLECTORS: {
        return "Coleccionistas de facturas y cuentas";
      }
      case JobFunction.BILLING_AND_POSTING_CLERKS_AND_MACHINE_OPERATORS: {
        return "Facturación y publicación de empleados y operadores de máquinas";
      }
      case JobFunction.BIOLOGICAL_SCIENTISTS: {
        return "Científicos biológicos";
      }
      case JobFunction.BIOLOGICAL_TECHNICIANS: {
        return "Técnicos biológicos";
      }
      case JobFunction.BIOMEDICAL_ENGINEERS: {
        return "Ingenieros biomédicos";
      }
      case JobFunction.BOILERMAKERS: {
        return "Caldermakers";
      }
      case JobFunction.BOOKBINDERS_AND_BINDERY_WORKERS: {
        return "Bookbinders y trabajadores de enlaces";
      }
      case JobFunction.BOOKKEEPING_ACCOUNTING_AND_AUDITING_CLERKS: {
        return "Contabilidad, contabilidad y auditoría de empleados";
      }
      case JobFunction.BRICKMASONS_BLOCKMASONS_AND_STONEMASONS: {
        return "Brickmasons, Blockmasons y Stonemasons";
      }
      case JobFunction.BROADCAST_AND_SOUND_ENGINEERING_TECHNICIANS_AND_RADIO_OPERATORS: {
        return "Técnicos de ingeniería de transmisión y sonido y RA";
      }
      case JobFunction.BROKERAGE_CLERKS: {
        return "Empleados de corretaje";
      }
      case JobFunction.BUDGET_ANALYSTS: {
        return "Analistas de presupuesto";
      }
      case JobFunction.BUILDING_CLEANING_WORKERS: {
        return "Trabajadores de limpieza de edificios";
      }
      case JobFunction.BUS_DRIVERS: {
        return "Choferes de autobus";
      }
      case JobFunction.BUTCHERS: {
        return "Carnicería";
      }
      case JobFunction.CARDIOVASCULAR_TECHNOLOGISTS_AND_TECHNICIANS: {
        return "Tecnólogos y técnicos cardiovasculares";
      }
      case JobFunction.CAREER_AND_TECHNICAL_EDUCATION_TEACHERS: {
        return "Maestros de educación profesional y técnica";
      }
      case JobFunction.CARGO_AND_FREIGHT_AGENTS: {
        return "Agentes de carga y carga";
      }
      case JobFunction.CARPENTERS: {
        return "Carpinteros";
      }
      case JobFunction.CARPET_FLOOR_AND_TILE_INSTALLERS_AND_FINISHERS: {
        return "Instaladores y finalistas de alfombras, pisos y azulejos";
      }
      case JobFunction.CARTOGRAPHERS_AND_PHOTOGRAMMETRISTS: {
        return "Cartógrafos y fotogramatristas";
      }
      case JobFunction.CASHIERS: {
        return "Cajeros";
      }
      case JobFunction.CEMENT_MASONS_CONCRETE_FINISHERS_SEGMENTAL_PAVERS_AND_TERRAZZO_WORKERS: {
        return "Masones de cemento, finalistas de concreto, pavimentadora segmentaria";
      }
      case JobFunction.CHEFS_AND_HEAD_COOKS: {
        return "Chefs y cocineros de cabeza";
      }
      case JobFunction.CHEFS_COOKS_AND_FOOD_PREPARATION_WORKERS: {
        return "Chefs, cocineros y trabajadores de preparación de alimentos";
      }
      case JobFunction.CHEMICAL_ENGINEERS: {
        return "Ingenieros químicos";
      }
      case JobFunction.CHEMICAL_TECHNICIANS: {
        return "Técnicos químicos";
      }
      case JobFunction.CHEMISTS_AND_MATERIALS_SCIENTISTS: {
        return "Químicos y científicos de materiales";
      }
      case JobFunction.CHILD_CARE_WORKERS: {
        return "Trabajadores de cuidado infantil";
      }
      case JobFunction.CHIROPRACTORS: {
        return "Quiroprácticos";
      }
      case JobFunction.CIVIL_ENGINEERING_TECHNICIANS: {
        return "Técnicos de ingeniería civil";
      }
      case JobFunction.CIVIL_ENGINEERS: {
        return "Ingenieros Civiles";
      }
      case JobFunction.CLAIMS_ADJUSTERS_APPRAISERS_EXAMINERS_AND_INVESTIGATORS: {
        return "Ajustadores de reclamos, tasadores, examinadores e invas";
      }
      case JobFunction.CLINICAL_LABORATORY_TECHNOLOGISTS_AND_TECHNICIANS: {
        return "Tecnólogos y técnicos de laboratorio clínico";
      }
      case JobFunction.COACHES_AND_SCOUTS: {
        return "Entrenadores y exploradores";
      }
      case JobFunction.COIN_VENDING_AND_AMUSEMENT_MACHINE_SERVICERS_AND_REPAIRERS: {
        return "Administradores de máquinas de monedas, expendientes y de diversión y";
      }
      case JobFunction.COMMERCIAL_AND_INDUSTRIAL_DESIGNERS: {
        return "Diseñadores comerciales e industriales";
      }
      case JobFunction.COMMERCIAL_MANAGEMENT: {
        return "Gestión comercial";
      }
      case JobFunction.COMMUNICATIONS_EQUIPMENT_OPERATORS: {
        return "Operadores de equipos de comunicaciones";
      }
      case JobFunction.COMPENSATION_AND_BENEFITS_MANAGERS: {
        return "Gerentes de compensación y beneficios";
      }
      case JobFunction.COMPENSATION_BENEFITS_AND_JOB_ANALYSIS_SPECIALISTS: {
        return "Compensación, beneficios y análisis de trabajo especialis";
      }
      case JobFunction.COMPUTER_AND_INFORMATION_RESEARCH_SCIENTISTS: {
        return "Científicos de investigación de informática e información";
      }
      case JobFunction.COMPUTER_AND_INFORMATION_SYSTEMS_MANAGERS: {
        return "Gerentes de sistemas de computadora y de información";
      }
      case JobFunction.COMPUTER_CONTROL_PROGRAMMERS_AND_OPERATORS: {
        return "Programadores y operadores de control de computadora";
      }
      case JobFunction.COMPUTER_HARDWARE_ENGINEERS: {
        return "Ingenieros de hardware de computadora";
      }
      case JobFunction.COMPUTER_NETWORK_ARCHITECTS: {
        return "Arquitectos de redes informáticas";
      }
      case JobFunction.COMPUTER_OPERATORS: {
        return "Operadores de computadoras";
      }
      case JobFunction.COMPUTER_PROGRAMMERS: {
        return "Programador de computadoras";
      }
      case JobFunction.COMPUTER_SCIENTISTS_AND_DATABASE_ADMINISTRATORS: {
        return "Informáticos y administradores de bases de datos";
      }
      case JobFunction.COMPUTER_SOFTWARE_ENGINEERS: {
        return "Ingenieros de software de computadora";
      }
      case JobFunction.COMPUTER_SUPPORT_SPECIALISTS: {
        return "Especialistas en soporte informático";
      }
      case JobFunction.COMPUTER_SUPPORT_SPECIALISTS_AND_SYSTEMS_ADMINISTRATORS: {
        return "Especialistas de soporte informático y administración de sistemas";
      }
      case JobFunction.COMPUTER_SYSTEMS_ANALYSTS: {
        return "Analistas de sistemas informáticos";
      }
      case JobFunction.COMPUTER_AUTOMATED_TELLER_AND_OFFICE_MACHINE_REPAIRERS: {
        return "Computadora, cajero automatizado y representante de máquinas de oficina";
      }
      case JobFunction.CONSERVATION_SCIENTISTS_AND_FORESTERS: {
        return "Científicos y forestales de conservación";
      }
      case JobFunction.CONSTRUCTION_AND_BUILDING_INSPECTORS: {
        return "Inspectores de construcción y construcción";
      }
      case JobFunction.CONSTRUCTION_EQUIPMENT_OPERATORS: {
        return "Operadores de equipos de construcción";
      }
      case JobFunction.CONSTRUCTION_LABORERS: {
        return "Trabajadores de la construcción";
      }
      case JobFunction.CONSTRUCTION_LABORERS_AND_HELPERS: {
        return "Trabajadores de construcción y ayudantes";
      }
      case JobFunction.CONSTRUCTION_MANAGERS: {
        return "Gerentes de construcción";
      }
      case JobFunction.COOKS: {
        return "Cocineros";
      }
      case JobFunction.CORRECTIONAL_OFFICERS: {
        return "Oficiales correccionales";
      }
      case JobFunction.CONTRACT_MANAGEMENT: {
        return "Gestión de contratos";
      }
      case JobFunction.CORRECTIONAL_OFFICERS_AND_BAILIFFS: {
        return "Oficiales y Alguaciles correccionales";
      }
      case JobFunction.COST_ESTIMATORS: {
        return "Estimadores de costos";
      }
      case JobFunction.COUNSELORS: {
        return "Consejeros";
      }
      case JobFunction.COUNTER_AND_RENTAL_CLERKS: {
        return "Empleados de contra y alquiler";
      }
      case JobFunction.COURIERS_AND_MESSENGERS: {
        return "Correos y mensajeros";
      }
      case JobFunction.COURT_REPORTERS: {
        return "Reporteros de la corte";
      }
      case JobFunction.CRAFT_AND_FINE_ARTISTS: {
        return "Artistas artesanales y buenos";
      }
      case JobFunction.CREDIT_AUTHORIZERS_CHECKERS_AND_CLERKS: {
        return "Autorizadores de crédito, verificadores y empleados";
      }
      case JobFunction.CUSTOMER_SERVICE_REPRESENTATIVES: {
        return "Representantes de Servicio al Cliente";
      }
      case JobFunction.DANCERS_AND_CHOREOGRAPHERS: {
        return "Bailarines y coreógrafos";
      }
      case JobFunction.DATA_ENTRY_AND_INFORMATION_PROCESSING_WORKERS: {
        return "Trabajadores de la entrada de datos y procesamiento de información";
      }
      case JobFunction.DATABASE_ADMINISTRATORS: {
        return "Administradores de bases de datos";
      }
      case JobFunction.DELIVERY_TRUCK_DRIVERS_AND_DRIVERSALES_WORKERS: {
        return "Camiones de reparto y conductores/trabajadores de ventas";
      }
      case JobFunction.DEMONSTRATORS_PRODUCT_PROMOTERS_AND_MODELS: {
        return "Manifestantes, promotores de productos y modelos";
      }
      case JobFunction.DENTAL_AND_OPHTHALMIC_LABORATORY_TECHNICIANS_AND_MEDICAL_APPLIANCE_TECHNICIANS: {
        return "Técnicos de laboratorio dental y oftálmico y M";
      }
      case JobFunction.DENTAL_ASSISTANTS: {
        return "Asistentes dentales";
      }
      case JobFunction.DENTAL_HYGIENISTS: {
        return "Higienistas dentales";
      }
      case JobFunction.DENTISTS: {
        return "Dentistas";
      }
      case JobFunction.DESKTOP_PUBLISHERS: {
        return "Editores de escritorio";
      }
      case JobFunction.DIAGNOSTIC_MEDICAL_SONOGRAPHERS: {
        return "Ecogonógrafos de diagnóstico";
      }
      case JobFunction.DIESEL_SERVICE_TECHNICIANS_AND_MECHANICS: {
        return "Técnicos y mecánicos de servicio diesel";
      }
      case JobFunction.DIETITIANS_AND_NUTRITIONISTS: {
        return "Dietistas y nutricionistas";
      }
      case JobFunction.DISPATCHERS: {
        return "Despachadores";
      }
      case JobFunction.DRAFTERS: {
        return "Redactores";
      }
      case JobFunction.DRYWALL_AND_CEILING_TILE_INSTALLERS_AND_TAPERS: {
        return "Instaladores de tejas de paneles de yeso y techo";
      }
      case JobFunction.DRYWALL_INSTALLERS_CEILING_TILE_INSTALLERS_AND_TAPERS: {
        return "Instaladores de paneles de yeso, instaladores de baldosas de techo y t";
      }
      case JobFunction.ECONOMISTS: {
        return "Economistas";
      }
      case JobFunction.EDITORS: {
        return "Editores";
      }
      case JobFunction.EDUCATION_ADMINISTRATORS: {
        return "Administradores de educación";
      }
      case JobFunction.ELECTRICAL_AND_ELECTRONICS_ENGINEERING_TECHNICIANS: {
        return "Técnicos de ingeniería eléctrica y electrónica";
      }
      case JobFunction.ELECTRICAL_AND_ELECTRONICS_ENGINEERS: {
        return "Ingenieros eléctricos y electrónicos";
      }
      case JobFunction.ELECTRICAL_AND_ELECTRONICS_INSTALLERS_AND_REPAIRERS: {
        return "Instaladores y reparadores eléctricos y electrónicos";
      }
      case JobFunction.ELECTRICIANS: {
        return "Electricistas";
      }
      case JobFunction.ELECTRO_MECHANICAL_TECHNICIANS: {
        return "Técnicos electromecánicos";
      }
      case JobFunction.ELECTRONIC_HOME_ENTERTAINMENT_EQUIPMENT_INSTALLERS_AND_REPAIRERS: {
        return "Instaladores electrónicos de equipos de entretenimiento para el hogar";
      }
      case JobFunction.ELEMENTARY_MIDDLE_AND_HIGH_SCHOOL_PRINCIPALS: {
        return "Directores de primaria, intermedia y de secundaria";
      }
      case JobFunction.ELEVATOR_INSTALLERS_AND_REPAIRERS: {
        return "Instaladores de ascensores y reparadores";
      }
      case JobFunction.EMERGENCY_MANAGEMENT_DIRECTORS: {
        return "Directores de gestión de emergencias";
      }
      case JobFunction.EMERGENCY_MEDICAL_TECHNICIANS_AND_PARAMEDICS: {
        return "Técnicos y paramédicos médicos de emergencia";
      }
      case JobFunction.EMTS_AND_PARAMEDICS: {
        return "EMT y paramédicos";
      }
      case JobFunction.ENGINEERING_AND_NATURAL_SCIENCES_MANAGERS: {
        return "Gerentes de ingeniería y ciencias naturales";
      }
      case JobFunction.ENGINEERING_TECHNICIANS: {
        return "Técnicos de ingeniería";
      }
      case JobFunction.ENVIRONMENTAL_ENGINEERING_TECHNICIANS: {
        return "Técnicos de ingeniería ambiental";
      }
      case JobFunction.ENVIRONMENTAL_ENGINEERS: {
        return "Ingenieros ambientales";
      }
      case JobFunction.ENVIRONMENTAL_SCIENCE_AND_PROTECTION_TECHNICIANS: {
        return "Técnicos de ciencias ambientales y protección";
      }
      case JobFunction.ENVIRONMENTAL_SCIENTISTS_AND_HYDROLOGISTS: {
        return "Científicos e hidrólogos ambientales";
      }
      case JobFunction.ENVIRONMENTAL_SCIENTISTS_AND_SPECIALISTS: {
        return "Científicos y especialistas ambientales";
      }
      case JobFunction.EPIDEMIOLOGISTS: {
        return "Epidemiólogos";
      }
      case JobFunction.EXERCISE_PHYSIOLOGISTS: {
        return "Fisiólogos de ejercicio";
      }
      case JobFunction.FARMERS_RANCHERS_AND_AGRICULTURAL_MANAGERS: {
        return "Agricultores, ganaderos y gerentes agrícolas";
      }
      case JobFunction.FARMERS_RANCHERS_AND_OTHER_AGRICULTURAL_MANAGERS: {
        return "Agricultores, ganaderos y otros gerentes agrícolas";
      }
      case JobFunction.FASHION_DESIGNERS: {
        return "Diseñadores de moda";
      }
      case JobFunction.FILE_CLERKS: {
        return "Clerks de archivo";
      }
      case JobFunction.FILM_AND_VIDEO_EDITORS_AND_CAMERA_OPERATORS: {
        return "Editores de películas y videos y operadores de cámaras";
      }
      case JobFunction.FINANCIAL_ANALYSTS: {
        return "Analistas financieros";
      }
      case JobFunction.FINANCIAL_ANALYSTS_AND_PERSONAL_FINANCIAL_ADVISORS: {
        return "Analistas financieros y asesores financieros personales";
      }
      case JobFunction.FINANCIAL_CLERKS: {
        return "Empleados financieros";
      }
      case JobFunction.FINANCIAL_EXAMINERS: {
        return "Examinadores financieros";
      }
      case JobFunction.FINANCIAL_MANAGERS: {
        return "Gerentes financieros";
      }
      case JobFunction.FIRE_FIGHTING_OCCUPATIONS: {
        return "Ocupaciones de lucha contra incendios";
      }
      case JobFunction.FIRE_INSPECTORS: {
        return "Inspectores de incendios";
      }
      case JobFunction.FIREFIGHTERS: {
        return "Bomberos";
      }
      case JobFunction.FISHERS_AND_FISHING_VESSEL_OPERATORS: {
        return "Pescadores y operadores de buques pesqueros";
      }
      case JobFunction.FISHING_AND_HUNTING_WORKERS: {
        return "Trabajadores de pesca y caza";
      }
      case JobFunction.FITNESS_TRAINERS_AND_INSTRUCTORS: {
        return "Fitness entrenadores e instructores";
      }
      case JobFunction.FITNESS_WORKERS: {
        return "Trabajadores de fitness";
      }
      case JobFunction.FLIGHT_ATTENDANTS: {
        return "Auxiliares de vuelo";
      }
      case JobFunction.FLOORING_INSTALLERS_AND_TILE_AND_MARBLE_SETTERS: {
        return "Instaladores de pisos y colchonetas de baldosas y mármol";
      }
      case JobFunction.FLORAL_DESIGNERS: {
        return "Diseñadores florales";
      }
      case JobFunction.FOOD_AND_BEVERAGE_SERVING_AND_RELATED_WORKERS: {
        return "Servicio de alimentos y bebidas y trabajadores relacionados";
      }
      case JobFunction.FOOD_AND_TOBACCO_PROCESSING_WORKERS: {
        return "Trabajadores de procesamiento de alimentos y tabaco";
      }
      case JobFunction.FOOD_PROCESSING_OCCUPATIONS: {
        return "Ocupaciones de procesamiento de alimentos";
      }
      case JobFunction.FOOD_SERVICE_MANAGERS: {
        return "Gerentes de servicio de alimentos";
      }
      case JobFunction.FORENSIC_SCIENCE_TECHNICIANS: {
        return "Técnicos de ciencias forenses";
      }
      case JobFunction.FOREST_AND_CONSERVATION_WORKERS: {
        return "Trabajadores de bosques y conservación";
      }
      case JobFunction.FOREST_CONSERVATION_AND_LOGGING_WORKERS: {
        return "Trabajadores de bosque, conservación y tala";
      }
      case JobFunction.FUNDRAISERS: {
        return "Recaudación de fondos";
      }
      case JobFunction.FUNERAL_DIRECTORS: {
        return "Directores de funerales";
      }
      case JobFunction.FUNERAL_SERVICE_WORKERS: {
        return "Trabajadores de servicios funerarios";
      }
      case JobFunction.GAMING_CAGE_WORKERS: {
        return "Trabajadores de la jaula de juego";
      }
      case JobFunction.GAMING_SERVICES_OCCUPATIONS: {
        return "Ocupaciones de servicios de juego";
      }
      case JobFunction.GAMING_SERVICES_WORKERS: {
        return "Trabajadores de servicios de juego";
      }
      case JobFunction.GENERAL_MAINTENANCE_AND_REPAIR_WORKERS: {
        return "Trabajadores generales de mantenimiento y reparación";
      }
      case JobFunction.GENERAL_OFFICE_CLERKS: {
        return "Empleados de la oficina general";
      }
      case JobFunction.GENETIC_COUNSELORS: {
        return "Asesores genéticos";
      }
      case JobFunction.GEOGRAPHERS: {
        return "Geógrafos";
      }
      case JobFunction.GEOLOGICAL_AND_PETROLEUM_TECHNICIANS: {
        return "Técnicos geológicos y de petróleo";
      }
      case JobFunction.GEOSCIENTISTS: {
        return "Geocientíficos";
      }
      case JobFunction.GLAZIERS: {
        return "Lisas";
      }
      case JobFunction.GRAPHIC_DESIGNERS: {
        return "Diseñadores gráficos";
      }
      case JobFunction.GROUNDS_MAINTENANCE_WORKERS: {
        return "Trabajadores de mantenimiento de los terrenos";
      }
      case JobFunction.HAND_LABORERS_AND_MATERIAL_MOVERS: {
        return "Trabajadores manuales y motores de material";
      }
      case JobFunction.HAZARDOUS_MATERIALS_REMOVAL_WORKERS: {
        return "Trabajadores de eliminación de materiales peligrosos";
      }
      case JobFunction.HEALTH_AND_SAFETY_ENGINEERS: {
        return "Ingenieros de salud y seguridad";
      }
      case JobFunction.HEALTH_EDUCATORS: {
        return "Educadores de salud";
      }
      case JobFunction.HEALTH_EDUCATORS_AND_COMMUNITY_HEALTH_WORKERS: {
        return "Educadores de salud y trabajadores de la salud comunitarios";
      }
      case JobFunction.HEATING_AIR_CONDITIONING_AND_REFRIGERATION_MECHANICS_AND_INSTALLERS: {
        return "Calefacción, aire acondicionado y refrigeración mecha";
      }
      case JobFunction.HEAVY_AND_TRACTOR_TRAILER_TRUCK_DRIVERS: {
        return "Conductores de camiones pesados ​​y de tractor-remolque";
      }
      case JobFunction.HEAVY_VEHICLE_AND_MOBILE_EQUIPMENT_SERVICE_TECHNICIANS: {
        return "Técnica de servicio de vehículos pesados ​​y equipos móviles";
      }
      case JobFunction.HEAVY_VEHICLE_AND_MOBILE_EQUIPMENT_SERVICE_TECHNICIANS_AND_MECHANICS: {
        return "Técnica de servicio de vehículos pesados ​​y equipos móviles";
      }
      case JobFunction.HIGH_SCHOOL_TEACHERS: {
        return "Maestros de secundaria";
      }
      case JobFunction.HISTORIANS: {
        return "Historiadores";
      }
      case JobFunction.HOME_APPLIANCE_REPAIRERS: {
        return "Reparadores de electrodomésticos";
      }
      case JobFunction.HOME_HEALTH_AIDES_AND_PERSONAL_CARE_AIDES: {
        return "Ayudos de salud en el hogar y asistentes de cuidado personal";
      }
      case JobFunction.HOTEL_MOTEL_AND_RESORT_DESK_CLERKS: {
        return "Empleados de escritorio de hotel, motel y resort";
      }
      case JobFunction.HUMAN_RESOURCES_ASSISTANTS_EXCEPT_PAYROLL_AND_TIMEKEEPING: {
        return "Asistentes de recursos humanos, excepto la nómina y Tim";
      }
      case JobFunction.HUMAN_RESOURCES_MANAGERS: {
        return "Gerentes de recursos humanos";
      }
      case JobFunction.HUMAN_RESOURCES_SPECIALISTS: {
        return "Especialistas en recursos humanos";
      }
      case JobFunction.HUMAN_RESOURCES_TRAINING_AND_LABOR_RELATIONS_MANAGERS_AND_SPECIALISTS: {
        return "Hombre de recursos humanos, capacitación y relaciones laborales";
      }
      case JobFunction.HYDROLOGISTS: {
        return "Hidrólogos";
      }
      case JobFunction.INDUSTRIAL_DESIGNERS: {
        return "Diseñadores industriales";
      }
      case JobFunction.INDUSTRIAL_ENGINEERING_TECHNICIANS: {
        return "Técnicos de ingeniería industrial";
      }
      case JobFunction.INDUSTRIAL_ENGINEERS: {
        return "Ingenieros industriales";
      }
      case JobFunction.INDUSTRIAL_MACHINERY_MECHANICS_AND_MAINTENANCE_WORKERS: {
        return "Mecánica de maquinaria industrial y mantenimiento Wor";
      }
      case JobFunction.INDUSTRIAL_PRODUCTION_MANAGERS: {
        return "Gerentes de producción industrial";
      }
      case JobFunction.INFORMATION_CLERKS: {
        return "Empleados de información";
      }
      case JobFunction.INFORMATION_SECURITY_ANALYSTS: {
        return "Analistas de seguridad de la información";
      }
      case JobFunction.INSPECTORS_TESTERS_SORTERS_SAMPLERS_AND_WEIGHERS: {
        return "Inspectores, probadores, clasificadores, muestreadores y pesos";
      }
      case JobFunction.INSTRUCTIONAL_COORDINATORS: {
        return "Coordinadores de instrucción";
      }
      case JobFunction.INSULATION_WORKERS: {
        return "Trabajadores de aislamiento";
      }
      case JobFunction.INSURANCE_SALES_AGENTS: {
        return "Agentes de ventas de seguros";
      }
      case JobFunction.INSURANCE_UNDERWRITERS: {
        return "Suscriptores de seguros";
      }
      case JobFunction.INTERIOR_DESIGNERS: {
        return "Diseñadores de interiores";
      }
      case JobFunction.INTERPRETERS_AND_TRANSLATORS: {
        return "Intérpretes y traductores";
      }
      case JobFunction.IRONWORKERS: {
        return "Trabajadores de hierro";
      }
      case JobFunction.JEWELERS_AND_PRECIOUS_STONE_AND_METAL_WORKERS: {
        return "Joyeros y preciosos trabajadores de piedra y metal";
      }
      case JobFunction.JOB_OPPORTUNITIES_IN_THE_ARMED_FORCES: {
        return "Oportunidades de trabajo en las fuerzas armadas";
      }
      case JobFunction.JUDGES_AND_HEARING_OFFICERS: {
        return "Jueces y auditores";
      }
      case JobFunction.JUDGES_MAGISTRATES_AND_OTHER_JUDICIAL_WORKERS: {
        return "Jueces, magistrados y otros trabajadores judiciales";
      }
      case JobFunction.KINDERGARTEN_AND_ELEMENTARY_SCHOOL_TEACHERS: {
        return "Profesores de jardín de infantes y primaria";
      }
      case JobFunction.LABOR_RELATIONS_SPECIALISTS: {
        return "Especialistas en relaciones laborales";
      }
      case JobFunction.LANDSCAPE_ARCHITECTS: {
        return "Arquitectos paisajistas";
      }
      case JobFunction.LAWYERS: {
        return "Abogados";
      }
      case JobFunction.LIBRARIANS: {
        return "Bibliotecarios";
      }
      case JobFunction.LIBRARY_TECHNICIANS_AND_ASSISTANTS: {
        return "Técnicos y asistentes de la biblioteca";
      }
      case JobFunction.LICENSED_PRACTICAL_AND_LICENSED_VOCATIONAL_NURSES: {
        return "Enfermeras vocacionales prácticas y con licencia con licencia";
      }
      case JobFunction.LINE_INSTALLERS_AND_REPAIRERS: {
        return "Instaladores y reparadores de línea";
      }
      case JobFunction.LOAN_OFFICERS: {
        return "Oficiales de préstamo";
      }
      case JobFunction.LODGING_MANAGERS: {
        return "Gerentes de alojamiento";
      }
      case JobFunction.LOGGING_WORKERS: {
        return "Trabajadores de registro";
      }
      case JobFunction.LOGISTICIANS: {
        return "Logísticos";
      }
      case JobFunction.MACHINE_SETTERS_OPERATORS_AND_TENDERS_METAL_AND_PLASTIC: {
        return "Máineas, operadores y licitaciones-metal y";
      }
      case JobFunction.MACHINISTS: {
        return "Maquinistas";
      }
      case JobFunction.MACHINISTS_AND_TOOL_AND_DIE_MAKERS: {
        return "Maquinistas y fabricantes de herramientas y troqueles";
      }
      case JobFunction.MAINTENANCE_AND_REPAIR_WORKERS_GENERAL: {
        return "Trabajadores de mantenimiento y reparación, general";
      }
      case JobFunction.MANAGEMENT_ANALYSTS: {
        return "Analistas de gestión";
      }
      case JobFunction.MANICURISTS_AND_PEDICURISTS: {
        return "Manicuristas y pedicuristas";
      }
      case JobFunction.MARINE_ENGINEERS_AND_NAVAL_ARCHITECTS: {
        return "Ingenieros marinos y arquitectos navales";
      }
      case JobFunction.MARKET_AND_SURVEY_RESEARCHERS: {
        return "Investigadores de mercado y encuestas";
      }
      case JobFunction.MARKET_RESEARCH_ANALYSTS: {
        return "Analistas de investigación de mercado";
      }
      case JobFunction.MARRIAGE_AND_FAMILY_THERAPISTS: {
        return "Terapeutas matrimoniales y familiares";
      }
      case JobFunction.MASSAGE_THERAPISTS: {
        return "Terapeutas de masaje";
      }
      case JobFunction.MATERIAL_MOVING_MACHINE_OPERATORS: {
        return "Operadores de máquinas en movimiento de materiales";
      }
      case JobFunction.MATERIAL_MOVING_OCCUPATIONS: {
        return "Ocupaciones en movimiento de materiales";
      }
      case JobFunction.MATERIAL_RECORDING_CLERKS: {
        return "Empleados de grabación de material";
      }
      case JobFunction.MATERIALS_ENGINEERS: {
        return "Ingenieros de materiales";
      }
      case JobFunction.MATHEMATICIANS: {
        return "Matemáticos";
      }
      case JobFunction.MATHEMATICIANS_AND_STATISTICIANS: {
        return "Matemáticos y estadísticos";
      }
      case JobFunction.MECHANICAL_ENGINEERING_TECHNICIANS: {
        return "Técnicos de ingeniería mecánica";
      }
      case JobFunction.MECHANICAL_ENGINEERS: {
        return "Ingenieros mecánicos";
      }
      case JobFunction.MEDICAL_AND_CLINICAL_LABORATORY_TECHNOLOGISTS_AND_TECHNICIANS: {
        return "Tecnólogos de laboratorio médico y clínico y";
      }
      case JobFunction.MEDICAL_AND_HEALTH_SERVICES_MANAGERS: {
        return "Gerentes de servicios médicos y de salud";
      }
      case JobFunction.MEDICAL_ASSISTANTS: {
        return "Asistentes médicos";
      }
      case JobFunction.MEDICAL_EQUIPMENT_REPAIRERS: {
        return "Reparadores de equipos médicos";
      }
      case JobFunction.MEDICAL_RECORDS_AND_HEALTH_INFORMATION_TECHNICIANS: {
        return "Registros médicos y técnicos de información de salud";
      }
      case JobFunction.MEDICAL_SCIENTISTS: {
        return "Científicos médicos";
      }
      case JobFunction.MEDICAL_TRANSCRIPTIONISTS: {
        return "Transcriptores médicos";
      }
      case JobFunction.MEDICAL_DENTAL_AND_OPHTHALMIC_LABORATORY_TECHNICIANS: {
        return "Técnica de laboratorio médico, dental y oftálmico";
      }
      case JobFunction.MEETING_AND_CONVENTION_PLANNERS: {
        return "Planificadores de reuniones y convenciones";
      }
      case JobFunction.MEETING_CONVENTION_AND_EVENT_PLANNERS: {
        return "Planificadores de reuniones, convenciones y eventos";
      }
      case JobFunction.METAL_AND_PLASTIC_MACHINE_WORKERS: {
        return "Trabajadores de máquinas de metal y plástico";
      }
      case JobFunction.MICROBIOLOGISTS: {
        return "Microbiólogos";
      }
      case JobFunction.MIDDLE_SCHOOL_TEACHERS: {
        return "Maestros de secundaria";
      }
      case JobFunction.MILITARY_CAREERS: {
        return "Carreras militares";
      }
      case JobFunction.MILLWRIGHTS: {
        return "Millwrights";
      }
      case JobFunction.MINING_AND_GEOLOGICAL_ENGINEERS: {
        return "Ingenieros mineros y geológicos";
      }
      case JobFunction.MODELS: {
        return "Modelos";
      }
      case JobFunction.METER_READERS_UTILITIES: {
        return "Lectores de medidores, servicios públicos";
      }
      case JobFunction.MULTIMEDIA_ARTISTS_AND_ANIMATORS: {
        return "Artistas y animadores multimedia";
      }
      case JobFunction.MUSIC_DIRECTORS_AND_COMPOSERS: {
        return "Directores de música y compositores";
      }
      case JobFunction.MUSICIANS_AND_SINGERS: {
        return "Músicos y cantantes";
      }
      case JobFunction.MUSICIANS_SINGERS_AND_RELATED_WORKERS: {
        return "Músicos, cantantes y trabajadores relacionados";
      }
      case JobFunction.NATURAL_SCIENCES_MANAGERS: {
        return "Gerentes de ciencias naturales";
      }
      case JobFunction.NETWORK_AND_COMPUTER_SYSTEMS_ADMINISTRATORS: {
        return "Administradores de sistemas de red e informática";
      }
      case JobFunction.NEWS_ANALYSTS_REPORTERS_AND_CORRESPONDENTS: {
        return "Analistas de noticias, reporteros y corresponsales";
      }
      case JobFunction.NUCLEAR_ENGINEERS: {
        return "Ingenieros nucleares";
      }
      case JobFunction.NUCLEAR_MEDICINE_TECHNOLOGISTS: {
        return "Tecnólogos de medicina nuclear";
      }
      case JobFunction.NUCLEAR_TECHNICIANS: {
        return "Técnicos Nucleares";
      }
      case JobFunction.NURSE_ANESTHETISTS_NURSE_MIDWIVES_AND_NURSE_PRACTITIONERS: {
        return "Enfermera anestesistas, parteras de enfermería y enfermera Prac";
      }
      case JobFunction.NURSING_ASSISTANTS_AND_ORDERLIES: {
        return "Asistentes de enfermería y ordenadores";
      }
      case JobFunction.NURSING_PSYCHIATRIC_AND_HOME_HEALTH_AIDES: {
        return "Ayudos de enfermería, psiquiátricos y de salud en el hogar";
      }
      case JobFunction.OCCUPATIONAL_HEALTH_AND_SAFETY_SPECIALISTS_AND_TECHNICIANS: {
        return "Especialistas en salud y seguridad ocupacional y TEC";
      }
      case JobFunction.OCCUPATIONAL_THERAPIST_ASSISTANTS_AND_AIDES: {
        return "Asistentes y ayudantes del terapeuta ocupacional";
      }
      case JobFunction.OCCUPATIONAL_THERAPISTS: {
        return "Terapeutas ocupacionales";
      }
      case JobFunction.OCCUPATIONAL_THERAPY_ASSISTANTS_AND_AIDES: {
        return "Asistentes y asistentes de terapia ocupacional";
      }
      case JobFunction.OFFICE_AND_ADMINISTRATIVE_SUPPORT_WORKER_SUPERVISORS_AND_MANAGERS: {
        return "Superviso de la oficina y los trabajadores de apoyo administrativo";
      }
      case JobFunction.OFFICE_CLERKS_GENERAL: {
        return "Empleados de oficina, general";
      }
      case JobFunction.OPERATIONS_RESEARCH_ANALYSTS: {
        return "Analistas de investigación de operaciones";
      }
      case JobFunction.OPTICIANS: {
        return "Óptico";
      }
      case JobFunction.OPTICIANS_DISPENSING: {
        return "Ópticos, dispensando";
      }
      case JobFunction.OPTOMETRISTS: {
        return "Optometristas";
      }
      case JobFunction.ORDER_CLERKS: {
        return "Ordenar empleados";
      }
      case JobFunction.ORTHOTISTS_AND_PROSTHETISTS: {
        return "Ortotistas y prótesis";
      }
      case JobFunction.PAINTERS_AND_PAPERHANGERS: {
        return "Pintores y periódicos";
      }
      case JobFunction.PAINTERS_CONSTRUCTION_AND_MAINTENANCE: {
        return "Pintores, construcción y mantenimiento";
      }
      case JobFunction.PAINTING_AND_COATING_WORKERS: {
        return "Trabajadores de pintura y recubrimiento";
      }
      case JobFunction.PAINTING_AND_COATING_WORKERS_EXCEPT_CONSTRUCTION_AND_MAINTENANCE: {
        return "Trabajadores de pintura y recubrimiento, excepto la construcción";
      }
      case JobFunction.PARALEGALS_AND_LEGAL_ASSISTANTS: {
        return "Asistentes legales y asistentes legales";
      }
      case JobFunction.PAYROLL_AND_TIMEKEEPING_CLERKS: {
        return "Empleados de nómina y cronometraje";
      }
      case JobFunction.PERSONAL_AND_HOME_CARE_AIDES: {
        return "Ayudos de atención personal y doméstica";
      }
      case JobFunction.PERSONAL_FINANCIAL_ADVISORS: {
        return "Asesores financieros personales";
      }
      case JobFunction.PEST_CONTROL_WORKERS: {
        return "Trabajadores de control de plagas";
      }
      case JobFunction.PETROLEUM_ENGINEERS: {
        return "Ingenieros de petróleo";
      }
      case JobFunction.PHARMACISTS: {
        return "Farmacéuticos";
      }
      case JobFunction.PHARMACY_AIDES: {
        return "Ayudantes de farmacia";
      }
      case JobFunction.PHARMACY_TECHNICIANS: {
        return "Técnicos de farmacia";
      }
      case JobFunction.PHLEBOTOMISTS: {
        return "Flebotomistas";
      }
      case JobFunction.PHOTOGRAPHERS: {
        return "Fotógrafos";
      }
      case JobFunction.PHYSICAL_THERAPIST_ASSISTANTS_AND_AIDES: {
        return "Asistentes y ayudantes de fisioterapeuta";
      }
      case JobFunction.PHYSICAL_THERAPISTS: {
        return "Fisioterapeutas";
      }
      case JobFunction.PHYSICIAN_ASSISTANTS: {
        return "Asistentes médicos";
      }
      case JobFunction.PHYSICIANS_AND_SURGEONS: {
        return "Médicos y cirujanos";
      }
      case JobFunction.PHYSICISTS_AND_ASTRONOMERS: {
        return "Físicos y astrónomos";
      }
      case JobFunction.PIPELAYERS_PLUMBERS_PIPEFITTERS_AND_STEAMFITTERS: {
        return "Pipellayers, Flumbers, PipeFitters y Steamfitter";
      }
      case JobFunction.PHOTOGRAPHIC_PROCESS_WORKERS_AND_PROCESSING_MACHINE_OPERATORS: {
        return "Trabajadores de procesos fotográficos y procesamiento de máquinas";
      }
      case JobFunction.PLUMBERS_PIPEFITTERS_AND_STEAMFITTERS: {
        return "Flores, PipeFitters y Steamfitters";
      }
      case JobFunction.PODIATRISTS: {
        return "Podólogos";
      }
      case JobFunction.POLICE_AND_DETECTIVES: {
        return "Policía y detectives";
      }
      case JobFunction.POLICE_FIRE_AND_AMBULANCE_DISPATCHERS: {
        return "Despachadores de la policía, los bomberos y la ambulancia";
      }
      case JobFunction.POLITICAL_SCIENTISTS: {
        return "Politólogos";
      }
      case JobFunction.POSTAL_SERVICE_WORKERS: {
        return "Trabajadores de servicio postal";
      }
      case JobFunction.POSTSECONDARY_EDUCATION_ADMINISTRATORS: {
        return "Administradores de educación postsecundaria";
      }
      case JobFunction.POSTSECONDARY_TEACHERS: {
        return "Profesores postsecundarios";
      }
      case JobFunction.PLASTERERS_AND_STUCCO_MASONS: {
        return "Yeseros y albañiles de estuco";
      }
      case JobFunction.POWER_PLANT_OPERATORS_DISTRIBUTORS_AND_DISPATCHERS: {
        return "Operadores de plantas de energía, distribuidores y despacho";
      }
      case JobFunction.PRESCHOOL_AND_CHILDCARE_CENTER_DIRECTORS: {
        return "Directores de preescolar y centros de cuidado infantil";
      }
      case JobFunction.PRESCHOOL_TEACHERS: {
        return "Maestros de preescolar";
      }
      case JobFunction.PRIVATE_DETECTIVES_AND_INVESTIGATORS: {
        return "Detectives e investigadores privados";
      }
      case JobFunction.PROBATION_OFFICERS_AND_CORRECTIONAL_TREATMENT_SPECIALISTS: {
        return "Oficiales de libertad condicional y especificaciones de tratamiento correccional";
      }
      case JobFunction.PROCUREMENT_CLERKS: {
        return "Empleados de adquisiciones";
      }
      case JobFunction.PROCUREMENT_MANAGER: {
        return "Gerente de compras";
      }
      case JobFunction.PRODUCERS_AND_DIRECTORS: {
        return "Productores y directores";
      }
      case JobFunction.PRODUCTION_PLANNING_AND_EXPEDITING_CLERKS: {
        return "Empleados de producción, planificación y aceleración";
      }
      case JobFunction.PROPERTY_REAL_ESTATE_AND_COMMUNITY_ASSOCIATION_MANAGERS: {
        return "Asociación de propiedades, bienes raíces y comunidad M";
      }
      case JobFunction.PRECISION_INSTRUMENT_AND_EQUIPMENT_REPAIRERS: {
        return "Reparadores de instrumentos y equipos de precisión";
      }
      case JobFunction.PSYCHIATRIC_TECHNICIANS_AND_AIDES: {
        return "Técnicos psiquiátricos y ayudantes";
      }
      case JobFunction.PREPRESS_TECHNICIANS_AND_WORKERS: {
        return "Técnicos y trabajadores de prejuicios";
      }
      case JobFunction.PSYCHOLOGISTS: {
        return "Psicólogos";
      }
      case JobFunction.PUBLIC_RELATIONS_AND_FUNDRAISING_MANAGERS: {
        return "Relaciones públicas y gerentes de recaudación de fondos";
      }
      case JobFunction.PUBLIC_RELATIONS_SPECIALISTS: {
        return "Especialistas en relaciones públicas";
      }
      case JobFunction.PURCHASING_MANAGERS_BUYERS_AND_PURCHASING_AGENTS: {
        return "Gerentes de compras, compradores y agentes de compras";
      }
      case JobFunction.PRINTING_MACHINE_OPERATORS: {
        return "Operadores de la máquina de impresión";
      }
      case JobFunction.QUALITY_CONTROL_INSPECTORS: {
        return "Inspectores de control de calidad";
      }
      case JobFunction.RADIATION_THERAPISTS: {
        return "Radioterapeutas";
      }
      case JobFunction.RADIO_AND_TELECOMMUNICATIONS_EQUIPMENT_INSTALLERS_AND_REPAIRERS: {
        return "Instaladores de equipos de radio y telecomunicaciones";
      }
      case JobFunction.RADIOLOGIC_AND_MRI_TECHNOLOGISTS: {
        return "Tecnólogos radiológicos y de resonancia magnética";
      }
      case JobFunction.RADIOLOGIC_TECHNOLOGISTS_AND_TECHNICIANS: {
        return "Tecnólogos y técnicos radiológicos";
      }
      case JobFunction.RAIL_TRANSPORTATION_OCCUPATIONS: {
        return "Ocupaciones de transporte ferroviario";
      }
      case JobFunction.RAILROAD_WORKERS: {
        return "Trabajadores ferroviarios";
      }
      case JobFunction.REAL_ESTATE_BROKERS_AND_SALES_AGENTS: {
        return "Corredores de bienes raíces y agentes de ventas";
      }
      case JobFunction.RECEPTIONISTS: {
        return "Recepcionistas";
      }
      case JobFunction.RECEPTIONISTS_AND_INFORMATION_CLERKS: {
        return "Recepcionistas y empleados de información";
      }
      case JobFunction.RECREATION_WORKERS: {
        return "Trabajadores de recreación";
      }
      case JobFunction.RECREATIONAL_THERAPISTS: {
        return "Terapeutas recreativas";
      }
      case JobFunction.REGISTERED_NURSES: {
        return "Enfermeras registradas";
      }
      case JobFunction.REHABILITATION_COUNSELORS: {
        return "Consejeros de rehabilitación";
      }
      case JobFunction.REPORTERS_CORRESPONDENTS_AND_BROADCAST_NEWS_ANALYSTS: {
        return "Reporteros, corresponsales y noticias de transmisión anal";
      }
      case JobFunction.RESERVATION_AND_TRANSPORTATION_TICKET_AGENTS_AND_TRAVEL_CLERKS: {
        return "Agentes de boletos de reserva y transporte y T";
      }
      case JobFunction.RESPIRATORY_THERAPISTS: {
        return "Terapeutas respiratorias";
      }
      case JobFunction.RETAIL_SALES_WORKERS: {
        return "Trabajadores de ventas minoristas";
      }
      case JobFunction.RETAIL_SALESPERSONS: {
        return "Vendedores minoristas";
      }
      case JobFunction.ROOFERS: {
        return "Techadores";
      }
      case JobFunction.SALES_ENGINEERS: {
        return "Ingenieros de ventas";
      }
      case JobFunction.SALES_MANAGERS: {
        return "Gerentes de ventas";
      }
      case JobFunction.SALES_REPRESENTATIVES_WHOLESALE_AND_MANUFACTURING: {
        return "Representantes de ventas, mayoristas y fabricación";
      }
      case JobFunction.SCHOOL_AND_CAREER_COUNSELORS: {
        return "Consejeros escolares y profesionales";
      }
      case JobFunction.SECRETARIES_AND_ADMINISTRATIVE_ASSISTANTS: {
        return "Secretarios y asistentes administrativos";
      }
      case JobFunction.SECURITIES_COMMODITIES_AND_FINANCIAL_SERVICES_SALES_AGENTS: {
        return "Valores, productos básicos y servicios financieros SA";
      }
      case JobFunction.SECURITY_GUARDS_AND_GAMING_SURVEILLANCE_OFFICERS: {
        return "Guardias de seguridad y oficiales de vigilancia de juegos";
      }
      case JobFunction.SHEET_METAL_WORKERS: {
        return "Trabajadores de chapa";
      }
      case JobFunction.SHIPPING_RECEIVING_AND_TRAFFIC_CLERKS: {
        return "Empleados de envío, recepción y tráfico";
      }
      case JobFunction.SKINCARE_SPECIALISTS: {
        return "Especialistas en el cuidado de la piel";
      }
      case JobFunction.SMALL_ENGINE_MECHANICS: {
        return "Mecánica de motor pequeño";
      }
      case JobFunction.SOCIAL_AND_COMMUNITY_SERVICE_MANAGERS: {
        return "Gerentes de servicios sociales y comunitarios";
      }
      case JobFunction.SOCIAL_AND_HUMAN_SERVICE_ASSISTANTS: {
        return "Asistentes de servicios sociales y humanos";
      }
      case JobFunction.SOCIAL_SCIENTISTS_OTHER: {
        return "Científicos sociales, otros";
      }
      case JobFunction.SOCIAL_WORKERS: {
        return "Trabajadores sociales";
      }
      case JobFunction.SALES_WORKER_SUPERVISORS: {
        return "Supervisores de trabajadores de ventas";
      }
      case JobFunction.SOCIOLOGISTS: {
        return "Sociólogos";
      }
      case JobFunction.SOFTWARE_DEVELOPERS: {
        return "Desarrolladores de software";
      }
      case JobFunction.SCIENCE_TECHNICIANS: {
        return "Técnicos científicos";
      }
      case JobFunction.SOLAR_PHOTOVOLTAIC_INSTALLERS: {
        return "Instaladores solares fotovoltaicos";
      }
      case JobFunction.SPECIAL_EDUCATION_TEACHERS: {
        return "Maestros de educación especial";
      }
      case JobFunction.SPEECH_LANGUAGE_PATHOLOGISTS: {
        return "Patólogos del habla y lenguaje";
      }
      case JobFunction.STATIONARY_ENGINEERS_AND_BOILER_OPERATORS: {
        return "Ingenieros estacionarios y operadores de calderas";
      }
      case JobFunction.SEMICONDUCTOR_PROCESSORS: {
        return "Procesadores de semiconductores";
      }
      case JobFunction.STATISTICIANS: {
        return "Estadísticos";
      }
      case JobFunction.STOCK_CLERKS_AND_ORDER_FILLERS: {
        return "Empleados de stock y rellenos de pedidos";
      }
      case JobFunction.STRUCTURAL_AND_REINFORCING_IRON_AND_METAL_WORKERS: {
        return "Trabajadores estructurales y reforzados de hierro y metal";
      }
      case JobFunction.SUBSTANCE_ABUSE_BEHAVIORAL_DISORDER_AND_MENTAL_HEALTH_COUNSELORS: {
        return "Abuso de sustancias, trastorno conductual y h mental h";
      }
      case JobFunction.SUPPLY_MANAGEMENT: {
        return "Gestión de suministros";
      }
      case JobFunction.SURGICAL_TECHNOLOGISTS: {
        return "Tecnólogos quirúrgicos";
      }
      case JobFunction.SURVEY_RESEARCHERS: {
        return "Investigadores de encuestas";
      }
      case JobFunction.SURVEYING_AND_MAPPING_TECHNICIANS: {
        return "Técnicos de topografía y mapeo";
      }
      case JobFunction.SURVEYORS: {
        return "Topógrafos";
      }
      case JobFunction.SURVEYORS_CARTOGRAPHERS_PHOTOGRAMMETRISTS_AND_SURVEYING_AND_MAPPING_TECHNICIANS: {
        return "Topógrafos, cartógrafos, fotogramcrametristas y s";
      }
      case JobFunction.TAX_EXAMINERS_AND_COLLECTORS_AND_REVENUE_AGENTS: {
        return "Examinadores de impuestos y recolectores, y agentes de ingresos";
      }
      case JobFunction.TAX_EXAMINERS_COLLECTORS_AND_REVENUE_AGENTS: {
        return "Examinadores de impuestos, cobradores y agentes de ingresos";
      }
      case JobFunction.TAXI_DRIVERS_AND_CHAUFFEURS: {
        return "Taxistas y choferes";
      }
      case JobFunction.TAXI_DRIVERS_RIDE_HAILING_DRIVERS_AND_CHAUFFEURS: {
        return "Taxistas, conductores de transporte y choferes";
      }
      case JobFunction.TEACHER_ASSISTANTS: {
        return "Asistentes de maestros";
      }
      case JobFunction.TEACHERS_ADULT_LITERACY_AND_REMEDIAL_EDUCATION: {
        return "Maestros-educación para adultos y educación correctiva";
      }
      case JobFunction.TEACHERS_POSTSECONDARY: {
        return "Maestros postsecundarios";
      }
      case JobFunction.TEACHERS_PRESCHOOL_KINDERGARTEN_ELEMENTARY_MIDDLE_AND_SECONDARY: {
        return "Profesores-Preschool, Kindergarten, Elementary, Midd";
      }
      case JobFunction.TEACHERS_SPECIAL_EDUCATION: {
        return "Educación especial para maestros";
      }
      case JobFunction.TECHNICAL_WRITERS: {
        return "Escritores técnicos";
      }
      case JobFunction.TELECOMMUNICATIONS_EQUIPMENT_INSTALLERS_AND_REPAIRERS: {
        return "Instaladores de equipos de telecomunicaciones y reparación";
      }
      case JobFunction.TELEVISION_VIDEO_AND_MOTION_PICTURE_CAMERA_OPERATORS_AND_EDITORS: {
        return "Opera de cámara de televisión, video y cine de películas";
      }
      case JobFunction.TELLERS: {
        return "Cajeros";
      }
      case JobFunction.TOOL_AND_DIE_MAKERS: {
        return "Fabricantes de herramientas y troqueles";
      }
      case JobFunction.TOP_EXECUTIVES: {
        return "Ejecutivos de alto nivel";
      }
      case JobFunction.TRAINING_AND_DEVELOPMENT_MANAGERS: {
        return "Gerentes de capacitación y desarrollo";
      }
      case JobFunction.TRAINING_AND_DEVELOPMENT_SPECIALISTS: {
        return "Especialistas en capacitación y desarrollo";
      }
      case JobFunction.TRAVEL_AGENTS: {
        return "Agentes de viajes";
      }
      case JobFunction.TRUCK_DRIVERS_AND_DRIVERSALES_WORKERS: {
        return "Conductores de camiones y conductores/trabajadores de ventas";
      }
      case JobFunction.UMPIRES_REFEREES_AND_OTHER_SPORTS_OFFICIALS: {
        return "Árbitros, árbitros y otros funcionarios deportivos";
      }
      case JobFunction.URBAN_AND_REGIONAL_PLANNERS: {
        return "Planificadores urbanos y regionales";
      }
      case JobFunction.VETERINARIANS: {
        return "Veterinarios";
      }
      case JobFunction.TEACHERS_SELF_ENRICHMENT_EDUCATION: {
        return "Educación de los maestros-self-enriquecimiento";
      }
      case JobFunction.VETERINARY_ASSISTANTS_AND_LABORATORY_ANIMAL_CARETAKERS: {
        return "Asistentes veterinarios y caretada de animales de laboratorio";
      }
      case JobFunction.VETERINARY_TECHNOLOGISTS_AND_TECHNICIANS: {
        return "Tecnólogos y técnicos veterinarios";
      }
      case JobFunction.WAITERS_AND_WAITRESSES: {
        return "Camareros y camareras";
      }
      case JobFunction.WATER_AND_LIQUID_WASTE_TREATMENT_PLANT_AND_SYSTEM_OPERATORS: {
        return "Planta y sistema de tratamiento de residuos de agua y líquidos";
      }
      case JobFunction.WATER_AND_WASTEWATER_TREATMENT_PLANT_AND_SYSTEM_OPERATORS: {
        return "Planta de tratamiento de agua y aguas residuales y sistema";
      }
      case JobFunction.WATER_TRANSPORTATION_OCCUPATIONS: {
        return "Ocupaciones de transporte de agua";
      }
      case JobFunction.TEXTILE_APPAREL_AND_FURNISHINGS_OCCUPATIONS: {
        return "Ocupaciones de textiles, ropa y muebles";
      }
      case JobFunction.WATER_TRANSPORTATION_WORKERS: {
        return "Trabajadores del transporte de agua";
      }
      case JobFunction.WEB_DEVELOPERS: {
        return "Desarrolladores de sitios de Internet";
      }
      case JobFunction.WEIGHERS_MEASURERS_CHECKERS_AND_SAMPLERS_RECORDKEEPING: {
        return "Pesadillas, medidores, damas y muestreadores, recor";
      }
      case JobFunction.WELDERS_CUTTERS_SOLDERERS_AND_BRAZERS: {
        return "Soldadores, cortadores, soldadores y brascos";
      }
      case JobFunction.WELDING_SOLDERING_AND_BRAZING_WORKERS: {
        return "Trabajadores de soldadura, soldadura y soldadura";
      }
      case JobFunction.WHOLESALE_AND_MANUFACTURING_SALES_REPRESENTATIVES: {
        return "Representantes de ventas mayoristas y de fabricación";
      }
      case JobFunction.WIND_TURBINE_TECHNICIANS: {
        return "Técnicos de turbina eólica";
      }
      case JobFunction.WOODWORKERS: {
        return "Carpinteros";
      }
      case JobFunction.WRITERS_AND_AUTHORS: {
        return "Escritores y autores";
      }
      case JobFunction.WRITERS_AND_EDITORS: {
        return "Escritores y editores";
      }
      case JobFunction.ZOOLOGISTS_AND_WILDLIFE_BIOLOGISTS: {
        return "Zoólogos y biólogos de vida silvestre";
      }
      case JobFunction.OTHER: {
        return "Otro";
      }
    }

    return "";
  }

  private static getJobFunctionTitleInFrench(jobFunction: JobFunction): string {
    switch (jobFunction) {
      case JobFunction.ACCOUNTANTS_AND_AUDITORS: {
        return "Comptables et auditeurs";
      }
      case JobFunction.ACTORS_PRODUCERS_AND_DIRECTORS: {
        return "Acteurs, producteurs et réalisateurs";
      }
      case JobFunction.ACTUARIES: {
        return "Actuaires";
      }
      case JobFunction.ADMINISTRATIVE_SERVICES_MANAGERS: {
        return "Gestionnaires de services administratifs";
      }
      case JobFunction.ADULT_LITERACY_AND_HIGH_SCHOOL_EQUIVALENCY_DIPLOMA_TEACHERS: {
        return "Diphétente des adultes et diplôme d'équivalence des écoles secondaires";
      }
      case JobFunction.ADVERTISING_SALES_AGENTS: {
        return "Agents de vente publicitaire";
      }
      case JobFunction.ADVERTISING_MARKETING_PROMOTIONS_PUBLIC_RELATIONS_AND_SALES_MANAGERS: {
        return "Publicité, marketing, promotions, Relatio public";
      }
      case JobFunction.AEROSPACE_ENGINEERING_AND_OPERATIONS_TECHNICIANS: {
        return "Techniciens d'ingénierie et d'exploitation aérospatiale";
      }
      case JobFunction.AEROSPACE_ENGINEERS: {
        return "Ingénieurs aérospatiaux";
      }
      case JobFunction.AGRICULTURAL_AND_FOOD_SCIENCE_TECHNICIANS: {
        return "Techniciens agricoles et scientifiques alimentaires";
      }
      case JobFunction.AGRICULTURAL_AND_FOOD_SCIENTISTS: {
        return "Scientifiques de l'agriculture et de l'alimentation";
      }
      case JobFunction.AGRICULTURAL_ENGINEERS: {
        return "Ingénieurs agricoles";
      }
      case JobFunction.AGRICULTURAL_WORKERS: {
        return "Travailleurs agricoles";
      }
      case JobFunction.AIR_TRAFFIC_CONTROLLERS: {
        return "Contrôleurs du trafic aérien";
      }
      case JobFunction.AIRCRAFT_AND_AVIONICS_EQUIPMENT_MECHANICS_AND_SERVICE_TECHNICIANS: {
        return "Mécaniques et servages d'avions et avioniques";
      }
      case JobFunction.AIRCRAFT_PILOTS_AND_FLIGHT_ENGINEERS: {
        return "Pilotes d'avions et ingénieurs de vol";
      }
      case JobFunction.ANIMAL_CARE_AND_SERVICE_WORKERS: {
        return "Travailleurs de soins aux animaux et de services";
      }
      case JobFunction.ANNOUNCERS: {
        return "Annonceurs";
      }
      case JobFunction.ANTHROPOLOGISTS_AND_ARCHEOLOGISTS: {
        return "Anthropologues et archéologues";
      }
      case JobFunction.APPRAISERS_AND_ASSESSORS_OF_REAL_ESTATE: {
        return "Évaluateurs et évaluateurs de l'immobilier";
      }
      case JobFunction.ARBITRATORS_MEDIATORS_AND_CONCILIATORS: {
        return "Arbitres, médiateurs et conciliateurs";
      }
      case JobFunction.ARCHITECTS_EXCEPT_LANDSCAPE_AND_NAVAL: {
        return "Architectes, sauf le paysage et la marine";
      }
      case JobFunction.ARCHITECTURAL_AND_ENGINEERING_MANAGERS: {
        return "Gestionnaires d'architecture et d'ingénierie";
      }
      case JobFunction.ARCHIVISTS_CURATORS_AND_MUSEUM_TECHNICIANS: {
        return "Archivistes, conservateurs et techniciens du musée";
      }
      case JobFunction.ART_DIRECTORS: {
        return "Réalisateurs artistiques";
      }
      case JobFunction.ARTISTS_AND_RELATED_WORKERS: {
        return "Artistes et travailleurs connexes";
      }
      case JobFunction.ASSEMBLERS_AND_FABRICATORS: {
        return "Assembleurs et fabricants";
      }
      case JobFunction.ATHLETES_COACHES_UMPIRES_AND_RELATED_WORKERS: {
        return "Athlètes, entraîneurs, arbitres et travailleurs connexes";
      }
      case JobFunction.ATHLETIC_TRAINERS: {
        return "Entraîneurs sportifs";
      }
      case JobFunction.ATMOSPHERIC_SCIENTISTS: {
        return "Scientifiques atmosphériques";
      }
      case JobFunction.AUDIOLOGISTS: {
        return "Audiologistes";
      }
      case JobFunction.AUTOMOTIVE_BODY_AND_RELATED_REPAIRERS: {
        return "Corps automobile et réparateurs connexes";
      }
      case JobFunction.AUTOMOTIVE_SERVICE_TECHNICIANS_AND_MECHANICS: {
        return "Techniciens et mécaniciens de service automobile";
      }
      case JobFunction.BAKERS: {
        return "Boulangers";
      }
      case JobFunction.BARBERS_COSMETOLOGISTS_AND_OTHER_PERSONAL_APPEARANCE_WORKERS: {
        return "Barbers, cosmétologues et autres apparences personnelles";
      }
      case JobFunction.BARTENDERS: {
        return "Barmans";
      }
      case JobFunction.BILL_AND_ACCOUNT_COLLECTORS: {
        return "Factures et collecteurs de compte";
      }
      case JobFunction.BILLING_AND_POSTING_CLERKS_AND_MACHINE_OPERATORS: {
        return "Facturer et afficher des opérateurs de machines";
      }
      case JobFunction.BIOLOGICAL_SCIENTISTS: {
        return "Biologiques";
      }
      case JobFunction.BIOLOGICAL_TECHNICIANS: {
        return "Techniciens biologiques";
      }
      case JobFunction.BIOMEDICAL_ENGINEERS: {
        return "Ingénieurs biomédicaux";
      }
      case JobFunction.BOILERMAKERS: {
        return "Chaudronniers";
      }
      case JobFunction.BOOKBINDERS_AND_BINDERY_WORKERS: {
        return "Bookbinders et travailleurs de reliure";
      }
      case JobFunction.BOOKKEEPING_ACCOUNTING_AND_AUDITING_CLERKS: {
        return "Commis de comptabilité, de comptabilité et d'audit";
      }
      case JobFunction.BRICKMASONS_BLOCKMASONS_AND_STONEMASONS: {
        return "Brickmasons, Blockmasons et Stonemasons";
      }
      case JobFunction.BROADCAST_AND_SOUND_ENGINEERING_TECHNICIANS_AND_RADIO_OPERATORS: {
        return "Techniciens de diffusion et d'ingénierie du son et RA";
      }
      case JobFunction.BROKERAGE_CLERKS: {
        return "Commis de courtage";
      }
      case JobFunction.BUDGET_ANALYSTS: {
        return "Analystes budgétaires";
      }
      case JobFunction.BUILDING_CLEANING_WORKERS: {
        return "Travailleurs de nettoyage de construction";
      }
      case JobFunction.BUS_DRIVERS: {
        return "Conducteurs d'autobus";
      }
      case JobFunction.BUTCHERS: {
        return "Bouchers";
      }
      case JobFunction.CARDIOVASCULAR_TECHNOLOGISTS_AND_TECHNICIANS: {
        return "Technologues et techniciens cardiovasculaires";
      }
      case JobFunction.CAREER_AND_TECHNICAL_EDUCATION_TEACHERS: {
        return "Professeurs d'éducation à la carrière et technique";
      }
      case JobFunction.CARGO_AND_FREIGHT_AGENTS: {
        return "Agents de fret et de fret";
      }
      case JobFunction.CARPENTERS: {
        return "Charpentiers";
      }
      case JobFunction.CARPET_FLOOR_AND_TILE_INSTALLERS_AND_FINISHERS: {
        return "Installateurs et finisseurs de tapis, de sol et de carreaux";
      }
      case JobFunction.CARTOGRAPHERS_AND_PHOTOGRAMMETRISTS: {
        return "Cartographes et photogramétristes";
      }
      case JobFunction.CASHIERS: {
        return "Caissières";
      }
      case JobFunction.CEMENT_MASONS_CONCRETE_FINISHERS_SEGMENTAL_PAVERS_AND_TERRAZZO_WORKERS: {
        return "Masons en ciment, finisseurs en béton, pavé segmentaire";
      }
      case JobFunction.CHEFS_AND_HEAD_COOKS: {
        return "Chefs et cuisiniers de tête";
      }
      case JobFunction.CHEFS_COOKS_AND_FOOD_PREPARATION_WORKERS: {
        return "Chefs, cuisiniers et travailleurs de la préparation des aliments";
      }
      case JobFunction.CHEMICAL_ENGINEERS: {
        return "Ingénieurs chimiques";
      }
      case JobFunction.CHEMICAL_TECHNICIANS: {
        return "Techniciens chimiques";
      }
      case JobFunction.CHEMISTS_AND_MATERIALS_SCIENTISTS: {
        return "Chimistes et scientifiques des matériaux";
      }
      case JobFunction.CHILD_CARE_WORKERS: {
        return "Travailleurs de la garde d'enfants";
      }
      case JobFunction.CHIROPRACTORS: {
        return "Chiropraticiens";
      }
      case JobFunction.CIVIL_ENGINEERING_TECHNICIANS: {
        return "Techniciens de génie civil";
      }
      case JobFunction.CIVIL_ENGINEERS: {
        return "Ingénieurs civils";
      }
      case JobFunction.CLAIMS_ADJUSTERS_APPRAISERS_EXAMINERS_AND_INVESTIGATORS: {
        return "Réclamations, évaluateurs, examinateurs et ENV";
      }
      case JobFunction.CLINICAL_LABORATORY_TECHNOLOGISTS_AND_TECHNICIANS: {
        return "Technologues et techniciens de laboratoire clinique";
      }
      case JobFunction.COACHES_AND_SCOUTS: {
        return "Entraîneurs et scouts";
      }
      case JobFunction.COIN_VENDING_AND_AMUSEMENT_MACHINE_SERVICERS_AND_REPAIRERS: {
        return "Services de machines à pièces, de vente et d'attractions et";
      }
      case JobFunction.COMMERCIAL_AND_INDUSTRIAL_DESIGNERS: {
        return "Designers commerciaux et industriels";
      }
      case JobFunction.COMMERCIAL_MANAGEMENT: {
        return "Gestion commerciale";
      }
      case JobFunction.COMMUNICATIONS_EQUIPMENT_OPERATORS: {
        return "Opérateurs d'équipements de communication";
      }
      case JobFunction.COMPENSATION_AND_BENEFITS_MANAGERS: {
        return "Gestionnaires de rémunération et d'avantages sociaux";
      }
      case JobFunction.COMPENSATION_BENEFITS_AND_JOB_ANALYSIS_SPECIALISTS: {
        return "Spécialités sur la rémunération, les avantages sociaux et l'analyse des emplois";
      }
      case JobFunction.COMPUTER_AND_INFORMATION_RESEARCH_SCIENTISTS: {
        return "RECHERCHE STOCIFICALES D'INFORMATION";
      }
      case JobFunction.COMPUTER_AND_INFORMATION_SYSTEMS_MANAGERS: {
        return "Gestionnaires de systèmes informatiques et d'information";
      }
      case JobFunction.COMPUTER_CONTROL_PROGRAMMERS_AND_OPERATORS: {
        return "Programmeurs et opérateurs de contrôle informatique";
      }
      case JobFunction.COMPUTER_HARDWARE_ENGINEERS: {
        return "Ingénieurs matériels informatiques";
      }
      case JobFunction.COMPUTER_NETWORK_ARCHITECTS: {
        return "Architectes de réseau informatique";
      }
      case JobFunction.COMPUTER_OPERATORS: {
        return "Opérateurs informatiques";
      }
      case JobFunction.COMPUTER_PROGRAMMERS: {
        return "Programmeurs informatiques";
      }
      case JobFunction.COMPUTER_SCIENTISTS_AND_DATABASE_ADMINISTRATORS: {
        return "Informaticiens et administrateurs de la base de données";
      }
      case JobFunction.COMPUTER_SOFTWARE_ENGINEERS: {
        return "Ingénieurs logiciels";
      }
      case JobFunction.COMPUTER_SUPPORT_SPECIALISTS: {
        return "Spécialistes du support informatique";
      }
      case JobFunction.COMPUTER_SUPPORT_SPECIALISTS_AND_SYSTEMS_ADMINISTRATORS: {
        return "Spécialistes d'assistance informatique et administration des systèmes";
      }
      case JobFunction.COMPUTER_SYSTEMS_ANALYSTS: {
        return "Analystes des systèmes informatiques";
      }
      case JobFunction.COMPUTER_AUTOMATED_TELLER_AND_OFFICE_MACHINE_REPAIRERS: {
        return "Ordinateur, caissier automatisé et représentant de la machine de bureau";
      }
      case JobFunction.CONSERVATION_SCIENTISTS_AND_FORESTERS: {
        return "Scientifiques de la conservation et forestiers";
      }
      case JobFunction.CONSTRUCTION_AND_BUILDING_INSPECTORS: {
        return "Inspecteurs de la construction et du bâtiment";
      }
      case JobFunction.CONSTRUCTION_EQUIPMENT_OPERATORS: {
        return "Opérateurs d'équipement de construction";
      }
      case JobFunction.CONSTRUCTION_LABORERS: {
        return "Ouvriers de la construction";
      }
      case JobFunction.CONSTRUCTION_LABORERS_AND_HELPERS: {
        return "Ouvriers de la construction et assistants";
      }
      case JobFunction.CONSTRUCTION_MANAGERS: {
        return "Chefs de construction";
      }
      case JobFunction.COOKS: {
        return "Cuisinier";
      }
      case JobFunction.CORRECTIONAL_OFFICERS: {
        return "Agents correctionnels";
      }
      case JobFunction.CONTRACT_MANAGEMENT: {
        return "Gestion des contrats";
      }
      case JobFunction.CORRECTIONAL_OFFICERS_AND_BAILIFFS: {
        return "Agents correctionnels et huissiers de justice";
      }
      case JobFunction.COST_ESTIMATORS: {
        return "Estimateurs de coûts";
      }
      case JobFunction.COUNSELORS: {
        return "Conseillers";
      }
      case JobFunction.COUNTER_AND_RENTAL_CLERKS: {
        return "Commis de comptoir et de location";
      }
      case JobFunction.COURIERS_AND_MESSENGERS: {
        return "Courriers et messagers";
      }
      case JobFunction.COURT_REPORTERS: {
        return "Journalistes des tribunaux";
      }
      case JobFunction.CRAFT_AND_FINE_ARTISTS: {
        return "Artisanat et beaux artistes";
      }
      case JobFunction.CREDIT_AUTHORIZERS_CHECKERS_AND_CLERKS: {
        return "Autorisateurs de crédit, vérificateurs et commis";
      }
      case JobFunction.CUSTOMER_SERVICE_REPRESENTATIVES: {
        return "Représentants Service à la clientèle";
      }
      case JobFunction.DANCERS_AND_CHOREOGRAPHERS: {
        return "Danseurs et chorégraphes";
      }
      case JobFunction.DATA_ENTRY_AND_INFORMATION_PROCESSING_WORKERS: {
        return "Travailleurs de saisie des données et de traitement de l'information";
      }
      case JobFunction.DATABASE_ADMINISTRATORS: {
        return "Administrateurs de base de données";
      }
      case JobFunction.DELIVERY_TRUCK_DRIVERS_AND_DRIVERSALES_WORKERS: {
        return "Conducteurs de camions de livraison et travailleurs des conducteurs / ventes";
      }
      case JobFunction.DEMONSTRATORS_PRODUCT_PROMOTERS_AND_MODELS: {
        return "Démonstrateurs, promoteurs de produits et modèles";
      }
      case JobFunction.DENTAL_AND_OPHTHALMIC_LABORATORY_TECHNICIANS_AND_MEDICAL_APPLIANCE_TECHNICIANS: {
        return "Techniciens de laboratoire dentaire et ophtalmique et m";
      }
      case JobFunction.DENTAL_ASSISTANTS: {
        return "Assistants dentaires";
      }
      case JobFunction.DENTAL_HYGIENISTS: {
        return "Hygiénistes dentaires";
      }
      case JobFunction.DENTISTS: {
        return "Dentistes";
      }
      case JobFunction.DESKTOP_PUBLISHERS: {
        return "Éditeurs de bureau";
      }
      case JobFunction.DIAGNOSTIC_MEDICAL_SONOGRAPHERS: {
        return "Échographistes médicaux diagnostiques";
      }
      case JobFunction.DIESEL_SERVICE_TECHNICIANS_AND_MECHANICS: {
        return "Techniciens et mécaniciens de service diesel";
      }
      case JobFunction.DIETITIANS_AND_NUTRITIONISTS: {
        return "Diététiciens et nutritionnistes";
      }
      case JobFunction.DISPATCHERS: {
        return "Répartiteurs";
      }
      case JobFunction.DRAFTERS: {
        return "Rédacteurs";
      }
      case JobFunction.DRYWALL_AND_CEILING_TILE_INSTALLERS_AND_TAPERS: {
        return "Installateurs de carreaux de cloisons sèches et de plafond et se rétrécir";
      }
      case JobFunction.DRYWALL_INSTALLERS_CEILING_TILE_INSTALLERS_AND_TAPERS: {
        return "Installateurs de cloisons sèches, installateurs de carreaux de plafond et t";
      }
      case JobFunction.ECONOMISTS: {
        return "Économistes";
      }
      case JobFunction.EDITORS: {
        return "Éditeurs";
      }
      case JobFunction.EDUCATION_ADMINISTRATORS: {
        return "Administrateurs de l'éducation";
      }
      case JobFunction.ELECTRICAL_AND_ELECTRONICS_ENGINEERING_TECHNICIANS: {
        return "Techniciens d'ingénierie électrique et électronique";
      }
      case JobFunction.ELECTRICAL_AND_ELECTRONICS_ENGINEERS: {
        return "Ingénieurs électriques et électroniques";
      }
      case JobFunction.ELECTRICAL_AND_ELECTRONICS_INSTALLERS_AND_REPAIRERS: {
        return "Installateurs et réparateurs électriques et électroniques";
      }
      case JobFunction.ELECTRICIANS: {
        return "Électriciens";
      }
      case JobFunction.ELECTRO_MECHANICAL_TECHNICIANS: {
        return "Techniciens électro-mécaniques";
      }
      case JobFunction.ELECTRONIC_HOME_ENTERTAINMENT_EQUIPMENT_INSTALLERS_AND_REPAIRERS: {
        return "Installateurs électroniques d'équipement de divertissement à domicile";
      }
      case JobFunction.ELEMENTARY_MIDDLE_AND_HIGH_SCHOOL_PRINCIPALS: {
        return "Principaux de l'école primaire, du collège et du secondaire";
      }
      case JobFunction.ELEVATOR_INSTALLERS_AND_REPAIRERS: {
        return "Installateurs et réparateurs d'ascenseurs";
      }
      case JobFunction.EMERGENCY_MANAGEMENT_DIRECTORS: {
        return "Directeurs de gestion des urgences";
      }
      case JobFunction.EMERGENCY_MEDICAL_TECHNICIANS_AND_PARAMEDICS: {
        return "Techniciens médicaux d'urgence et ambulanciers paramédicaux";
      }
      case JobFunction.EMTS_AND_PARAMEDICS: {
        return "EMTS et ambulanciers paramédicaux";
      }
      case JobFunction.ENGINEERING_AND_NATURAL_SCIENCES_MANAGERS: {
        return "Gestionnaires d'ingénierie et de sciences naturelles";
      }
      case JobFunction.ENGINEERING_TECHNICIANS: {
        return "Techniciens d'ingénierie";
      }
      case JobFunction.ENVIRONMENTAL_ENGINEERING_TECHNICIANS: {
        return "Techniciens d'ingénierie environnementale";
      }
      case JobFunction.ENVIRONMENTAL_ENGINEERS: {
        return "Ingénieurs environnementaux";
      }
      case JobFunction.ENVIRONMENTAL_SCIENCE_AND_PROTECTION_TECHNICIANS: {
        return "Techniciens en sciences de l'environnement et de la protection";
      }
      case JobFunction.ENVIRONMENTAL_SCIENTISTS_AND_HYDROLOGISTS: {
        return "Scientifiques de l'environnement et hydrologues";
      }
      case JobFunction.ENVIRONMENTAL_SCIENTISTS_AND_SPECIALISTS: {
        return "Scientifiques de l'environnement et spécialistes";
      }
      case JobFunction.EPIDEMIOLOGISTS: {
        return "Épidémiologistes";
      }
      case JobFunction.EXERCISE_PHYSIOLOGISTS: {
        return "Physiologistes de l'exercice";
      }
      case JobFunction.FARMERS_RANCHERS_AND_AGRICULTURAL_MANAGERS: {
        return "Agriculteurs, éleveurs et gestionnaires agricoles";
      }
      case JobFunction.FARMERS_RANCHERS_AND_OTHER_AGRICULTURAL_MANAGERS: {
        return "Agriculteurs, éleveurs et autres gestionnaires agricoles";
      }
      case JobFunction.FASHION_DESIGNERS: {
        return "Créateurs de mode";
      }
      case JobFunction.FILE_CLERKS: {
        return "Clercs de dossier";
      }
      case JobFunction.FILM_AND_VIDEO_EDITORS_AND_CAMERA_OPERATORS: {
        return "Éditeurs de films et vidéo et opérateurs de caméras";
      }
      case JobFunction.FINANCIAL_ANALYSTS: {
        return "Analystes financiers";
      }
      case JobFunction.FINANCIAL_ANALYSTS_AND_PERSONAL_FINANCIAL_ADVISORS: {
        return "Analystes financiers et conseillers financiers personnels";
      }
      case JobFunction.FINANCIAL_CLERKS: {
        return "Commis financiers";
      }
      case JobFunction.FINANCIAL_EXAMINERS: {
        return "Examinateurs financiers";
      }
      case JobFunction.FINANCIAL_MANAGERS: {
        return "Gestionnaires financiers";
      }
      case JobFunction.FIRE_FIGHTING_OCCUPATIONS: {
        return "Occupations de lutte contre l'incendie";
      }
      case JobFunction.FIRE_INSPECTORS: {
        return "Inspecteurs d'incendie";
      }
      case JobFunction.FIREFIGHTERS: {
        return "Sapeurs pompiers";
      }
      case JobFunction.FISHERS_AND_FISHING_VESSEL_OPERATORS: {
        return "Pêcheurs et opérateurs de navires de pêche";
      }
      case JobFunction.FISHING_AND_HUNTING_WORKERS: {
        return "Travailleurs de pêche et de chasse";
      }
      case JobFunction.FITNESS_TRAINERS_AND_INSTRUCTORS: {
        return "DESSOINS ET INSTRUCTEURS DE FITNESS";
      }
      case JobFunction.FITNESS_WORKERS: {
        return "Travailleurs du fitness";
      }
      case JobFunction.FLIGHT_ATTENDANTS: {
        return "Hôtesses de l'air";
      }
      case JobFunction.FLOORING_INSTALLERS_AND_TILE_AND_MARBLE_SETTERS: {
        return "Installateurs de revêtements de sol et setteurs de carreaux et de marbre";
      }
      case JobFunction.FLORAL_DESIGNERS: {
        return "Designers floraux";
      }
      case JobFunction.FOOD_AND_BEVERAGE_SERVING_AND_RELATED_WORKERS: {
        return "Service de nourriture et de boissons et travailleurs connexes";
      }
      case JobFunction.FOOD_AND_TOBACCO_PROCESSING_WORKERS: {
        return "Travailleurs de la transformation de la nourriture et du tabac";
      }
      case JobFunction.FOOD_PROCESSING_OCCUPATIONS: {
        return "Occupations de transformation des aliments";
      }
      case JobFunction.FOOD_SERVICE_MANAGERS: {
        return "Gestionnaires des services alimentaires";
      }
      case JobFunction.FORENSIC_SCIENCE_TECHNICIANS: {
        return "Techniciens de médecine légale";
      }
      case JobFunction.FOREST_AND_CONSERVATION_WORKERS: {
        return "Travailleurs de la forêt et de la conservation";
      }
      case JobFunction.FOREST_CONSERVATION_AND_LOGGING_WORKERS: {
        return "Travailleurs de la forêt, de la conservation et de l'exploitation forestière";
      }
      case JobFunction.FUNDRAISERS: {
        return "Collecte de fonds";
      }
      case JobFunction.FUNERAL_DIRECTORS: {
        return "Directeurs funéraires";
      }
      case JobFunction.FUNERAL_SERVICE_WORKERS: {
        return "Travailleurs des funérailles";
      }
      case JobFunction.GAMING_CAGE_WORKERS: {
        return "Travailleurs de la cage de jeu";
      }
      case JobFunction.GAMING_SERVICES_OCCUPATIONS: {
        return "Professions des services de jeu";
      }
      case JobFunction.GAMING_SERVICES_WORKERS: {
        return "Travailleurs des services de jeu";
      }
      case JobFunction.GENERAL_MAINTENANCE_AND_REPAIR_WORKERS: {
        return "Entretien général et agents de réparation";
      }
      case JobFunction.GENERAL_OFFICE_CLERKS: {
        return "Commis de bureau général";
      }
      case JobFunction.GENETIC_COUNSELORS: {
        return "Conseillers génétiques";
      }
      case JobFunction.GEOGRAPHERS: {
        return "Géographes";
      }
      case JobFunction.GEOLOGICAL_AND_PETROLEUM_TECHNICIANS: {
        return "Techniciens géologiques et pétroliers";
      }
      case JobFunction.GEOSCIENTISTS: {
        return "Géoscientifiques";
      }
      case JobFunction.GLAZIERS: {
        return "Vitrines";
      }
      case JobFunction.GRAPHIC_DESIGNERS: {
        return "Graphistes";
      }
      case JobFunction.GROUNDS_MAINTENANCE_WORKERS: {
        return "Travailleurs de l'entretien des terrains";
      }
      case JobFunction.HAND_LABORERS_AND_MATERIAL_MOVERS: {
        return "Ouvriers de la main et déménageurs";
      }
      case JobFunction.HAZARDOUS_MATERIALS_REMOVAL_WORKERS: {
        return "Travailleurs d'élimination des matières dangereuses";
      }
      case JobFunction.HEALTH_AND_SAFETY_ENGINEERS: {
        return "Ingénieurs de la santé et de la sécurité";
      }
      case JobFunction.HEALTH_EDUCATORS: {
        return "Éducateurs en santé";
      }
      case JobFunction.HEALTH_EDUCATORS_AND_COMMUNITY_HEALTH_WORKERS: {
        return "Les éducateurs en santé et les agents de santé communautaires";
      }
      case JobFunction.HEATING_AIR_CONDITIONING_AND_REFRIGERATION_MECHANICS_AND_INSTALLERS: {
        return "Chauffage, climatisation et réfrigération mecha";
      }
      case JobFunction.HEAVY_AND_TRACTOR_TRAILER_TRUCK_DRIVERS: {
        return "Chauffeurs de camions lourds et semi-remorque";
      }
      case JobFunction.HEAVY_VEHICLE_AND_MOBILE_EQUIPMENT_SERVICE_TECHNICIANS: {
        return "Technique de service d'équipement de véhicules lourds et mobile";
      }
      case JobFunction.HEAVY_VEHICLE_AND_MOBILE_EQUIPMENT_SERVICE_TECHNICIANS_AND_MECHANICS: {
        return "Technique de service d'équipement de véhicules lourds et mobile";
      }
      case JobFunction.HIGH_SCHOOL_TEACHERS: {
        return "Enseignants du secondaire";
      }
      case JobFunction.HISTORIANS: {
        return "Historiens";
      }
      case JobFunction.HOME_APPLIANCE_REPAIRERS: {
        return "Réparation des appareils électroménagers";
      }
      case JobFunction.HOME_HEALTH_AIDES_AND_PERSONAL_CARE_AIDES: {
        return "Aides de santé à domicile et soins personnels";
      }
      case JobFunction.HOTEL_MOTEL_AND_RESORT_DESK_CLERKS: {
        return "Commis de bureau d'hôtel, de motel et de complexe";
      }
      case JobFunction.HUMAN_RESOURCES_ASSISTANTS_EXCEPT_PAYROLL_AND_TIMEKEEPING: {
        return "Assistants des ressources humaines, sauf la paie et Tim";
      }
      case JobFunction.HUMAN_RESOURCES_MANAGERS: {
        return "Gestionnaires des ressources humaines";
      }
      case JobFunction.HUMAN_RESOURCES_SPECIALISTS: {
        return "Spécialistes des ressources humaines";
      }
      case JobFunction.HUMAN_RESOURCES_TRAINING_AND_LABOR_RELATIONS_MANAGERS_AND_SPECIALISTS: {
        return "Ressources humaines, formation et relations de travail";
      }
      case JobFunction.HYDROLOGISTS: {
        return "Hydrologues";
      }
      case JobFunction.INDUSTRIAL_DESIGNERS: {
        return "Designers industriels";
      }
      case JobFunction.INDUSTRIAL_ENGINEERING_TECHNICIANS: {
        return "Techniciens d'ingénierie industrielle";
      }
      case JobFunction.INDUSTRIAL_ENGINEERS: {
        return "Ingénieurs industriels";
      }
      case JobFunction.INDUSTRIAL_MACHINERY_MECHANICS_AND_MAINTENANCE_WORKERS: {
        return "Mécanique et maintenance des machines industrielles";
      }
      case JobFunction.INDUSTRIAL_PRODUCTION_MANAGERS: {
        return "Chefs de production industrielle";
      }
      case JobFunction.INFORMATION_CLERKS: {
        return "Commis d'information";
      }
      case JobFunction.INFORMATION_SECURITY_ANALYSTS: {
        return "Analystes de la sécurité de l'information";
      }
      case JobFunction.INSPECTORS_TESTERS_SORTERS_SAMPLERS_AND_WEIGHERS: {
        return "Inspecteurs, testeurs, trieurs, échantillonneurs et peser";
      }
      case JobFunction.INSTRUCTIONAL_COORDINATORS: {
        return "Coordinateurs pédagogiques";
      }
      case JobFunction.INSULATION_WORKERS: {
        return "Travailleurs de l'isolation";
      }
      case JobFunction.INSURANCE_SALES_AGENTS: {
        return "Agents de vente d'assurance";
      }
      case JobFunction.INSURANCE_UNDERWRITERS: {
        return "Souscripteurs d'assurance";
      }
      case JobFunction.INTERIOR_DESIGNERS: {
        return "Designers d'intérieur";
      }
      case JobFunction.INTERPRETERS_AND_TRANSLATORS: {
        return "Interprètes et traducteurs";
      }
      case JobFunction.IRONWORKERS: {
        return "Ferronneurs";
      }
      case JobFunction.JEWELERS_AND_PRECIOUS_STONE_AND_METAL_WORKERS: {
        return "Bijoutiers et en pierre précieuse et en métal";
      }
      case JobFunction.JOB_OPPORTUNITIES_IN_THE_ARMED_FORCES: {
        return "Opportunités d'emploi dans les forces armées";
      }
      case JobFunction.JUDGES_AND_HEARING_OFFICERS: {
        return "Juges et agents d'audience";
      }
      case JobFunction.JUDGES_MAGISTRATES_AND_OTHER_JUDICIAL_WORKERS: {
        return "Juges, magistrats et autres travailleurs judiciaires";
      }
      case JobFunction.KINDERGARTEN_AND_ELEMENTARY_SCHOOL_TEACHERS: {
        return "Enseignants de la maternelle et des écoles primaires";
      }
      case JobFunction.LABOR_RELATIONS_SPECIALISTS: {
        return "Spécialistes des relations de travail";
      }
      case JobFunction.LANDSCAPE_ARCHITECTS: {
        return "Architectes paysagistes";
      }
      case JobFunction.LAWYERS: {
        return "Avocats";
      }
      case JobFunction.LIBRARIANS: {
        return "Bibliothécaires";
      }
      case JobFunction.LIBRARY_TECHNICIANS_AND_ASSISTANTS: {
        return "Techniciens et assistants de la bibliothèque";
      }
      case JobFunction.LICENSED_PRACTICAL_AND_LICENSED_VOCATIONAL_NURSES: {
        return "Infirmières professionnelles pratiques et agréées sous licence";
      }
      case JobFunction.LINE_INSTALLERS_AND_REPAIRERS: {
        return "Installateurs et réparateurs de ligne";
      }
      case JobFunction.LOAN_OFFICERS: {
        return "Agents de prêt";
      }
      case JobFunction.LODGING_MANAGERS: {
        return "Responsables de l'hébergement";
      }
      case JobFunction.LOGGING_WORKERS: {
        return "Journalisants";
      }
      case JobFunction.LOGISTICIANS: {
        return "Logistiqueniens";
      }
      case JobFunction.MACHINE_SETTERS_OPERATORS_AND_TENDERS_METAL_AND_PLASTIC: {
        return "Sets de machines, opérateurs et appels d'offres-métal et";
      }
      case JobFunction.MACHINISTS: {
        return "Machinistes";
      }
      case JobFunction.MACHINISTS_AND_TOOL_AND_DIE_MAKERS: {
        return "Machinistes et outils et les fabricants";
      }
      case JobFunction.MAINTENANCE_AND_REPAIR_WORKERS_GENERAL: {
        return "Travailleurs d'entretien et des réparations, général";
      }
      case JobFunction.MANAGEMENT_ANALYSTS: {
        return "Analystes de gestion";
      }
      case JobFunction.MANICURISTS_AND_PEDICURISTS: {
        return "Manucureurs et pédicureurs";
      }
      case JobFunction.MARINE_ENGINEERS_AND_NAVAL_ARCHITECTS: {
        return "Ingénieurs marins et architectes navals";
      }
      case JobFunction.MARKET_AND_SURVEY_RESEARCHERS: {
        return "Chercheurs sur le marché et le sondage";
      }
      case JobFunction.MARKET_RESEARCH_ANALYSTS: {
        return "Analystes d'étude de marché";
      }
      case JobFunction.MARRIAGE_AND_FAMILY_THERAPISTS: {
        return "Thérapeutes du mariage et familiaux";
      }
      case JobFunction.MASSAGE_THERAPISTS: {
        return "Massothérapeutes";
      }
      case JobFunction.MATERIAL_MOVING_MACHINE_OPERATORS: {
        return "Opérateurs de machines en mouvement de matériau";
      }
      case JobFunction.MATERIAL_MOVING_OCCUPATIONS: {
        return "Professions en mouvement des matériaux";
      }
      case JobFunction.MATERIAL_RECORDING_CLERKS: {
        return "Clerks d'enregistrement matériel";
      }
      case JobFunction.MATERIALS_ENGINEERS: {
        return "Ingénieurs de matériaux";
      }
      case JobFunction.MATHEMATICIANS: {
        return "Mathématiciens";
      }
      case JobFunction.MATHEMATICIANS_AND_STATISTICIANS: {
        return "Mathématiciens et statisticiens";
      }
      case JobFunction.MECHANICAL_ENGINEERING_TECHNICIANS: {
        return "Techniciens d'ingénierie mécanique";
      }
      case JobFunction.MECHANICAL_ENGINEERS: {
        return "Ingénieurs mécaniques";
      }
      case JobFunction.MEDICAL_AND_CLINICAL_LABORATORY_TECHNOLOGISTS_AND_TECHNICIANS: {
        return "Technologues de laboratoire médical et clinique et";
      }
      case JobFunction.MEDICAL_AND_HEALTH_SERVICES_MANAGERS: {
        return "Gestionnaires des services médicaux et de santé";
      }
      case JobFunction.MEDICAL_ASSISTANTS: {
        return "Assistants médicaux";
      }
      case JobFunction.MEDICAL_EQUIPMENT_REPAIRERS: {
        return "Réparateurs d'équipements médicaux";
      }
      case JobFunction.MEDICAL_RECORDS_AND_HEALTH_INFORMATION_TECHNICIANS: {
        return "Dossiers médicaux et techniciens de l'information sur la santé";
      }
      case JobFunction.MEDICAL_SCIENTISTS: {
        return "Médicaux";
      }
      case JobFunction.MEDICAL_TRANSCRIPTIONISTS: {
        return "Transcriptives médicales";
      }
      case JobFunction.MEDICAL_DENTAL_AND_OPHTHALMIC_LABORATORY_TECHNICIANS: {
        return "Technique de laboratoire médical, dentaire et ophtalmique";
      }
      case JobFunction.MEETING_AND_CONVENTION_PLANNERS: {
        return "Planificateurs de réunion et de congrès";
      }
      case JobFunction.MEETING_CONVENTION_AND_EVENT_PLANNERS: {
        return "Réunion, congrès et planificateurs d'événements";
      }
      case JobFunction.METAL_AND_PLASTIC_MACHINE_WORKERS: {
        return "Travailleurs de la machine en métal et en plastique";
      }
      case JobFunction.MICROBIOLOGISTS: {
        return "Microbiologistes";
      }
      case JobFunction.MIDDLE_SCHOOL_TEACHERS: {
        return "Enseignants du collège";
      }
      case JobFunction.MILITARY_CAREERS: {
        return "Carrières militaires";
      }
      case JobFunction.MILLWRIGHTS: {
        return "Mélites";
      }
      case JobFunction.MINING_AND_GEOLOGICAL_ENGINEERS: {
        return "Ingénieurs miniers et géologiques";
      }
      case JobFunction.MODELS: {
        return "Des modèles";
      }
      case JobFunction.METER_READERS_UTILITIES: {
        return "Lecteurs de compteurs, services publics";
      }
      case JobFunction.MULTIMEDIA_ARTISTS_AND_ANIMATORS: {
        return "Artistes et animateurs multimédias";
      }
      case JobFunction.MUSIC_DIRECTORS_AND_COMPOSERS: {
        return "Directeurs de musique et compositeurs";
      }
      case JobFunction.MUSICIANS_AND_SINGERS: {
        return "Musiciens et chanteurs";
      }
      case JobFunction.MUSICIANS_SINGERS_AND_RELATED_WORKERS: {
        return "Musiciens, chanteurs et travailleurs connexes";
      }
      case JobFunction.NATURAL_SCIENCES_MANAGERS: {
        return "Gestionnaires des sciences naturelles";
      }
      case JobFunction.NETWORK_AND_COMPUTER_SYSTEMS_ADMINISTRATORS: {
        return "Administrateurs de systèmes de réseau et informatique";
      }
      case JobFunction.NEWS_ANALYSTS_REPORTERS_AND_CORRESPONDENTS: {
        return "Analystes de nouvelles, journalistes et correspondants";
      }
      case JobFunction.NUCLEAR_ENGINEERS: {
        return "Ingénieurs nucléaires";
      }
      case JobFunction.NUCLEAR_MEDICINE_TECHNOLOGISTS: {
        return "Technologues en médecine nucléaire";
      }
      case JobFunction.NUCLEAR_TECHNICIANS: {
        return "Techniciens nucléaires";
      }
      case JobFunction.NURSE_ANESTHETISTS_NURSE_MIDWIVES_AND_NURSE_PRACTITIONERS: {
        return "Infirmières anesthésistes, infirmières sages-femmes et infirmière prac";
      }
      case JobFunction.NURSING_ASSISTANTS_AND_ORDERLIES: {
        return "Assistants infirmiers et garnisses";
      }
      case JobFunction.NURSING_PSYCHIATRIC_AND_HOME_HEALTH_AIDES: {
        return "Infirmières, psychiatriques et aides à domicile";
      }
      case JobFunction.OCCUPATIONAL_HEALTH_AND_SAFETY_SPECIALISTS_AND_TECHNICIANS: {
        return "Spécialistes de la santé et de la sécurité au travail et TEC";
      }
      case JobFunction.OCCUPATIONAL_THERAPIST_ASSISTANTS_AND_AIDES: {
        return "Assistants et aides à l'ergothérapeute";
      }
      case JobFunction.OCCUPATIONAL_THERAPISTS: {
        return "Ergothérapeutes";
      }
      case JobFunction.OCCUPATIONAL_THERAPY_ASSISTANTS_AND_AIDES: {
        return "Assistants et assistants en ergothérapie";
      }
      case JobFunction.OFFICE_AND_ADMINISTRATIVE_SUPPORT_WORKER_SUPERVISORS_AND_MANAGERS: {
        return "Superviso des travailleurs de soutien aux bureaux et administratifs";
      }
      case JobFunction.OFFICE_CLERKS_GENERAL: {
        return "Commis de bureau, général";
      }
      case JobFunction.OPERATIONS_RESEARCH_ANALYSTS: {
        return "Analystes de la recherche opérationnelle";
      }
      case JobFunction.OPTICIANS: {
        return "Opticiens";
      }
      case JobFunction.OPTICIANS_DISPENSING: {
        return "Opticiens, distribution";
      }
      case JobFunction.OPTOMETRISTS: {
        return "Optométristes";
      }
      case JobFunction.ORDER_CLERKS: {
        return "Commis d'ordonnance";
      }
      case JobFunction.ORTHOTISTS_AND_PROSTHETISTS: {
        return "Orthotistes et prothétistes";
      }
      case JobFunction.PAINTERS_AND_PAPERHANGERS: {
        return "Peintres et paperhangers";
      }
      case JobFunction.PAINTERS_CONSTRUCTION_AND_MAINTENANCE: {
        return "Peintres, construction et entretien";
      }
      case JobFunction.PAINTING_AND_COATING_WORKERS: {
        return "Travailleurs de peinture et de revêtement";
      }
      case JobFunction.PAINTING_AND_COATING_WORKERS_EXCEPT_CONSTRUCTION_AND_MAINTENANCE: {
        return "Travailleurs de peinture et de revêtement, sauf la construction";
      }
      case JobFunction.PARALEGALS_AND_LEGAL_ASSISTANTS: {
        return "Parajuristes et assistants juridiques";
      }
      case JobFunction.PAYROLL_AND_TIMEKEEPING_CLERKS: {
        return "Clercs de paie et de chronomètre";
      }
      case JobFunction.PERSONAL_AND_HOME_CARE_AIDES: {
        return "Aides de soins personnels et à domicile";
      }
      case JobFunction.PERSONAL_FINANCIAL_ADVISORS: {
        return "Conseillers financiers personnels";
      }
      case JobFunction.PEST_CONTROL_WORKERS: {
        return "Travailleurs de la lutte antiparasitaire";
      }
      case JobFunction.PETROLEUM_ENGINEERS: {
        return "Ingénieurs pétroliers";
      }
      case JobFunction.PHARMACISTS: {
        return "Pharmaciens";
      }
      case JobFunction.PHARMACY_AIDES: {
        return "Aides à la pharmacie";
      }
      case JobFunction.PHARMACY_TECHNICIANS: {
        return "Techniciens de pharmacie";
      }
      case JobFunction.PHLEBOTOMISTS: {
        return "Phlébotomistes";
      }
      case JobFunction.PHOTOGRAPHERS: {
        return "Photographes";
      }
      case JobFunction.PHYSICAL_THERAPIST_ASSISTANTS_AND_AIDES: {
        return "Assistants et assistants physiothérapeutes";
      }
      case JobFunction.PHYSICAL_THERAPISTS: {
        return "Physiothérapeutes";
      }
      case JobFunction.PHYSICIAN_ASSISTANTS: {
        return "Assistants médicaux";
      }
      case JobFunction.PHYSICIANS_AND_SURGEONS: {
        return "Médecins et chirurgiens";
      }
      case JobFunction.PHYSICISTS_AND_ASTRONOMERS: {
        return "Physiciens et astronomes";
      }
      case JobFunction.PIPELAYERS_PLUMBERS_PIPEFITTERS_AND_STEAMFITTERS: {
        return "Pipelayers, plombiers, pipetters et fitteur à vapeur";
      }
      case JobFunction.PHOTOGRAPHIC_PROCESS_WORKERS_AND_PROCESSING_MACHINE_OPERATORS: {
        return "Travailleurs de processus photographiques et Machin de traitement";
      }
      case JobFunction.PLUMBERS_PIPEFITTERS_AND_STEAMFITTERS: {
        return "Plombiers, tuyaux et vapeur";
      }
      case JobFunction.PODIATRISTS: {
        return "Podiatres";
      }
      case JobFunction.POLICE_AND_DETECTIVES: {
        return "Police et détectives";
      }
      case JobFunction.POLICE_FIRE_AND_AMBULANCE_DISPATCHERS: {
        return "Dispatcheurs de police, d'incendie et d'ambulance";
      }
      case JobFunction.POLITICAL_SCIENTISTS: {
        return "Politologues";
      }
      case JobFunction.POSTAL_SERVICE_WORKERS: {
        return "Travailleurs des services postaux";
      }
      case JobFunction.POSTSECONDARY_EDUCATION_ADMINISTRATORS: {
        return "Administrateurs de l'enseignement postsecondaire";
      }
      case JobFunction.POSTSECONDARY_TEACHERS: {
        return "Enseignants postsecondaires";
      }
      case JobFunction.PLASTERERS_AND_STUCCO_MASONS: {
        return "Plâtriers et maçons de stuc";
      }
      case JobFunction.POWER_PLANT_OPERATORS_DISTRIBUTORS_AND_DISPATCHERS: {
        return "Opérateurs de centrales électriques, distributeurs et répartir";
      }
      case JobFunction.PRESCHOOL_AND_CHILDCARE_CENTER_DIRECTORS: {
        return "Directeurs du centre préscolaire et de garde d'enfants";
      }
      case JobFunction.PRESCHOOL_TEACHERS: {
        return "Enseignants préscolaires";
      }
      case JobFunction.PRIVATE_DETECTIVES_AND_INVESTIGATORS: {
        return "Détectives et enquêteurs privés";
      }
      case JobFunction.PROBATION_OFFICERS_AND_CORRECTIONAL_TREATMENT_SPECIALISTS: {
        return "Agents de probation et spécifications de traitement correctionnel";
      }
      case JobFunction.PROCUREMENT_CLERKS: {
        return "Commis en matière d'approvisionnement";
      }
      case JobFunction.PROCUREMENT_MANAGER: {
        return "Responsable des achats";
      }
      case JobFunction.PRODUCERS_AND_DIRECTORS: {
        return "Producteurs et réalisateurs";
      }
      case JobFunction.PRODUCTION_PLANNING_AND_EXPEDITING_CLERKS: {
        return "Production, planification et accélérer les commis";
      }
      case JobFunction.PROPERTY_REAL_ESTATE_AND_COMMUNITY_ASSOCIATION_MANAGERS: {
        return "Propriété, immobilier et association communautaire m";
      }
      case JobFunction.PRECISION_INSTRUMENT_AND_EQUIPMENT_REPAIRERS: {
        return "Instruments de précision et réparateurs d'équipement";
      }
      case JobFunction.PSYCHIATRIC_TECHNICIANS_AND_AIDES: {
        return "Techniciens et aides psychiatriques";
      }
      case JobFunction.PREPRESS_TECHNICIANS_AND_WORKERS: {
        return "Techniciens et travailleurs de la prépression";
      }
      case JobFunction.PSYCHOLOGISTS: {
        return "Psychologues";
      }
      case JobFunction.PUBLIC_RELATIONS_AND_FUNDRAISING_MANAGERS: {
        return "Gestionnaires de relations publiques et de collecte de fonds";
      }
      case JobFunction.PUBLIC_RELATIONS_SPECIALISTS: {
        return "Spécialistes des relations publiques";
      }
      case JobFunction.PURCHASING_MANAGERS_BUYERS_AND_PURCHASING_AGENTS: {
        return "Les gestionnaires d'achat, les acheteurs et les agents d'achat";
      }
      case JobFunction.PRINTING_MACHINE_OPERATORS: {
        return "Opérateurs d'imprimerie";
      }
      case JobFunction.QUALITY_CONTROL_INSPECTORS: {
        return "Inspecteurs du contrôle de la qualité";
      }
      case JobFunction.RADIATION_THERAPISTS: {
        return "Radiothérapeutes";
      }
      case JobFunction.RADIO_AND_TELECOMMUNICATIONS_EQUIPMENT_INSTALLERS_AND_REPAIRERS: {
        return "Installateurs d'équipements de radio et de télécommunications";
      }
      case JobFunction.RADIOLOGIC_AND_MRI_TECHNOLOGISTS: {
        return "Technologues radiologiques et IRM";
      }
      case JobFunction.RADIOLOGIC_TECHNOLOGISTS_AND_TECHNICIANS: {
        return "Technologues et techniciens radiologiques";
      }
      case JobFunction.RAIL_TRANSPORTATION_OCCUPATIONS: {
        return "Professions du transport ferroviaire";
      }
      case JobFunction.RAILROAD_WORKERS: {
        return "Chemins de chemin de fer";
      }
      case JobFunction.REAL_ESTATE_BROKERS_AND_SALES_AGENTS: {
        return "Brokers immobiliers et agents de vente";
      }
      case JobFunction.RECEPTIONISTS: {
        return "Réceptionnistes";
      }
      case JobFunction.RECEPTIONISTS_AND_INFORMATION_CLERKS: {
        return "Réceptionnistes et commis d'information";
      }
      case JobFunction.RECREATION_WORKERS: {
        return "Employés récréatifs";
      }
      case JobFunction.RECREATIONAL_THERAPISTS: {
        return "Thérapeutes récréatifs";
      }
      case JobFunction.REGISTERED_NURSES: {
        return "Infirmières autorisées";
      }
      case JobFunction.REHABILITATION_COUNSELORS: {
        return "Conseillers en réadaptation";
      }
      case JobFunction.REPORTERS_CORRESPONDENTS_AND_BROADCAST_NEWS_ANALYSTS: {
        return "Journalistes, correspondants et nouvelles de diffusion anal";
      }
      case JobFunction.RESERVATION_AND_TRANSPORTATION_TICKET_AGENTS_AND_TRAVEL_CLERKS: {
        return "Agents de ticket de réservation et de transport et t";
      }
      case JobFunction.RESPIRATORY_THERAPISTS: {
        return "Thérapeutes respiratoires";
      }
      case JobFunction.RETAIL_SALES_WORKERS: {
        return "Travailleurs de la vente au détail";
      }
      case JobFunction.RETAIL_SALESPERSONS: {
        return "Vendeurs de détail";
      }
      case JobFunction.ROOFERS: {
        return "Cacheurs";
      }
      case JobFunction.SALES_ENGINEERS: {
        return "Ingénieurs commerciaux";
      }
      case JobFunction.SALES_MANAGERS: {
        return "Directeurs commerciaux";
      }
      case JobFunction.SALES_REPRESENTATIVES_WHOLESALE_AND_MANUFACTURING: {
        return "Représentants commerciaux, en gros et fabrication";
      }
      case JobFunction.SCHOOL_AND_CAREER_COUNSELORS: {
        return "Conseillers scolaires et carrière";
      }
      case JobFunction.SECRETARIES_AND_ADMINISTRATIVE_ASSISTANTS: {
        return "Secrétaires et assistants administratifs";
      }
      case JobFunction.SECURITIES_COMMODITIES_AND_FINANCIAL_SERVICES_SALES_AGENTS: {
        return "Titres, produits de base et services financiers SA";
      }
      case JobFunction.SECURITY_GUARDS_AND_GAMING_SURVEILLANCE_OFFICERS: {
        return "Agents de sécurité et agents de surveillance des jeux";
      }
      case JobFunction.SHEET_METAL_WORKERS: {
        return "Tôlerie";
      }
      case JobFunction.SHIPPING_RECEIVING_AND_TRAFFIC_CLERKS: {
        return "Commis d'expédition, de réception et de circulation";
      }
      case JobFunction.SKINCARE_SPECIALISTS: {
        return "Spécialistes des soins de la peau";
      }
      case JobFunction.SMALL_ENGINE_MECHANICS: {
        return "Petite mécanique du moteur";
      }
      case JobFunction.SOCIAL_AND_COMMUNITY_SERVICE_MANAGERS: {
        return "Gestionnaires de services sociaux et communautaires";
      }
      case JobFunction.SOCIAL_AND_HUMAN_SERVICE_ASSISTANTS: {
        return "Assistants sociaux et à services humains";
      }
      case JobFunction.SOCIAL_SCIENTISTS_OTHER: {
        return "Spécialistes des sciences sociales, autres";
      }
      case JobFunction.SOCIAL_WORKERS: {
        return "Les travailleurs sociaux";
      }
      case JobFunction.SALES_WORKER_SUPERVISORS: {
        return "Superviseurs des travailleurs";
      }
      case JobFunction.SOCIOLOGISTS: {
        return "Sociologues";
      }
      case JobFunction.SOFTWARE_DEVELOPERS: {
        return "Développeurs de logiciels";
      }
      case JobFunction.SCIENCE_TECHNICIANS: {
        return "Techniciens scientifiques";
      }
      case JobFunction.SOLAR_PHOTOVOLTAIC_INSTALLERS: {
        return "Installateurs solaires photovoltaïques";
      }
      case JobFunction.SPECIAL_EDUCATION_TEACHERS: {
        return "Professeurs d'éducation spéciale";
      }
      case JobFunction.SPEECH_LANGUAGE_PATHOLOGISTS: {
        return "Orthophonie";
      }
      case JobFunction.STATIONARY_ENGINEERS_AND_BOILER_OPERATORS: {
        return "Ingénieurs stationnaires et opérateurs de chaudières";
      }
      case JobFunction.SEMICONDUCTOR_PROCESSORS: {
        return "Processeurs de semi-conducteurs";
      }
      case JobFunction.STATISTICIANS: {
        return "Statisticiens";
      }
      case JobFunction.STOCK_CLERKS_AND_ORDER_FILLERS: {
        return "Commis d'origine et charges de commande";
      }
      case JobFunction.STRUCTURAL_AND_REINFORCING_IRON_AND_METAL_WORKERS: {
        return "Structural et renforcer le fer et les métalliques";
      }
      case JobFunction.SUBSTANCE_ABUSE_BEHAVIORAL_DISORDER_AND_MENTAL_HEALTH_COUNSELORS: {
        return "Toxicomanie, trouble comportemental et H mental";
      }
      case JobFunction.SUPPLY_MANAGEMENT: {
        return "Gestion des approvisionnements";
      }
      case JobFunction.SURGICAL_TECHNOLOGISTS: {
        return "Technologues chirurgicaux";
      }
      case JobFunction.SURVEY_RESEARCHERS: {
        return "Chercheurs en sondage";
      }
      case JobFunction.SURVEYING_AND_MAPPING_TECHNICIANS: {
        return "Techniciens d'arpentage et de cartographie";
      }
      case JobFunction.SURVEYORS: {
        return "Arpenteurs";
      }
      case JobFunction.SURVEYORS_CARTOGRAPHERS_PHOTOGRAMMETRISTS_AND_SURVEYING_AND_MAPPING_TECHNICIANS: {
        return "Arpenteurs, cartographes, photogramétristes et S";
      }
      case JobFunction.TAX_EXAMINERS_AND_COLLECTORS_AND_REVENUE_AGENTS: {
        return "Examinateurs fiscaux et collecteurs et agents de revenus";
      }
      case JobFunction.TAX_EXAMINERS_COLLECTORS_AND_REVENUE_AGENTS: {
        return "Examinateurs fiscaux, collectionneurs et agents de revenus";
      }
      case JobFunction.TAXI_DRIVERS_AND_CHAUFFEURS: {
        return "Chauffeurs de taxi et chauffeurs";
      }
      case JobFunction.TAXI_DRIVERS_RIDE_HAILING_DRIVERS_AND_CHAUFFEURS: {
        return "Chauffeurs de taxi, chauffeurs de covoiturage et chauffeurs";
      }
      case JobFunction.TEACHER_ASSISTANTS: {
        return "Assistants enseignants";
      }
      case JobFunction.TEACHERS_ADULT_LITERACY_AND_REMEDIAL_EDUCATION: {
        return "Enseignants-adulte littératie et éducation corrective";
      }
      case JobFunction.TEACHERS_POSTSECONDARY: {
        return "Enseignants-post-seconde";
      }
      case JobFunction.TEACHERS_PRESCHOOL_KINDERGARTEN_ELEMENTARY_MIDDLE_AND_SECONDARY: {
        return "Enseignants-préscol, maternelle, élémentaire, midd";
      }
      case JobFunction.TEACHERS_SPECIAL_EDUCATION: {
        return "Enseignement spécial des enseignants";
      }
      case JobFunction.TECHNICAL_WRITERS: {
        return "Rédacteurs techniques";
      }
      case JobFunction.TELECOMMUNICATIONS_EQUIPMENT_INSTALLERS_AND_REPAIRERS: {
        return "Installateurs et réparations d'équipement de télécommunications";
      }
      case JobFunction.TELEVISION_VIDEO_AND_MOTION_PICTURE_CAMERA_OPERATORS_AND_EDITORS: {
        return "Opéra de caméra de télévision, de vidéo et de cinéma";
      }
      case JobFunction.TELLERS: {
        return "Scrueux";
      }
      case JobFunction.TOOL_AND_DIE_MAKERS: {
        return "Outil et créateurs";
      }
      case JobFunction.TOP_EXECUTIVES: {
        return "Cadres supérieurs";
      }
      case JobFunction.TRAINING_AND_DEVELOPMENT_MANAGERS: {
        return "Gestionnaires de formation et de développement";
      }
      case JobFunction.TRAINING_AND_DEVELOPMENT_SPECIALISTS: {
        return "Spécialistes de la formation et du développement";
      }
      case JobFunction.TRAVEL_AGENTS: {
        return "Agents de voyage";
      }
      case JobFunction.TRUCK_DRIVERS_AND_DRIVERSALES_WORKERS: {
        return "Chauffeurs de camions et travailleurs des conducteurs / ventes";
      }
      case JobFunction.UMPIRES_REFEREES_AND_OTHER_SPORTS_OFFICIALS: {
        return "Arbitres, arbitres et autres responsables sportifs";
      }
      case JobFunction.URBAN_AND_REGIONAL_PLANNERS: {
        return "Planificateurs urbains et régionaux";
      }
      case JobFunction.VETERINARIANS: {
        return "Vétérinaires";
      }
      case JobFunction.TEACHERS_SELF_ENRICHMENT_EDUCATION: {
        return "Enseignant-enseignement de l'auto-inscription";
      }
      case JobFunction.VETERINARY_ASSISTANTS_AND_LABORATORY_ANIMAL_CARETAKERS: {
        return "Assistants vétérinaires et carita animaux de laboratoire";
      }
      case JobFunction.VETERINARY_TECHNOLOGISTS_AND_TECHNICIANS: {
        return "Technologues vétérinaires et techniciens";
      }
      case JobFunction.WAITERS_AND_WAITRESSES: {
        return "Serveurs et serveuses";
      }
      case JobFunction.WATER_AND_LIQUID_WASTE_TREATMENT_PLANT_AND_SYSTEM_OPERATORS: {
        return "Usine et système de traitement des déchets d'eau et de liquide";
      }
      case JobFunction.WATER_AND_WASTEWATER_TREATMENT_PLANT_AND_SYSTEM_OPERATORS: {
        return "Usine de traitement des eaux et des eaux usées OP";
      }
      case JobFunction.WATER_TRANSPORTATION_OCCUPATIONS: {
        return "Occupations du transport d'eau";
      }
      case JobFunction.TEXTILE_APPAREL_AND_FURNISHINGS_OCCUPATIONS: {
        return "Occupations textiles, vêtements et meubles";
      }
      case JobFunction.WATER_TRANSPORTATION_WORKERS: {
        return "Travailleurs du transport d'eau";
      }
      case JobFunction.WEB_DEVELOPERS: {
        return "Développeurs web";
      }
      case JobFunction.WEIGHERS_MEASURERS_CHECKERS_AND_SAMPLERS_RECORDKEEPING: {
        return "Pesseurs, mesures, dames et échantillonneurs, recor";
      }
      case JobFunction.WELDERS_CUTTERS_SOLDERERS_AND_BRAZERS: {
        return "Soudeurs, coupeurs, soudereurs et brassières";
      }
      case JobFunction.WELDING_SOLDERING_AND_BRAZING_WORKERS: {
        return "Soudage, soudure et brasage";
      }
      case JobFunction.WHOLESALE_AND_MANUFACTURING_SALES_REPRESENTATIVES: {
        return "Représentants des ventes en gros et manufacturières";
      }
      case JobFunction.WIND_TURBINE_TECHNICIANS: {
        return "Techniciens d'éoliennes";
      }
      case JobFunction.WOODWORKERS: {
        return "Menuiserie";
      }
      case JobFunction.WRITERS_AND_AUTHORS: {
        return "Écrivains et auteurs";
      }
      case JobFunction.WRITERS_AND_EDITORS: {
        return "Écrivains et éditeurs";
      }
      case JobFunction.ZOOLOGISTS_AND_WILDLIFE_BIOLOGISTS: {
        return "Zoologistes et biologistes de la faune";
      }
      case JobFunction.OTHER: {
        return "Autre";
      }
    }
    return "";
  }

  private static getJobFunctionTitleInItalian(
    jobFunction: JobFunction,
  ): string {
    switch (jobFunction) {
      case JobFunction.ACCOUNTANTS_AND_AUDITORS: {
        return "Contabili e revisori";
      }
      case JobFunction.ACTORS_PRODUCERS_AND_DIRECTORS: {
        return "Attori, produttori e registi";
      }
      case JobFunction.ACTUARIES: {
        return "Attuari";
      }
      case JobFunction.ADMINISTRATIVE_SERVICES_MANAGERS: {
        return "Manager di servizi amministrativi";
      }
      case JobFunction.ADULT_LITERACY_AND_HIGH_SCHOOL_EQUIVALENCY_DIPLOMA_TEACHERS: {
        return "Alfabetizzazione per adulti e diploma di equivalenza al liceo";
      }
      case JobFunction.ADVERTISING_SALES_AGENTS: {
        return "Agenti di vendita pubblicitaria";
      }
      case JobFunction.ADVERTISING_MARKETING_PROMOTIONS_PUBLIC_RELATIONS_AND_SALES_MANAGERS: {
        return "Pubblicità, marketing, promozioni, relatio pubblico";
      }
      case JobFunction.AEROSPACE_ENGINEERING_AND_OPERATIONS_TECHNICIANS: {
        return "Tecnici di ingegneria aerospaziale e operazioni";
      }
      case JobFunction.AEROSPACE_ENGINEERS: {
        return "Ingegneri aerospaziali";
      }
      case JobFunction.AGRICULTURAL_AND_FOOD_SCIENCE_TECHNICIANS: {
        return "Tecnici di scienze agricole e alimentari";
      }
      case JobFunction.AGRICULTURAL_AND_FOOD_SCIENTISTS: {
        return "Scienziati agricoli e alimentari";
      }
      case JobFunction.AGRICULTURAL_ENGINEERS: {
        return "Ingegneri agricoli";
      }
      case JobFunction.AGRICULTURAL_WORKERS: {
        return "Lavoratori agricoli";
      }
      case JobFunction.AIR_TRAFFIC_CONTROLLERS: {
        return "Controllori del traffico aereo";
      }
      case JobFunction.AIRCRAFT_AND_AVIONICS_EQUIPMENT_MECHANICS_AND_SERVICE_TECHNICIANS: {
        return "Meccanici e service attrezzature aeromobili e avioniche";
      }
      case JobFunction.AIRCRAFT_PILOTS_AND_FLIGHT_ENGINEERS: {
        return "Piloti di aeromobili e ingegneri di volo";
      }
      case JobFunction.ANIMAL_CARE_AND_SERVICE_WORKERS: {
        return "Assistenza agli animali e assistenza";
      }
      case JobFunction.ANNOUNCERS: {
        return "Annunciatori";
      }
      case JobFunction.ANTHROPOLOGISTS_AND_ARCHEOLOGISTS: {
        return "Antropologi e archeologi";
      }
      case JobFunction.APPRAISERS_AND_ASSESSORS_OF_REAL_ESTATE: {
        return "Periti e valutatori del settore immobiliare";
      }
      case JobFunction.ARBITRATORS_MEDIATORS_AND_CONCILIATORS: {
        return "Arbitri, mediatori e conciliatori";
      }
      case JobFunction.ARCHITECTS_EXCEPT_LANDSCAPE_AND_NAVAL: {
        return "Architetti, tranne il paesaggio e la navale";
      }
      case JobFunction.ARCHITECTURAL_AND_ENGINEERING_MANAGERS: {
        return "Manager di architettura e ingegneria";
      }
      case JobFunction.ARCHIVISTS_CURATORS_AND_MUSEUM_TECHNICIANS: {
        return "Archivisti, curatori e tecnici dei musei";
      }
      case JobFunction.ART_DIRECTORS: {
        return "Registi d'arte";
      }
      case JobFunction.ARTISTS_AND_RELATED_WORKERS: {
        return "Artisti e lavoratori correlati";
      }
      case JobFunction.ASSEMBLERS_AND_FABRICATORS: {
        return "Assemblatori e fabbricanti";
      }
      case JobFunction.ATHLETES_COACHES_UMPIRES_AND_RELATED_WORKERS: {
        return "Atleti, allenatori, arbitri e lavoratori correlati";
      }
      case JobFunction.ATHLETIC_TRAINERS: {
        return "Allenatori atletici";
      }
      case JobFunction.ATMOSPHERIC_SCIENTISTS: {
        return "Scienziati atmosferici";
      }
      case JobFunction.AUDIOLOGISTS: {
        return "Audiologi";
      }
      case JobFunction.AUTOMOTIVE_BODY_AND_RELATED_REPAIRERS: {
        return "Corpo automobilistico e relativi riparatori";
      }
      case JobFunction.AUTOMOTIVE_SERVICE_TECHNICIANS_AND_MECHANICS: {
        return "Tecnici e meccanici del servizio automobilistico";
      }
      case JobFunction.BAKERS: {
        return "Fornai";
      }
      case JobFunction.BARBERS_COSMETOLOGISTS_AND_OTHER_PERSONAL_APPEARANCE_WORKERS: {
        return "Barbieri, cosmetologi e altri personali appaiono";
      }
      case JobFunction.BARTENDERS: {
        return "Baristi";
      }
      case JobFunction.BILL_AND_ACCOUNT_COLLECTORS: {
        return "Collezionisti di fattura e conti";
      }
      case JobFunction.BILLING_AND_POSTING_CLERKS_AND_MACHINE_OPERATORS: {
        return "Fatturazione e pubblicazione di impiegati e operatori di macchine";
      }
      case JobFunction.BIOLOGICAL_SCIENTISTS: {
        return "Scienziati biologici";
      }
      case JobFunction.BIOLOGICAL_TECHNICIANS: {
        return "Tecnici biologici";
      }
      case JobFunction.BIOMEDICAL_ENGINEERS: {
        return "Ingegneri biomedici";
      }
      case JobFunction.BOILERMAKERS: {
        return "Boilermakers";
      }
      case JobFunction.BOOKBINDERS_AND_BINDERY_WORKERS: {
        return "Bookbinders e bindhery workers";
      }
      case JobFunction.BOOKKEEPING_ACCOUNTING_AND_AUDITING_CLERKS: {
        return "Contabilizzazione, contabilità e addetti alle audit";
      }
      case JobFunction.BRICKMASONS_BLOCKMASONS_AND_STONEMASONS: {
        return "Brickmasons, Blockmasons e Stonemasons";
      }
      case JobFunction.BROADCAST_AND_SOUND_ENGINEERING_TECHNICIANS_AND_RADIO_OPERATORS: {
        return "Tecnici di trasmissione e ingegneria sonora e RA";
      }
      case JobFunction.BROKERAGE_CLERKS: {
        return "Impieghi di intermediazione";
      }
      case JobFunction.BUDGET_ANALYSTS: {
        return "Analisti di bilancio";
      }
      case JobFunction.BUILDING_CLEANING_WORKERS: {
        return "Costruire i lavoratori delle pulizie";
      }
      case JobFunction.BUS_DRIVERS: {
        return "Conducenti di autobus";
      }
      case JobFunction.BUTCHERS: {
        return "Macellai";
      }
      case JobFunction.CARDIOVASCULAR_TECHNOLOGISTS_AND_TECHNICIANS: {
        return "Tecnologi e tecnici cardiovascolari";
      }
      case JobFunction.CAREER_AND_TECHNICAL_EDUCATION_TEACHERS: {
        return "Insegnanti di carriera e educazione tecnica";
      }
      case JobFunction.CARGO_AND_FREIGHT_AGENTS: {
        return "Agenti merci e merci";
      }
      case JobFunction.CARPENTERS: {
        return "Carpenters";
      }
      case JobFunction.CARPET_FLOOR_AND_TILE_INSTALLERS_AND_FINISHERS: {
        return "Installatori di moquette, pavimenti e piastrelle e finitori";
      }
      case JobFunction.CARTOGRAPHERS_AND_PHOTOGRAMMETRISTS: {
        return "Cartografi e fotogrammetristi";
      }
      case JobFunction.CASHIERS: {
        return "Cassieri";
      }
      case JobFunction.CEMENT_MASONS_CONCRETE_FINISHERS_SEGMENTAL_PAVERS_AND_TERRAZZO_WORKERS: {
        return "Muratori di cemento, finitori in cemento, finitrice segmentaria";
      }
      case JobFunction.CHEFS_AND_HEAD_COOKS: {
        return "Chef e cuochi per la testa";
      }
      case JobFunction.CHEFS_COOKS_AND_FOOD_PREPARATION_WORKERS: {
        return "Chef, cuochi e lavoratori della preparazione di cibo";
      }
      case JobFunction.CHEMICAL_ENGINEERS: {
        return "Ingegneri chimici";
      }
      case JobFunction.CHEMICAL_TECHNICIANS: {
        return "Tecnici chimici";
      }
      case JobFunction.CHEMISTS_AND_MATERIALS_SCIENTISTS: {
        return "Chimici e scienziati dei materiali";
      }
      case JobFunction.CHILD_CARE_WORKERS: {
        return "Operatori di assistenza all'infanzia";
      }
      case JobFunction.CHIROPRACTORS: {
        return "Chiropratici";
      }
      case JobFunction.CIVIL_ENGINEERING_TECHNICIANS: {
        return "Tecnici di ingegneria civile";
      }
      case JobFunction.CIVIL_ENGINEERS: {
        return "Ingegneri civili";
      }
      case JobFunction.CLAIMS_ADJUSTERS_APPRAISERS_EXAMINERS_AND_INVESTIGATORS: {
        return "Reclami regolatori, periti, esaminatori e inves";
      }
      case JobFunction.CLINICAL_LABORATORY_TECHNOLOGISTS_AND_TECHNICIANS: {
        return "Tecnologi e tecnici di laboratorio clinico";
      }
      case JobFunction.COACHES_AND_SCOUTS: {
        return "Allenatori e scout";
      }
      case JobFunction.COIN_VENDING_AND_AMUSEMENT_MACHINE_SERVICERS_AND_REPAIRERS: {
        return "Servizio di moneta, distribuzione e macchina da divertimenti e";
      }
      case JobFunction.COMMERCIAL_AND_INDUSTRIAL_DESIGNERS: {
        return "Designer commerciali e industriali";
      }
      case JobFunction.COMMERCIAL_MANAGEMENT: {
        return "Gestione commerciale";
      }
      case JobFunction.COMMUNICATIONS_EQUIPMENT_OPERATORS: {
        return "Operatori di apparecchiature di comunicazione";
      }
      case JobFunction.COMPENSATION_AND_BENEFITS_MANAGERS: {
        return "Manager di compensazione e benefici";
      }
      case JobFunction.COMPENSATION_BENEFITS_AND_JOB_ANALYSIS_SPECIALISTS: {
        return "Speciali di compensazione, benefici e analisi del lavoro";
      }
      case JobFunction.COMPUTER_AND_INFORMATION_RESEARCH_SCIENTISTS: {
        return "Scienziati di ricerche informatiche e informatiche";
      }
      case JobFunction.COMPUTER_AND_INFORMATION_SYSTEMS_MANAGERS: {
        return "Gestori di sistemi informatici e informatici";
      }
      case JobFunction.COMPUTER_CONTROL_PROGRAMMERS_AND_OPERATORS: {
        return "Programmatori e operatori di controllo del computer";
      }
      case JobFunction.COMPUTER_HARDWARE_ENGINEERS: {
        return "Ingegneri hardware del computer";
      }
      case JobFunction.COMPUTER_NETWORK_ARCHITECTS: {
        return "Architetti di rete di computer";
      }
      case JobFunction.COMPUTER_OPERATORS: {
        return "Operatori di computer";
      }
      case JobFunction.COMPUTER_PROGRAMMERS: {
        return "Programmatori di computer";
      }
      case JobFunction.COMPUTER_SCIENTISTS_AND_DATABASE_ADMINISTRATORS: {
        return "Scienziati informatici e amministratori di database";
      }
      case JobFunction.COMPUTER_SOFTWARE_ENGINEERS: {
        return "Ingegneri del software per computer";
      }
      case JobFunction.COMPUTER_SUPPORT_SPECIALISTS: {
        return "Speciali di supporto per computer";
      }
      case JobFunction.COMPUTER_SUPPORT_SPECIALISTS_AND_SYSTEMS_ADMINISTRATORS: {
        return "Specialisti di supporto per computer e amministratore di sistemi";
      }
      case JobFunction.COMPUTER_SYSTEMS_ANALYSTS: {
        return "Analisti dei sistemi informatici";
      }
      case JobFunction.COMPUTER_AUTOMATED_TELLER_AND_OFFICE_MACHINE_REPAIRERS: {
        return "Computer, cassiere automatizzato e rappresentante della macchina per ufficio";
      }
      case JobFunction.CONSERVATION_SCIENTISTS_AND_FORESTERS: {
        return "Scienziati e silvicoltori per la conservazione";
      }
      case JobFunction.CONSTRUCTION_AND_BUILDING_INSPECTORS: {
        return "Ispettori di costruzione e edilizia";
      }
      case JobFunction.CONSTRUCTION_EQUIPMENT_OPERATORS: {
        return "Operatori di attrezzature da costruzione";
      }
      case JobFunction.CONSTRUCTION_LABORERS: {
        return "Operai di costruzione";
      }
      case JobFunction.CONSTRUCTION_LABORERS_AND_HELPERS: {
        return "Operai e aiutanti di costruzione";
      }
      case JobFunction.CONSTRUCTION_MANAGERS: {
        return "Responsabili delle costruzioni";
      }
      case JobFunction.COOKS: {
        return "Cuochi";
      }
      case JobFunction.CORRECTIONAL_OFFICERS: {
        return "Ufficiali correttivi";
      }
      case JobFunction.CONTRACT_MANAGEMENT: {
        return "Gestione contratti";
      }
      case JobFunction.CORRECTIONAL_OFFICERS_AND_BAILIFFS: {
        return "Ufficiali e giudiziari correttivi";
      }
      case JobFunction.COST_ESTIMATORS: {
        return "Stimatori di costo";
      }
      case JobFunction.COUNSELORS: {
        return "Consulenti";
      }
      case JobFunction.COUNTER_AND_RENTAL_CLERKS: {
        return "Contattatore e impiegati";
      }
      case JobFunction.COURIERS_AND_MESSENGERS: {
        return "Corrieri e messaggeri";
      }
      case JobFunction.COURT_REPORTERS: {
        return "Reporter di corte";
      }
      case JobFunction.CRAFT_AND_FINE_ARTISTS: {
        return "Artigianato e belle artisti";
      }
      case JobFunction.CREDIT_AUTHORIZERS_CHECKERS_AND_CLERKS: {
        return "Autorizzatori di credito, dottori e impiegati";
      }
      case JobFunction.CUSTOMER_SERVICE_REPRESENTATIVES: {
        return "Rappresentanti del servizio clienti";
      }
      case JobFunction.DANCERS_AND_CHOREOGRAPHERS: {
        return "Dancer e coreografi";
      }
      case JobFunction.DATA_ENTRY_AND_INFORMATION_PROCESSING_WORKERS: {
        return "INVICAZIONE DATI E ELAMBINO DI INFORMAZIONI I lavoratori";
      }
      case JobFunction.DATABASE_ADMINISTRATORS: {
        return "Amministratori di database";
      }
      case JobFunction.DELIVERY_TRUCK_DRIVERS_AND_DRIVERSALES_WORKERS: {
        return "Camionisti di consegna e operatori di autista/vendite";
      }
      case JobFunction.DEMONSTRATORS_PRODUCT_PROMOTERS_AND_MODELS: {
        return "Manifestanti, promotori di prodotti e modelli";
      }
      case JobFunction.DENTAL_AND_OPHTHALMIC_LABORATORY_TECHNICIANS_AND_MEDICAL_APPLIANCE_TECHNICIANS: {
        return "Tecnici di laboratorio dentale e oftalmico e m";
      }
      case JobFunction.DENTAL_ASSISTANTS: {
        return "Assistenti dentali";
      }
      case JobFunction.DENTAL_HYGIENISTS: {
        return "Igienisti dentali";
      }
      case JobFunction.DENTISTS: {
        return "Dentisti";
      }
      case JobFunction.DESKTOP_PUBLISHERS: {
        return "Editori desktop";
      }
      case JobFunction.DIAGNOSTIC_MEDICAL_SONOGRAPHERS: {
        return "Sonografi medici diagnostici";
      }
      case JobFunction.DIESEL_SERVICE_TECHNICIANS_AND_MECHANICS: {
        return "Tecnici e meccanici di servizio diesel";
      }
      case JobFunction.DIETITIANS_AND_NUTRITIONISTS: {
        return "Dietisti e nutrizionisti";
      }
      case JobFunction.DISPATCHERS: {
        return "Dispatcher";
      }
      case JobFunction.DRAFTERS: {
        return "Drafters";
      }
      case JobFunction.DRYWALL_AND_CEILING_TILE_INSTALLERS_AND_TAPERS: {
        return "Installatori di piastrelle a secco e soffitto e tasso";
      }
      case JobFunction.DRYWALL_INSTALLERS_CEILING_TILE_INSTALLERS_AND_TAPERS: {
        return "Installatori di muro a secco, installatori di piastrelle a soffitto e t";
      }
      case JobFunction.ECONOMISTS: {
        return "Economisti";
      }
      case JobFunction.EDITORS: {
        return "Redattori";
      }
      case JobFunction.EDUCATION_ADMINISTRATORS: {
        return "Amministratori dell'istruzione";
      }
      case JobFunction.ELECTRICAL_AND_ELECTRONICS_ENGINEERING_TECHNICIANS: {
        return "Tecnici ingegneristici elettrici ed elettronici";
      }
      case JobFunction.ELECTRICAL_AND_ELECTRONICS_ENGINEERS: {
        return "Ingegneri elettrici ed elettronici";
      }
      case JobFunction.ELECTRICAL_AND_ELECTRONICS_INSTALLERS_AND_REPAIRERS: {
        return "Installatori e riparatori elettrici ed elettronici";
      }
      case JobFunction.ELECTRICIANS: {
        return "Elettricisti";
      }
      case JobFunction.ELECTRO_MECHANICAL_TECHNICIANS: {
        return "Tecnici elettromeccanici";
      }
      case JobFunction.ELECTRONIC_HOME_ENTERTAINMENT_EQUIPMENT_INSTALLERS_AND_REPAIRERS: {
        return "Installatori di attrezzature elettroniche di intrattenimento domestico";
      }
      case JobFunction.ELEMENTARY_MIDDLE_AND_HIGH_SCHOOL_PRINCIPALS: {
        return "Principali delle scuole elementari, medie e superiori";
      }
      case JobFunction.ELEVATOR_INSTALLERS_AND_REPAIRERS: {
        return "Installatori e riparatori di ascensori";
      }
      case JobFunction.EMERGENCY_MANAGEMENT_DIRECTORS: {
        return "Direttori di gestione delle emergenze";
      }
      case JobFunction.EMERGENCY_MEDICAL_TECHNICIANS_AND_PARAMEDICS: {
        return "Tecnici e paramedici medici di emergenza";
      }
      case JobFunction.EMTS_AND_PARAMEDICS: {
        return "EMT e paramedici";
      }
      case JobFunction.ENGINEERING_AND_NATURAL_SCIENCES_MANAGERS: {
        return "Manager di ingegneria e scienze naturali";
      }
      case JobFunction.ENGINEERING_TECHNICIANS: {
        return "Tecnici ingegneristici";
      }
      case JobFunction.ENVIRONMENTAL_ENGINEERING_TECHNICIANS: {
        return "Tecnici di ingegneria ambientale";
      }
      case JobFunction.ENVIRONMENTAL_ENGINEERS: {
        return "Ingegneri ambientali";
      }
      case JobFunction.ENVIRONMENTAL_SCIENCE_AND_PROTECTION_TECHNICIANS: {
        return "Tecnici di scienze ambientali e protezione";
      }
      case JobFunction.ENVIRONMENTAL_SCIENTISTS_AND_HYDROLOGISTS: {
        return "Scienziati e idrologi ambientali";
      }
      case JobFunction.ENVIRONMENTAL_SCIENTISTS_AND_SPECIALISTS: {
        return "Scienziati e specialisti ambientali";
      }
      case JobFunction.EPIDEMIOLOGISTS: {
        return "Epidemiologi";
      }
      case JobFunction.EXERCISE_PHYSIOLOGISTS: {
        return "Esercizio fisiologi";
      }
      case JobFunction.FARMERS_RANCHERS_AND_AGRICULTURAL_MANAGERS: {
        return "Agricoltori, allevatori e gestori agricoli";
      }
      case JobFunction.FARMERS_RANCHERS_AND_OTHER_AGRICULTURAL_MANAGERS: {
        return "Agricoltori, allevatori e altri gestori agricoli";
      }
      case JobFunction.FASHION_DESIGNERS: {
        return "Stilisti";
      }
      case JobFunction.FILE_CLERKS: {
        return "Impiegati";
      }
      case JobFunction.FILM_AND_VIDEO_EDITORS_AND_CAMERA_OPERATORS: {
        return "Editori di film e video e operatori di telecamere";
      }
      case JobFunction.FINANCIAL_ANALYSTS: {
        return "Analisti finanziari";
      }
      case JobFunction.FINANCIAL_ANALYSTS_AND_PERSONAL_FINANCIAL_ADVISORS: {
        return "Analisti finanziari e consulenti finanziari personali";
      }
      case JobFunction.FINANCIAL_CLERKS: {
        return "Impiegati finanziari";
      }
      case JobFunction.FINANCIAL_EXAMINERS: {
        return "Esaminatori finanziari";
      }
      case JobFunction.FINANCIAL_MANAGERS: {
        return "Gestori finanziari";
      }
      case JobFunction.FIRE_FIGHTING_OCCUPATIONS: {
        return "Professioni da combattimento antincendio";
      }
      case JobFunction.FIRE_INSPECTORS: {
        return "Ispettori antincendio";
      }
      case JobFunction.FIREFIGHTERS: {
        return "Pompiere";
      }
      case JobFunction.FISHERS_AND_FISHING_VESSEL_OPERATORS: {
        return "Pescatori e operatori di reciproche";
      }
      case JobFunction.FISHING_AND_HUNTING_WORKERS: {
        return "La pesca e i lavoratori della caccia";
      }
      case JobFunction.FITNESS_TRAINERS_AND_INSTRUCTORS: {
        return "Formatori di fitness e istruttori";
      }
      case JobFunction.FITNESS_WORKERS: {
        return "Lavoratori del fitness";
      }
      case JobFunction.FLIGHT_ATTENDANTS: {
        return "Assistenti di volo";
      }
      case JobFunction.FLOORING_INSTALLERS_AND_TILE_AND_MARBLE_SETTERS: {
        return "Installatori di pavimenti e setter di piastrelle e marmo";
      }
      case JobFunction.FLORAL_DESIGNERS: {
        return "Designer floreali";
      }
      case JobFunction.FOOD_AND_BEVERAGE_SERVING_AND_RELATED_WORKERS: {
        return "Alimentari e bevande e lavoratori correlati";
      }
      case JobFunction.FOOD_AND_TOBACCO_PROCESSING_WORKERS: {
        return "Lavoratori di trasformazione alimentare e del tabacco";
      }
      case JobFunction.FOOD_PROCESSING_OCCUPATIONS: {
        return "Occupazioni di trasformazione alimentare";
      }
      case JobFunction.FOOD_SERVICE_MANAGERS: {
        return "Gestori di servizi di ristorazione";
      }
      case JobFunction.FORENSIC_SCIENCE_TECHNICIANS: {
        return "Tecnici scientifici forensi";
      }
      case JobFunction.FOREST_AND_CONSERVATION_WORKERS: {
        return "Lavoratori forestali e di conservazione";
      }
      case JobFunction.FOREST_CONSERVATION_AND_LOGGING_WORKERS: {
        return "I lavoratori della foresta, della conservazione e del disboscamento";
      }
      case JobFunction.FUNDRAISERS: {
        return "Raccolte fondi";
      }
      case JobFunction.FUNERAL_DIRECTORS: {
        return "Registi funebri";
      }
      case JobFunction.FUNERAL_SERVICE_WORKERS: {
        return "Operatori funebri";
      }
      case JobFunction.GAMING_CAGE_WORKERS: {
        return "Lavori di gabbia da gioco";
      }
      case JobFunction.GAMING_SERVICES_OCCUPATIONS: {
        return "Occupazioni dei servizi di gioco";
      }
      case JobFunction.GAMING_SERVICES_WORKERS: {
        return "Servizi di gioco";
      }
      case JobFunction.GENERAL_MAINTENANCE_AND_REPAIR_WORKERS: {
        return "Operatori di manutenzione e riparazione generale";
      }
      case JobFunction.GENERAL_OFFICE_CLERKS: {
        return "Impiegati dell'ufficio generale";
      }
      case JobFunction.GENETIC_COUNSELORS: {
        return "Consulenti genetici";
      }
      case JobFunction.GEOGRAPHERS: {
        return "Geografi";
      }
      case JobFunction.GEOLOGICAL_AND_PETROLEUM_TECHNICIANS: {
        return "Tecnici geologici e petroliferi";
      }
      case JobFunction.GEOSCIENTISTS: {
        return "Geoscienziati";
      }
      case JobFunction.GLAZIERS: {
        return "Glazieri";
      }
      case JobFunction.GRAPHIC_DESIGNERS: {
        return "Grafici";
      }
      case JobFunction.GROUNDS_MAINTENANCE_WORKERS: {
        return "Grounds Werstening Weders";
      }
      case JobFunction.HAND_LABORERS_AND_MATERIAL_MOVERS: {
        return "Operai a mano e motori materiali";
      }
      case JobFunction.HAZARDOUS_MATERIALS_REMOVAL_WORKERS: {
        return "Lavoratori di rimozione dei materiali pericolosi";
      }
      case JobFunction.HEALTH_AND_SAFETY_ENGINEERS: {
        return "Ingegneri di salute e sicurezza";
      }
      case JobFunction.HEALTH_EDUCATORS: {
        return "Educatori della salute";
      }
      case JobFunction.HEALTH_EDUCATORS_AND_COMMUNITY_HEALTH_WORKERS: {
        return "Educatori sanitari e operatori sanitari della comunità";
      }
      case JobFunction.HEATING_AIR_CONDITIONING_AND_REFRIGERATION_MECHANICS_AND_INSTALLERS: {
        return "Mecha di riscaldamento, aria condizionata e refrigerazione";
      }
      case JobFunction.HEAVY_AND_TRACTOR_TRAILER_TRUCK_DRIVERS: {
        return "Camionisti pesanti e trattore rimorchi";
      }
      case JobFunction.HEAVY_VEHICLE_AND_MOBILE_EQUIPMENT_SERVICE_TECHNICIANS: {
        return "Tecnica di servizio per veicoli e attrezzature mobili pesanti";
      }
      case JobFunction.HEAVY_VEHICLE_AND_MOBILE_EQUIPMENT_SERVICE_TECHNICIANS_AND_MECHANICS: {
        return "Tecnica di servizio per veicoli e attrezzature mobili pesanti";
      }
      case JobFunction.HIGH_SCHOOL_TEACHERS: {
        return "Insegnanti delle scuole superiori";
      }
      case JobFunction.HISTORIANS: {
        return "Storici";
      }
      case JobFunction.HOME_APPLIANCE_REPAIRERS: {
        return "Riparatori di elettrodomestici";
      }
      case JobFunction.HOME_HEALTH_AIDES_AND_PERSONAL_CARE_AIDES: {
        return "Aiuti per la salute domestica e assistenti per la cura personale";
      }
      case JobFunction.HOTEL_MOTEL_AND_RESORT_DESK_CLERKS: {
        return "Impiegati di hotel, motel e resort";
      }
      case JobFunction.HUMAN_RESOURCES_ASSISTANTS_EXCEPT_PAYROLL_AND_TIMEKEEPING: {
        return "Assistenti alle risorse umane, tranne il libro paga e Tim";
      }
      case JobFunction.HUMAN_RESOURCES_MANAGERS: {
        return "Manager delle risorse umane";
      }
      case JobFunction.HUMAN_RESOURCES_SPECIALISTS: {
        return "Specialisti delle risorse umane";
      }
      case JobFunction.HUMAN_RESOURCES_TRAINING_AND_LABOR_RELATIONS_MANAGERS_AND_SPECIALISTS: {
        return "Risorse umane, formazione e relazioni di lavoro";
      }
      case JobFunction.HYDROLOGISTS: {
        return "Idrologi";
      }
      case JobFunction.INDUSTRIAL_DESIGNERS: {
        return "Designer industriali";
      }
      case JobFunction.INDUSTRIAL_ENGINEERING_TECHNICIANS: {
        return "Tecnici di ingegneria industriale";
      }
      case JobFunction.INDUSTRIAL_ENGINEERS: {
        return "Ingegneri industriali";
      }
      case JobFunction.INDUSTRIAL_MACHINERY_MECHANICS_AND_MAINTENANCE_WORKERS: {
        return "Meccanica di macchinari industriali e manutenzione WOR";
      }
      case JobFunction.INDUSTRIAL_PRODUCTION_MANAGERS: {
        return "Manager di produzione industriale";
      }
      case JobFunction.INFORMATION_CLERKS: {
        return "Impiegati di informazione";
      }
      case JobFunction.INFORMATION_SECURITY_ANALYSTS: {
        return "Analisti della sicurezza delle informazioni";
      }
      case JobFunction.INSPECTORS_TESTERS_SORTERS_SAMPLERS_AND_WEIGHERS: {
        return "Ispettori, tester, sorsterie, campionatori e pesi";
      }
      case JobFunction.INSTRUCTIONAL_COORDINATORS: {
        return "Coordinatori didattici";
      }
      case JobFunction.INSULATION_WORKERS: {
        return "Lavoratori isolanti";
      }
      case JobFunction.INSURANCE_SALES_AGENTS: {
        return "Agenti di vendita assicurativi";
      }
      case JobFunction.INSURANCE_UNDERWRITERS: {
        return "Sottoscrittori assicurativi";
      }
      case JobFunction.INTERIOR_DESIGNERS: {
        return "Interior designer";
      }
      case JobFunction.INTERPRETERS_AND_TRANSLATORS: {
        return "Interpreti e traduttori";
      }
      case JobFunction.IRONWORKERS: {
        return "Ironworker";
      }
      case JobFunction.JEWELERS_AND_PRECIOUS_STONE_AND_METAL_WORKERS: {
        return "Gioiellieri e preziosi lavoratori in pietra e metallo";
      }
      case JobFunction.JOB_OPPORTUNITIES_IN_THE_ARMED_FORCES: {
        return "Opportunità di lavoro nelle forze armate";
      }
      case JobFunction.JUDGES_AND_HEARING_OFFICERS: {
        return "Giudici e uditori";
      }
      case JobFunction.JUDGES_MAGISTRATES_AND_OTHER_JUDICIAL_WORKERS: {
        return "Giudici, magistrati e altri lavoratori giudiziari";
      }
      case JobFunction.KINDERGARTEN_AND_ELEMENTARY_SCHOOL_TEACHERS: {
        return "Scuola materna e scuola elementare";
      }
      case JobFunction.LABOR_RELATIONS_SPECIALISTS: {
        return "Specialisti delle relazioni di lavoro";
      }
      case JobFunction.LANDSCAPE_ARCHITECTS: {
        return "Architetti paesaggistici";
      }
      case JobFunction.LAWYERS: {
        return "Avvocati";
      }
      case JobFunction.LIBRARIANS: {
        return "Bibliotecari";
      }
      case JobFunction.LIBRARY_TECHNICIANS_AND_ASSISTANTS: {
        return "Tecnici e assistenti della biblioteca";
      }
      case JobFunction.LICENSED_PRACTICAL_AND_LICENSED_VOCATIONAL_NURSES: {
        return "Infermieri professionali pratici e autorizzati con licenza";
      }
      case JobFunction.LINE_INSTALLERS_AND_REPAIRERS: {
        return "Installatori di linee e riparatori";
      }
      case JobFunction.LOAN_OFFICERS: {
        return "Ufficiali di prestito";
      }
      case JobFunction.LODGING_MANAGERS: {
        return "Manager di alloggio";
      }
      case JobFunction.LOGGING_WORKERS: {
        return "Servizi di registrazione";
      }
      case JobFunction.LOGISTICIANS: {
        return "Logisti";
      }
      case JobFunction.MACHINE_SETTERS_OPERATORS_AND_TENDERS_METAL_AND_PLASTIC: {
        return "Set di macchine, operatori e gare d'appalto-metal e";
      }
      case JobFunction.MACHINISTS: {
        return "Macchinisti";
      }
      case JobFunction.MACHINISTS_AND_TOOL_AND_DIE_MAKERS: {
        return "Macchinisti e produttori di strumenti e dado";
      }
      case JobFunction.MAINTENANCE_AND_REPAIR_WORKERS_GENERAL: {
        return "Operatori di manutenzione e riparazione, generale";
      }
      case JobFunction.MANAGEMENT_ANALYSTS: {
        return "Analisti di gestione";
      }
      case JobFunction.MANICURISTS_AND_PEDICURISTS: {
        return "Manicuristi e pedicuisti";
      }
      case JobFunction.MARINE_ENGINEERS_AND_NAVAL_ARCHITECTS: {
        return "Ingegneri marini e architetti navali";
      }
      case JobFunction.MARKET_AND_SURVEY_RESEARCHERS: {
        return "Ricercatori di mercato e di indagine";
      }
      case JobFunction.MARKET_RESEARCH_ANALYSTS: {
        return "Analisti delle ricerche di mercato";
      }
      case JobFunction.MARRIAGE_AND_FAMILY_THERAPISTS: {
        return "Terapisti del matrimonio e della famiglia";
      }
      case JobFunction.MASSAGE_THERAPISTS: {
        return "Massaggiatori";
      }
      case JobFunction.MATERIAL_MOVING_MACHINE_OPERATORS: {
        return "Operatori della macchina in movimento materiale";
      }
      case JobFunction.MATERIAL_MOVING_OCCUPATIONS: {
        return "Materiale in movimento professionale";
      }
      case JobFunction.MATERIAL_RECORDING_CLERKS: {
        return "Impieghi di registrazione materiale";
      }
      case JobFunction.MATERIALS_ENGINEERS: {
        return "Ingegneri di materiali";
      }
      case JobFunction.MATHEMATICIANS: {
        return "Matematici";
      }
      case JobFunction.MATHEMATICIANS_AND_STATISTICIANS: {
        return "Matematici e statistici";
      }
      case JobFunction.MECHANICAL_ENGINEERING_TECHNICIANS: {
        return "Tecnici di ingegneria meccanica";
      }
      case JobFunction.MECHANICAL_ENGINEERS: {
        return "Ingegneri meccanici";
      }
      case JobFunction.MEDICAL_AND_CLINICAL_LABORATORY_TECHNOLOGISTS_AND_TECHNICIANS: {
        return "Tecnologi di laboratorio medico e clinico e";
      }
      case JobFunction.MEDICAL_AND_HEALTH_SERVICES_MANAGERS: {
        return "Manager di servizi medici e sanitari";
      }
      case JobFunction.MEDICAL_ASSISTANTS: {
        return "Assistenti medici";
      }
      case JobFunction.MEDICAL_EQUIPMENT_REPAIRERS: {
        return "Riparatori di attrezzature mediche";
      }
      case JobFunction.MEDICAL_RECORDS_AND_HEALTH_INFORMATION_TECHNICIANS: {
        return "Cartelle cliniche e tecnici delle informazioni sanitarie";
      }
      case JobFunction.MEDICAL_SCIENTISTS: {
        return "Scienziati medici";
      }
      case JobFunction.MEDICAL_TRANSCRIPTIONISTS: {
        return "Trascritisti medici";
      }
      case JobFunction.MEDICAL_DENTAL_AND_OPHTHALMIC_LABORATORY_TECHNICIANS: {
        return "Tecnica di laboratorio medico, dentale e oftalmico";
      }
      case JobFunction.MEETING_AND_CONVENTION_PLANNERS: {
        return "Pianificatori di incontri e convention";
      }
      case JobFunction.MEETING_CONVENTION_AND_EVENT_PLANNERS: {
        return "Incontro, convention e pianificatori di eventi";
      }
      case JobFunction.METAL_AND_PLASTIC_MACHINE_WORKERS: {
        return "Operai in metallo e plastica";
      }
      case JobFunction.MICROBIOLOGISTS: {
        return "Microbiologi";
      }
      case JobFunction.MIDDLE_SCHOOL_TEACHERS: {
        return "Insegnanti della scuola media";
      }
      case JobFunction.MILITARY_CAREERS: {
        return "Carriere militari";
      }
      case JobFunction.MILLWRIGHTS: {
        return "Millwrights";
      }
      case JobFunction.MINING_AND_GEOLOGICAL_ENGINEERS: {
        return "Ingegneri minerari e geologici";
      }
      case JobFunction.MODELS: {
        return "Modelli";
      }
      case JobFunction.METER_READERS_UTILITIES: {
        return "Lettori del contatore, utility";
      }
      case JobFunction.MULTIMEDIA_ARTISTS_AND_ANIMATORS: {
        return "Artisti e animatori multimediali";
      }
      case JobFunction.MUSIC_DIRECTORS_AND_COMPOSERS: {
        return "Registi e compositori musicali";
      }
      case JobFunction.MUSICIANS_AND_SINGERS: {
        return "Musicisti e cantanti";
      }
      case JobFunction.MUSICIANS_SINGERS_AND_RELATED_WORKERS: {
        return "Musicisti, cantanti e lavoratori correlati";
      }
      case JobFunction.NATURAL_SCIENCES_MANAGERS: {
        return "Manager di scienze naturali";
      }
      case JobFunction.NETWORK_AND_COMPUTER_SYSTEMS_ADMINISTRATORS: {
        return "Amministratori di sistemi di rete e informatica";
      }
      case JobFunction.NEWS_ANALYSTS_REPORTERS_AND_CORRESPONDENTS: {
        return "Analisti, giornalisti e corrispondenti di notizie";
      }
      case JobFunction.NUCLEAR_ENGINEERS: {
        return "Ingegneri nucleari";
      }
      case JobFunction.NUCLEAR_MEDICINE_TECHNOLOGISTS: {
        return "Tecnologi della medicina nucleare";
      }
      case JobFunction.NUCLEAR_TECHNICIANS: {
        return "Tecnici nucleari";
      }
      case JobFunction.NURSE_ANESTHETISTS_NURSE_MIDWIVES_AND_NURSE_PRACTITIONERS: {
        return "Infermiera anestesisti, ostetriche infermiere e infermiera Prac";
      }
      case JobFunction.NURSING_ASSISTANTS_AND_ORDERLIES: {
        return "Assistenti e ordini infermieristici";
      }
      case JobFunction.NURSING_PSYCHIATRIC_AND_HOME_HEALTH_AIDES: {
        return "Aiutazioni infermieristiche, psichiatriche e sanitarie a domicilio";
      }
      case JobFunction.OCCUPATIONAL_HEALTH_AND_SAFETY_SPECIALISTS_AND_TECHNICIANS: {
        return "Specialisti e TEC per la salute e la sicurezza sul lavoro";
      }
      case JobFunction.OCCUPATIONAL_THERAPIST_ASSISTANTS_AND_AIDES: {
        return "Assistenti e assistenti terapisti occupazionali";
      }
      case JobFunction.OCCUPATIONAL_THERAPISTS: {
        return "Terapista occupazionale";
      }
      case JobFunction.OCCUPATIONAL_THERAPY_ASSISTANTS_AND_AIDES: {
        return "Assistenti e aiutanti della terapia occupazionale";
      }
      case JobFunction.OFFICE_AND_ADMINISTRATIVE_SUPPORT_WORKER_SUPERVISORS_AND_MANAGERS: {
        return "Superviso dei lavoratori di supporto per ufficio e amministrativo";
      }
      case JobFunction.OFFICE_CLERKS_GENERAL: {
        return "Impiegati di ufficio, generale";
      }
      case JobFunction.OPERATIONS_RESEARCH_ANALYSTS: {
        return "Analisti della ricerca sulle operazioni";
      }
      case JobFunction.OPTICIANS: {
        return "Ottici";
      }
      case JobFunction.OPTICIANS_DISPENSING: {
        return "Ottici, dispensare";
      }
      case JobFunction.OPTOMETRISTS: {
        return "Optometristi";
      }
      case JobFunction.ORDER_CLERKS: {
        return "Ordina impiegati";
      }
      case JobFunction.ORTHOTISTS_AND_PROSTHETISTS: {
        return "Ortoisti e protesisti";
      }
      case JobFunction.PAINTERS_AND_PAPERHANGERS: {
        return "Pittori e paperhengers";
      }
      case JobFunction.PAINTERS_CONSTRUCTION_AND_MAINTENANCE: {
        return "Pittori, costruzione e manutenzione";
      }
      case JobFunction.PAINTING_AND_COATING_WORKERS: {
        return "Dipingi e rivestimenti";
      }
      case JobFunction.PAINTING_AND_COATING_WORKERS_EXCEPT_CONSTRUCTION_AND_MAINTENANCE: {
        return "Dipingi e rivestimenti, tranne la costruzione";
      }
      case JobFunction.PARALEGALS_AND_LEGAL_ASSISTANTS: {
        return "Paralegali e assistenti legali";
      }
      case JobFunction.PAYROLL_AND_TIMEKEEPING_CLERKS: {
        return "Impiegati di buste paga e cronometri";
      }
      case JobFunction.PERSONAL_AND_HOME_CARE_AIDES: {
        return "Aidratori di assistenza personale e domestica";
      }
      case JobFunction.PERSONAL_FINANCIAL_ADVISORS: {
        return "Consulenti finanziari personali";
      }
      case JobFunction.PEST_CONTROL_WORKERS: {
        return "Operai di controllo dei parassiti";
      }
      case JobFunction.PETROLEUM_ENGINEERS: {
        return "Ingegneri petroliferi";
      }
      case JobFunction.PHARMACISTS: {
        return "Farmacisti";
      }
      case JobFunction.PHARMACY_AIDES: {
        return "Aidrati di farmacia";
      }
      case JobFunction.PHARMACY_TECHNICIANS: {
        return "Tecnici di farmacia";
      }
      case JobFunction.PHLEBOTOMISTS: {
        return "Flebotomisti";
      }
      case JobFunction.PHOTOGRAPHERS: {
        return "Fotografi";
      }
      case JobFunction.PHYSICAL_THERAPIST_ASSISTANTS_AND_AIDES: {
        return "Assistenti e aiutanti del fisioterapista";
      }
      case JobFunction.PHYSICAL_THERAPISTS: {
        return "Fisioterapisti";
      }
      case JobFunction.PHYSICIAN_ASSISTANTS: {
        return "Assistenti medici";
      }
      case JobFunction.PHYSICIANS_AND_SURGEONS: {
        return "Medici e chirurghi";
      }
      case JobFunction.PHYSICISTS_AND_ASTRONOMERS: {
        return "Fisici e astronomi";
      }
      case JobFunction.PIPELAYERS_PLUMBERS_PIPEFITTERS_AND_STEAMFITTERS: {
        return "Pipelayers, idraulici, pipefittenti e steamfitter";
      }
      case JobFunction.PHOTOGRAPHIC_PROCESS_WORKERS_AND_PROCESSING_MACHINE_OPERATORS: {
        return "Lavoratori del processo fotografico e macchina di lavorazione";
      }
      case JobFunction.PLUMBERS_PIPEFITTERS_AND_STEAMFITTERS: {
        return "Plumbers, Pipefitters e Steamfitters";
      }
      case JobFunction.PODIATRISTS: {
        return "Podologi";
      }
      case JobFunction.POLICE_AND_DETECTIVES: {
        return "Polizia e investigatori";
      }
      case JobFunction.POLICE_FIRE_AND_AMBULANCE_DISPATCHERS: {
        return "Dispatti della polizia, del fuoco e delle ambulanze";
      }
      case JobFunction.POLITICAL_SCIENTISTS: {
        return "Scienziati politici";
      }
      case JobFunction.POSTAL_SERVICE_WORKERS: {
        return "Operatori di servizio postale";
      }
      case JobFunction.POSTSECONDARY_EDUCATION_ADMINISTRATORS: {
        return "Amministratori dell'istruzione post -secondaria";
      }
      case JobFunction.POSTSECONDARY_TEACHERS: {
        return "Insegnanti post -secondari";
      }
      case JobFunction.PLASTERERS_AND_STUCCO_MASONS: {
        return "Intonacatori e muratori di stucco";
      }
      case JobFunction.POWER_PLANT_OPERATORS_DISTRIBUTORS_AND_DISPATCHERS: {
        return "Operatori, distributori e dispatche";
      }
      case JobFunction.PRESCHOOL_AND_CHILDCARE_CENTER_DIRECTORS: {
        return "Direttori del Centro per la scuola materna e di assistenza all'infanzia";
      }
      case JobFunction.PRESCHOOL_TEACHERS: {
        return "Insegnanti in età prescolare";
      }
      case JobFunction.PRIVATE_DETECTIVES_AND_INVESTIGATORS: {
        return "Detective e investigatori privati";
      }
      case JobFunction.PROBATION_OFFICERS_AND_CORRECTIONAL_TREATMENT_SPECIALISTS: {
        return "Officer di libertà vigilata e specifiche di trattamento correttivo";
      }
      case JobFunction.PROCUREMENT_CLERKS: {
        return "Impiegati di approvvigionamento";
      }
      case JobFunction.PROCUREMENT_MANAGER: {
        return "Responsabile degli acquisti";
      }
      case JobFunction.PRODUCERS_AND_DIRECTORS: {
        return "Produttori e registi";
      }
      case JobFunction.PRODUCTION_PLANNING_AND_EXPEDITING_CLERKS: {
        return "Impieghi di produzione, pianificazione e accelerazione";
      }
      case JobFunction.PROPERTY_REAL_ESTATE_AND_COMMUNITY_ASSOCIATION_MANAGERS: {
        return "Proprietà, immobili e associazioni comunitarie m";
      }
      case JobFunction.PRECISION_INSTRUMENT_AND_EQUIPMENT_REPAIRERS: {
        return "Strumento di precisione e riparatori di attrezzature";
      }
      case JobFunction.PSYCHIATRIC_TECHNICIANS_AND_AIDES: {
        return "Tecnici psichiatrici e aiutanti";
      }
      case JobFunction.PREPRESS_TECHNICIANS_AND_WORKERS: {
        return "Prepressa tecnici e lavoratori";
      }
      case JobFunction.PSYCHOLOGISTS: {
        return "Psicologi";
      }
      case JobFunction.PUBLIC_RELATIONS_AND_FUNDRAISING_MANAGERS: {
        return "Relazioni pubbliche e gestori di raccolta fondi";
      }
      case JobFunction.PUBLIC_RELATIONS_SPECIALISTS: {
        return "Specialisti delle pubbliche relazioni";
      }
      case JobFunction.PURCHASING_MANAGERS_BUYERS_AND_PURCHASING_AGENTS: {
        return "Manager per acquisti, acquirenti e agenti acquisti";
      }
      case JobFunction.PRINTING_MACHINE_OPERATORS: {
        return "Operatori della macchina da stampa";
      }
      case JobFunction.QUALITY_CONTROL_INSPECTORS: {
        return "Ispettori di controllo di qualità";
      }
      case JobFunction.RADIATION_THERAPISTS: {
        return "Radiazioni";
      }
      case JobFunction.RADIO_AND_TELECOMMUNICATIONS_EQUIPMENT_INSTALLERS_AND_REPAIRERS: {
        return "Installatori di apparecchiature radio e telecomunicazioni";
      }
      case JobFunction.RADIOLOGIC_AND_MRI_TECHNOLOGISTS: {
        return "Tecnologi radiologici e MRI";
      }
      case JobFunction.RADIOLOGIC_TECHNOLOGISTS_AND_TECHNICIANS: {
        return "Tecnologi e tecnici radiologici";
      }
      case JobFunction.RAIL_TRANSPORTATION_OCCUPATIONS: {
        return "Professioni di trasporto ferroviario";
      }
      case JobFunction.RAILROAD_WORKERS: {
        return "Lavoro ferroviario";
      }
      case JobFunction.REAL_ESTATE_BROKERS_AND_SALES_AGENTS: {
        return "I broker immobiliari e gli agenti di vendita";
      }
      case JobFunction.RECEPTIONISTS: {
        return "Receptionist";
      }
      case JobFunction.RECEPTIONISTS_AND_INFORMATION_CLERKS: {
        return "Receptionist e impiegati di informazione";
      }
      case JobFunction.RECREATION_WORKERS: {
        return "Lavoratori ricreativi";
      }
      case JobFunction.RECREATIONAL_THERAPISTS: {
        return "Terapisti ricreativi";
      }
      case JobFunction.REGISTERED_NURSES: {
        return "Infermieri registrati";
      }
      case JobFunction.REHABILITATION_COUNSELORS: {
        return "Consulenti di riabilitazione";
      }
      case JobFunction.REPORTERS_CORRESPONDENTS_AND_BROADCAST_NEWS_ANALYSTS: {
        return "Reporter, corrispondenti e notizie trasmesse anali";
      }
      case JobFunction.RESERVATION_AND_TRANSPORTATION_TICKET_AGENTS_AND_TRAVEL_CLERKS: {
        return "Agenti dei biglietti per la prenotazione e dei trasporti e T";
      }
      case JobFunction.RESPIRATORY_THERAPISTS: {
        return "Terapisti respiratori";
      }
      case JobFunction.RETAIL_SALES_WORKERS: {
        return "Operatori di vendita al dettaglio";
      }
      case JobFunction.RETAIL_SALESPERSONS: {
        return "Venditori al dettaglio";
      }
      case JobFunction.ROOFERS: {
        return "Tetti";
      }
      case JobFunction.SALES_ENGINEERS: {
        return "Ingegneri di vendita";
      }
      case JobFunction.SALES_MANAGERS: {
        return "Gestori delle vendite";
      }
      case JobFunction.SALES_REPRESENTATIVES_WHOLESALE_AND_MANUFACTURING: {
        return "Rappresentanti di vendita, all'ingrosso e manifatturiero";
      }
      case JobFunction.SCHOOL_AND_CAREER_COUNSELORS: {
        return "Consulenti scolastici e di carriera";
      }
      case JobFunction.SECRETARIES_AND_ADMINISTRATIVE_ASSISTANTS: {
        return "Segretari e assistenti amministrativi";
      }
      case JobFunction.SECURITIES_COMMODITIES_AND_FINANCIAL_SERVICES_SALES_AGENTS: {
        return "Titoli, materie prime e servizi finanziari SA";
      }
      case JobFunction.SECURITY_GUARDS_AND_GAMING_SURVEILLANCE_OFFICERS: {
        return "Guardie di sicurezza e ufficiali di sorveglianza dei giochi";
      }
      case JobFunction.SHEET_METAL_WORKERS: {
        return "Late -metal operaio";
      }
      case JobFunction.SHIPPING_RECEIVING_AND_TRAFFIC_CLERKS: {
        return "Spedizione, ricezione e impiegati del traffico";
      }
      case JobFunction.SKINCARE_SPECIALISTS: {
        return "Specialisti della cura della pelle";
      }
      case JobFunction.SMALL_ENGINE_MECHANICS: {
        return "Meccanica di piccoli motori";
      }
      case JobFunction.SOCIAL_AND_COMMUNITY_SERVICE_MANAGERS: {
        return "Manager di servizi sociali e comunitari";
      }
      case JobFunction.SOCIAL_AND_HUMAN_SERVICE_ASSISTANTS: {
        return "Assistenti di servizio sociale e umano";
      }
      case JobFunction.SOCIAL_SCIENTISTS_OTHER: {
        return "Scienziati sociali, altro";
      }
      case JobFunction.SOCIAL_WORKERS: {
        return "Lavoratori sociali";
      }
      case JobFunction.SALES_WORKER_SUPERVISORS: {
        return "Supervisori degli operatori di vendita";
      }
      case JobFunction.SOCIOLOGISTS: {
        return "Sociologi";
      }
      case JobFunction.SOFTWARE_DEVELOPERS: {
        return "Sviluppatori di software";
      }
      case JobFunction.SCIENCE_TECHNICIANS: {
        return "Tecnici scientifici";
      }
      case JobFunction.SOLAR_PHOTOVOLTAIC_INSTALLERS: {
        return "Installatori di fotovoltaici solari";
      }
      case JobFunction.SPECIAL_EDUCATION_TEACHERS: {
        return "Insegnanti di educazione speciale";
      }
      case JobFunction.SPEECH_LANGUAGE_PATHOLOGISTS: {
        return "Patologi del linguaggio";
      }
      case JobFunction.STATIONARY_ENGINEERS_AND_BOILER_OPERATORS: {
        return "Ingegneri fissi e operatori della caldaia";
      }
      case JobFunction.SEMICONDUCTOR_PROCESSORS: {
        return "Processori a semiconduttore";
      }
      case JobFunction.STATISTICIANS: {
        return "Statistici";
      }
      case JobFunction.STOCK_CLERKS_AND_ORDER_FILLERS: {
        return "Impieghi e riempitivi degli ordini";
      }
      case JobFunction.STRUCTURAL_AND_REINFORCING_IRON_AND_METAL_WORKERS: {
        return "Lavoratori strutturali e rinforzanti di ferro e metallo";
      }
      case JobFunction.SUBSTANCE_ABUSE_BEHAVIORAL_DISORDER_AND_MENTAL_HEALTH_COUNSELORS: {
        return "Abuso di sostanze, disturbo comportamentale e h mentale";
      }
      case JobFunction.SUPPLY_MANAGEMENT: {
        return "Gestione della fornitura";
      }
      case JobFunction.SURGICAL_TECHNOLOGISTS: {
        return "Tecnologi chirurgici";
      }
      case JobFunction.SURVEY_RESEARCHERS: {
        return "Ricercatori del sondaggio";
      }
      case JobFunction.SURVEYING_AND_MAPPING_TECHNICIANS: {
        return "I tecnici di rilevamento e mappatura";
      }
      case JobFunction.SURVEYORS: {
        return "Surveers";
      }
      case JobFunction.SURVEYORS_CARTOGRAPHERS_PHOTOGRAMMETRISTS_AND_SURVEYING_AND_MAPPING_TECHNICIANS: {
        return "Survesor, cartografi, fotogrammetristi e s";
      }
      case JobFunction.TAX_EXAMINERS_AND_COLLECTORS_AND_REVENUE_AGENTS: {
        return "Esaminatori e collettori fiscali e agenti delle entrate";
      }
      case JobFunction.TAX_EXAMINERS_COLLECTORS_AND_REVENUE_AGENTS: {
        return "Esaminatori fiscali, collettori e agenti delle entrate";
      }
      case JobFunction.TAXI_DRIVERS_AND_CHAUFFEURS: {
        return "Tassisti e autisti";
      }
      case JobFunction.TAXI_DRIVERS_RIDE_HAILING_DRIVERS_AND_CHAUFFEURS: {
        return "Tassisti, conducenti da viaggio e autisti";
      }
      case JobFunction.TEACHER_ASSISTANTS: {
        return "Assistenti insegnanti";
      }
      case JobFunction.TEACHERS_ADULT_LITERACY_AND_REMEDIAL_EDUCATION: {
        return "Teachers-Adult Literacy and Ridedial Education";
      }
      case JobFunction.TEACHERS_POSTSECONDARY: {
        return "Insegnanti-Postsecondarie";
      }
      case JobFunction.TEACHERS_PRESCHOOL_KINDERGARTEN_ELEMENTARY_MIDDLE_AND_SECONDARY: {
        return "Insegnanti-Preschool, Kindergarten, Elementary, Midd";
      }
      case JobFunction.TEACHERS_SPECIAL_EDUCATION: {
        return "Educazione agli insegnanti-speciali";
      }
      case JobFunction.TECHNICAL_WRITERS: {
        return "Scrittori tecnici";
      }
      case JobFunction.TELECOMMUNICATIONS_EQUIPMENT_INSTALLERS_AND_REPAIRERS: {
        return "Installatori di apparecchiature di telecomunicazioni e riparazione";
      }
      case JobFunction.TELEVISION_VIDEO_AND_MOTION_PICTURE_CAMERA_OPERATORS_AND_EDITORS: {
        return "Opera televisiva, video e fotocamera cinematografica";
      }
      case JobFunction.TELLERS: {
        return "Cassiere";
      }
      case JobFunction.TOOL_AND_DIE_MAKERS: {
        return "Produttori di strumenti e dado";
      }
      case JobFunction.TOP_EXECUTIVES: {
        return "Top Executives";
      }
      case JobFunction.TRAINING_AND_DEVELOPMENT_MANAGERS: {
        return "Manager di formazione e sviluppo";
      }
      case JobFunction.TRAINING_AND_DEVELOPMENT_SPECIALISTS: {
        return "Specialisti di formazione e sviluppo";
      }
      case JobFunction.TRAVEL_AGENTS: {
        return "Agenti di viaggio";
      }
      case JobFunction.TRUCK_DRIVERS_AND_DRIVERSALES_WORKERS: {
        return "Camionisti e lavoratori autista/vendite";
      }
      case JobFunction.UMPIRES_REFEREES_AND_OTHER_SPORTS_OFFICIALS: {
        return "Arbitri, arbitri e altri funzionari sportivi";
      }
      case JobFunction.URBAN_AND_REGIONAL_PLANNERS: {
        return "Pianificatori urbani e regionali";
      }
      case JobFunction.VETERINARIANS: {
        return "Veterinari";
      }
      case JobFunction.TEACHERS_SELF_ENRICHMENT_EDUCATION: {
        return "Educazione agli insegnanti-sé";
      }
      case JobFunction.VETERINARY_ASSISTANTS_AND_LABORATORY_ANIMAL_CARETAKERS: {
        return "Assistenti veterinari e animali da laboratorio";
      }
      case JobFunction.VETERINARY_TECHNOLOGISTS_AND_TECHNICIANS: {
        return "Tecnologi e tecnici veterinari";
      }
      case JobFunction.WAITERS_AND_WAITRESSES: {
        return "Camerieri e cameriere";
      }
      case JobFunction.WATER_AND_LIQUID_WASTE_TREATMENT_PLANT_AND_SYSTEM_OPERATORS: {
        return "Impianto e sistema di trattamento dei rifiuti idrici e liquidi";
      }
      case JobFunction.WATER_AND_WASTEWATER_TREATMENT_PLANT_AND_SYSTEM_OPERATORS: {
        return "Impianto di trattamento dell'acqua e delle acque reflue e sistema OP";
      }
      case JobFunction.WATER_TRANSPORTATION_OCCUPATIONS: {
        return "Professioni di trasporto dell'acqua";
      }
      case JobFunction.TEXTILE_APPAREL_AND_FURNISHINGS_OCCUPATIONS: {
        return "Occupazioni tessili, abbigliamento e arredi";
      }
      case JobFunction.WATER_TRANSPORTATION_WORKERS: {
        return "Lavoratori di trasporto dell'acqua";
      }
      case JobFunction.WEB_DEVELOPERS: {
        return "Sviluppatori web";
      }
      case JobFunction.WEIGHERS_MEASURERS_CHECKERS_AND_SAMPLERS_RECORDKEEPING: {
        return "Pesati, misuratori, pedalatori e campionatori, ricorrono";
      }
      case JobFunction.WELDERS_CUTTERS_SOLDERERS_AND_BRAZERS: {
        return "Saldatori, tagliatori, saldatrici e brasalatori";
      }
      case JobFunction.WELDING_SOLDERING_AND_BRAZING_WORKERS: {
        return "Saldatura, saldatura e brasatura";
      }
      case JobFunction.WHOLESALE_AND_MANUFACTURING_SALES_REPRESENTATIVES: {
        return "Rappresentanti di vendita all'ingrosso e manifatturiero";
      }
      case JobFunction.WIND_TURBINE_TECHNICIANS: {
        return "Tecnici delle turbine eoliche";
      }
      case JobFunction.WOODWORKERS: {
        return "Falegnami";
      }
      case JobFunction.WRITERS_AND_AUTHORS: {
        return "Scrittori e autori";
      }
      case JobFunction.WRITERS_AND_EDITORS: {
        return "Scrittori ed editori";
      }
      case JobFunction.ZOOLOGISTS_AND_WILDLIFE_BIOLOGISTS: {
        return "Zoologi e biologi della fauna selvatica";
      }
      case JobFunction.OTHER: {
        return "Altro";
      }
    }

    return "";
  }

  private static getJobFunctionTitleInGerman(jobFunction: JobFunction): string {
    switch (jobFunction) {
      case JobFunction.ACCOUNTANTS_AND_AUDITORS: {
        return "Buchhalter und Wirtschaftsprüfer";
      }
      case JobFunction.ACTORS_PRODUCERS_AND_DIRECTORS: {
        return "Schauspieler, Produzenten und Regisseure";
      }
      case JobFunction.ACTUARIES: {
        return "Versicherungsmathematiker";
      }
      case JobFunction.ADMINISTRATIVE_SERVICES_MANAGERS: {
        return "Manager für Verwaltungsdienste";
      }
      case JobFunction.ADULT_LITERACY_AND_HIGH_SCHOOL_EQUIVALENCY_DIPLOMA_TEACHERS: {
        return "Alphabetisierung für Erwachsene und Highschool -Äquivalenzdiplomlehrer";
      }
      case JobFunction.ADVERTISING_SALES_AGENTS: {
        return "Werbekaufleute";
      }
      case JobFunction.ADVERTISING_MARKETING_PROMOTIONS_PUBLIC_RELATIONS_AND_SALES_MANAGERS: {
        return "Werbung, Marketing, Werbeaktionen, Öffentlichkeitsarbeit und Vertriebsleiter";
      }
      case JobFunction.AEROSPACE_ENGINEERING_AND_OPERATIONS_TECHNICIANS: {
        return "Luft- und Raumfahrttechniker und Betriebstechniker";
      }
      case JobFunction.AEROSPACE_ENGINEERS: {
        return "Luft- und Raumfahrtingenieure";
      }
      case JobFunction.AGRICULTURAL_AND_FOOD_SCIENCE_TECHNICIANS: {
        return "Techniker für Landwirtschaft und Lebensmittelwissenschaft";
      }
      case JobFunction.AGRICULTURAL_AND_FOOD_SCIENTISTS: {
        return "Agrarwissenschaftler und Lebensmittelwissenschaftler";
      }
      case JobFunction.AGRICULTURAL_ENGINEERS: {
        return "Agraringenieure";
      }
      case JobFunction.AGRICULTURAL_WORKERS: {
        return "Beschäftigte in der Landwirtschaft";
      }
      case JobFunction.AIR_TRAFFIC_CONTROLLERS: {
        return "Fluglotsen";
      }
      case JobFunction.AIRCRAFT_AND_AVIONICS_EQUIPMENT_MECHANICS_AND_SERVICE_TECHNICIANS: {
        return "Mechaniker und Servicetechniker von Flugzeugen und Avionikgeräten";
      }
      case JobFunction.AIRCRAFT_PILOTS_AND_FLIGHT_ENGINEERS: {
        return "Luftfahrzeugpiloten und Flugingenieure";
      }
      case JobFunction.ANIMAL_CARE_AND_SERVICE_WORKERS: {
        return "Tierpfleger und -servicemitarbeiter";
      }
      case JobFunction.ANNOUNCERS: {
        return "Ansager";
      }
      case JobFunction.ANTHROPOLOGISTS_AND_ARCHEOLOGISTS: {
        return "Anthropologen und Archäologen";
      }
      case JobFunction.APPRAISERS_AND_ASSESSORS_OF_REAL_ESTATE: {
        return "Gutachter für Immobilien";
      }
      case JobFunction.ARBITRATORS_MEDIATORS_AND_CONCILIATORS: {
        return "Schlichter, Mediatoren";
      }
      case JobFunction.ARCHITECTS_EXCEPT_LANDSCAPE_AND_NAVAL: {
        return "Architekten, außer Landschafts- und Schiffbau-";
      }
      case JobFunction.ARCHITECTURAL_AND_ENGINEERING_MANAGERS: {
        return "Leiter von Architektur- und Ingenieurbüros";
      }
      case JobFunction.ARCHIVISTS_CURATORS_AND_MUSEUM_TECHNICIANS: {
        return "Archivare, Kuratoren und Museumstechniker";
      }
      case JobFunction.ART_DIRECTORS: {
        return "Künstlerische Leiter";
      }
      case JobFunction.ARTISTS_AND_RELATED_WORKERS: {
        return "Künstler und verwandte Berufe";
      }
      case JobFunction.ASSEMBLERS_AND_FABRICATORS: {
        return "Monteure und Produktionsmitarbeiter";
      }
      case JobFunction.ATHLETES_COACHES_UMPIRES_AND_RELATED_WORKERS: {
        return "Athleten, Trainer, Schiedsrichter und verwandte Berufe";
      }
      case JobFunction.ATHLETIC_TRAINERS: {
        return "Leichtathletik-TrainerIn";
      }
      case JobFunction.ATMOSPHERIC_SCIENTISTS: {
        return "Atmosphärenforscher";
      }
      case JobFunction.AUDIOLOGISTS: {
        return "Hörgeräteakustiker";
      }
      case JobFunction.AUTOMOTIVE_BODY_AND_RELATED_REPAIRERS: {
        return "Autokarosserie- und verwandte Reparaturbetriebe";
      }
      case JobFunction.AUTOMOTIVE_SERVICE_TECHNICIANS_AND_MECHANICS: {
        return "Kfz-Servicetechniker und -mechaniker";
      }
      case JobFunction.BAKERS: {
        return "Bäcker";
      }
      case JobFunction.BARBERS_COSMETOLOGISTS_AND_OTHER_PERSONAL_APPEARANCE_WORKERS: {
        return "Friseur, Kosmetiker und andere persönliche Aussehensarbeiter";
      }
      case JobFunction.BARTENDERS: {
        return "Barkeeper";
      }
      case JobFunction.BILL_AND_ACCOUNT_COLLECTORS: {
        return "Inkassobeauftragte und Rechnungsprüfer";
      }
      case JobFunction.BILLING_AND_POSTING_CLERKS_AND_MACHINE_OPERATORS: {
        return "Sachbearbeiter für Rechnungsstellung";
      }
      case JobFunction.BIOLOGICAL_SCIENTISTS: {
        return "Biologe";
      }
      case JobFunction.BIOLOGICAL_TECHNICIANS: {
        return "Biologisch-technische Assistenten";
      }
      case JobFunction.BIOMEDICAL_ENGINEERS: {
        return "Biomedizin-Ingenieure";
      }
      case JobFunction.BOILERMAKERS: {
        return "Kesselschmied";
      }
      case JobFunction.BOOKBINDERS_AND_BINDERY_WORKERS: {
        return "Buchbinder und Buchbindereibedienstete";
      }
      case JobFunction.BOOKKEEPING_ACCOUNTING_AND_AUDITING_CLERKS: {
        return "Angestellte in der Buchhaltung, Rechnungslegung und Wirtschaftsprüfung";
      }
      case JobFunction.BRICKMASONS_BLOCKMASONS_AND_STONEMASONS: {
        return "Maurer, Steinmetze und Steinbildhauer";
      }
      case JobFunction.BROADCAST_AND_SOUND_ENGINEERING_TECHNICIANS_AND_RADIO_OPERATORS: {
        return "Rundfunk- und Sound -Engineering -Techniker und Funkbetreiber";
      }
      case JobFunction.BROKERAGE_CLERKS: {
        return "Sachbearbeiter im Maklergewerbe";
      }
      case JobFunction.BUDGET_ANALYSTS: {
        return "Haushaltsanalysten";
      }
      case JobFunction.BUILDING_CLEANING_WORKERS: {
        return "Gebäudereiniger";
      }
      case JobFunction.BUS_DRIVERS: {
        return "Busfahrer";
      }
      case JobFunction.BUTCHERS: {
        return "Metzger";
      }
      case JobFunction.CARDIOVASCULAR_TECHNOLOGISTS_AND_TECHNICIANS: {
        return "Kardiovaskuläre Technologen und Techniker";
      }
      case JobFunction.CAREER_AND_TECHNICAL_EDUCATION_TEACHERS: {
        return "Lehrer für berufliche und technische Bildung";
      }
      case JobFunction.CARGO_AND_FREIGHT_AGENTS: {
        return "Spediteure und Frachtagenten";
      }
      case JobFunction.CARPENTERS: {
        return "Tischler";
      }
      case JobFunction.CARPET_FLOOR_AND_TILE_INSTALLERS_AND_FINISHERS: {
        return "Teppich-, Boden- und Fliesenleger und -verlegerinnen";
      }
      case JobFunction.CARTOGRAPHERS_AND_PHOTOGRAMMETRISTS: {
        return "Kartographen und Fotogrammetristen";
      }
      case JobFunction.CASHIERS: {
        return "Kassierer";
      }
      case JobFunction.CEMENT_MASONS_CONCRETE_FINISHERS_SEGMENTAL_PAVERS_AND_TERRAZZO_WORKERS: {
        return "Zementmauer, Beton -Finisher, segmentale Pflastersteine und Terrazzo -Arbeiter";
      }
      case JobFunction.CHEFS_AND_HEAD_COOKS: {
        return "Köche und Chefköche";
      }
      case JobFunction.CHEFS_COOKS_AND_FOOD_PREPARATION_WORKERS: {
        return "Chefköche, Köche und Mitarbeiter in der Lebensmittelzubereitung";
      }
      case JobFunction.CHEMICAL_ENGINEERS: {
        return "Chemie-Ingenieure";
      }
      case JobFunction.CHEMICAL_TECHNICIANS: {
        return "Chemotechniker";
      }
      case JobFunction.CHEMISTS_AND_MATERIALS_SCIENTISTS: {
        return "Chemiker und Materialwissenschaftler";
      }
      case JobFunction.CHILD_CARE_WORKERS: {
        return "Kinderbetreuer";
      }
      case JobFunction.CHIROPRACTORS: {
        return "Chiropraktiker";
      }
      case JobFunction.CIVIL_ENGINEERING_TECHNICIANS: {
        return "Techniker im Bauwesen";
      }
      case JobFunction.CIVIL_ENGINEERS: {
        return "Bauingenieure";
      }
      case JobFunction.CLAIMS_ADJUSTERS_APPRAISERS_EXAMINERS_AND_INVESTIGATORS: {
        return "Ansprüchen, Angaben, Gutachter, Prüfer und Ermittler";
      }
      case JobFunction.CLINICAL_LABORATORY_TECHNOLOGISTS_AND_TECHNICIANS: {
        return "Klinische Labortechniker und Techniker";
      }
      case JobFunction.COACHES_AND_SCOUTS: {
        return "Coaches und Pfadfinder";
      }
      case JobFunction.COIN_VENDING_AND_AMUSEMENT_MACHINE_SERVICERS_AND_REPAIRERS: {
        return "Servicer und Reparaturen für Münz-, Verkaufs- und Vergnügungspartner";
      }
      case JobFunction.COMMERCIAL_AND_INDUSTRIAL_DESIGNERS: {
        return "Handels- und Industriedesigner";
      }
      case JobFunction.COMMERCIAL_MANAGEMENT: {
        return "Kommerzielles Management";
      }
      case JobFunction.COMMUNICATIONS_EQUIPMENT_OPERATORS: {
        return "Bediener von Kommunikationsgeräten";
      }
      case JobFunction.COMPENSATION_AND_BENEFITS_MANAGERS: {
        return "Manager für Vergütungen und Sozialleistungen";
      }
      case JobFunction.COMPENSATION_BENEFITS_AND_JOB_ANALYSIS_SPECIALISTS: {
        return "Spezialisten für Vergütung, Leistungen und Jobanalyse";
      }
      case JobFunction.COMPUTER_AND_INFORMATION_RESEARCH_SCIENTISTS: {
        return "Wissenschaftler in der Computer- und Informationsforschung";
      }
      case JobFunction.COMPUTER_AND_INFORMATION_SYSTEMS_MANAGERS: {
        return "Manager für Computer- und Informationssysteme";
      }
      case JobFunction.COMPUTER_CONTROL_PROGRAMMERS_AND_OPERATORS: {
        return "Programmierer und Bediener von Computersteuerungen";
      }
      case JobFunction.COMPUTER_HARDWARE_ENGINEERS: {
        return "Computer-Hardware-Ingenieure";
      }
      case JobFunction.COMPUTER_NETWORK_ARCHITECTS: {
        return "Computernetzwerk-Architekten";
      }
      case JobFunction.COMPUTER_OPERATORS: {
        return "Computer-Bediener";
      }
      case JobFunction.COMPUTER_PROGRAMMERS: {
        return "Computer-Programmierer";
      }
      case JobFunction.COMPUTER_SCIENTISTS_AND_DATABASE_ADMINISTRATORS: {
        return "Informatiker und Datenbankadministratoren";
      }
      case JobFunction.COMPUTER_SOFTWARE_ENGINEERS: {
        return "Computer-Software-Ingenieure";
      }
      case JobFunction.COMPUTER_SUPPORT_SPECIALISTS: {
        return "Computer-Support-Spezialisten";
      }
      case JobFunction.COMPUTER_SUPPORT_SPECIALISTS_AND_SYSTEMS_ADMINISTRATORS: {
        return "Computer -Support -Spezialisten und Systemadministratoren";
      }
      case JobFunction.COMPUTER_SYSTEMS_ANALYSTS: {
        return "Computer-Systemanalytiker";
      }
      case JobFunction.COMPUTER_AUTOMATED_TELLER_AND_OFFICE_MACHINE_REPAIRERS: {
        return "Computer, automatisierte Kassierer und Reparaturen für Büromaschine";
      }
      case JobFunction.CONSERVATION_SCIENTISTS_AND_FORESTERS: {
        return "Naturwissenschaftler und Förster";
      }
      case JobFunction.CONSTRUCTION_AND_BUILDING_INSPECTORS: {
        return "Bau- und Gebäudeinspektoren";
      }
      case JobFunction.CONSTRUCTION_EQUIPMENT_OPERATORS: {
        return "Bediener von Baumaschinen";
      }
      case JobFunction.CONSTRUCTION_LABORERS: {
        return "Bauhandwerker";
      }
      case JobFunction.CONSTRUCTION_LABORERS_AND_HELPERS: {
        return "Bauhandwerker und Bauhilfskräfte";
      }
      case JobFunction.CONSTRUCTION_MANAGERS: {
        return "Bauleiter";
      }
      case JobFunction.COOKS: {
        return "Köche";
      }
      case JobFunction.CORRECTIONAL_OFFICERS: {
        return "Justizvollzugsbeamte";
      }
      case JobFunction.CONTRACT_MANAGEMENT: {
        return "Vertragsmanagement";
      }
      case JobFunction.CORRECTIONAL_OFFICERS_AND_BAILIFFS: {
        return "Justizvollzugsbeamte und Gerichtsvollzieher";
      }
      case JobFunction.COST_ESTIMATORS: {
        return "Kostenschätzer";
      }
      case JobFunction.COUNSELORS: {
        return "Seelsorger Übersetzt mit www.DeepL.com/Translator (kostenlose Version)";
      }
      case JobFunction.COUNTER_AND_RENTAL_CLERKS: {
        return "Schalterbeamte und Vermietungsbeamte";
      }
      case JobFunction.COURIERS_AND_MESSENGERS: {
        return "Kuriere und Boten";
      }
      case JobFunction.COURT_REPORTERS: {
        return "Hofberichterstatter";
      }
      case JobFunction.CRAFT_AND_FINE_ARTISTS: {
        return "Kunsthandwerker und bildende Künstler";
      }
      case JobFunction.CREDIT_AUTHORIZERS_CHECKERS_AND_CLERKS: {
        return "Kreditermächtiger, Prüfer und Angestellte";
      }
      case JobFunction.CUSTOMER_SERVICE_REPRESENTATIVES: {
        return "Kundenbetreuer";
      }
      case JobFunction.DANCERS_AND_CHOREOGRAPHERS: {
        return "Tänzer und Choreographen";
      }
      case JobFunction.DATA_ENTRY_AND_INFORMATION_PROCESSING_WORKERS: {
        return "Dateneingabe- und Informationsverarbeitungsmitarbeiter";
      }
      case JobFunction.DATABASE_ADMINISTRATORS: {
        return "Datenbankadministratoren";
      }
      case JobFunction.DELIVERY_TRUCK_DRIVERS_AND_DRIVERSALES_WORKERS: {
        return "Fahrer von Lieferwagen und Fahrer/Verkäufer";
      }
      case JobFunction.DEMONSTRATORS_PRODUCT_PROMOTERS_AND_MODELS: {
        return "Vorführer, Produktpromoter und Modelle";
      }
      case JobFunction.DENTAL_AND_OPHTHALMIC_LABORATORY_TECHNICIANS_AND_MEDICAL_APPLIANCE_TECHNICIANS: {
        return "Dental- und Ophthalmic Laboratory Technicians und Medical Appliance Technicians";
      }
      case JobFunction.DENTAL_ASSISTANTS: {
        return "Zahnmedizinische Fachangestellte";
      }
      case JobFunction.DENTAL_HYGIENISTS: {
        return "Dentalhygieniker";
      }
      case JobFunction.DENTISTS: {
        return "Zahnärzte";
      }
      case JobFunction.DESKTOP_PUBLISHERS: {
        return "Desktop-Publisher";
      }
      case JobFunction.DIAGNOSTIC_MEDICAL_SONOGRAPHERS: {
        return "Diagnostische medizinische Sonographen";
      }
      case JobFunction.DIESEL_SERVICE_TECHNICIANS_AND_MECHANICS: {
        return "Dieselservicetechniker und -mechaniker";
      }
      case JobFunction.DIETITIANS_AND_NUTRITIONISTS: {
        return "Diätassistenten und Ernährungswissenschaftler";
      }
      case JobFunction.DISPATCHERS: {
        return "Fahrdienstleiter";
      }
      case JobFunction.DRAFTERS: {
        return "Technische Zeichner";
      }
      case JobFunction.DRYWALL_AND_CEILING_TILE_INSTALLERS_AND_TAPERS: {
        return "Trockenbauer, Deckenfliesenleger und Fliesenleger";
      }
      case JobFunction.DRYWALL_INSTALLERS_CEILING_TILE_INSTALLERS_AND_TAPERS: {
        return "Trockenbau -Installateure, Deckenflieseninstallateure und Verjüngungszeiten";
      }
      case JobFunction.ECONOMISTS: {
        return "Wirtschaftswissenschaftler";
      }
      case JobFunction.EDITORS: {
        return "Redakteure";
      }
      case JobFunction.EDUCATION_ADMINISTRATORS: {
        return "Bildungsadministratoren";
      }
      case JobFunction.ELECTRICAL_AND_ELECTRONICS_ENGINEERING_TECHNICIANS: {
        return "Techniker für Elektrotechnik und Elektronik";
      }
      case JobFunction.ELECTRICAL_AND_ELECTRONICS_ENGINEERS: {
        return "Elektro- und Elektronikingenieure";
      }
      case JobFunction.ELECTRICAL_AND_ELECTRONICS_INSTALLERS_AND_REPAIRERS: {
        return "Installateure und Reparaturen von Elektro- und Elektronik";
      }
      case JobFunction.ELECTRICIANS: {
        return "Elektriker";
      }
      case JobFunction.ELECTRO_MECHANICAL_TECHNICIANS: {
        return "Elektro-mechanische Techniker";
      }
      case JobFunction.ELECTRONIC_HOME_ENTERTAINMENT_EQUIPMENT_INSTALLERS_AND_REPAIRERS: {
        return "Installateure und Reparaturen für elektronische Heimunterhaltungsgeräte und Reparaturen";
      }
      case JobFunction.ELEMENTARY_MIDDLE_AND_HIGH_SCHOOL_PRINCIPALS: {
        return "Schulleiter von Grund-, Mittel- und Oberschulen";
      }
      case JobFunction.ELEVATOR_INSTALLERS_AND_REPAIRERS: {
        return "Aufzugsinstallateure und -reparateure";
      }
      case JobFunction.EMERGENCY_MANAGEMENT_DIRECTORS: {
        return "Notfallmanagement-Direktoren";
      }
      case JobFunction.EMERGENCY_MEDICAL_TECHNICIANS_AND_PARAMEDICS: {
        return "Notfallmedizinische Techniker und Sanitäter";
      }
      case JobFunction.EMTS_AND_PARAMEDICS: {
        return "Rettungssanitäter und Rettungsassistenten";
      }
      case JobFunction.ENGINEERING_AND_NATURAL_SCIENCES_MANAGERS: {
        return "Manager für Ingenieur- und Naturwissenschaften";
      }
      case JobFunction.ENGINEERING_TECHNICIANS: {
        return "Ingenieurtechniker";
      }
      case JobFunction.ENVIRONMENTAL_ENGINEERING_TECHNICIANS: {
        return "Techniker für Umwelttechnik";
      }
      case JobFunction.ENVIRONMENTAL_ENGINEERS: {
        return "Umweltingenieure";
      }
      case JobFunction.ENVIRONMENTAL_SCIENCE_AND_PROTECTION_TECHNICIANS: {
        return "Techniker für Umweltwissenschaften und Umweltschutz";
      }
      case JobFunction.ENVIRONMENTAL_SCIENTISTS_AND_HYDROLOGISTS: {
        return "Umweltwissenschaftler und Hydrologen";
      }
      case JobFunction.ENVIRONMENTAL_SCIENTISTS_AND_SPECIALISTS: {
        return "Umweltwissenschaftler und -spezialisten";
      }
      case JobFunction.EPIDEMIOLOGISTS: {
        return "Epidemiologen";
      }
      case JobFunction.EXERCISE_PHYSIOLOGISTS: {
        return "Sportphysiologen";
      }
      case JobFunction.FARMERS_RANCHERS_AND_AGRICULTURAL_MANAGERS: {
        return "Landwirte, Viehzüchter und landwirtschaftliche Betriebsleiter";
      }
      case JobFunction.FARMERS_RANCHERS_AND_OTHER_AGRICULTURAL_MANAGERS: {
        return "Landwirte, Viehzüchter und andere landwirtschaftliche Fachkräfte";
      }
      case JobFunction.FASHION_DESIGNERS: {
        return "Modedesigner";
      }
      case JobFunction.FILE_CLERKS: {
        return "Büroangestellte";
      }
      case JobFunction.FILM_AND_VIDEO_EDITORS_AND_CAMERA_OPERATORS: {
        return "Film- und Videoredakteure und Kameraleute";
      }
      case JobFunction.FINANCIAL_ANALYSTS: {
        return "Finanzanalysten";
      }
      case JobFunction.FINANCIAL_ANALYSTS_AND_PERSONAL_FINANCIAL_ADVISORS: {
        return "Finanzanalysten und persönliche Finanzberater";
      }
      case JobFunction.FINANCIAL_CLERKS: {
        return "Finanzsachbearbeiter";
      }
      case JobFunction.FINANCIAL_EXAMINERS: {
        return "Finanz-Prüfer";
      }
      case JobFunction.FINANCIAL_MANAGERS: {
        return "Finanz-Manager";
      }
      case JobFunction.FIRE_FIGHTING_OCCUPATIONS: {
        return "Berufe in der Brandbekämpfung";
      }
      case JobFunction.FIRE_INSPECTORS: {
        return "Brandinspektoren";
      }
      case JobFunction.FIREFIGHTERS: {
        return "Feuerwehrleute";
      }
      case JobFunction.FISHERS_AND_FISHING_VESSEL_OPERATORS: {
        return "Fischer und Betreiber von Fischereifahrzeugen";
      }
      case JobFunction.FISHING_AND_HUNTING_WORKERS: {
        return "Beschäftigte in der Fischerei und Jagd";
      }
      case JobFunction.FITNESS_TRAINERS_AND_INSTRUCTORS: {
        return "Fitnesstrainer und -ausbilder";
      }
      case JobFunction.FITNESS_WORKERS: {
        return "Beschäftigte im Fitnessbereich";
      }
      case JobFunction.FLIGHT_ATTENDANTS: {
        return "Flugbegleiter";
      }
      case JobFunction.FLOORING_INSTALLERS_AND_TILE_AND_MARBLE_SETTERS: {
        return "Fußbodenleger und Fliesen- und Marmorverleger";
      }
      case JobFunction.FLORAL_DESIGNERS: {
        return "Blumendesigner";
      }
      case JobFunction.FOOD_AND_BEVERAGE_SERVING_AND_RELATED_WORKERS: {
        return "Lebensmittel- und Getränkeservierer und verwandte Berufe";
      }
      case JobFunction.FOOD_AND_TOBACCO_PROCESSING_WORKERS: {
        return "Beschäftigte in der Lebensmittel- und Tabakverarbeitung";
      }
      case JobFunction.FOOD_PROCESSING_OCCUPATIONS: {
        return "Lebensmittelverarbeitende Berufe";
      }
      case JobFunction.FOOD_SERVICE_MANAGERS: {
        return "Manager in der Lebensmittelindustrie";
      }
      case JobFunction.FORENSIC_SCIENCE_TECHNICIANS: {
        return "Forensische Techniker";
      }
      case JobFunction.FOREST_AND_CONSERVATION_WORKERS: {
        return "Waldarbeiter und Naturschützer";
      }
      case JobFunction.FOREST_CONSERVATION_AND_LOGGING_WORKERS: {
        return "Waldarbeiter, Naturschützer und Holzfäller";
      }
      case JobFunction.FUNDRAISERS: {
        return "Geldbeschaffer";
      }
      case JobFunction.FUNERAL_DIRECTORS: {
        return "Bestattungsunternehmer";
      }
      case JobFunction.FUNERAL_SERVICE_WORKERS: {
        return "Arbeiter im Bestattungswesen";
      }
      case JobFunction.GAMING_CAGE_WORKERS: {
        return "Arbeiter in Glücksspielkäfigen";
      }
      case JobFunction.GAMING_SERVICES_OCCUPATIONS: {
        return "Berufe im Bereich Glücksspielservice";
      }
      case JobFunction.GAMING_SERVICES_WORKERS: {
        return "Arbeiter im Glücksspielservice";
      }
      case JobFunction.GENERAL_MAINTENANCE_AND_REPAIR_WORKERS: {
        return "Allgemeine Wartungs- und Reparaturarbeiten";
      }
      case JobFunction.GENERAL_OFFICE_CLERKS: {
        return "Allgemeine Büroangestellte";
      }
      case JobFunction.GENETIC_COUNSELORS: {
        return "Genetische Berater";
      }
      case JobFunction.GEOGRAPHERS: {
        return "Geographen";
      }
      case JobFunction.GEOLOGICAL_AND_PETROLEUM_TECHNICIANS: {
        return "Geologische und Erdöltechniker";
      }
      case JobFunction.GEOSCIENTISTS: {
        return "Geowissenschaftler";
      }
      case JobFunction.GLAZIERS: {
        return "Glaser";
      }
      case JobFunction.GRAPHIC_DESIGNERS: {
        return "Grafikdesigner";
      }
      case JobFunction.GROUNDS_MAINTENANCE_WORKERS: {
        return "Arbeiter für die Instandhaltung von Grundstücken";
      }
      case JobFunction.HAND_LABORERS_AND_MATERIAL_MOVERS: {
        return "Hilfsarbeiter und Materialtransporteure";
      }
      case JobFunction.HAZARDOUS_MATERIALS_REMOVAL_WORKERS: {
        return "Arbeiter für die Beseitigung gefährlicher Materialien";
      }
      case JobFunction.HEALTH_AND_SAFETY_ENGINEERS: {
        return "Ingenieure für Gesundheit und Sicherheit";
      }
      case JobFunction.HEALTH_EDUCATORS: {
        return "Gesundheitspädagogen";
      }
      case JobFunction.HEALTH_EDUCATORS_AND_COMMUNITY_HEALTH_WORKERS: {
        return "Gesundheitspädagogen und Mitarbeiter der kommunalen Gesundheitsfürsorge";
      }
      case JobFunction.HEATING_AIR_CONDITIONING_AND_REFRIGERATION_MECHANICS_AND_INSTALLERS: {
        return "Mechanik und Installateure von Heizung, Klimaanlage und Kühlung";
      }
      case JobFunction.HEAVY_AND_TRACTOR_TRAILER_TRUCK_DRIVERS: {
        return "Fahrer von schweren Lastkraftwagen und Sattelschleppern";
      }
      case JobFunction.HEAVY_VEHICLE_AND_MOBILE_EQUIPMENT_SERVICE_TECHNICIANS: {
        return "Service für schwere Fahrzeuge und mobile Geräte -Service -Techniker";
      }
      case JobFunction.HEAVY_VEHICLE_AND_MOBILE_EQUIPMENT_SERVICE_TECHNICIANS_AND_MECHANICS: {
        return "Techniker und Mechaniker mit schwerem Fahrzeug und mobiler Geräte -Service";
      }
      case JobFunction.HIGH_SCHOOL_TEACHERS: {
        return "High School-Lehrer";
      }
      case JobFunction.HISTORIANS: {
        return "Historiker";
      }
      case JobFunction.HOME_APPLIANCE_REPAIRERS: {
        return "Reparateure für Haushaltsgeräte";
      }
      case JobFunction.HOME_HEALTH_AIDES_AND_PERSONAL_CARE_AIDES: {
        return "Haushaltshilfen und persönliche Pflegehelfer";
      }
      case JobFunction.HOTEL_MOTEL_AND_RESORT_DESK_CLERKS: {
        return "Hotel-, Motel- und Resort-Schreibtischangestellte";
      }
      case JobFunction.HUMAN_RESOURCES_ASSISTANTS_EXCEPT_PAYROLL_AND_TIMEKEEPING: {
        return "Personalassistenten außer Gehaltsabrechnung und Zeitmessung";
      }
      case JobFunction.HUMAN_RESOURCES_MANAGERS: {
        return "Manager der Personalabteilung";
      }
      case JobFunction.HUMAN_RESOURCES_SPECIALISTS: {
        return "Spezialisten für Personalwesen";
      }
      case JobFunction.HUMAN_RESOURCES_TRAINING_AND_LABOR_RELATIONS_MANAGERS_AND_SPECIALISTS: {
        return "Manager und Spezialisten für Personal-, Schulungs- und Arbeitsbeziehungen";
      }
      case JobFunction.HYDROLOGISTS: {
        return "Hydrologen";
      }
      case JobFunction.INDUSTRIAL_DESIGNERS: {
        return "Industriedesigner";
      }
      case JobFunction.INDUSTRIAL_ENGINEERING_TECHNICIANS: {
        return "Wirtschaftsingenieurwesen-Techniker";
      }
      case JobFunction.INDUSTRIAL_ENGINEERS: {
        return "Wirtschaftsingenieure";
      }
      case JobFunction.INDUSTRIAL_MACHINERY_MECHANICS_AND_MAINTENANCE_WORKERS: {
        return "Mechaniker und Wartungsarbeiter von Industriemaschinen";
      }
      case JobFunction.INDUSTRIAL_PRODUCTION_MANAGERS: {
        return "Manager für industrielle Produktion";
      }
      case JobFunction.INFORMATION_CLERKS: {
        return "Sachbearbeiter für Information";
      }
      case JobFunction.INFORMATION_SECURITY_ANALYSTS: {
        return "Analysten für Informationssicherheit";
      }
      case JobFunction.INSPECTORS_TESTERS_SORTERS_SAMPLERS_AND_WEIGHERS: {
        return "Inspektoren, Tester, Sortierer, Probene und Wiegen";
      }
      case JobFunction.INSTRUCTIONAL_COORDINATORS: {
        return "Unterrichtskoordinatoren";
      }
      case JobFunction.INSULATION_WORKERS: {
        return "Arbeiter in der Isolierung";
      }
      case JobFunction.INSURANCE_SALES_AGENTS: {
        return "Versicherungskaufleute";
      }
      case JobFunction.INSURANCE_UNDERWRITERS: {
        return "Underwriter für Versicherungen";
      }
      case JobFunction.INTERIOR_DESIGNERS: {
        return "Innenarchitekten";
      }
      case JobFunction.INTERPRETERS_AND_TRANSLATORS: {
        return "Dolmetscher und Übersetzer";
      }
      case JobFunction.IRONWORKERS: {
        return "Eisenflechter";
      }
      case JobFunction.JEWELERS_AND_PRECIOUS_STONE_AND_METAL_WORKERS: {
        return "Juweliere und Edelstein- und Metallarbeiter";
      }
      case JobFunction.JOB_OPPORTUNITIES_IN_THE_ARMED_FORCES: {
        return "Beschäftigungsmöglichkeiten bei den Streitkräften";
      }
      case JobFunction.JUDGES_AND_HEARING_OFFICERS: {
        return "Richter und Anhörungsbeauftragte";
      }
      case JobFunction.JUDGES_MAGISTRATES_AND_OTHER_JUDICIAL_WORKERS: {
        return "Richter, Staatsanwälte und andere Justizbedienstete";
      }
      case JobFunction.KINDERGARTEN_AND_ELEMENTARY_SCHOOL_TEACHERS: {
        return "Kindergarten- und Grundschullehrer";
      }
      case JobFunction.LABOR_RELATIONS_SPECIALISTS: {
        return "Spezialisten für Arbeitsbeziehungen";
      }
      case JobFunction.LANDSCAPE_ARCHITECTS: {
        return "Landschaftsarchitekten";
      }
      case JobFunction.LAWYERS: {
        return "Anwälte";
      }
      case JobFunction.LIBRARIANS: {
        return "Bibliothekare";
      }
      case JobFunction.LIBRARY_TECHNICIANS_AND_ASSISTANTS: {
        return "Bibliothekstechniker und -assistenten";
      }
      case JobFunction.LICENSED_PRACTICAL_AND_LICENSED_VOCATIONAL_NURSES: {
        return "Praktische und berufliche Krankenschwestern und -pfleger mit Lizenz";
      }
      case JobFunction.LINE_INSTALLERS_AND_REPAIRERS: {
        return "Leitungsinstallateure und -reparateure";
      }
      case JobFunction.LOAN_OFFICERS: {
        return "Kreditsachbearbeiter";
      }
      case JobFunction.LODGING_MANAGERS: {
        return "Manager von Unterkünften";
      }
      case JobFunction.LOGGING_WORKERS: {
        return "Logging-Arbeiter";
      }
      case JobFunction.LOGISTICIANS: {
        return "Logistiker";
      }
      case JobFunction.MACHINE_SETTERS_OPERATORS_AND_TENDERS_METAL_AND_PLASTIC: {
        return "Maschinensetzer, Operatoren, Ausschreibungsmetal und Kunststoff";
      }
      case JobFunction.MACHINISTS: {
        return "Maschinisten";
      }
      case JobFunction.MACHINISTS_AND_TOOL_AND_DIE_MAKERS: {
        return "Maschinenschlosser und Werkzeugmacher";
      }
      case JobFunction.MAINTENANCE_AND_REPAIR_WORKERS_GENERAL: {
        return "Wartungs- und Reparaturarbeiter, allgemein";
      }
      case JobFunction.MANAGEMENT_ANALYSTS: {
        return "Management-Analysten";
      }
      case JobFunction.MANICURISTS_AND_PEDICURISTS: {
        return "Maniküre und Pediküre";
      }
      case JobFunction.MARINE_ENGINEERS_AND_NAVAL_ARCHITECTS: {
        return "Schiffsingenieure und Schiffbauingenieure";
      }
      case JobFunction.MARKET_AND_SURVEY_RESEARCHERS: {
        return "Markt- und Umfrageforscher";
      }
      case JobFunction.MARKET_RESEARCH_ANALYSTS: {
        return "Marktforschungsanalysten";
      }
      case JobFunction.MARRIAGE_AND_FAMILY_THERAPISTS: {
        return "Ehe- und Familientherapeuten";
      }
      case JobFunction.MASSAGE_THERAPISTS: {
        return "Massage-Therapeuten Übersetzt mit www.DeepL.com/Translator (kostenlose Version)";
      }
      case JobFunction.MATERIAL_MOVING_MACHINE_OPERATORS: {
        return "Bediener von Materialtransportmaschinen";
      }
      case JobFunction.MATERIAL_MOVING_OCCUPATIONS: {
        return "Materialtransportberufe";
      }
      case JobFunction.MATERIAL_RECORDING_CLERKS: {
        return "Materialerfassungsbeamte";
      }
      case JobFunction.MATERIALS_ENGINEERS: {
        return "Werkstoff-Ingenieure";
      }
      case JobFunction.MATHEMATICIANS: {
        return "Mathematiker";
      }
      case JobFunction.MATHEMATICIANS_AND_STATISTICIANS: {
        return "Mathematiker und Statistiker";
      }
      case JobFunction.MECHANICAL_ENGINEERING_TECHNICIANS: {
        return "Maschinenbautechniker";
      }
      case JobFunction.MECHANICAL_ENGINEERS: {
        return "Maschinenbauingenieure";
      }
      case JobFunction.MEDICAL_AND_CLINICAL_LABORATORY_TECHNOLOGISTS_AND_TECHNICIANS: {
        return "Medizinische und klinische Labortechnologisten und Techniker";
      }
      case JobFunction.MEDICAL_AND_HEALTH_SERVICES_MANAGERS: {
        return "Manager für medizinische und Gesundheitsdienste";
      }
      case JobFunction.MEDICAL_ASSISTANTS: {
        return "Medizinische Assistenten";
      }
      case JobFunction.MEDICAL_EQUIPMENT_REPAIRERS: {
        return "Reparateure für medizinische Geräte";
      }
      case JobFunction.MEDICAL_RECORDS_AND_HEALTH_INFORMATION_TECHNICIANS: {
        return "Techniker für medizinische Aufzeichnungen und Gesundheitsinformationen";
      }
      case JobFunction.MEDICAL_SCIENTISTS: {
        return "Medizinische Wissenschaftler";
      }
      case JobFunction.MEDICAL_TRANSCRIPTIONISTS: {
        return "Medizinische Transkriptionisten";
      }
      case JobFunction.MEDICAL_DENTAL_AND_OPHTHALMIC_LABORATORY_TECHNICIANS: {
        return "Medizinische, zahnärztliche und ophthalmische Labortechniker";
      }
      case JobFunction.MEETING_AND_CONVENTION_PLANNERS: {
        return "Planer von Tagungen und Kongressen";
      }
      case JobFunction.MEETING_CONVENTION_AND_EVENT_PLANNERS: {
        return "Planer von Tagungen, Kongressen und Veranstaltungen";
      }
      case JobFunction.METAL_AND_PLASTIC_MACHINE_WORKERS: {
        return "Metall- und Kunststoffmaschinenbauer";
      }
      case JobFunction.MICROBIOLOGISTS: {
        return "Mikrobiologen";
      }
      case JobFunction.MIDDLE_SCHOOL_TEACHERS: {
        return "Mittelschullehrer";
      }
      case JobFunction.MILITARY_CAREERS: {
        return "Militärische Berufe";
      }
      case JobFunction.MILLWRIGHTS: {
        return "Müllwerker";
      }
      case JobFunction.MINING_AND_GEOLOGICAL_ENGINEERS: {
        return "Bergbau- und Geologieingenieure";
      }
      case JobFunction.MODELS: {
        return "Modelle";
      }
      case JobFunction.METER_READERS_UTILITIES: {
        return "Zählerableser, Versorgungsunternehmen";
      }
      case JobFunction.MULTIMEDIA_ARTISTS_AND_ANIMATORS: {
        return "Multimedia-Künstler und Animatoren";
      }
      case JobFunction.MUSIC_DIRECTORS_AND_COMPOSERS: {
        return "Musikregisseure und Komponisten";
      }
      case JobFunction.MUSICIANS_AND_SINGERS: {
        return "Musiker und Sänger";
      }
      case JobFunction.MUSICIANS_SINGERS_AND_RELATED_WORKERS: {
        return "Musiker, Sänger und verwandte Berufe";
      }
      case JobFunction.NATURAL_SCIENCES_MANAGERS: {
        return "Naturwissenschaftliche Manager";
      }
      case JobFunction.NETWORK_AND_COMPUTER_SYSTEMS_ADMINISTRATORS: {
        return "Netzwerk- und Computersystemadministratoren";
      }
      case JobFunction.NEWS_ANALYSTS_REPORTERS_AND_CORRESPONDENTS: {
        return "Nachrichtenanalysten, Reporter und Korrespondenten";
      }
      case JobFunction.NUCLEAR_ENGINEERS: {
        return "Nuklear-Ingenieure";
      }
      case JobFunction.NUCLEAR_MEDICINE_TECHNOLOGISTS: {
        return "Nuklearmedizinische Technologen";
      }
      case JobFunction.NUCLEAR_TECHNICIANS: {
        return "Nukleartechniker";
      }
      case JobFunction.NURSE_ANESTHETISTS_NURSE_MIDWIVES_AND_NURSE_PRACTITIONERS: {
        return "Krankenschwesteranästhesisten, Hebammen der Krankenschwester und Krankenschwesterpraktizierende";
      }
      case JobFunction.NURSING_ASSISTANTS_AND_ORDERLIES: {
        return "Pflegeassistenten und Krankenpfleger";
      }
      case JobFunction.NURSING_PSYCHIATRIC_AND_HOME_HEALTH_AIDES: {
        return "Krankenpflege-, Psychiatrie- und Hauskrankenpflegehelfer";
      }
      case JobFunction.OCCUPATIONAL_HEALTH_AND_SAFETY_SPECIALISTS_AND_TECHNICIANS: {
        return "Arbeits- und Sicherheitsspezialisten und Techniker";
      }
      case JobFunction.OCCUPATIONAL_THERAPIST_ASSISTANTS_AND_AIDES: {
        return "Assistenten und Helfer von Ergotherapeuten";
      }
      case JobFunction.OCCUPATIONAL_THERAPISTS: {
        return "Beschäftigungstherapeuten";
      }
      case JobFunction.OCCUPATIONAL_THERAPY_ASSISTANTS_AND_AIDES: {
        return "Assistenten und Hilfskräfte in der Ergotherapie";
      }
      case JobFunction.OFFICE_AND_ADMINISTRATIVE_SUPPORT_WORKER_SUPERVISORS_AND_MANAGERS: {
        return "Vorgesetzte und Manager von Büro- und Verwaltungsunterstützung Arbeitern";
      }
      case JobFunction.OFFICE_CLERKS_GENERAL: {
        return "Büroangestellte, allgemein";
      }
      case JobFunction.OPERATIONS_RESEARCH_ANALYSTS: {
        return "Analysten für Betriebsforschung";
      }
      case JobFunction.OPTICIANS: {
        return "Optiker";
      }
      case JobFunction.OPTICIANS_DISPENSING: {
        return "Augenoptiker, Dispenser";
      }
      case JobFunction.OPTOMETRISTS: {
        return "Optometristen";
      }
      case JobFunction.ORDER_CLERKS: {
        return "Büroangestellte";
      }
      case JobFunction.ORTHOTISTS_AND_PROSTHETISTS: {
        return "Orthopädietechniker und Prothetiker";
      }
      case JobFunction.PAINTERS_AND_PAPERHANGERS: {
        return "Maler und Tapezierer";
      }
      case JobFunction.PAINTERS_CONSTRUCTION_AND_MAINTENANCE: {
        return "Maler und Lackierer, Bau und Instandhaltung";
      }
      case JobFunction.PAINTING_AND_COATING_WORKERS: {
        return "Maler und Lackierer";
      }
      case JobFunction.PAINTING_AND_COATING_WORKERS_EXCEPT_CONSTRUCTION_AND_MAINTENANCE: {
        return "Malen- und Beschichtungspersonal, außer auf Bau und Wartung";
      }
      case JobFunction.PARALEGALS_AND_LEGAL_ASSISTANTS: {
        return "Rechtsanwaltsfachangestellte und Rechtsassistenten";
      }
      case JobFunction.PAYROLL_AND_TIMEKEEPING_CLERKS: {
        return "Angestellte in der Lohnbuchhaltung und Zeiterfassung";
      }
      case JobFunction.PERSONAL_AND_HOME_CARE_AIDES: {
        return "Persönliche und häusliche Pflegehilfen";
      }
      case JobFunction.PERSONAL_FINANCIAL_ADVISORS: {
        return "Persönliche Finanzberater";
      }
      case JobFunction.PEST_CONTROL_WORKERS: {
        return "Arbeiter in der Schädlingsbekämpfung";
      }
      case JobFunction.PETROLEUM_ENGINEERS: {
        return "Petroleum-Ingenieure";
      }
      case JobFunction.PHARMACISTS: {
        return "Apotheker";
      }
      case JobFunction.PHARMACY_AIDES: {
        return "Apothekenhelfer";
      }
      case JobFunction.PHARMACY_TECHNICIANS: {
        return "Pharmazeutische Techniker";
      }
      case JobFunction.PHLEBOTOMISTS: {
        return "Phlebotomisten";
      }
      case JobFunction.PHOTOGRAPHERS: {
        return "Fotografen";
      }
      case JobFunction.PHYSICAL_THERAPIST_ASSISTANTS_AND_AIDES: {
        return "Physiotherapeutische Assistenten und Hilfskräfte";
      }
      case JobFunction.PHYSICAL_THERAPISTS: {
        return "Physikalische Therapeuten";
      }
      case JobFunction.PHYSICIAN_ASSISTANTS: {
        return "Ärztliche Assistenten";
      }
      case JobFunction.PHYSICIANS_AND_SURGEONS: {
        return "Ärzte und Chirurgen";
      }
      case JobFunction.PHYSICISTS_AND_ASTRONOMERS: {
        return "Physiker und Astronomen";
      }
      case JobFunction.PIPELAYERS_PLUMBERS_PIPEFITTERS_AND_STEAMFITTERS: {
        return "Pipelayer, Klempner, PipeFitters und Dampfschotter";
      }
      case JobFunction.PHOTOGRAPHIC_PROCESS_WORKERS_AND_PROCESSING_MACHINE_OPERATORS: {
        return "Fotografische Prozessarbeiter und Verarbeitungsmaschinenbetreiber";
      }
      case JobFunction.PLUMBERS_PIPEFITTERS_AND_STEAMFITTERS: {
        return "Klempner, Rohrleitungsbauer und Dampfinstallateure";
      }
      case JobFunction.PODIATRISTS: {
        return "Fußpfleger";
      }
      case JobFunction.POLICE_AND_DETECTIVES: {
        return "Polizei und Detektive";
      }
      case JobFunction.POLICE_FIRE_AND_AMBULANCE_DISPATCHERS: {
        return "Polizei-, Feuerwehr- und Rettungsdienstdisponenten";
      }
      case JobFunction.POLITICAL_SCIENTISTS: {
        return "Politikwissenschaftler";
      }
      case JobFunction.POSTAL_SERVICE_WORKERS: {
        return "Postangestellte";
      }
      case JobFunction.POSTSECONDARY_EDUCATION_ADMINISTRATORS: {
        return "Postsekundäre Bildungsadministratoren";
      }
      case JobFunction.POSTSECONDARY_TEACHERS: {
        return "Postsekundäre Lehrkräfte";
      }
      case JobFunction.PLASTERERS_AND_STUCCO_MASONS: {
        return "Gipser und Stuckateure";
      }
      case JobFunction.POWER_PLANT_OPERATORS_DISTRIBUTORS_AND_DISPATCHERS: {
        return "Kraftwerksbetreiber, Distributoren und Dispatcher";
      }
      case JobFunction.PRESCHOOL_AND_CHILDCARE_CENTER_DIRECTORS: {
        return "Leiter von Vorschulen und Kinderbetreuungseinrichtungen";
      }
      case JobFunction.PRESCHOOL_TEACHERS: {
        return "VorschullehrerInnen";
      }
      case JobFunction.PRIVATE_DETECTIVES_AND_INVESTIGATORS: {
        return "Privatdetektive und Ermittler";
      }
      case JobFunction.PROBATION_OFFICERS_AND_CORRECTIONAL_TREATMENT_SPECIALISTS: {
        return "Bewährungshelfer und Spezialisten für Justizvollzugsbehandlung";
      }
      case JobFunction.PROCUREMENT_CLERKS: {
        return "Sachbearbeiter im Beschaffungswesen";
      }
      case JobFunction.PROCUREMENT_MANAGER: {
        return "Einkaufsleiter";
      }
      case JobFunction.PRODUCERS_AND_DIRECTORS: {
        return "Produzenten und Direktoren";
      }
      case JobFunction.PRODUCTION_PLANNING_AND_EXPEDITING_CLERKS: {
        return "Produktions-, Planungs- und Expediting-Sachbearbeiter";
      }
      case JobFunction.PROPERTY_REAL_ESTATE_AND_COMMUNITY_ASSOCIATION_MANAGERS: {
        return "Manager von Eigentum, Immobilien und Community Association";
      }
      case JobFunction.PRECISION_INSTRUMENT_AND_EQUIPMENT_REPAIRERS: {
        return "Reparateure für Präzisionsinstrumente und -geräte";
      }
      case JobFunction.PSYCHIATRIC_TECHNICIANS_AND_AIDES: {
        return "Psychiatrietechniker und -assistenten";
      }
      case JobFunction.PREPRESS_TECHNICIANS_AND_WORKERS: {
        return "Druckvorstufentechniker und -mitarbeiter";
      }
      case JobFunction.PSYCHOLOGISTS: {
        return "Psychologen";
      }
      case JobFunction.PUBLIC_RELATIONS_AND_FUNDRAISING_MANAGERS: {
        return "Manager für Öffentlichkeitsarbeit und Fundraising";
      }
      case JobFunction.PUBLIC_RELATIONS_SPECIALISTS: {
        return "Fachleute für Öffentlichkeitsarbeit";
      }
      case JobFunction.PURCHASING_MANAGERS_BUYERS_AND_PURCHASING_AGENTS: {
        return "Einkaufsleiter, Einkäufer und Einkaufsbeauftragte";
      }
      case JobFunction.PRINTING_MACHINE_OPERATORS: {
        return "Bediener von Druckmaschinen";
      }
      case JobFunction.QUALITY_CONTROL_INSPECTORS: {
        return "Inspektoren der Qualitätskontrolle";
      }
      case JobFunction.RADIATION_THERAPISTS: {
        return "Strahlentherapeuten";
      }
      case JobFunction.RADIO_AND_TELECOMMUNICATIONS_EQUIPMENT_INSTALLERS_AND_REPAIRERS: {
        return "Radio- und Telekommunikationsgeräteinstallateure und Reparaturen";
      }
      case JobFunction.RADIOLOGIC_AND_MRI_TECHNOLOGISTS: {
        return "Radiologische und MRI-Technologen";
      }
      case JobFunction.RADIOLOGIC_TECHNOLOGISTS_AND_TECHNICIANS: {
        return "Radiologische Technologen und Techniker";
      }
      case JobFunction.RAIL_TRANSPORTATION_OCCUPATIONS: {
        return "Berufe im Schienenverkehr";
      }
      case JobFunction.RAILROAD_WORKERS: {
        return "Eisenbahner";
      }
      case JobFunction.REAL_ESTATE_BROKERS_AND_SALES_AGENTS: {
        return "Immobilienmakler und -vermittler";
      }
      case JobFunction.RECEPTIONISTS: {
        return "Rezeptionisten";
      }
      case JobFunction.RECEPTIONISTS_AND_INFORMATION_CLERKS: {
        return "Rezeptionisten und Informationsbeamte";
      }
      case JobFunction.RECREATION_WORKERS: {
        return "Beschäftigte im Freizeitbereich";
      }
      case JobFunction.RECREATIONAL_THERAPISTS: {
        return "Freizeittherapeuten";
      }
      case JobFunction.REGISTERED_NURSES: {
        return "Registrierte Krankenschwestern";
      }
      case JobFunction.REHABILITATION_COUNSELORS: {
        return "Reha-Berater";
      }
      case JobFunction.REPORTERS_CORRESPONDENTS_AND_BROADCAST_NEWS_ANALYSTS: {
        return "Reporter, Korrespondenten und Rundfunk -Nachrichtenanalysten";
      }
      case JobFunction.RESERVATION_AND_TRANSPORTATION_TICKET_AGENTS_AND_TRAVEL_CLERKS: {
        return "Reservierungs- und Transport -Ticket -Agenten und Reisebersteller";
      }
      case JobFunction.RESPIRATORY_THERAPISTS: {
        return "Atemwegstherapeuten";
      }
      case JobFunction.RETAIL_SALES_WORKERS: {
        return "Einzelhandelskaufleute";
      }
      case JobFunction.RETAIL_SALESPERSONS: {
        return "Einzelhandelskauffrauen und -männer";
      }
      case JobFunction.ROOFERS: {
        return "Dachdecker";
      }
      case JobFunction.SALES_ENGINEERS: {
        return "Vertriebsingenieure";
      }
      case JobFunction.SALES_MANAGERS: {
        return "Verkaufsleiter";
      }
      case JobFunction.SALES_REPRESENTATIVES_WHOLESALE_AND_MANUFACTURING: {
        return "Vertriebsmitarbeiter, Großhandel und Produktion";
      }
      case JobFunction.SCHOOL_AND_CAREER_COUNSELORS: {
        return "Schul- und Berufsberater";
      }
      case JobFunction.SECRETARIES_AND_ADMINISTRATIVE_ASSISTANTS: {
        return "Sekretäre und Verwaltungsassistenten";
      }
      case JobFunction.SECURITIES_COMMODITIES_AND_FINANCIAL_SERVICES_SALES_AGENTS: {
        return "Wertpapiere, Rohstoffe und Finanzdienstleistungsverkäufe";
      }
      case JobFunction.SECURITY_GUARDS_AND_GAMING_SURVEILLANCE_OFFICERS: {
        return "Sicherheitspersonal und Glücksspielaufsichtspersonal";
      }
      case JobFunction.SHEET_METAL_WORKERS: {
        return "Blechbearbeiter";
      }
      case JobFunction.SHIPPING_RECEIVING_AND_TRAFFIC_CLERKS: {
        return "Versand-, Empfangs- und Verkehrskaufleute";
      }
      case JobFunction.SKINCARE_SPECIALISTS: {
        return "Hautpflegespezialisten";
      }
      case JobFunction.SMALL_ENGINE_MECHANICS: {
        return "Mechaniker für Kleinmotoren";
      }
      case JobFunction.SOCIAL_AND_COMMUNITY_SERVICE_MANAGERS: {
        return "Manager für soziale und kommunale Dienstleistungen";
      }
      case JobFunction.SOCIAL_AND_HUMAN_SERVICE_ASSISTANTS: {
        return "Assistenten für Sozial- und Humandienstleistungen";
      }
      case JobFunction.SOCIAL_SCIENTISTS_OTHER: {
        return "Sozialwissenschaftler, andere";
      }
      case JobFunction.SOCIAL_WORKERS: {
        return "Sozialarbeiter";
      }
      case JobFunction.SALES_WORKER_SUPERVISORS: {
        return "Supervisor für Vertriebsmitarbeiter";
      }
      case JobFunction.SOCIOLOGISTS: {
        return "Soziologen";
      }
      case JobFunction.SOFTWARE_DEVELOPERS: {
        return "Software-Entwickler";
      }
      case JobFunction.SCIENCE_TECHNICIANS: {
        return "Wissenschaftliche Techniker";
      }
      case JobFunction.SOLAR_PHOTOVOLTAIC_INSTALLERS: {
        return "Solar-Photovoltaik-Installateure";
      }
      case JobFunction.SPECIAL_EDUCATION_TEACHERS: {
        return "Lehrer für Sonderpädagogik";
      }
      case JobFunction.SPEECH_LANGUAGE_PATHOLOGISTS: {
        return "Sprachheilpathologen";
      }
      case JobFunction.STATIONARY_ENGINEERS_AND_BOILER_OPERATORS: {
        return "Stationsingenieure und Kesselbetreiber";
      }
      case JobFunction.SEMICONDUCTOR_PROCESSORS: {
        return "Halbleiter-Prozessoren";
      }
      case JobFunction.STATISTICIANS: {
        return "Statistiker";
      }
      case JobFunction.STOCK_CLERKS_AND_ORDER_FILLERS: {
        return "Lagerverwalter und Auftragserfüller";
      }
      case JobFunction.STRUCTURAL_AND_REINFORCING_IRON_AND_METAL_WORKERS: {
        return "Bau- und Bewehrungseisen- und Metallarbeiter";
      }
      case JobFunction.SUBSTANCE_ABUSE_BEHAVIORAL_DISORDER_AND_MENTAL_HEALTH_COUNSELORS: {
        return "Drogenmissbrauch, Verhaltensstörungen und Berater für psychische Gesundheitswesen";
      }
      case JobFunction.SUPPLY_MANAGEMENT: {
        return "Lieferantenmanagement";
      }
      case JobFunction.SURGICAL_TECHNOLOGISTS: {
        return "Chirurgische Technologen";
      }
      case JobFunction.SURVEY_RESEARCHERS: {
        return "Vermessungsforscher";
      }
      case JobFunction.SURVEYING_AND_MAPPING_TECHNICIANS: {
        return "Vermessungstechniker und Kartierungstechniker";
      }
      case JobFunction.SURVEYORS: {
        return "Vermessungsingenieure";
      }
      case JobFunction.SURVEYORS_CARTOGRAPHERS_PHOTOGRAMMETRISTS_AND_SURVEYING_AND_MAPPING_TECHNICIANS: {
        return "Vermesser, Kartografen, Photogrammetriker sowie Vermessungstechniker und Kartierungstechniker";
      }
      case JobFunction.TAX_EXAMINERS_AND_COLLECTORS_AND_REVENUE_AGENTS: {
        return "Steuerprüfer, Steuereinzieher und Steuerfahnder";
      }
      case JobFunction.TAX_EXAMINERS_COLLECTORS_AND_REVENUE_AGENTS: {
        return "Steuerprüfer, Steuereinzieher und Steuerfahnder";
      }
      case JobFunction.TAXI_DRIVERS_AND_CHAUFFEURS: {
        return "Taxifahrer und Chauffeure";
      }
      case JobFunction.TAXI_DRIVERS_RIDE_HAILING_DRIVERS_AND_CHAUFFEURS: {
        return "Taxifahrer, Ride-Hailing-Fahrer und Chauffeure";
      }
      case JobFunction.TEACHER_ASSISTANTS: {
        return "Lehrer-Assistenten";
      }
      case JobFunction.TEACHERS_ADULT_LITERACY_AND_REMEDIAL_EDUCATION: {
        return "Lehrkräfte - Alphabetisierung und Nachhilfeunterricht für Erwachsene";
      }
      case JobFunction.TEACHERS_POSTSECONDARY: {
        return "Lehrkräfte - postsekundäre Bildung";
      }
      case JobFunction.TEACHERS_PRESCHOOL_KINDERGARTEN_ELEMENTARY_MIDDLE_AND_SECONDARY: {
        return "Lehrer-Vorschule, Kindergarten, Grundschule, Mitte und Sekundarstufe";
      }
      case JobFunction.TEACHERS_SPECIAL_EDUCATION: {
        return "Lehrkräfte-Sonderpädagogik";
      }
      case JobFunction.TECHNICAL_WRITERS: {
        return "Technische Redakteure";
      }
      case JobFunction.TELECOMMUNICATIONS_EQUIPMENT_INSTALLERS_AND_REPAIRERS: {
        return "Telekommunikationsgeräteinstallateure und Reparaturen";
      }
      case JobFunction.TELEVISION_VIDEO_AND_MOTION_PICTURE_CAMERA_OPERATORS_AND_EDITORS: {
        return "Fernseh-, Video- und Filmkameramameratikbetreiber und Redakteure";
      }
      case JobFunction.TELLERS: {
        return "Kassierer";
      }
      case JobFunction.TOOL_AND_DIE_MAKERS: {
        return "Werkzeug- und Formenbauer";
      }
      case JobFunction.TOP_EXECUTIVES: {
        return "Top-Führungskräfte";
      }
      case JobFunction.TRAINING_AND_DEVELOPMENT_MANAGERS: {
        return "Manager für Ausbildung und Entwicklung";
      }
      case JobFunction.TRAINING_AND_DEVELOPMENT_SPECIALISTS: {
        return "Spezialisten für Ausbildung und Entwicklung";
      }
      case JobFunction.TRAVEL_AGENTS: {
        return "Reisebüromitarbeiter";
      }
      case JobFunction.TRUCK_DRIVERS_AND_DRIVERSALES_WORKERS: {
        return "Lkw-Fahrer und Fahrer/Vertriebsmitarbeiter";
      }
      case JobFunction.UMPIRES_REFEREES_AND_OTHER_SPORTS_OFFICIALS: {
        return "Schiedsrichter, Referees und andere Sportfunktionäre";
      }
      case JobFunction.URBAN_AND_REGIONAL_PLANNERS: {
        return "Stadt- und Regionalplaner";
      }
      case JobFunction.VETERINARIANS: {
        return "Veterinärmediziner";
      }
      case JobFunction.TEACHERS_SELF_ENRICHMENT_EDUCATION: {
        return "Lehrer - Erziehung zur Selbstverwirklichung";
      }
      case JobFunction.VETERINARY_ASSISTANTS_AND_LABORATORY_ANIMAL_CARETAKERS: {
        return "Tierarzthelferin und Tierpfleger Labor";
      }
      case JobFunction.VETERINARY_TECHNOLOGISTS_AND_TECHNICIANS: {
        return "Veterinärtechnologen und -techniker";
      }
      case JobFunction.WAITERS_AND_WAITRESSES: {
        return "Kellner und Kellnerinnen";
      }
      case JobFunction.WATER_AND_LIQUID_WASTE_TREATMENT_PLANT_AND_SYSTEM_OPERATORS: {
        return "Anlagen für Wasser- und Flüssigkeitsabfälle und Systembetreiber";
      }
      case JobFunction.WATER_AND_WASTEWATER_TREATMENT_PLANT_AND_SYSTEM_OPERATORS: {
        return "Anlagen für Wasser- und Abwasseranlagen und Systembetreiber";
      }
      case JobFunction.WATER_TRANSPORTATION_OCCUPATIONS: {
        return "Berufe im Wassertransport";
      }
      case JobFunction.TEXTILE_APPAREL_AND_FURNISHINGS_OCCUPATIONS: {
        return "Berufe in der Textil-, Bekleidungs- und Einrichtungsindustrie";
      }
      case JobFunction.WATER_TRANSPORTATION_WORKERS: {
        return "Beschäftigte im Wassertransport";
      }
      case JobFunction.WEB_DEVELOPERS: {
        return "Web-Entwickler";
      }
      case JobFunction.WEIGHERS_MEASURERS_CHECKERS_AND_SAMPLERS_RECORDKEEPING: {
        return "Wiegen, Messgeräte, Checkers und Probene, Aufzeichnungen";
      }
      case JobFunction.WELDERS_CUTTERS_SOLDERERS_AND_BRAZERS: {
        return "Schweißer, Schneidegeräte, Lötgeräte und Hartlötgeräte";
      }
      case JobFunction.WELDING_SOLDERING_AND_BRAZING_WORKERS: {
        return "Schweißer, Lötarbeiter und Hartlötarbeiter";
      }
      case JobFunction.WHOLESALE_AND_MANUFACTURING_SALES_REPRESENTATIVES: {
        return "Vertriebsmitarbeiter im Großhandel und in der Fertigung";
      }
      case JobFunction.WIND_TURBINE_TECHNICIANS: {
        return "Techniker für Windkraftanlagen";
      }
      case JobFunction.WOODWORKERS: {
        return "Holzbearbeiter";
      }
      case JobFunction.WRITERS_AND_AUTHORS: {
        return "Schriftsteller und Autoren";
      }
      case JobFunction.WRITERS_AND_EDITORS: {
        return "Schriftsteller und Redakteure";
      }
      case JobFunction.ZOOLOGISTS_AND_WILDLIFE_BIOLOGISTS: {
        return "Zoologen und Wildtierbiologen";
      }
      case JobFunction.OTHER: {
        return "Andere";
      }
    }
    return "";
  }

  static getJobFunctionTitle(
    jobFunction: JobFunction,
    locale?: Languages,
  ): string {
    switch (locale) {
      case Languages.ENGLISH:
        return this.getJobFunctionTitleInEnglish(jobFunction);
      case Languages.SPANISH:
        return this.getJobFunctionTitleInSpanish(jobFunction);
      case Languages.FRENCH:
        return this.getJobFunctionTitleInFrench(jobFunction);
      case Languages.ITALIAN:
        return this.getJobFunctionTitleInItalian(jobFunction);
      case Languages.TURKISH:
        return this.getJobFunctionTitleInTurkish(jobFunction);
      case Languages.GERMAN:
        return this.getJobFunctionTitleInGerman(jobFunction);
      case Languages.VIETNAMESE:
        return this.getJobFunctionTitleInVitenamese(jobFunction);
      default:
        return this.getJobFunctionTitleInEnglish(jobFunction);
    }
  }
}
