import { action, observable } from "mobx";
import { assessmentService } from "../services/AssessmentService";

export interface AnswerInput {
  identifier: string;
  answer: string;
  locale?: string;
}

interface Option {
  label: string;
  value: string;
}

export interface Question {
  sequenceId: number;
  nextSequenceId: number;
  id: number;
  identifier: string;
  type: string;
  preAdditionalStatement?: string[];
  postAdditionalStatement?: string[];
  description: string;
  answer?: string;
  options: Option[];
}

export interface QuestionBackendResponse {
  surveyId?: number;
  questions: Question[];
}

export class AssessmentStore {
  private static _instance: AssessmentStore;
  @observable questions: Question[] = [];
  @observable error: string | null = null;

  static getInstance(): AssessmentStore {
    if (!this._instance) {
      this._instance = new AssessmentStore();
    }

    return this._instance;
  }

  @action
  async fetchQuestion(sequenceId?: number) {
    try {
      const result = await assessmentService.getQuestion(sequenceId);
      // For the first fetch replace the whole array.
      if (!sequenceId || sequenceId === 1) {
        this.questions = result.questions;
      } else {
        // Push the new question in exiting question list.
        this.questions = [...this.questions, ...result.questions];
      }
    } catch (error) {
      this.error = (error as any)?.message;
    }
  }

  @action
  async submitAnswer(input: AnswerInput) {
    try {
      await assessmentService.postAnswer(input);

      const formattedArray = this.questions.map((item) => {
        if (item.identifier === input.identifier) {
          return {
            ...item,
            answer: input.answer,
          };
        }
        return item;
      });

      this.questions = formattedArray;
      return { isSuccess: true };
    } catch (error) {
      this.error = (error as any)?.message;
    }
  }

  @action
  async completeSurvey(orgId: number, locale: string) {
    try {
      const response = await assessmentService.completeSurvey(orgId, locale);
      return response;
    } catch (error) {
      this.error = (error as any)?.message;
    }
  }
}
