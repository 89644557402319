export enum UserPlans {
  LITE = "lite",
  PREMIUM = "premium",
}

export class UserPlansEnumUtils {
  static getPlanTitle(plan: UserPlans) {
    return plan.charAt(0).toUpperCase() + plan.slice(1);
  }
}
