import { observable } from "mobx";
import { CompletedSurveyStore } from "../stores/CompletedSurveyStore";
import { DimensionLanguages } from "./../enums/languages.enum";
import Model from "./Model";
import { User } from "./UserModel";

export class CompleteSurveysModel extends Model {
  static _store: CompletedSurveyStore;

  @observable id!: number;
  @observable user_id!: number;
  @observable is_completed!: boolean;
  @observable createdAt!: string;
  @observable updatedAt!: string;
  @observable dimension_wise_score?: any;
  @observable completed_on!: string;
  @observable report_url!: {
    [name in DimensionLanguages]: string;
  };
  @observable fullReportUrl!: {
    [name in DimensionLanguages]: string;
  };
  @observable completion_time!: number;
  @observable is_lite!: boolean;
  @observable user!: User;
  @observable survey_language!: DimensionLanguages;

  getId(): string | number {
    return this.id;
  }
}
