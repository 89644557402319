import { inject, observer } from "mobx-react";
import React, { useEffect } from "react";
import { Redirect, Route } from "react-router";
import { Routes } from "../routes";
import { RouteUtil } from "../utils/route-utils";
import Loader from "../components/shared/loader/Loader";
import { SettingType } from "../enums/SettingTypeEnum.enum";
import MaintenancePage from "../components/MaintainancePage/MaintainancePage";

const AnonymousRoute: React.FC<any> = ({ component: Component, ...rest }) => {
  useEffect(() => {
    if (rest.isCheckSettings && !window.localStorage.getItem("auth_token"))
      rest.store!.settingsStore.fetchSettings();
  }, []);

  if (window.localStorage.getItem("auth_token")) {
    return (
      <Route
        {...rest}
        render={(props) => (
          <Redirect
            to={{
              pathname: RouteUtil.getUrl(Routes.dashboard),
              state: { from: props.location },
            }}
          />
        )}
      />
    );
  }
  switch (rest.store!.settingsStore.isLoading && rest.isCheckSettings) {
    case true:
      return <Route {...rest} render={() => <Loader />} />;
    case false:
    default:
      if (
        rest.isCheckSettings &&
        rest.store!.settingsStore.isLoaded &&
        rest.store!.settingsStore.settingsData &&
        rest.store!.settingsStore.settingsData[SettingType.MAINTENANCE_PAGE]
          .value === "true"
      ) {
        return <Route {...rest} render={(props) => <MaintenancePage />} />;
      }
      return (
        <Route
          {...rest}
          render={(props) => <Component {...{ ...rest, ...props }} />}
        />
      );
  }
};

export default inject("store")(observer(AnonymousRoute));
