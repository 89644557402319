import React from "react";
import { CSSProperties } from "react";
import "./svgIcon.scss";
import svgList, { SvgIconType } from "./svgIcons.list";

interface SvgIconComponentProps {
  icon: SvgIconType;
  className?: string;
  style?: CSSProperties;
  onClick?: () => void;
  iconClassName?: string;
  ref?: any;
}

const SvgIconComponent: React.FC<SvgIconComponentProps> = ({
  icon,
  className,
  style,
  onClick,
  iconClassName,
  ref,
}) => {
  const classes = () => {
    const classNames = ["svg_icon"];

    if (className) {
      classNames.push(className);
    }

    if (!svgList[icon]) {
      classNames.unshift(`svg_icon--hide-empty-container`);
    }
    return classNames.join(" ");
  };

  return (
    <div
      className={classes()}
      style={style}
      onClick={onClick ? () => onClick() : () => null}
      ref={ref}
    >
      <img src={svgList[icon]} className={iconClassName} alt={"aqai"} />
    </div>
  );
};

export default SvgIconComponent;
