import { useField } from "formik";
import React from "react";
import SelectMenu, { SelectMenuType } from "./SelectMenu";
import InputHelper from "../Inputhelper/InputHelper";
import { AutoCompleteItemType } from "../../../types/AutoComplete.type";

interface FormikSelectMenuProps {
  name: string;
  label?: string;
  placeholder?: string;
  type?: SelectMenuType;
  autoComplete?: boolean;
  items: AutoCompleteItemType[];
  allowClear?: boolean;
  onChange?: (value: any) => void;
  addItemAction?: { title: string; onAddItem: () => void };
  disabled?: boolean;
  textColor?: string;
  padding?: string;
  labelClassName?: string;
  isRequiredField?: boolean;
  isLoading?: boolean;
}

const FormikSelectMenu: React.FC<FormikSelectMenuProps> = (props) => {
  const [inputProps, meta, helpers] = useField(props.name);

  const { value: selection, error, touched } = meta;
  const { setValue: setSelection, setTouched } = helpers;
  return (
    <div className={"w-full"}>
      <SelectMenu
        name={props.name}
        selected={selection}
        label={props.label}
        items={props.items}
        type={props.type}
        allowClear={props.allowClear}
        autoComplete={props.autoComplete}
        onBlur={() => setTouched(true)}
        placeholder={props.placeholder}
        onChange={(value): void =>
          props.onChange ? props.onChange(value) : setSelection(value)
        }
        addItemAction={props.addItemAction}
        disabled={props.disabled}
        error={!!error && touched}
        textColor={props.textColor}
        padding={props.padding}
        labelClassName={props.labelClassName}
        isRequiredIndicator={props.isRequiredField}
        isLoading={props.isLoading}
      />
      {error && touched && <InputHelper type={"error"} text={error} />}
    </div>
  );
};

export default FormikSelectMenu;
