import Model from "./Model";
import { observable } from "mobx";
import { PlatformContentStore } from "../stores/PlatformContentStore";

export class PlatformContentModal extends Model {
  static _store: PlatformContentStore;

  @observable id!: number;
  @observable title!: string;
  @observable heading!: string;
  @observable description!: string;
  @observable link!: string;
  @observable meta!: any;
  @observable is_active!: boolean;
  @observable slug!: string;
  @observable type!: string;

  getId(): string | number {
    return this.id;
  }
}

export interface PlatformContentFormData {
  title?: string;
  heading?: string;
  description?: string;
  link?: string;
  meta?: any;
  is_active?: boolean;
}
