export enum Languages {
  ENGLISH = "en",
  FRENCH = "fr",
  ITALIAN = "it",
  SPANISH = "es",
  TURKISH = "tr",
  GERMAN = "de",
  VIETNAMESE = "vi",
}

export enum DimensionLanguages {
  ENGLISH = "english",
  FRENCH = "french",
  ITALIAN = "italian",
  SPANISH = "spanish",
  TURKISH = "turkish",
  GERMAN = "german",
  VIETNAMESE = "vietnamese",
}

export class LanguagesEnumUtils {
  static getLanguageTitle(lang: Languages) {
    switch (lang) {
      case Languages.ENGLISH: {
        return "English";
      }
      case Languages.SPANISH: {
        return "Spanish";
      }
      case Languages.FRENCH: {
        return "French";
      }
      case Languages.ITALIAN: {
        return "Italian";
      }
      case Languages.TURKISH: {
        return "Turkish";
      }
      case Languages.GERMAN: {
        return "German";
      }
      case Languages.VIETNAMESE: {
        return "Vietnamese";
      }
    }
  }
  static getLanguageShortName(lang: DimensionLanguages) {
    switch (lang) {
      case DimensionLanguages.ENGLISH: {
        return Languages.ENGLISH;
      }
      case DimensionLanguages.SPANISH: {
        return Languages.SPANISH;
      }
      case DimensionLanguages.FRENCH: {
        return Languages.FRENCH;
      }
      case DimensionLanguages.ITALIAN: {
        return Languages.ITALIAN;
      }
      case DimensionLanguages.TURKISH: {
        return Languages.TURKISH;
      }
      case DimensionLanguages.GERMAN: {
        return Languages.GERMAN;
      }
      case DimensionLanguages.VIETNAMESE: {
        return Languages.VIETNAMESE;
      }
    }
  }

  static getLanguageTitleByDimensionLanguage(lang: DimensionLanguages) {
    switch (lang) {
      case DimensionLanguages.ENGLISH: {
        return "English";
      }
      case DimensionLanguages.SPANISH: {
        return "Spanish";
      }
      case DimensionLanguages.FRENCH: {
        return "French";
      }
      case DimensionLanguages.ITALIAN: {
        return "Italian";
      }
      case DimensionLanguages.TURKISH: {
        return "Turkish";
      }
      case DimensionLanguages.GERMAN: {
        return "German";
      }
      case DimensionLanguages.VIETNAMESE: {
        return "Vietnamese";
      }
    }
  }

  static getDimensionLanguageFromLocale(lang: Languages) {
    switch (lang) {
      case Languages.ENGLISH: {
        return DimensionLanguages.ENGLISH;
      }
      case Languages.SPANISH: {
        return DimensionLanguages.SPANISH;
      }
      case Languages.FRENCH: {
        return DimensionLanguages.FRENCH;
      }
      case Languages.ITALIAN: {
        return DimensionLanguages.ITALIAN;
      }
      case Languages.TURKISH: {
        return DimensionLanguages.TURKISH;
      }
      case Languages.GERMAN: {
        return DimensionLanguages.GERMAN;
      }
      case Languages.VIETNAMESE: {
        return DimensionLanguages.VIETNAMESE;
      }
    }
  }
}
