export enum Colors {
  ABILITY_ACTIVE = "#CE88BA",
  ABILITY_INACTIVE = "#F5E7F1",
  ABILITY_INACTIVE_SECONDARY = "#E3C1DB",
  CHARACTER_ACTIVE = "#67C5CA",
  CHARACTER_INACTIVE = "#B0DFE3",
  CHARACTER_INACTIVE_SECONDARY = "#E1F3F4",
  CHARACTER_DARK = "#8CBCD2",
  CHARACTER_LIGHT = "#DFF0F8",
  ENVIRONMENT_ACTIVE = "#7B92B4",
  ENVIRONMENT_INACTIVE = "#E5E9F0",
  ENVIRONMENT_INACTIVE_SECONDARY = "#BAC6D8",
  AI_PREDICTIVE_OPTIMISATION_ACTIVE = "#F5A04E",
  AI_PREDICTIVE_OPTIMISATION_LIGHT = "#FCEBDB",
  AI_PREDICTIVE_OPTIMISATION_LIGHT_NEW = "#F5A04E19",
  AI_PREDICTIVE_OPTIMISATION_INACTIVE = "#FDECDC",
  NEW_AI_PREDICTIVE_OPTIMISATION_ACTIVE = "#F8AC2D",
  NEW_AI_PREDICTIVE_OPTIMISATION_INACTIVE = "#F9D393",
  CHARACTER_LIKE_BAR = "#A0A0A0",
  CHARACTER_NEUTRAL_BAR = "#888888",
  CHARACTER_DISLIKE_BAR = "#717171",
  RANGE_INACTIVE = "#EEEEEE",
  NEGATIVE_SLIDER = "#F26262",
  POSITIVE_SLIDER = "#a9ca67",
  MID_RANGE = "#F2B957",
  NEW_CHARACTER_ACTIVE = "#2B8A92",
  NEW_CHARACTER_INACTIVE = "#E8F0F3",
  NEW_ABILITY_ACTIVE = "#C48BB7",
  NEW_ABILITY_INACTIVE = "#F9F3F7",
  NEW_ABILITY_INACTIVE_2 = "#C48BB719",
  NEW_ENVIRONMENT_ACTIVE = " #627394",
  NEW_ENVIRONMENT_INACTIVE = " #F0F2F6",
  ENVIRONMENT_BAR_ACTIVE = "#8091B1",
  ABILITY_BAR_INDICATOR = "#B0609E",
  ABILITY_LIGHT = "#F4EAF2",
  BAR_INACTIVE_COLOR = "#F9F9F9",
  NEW_ENVIRONMENT_BAR_ACTIVE = "#7F91B1",
  NEW_ENVIRONMENT_BAR_INACTIVE = "#CFD6E2",
  NEW_ENVIRONMENT_LIGHT = "#E5EFFF",
  NEW_ENVIRONMENT_LIGHT_2 = "#7F91B119",
  ENVIRONMENT_BAR_INDICATOR = "#3F5274",
  NEW_CHARACTER_BAR_ACTIVE = "#7FC4C8",
  NEW_CHARACTER_INACTIVE_2 = "#7FC4C819",
  BAR_DECREMENT_COLOR = "#C9C9C9",
  ENVIRONMENT_BAR_DARK = "#46608E",
  LOW_RANGE_BAND_COLOR = "#E16B67",
  MID_RANGE_BAND_COLOR = "#E9BB67",
  HIGH_RANGE_BAND_COLOR = "#AFC973",
  ENVIRONMENT_DARK_2 = "#798CB8",
  AI_PREDICTIVE_LIGHT_2 = "#F8C87E",
  ABILITY_LIGHT_NEW = "#C48BB71A",
  CHARACTER_LIGHT_NEW = "#7FC4C81A",
  ENVIRONMENT_LIGHT_NEW = "#3E4E771A",
  CHARACTER_NEW_DARK = "#505D6F",
  CHARACTER_NEW_LIGHT = "#B2DCDE4C",
  ENV_NEW_LIGHT = "#8091B133 ",
  BLACK = "#000000",
  WHITE = "#FFFFFF",
  BRIGHT_TRAFFIC_LIGHT_CHARACTER_HIGH = "#32AEB6",
  BRIGHT_TRAFFIC_LIGHT_CHARACTER_MID = "#7FC4C8",
  BRIGHT_TRAFFIC_LIGHT_CHARACTER_LOW = "#7CE2E8",
}
