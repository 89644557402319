import { action, observable, runInAction } from "mobx";
import { Question } from "../models/QuestionModel";
import { questionService } from "../services/QuestionService";
import Store from "./Store";

export class QuestionStore extends Store<Question> {
  private static _instance: QuestionStore;
  @observable isLoading = false;
  @observable isLoaded = false;

  constructor() {
    super();
    Question._store = this;
  }

  static getInstance(): QuestionStore {
    if (!this._instance) {
      this._instance = new QuestionStore();
    }

    return this._instance;
  }

  @action
  fetchQuestions() {
    this.isLoading = true;
    questionService.getAll().then((questions) => {
      runInAction(() =>
        questions.map((question) => Question.fromJson(question)),
      );
      this.isLoaded = true;
      this.isLoading = false;
      return;
    });
  }

  syncQuestions() {
    this.isLoading = true;
    return questionService.syncQuestions();
  }
}
