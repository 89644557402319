export enum ErrorCode {
  UNRECOGNIZED_EMAIL = 101,
  EMAIL_ALREADY_REGISTERED = 102,
  USER_DEACTIVATED = 103,
  INCORRECT_PASSWORD = 104,
  UNPROCESSED_PAYMENT = 110,
  USER_EMAIL_VERIFICATION_OTP_NOT_FOUND = 112,
  USER_EMAIL_VERIFICATION_WRONG_OTP = 113,
  NO_USER_AFTER_FILTER = 301,
  NO_USER_COMPLETED_ASSESSMENT = 302,
  INSUFFICIENT_USER_TO_GENERATE_REPORT = 303,
  COUPON_NOT_FOUND = 701,
  COUPON_EXPIRED = 702,
  DUPLICATE_COUPON = 703,
  ORGANIZATION_NOT_FOUND = 1601,
  ORGANIZATION_LIMIT_REACHED = 1603,
  TEAM_NOT_FOUND = 1702,
  RESET_PASSWORD_TOKEN_EXPIRED = 8104,
  INVITATION_LINK_EXPIRED = 8105,
  UNPROCESSABLE_ENTITY = 9998,
  DATABASE_ERROR = 9999,
  DAILY_EMAIL_INVITE_LIMIT = 8501,
  SESSION_EXPIRED = 1018,
  UNUSUAL_ACTIVITY = 1019,
  CAPTCHA_REQUIRED = 1020,
  EMAIL_ALREADY_EXIST = 1602,
}
