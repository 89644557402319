import NotificationSettings from "./components/notification-settings";
import { SvgIconType } from "./components/shared/svgIconComponent/svgIcons.list";
import { Dimensions } from "./enums/dimension-id.enum";
import { PromotionalScreens } from "./enums/promotional-screens.enum";
import { Role } from "./enums/role.enum";
import { Routes } from "./routes";
import { RouteUtil } from "./utils/route-utils";

export type TimezoneListItem = {
  value: string;
  abbr: string;
  offset: string;
  label: string;
  val: string;
};
export type TimezoneList = {
  abbreviation: string;
  dst: boolean;
  dst_from: string | null;
  dst_offset: number;
  dst_until: string | null;
  timezone: string;
  utc_offset: string;
};
export type SpokenLanguages = {
  key: string;
  language_name: string;
};

export class Constants {
  static WEIGHT = 9;
  static OVERVIEW_MAX = 900;
  static ABILITY_MAX = 450;
  static ENVIRONMENT_MAX = 250;
  static MAIN_LOGO_ID = "main-logo-id";
  static APP_TEST_STRIPE_KEY = "app_test_stripe_key";
  static SCORE_CALCULATOR_ENVIRONMENT_GRAPH_ID = "calculator-environment-graph";
  static SCORE_CALCULATOR_ABILITY_GRAPH_ID = "calculator-ability-graph";
  static SCORE_CALCULATOR_OVERVIEW_GRAPH_ID = "calculator-overview-graph";

  static OVERVIEW_GRAPH_ID = "overview-graph";
  static OVERVIEW_GRAPH_ID_FOR_TEAM_REPORT = "overview-graph-for-team-report";
  static OVERVIEW_GRAPH_ID_COPY = "overview-graph-id-copy";
  static OVERVIEW_ABILITY_GRAPH_ID = "overview-ability-graph";
  static OVERVIEW_CHARACTER_GRAPH_ID = "overview-character-graph";
  static OVERVIEW_ENVIRONMENT_GRAPH_ID = "overview-environment-graph";
  static OVERVIEW_OPTIMISATION_GRAPH_ID = "overview-optimisation-graph";
  static ABILITY_GRAPH_ID = "ability-graph";
  static ABILITY_GRAPH_ID_COPY = "ability-graph-copy";
  static HOW_TO_USE_REPORT_ABILITY_GRAPH_ID = "how-to-use-report-ability-graph";
  static TEAM_REPORT_ABILITY_GRAPH_ID = "team-report-ability-graph";
  static CHARACTER_GRAPH_ID = "character-graph";
  static TEAM_REPORT_CHARACTER_GRAPH_ID = "team-report-character-graph";
  static CHARACTER_GRAPH_ID_COPY = "character-graph-id-copy";
  static CHARACTER_GRAPH_ID_SECOND_COPY = "character-graph-copy";
  static CHARACTER_GRAPH_ID_YOUR_PROFILE = "character-graph-id-your-profile";
  static HOW_TO_USE_REPORT_CHARACTER_GRAPH_ID =
    "how-to-use-report-character-graph";
  static ENVIRONMENT_GRAPH_ID = "environment-graph";
  static TEAM_REPORT_ENVIRONMENT_GRAPH_ID = "team-report-environment-graph";
  static ENVIRONMENT_GRAPH_ID_COPY = "environment-graph-copy";
  static ENVIRONMENT_WORK_STRESS_GRAPH_ID = "environment-work-stress-graph";
  static ENVIRONMENT_SHORT_WORK_STRESS_GRAPH_ID =
    "environment-short-work-stress-graph";
  static HOW_TO_USE_REPORT_ENVIRONMENT_GRAPH_ID =
    "how-to-use-report-environment-graph";
  static OPTIMISATION_GRAPH_ID = "optimisation-graph";
  static OPTIMISATION_GRAPH_ID_COPY = "optimisation-graph-copy";
  static HOW_TO_USE_OPTIMISATION_GRAPH_ID = "how-to-use-optimisation-graph";
  static OPTIMISATION_MAIN_GRAPH_ID = "optimisation-main-graph";
  static MOTIVATION_GRAPH_ID = "character-motivation-graph";
  static SHORT_MOTIVATION_GRAPH_ID = "character-short-motivation-graph";
  static THINKING_STYLE_GRAPH_ID = "character-thinking-style-graph";
  static SHORT_THINKING_STYLE_GRAPH_ID = "character-short-thinking-style-graph";
  static EXTRAVERSION_GRAPH_ID = "character-extraversion-graph";
  static SHORT_EXTRAVERSION_GRAPH_ID = "character-short-extraversion-graph";
  static HOPE_GRAPH_ID = "character-hope-graph";
  static SHORT_HOPE_GRAPH_ID = "character-short-hope-graph";
  static EMOTIONAL_RANGE_GRAPH_ID = "character-emotional-range-graph";
  static SHORT_EMOTIONAL_RANGE_GRAPH_ID =
    "character-short-emotional-range-graph";

  static ENTERPRISE_OVERVIEW_GRAPH_ID = "enterprise-overview-graph";
  static ENTERPRISE_OVERVIEW_ABILITY_GRAPH_ID =
    "enterprise-overview-ability-graph";
  static ENTERPRISE_OVERVIEW_CHARACTER_GRAPH_ID =
    "enterprise-overview-character-graph";
  static ENTERPRISE_OVERVIEW_ENVIRONMENT_GRAPH_ID =
    "enterprise-overview-environment-graph";
  static ENTERPRISE_OVERVIEW_OPTIMISATION_GRAPH_ID =
    "enterprise-overview-optimisation-graph";
  static ENTERPRISE_ABILITY_GRAPH_ID = "enterprise-ability-graph";
  static ENTERPRISE_CHARACTER_GRAPH_ID = "enterprise-character-graph";
  static ENTERPRISE_ENVIRONMENT_GRAPH_ID = "enterprise-environment-graph";
  static ENTERPRISE_OPTIMISATION_GRAPH_ID = "enterprise-optimisation-graph";
  static ENTERPRISE_OPTIMISATION_MAIN_GRAPH_ID =
    "enterprise-optimisation-main-graph";
  static ENTERPRISE_OPTIMISATION_GRAPH_ID_COPY =
    "enterprise-optimisation-graph-copy";
  static ENTERPRISE_ENVIRONMENT_WORK_STRESS_GRAPH_ID =
    "enterprise_environment-work-stress-graph";
  static ENTERPRISE_MOTIVATION_GRAPH_ID =
    "enterprise_character-motivation-graph";
  static ENTERPRISE_THINKING_STYLE_GRAPH_ID = "enterprise-thinking-style-graph";
  static ENTERPRISE_EXTRAVERSION_GRAPH_ID = "enterprise-extraversion-graph";
  static ENTERPRISE_HOPE_GRAPH_ID = "enterprise-hope-graph";
  static ENTERPRISE_EMOTIONAL_RANGE_ID = "enterprise-emotional-range-graph";

  static DASHBOARD_OVERVIEW_GRAPH_ID = "dashboard-overview-graph";
  static DASHBOARD_OVERVIEW_ABILITY_GRAPH_ID =
    "dashboard-overview-ability-graph";
  static DASHBOARD_OVERVIEW_CHARACTER_GRAPH_ID =
    "dashboard-overview-character-graph";
  static DASHBOARD_OVERVIEW_ENVIRONMENT_GRAPH_ID =
    "dashboard-overview-environment-graph";
  static DASHBOARD_OVERVIEW_OPTIMISATION_GRAPH_ID =
    "dashboard-overview-optimisation-graph";
  static DASHBOARD_ABILITY_GRAPH_ID = "dashboard-ability-graph";
  static DASHBOARD_CHARACTER_GRAPH_ID = "dashboard-character-graph";
  static DASHBOARD_ENVIRONMENT_GRAPH_ID = "dashboard-environment-graph";
  static DASHBOARD_OPTIMISATION_GRAPH_ID = "dashboard-optimisation-graph";
  static DASHBOARD_OPTIMISATION_MAIN_GRAPH_ID =
    "dashboard-optimisation-main-graph";
  static DASHBOARD_ENVIRONMENT_WORK_STRESS_GRAPH_ID =
    "dashboard_environment-work-stress-graph";
  static DASHBOARD_MOTIVATION_GRAPH_ID = "dashboard_character-motivation-graph";
  static DASHBOARD_THINKING_STYLE_GRAPH_ID = "dashboard-thinking-style-graph";
  static DASHBOARD_EXTRAVERSION_GRAPH_ID = "dashboard-extraversion-graph";
  static DASHBOARD_EMOTIONAL_RANGE_ID = "dashboard-emotional-range-graph";
  static DASHBOARD_HOPE_ID = "dashboard-hope-graph";

  static AQPIONEER_ASSESSMENT_LIMIT = 301;
  static AQVISION_ASSESSMENT_LIMIT = 101;
  static AQTEAM_ASSESSMENT_LIMIT = 51;
  static AQTEAMTASTER_ASSESSMENT_LIMIT = 26;

  static MIN_TEAM_ASSESSMENT_LIMIT = 5;

  static AQLITE_QUESTION_IDS = [
    "welcome",
    "aida_pre_text_1",
    "aida_a_res1",
    "aida_a_res2_r",
    "aida_a_res3_r",
    "aida_a_res4",
    "aida_interlude_3",
    "aida_a_lea_ul_1_r",
    "aida_a_lea_ul_2",
    "aida_a_lea_ul_3",
    "aida_a_lea_ul_5",
    "aida_a_lea_ul_6_r",
    "aida_c_hopep1",
    "aida_c_hopea1",
    "aida_c_hopep2",
    "aida_c_hopea2",
    "aida_c_hopep3",
    "aida_c_hopea3",
    "aida_e_well_wrk1",
    "aida_e_well_wrk2",
    "aida_e_well_wrk3",
    "aida_e_post1",
  ];

  static QUESTION_IDS = [
    "welcome",
    "aida_pre_text_1",
    "aida_a_flex_exp1",
    "aida_a_flex_exp2",
    "aida_a_flex_exp3",
    "aida_a_flex_exp4",
    "aida_a_flex_exp5",
    "aida_a_flex_ext1",
    "aida_a_flex_ext2",
    "aida_a_flex_ext3",
    "aida_a_flex_ext4",
    "aida_a_flex_ext5",
    "aida_interlude_1",
    "aida_interlude_1q",
    "aida_interlude_1r",
    "aida_a_res1",
    "aida_a_res2_r",
    "aida_a_res3_r",
    "aida_a_res4",
    "aida_interlude_3",
    "aida_a_lea_ul_1_r",
    "aida_a_lea_ul_2",
    "aida_a_lea_ul_3",
    "aida_a_lea_ul_5",
    "aida_a_lea_ul_6_r",
    "aida_a_lea_para1",
    "aida_a_lea_para2",
    "aida_a_lea_para3",
    "aida_a_lea_para4",
    "aida_a_lea_para5",
    "aida_a_lea_para6",
    "aida_a_lea_para7",
    "aida_a_lea_para8",
    "aida_a_lea_para9",
    "aida_interlude_4",
    "aida_interlude_4b",
    "aida_c_opt1",
    "aida_c_opt2_f",
    "aida_c_opt3_r",
    "aida_c_opt4",
    "aida_c_opt5_f",
    "aida_c_opt6_f",
    "aida_c_opt7_r",
    "aida_c_opt8_f",
    "aida_c_opt9_r",
    "aida_c_opt10",
    "aida_a_grit_pr1",
    "aida_a_grit_pr2",
    "aida_a_grit_pr3",
    "aida_a_grit_pr4",
    "aida_a_grit_pr5",
    "aida_interlude_7",
    "aida_c_per_e1_r",
    "aida_c_per_e2",
    "aida_c_per_n1_r",
    "aida_c_per_n2",
    "aida_c_per_post1",
    "aida_interlude_8",
    "aida_c_mot_pre1",
    "aida_c_mot_pre2",
    "aida_c_mot_pre3",
    "aida_c_mot_pre4",
    "aida_c_mot_pro1",
    "aida_c_mot_pro2",
    "aida_c_mot_pro3",
    "aida_c_mot_pro4",
    "aida_c_think1",
    "aida_c_think2",
    "aida_c_think3",
    "aida_c_hopep1",
    "aida_c_hopea1",
    "aida_c_hopep2",
    "aida_c_hopea2",
    "aida_c_hopep3",
    "aida_c_hopea3",
    "aida_e_comm_txt1",
    "aida_e_comm_txt2",
    "aida_e_comm_txt3",
    "aida_e_comm_cm1",
    "aida_e_comm_cm4",
    "aida_e_comm_cm5_r",
    "aida_e_comm_cm_6",
    "aida_e_comm_tm1",
    "aida_e_comm_tm2_r",
    "aida_e_comm_tm3",
    "aida_e_comm_tm4_r",
    "aida_e_comm_tm5_r",
    "aida_e_en_place1",
    "aida_e_en_place2",
    "aida_e_en_place3",
    "aida_e_en_cult1",
    "aida_e_en_cult2",
    "aida_e_en_cult3_r",
    "aida_e_en_cult4",
    "aida_e_en_cult5",
    "aida_e_en_cult6_r",
    "aida_e_en_cult7",
    "aida_e_en_re_1",
    "aida_e_en_re_2",
    "aida_e_well_ex1",
    "aida_e_well_ex2",
    "aida_e_well_ex3",
    "aida_e_well_ex4",
    "aida_e_well_wrk1",
    "aida_e_well_wrk2",
    "aida_e_well_wrk3",
    "aida_e_ch1",
    "aida_e_ch2",
    "aida_e_ch3",
    "aida_e_ch_post1",
    "aida_others_adap1",
    "aida_others_adap2",
    "aida_others_adap3",
    "aida_e_post1",
  ];

  static ABILITY_SCORE_WEIGHT_MAP: { [id: number]: number } = {
    [Dimensions.GRIT_ID]: 1.1,
    [Dimensions.MENTAL_FLEXIBILITY_ID]: 0.7,
    [Dimensions.MINDSET_ID]: 1.1,
    [Dimensions.RESILIENCE_ID]: 0.8,
    [Dimensions.UNLEARN_ID]: 0.8,
  };

  static ENVIRONMENT_SCORE_WEIGHT_MAP: { [id: number]: number } = {
    [Dimensions.COMPANY_SUPPORT_ID]: 0.8,
    [Dimensions.EMOTIONAL_HEALTH_ID]: 0.4,
    [Dimensions.WORK_STRESS_ID]: 0.4,
    [Dimensions.TEAM_SUPPORT_ID]: 0.9,
    [Dimensions.WORK_ENVIRONMENT_ID]: 0,
  };

  static PROMOTIONAL_SCREENS_MAP: {
    [key in PromotionalScreens]: Array<{ label: string; value: number }>;
  } = {
    [PromotionalScreens.REGISTRATION]: [
      { label: "Enter personal details and email verification", value: 1 },
      { label: "Gender, age and country", value: 2 },
      { label: "Professional details", value: 3 },
      { label: "Billing details", value: 4 },
      { label: " Payment page", value: 5 },
      { label: "Lets explore", value: 6 },
    ],
    [PromotionalScreens.LOGIN]: [{ label: "Login Screen", value: 1 }],
  };

  static DASHBOARDROUTES = [
    {
      id: "Home",
      name: "Home",
      path: RouteUtil.getUrl(Routes.home),
      leadingIcon: SvgIconType.homeIcon,
      leadingIconActive: SvgIconType.homeActiveIcon,
      component: "",
      role: [Role.PARTNER, Role.OWNER, Role.EMPLOYEE],
    },

    {
      id: "My_Report",
      name: "My Report",
      path: RouteUtil.getUrl(Routes.aq_me),
      leadingIcon: SvgIconType.myReportIcon,
      leadingIconActive: SvgIconType.myReportIconActive,
      component: "",
      role: [Role.PARTNER, Role.OWNER, Role.EMPLOYEE],
    },

    {
      id: "All_Reports",
      name: "All Reports",
      path: RouteUtil.getUrl(Routes.reports_center),
      leadingIcon: SvgIconType.reportCenter,
      leadingIconActive: SvgIconType.reportCenterActive,
      component: "",
      role: [Role.PARTNER, Role.OWNER],

      sidebar: true,
    },

    {
      id: "People",
      name: "People",
      path: RouteUtil.getUrl(Routes.people),
      leadingIcon: SvgIconType.peopleIconNavbar,
      leadingIconActive: SvgIconType.peopleIconActive,
      component: "",
      role: [Role.PARTNER, Role.OWNER],
    },

    {
      id: "Community",
      name: "Community",
      path: RouteUtil.getUrl(Routes.community),
      leadingIcon: SvgIconType.communityIconNavBar,
      leadingIconActive: SvgIconType.communityIconActiveNavBar,
      component: "",
      role: [Role.PARTNER, Role.OWNER, Role.EMPLOYEE],
      sidebar: true,
    },
    {
      id: "Organisations_&_Teams",
      name: "Organisations & Teams",
      path: RouteUtil.getUrl(Routes.org_team_landing),
      leadingIcon: SvgIconType.orgTeamIcon,
      leadingIconActive: SvgIconType.orgTeamIconActive,
      component: "",
      role: [Role.PARTNER, Role.OWNER],
    },

    {
      id: "AQ_Journey",
      name: "AQ Journey",
      path: RouteUtil.getUrl(Routes.my_journey),
      leadingIcon: SvgIconType.cupIcon,
      leadingIconActive: SvgIconType.cupActiveIcon,
      component: "",
      role: [Role.PARTNER, Role.OWNER, Role.EMPLOYEE],

      sidebar: true,
    },

    {
      id: "Profile",
      name: "Profile",
      path: RouteUtil.getUrl(Routes.profile_landing),
      leadingIcon: SvgIconType.userIcon,
      leadingIconActive: SvgIconType.userActiveIcon,
      component: "",
      role: [Role.PARTNER, Role.OWNER, Role.EMPLOYEE],
    },

    {
      id: "Help_&_Support",
      name: "Help & Support",
      path: "https://help.aqai.io/hc/",
      leadingIcon: SvgIconType.helpSupportNavbar,
      leadingIconActive: SvgIconType.helpSupportNavbar,
      component: "",
      role: [Role.PARTNER, Role.OWNER, Role.EMPLOYEE],
      isRedirect: true,
      sidebar: true,
    },
    {
      name: "Notifications",
      path: RouteUtil.getUrl(Routes.notificationSettings),
      component: "",
      role: [Role.PARTNER, Role.OWNER],
      isRedirect: false,
      sidebar: false,
    },
  ];

  static ADMINROUTES = [
    {
      name: "Home",
      path: RouteUtil.getUrl(Routes.adminHome),
      leadingIcon: SvgIconType.homeIcon,
      leadingIconActive: SvgIconType.homeActiveIcon,
      component: "",
      role: [Role.ADMIN, Role.CDP, Role.CORPORATE],
    },

    {
      id: "Profile",
      name: "Profile",
      path: RouteUtil.getUrl(Routes.cdpCorporateProfile),
      leadingIcon: SvgIconType.userIcon,
      leadingIconActive: SvgIconType.userActiveIcon,
      component: "",
      role: [Role.CDP, Role.CORPORATE],
    },
    {
      name: "Users",
      path: RouteUtil.getUrl(Routes.adminUsers),
      leadingIcon: SvgIconType.peopleIconNavbar,
      leadingIconActive: SvgIconType.peopleIconActive,
      component: "",
      role: [Role.ADMIN, Role.CDP, Role.CORPORATE],
    },
    {
      id: "team_report",
      name: "Team Report",
      path: RouteUtil.getUrl(Routes.adminReport),
      leadingIcon: SvgIconType.myReportIcon,
      leadingIconActive: SvgIconType.myReportIconActive,
      component: "",
      role: [Role.CORPORATE],
    },

    {
      name: "Community",
      path: RouteUtil.getUrl(Routes.adminCommunity),
      leadingIcon: SvgIconType.communityIconNavBar,
      leadingIconActive: SvgIconType.communityIconActiveNavBar,
      component: "",
      role: [Role.ADMIN],

      sidebar: true,
    },
    {
      name: "Organisations",
      path: RouteUtil.getUrl(Routes.adminOrgnizations),
      leadingIcon: SvgIconType.orgTeamIcon,
      leadingIconActive: SvgIconType.orgTeamIconActive,
      component: "",
      role: [Role.ADMIN, Role.CDP, Role.CORPORATE],
    },
    {
      name: "Meta Information",
      path: RouteUtil.getUrl(Routes.reportMetaInformation),
      leadingIcon: SvgIconType.orgTeamIcon,
      leadingIconActive: SvgIconType.orgTeamIconActive,
      component: "",
      role: [Role.ADMIN],
    },
    {
      name: "Coupons",
      path: RouteUtil.getUrl(Routes.adminCoupons),
      leadingIcon: SvgIconType.orgTeamIcon,
      leadingIconActive: SvgIconType.orgTeamIconActive,
      component: "",
      role: [Role.ADMIN],
    },
    {
      name: "Assessment Invite",
      path: RouteUtil.getUrl(Routes.adminAssessmentInvite),
      leadingIcon: SvgIconType.orgTeamIcon,
      leadingIconActive: SvgIconType.orgTeamIconActive,
      component: "",
      role: [Role.ADMIN],
    },
    {
      name: "Reports",
      path: RouteUtil.getUrl(Routes.adminReports),
      leadingIcon: SvgIconType.myReportIcon,
      leadingIconActive: SvgIconType.myReportIconActive,
      component: "",
      role: [Role.ADMIN],
    },
    {
      name: "Account",
      path: RouteUtil.getUrl(Routes.cdpCorporateAccount),
      leadingIcon: SvgIconType.reportCenter,
      leadingIconActive: SvgIconType.reportCenterActive,
      component: "",
      role: [Role.CDP, Role.CORPORATE],
    },
    {
      name: "Product Documents",
      path: RouteUtil.getUrl(Routes.productUpdates),
      leadingIcon: SvgIconType.reportCenter,
      leadingIconActive: SvgIconType.reportCenter,
      component: "",
      role: [Role.CDP, Role.CORPORATE],
    },
    {
      name: "Settings",
      path: RouteUtil.getUrl(Routes.settings),
      leadingIcon: SvgIconType.reportCenter,
      leadingIconActive: SvgIconType.reportCenter,
      component: "",
      role: [Role.ADMIN],
    },
    {
      name: "Update Password",
      path: RouteUtil.getUrl(Routes.updatePassword),
      leadingIcon: SvgIconType.reportCenter,
      leadingIconActive: SvgIconType.reportCenter,
      component: "",
      role: [Role.ADMIN],
    },
    {
      name: "Help & Support",
      path: "https://help.aqai.io/agent/",
      leadingIcon: SvgIconType.helpSupportNavbar,
      leadingIconActive: SvgIconType.helpSupportNavbar,
      component: "",
      role: [Role.ADMIN],
      isRedirect: true,
      sidebar: true,
    },

    {
      name: "Help & Support",
      path: "https://help.aqai.io/hc/",
      leadingIcon: SvgIconType.helpSupportNavbar,
      leadingIconActive: SvgIconType.helpSupportNavbar,
      component: "",
      role: [Role.CDP, Role.CORPORATE],
      isRedirect: true,
      sidebar: true,
    },
  ];

  static timezone = [
    {
      abbreviation: "GMT",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Africa/Abidjan",
      utc_offset: "+00:00",
    },
    {
      abbreviation: "GMT",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Africa/Accra",
      utc_offset: "+00:00",
    },
    {
      abbreviation: "+01",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Africa/Casablanca",
      utc_offset: "+01:00",
    },
    {
      abbreviation: "+01",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Africa/El_Aaiun",
      utc_offset: "+01:00",
    },
    {
      abbreviation: "WAT",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Africa/Ndjamena",
      utc_offset: "+01:00",
    },
    {
      abbreviation: "WAT",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Africa/Lagos",
      utc_offset: "+01:00",
    },
    {
      abbreviation: "CAT",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Africa/Khartoum",
      utc_offset: "+02:00",
    },
    {
      abbreviation: "GMT",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Africa/Monrovia",
      utc_offset: "+00:00",
    },
    {
      abbreviation: "CAT",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Africa/Maputo",
      utc_offset: "+02:00",
    },
    {
      abbreviation: "GMT",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Africa/Sao_Tome",
      utc_offset: "+00:00",
    },
    {
      abbreviation: "CET",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Africa/Tunis",
      utc_offset: "+01:00",
    },
    {
      abbreviation: "AKDT",
      dst: true,
      dst_from: "2021-03-14T11:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2021-11-07T10:00:00+00:00",
      timezone: "America/Anchorage",
      utc_offset: "-08:00",
    },
    {
      abbreviation: "-03",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Argentina/Catamarca",
      utc_offset: "-03:00",
    },
    {
      abbreviation: "-03",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Argentina/Jujuy",
      utc_offset: "-03:00",
    },
    {
      abbreviation: "-03",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Argentina/Cordoba",
      utc_offset: "-03:00",
    },
    {
      abbreviation: "-03",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Argentina/San_Luis",
      utc_offset: "-03:00",
    },
    {
      abbreviation: "-03",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Argentina/Tucuman",
      utc_offset: "-03:00",
    },
    {
      abbreviation: "-03",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Argentina/Ushuaia",
      utc_offset: "-03:00",
    },
    {
      abbreviation: "AST",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Barbados",
      utc_offset: "-04:00",
    },
    {
      abbreviation: "-03",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Bahia",
      utc_offset: "-03:00",
    },
    {
      abbreviation: "-03",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Belem",
      utc_offset: "-03:00",
    },
    {
      abbreviation: "AST",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Blanc-Sablon",
      utc_offset: "-04:00",
    },
    {
      abbreviation: "-04",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Campo_Grande",
      utc_offset: "-04:00",
    },
    {
      abbreviation: "-04",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Boa_Vista",
      utc_offset: "-04:00",
    },
    {
      abbreviation: "CST",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Costa_Rica",
      utc_offset: "-06:00",
    },
    {
      abbreviation: "EST",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Cancun",
      utc_offset: "-05:00",
    },
    {
      abbreviation: "MST",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Creston",
      utc_offset: "-07:00",
    },
    {
      abbreviation: "-04",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Caracas",
      utc_offset: "-04:00",
    },
    {
      abbreviation: "MST",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Dawson_Creek",
      utc_offset: "-07:00",
    },
    {
      abbreviation: "MDT",
      dst: true,
      dst_from: "2021-03-14T09:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2021-11-07T08:00:00+00:00",
      timezone: "America/Edmonton",
      utc_offset: "-06:00",
    },
    {
      abbreviation: "-05",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Eirunepe",
      utc_offset: "-05:00",
    },
    {
      abbreviation: "ADT",
      dst: true,
      dst_from: "2021-03-14T06:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2021-11-07T05:00:00+00:00",
      timezone: "America/Goose_Bay",
      utc_offset: "-03:00",
    },
    {
      abbreviation: "MST",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Fort_Nelson",
      utc_offset: "-07:00",
    },
    {
      abbreviation: "CST",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/El_Salvador",
      utc_offset: "-06:00",
    },
    {
      abbreviation: "CDT",
      dst: true,
      dst_from: "2021-03-14T08:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2021-11-07T07:00:00+00:00",
      timezone: "America/Menominee",
      utc_offset: "-05:00",
    },
    {
      abbreviation: "CDT",
      dst: true,
      dst_from: "2021-03-14T08:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2021-11-07T07:00:00+00:00",
      timezone: "America/Indiana/Tell_City",
      utc_offset: "-05:00",
    },
    {
      abbreviation: "EDT",
      dst: true,
      dst_from: "2021-03-14T07:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2021-11-07T06:00:00+00:00",
      timezone: "America/Kentucky/Monticello",
      utc_offset: "-04:00",
    },
    {
      abbreviation: "EDT",
      dst: true,
      dst_from: "2021-03-14T07:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2021-11-07T06:00:00+00:00",
      timezone: "America/Indiana/Vincennes",
      utc_offset: "-04:00",
    },
    {
      abbreviation: "EDT",
      dst: true,
      dst_from: "2021-03-14T07:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2021-11-07T06:00:00+00:00",
      timezone: "America/Indiana/Vevay",
      utc_offset: "-04:00",
    },
    {
      abbreviation: "CST",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Guatemala",
      utc_offset: "-06:00",
    },
    {
      abbreviation: "-04",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Manaus",
      utc_offset: "-04:00",
    },
    {
      abbreviation: "MST",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Hermosillo",
      utc_offset: "-07:00",
    },
    {
      abbreviation: "EDT",
      dst: true,
      dst_from: "2021-03-14T07:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2021-11-07T06:00:00+00:00",
      timezone: "America/Kentucky/Louisville",
      utc_offset: "-04:00",
    },
    {
      abbreviation: "EDT",
      dst: true,
      dst_from: "2021-03-14T07:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2021-11-07T06:00:00+00:00",
      timezone: "America/Iqaluit",
      utc_offset: "-04:00",
    },
    {
      abbreviation: "AKDT",
      dst: true,
      dst_from: "2021-03-14T11:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2021-11-07T10:00:00+00:00",
      timezone: "America/Juneau",
      utc_offset: "-08:00",
    },
    {
      abbreviation: "MDT",
      dst: true,
      dst_from: "2021-03-14T09:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2021-11-07T08:00:00+00:00",
      timezone: "America/Inuvik",
      utc_offset: "-06:00",
    },
    {
      abbreviation: "EDT",
      dst: true,
      dst_from: "2021-03-14T07:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2021-11-07T06:00:00+00:00",
      timezone: "America/Indiana/Winamac",
      utc_offset: "-04:00",
    },
    {
      abbreviation: "AST",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Martinique",
      utc_offset: "-04:00",
    },
    {
      abbreviation: "CDT",
      dst: true,
      dst_from: "2021-03-14T08:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2021-11-07T07:00:00+00:00",
      timezone: "America/Matamoros",
      utc_offset: "-05:00",
    },
    {
      abbreviation: "EDT",
      dst: true,
      dst_from: "2021-03-14T07:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2021-11-07T06:00:00+00:00",
      timezone: "America/Indiana/Indianapolis",
      utc_offset: "-04:00",
    },
    {
      abbreviation: "-04",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/La_Paz",
      utc_offset: "-04:00",
    },
    {
      abbreviation: "EDT",
      dst: true,
      dst_from: "2021-03-14T07:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2021-11-07T06:00:00+00:00",
      timezone: "America/Indiana/Petersburg",
      utc_offset: "-04:00",
    },
    {
      abbreviation: "-05",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Guayaquil",
      utc_offset: "-05:00",
    },
    {
      abbreviation: "EET",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Africa/Cairo",
      utc_offset: "+02:00",
    },
    {
      abbreviation: "CET",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Africa/Ceuta",
      utc_offset: "+01:00",
    },
    {
      abbreviation: "-04",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Guyana",
      utc_offset: "-04:00",
    },
    {
      abbreviation: "-05",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Lima",
      utc_offset: "-05:00",
    },
    {
      abbreviation: "CST",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Managua",
      utc_offset: "-06:00",
    },
    {
      abbreviation: "EST",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Jamaica",
      utc_offset: "-05:00",
    },
    {
      abbreviation: "+05",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Antarctica/Mawson",
      utc_offset: "+05:00",
    },
    {
      abbreviation: "-03",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Antarctica/Palmer",
      utc_offset: "-03:00",
    },
    {
      abbreviation: "CET",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Africa/Algiers",
      utc_offset: "+01:00",
    },
    {
      abbreviation: "EET",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Amman",
      utc_offset: "+02:00",
    },
    {
      abbreviation: "+06",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Almaty",
      utc_offset: "+06:00",
    },
    {
      abbreviation: "GMT",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Africa/Bissau",
      utc_offset: "+00:00",
    },
    {
      abbreviation: "EAT",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Africa/Nairobi",
      utc_offset: "+03:00",
    },
    {
      abbreviation: "CAT",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Africa/Juba",
      utc_offset: "+02:00",
    },
    {
      abbreviation: "CAT",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Africa/Windhoek",
      utc_offset: "+02:00",
    },
    {
      abbreviation: "EET",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Africa/Tripoli",
      utc_offset: "+02:00",
    },
    {
      abbreviation: "-03",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Argentina/La_Rioja",
      utc_offset: "-03:00",
    },
    {
      abbreviation: "-03",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Argentina/Rio_Gallegos",
      utc_offset: "-03:00",
    },
    {
      abbreviation: "-03",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Araguaina",
      utc_offset: "-03:00",
    },
    {
      abbreviation: "-03",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Argentina/San_Juan",
      utc_offset: "-03:00",
    },
    {
      abbreviation: "-03",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Argentina/Buenos_Aires",
      utc_offset: "-03:00",
    },
    {
      abbreviation: "WIB",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Jakarta",
      utc_offset: "+07:00",
    },
    {
      abbreviation: "-03",
      dst: true,
      dst_from: "2021-10-03T04:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2022-03-27T03:00:00+00:00",
      timezone: "America/Asuncion",
      utc_offset: "-03:00",
    },
    {
      abbreviation: "AKDT",
      dst: true,
      dst_from: "2021-03-14T11:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2021-11-07T10:00:00+00:00",
      timezone: "America/Metlakatla",
      utc_offset: "-08:00",
    },
    {
      abbreviation: "GMT",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Danmarkshavn",
      utc_offset: "+00:00",
    },
    {
      abbreviation: "EST",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Atikokan",
      utc_offset: "-05:00",
    },
    {
      abbreviation: "CST",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Bahia_Banderas",
      utc_offset: "-06:00",
    },
    {
      abbreviation: "ADT",
      dst: true,
      dst_from: "2021-03-14T06:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2021-11-07T05:00:00+00:00",
      timezone: "America/Glace_Bay",
      utc_offset: "-03:00",
    },
    {
      abbreviation: "MST",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Chihuahua",
      utc_offset: "-07:00",
    },
    {
      abbreviation: "MDT",
      dst: true,
      dst_from: "2021-03-14T09:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2021-11-07T08:00:00+00:00",
      timezone: "America/Cambridge_Bay",
      utc_offset: "-06:00",
    },
    {
      abbreviation: "MST",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Mazatlan",
      utc_offset: "-07:00",
    },
    {
      abbreviation: "-02",
      dst: true,
      dst_from: "2021-03-14T05:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2021-11-07T04:00:00+00:00",
      timezone: "America/Miquelon",
      utc_offset: "-02:00",
    },
    {
      abbreviation: "EDT",
      dst: true,
      dst_from: "2021-03-14T07:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2021-11-07T06:00:00+00:00",
      timezone: "America/Grand_Turk",
      utc_offset: "-04:00",
    },
    {
      abbreviation: "CST",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Belize",
      utc_offset: "-06:00",
    },
    {
      abbreviation: "PDT",
      dst: true,
      dst_from: "2021-03-14T10:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2021-11-07T09:00:00+00:00",
      timezone: "America/Los_Angeles",
      utc_offset: "-07:00",
    },
    {
      abbreviation: "CDT",
      dst: true,
      dst_from: "2021-03-14T08:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2021-11-07T07:00:00+00:00",
      timezone: "America/Indiana/Knox",
      utc_offset: "-05:00",
    },
    {
      abbreviation: "-03",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Maceio",
      utc_offset: "-03:00",
    },
    {
      abbreviation: "AST",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Santo_Domingo",
      utc_offset: "-04:00",
    },
    {
      abbreviation: "EDT",
      dst: true,
      dst_from: "2021-03-14T07:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2021-11-07T06:00:00+00:00",
      timezone: "America/Indiana/Marengo",
      utc_offset: "-04:00",
    },
    {
      abbreviation: "CDT",
      dst: true,
      dst_from: "2021-03-14T05:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2021-11-07T05:00:00+00:00",
      timezone: "America/Havana",
      utc_offset: "-04:00",
    },
    {
      abbreviation: "CDT",
      dst: true,
      dst_from: "2021-03-14T08:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2021-11-07T07:00:00+00:00",
      timezone: "America/North_Dakota/Beulah",
      utc_offset: "-05:00",
    },
    {
      abbreviation: "EDT",
      dst: true,
      dst_from: "2021-03-14T07:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2021-11-07T06:00:00+00:00",
      timezone: "America/New_York",
      utc_offset: "-04:00",
    },
    {
      abbreviation: "-02",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Noronha",
      utc_offset: "-02:00",
    },
    {
      abbreviation: "-01",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Scoresbysund",
      utc_offset: "-01:00",
    },
    {
      abbreviation: "-03",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Montevideo",
      utc_offset: "-03:00",
    },
    {
      abbreviation: "NDT",
      dst: true,
      dst_from: "2021-03-14T05:30:00+00:00",
      dst_offset: 3600,
      dst_until: "2021-11-07T04:30:00+00:00",
      timezone: "America/St_Johns",
      utc_offset: "-02:30",
    },
    {
      abbreviation: "-03",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Sao_Paulo",
      utc_offset: "-03:00",
    },
    {
      abbreviation: "CST",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Merida",
      utc_offset: "-06:00",
    },
    {
      abbreviation: "-05",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Bogota",
      utc_offset: "-05:00",
    },
    {
      abbreviation: "EDT",
      dst: true,
      dst_from: "2021-03-14T07:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2021-11-07T06:00:00+00:00",
      timezone: "America/Nassau",
      utc_offset: "-04:00",
    },
    {
      abbreviation: "AKDT",
      dst: true,
      dst_from: "2021-03-14T11:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2021-11-07T10:00:00+00:00",
      timezone: "America/Nome",
      utc_offset: "-08:00",
    },
    {
      abbreviation: "EDT",
      dst: true,
      dst_from: "2021-03-14T07:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2021-11-07T06:00:00+00:00",
      timezone: "America/Nipigon",
      utc_offset: "-04:00",
    },
    {
      abbreviation: "MDT",
      dst: true,
      dst_from: "2021-03-14T09:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2021-11-07T08:00:00+00:00",
      timezone: "America/Yellowknife",
      utc_offset: "-06:00",
    },
    {
      abbreviation: "-03",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Fortaleza",
      utc_offset: "-03:00",
    },
    {
      abbreviation: "-03",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Cayenne",
      utc_offset: "-03:00",
    },
    {
      abbreviation: "AKDT",
      dst: true,
      dst_from: "2021-03-14T11:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2021-11-07T10:00:00+00:00",
      timezone: "America/Sitka",
      utc_offset: "-08:00",
    },
    {
      abbreviation: "CDT",
      dst: true,
      dst_from: "2021-03-14T08:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2021-11-07T07:00:00+00:00",
      timezone: "America/Chicago",
      utc_offset: "-05:00",
    },
    {
      abbreviation: "MST",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Dawson",
      utc_offset: "-07:00",
    },
    {
      abbreviation: "AEDT",
      dst: true,
      dst_from: "2021-10-02T16:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2022-04-02T16:00:00+00:00",
      timezone: "Antarctica/Macquarie",
      utc_offset: "+11:00",
    },
    {
      abbreviation: "+10",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Antarctica/DumontDUrville",
      utc_offset: "+10:00",
    },
    {
      abbreviation: "+11",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Antarctica/Casey",
      utc_offset: "+11:00",
    },
    {
      abbreviation: "-07",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Etc/GMT+7",
      utc_offset: "-07:00",
    },
    {
      abbreviation: "-03",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Antarctica/Rothera",
      utc_offset: "-03:00",
    },
    {
      abbreviation: "+07",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Antarctica/Davis",
      utc_offset: "+07:00",
    },
    {
      abbreviation: "+13",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Etc/GMT-13",
      utc_offset: "+13:00",
    },
    {
      abbreviation: "+14",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Etc/GMT-14",
      utc_offset: "+14:00",
    },
    {
      abbreviation: "+05",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Ashgabat",
      utc_offset: "+05:00",
    },
    {
      abbreviation: "+12",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Etc/GMT-12",
      utc_offset: "+12:00",
    },
    {
      abbreviation: "+12",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Anadyr",
      utc_offset: "+12:00",
    },
    {
      abbreviation: "+05",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Aqtau",
      utc_offset: "+05:00",
    },
    {
      abbreviation: "+05",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Atyrau",
      utc_offset: "+05:00",
    },
    {
      abbreviation: "+07",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Bangkok",
      utc_offset: "+07:00",
    },
    {
      abbreviation: "+04",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Baku",
      utc_offset: "+04:00",
    },
    {
      abbreviation: "+06",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Bishkek",
      utc_offset: "+06:00",
    },
    {
      abbreviation: "EET",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Beirut",
      utc_offset: "+02:00",
    },
    {
      abbreviation: "CET",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Europe/Belgrade",
      utc_offset: "+01:00",
    },
    {
      abbreviation: "+04",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Europe/Astrakhan",
      utc_offset: "+04:00",
    },
    {
      abbreviation: "CET",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Europe/Berlin",
      utc_offset: "+01:00",
    },
    {
      abbreviation: "SAST",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Africa/Johannesburg",
      utc_offset: "+02:00",
    },
    {
      abbreviation: "+12",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Pacific/Kwajalein",
      utc_offset: "+12:00",
    },
    {
      abbreviation: "+11",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Pacific/Kosrae",
      utc_offset: "+11:00",
    },
    {
      abbreviation: "+12",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Pacific/Majuro",
      utc_offset: "+12:00",
    },
    {
      abbreviation: "+12",
      dst: true,
      dst_from: "2021-10-02T15:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2022-04-02T15:00:00+00:00",
      timezone: "Pacific/Norfolk",
      utc_offset: "+12:00",
    },
    {
      abbreviation: "+11",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Pacific/Noumea",
      utc_offset: "+11:00",
    },
    {
      abbreviation: "+09",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Pacific/Palau",
      utc_offset: "+09:00",
    },
    {
      abbreviation: "+06",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Etc/GMT-6",
      utc_offset: "+06:00",
    },
    {
      abbreviation: "-08",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Pacific/Pitcairn",
      utc_offset: "-08:00",
    },
    {
      abbreviation: "CET",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Europe/Amsterdam",
      utc_offset: "+01:00",
    },
    {
      abbreviation: "+11",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Pacific/Pohnpei",
      utc_offset: "+11:00",
    },
    {
      abbreviation: "+08",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Etc/GMT-8",
      utc_offset: "+08:00",
    },
    {
      abbreviation: "+09",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Etc/GMT-9",
      utc_offset: "+09:00",
    },
    {
      abbreviation: "EET",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Damascus",
      utc_offset: "+02:00",
    },
    {
      abbreviation: "EET",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Europe/Helsinki",
      utc_offset: "+02:00",
    },
    {
      abbreviation: "+10",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Pacific/Port_Moresby",
      utc_offset: "+10:00",
    },
    {
      abbreviation: "WET",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Europe/Lisbon",
      utc_offset: "+00:00",
    },
    {
      abbreviation: "+09",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Chita",
      utc_offset: "+09:00",
    },
    {
      abbreviation: "+08",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Choibalsan",
      utc_offset: "+08:00",
    },
    {
      abbreviation: "WIT",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Jayapura",
      utc_offset: "+09:00",
    },
    {
      abbreviation: "+12",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Kamchatka",
      utc_offset: "+12:00",
    },
    {
      abbreviation: "HDT",
      dst: true,
      dst_from: "2021-03-14T12:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2021-11-07T11:00:00+00:00",
      timezone: "America/Adak",
      utc_offset: "-09:00",
    },
    {
      abbreviation: "+03",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Europe/Kirov",
      utc_offset: "+03:00",
    },
    {
      abbreviation: "+06",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Dhaka",
      utc_offset: "+06:00",
    },
    {
      abbreviation: "+08",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Kuala_Lumpur",
      utc_offset: "+08:00",
    },
    {
      abbreviation: "IST",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Jerusalem",
      utc_offset: "+02:00",
    },
    {
      abbreviation: "+0545",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Kathmandu",
      utc_offset: "+05:45",
    },
    {
      abbreviation: "+07",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Krasnoyarsk",
      utc_offset: "+07:00",
    },
    {
      abbreviation: "-03",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Argentina/Mendoza",
      utc_offset: "-03:00",
    },
    {
      abbreviation: "-03",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Argentina/Salta",
      utc_offset: "-03:00",
    },
    {
      abbreviation: "+0430",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Kabul",
      utc_offset: "+04:30",
    },
    {
      abbreviation: "IST",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Kolkata",
      utc_offset: "+05:30",
    },
    {
      abbreviation: "-10",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Pacific/Tahiti",
      utc_offset: "-10:00",
    },
    {
      abbreviation: "+13",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Pacific/Tongatapu",
      utc_offset: "+13:00",
    },
    {
      abbreviation: "-10",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Pacific/Rarotonga",
      utc_offset: "-10:00",
    },
    {
      abbreviation: "-06",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Pacific/Galapagos",
      utc_offset: "-06:00",
    },
    {
      abbreviation: "+12",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Pacific/Wake",
      utc_offset: "+12:00",
    },
    {
      abbreviation: "AST",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Curacao",
      utc_offset: "-04:00",
    },
    {
      abbreviation: "-04",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Cuiaba",
      utc_offset: "-04:00",
    },
    {
      abbreviation: "MDT",
      dst: true,
      dst_from: "2021-03-14T09:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2021-11-07T08:00:00+00:00",
      timezone: "America/Boise",
      utc_offset: "-06:00",
    },
    {
      abbreviation: "MDT",
      dst: true,
      dst_from: "2021-03-14T09:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2021-11-07T08:00:00+00:00",
      timezone: "America/Denver",
      utc_offset: "-06:00",
    },
    {
      abbreviation: "EDT",
      dst: true,
      dst_from: "2021-03-14T07:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2021-11-07T06:00:00+00:00",
      timezone: "America/Detroit",
      utc_offset: "-04:00",
    },
    {
      abbreviation: "CST",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Mexico_City",
      utc_offset: "-06:00",
    },
    {
      abbreviation: "CST",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Monterrey",
      utc_offset: "-06:00",
    },
    {
      abbreviation: "+12",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Pacific/Wallis",
      utc_offset: "+12:00",
    },
    {
      abbreviation: "CDT",
      dst: true,
      dst_from: "2021-03-14T08:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2021-11-07T07:00:00+00:00",
      timezone: "America/North_Dakota/New_Salem",
      utc_offset: "-05:00",
    },
    {
      abbreviation: "WET",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "WET",
      utc_offset: "+00:00",
    },
    {
      abbreviation: "CDT",
      dst: true,
      dst_from: "2021-03-14T08:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2021-11-07T07:00:00+00:00",
      timezone: "America/North_Dakota/Center",
      utc_offset: "-05:00",
    },
    {
      abbreviation: "ADT",
      dst: true,
      dst_from: "2021-03-14T06:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2021-11-07T05:00:00+00:00",
      timezone: "America/Halifax",
      utc_offset: "-03:00",
    },
    {
      abbreviation: "ADT",
      dst: true,
      dst_from: "2021-03-14T06:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2021-11-07T05:00:00+00:00",
      timezone: "America/Moncton",
      utc_offset: "-03:00",
    },
    {
      abbreviation: "-03",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Etc/GMT+3",
      utc_offset: "-03:00",
    },
    {
      abbreviation: "+07",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Etc/GMT-7",
      utc_offset: "+07:00",
    },
    {
      abbreviation: "CET",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Europe/Gibraltar",
      utc_offset: "+01:00",
    },
    {
      abbreviation: "UTC",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Etc/UTC",
      utc_offset: "+00:00",
    },
    {
      abbreviation: "EET",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Europe/Riga",
      utc_offset: "+02:00",
    },
    {
      abbreviation: "CET",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Europe/Rome",
      utc_offset: "+01:00",
    },
    {
      abbreviation: "+04",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Europe/Saratov",
      utc_offset: "+04:00",
    },
    {
      abbreviation: "+04",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Europe/Samara",
      utc_offset: "+04:00",
    },
    {
      abbreviation: "+03",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Europe/Volgograd",
      utc_offset: "+03:00",
    },
    {
      abbreviation: "+0530",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Colombo",
      utc_offset: "+05:30",
    },
    {
      abbreviation: "PKT",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Karachi",
      utc_offset: "+05:00",
    },
    {
      abbreviation: "CST",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Macau",
      utc_offset: "+08:00",
    },
    {
      abbreviation: "EET",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Europe/Zaporozhye",
      utc_offset: "+02:00",
    },
    {
      abbreviation: "+08",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Kuching",
      utc_offset: "+08:00",
    },
    {
      abbreviation: "+09",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Khandyga",
      utc_offset: "+09:00",
    },
    {
      abbreviation: "CET",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Europe/Tirane",
      utc_offset: "+01:00",
    },
    {
      abbreviation: "CET",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Europe/Vienna",
      utc_offset: "+01:00",
    },
    {
      abbreviation: "CET",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Europe/Warsaw",
      utc_offset: "+01:00",
    },
    {
      abbreviation: "+13",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Pacific/Enderbury",
      utc_offset: "+13:00",
    },
    {
      abbreviation: "EET",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Europe/Vilnius",
      utc_offset: "+02:00",
    },
    {
      abbreviation: "+12",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Pacific/Funafuti",
      utc_offset: "+12:00",
    },
    {
      abbreviation: "+10",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Pacific/Chuuk",
      utc_offset: "+10:00",
    },
    {
      abbreviation: "+06",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Indian/Chagos",
      utc_offset: "+06:00",
    },
    {
      abbreviation: "+13",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Pacific/Fakaofo",
      utc_offset: "+13:00",
    },
    {
      abbreviation: "HST",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "HST",
      utc_offset: "-10:00",
    },
    {
      abbreviation: "CET",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Europe/Zurich",
      utc_offset: "+01:00",
    },
    {
      abbreviation: "-05",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Etc/GMT+5",
      utc_offset: "-05:00",
    },
    {
      abbreviation: "-04",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Etc/GMT+4",
      utc_offset: "-04:00",
    },
    {
      abbreviation: "+10",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Etc/GMT-10",
      utc_offset: "+10:00",
    },
    {
      abbreviation: "-08",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Etc/GMT+8",
      utc_offset: "-08:00",
    },
    {
      abbreviation: "+01",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Etc/GMT-1",
      utc_offset: "+01:00",
    },
    {
      abbreviation: "+03",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Etc/GMT-3",
      utc_offset: "+03:00",
    },
    {
      abbreviation: "+04",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Etc/GMT-4",
      utc_offset: "+04:00",
    },
    {
      abbreviation: "EET",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Europe/Athens",
      utc_offset: "+02:00",
    },
    {
      abbreviation: "GMT",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Europe/London",
      utc_offset: "+00:00",
    },
    {
      abbreviation: "+03",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Europe/Istanbul",
      utc_offset: "+03:00",
    },
    {
      abbreviation: "EET",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Europe/Kaliningrad",
      utc_offset: "+02:00",
    },
    {
      abbreviation: "+11",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Pacific/Guadalcanal",
      utc_offset: "+11:00",
    },
    {
      abbreviation: "+11",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Pacific/Efate",
      utc_offset: "+11:00",
    },
    {
      abbreviation: "CET",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Europe/Madrid",
      utc_offset: "+01:00",
    },
    {
      abbreviation: "-09",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Pacific/Gambier",
      utc_offset: "-09:00",
    },
    {
      abbreviation: "-05",
      dst: true,
      dst_from: "2021-09-05T04:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2022-04-03T03:00:00+00:00",
      timezone: "Pacific/Easter",
      utc_offset: "-05:00",
    },
    {
      abbreviation: "EET",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Europe/Uzhgorod",
      utc_offset: "+02:00",
    },
    {
      abbreviation: "EET",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Europe/Tallinn",
      utc_offset: "+02:00",
    },
    {
      abbreviation: "CET",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Europe/Luxembourg",
      utc_offset: "+01:00",
    },
    {
      abbreviation: "CET",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Europe/Malta",
      utc_offset: "+01:00",
    },
    {
      abbreviation: "CDT",
      dst: true,
      dst_from: "2021-03-14T08:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2021-11-07T07:00:00+00:00",
      timezone: "America/Resolute",
      utc_offset: "-05:00",
    },
    {
      abbreviation: "CST",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Swift_Current",
      utc_offset: "-06:00",
    },
    {
      abbreviation: "CST",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Tegucigalpa",
      utc_offset: "-06:00",
    },
    {
      abbreviation: "EDT",
      dst: true,
      dst_from: "2021-03-14T07:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2021-11-07T06:00:00+00:00",
      timezone: "America/Thunder_Bay",
      utc_offset: "-04:00",
    },
    {
      abbreviation: "-05",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Rio_Branco",
      utc_offset: "-05:00",
    },
    {
      abbreviation: "-03",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Santarem",
      utc_offset: "-03:00",
    },
    {
      abbreviation: "+00",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Antarctica/Troll",
      utc_offset: "+00:00",
    },
    {
      abbreviation: "+03",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Baghdad",
      utc_offset: "+03:00",
    },
    {
      abbreviation: "+06",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Antarctica/Vostok",
      utc_offset: "+06:00",
    },
    {
      abbreviation: "ADT",
      dst: true,
      dst_from: "2021-03-14T06:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2021-11-07T05:00:00+00:00",
      timezone: "America/Thule",
      utc_offset: "-03:00",
    },
    {
      abbreviation: "PDT",
      dst: true,
      dst_from: "2021-03-14T10:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2021-11-07T09:00:00+00:00",
      timezone: "America/Tijuana",
      utc_offset: "-07:00",
    },
    {
      abbreviation: "+05",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Aqtobe",
      utc_offset: "+05:00",
    },
    {
      abbreviation: "HKT",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Hong_Kong",
      utc_offset: "+08:00",
    },
    {
      abbreviation: "EET",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Famagusta",
      utc_offset: "+02:00",
    },
    {
      abbreviation: "+07",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Barnaul",
      utc_offset: "+07:00",
    },
    {
      abbreviation: "+04",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Dubai",
      utc_offset: "+04:00",
    },
    {
      abbreviation: "+08",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Brunei",
      utc_offset: "+08:00",
    },
    {
      abbreviation: "+05",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Dushanbe",
      utc_offset: "+05:00",
    },
    {
      abbreviation: "+07",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Hovd",
      utc_offset: "+07:00",
    },
    {
      abbreviation: "WITA",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Makassar",
      utc_offset: "+08:00",
    },
    {
      abbreviation: "+08",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Irkutsk",
      utc_offset: "+08:00",
    },
    {
      abbreviation: "+05",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Qyzylorda",
      utc_offset: "+05:00",
    },
    {
      abbreviation: "+07",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Novosibirsk",
      utc_offset: "+07:00",
    },
    {
      abbreviation: "EET",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Nicosia",
      utc_offset: "+02:00",
    },
    {
      abbreviation: "WIB",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Pontianak",
      utc_offset: "+07:00",
    },
    {
      abbreviation: "+06",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Omsk",
      utc_offset: "+06:00",
    },
    {
      abbreviation: "PST",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Manila",
      utc_offset: "+08:00",
    },
    {
      abbreviation: "KST",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Pyongyang",
      utc_offset: "+09:00",
    },
    {
      abbreviation: "+06",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Qostanay",
      utc_offset: "+06:00",
    },
    {
      abbreviation: "+10",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Vladivostok",
      utc_offset: "+10:00",
    },
    {
      abbreviation: "+11",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Sakhalin",
      utc_offset: "+11:00",
    },
    {
      abbreviation: "+0330",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Tehran",
      utc_offset: "+03:30",
    },
    {
      abbreviation: "+03",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Qatar",
      utc_offset: "+03:00",
    },
    {
      abbreviation: "GMT",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Atlantic/Reykjavik",
      utc_offset: "+00:00",
    },
    {
      abbreviation: "+06",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Thimphu",
      utc_offset: "+06:00",
    },
    {
      abbreviation: "+08",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Ulaanbaatar",
      utc_offset: "+08:00",
    },
    {
      abbreviation: "-02",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Atlantic/South_Georgia",
      utc_offset: "-02:00",
    },
    {
      abbreviation: "+07",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Tomsk",
      utc_offset: "+07:00",
    },
    {
      abbreviation: "+10",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Ust-Nera",
      utc_offset: "+10:00",
    },
    {
      abbreviation: "ACDT",
      dst: true,
      dst_from: "2021-10-02T16:30:00+00:00",
      dst_offset: 3600,
      dst_until: "2022-04-02T16:30:00+00:00",
      timezone: "Australia/Adelaide",
      utc_offset: "+10:30",
    },
    {
      abbreviation: "+06",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Urumqi",
      utc_offset: "+06:00",
    },
    {
      abbreviation: "+03",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Riyadh",
      utc_offset: "+03:00",
    },
    {
      abbreviation: "+0845",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Australia/Eucla",
      utc_offset: "+08:45",
    },
    {
      abbreviation: "AEDT",
      dst: true,
      dst_from: "2021-10-02T16:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2022-04-02T16:00:00+00:00",
      timezone: "Australia/Hobart",
      utc_offset: "+11:00",
    },
    {
      abbreviation: "AWST",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Australia/Perth",
      utc_offset: "+08:00",
    },
    {
      abbreviation: "-03",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Atlantic/Stanley",
      utc_offset: "-03:00",
    },
    {
      abbreviation: "AEDT",
      dst: true,
      dst_from: "2021-10-02T16:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2022-04-02T16:00:00+00:00",
      timezone: "Australia/Sydney",
      utc_offset: "+11:00",
    },
    {
      abbreviation: "ACST",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Australia/Darwin",
      utc_offset: "+09:30",
    },
    {
      abbreviation: "EDT",
      dst: true,
      dst_from: "2021-03-14T07:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2021-11-07T06:00:00+00:00",
      timezone: "EST5EDT",
      utc_offset: "-04:00",
    },
    {
      abbreviation: "ACDT",
      dst: true,
      dst_from: "2021-10-02T16:30:00+00:00",
      dst_offset: 3600,
      dst_until: "2022-04-02T16:30:00+00:00",
      timezone: "Australia/Broken_Hill",
      utc_offset: "+10:30",
    },
    {
      abbreviation: "CDT",
      dst: true,
      dst_from: "2021-03-14T08:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2021-11-07T07:00:00+00:00",
      timezone: "CST6CDT",
      utc_offset: "-05:00",
    },
    {
      abbreviation: "EST",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "EST",
      utc_offset: "-05:00",
    },
    {
      abbreviation: "EET",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "EET",
      utc_offset: "+02:00",
    },
    {
      abbreviation: "CET",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Europe/Andorra",
      utc_offset: "+01:00",
    },
    {
      abbreviation: "GMT",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Etc/GMT",
      utc_offset: "+00:00",
    },
    {
      abbreviation: "CET",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Europe/Brussels",
      utc_offset: "+01:00",
    },
    {
      abbreviation: "CET",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Europe/Copenhagen",
      utc_offset: "+01:00",
    },
    {
      abbreviation: "CET",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Europe/Budapest",
      utc_offset: "+01:00",
    },
    {
      abbreviation: "-03",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Punta_Arenas",
      utc_offset: "-03:00",
    },
    {
      abbreviation: "EET",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Europe/Bucharest",
      utc_offset: "+02:00",
    },
    {
      abbreviation: "+14",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Pacific/Kiritimati",
      utc_offset: "+14:00",
    },
    {
      abbreviation: "CET",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Europe/Monaco",
      utc_offset: "+01:00",
    },
    {
      abbreviation: "-0930",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Pacific/Marquesas",
      utc_offset: "-09:30",
    },
    {
      abbreviation: "AST",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Puerto_Rico",
      utc_offset: "-04:00",
    },
    {
      abbreviation: "CET",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Europe/Oslo",
      utc_offset: "+01:00",
    },
    {
      abbreviation: "CDT",
      dst: true,
      dst_from: "2021-03-14T08:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2021-11-07T07:00:00+00:00",
      timezone: "America/Rainy_River",
      utc_offset: "-05:00",
    },
    {
      abbreviation: "GMT",
      dst: false,
      dst_from: "2021-10-31T01:00:00+00:00",
      dst_offset: -3600,
      dst_until: "2022-03-27T01:00:00+00:00",
      timezone: "Europe/Dublin",
      utc_offset: "+00:00",
    },
    {
      abbreviation: "CST",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Regina",
      utc_offset: "-06:00",
    },
    {
      abbreviation: "MSK",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Europe/Simferopol",
      utc_offset: "+03:00",
    },
    {
      abbreviation: "CET",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Europe/Paris",
      utc_offset: "+01:00",
    },
    {
      abbreviation: "+03",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Europe/Minsk",
      utc_offset: "+03:00",
    },
    {
      abbreviation: "SST",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Pacific/Pago_Pago",
      utc_offset: "-11:00",
    },
    {
      abbreviation: "+05",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Yekaterinburg",
      utc_offset: "+05:00",
    },
    {
      abbreviation: "MSK",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Europe/Moscow",
      utc_offset: "+03:00",
    },
    {
      abbreviation: "+12",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Pacific/Nauru",
      utc_offset: "+12:00",
    },
    {
      abbreviation: "CET",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Europe/Prague",
      utc_offset: "+01:00",
    },
    {
      abbreviation: "-03",
      dst: true,
      dst_from: "2021-09-05T04:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2022-04-03T03:00:00+00:00",
      timezone: "America/Santiago",
      utc_offset: "-03:00",
    },
    {
      abbreviation: "+0630",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Yangon",
      utc_offset: "+06:30",
    },
    {
      abbreviation: "CDT",
      dst: true,
      dst_from: "2021-03-14T08:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2021-11-07T07:00:00+00:00",
      timezone: "America/Rankin_Inlet",
      utc_offset: "-05:00",
    },
    {
      abbreviation: "-01",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Atlantic/Azores",
      utc_offset: "-01:00",
    },
    {
      abbreviation: "-11",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Pacific/Niue",
      utc_offset: "-11:00",
    },
    {
      abbreviation: "EDT",
      dst: true,
      dst_from: "2021-03-14T07:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2021-11-07T06:00:00+00:00",
      timezone: "America/Toronto",
      utc_offset: "-04:00",
    },
    {
      abbreviation: "-03",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Recife",
      utc_offset: "-03:00",
    },
    {
      abbreviation: "AEST",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Australia/Lindeman",
      utc_offset: "+10:00",
    },
    {
      abbreviation: "CET",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "CET",
      utc_offset: "+01:00",
    },
    {
      abbreviation: "+11",
      dst: true,
      dst_from: "2021-10-02T15:30:00+00:00",
      dst_offset: 1800,
      dst_until: "2022-04-02T15:00:00+00:00",
      timezone: "Australia/Lord_Howe",
      utc_offset: "+11:00",
    },
    {
      abbreviation: "-11",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Etc/GMT+11",
      utc_offset: "-11:00",
    },
    {
      abbreviation: "-12",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Etc/GMT+12",
      utc_offset: "-12:00",
    },
    {
      abbreviation: "+09",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Yakutsk",
      utc_offset: "+09:00",
    },
    {
      abbreviation: "-01",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Etc/GMT+1",
      utc_offset: "-01:00",
    },
    {
      abbreviation: "+04",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Yerevan",
      utc_offset: "+04:00",
    },
    {
      abbreviation: "-10",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Etc/GMT+10",
      utc_offset: "-10:00",
    },
    {
      abbreviation: "+04",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Europe/Ulyanovsk",
      utc_offset: "+04:00",
    },
    {
      abbreviation: "+0630",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Indian/Cocos",
      utc_offset: "+06:30",
    },
    {
      abbreviation: "+07",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Indian/Christmas",
      utc_offset: "+07:00",
    },
    {
      abbreviation: "+07",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Ho_Chi_Minh",
      utc_offset: "+07:00",
    },
    {
      abbreviation: "+04",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Indian/Mauritius",
      utc_offset: "+04:00",
    },
    {
      abbreviation: "+05",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Indian/Kerguelen",
      utc_offset: "+05:00",
    },
    {
      abbreviation: "+05",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Indian/Maldives",
      utc_offset: "+05:00",
    },
    {
      abbreviation: "+04",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Indian/Reunion",
      utc_offset: "+04:00",
    },
    {
      abbreviation: "+11",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Magadan",
      utc_offset: "+11:00",
    },
    {
      abbreviation: "KST",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Seoul",
      utc_offset: "+09:00",
    },
    {
      abbreviation: "+08",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Singapore",
      utc_offset: "+08:00",
    },
    {
      abbreviation: "+04",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Indian/Mahe",
      utc_offset: "+04:00",
    },
    {
      abbreviation: "CST",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Shanghai",
      utc_offset: "+08:00",
    },
    {
      abbreviation: "EET",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Hebron",
      utc_offset: "+02:00",
    },
    {
      abbreviation: "-01",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Atlantic/Cape_Verde",
      utc_offset: "-01:00",
    },
    {
      abbreviation: "WET",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Atlantic/Faroe",
      utc_offset: "+00:00",
    },
    {
      abbreviation: "ADT",
      dst: true,
      dst_from: "2021-03-14T06:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2021-11-07T05:00:00+00:00",
      timezone: "Atlantic/Bermuda",
      utc_offset: "-03:00",
    },
    {
      abbreviation: "+04",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Tbilisi",
      utc_offset: "+04:00",
    },
    {
      abbreviation: "WET",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Atlantic/Canary",
      utc_offset: "+00:00",
    },
    {
      abbreviation: "JST",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Tokyo",
      utc_offset: "+09:00",
    },
    {
      abbreviation: "+11",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Pacific/Bougainville",
      utc_offset: "+11:00",
    },
    {
      abbreviation: "PDT",
      dst: true,
      dst_from: "2021-03-14T10:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2021-11-07T09:00:00+00:00",
      timezone: "PST8PDT",
      utc_offset: "-07:00",
    },
    {
      abbreviation: "MDT",
      dst: true,
      dst_from: "2021-03-14T09:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2021-11-07T08:00:00+00:00",
      timezone: "MST7MDT",
      utc_offset: "-06:00",
    },
    {
      abbreviation: "NZDT",
      dst: true,
      dst_from: "2021-09-25T14:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2022-04-02T14:00:00+00:00",
      timezone: "Pacific/Auckland",
      utc_offset: "+13:00",
    },
    {
      abbreviation: "+1345",
      dst: true,
      dst_from: "2021-09-25T14:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2022-04-02T14:00:00+00:00",
      timezone: "Pacific/Chatham",
      utc_offset: "+13:45",
    },
    {
      abbreviation: "+14",
      dst: true,
      dst_from: "2021-09-25T14:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2022-04-02T14:00:00+00:00",
      timezone: "Pacific/Apia",
      utc_offset: "+14:00",
    },
    {
      abbreviation: "CDT",
      dst: true,
      dst_from: "2021-03-14T08:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2021-11-07T07:00:00+00:00",
      timezone: "America/Winnipeg",
      utc_offset: "-05:00",
    },
    {
      abbreviation: "AKDT",
      dst: true,
      dst_from: "2021-03-14T11:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2021-11-07T10:00:00+00:00",
      timezone: "America/Yakutat",
      utc_offset: "-08:00",
    },
    {
      abbreviation: "+07",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Novokuznetsk",
      utc_offset: "+07:00",
    },
    {
      abbreviation: "+05",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Samarkand",
      utc_offset: "+05:00",
    },
    {
      abbreviation: "+11",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Srednekolymsk",
      utc_offset: "+11:00",
    },
    {
      abbreviation: "AEST",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Australia/Brisbane",
      utc_offset: "+10:00",
    },
    {
      abbreviation: "AEDT",
      dst: true,
      dst_from: "2021-10-02T16:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2022-04-02T16:00:00+00:00",
      timezone: "Australia/Melbourne",
      utc_offset: "+11:00",
    },
    {
      abbreviation: "+03",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Antarctica/Syowa",
      utc_offset: "+03:00",
    },
    {
      abbreviation: "EET",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Gaza",
      utc_offset: "+02:00",
    },
    {
      abbreviation: "EET",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Europe/Chisinau",
      utc_offset: "+02:00",
    },
    {
      abbreviation: "+05",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Oral",
      utc_offset: "+05:00",
    },
    {
      abbreviation: "CST",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Taipei",
      utc_offset: "+08:00",
    },
    {
      abbreviation: "-03",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Paramaribo",
      utc_offset: "-03:00",
    },
    {
      abbreviation: "MST",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Whitehorse",
      utc_offset: "-07:00",
    },
    {
      abbreviation: "+05",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Etc/GMT-5",
      utc_offset: "+05:00",
    },
    {
      abbreviation: "WET",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Atlantic/Madeira",
      utc_offset: "+00:00",
    },
    {
      abbreviation: "EDT",
      dst: true,
      dst_from: "2021-03-14T07:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2021-11-07T06:00:00+00:00",
      timezone: "America/Pangnirtung",
      utc_offset: "-04:00",
    },
    {
      abbreviation: "+02",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Etc/GMT-2",
      utc_offset: "+02:00",
    },
    {
      abbreviation: "-06",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Etc/GMT+6",
      utc_offset: "-06:00",
    },
    {
      abbreviation: "-09",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Etc/GMT+9",
      utc_offset: "-09:00",
    },
    {
      abbreviation: "+09",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Dili",
      utc_offset: "+09:00",
    },
    {
      abbreviation: "+11",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Etc/GMT-11",
      utc_offset: "+11:00",
    },
    {
      abbreviation: "HST",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Pacific/Honolulu",
      utc_offset: "-10:00",
    },
    {
      abbreviation: "-02",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Etc/GMT+2",
      utc_offset: "-02:00",
    },
    {
      abbreviation: "+05",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Asia/Tashkent",
      utc_offset: "+05:00",
    },
    {
      abbreviation: "MET",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "MET",
      utc_offset: "+01:00",
    },
    {
      abbreviation: "EET",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Europe/Sofia",
      utc_offset: "+02:00",
    },
    {
      abbreviation: "EET",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Europe/Kiev",
      utc_offset: "+02:00",
    },
    {
      abbreviation: "MST",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Phoenix",
      utc_offset: "-07:00",
    },
    {
      abbreviation: "CET",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Europe/Stockholm",
      utc_offset: "+01:00",
    },
    {
      abbreviation: "+12",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Pacific/Tarawa",
      utc_offset: "+12:00",
    },
    {
      abbreviation: "MDT",
      dst: true,
      dst_from: "2021-03-14T09:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2021-11-07T08:00:00+00:00",
      timezone: "America/Ojinaga",
      utc_offset: "-06:00",
    },
    {
      abbreviation: "EST",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Panama",
      utc_offset: "-05:00",
    },
    {
      abbreviation: "+12",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Pacific/Fiji",
      utc_offset: "+12:00",
    },
    {
      abbreviation: "MST",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "MST",
      utc_offset: "-07:00",
    },
    {
      abbreviation: "ChST",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "Pacific/Guam",
      utc_offset: "+10:00",
    },
    {
      abbreviation: "AST",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Port_of_Spain",
      utc_offset: "-04:00",
    },
    {
      abbreviation: "-03",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Nuuk",
      utc_offset: "-03:00",
    },
    {
      abbreviation: "EDT",
      dst: true,
      dst_from: "2021-03-14T07:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2021-11-07T06:00:00+00:00",
      timezone: "America/Port-au-Prince",
      utc_offset: "-04:00",
    },
    {
      abbreviation: "PDT",
      dst: true,
      dst_from: "2021-03-14T10:00:00+00:00",
      dst_offset: 3600,
      dst_until: "2021-11-07T09:00:00+00:00",
      timezone: "America/Vancouver",
      utc_offset: "-07:00",
    },
    {
      abbreviation: "-04",
      dst: false,
      dst_from: null,
      dst_offset: 0,
      dst_until: null,
      timezone: "America/Porto_Velho",
      utc_offset: "-04:00",
    },
  ].sort((a, b) => {
    return (
      parseFloat(a.utc_offset.replace(":", ".")) -
      parseFloat(b.utc_offset.replace(":", "."))
    );
  });

  static mappedTimezoneList: TimezoneListItem[] = Constants.timezone.map(
    (item: TimezoneList) => {
      return {
        value: item.timezone,
        abbr: item.abbreviation,
        offset: item.utc_offset,
        label: `${item.timezone.replace(/_/g, " ")} (UTC ${item.utc_offset}) `,
        val: item.timezone,
      };
    },
  );

  static spokenLanguages: SpokenLanguages[] = Object.entries({
    ab: {
      name: "Abkhaz",
      nativeName: "аҧсуа",
    },
    aa: {
      name: "Afar",
      nativeName: "Afaraf",
    },
    af: {
      name: "Afrikaans",
      nativeName: "Afrikaans",
    },
    ak: {
      name: "Akan",
      nativeName: "Akan",
    },
    sq: {
      name: "Albanian",
      nativeName: "Shqip",
    },
    am: {
      name: "Amharic",
      nativeName: "አማርኛ",
    },
    ar: {
      name: "Arabic",
      nativeName: "العربية",
    },
    an: {
      name: "Aragonese",
      nativeName: "Aragonés",
    },
    hy: {
      name: "Armenian",
      nativeName: "Հայերեն",
    },
    as: {
      name: "Assamese",
      nativeName: "অসমীয়া",
    },
    av: {
      name: "Avaric",
      nativeName: "авар мацӀ, магӀарул мацӀ",
    },
    ae: {
      name: "Avestan",
      nativeName: "avesta",
    },
    ay: {
      name: "Aymara",
      nativeName: "aymar aru",
    },
    az: {
      name: "Azerbaijani",
      nativeName: "azərbaycan dili",
    },
    bm: {
      name: "Bambara",
      nativeName: "bamanankan",
    },
    ba: {
      name: "Bashkir",
      nativeName: "башҡорт теле",
    },
    eu: {
      name: "Basque",
      nativeName: "euskara, euskera",
    },
    be: {
      name: "Belarusian",
      nativeName: "Беларуская",
    },
    bn: {
      name: "Bengali",
      nativeName: "বাংলা",
    },
    bh: {
      name: "Bihari",
      nativeName: "भोजपुरी",
    },
    bi: {
      name: "Bislama",
      nativeName: "Bislama",
    },
    bs: {
      name: "Bosnian",
      nativeName: "bosanski jezik",
    },
    br: {
      name: "Breton",
      nativeName: "brezhoneg",
    },
    bg: {
      name: "Bulgarian",
      nativeName: "български език",
    },
    my: {
      name: "Burmese",
      nativeName: "ဗမာစာ",
    },
    ca: {
      name: "Catalan; Valencian",
      nativeName: "Català",
    },
    ch: {
      name: "Chamorro",
      nativeName: "Chamoru",
    },
    ce: {
      name: "Chechen",
      nativeName: "нохчийн мотт",
    },
    ny: {
      name: "Chichewa; Chewa; Nyanja",
      nativeName: "chiCheŵa, chinyanja",
    },
    zh: {
      name: "Chinese",
      nativeName: "中文 (Zhōngwén), 汉语, 漢語",
    },
    cv: {
      name: "Chuvash",
      nativeName: "чӑваш чӗлхи",
    },
    kw: {
      name: "Cornish",
      nativeName: "Kernewek",
    },
    co: {
      name: "Corsican",
      nativeName: "corsu, lingua corsa",
    },
    cr: {
      name: "Cree",
      nativeName: "ᓀᐦᐃᔭᐍᐏᐣ",
    },
    hr: {
      name: "Croatian",
      nativeName: "hrvatski",
    },
    cs: {
      name: "Czech",
      nativeName: "česky, čeština",
    },
    da: {
      name: "Danish",
      nativeName: "dansk",
    },
    dv: {
      name: "Divehi; Dhivehi; Maldivian;",
      nativeName: "ދިވެހި",
    },
    nl: {
      name: "Dutch",
      nativeName: "Nederlands, Vlaams",
    },
    en: {
      name: "English",
      nativeName: "English",
    },
    eo: {
      name: "Esperanto",
      nativeName: "Esperanto",
    },
    et: {
      name: "Estonian",
      nativeName: "eesti, eesti keel",
    },
    ee: {
      name: "Ewe",
      nativeName: "Eʋegbe",
    },
    fo: {
      name: "Faroese",
      nativeName: "føroyskt",
    },
    fj: {
      name: "Fijian",
      nativeName: "vosa Vakaviti",
    },
    fi: {
      name: "Finnish",
      nativeName: "suomi, suomen kieli",
    },
    fr: {
      name: "French",
      nativeName: "français, langue française",
    },
    ff: {
      name: "Fula; Fulah; Pulaar; Pular",
      nativeName: "Fulfulde, Pulaar, Pular",
    },
    gl: {
      name: "Galician",
      nativeName: "Galego",
    },
    ka: {
      name: "Georgian",
      nativeName: "ქართული",
    },
    de: {
      name: "German",
      nativeName: "Deutsch",
    },
    el: {
      name: "Greek, Modern",
      nativeName: "Ελληνικά",
    },
    gn: {
      name: "Guaraní",
      nativeName: "Avañeẽ",
    },
    gu: {
      name: "Gujarati",
      nativeName: "ગુજરાતી",
    },
    ht: {
      name: "Haitian; Haitian Creole",
      nativeName: "Kreyòl ayisyen",
    },
    ha: {
      name: "Hausa",
      nativeName: "Hausa, هَوُسَ",
    },
    he: {
      name: "Hebrew (modern)",
      nativeName: "עברית",
    },
    hz: {
      name: "Herero",
      nativeName: "Otjiherero",
    },
    hi: {
      name: "Hindi",
      nativeName: "हिन्दी, हिंदी",
    },
    ho: {
      name: "Hiri Motu",
      nativeName: "Hiri Motu",
    },
    hu: {
      name: "Hungarian",
      nativeName: "Magyar",
    },
    ia: {
      name: "Interlingua",
      nativeName: "Interlingua",
    },
    id: {
      name: "Indonesian",
      nativeName: "Bahasa Indonesia",
    },
    ie: {
      name: "Interlingue",
      nativeName: "Originally called Occidental; then Interlingue after WWII",
    },
    ga: {
      name: "Irish",
      nativeName: "Gaeilge",
    },
    ig: {
      name: "Igbo",
      nativeName: "Asụsụ Igbo",
    },
    ik: {
      name: "Inupiaq",
      nativeName: "Iñupiaq, Iñupiatun",
    },
    io: {
      name: "Ido",
      nativeName: "Ido",
    },
    is: {
      name: "Icelandic",
      nativeName: "Íslenska",
    },
    it: {
      name: "Italian",
      nativeName: "Italiano",
    },
    iu: {
      name: "Inuktitut",
      nativeName: "ᐃᓄᒃᑎᑐᑦ",
    },
    ja: {
      name: "Japanese",
      nativeName: "日本語 (にほんご／にっぽんご)",
    },
    jv: {
      name: "Javanese",
      nativeName: "basa Jawa",
    },
    kl: {
      name: "Kalaallisut, Greenlandic",
      nativeName: "kalaallisut, kalaallit oqaasii",
    },
    kn: {
      name: "Kannada",
      nativeName: "ಕನ್ನಡ",
    },
    kr: {
      name: "Kanuri",
      nativeName: "Kanuri",
    },
    ks: {
      name: "Kashmiri",
      nativeName: "कश्मीरी, كشميري‎",
    },
    kk: {
      name: "Kazakh",
      nativeName: "Қазақ тілі",
    },
    km: {
      name: "Khmer",
      nativeName: "ភាសាខ្មែរ",
    },
    ki: {
      name: "Kikuyu, Gikuyu",
      nativeName: "Gĩkũyũ",
    },
    rw: {
      name: "Kinyarwanda",
      nativeName: "Ikinyarwanda",
    },
    ky: {
      name: "Kirghiz, Kyrgyz",
      nativeName: "кыргыз тили",
    },
    kv: {
      name: "Komi",
      nativeName: "коми кыв",
    },
    kg: {
      name: "Kongo",
      nativeName: "KiKongo",
    },
    ko: {
      name: "Korean",
      nativeName: "한국어 (韓國語), 조선말 (朝鮮語)",
    },
    ku: {
      name: "Kurdish",
      nativeName: "Kurdî, كوردی‎",
    },
    kj: {
      name: "Kwanyama, Kuanyama",
      nativeName: "Kuanyama",
    },
    la: {
      name: "Latin",
      nativeName: "latine, lingua latina",
    },
    lb: {
      name: "Luxembourgish, Letzeburgesch",
      nativeName: "Lëtzebuergesch",
    },
    lg: {
      name: "Luganda",
      nativeName: "Luganda",
    },
    li: {
      name: "Limburgish, Limburgan, Limburger",
      nativeName: "Limburgs",
    },
    ln: {
      name: "Lingala",
      nativeName: "Lingála",
    },
    lo: {
      name: "Lao",
      nativeName: "ພາສາລາວ",
    },
    lt: {
      name: "Lithuanian",
      nativeName: "lietuvių kalba",
    },
    lu: {
      name: "Luba-Katanga",
      nativeName: "",
    },
    lv: {
      name: "Latvian",
      nativeName: "latviešu valoda",
    },
    gv: {
      name: "Manx",
      nativeName: "Gaelg, Gailck",
    },
    mk: {
      name: "Macedonian",
      nativeName: "македонски јазик",
    },
    mg: {
      name: "Malagasy",
      nativeName: "Malagasy fiteny",
    },
    ms: {
      name: "Malay",
      nativeName: "bahasa Melayu, بهاس ملايو‎",
    },
    ml: {
      name: "Malayalam",
      nativeName: "മലയാളം",
    },
    mt: {
      name: "Maltese",
      nativeName: "Malti",
    },
    mi: {
      name: "Māori",
      nativeName: "te reo Māori",
    },
    mr: {
      name: "Marathi (Marāṭhī)",
      nativeName: "मराठी",
    },
    mh: {
      name: "Marshallese",
      nativeName: "Kajin M̧ajeļ",
    },
    mn: {
      name: "Mongolian",
      nativeName: "монгол",
    },
    na: {
      name: "Nauru",
      nativeName: "Ekakairũ Naoero",
    },
    nv: {
      name: "Navajo, Navaho",
      nativeName: "Diné bizaad, Dinékʼehǰí",
    },
    nb: {
      name: "Norwegian Bokmål",
      nativeName: "Norsk bokmål",
    },
    nd: {
      name: "North Ndebele",
      nativeName: "isiNdebele",
    },
    ne: {
      name: "Nepali",
      nativeName: "नेपाली",
    },
    ng: {
      name: "Ndonga",
      nativeName: "Owambo",
    },
    nn: {
      name: "Norwegian Nynorsk",
      nativeName: "Norsk nynorsk",
    },
    no: {
      name: "Norwegian",
      nativeName: "Norsk",
    },
    ii: {
      name: "Nuosu",
      nativeName: "ꆈꌠ꒿ Nuosuhxop",
    },
    nr: {
      name: "South Ndebele",
      nativeName: "isiNdebele",
    },
    oc: {
      name: "Occitan",
      nativeName: "Occitan",
    },
    oj: {
      name: "Ojibwe, Ojibwa",
      nativeName: "ᐊᓂᔑᓈᐯᒧᐎᓐ",
    },
    cu: {
      name: "Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic",
      nativeName: "ѩзыкъ словѣньскъ",
    },
    om: {
      name: "Oromo",
      nativeName: "Afaan Oromoo",
    },
    or: {
      name: "Oriya",
      nativeName: "ଓଡ଼ିଆ",
    },
    os: {
      name: "Ossetian, Ossetic",
      nativeName: "ирон æвзаг",
    },
    pa: {
      name: "Panjabi, Punjabi",
      nativeName: "ਪੰਜਾਬੀ, پنجابی‎",
    },
    pi: {
      name: "Pāli",
      nativeName: "पाऴि",
    },
    fa: {
      name: "Persian",
      nativeName: "فارسی",
    },
    pl: {
      name: "Polish",
      nativeName: "polski",
    },
    ps: {
      name: "Pashto, Pushto",
      nativeName: "پښتو",
    },
    pt: {
      name: "Portuguese",
      nativeName: "Português",
    },
    qu: {
      name: "Quechua",
      nativeName: "Runa Simi, Kichwa",
    },
    rm: {
      name: "Romansh",
      nativeName: "rumantsch grischun",
    },
    rn: {
      name: "Kirundi",
      nativeName: "kiRundi",
    },
    ro: {
      name: "Romanian, Moldavian, Moldovan",
      nativeName: "română",
    },
    ru: {
      name: "Russian",
      nativeName: "русский язык",
    },
    sa: {
      name: "Sanskrit (Saṁskṛta)",
      nativeName: "संस्कृतम्",
    },
    sc: {
      name: "Sardinian",
      nativeName: "sardu",
    },
    sd: {
      name: "Sindhi",
      nativeName: "सिन्धी, سنڌي، سندھی‎",
    },
    se: {
      name: "Northern Sami",
      nativeName: "Davvisámegiella",
    },
    sm: {
      name: "Samoan",
      nativeName: "gagana faa Samoa",
    },
    sg: {
      name: "Sango",
      nativeName: "yângâ tî sängö",
    },
    sr: {
      name: "Serbian",
      nativeName: "српски језик",
    },
    gd: {
      name: "Scottish Gaelic; Gaelic",
      nativeName: "Gàidhlig",
    },
    sn: {
      name: "Shona",
      nativeName: "chiShona",
    },
    si: {
      name: "Sinhala, Sinhalese",
      nativeName: "සිංහල",
    },
    sk: {
      name: "Slovak",
      nativeName: "slovenčina",
    },
    sl: {
      name: "Slovene",
      nativeName: "slovenščina",
    },
    so: {
      name: "Somali",
      nativeName: "Soomaaliga, af Soomaali",
    },
    st: {
      name: "Southern Sotho",
      nativeName: "Sesotho",
    },
    es: {
      name: "Spanish; Castilian",
      nativeName: "español, castellano",
    },
    su: {
      name: "Sundanese",
      nativeName: "Basa Sunda",
    },
    sw: {
      name: "Swahili",
      nativeName: "Kiswahili",
    },
    ss: {
      name: "Swati",
      nativeName: "SiSwati",
    },
    sv: {
      name: "Swedish",
      nativeName: "svenska",
    },
    ta: {
      name: "Tamil",
      nativeName: "தமிழ்",
    },
    te: {
      name: "Telugu",
      nativeName: "తెలుగు",
    },
    tg: {
      name: "Tajik",
      nativeName: "тоҷикӣ, toğikī, تاجیکی‎",
    },
    th: {
      name: "Thai",
      nativeName: "ไทย",
    },
    ti: {
      name: "Tigrinya",
      nativeName: "ትግርኛ",
    },
    bo: {
      name: "Tibetan Standard, Tibetan, Central",
      nativeName: "བོད་ཡིག",
    },
    tk: {
      name: "Turkmen",
      nativeName: "Türkmen, Түркмен",
    },
    tl: {
      name: "Tagalog",
      nativeName: "Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔",
    },
    tn: {
      name: "Tswana",
      nativeName: "Setswana",
    },
    to: {
      name: "Tonga (Tonga Islands)",
      nativeName: "faka Tonga",
    },
    tr: {
      name: "Turkish",
      nativeName: "Türkçe",
    },
    ts: {
      name: "Tsonga",
      nativeName: "Xitsonga",
    },
    tt: {
      name: "Tatar",
      nativeName: "татарча, tatarça, تاتارچا‎",
    },
    tw: {
      name: "Twi",
      nativeName: "Twi",
    },
    ty: {
      name: "Tahitian",
      nativeName: "Reo Tahiti",
    },
    ug: {
      name: "Uighur, Uyghur",
      nativeName: "Uyƣurqə, ئۇيغۇرچە‎",
    },
    uk: {
      name: "Ukrainian",
      nativeName: "українська",
    },
    ur: {
      name: "Urdu",
      nativeName: "اردو",
    },
    uz: {
      name: "Uzbek",
      nativeName: "zbek, Ўзбек, أۇزبېك‎",
    },
    ve: {
      name: "Venda",
      nativeName: "Tshivenḓa",
    },
    vi: {
      name: "Vietnamese",
      nativeName: "Tiếng Việt",
    },
    vo: {
      name: "Volapük",
      nativeName: "Volapük",
    },
    wa: {
      name: "Walloon",
      nativeName: "Walon",
    },
    cy: {
      name: "Welsh",
      nativeName: "Cymraeg",
    },
    wo: {
      name: "Wolof",
      nativeName: "Wollof",
    },
    fy: {
      name: "Western Frisian",
      nativeName: "Frysk",
    },
    xh: {
      name: "Xhosa",
      nativeName: "isiXhosa",
    },
    yi: {
      name: "Yiddish",
      nativeName: "ייִדיש",
    },
    yo: {
      name: "Yoruba",
      nativeName: "Yorùbá",
    },
    za: {
      name: "Zhuang, Chuang",
      nativeName: "Saɯ cueŋƅ, Saw cuengh",
    },
  }).map(([key, value]) => ({ key: key, language_name: value.name }));
}
