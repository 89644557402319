import { inject, observer } from "mobx-react";
import React from "react";
import { Redirect, Route } from "react-router";
import NoAuthorized from "../components/no-authorized/NoAuthorized";
import Loader from "../components/shared/loader/Loader";
import { LoginState } from "../enums/login-state.enum";
import { Role } from "../enums/role.enum";
import { Routes } from "../routes";
import { RouteUtil } from "../utils/route-utils";
import { UserPlans } from "../enums/userPlan.enum";
import { useLocation } from "react-router-dom";
import { SettingType } from "../enums/SettingTypeEnum.enum";
import MaintenancePage from "../components/MaintainancePage/MaintainancePage";

const BotProtectedRoute: React.FC<any> = ({
  component: Component,
  ...rest
}) => {
  const location = useLocation();

  switch (rest.store.user.loginState) {
    case LoginState.LOGGED_IN: {
      switch (rest.store.isBootstrapped) {
        case true:
          // Maintenance Page check
          if (
            rest.store.user.loggedInProfile.role !== Role.ADMIN &&
            rest.store!.settingsStore.settingsData &&
            (rest.store!.settingsStore.settingsData[
              SettingType.MAINTENANCE_PAGE
            ].value === "true" ||
              rest.store!.settingsStore.settingsData[SettingType.BOT_DOWN_PAGE]
                .value === "true")
          ) {
            return (
              <Route
                {...rest}
                render={(props) => (
                  <MaintenancePage
                    isBotDown={
                      rest.store!.settingsStore.settingsData[
                        SettingType.BOT_DOWN_PAGE
                      ].value === "true"
                    }
                  />
                )}
              />
            );
          }
          if (rest.store.user.loggedInProfile.role === Role.ADMIN) {
            return <Route {...rest} render={(props) => <NoAuthorized />} />;
          }
          switch (rest.store.user.loggedInProfile.plan) {
            case UserPlans.LITE:
              return (
                <Route
                  {...rest}
                  render={(props) =>
                    location.pathname === "/aqlite" ? (
                      <Component {...props} />
                    ) : (
                      <Redirect
                        to={{
                          pathname: RouteUtil.getUrl(Routes.aqLiteChatBot),
                          state: { from: props.location },
                        }}
                      />
                    )
                  }
                />
              );
            case UserPlans.PREMIUM:
            default:
              return (
                <Route
                  {...rest}
                  render={(props) =>
                    location.pathname === "/aqme" ? (
                      <Component {...props} />
                    ) : (
                      <Redirect
                        to={{
                          pathname: RouteUtil.getUrl(Routes.aqMeChatBot),
                          state: { from: props.location },
                        }}
                      />
                    )
                  }
                />
              );
          }
        case false:
        default:
          return <Route {...rest} render={() => <Loader />} />;
      }
    }

    case LoginState.LOGGED_OUT:
      return (
        <Route
          {...rest}
          render={(props) => (
            <Redirect
              to={{
                pathname: RouteUtil.getUrl(Routes.login),
                state: { from: props.location },
              }}
            />
          )}
        />
      );

    case LoginState.PENDING:
    default:
      return <Route {...rest} render={() => <Loader />} />;
  }
};

export default inject("store")(observer(BotProtectedRoute));
