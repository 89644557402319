import { Languages } from "./languages.enum";

export enum Gender {
  FEMALE = "female",
  MALE = "male",
  TRANSGENDER = "transgender",
  NON_BINARY = "non_binary",
  PREFER_NOT_TO_SAY = "prefer_not_to_say",
}

export class GenderEnumUtils {
  private static getGenderTitleInEnglish(gender: Gender): string {
    switch (gender) {
      case Gender.MALE: {
        return "Male";
      }
      case Gender.FEMALE: {
        return "Female";
      }
      case Gender.TRANSGENDER: {
        return "Transgender";
      }
      case Gender.NON_BINARY: {
        return "Non binary";
      }
      case Gender.PREFER_NOT_TO_SAY: {
        return "Prefer not to say";
      }
    }

    return "";
  }
  private static getGenderTitleInVitenamese(gender: Gender): string {
    switch (gender) {
      case Gender.MALE: {
        return "Nam";
      }
      case Gender.FEMALE: {
        return "Nữ";
      }
      case Gender.TRANSGENDER: {
        return "Chuyển giới";
      }
      case Gender.NON_BINARY: {
        return "Phi nhị nguyên giới";
      }
      case Gender.PREFER_NOT_TO_SAY: {
        return "Không muốn tiết lộ";
      }
    }

    return "";
  }

  private static getGenderTitleInSpanish(gender: Gender): string {
    switch (gender) {
      case Gender.MALE: {
        return "Masculino";
      }
      case Gender.FEMALE: {
        return "Femenino";
      }
      case Gender.TRANSGENDER: {
        return "Transgénero";
      }
      case Gender.NON_BINARY: {
        return "No binario";
      }
      case Gender.PREFER_NOT_TO_SAY: {
        return "Prefiero no decirlo";
      }
    }

    return "";
  }

  private static getGenderTitleInFrench(gender: Gender): string {
    switch (gender) {
      case Gender.MALE: {
        return "Homme";
      }
      case Gender.FEMALE: {
        return "Femme";
      }
      case Gender.TRANSGENDER: {
        return "Transgenre";
      }
      case Gender.NON_BINARY: {
        return "Non binaire";
      }
      case Gender.PREFER_NOT_TO_SAY: {
        return "Préfère ne pas répondre";
      }
    }

    return "";
  }

  private static getGenderTitleInItalian(gender: Gender): string {
    switch (gender) {
      case Gender.MALE: {
        return "Maschio";
      }
      case Gender.FEMALE: {
        return "Femmina";
      }
      case Gender.TRANSGENDER: {
        return "Transgender";
      }
      case Gender.NON_BINARY: {
        return "Non binario";
      }
      case Gender.PREFER_NOT_TO_SAY: {
        return "Preferisco non dire";
      }
    }

    return "";
  }

  private static getGenderTitleInTurkish(gender: Gender): string {
    switch (gender) {
      case Gender.MALE: {
        return "Erkek";
      }
      case Gender.FEMALE: {
        return "Kadın";
      }
      case Gender.TRANSGENDER: {
        return "Transseksüel";
      }
      case Gender.NON_BINARY: {
        return "Non binary (İkili olmayan)";
      }
      case Gender.PREFER_NOT_TO_SAY: {
        return "Söylemeyi tercih etmiyorum";
      }
    }

    return "";
  }
  private static getGenderTitleInGerman(gender: Gender): string {
    switch (gender) {
      case Gender.MALE: {
        return "Männlich";
      }
      case Gender.FEMALE: {
        return "Weiblich";
      }
      case Gender.TRANSGENDER: {
        return "Transgender";
      }
      case Gender.NON_BINARY: {
        return "Nicht binär";
      }
      case Gender.PREFER_NOT_TO_SAY: {
        return "Das möchte ich nicht sagen";
      }
    }

    return "";
  }

  static getGenderTitle(gender: Gender, locale?: Languages): string {
    switch (locale) {
      case Languages.ENGLISH:
        return this.getGenderTitleInEnglish(gender);
      case Languages.SPANISH:
        return this.getGenderTitleInSpanish(gender);
      case Languages.FRENCH:
        return this.getGenderTitleInFrench(gender);
      case Languages.ITALIAN:
        return this.getGenderTitleInItalian(gender);
      case Languages.TURKISH:
        return this.getGenderTitleInTurkish(gender);
      case Languages.GERMAN:
        return this.getGenderTitleInGerman(gender);
      case Languages.VIETNAMESE:
        return this.getGenderTitleInVitenamese(gender);
      default:
        return this.getGenderTitleInEnglish(gender);
    }
  }
}
