import { ListResponse } from "../responses/list.response";
import { ShowResponse } from "../responses/show.response";
import { apiService } from "./ApiService";
import { TasksFormData, TasksModel } from "../models/TasksModel";
import { MilestoneFormData, MilestoneModel } from "../models/MilestoneModel";
import {
  PromotionalImagesFormData,
  PromotionalImagesModel,
} from "../models/PromotionalImagesModel";

class MilestonesService {
  static getInstance(): MilestonesService {
    return new MilestonesService();
  }

  getAll(): Promise<MilestoneModel[]> {
    return apiService
      .get<ListResponse<MilestoneModel>>("/milestones/admin", true, false)
      .then((response) => response.data);
  }

  updateBulk(data: Array<MilestoneModel>): Promise<MilestoneModel[]> {
    return apiService
      .put<ListResponse<MilestoneModel>>(
        `/milestones/bulk-update`,
        true,
        false,
        data,
      )
      .then((res) => res.data);
  }

  delete(id: number): Promise<any> {
    return apiService.delete("/milestones/" + id, true, false);
  }

  add(data: MilestoneFormData): Promise<MilestoneFormData> {
    return apiService
      .post<ShowResponse<MilestoneFormData>>("/milestones", true, false, data)
      .then((response) => response.data);
  }

  update(id: number, data: MilestoneFormData): Promise<MilestoneModel> {
    return apiService
      .put<ShowResponse<MilestoneModel>>(`/milestones/${id}`, true, false, data)
      .then((res) => res.data);
  }
}

export const milestonesService = MilestonesService.getInstance();
