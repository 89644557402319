import { action, computed, observable, runInAction } from "mobx";
import * as _ from "lodash";
import { Invite } from "../models/InviteModel";
import { RootStore } from "./RootStore";
import { inviteService } from "../services/Invite.service";
import { InviteType } from "../enums/invite-type.enum";

export class InviteStore {
  private static _instance: InviteStore;

  @observable entities: Invite[] = [];
  @observable isLoading = false;
  @observable isLoaded = false;
  @observable private _filter: { [key: string]: any } = {};

  @computed
  get filters() {
    return this._filter;
  }

  @action
  setFilters(filters: { [key: string]: any }) {
    if (!_.isEqual(this._filter, filters)) {
      this._filter = { ...this._filter, ...filters };
      this.fetchInvites(true);
    }
  }

  static getInstance(): InviteStore {
    if (!this._instance) {
      this._instance = new InviteStore();
    }

    return this._instance;
  }

  @action
  fetchInvites(useMutationToken: boolean) {
    this.isLoading = true;
    return inviteService
      .fetchInvites(this._filter, useMutationToken)
      .then((invites: Invite[]) => {
        runInAction(() => {
          this.entities = invites;
          this.isLoading = false;
          this.isLoaded = true;
        });
      })
      .catch(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
  }

  inviteChampion(data: {
    org_id: string;
    first_name: string;
    last_name: string;
    email: string;
  }) {
    return inviteService.inviteChampion(data.org_id, data);
  }

  inviteUser(
    data: {
      team_id: string;
      first_name: string;
      last_name: string;
      email: string;
      custom_placeholders?: { organization_name: string };
    },
    useMutatedToken: boolean,
  ) {
    return inviteService.inviteUser(data.team_id, data, useMutatedToken);
  }

  bulkInviteUser(
    data: {
      first_name: string;
      last_name: string;
      email: string;
      organization_id: number;
      team_id: number;
      custom_placeholders: any;
      notificationEnabled: boolean;
    }[],
    includeCC: boolean,
  ) {
    this.isLoading = true;
    return inviteService
      .bulkInviteUser(data, includeCC)
      .then((invites: Invite[]) => {
        runInAction(() => {
          this.entities = [...invites, ...this.entities];
          this.isLoading = false;
          invites.forEach(
            (invite) =>
              invite.organization &&
              invite.team &&
              RootStore.getInstance().organization.updatePendingCount(
                invite.organization.id,
                invite.team.id,
              ),
          );
        });
      })
      .catch((e) => {
        runInAction(() => {
          this.isLoading = false;
        });

        throw e;
      });
  }

  resendInvite(id: number, useMutatedToken: boolean) {
    return inviteService.resendInvite(id, useMutatedToken);
  }

  cancelInvite(
    id: number,
    useMutatedToken: boolean,
    isAdminOrCdpCorporate?: boolean,
  ) {
    return inviteService.cancelInvite(id, useMutatedToken).then(() => {
      runInAction(() => {
        const inviteObj = this.entities.filter((e) => e.id === id)[0];
        if (!isAdminOrCdpCorporate) {
          RootStore.getInstance().organization.updatePendingCount(
            inviteObj.organization!.id,
            inviteObj.team!.id,
            true,
          );
        }
        this.entities = this.entities.filter((e) => e.id !== id);
      });
    });
  }

  invite(
    data: {
      first_name: string;
      last_name: string;
      email: string;
      invite_type: InviteType;
    },
    useMutatedToken: boolean,
  ) {
    return inviteService.invite(data, useMutatedToken);
  }
}
