import { ListResponse } from "../responses/list.response";
import { apiService } from "./ApiService";
import { AqCoachReportModal } from "../models/AqCoachReportModal";

class AqCoachReportService {
  static getInstance(): AqCoachReportService {
    return new AqCoachReportService();
  }

  async fetchAqCoachReports(filters: {
    [key: string]: any;
  }): Promise<AqCoachReportModal[]> {
    return apiService
      .get<ListResponse<AqCoachReportModal>>(
        "/users/aq-coach",
        true,
        true,
        filters,
      )
      .then((response) => response.data);
  }
}

export const aqCoachReportService = AqCoachReportService.getInstance();
