import React, { CSSProperties, FunctionComponent } from "react";
import Transition from "../Transition";
import classNames from "classnames";
import useOutsideClickDetector from "../../../Hooks/useOutsideClickDetector";
import useEscapeKeyDetector from "../../../Hooks/useEscapeKeyDetector";

export interface ModalProps {
  open: boolean;
  modalStyle?: CSSProperties;
  modalClassName?: {
    shadow?: string;
    borderRadius?: string;
    backgroundColor?: string;
  };
}

interface Props extends ModalProps {
  onOutsideClick: VoidFunction;
  className?: string;
}

const Modal: FunctionComponent<Props> = ({
  open,
  onOutsideClick,
  children,
  modalStyle,
  className,
  modalClassName,
}) => {
  const wrapperRef = useOutsideClickDetector<HTMLDivElement>(onOutsideClick);
  const wrap = useEscapeKeyDetector<HTMLDivElement>(onOutsideClick);

  return (
    <div
      className={classNames(
        "fixed bottom-0 inset-x-0 px-4 pb-4 inset-0  flex items-center justify-center " +
          className,
        // { '-z-999': !open },
      )}
      style={{ zIndex: open ? 1002 : -999 }}
    >
      <Transition
        show={open}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-black opacity-50" />
        </div>
      </Transition>

      <Transition
        show={open}
        enter="ease-out duration-300"
        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        enterTo="opacity-100 translate-y-0 sm:scale-100"
        leave="ease-in duration-100"
        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      >
        <div
          ref={wrapperRef || wrap}
          style={modalStyle}
          className={classNames(
            "transform transition-all sm:w-full overflow-hidden",
            modalClassName && modalClassName.shadow
              ? modalClassName.shadow
              : "shadow-brandShadow4",
            modalClassName && modalClassName.borderRadius
              ? modalClassName.shadow
              : "rounded-25px",
            modalClassName && modalClassName.backgroundColor
              ? modalClassName.backgroundColor
              : "bg-white",
            modalClassName,
          )}
          role="dialog"
          aria-modal={open}
          aria-labelledby="modal-headline"
          id={"modalScrollDiv"}
        >
          {children}
        </div>
      </Transition>
    </div>
  );
};

export default Modal;
