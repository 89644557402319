import { observable } from "mobx";
import { AqScoreEnum } from "../enums/aq-score.enum";
import { Role } from "../enums/role.enum";
import { Visibility } from "../enums/visibility.enum";
import { UserStore } from "../stores/UserStore";
import Model from "./Model";
import { Organization } from "./OrganizationModel";
import { Coupon } from "./CouponModel";
import { Gender } from "../enums/gender.enum";
import { Age } from "../enums/age.enum";
import { JobFunction } from "../enums/job-function.enum";
import { JobLevel } from "../enums/job-level.enum";
import { WorkTenure } from "../enums/work-tenure.enum";
import { Survey } from "./Survey";
import { UserPlans } from "../enums/userPlan.enum";
import { UpgradeInvite } from "./AqliteUpgradeInviteModel";
import { IndustryType } from "../enums/industry-type.enum";
import { DimensionLanguages } from "../enums/languages.enum";

export class User extends Model {
  static _store: UserStore;

  @observable id!: string;
  @observable first_name!: string;
  @observable example!: string;
  @observable last_name!: string;
  @observable email!: string;
  @observable profile_pic!: string;
  @observable bio!: Role;
  @observable website_url!: string;
  @observable phone!: string;
  @observable city!: Role;
  @observable timezone!: Role;
  @observable languages_spoken!: string[];
  @observable coaching_consulting_specialism!: string;
  @observable product_types!: string[];
  @observable cost_of_products!: string[];
  @observable role!: Role;
  @observable partner_level!: number;
  @observable is_active!: boolean;
  @observable visibility!: Visibility;
  @observable landbot_token!: string;
  @observable sseToken!: string;
  @observable selected_organization!: Organization;
  @observable organizations!: Organization[];
  @observable surveys!: Survey[];
  @observable gender!: Gender;
  @observable age!: Age;
  @observable country_id!: number;
  @observable job_function!: JobFunction;
  @observable other_job_function!: string;
  @observable industry_type!: IndustryType;
  @observable industry_served!: IndustryType[];
  @observable other_industry_type!: string;
  @observable job_level!: JobLevel;
  @observable other_job_level!: string;
  @observable work_tenure!: WorkTenure;
  @observable isSurveyCompleted!: boolean;
  @observable is_survey_skipped!: boolean;
  @observable badge!: { url: string; description: string };
  @observable teams!: { id: number; title: string; allow_retake: boolean }[];
  @observable aqScore!: {
    text: AqScoreEnum;
    score: number;
    status?: String;
    completed_on?: string;
  };
  @observable createdAt!: Date;
  @observable survey!: any;
  @observable customPlaceholder?: { organization_name: string };
  @observable hasIncompleteSurveys!: boolean;
  @observable restart_survey!: boolean;
  @observable plan!: UserPlans;
  @observable aqliteToken!: string;
  @observable upgradeInvite?: UpgradeInvite;
  @observable hide_overlay!: boolean;
  @observable coachOnlyReportUrl!: {
    [name in DimensionLanguages]: string;
  };
  @observable is_primary_contact!: boolean;
  @observable dimensionWiseScore!: any;
  @observable dimensionWiseScoreForTeamReport?: any;
  coupon!: Coupon;
  completedPayment!: boolean;
  @observable can_access_own_aqme_report!: boolean;
  @observable can_access_others_aqme_report!: boolean;
  @observable can_access_adv_reports!: boolean;
  @observable can_access_team_report!: boolean;
  @observable can_purchase_assessment_credits!: boolean;
  @observable can_export_scores!: boolean;
  @observable completedSurveysCount!: number;
  @observable preferred_language!: DimensionLanguages;
  @observable isPasswordSet!: boolean;
  @observable latestAqScore!: {
    text: AqScoreEnum;
    score: number;
    status?: String;
    completed_on?: string;
  };

  getId(): string | number {
    return this.id;
  }

  get hasRemainingSurveys() {
    return (
      this.selected_organization &&
      this.selected_organization.remainingSurveyCount
    );
  }
}

export interface AdminUserUpdateModel {
  role?: Role;
  partner_level?: number;
  is_primary_contact?: boolean;
  can_access_own_aqme_report?: boolean;
  can_access_others_aqme_report?: boolean;
  can_access_adv_reports?: boolean;
  can_access_team_report?: boolean;
  can_purchase_assessment_credits?: boolean;
  can_export_scores?: boolean;
}
