import { Languages } from "../enums/languages.enum";

export class RegisterPageLanguagesContent {
  static RegisterStep1Content = {
    [Languages.ENGLISH]: {
      first_name: "First Name",
      first_name_placeholder: "Enter your first name",
      last_name_placeholder: "Enter your last name",
      email_name_placeholder: "Enter your email address",
      verification_code_placeholder: "Enter verification code",
      last_name: "Last Name",
      email: "Email address",
      otp: "Verification Code",
      password: "Password",
      confirm_password: "Confirm Password",
      password_placeholder: "Enter your password",
      confirm_password_placeholder: "Re-enter your password",
      nextButton: "Next",
      verifyEmailButton: "Verify Email",
      firstNameError: "First name is required",
      lastNameError: "Last name is required",
      otpError: "Verification code is Required",
      emailErrors: ["Email is required", "Invalid Email"],
      passwordErrors: [
        "Password is required",
        "At least 8 characters",
        "At least one capital letter",
        "At least two numbers",
        "At least one special character",
      ],
      confirmPasswordErrors: [
        "Confirm Password is required",
        "Passwords do not match",
      ],
      emailInfoText: "Verify your email before Next Step.",
      otpInfoText:
        "Enter the verification code received on your email after clicking the verify email button.",
      passwordInfoText: "8+ characters, 1 capital, 2 numbers, 1 special.",
      emptyEmailError: "Enter a Email First",
      wrongEmailFormat: "Enter a Valid Email",
      unknownError:
        "Some error occurred. Please refresh the page and try again",
      backButton: "Back",
    },
    [Languages.VIETNAMESE]: {
      first_name: "Tên",
      first_name_placeholder: "Nhập tên của bạn",
      last_name_placeholder: "Nhập họ và tên đệm của bạn",
      email_name_placeholder: "Nhập địa chỉ email của bạn",
      verification_code_placeholder: "Nhập mã xác nhận",
      last_name: "Họ và tên đệm",
      email: "Email",
      otp: "Mã xác nhận",
      password: "Mật khẩu",
      confirm_password: "Xác nhận mật khẩu",
      password_placeholder: "Nhập mật khẩu",
      confirm_password_placeholder: "Nhập lại mật khẩu",
      nextButton: "Tiếp theo",
      verifyEmailButton: "Xác nhận Email",
      firstNameError: "Tên là trường bắt buộc",
      lastNameError: "Họ và tên đệm là trường bắt buộc",
      otpError: "Mã xác nhận là trường bắt buộc",
      emailErrors: ["Email là trường bắt buộc", "Email không hợp lệ"],
      passwordErrors: [
        "Mật khẩu là trường bắt buộc",
        "Tối thiểu 8 ký tự",
        "Tối thiểu một chữ cái in hoa",
        "Tối thiểu hai chữ số",
        "Tối thiểu một ký tự đặc biệt",
      ],
      confirmPasswordErrors: [
        "Xác nhận mật khẩu là trường bắt buộc",
        "Mật khẩu không khớp",
      ],
      emailInfoText: "Xác minh email trước khi thực hiện bước tiếp theo.",
      otpInfoText: "Nhập mã xác minh đã được gửi qua email.",
      passwordInfoText:
        "8 ký tự trở lên, 1 chữ cái in hoa, 2 chữ số, 1 ký tự đặc biệt.",
      emptyEmailError: "Nhập email trước",
      wrongEmailFormat: "Nhập email hợp lệ",
      unknownError: "Đã có lỗi xảy ra. Hãy tải lại trang và thử lại",
      backButton: "Quay lại",
    },
    [Languages.TURKISH]: {
      first_name: "İlk adı",
      first_name_placeholder: "İlk adınızı girin",
      last_name_placeholder: "Soyadınızı giriniz",
      email_name_placeholder: "E-posta adresinizi giriniz",
      verification_code_placeholder: "dogrulama kodunu giriniz",
      last_name: "Soyadı",
      email: "E-posta adresi",
      otp: "Doğrulama kodu",
      password: "Parola",
      confirm_password: "Şifreyi Onayla",
      password_placeholder: "Şifrenizi girin",
      confirm_password_placeholder: "sifrenizi tekrar giriniz",
      nextButton: "Sonraki",
      verifyEmailButton: "E-postayı doğrula",
      firstNameError: "Ad gerekli",
      lastNameError: "Soyadı gerekli",
      otpError: "Doğrulama Kodu gerekli",
      emailErrors: ["Email gerekli", "Geçersiz E-posta"],
      passwordErrors: [
        "Şifre gerekli",
        "En az 8 karakter",
        "En az bir büyük harf",
        "En az iki sayı",
        "En az bir özel karakter",
      ],
      confirmPasswordErrors: ["Şifre onayı gerekli", "Şifreler uyuşmuyor"],
      emailInfoText: "Sonraki adımdan önce e-postanızı doğrulayın.",
      otpInfoText:
        "E-postayı doğrula düğmesine tıkladıktan sonra e-postanıza gelen doğrulama kodunu girin.",
      passwordInfoText: "8+ karakter, 1 büyük harf, 2 sayı, 1 özel.",
      emptyEmailError: "Önce bir E-posta girin",
      wrongEmailFormat: "Geçerli bir e-posta girin",
      unknownError:
        "Bazı hatalar oluştu. Lütfen sayfayı yenileyin ve tekrar deneyin",
      backButton: "Geri",
    },
    [Languages.SPANISH]: {
      first_name: "Primer nombre",
      first_name_placeholder: "Ponga su primer nombre",
      last_name_placeholder: "Ingresa tu apellido",
      email_name_placeholder: "Ingrese su dirección de correo electrónico",
      verification_code_placeholder: "Introduce el código de verificación",
      last_name: "Apellido",
      email: "Dirección de correo electrónico",
      otp: "Código de verificación",
      password: "Contraseña",
      confirm_password: "confirmar Contraseña",
      password_placeholder: "Ingresa tu contraseña",
      confirm_password_placeholder: "reingresa tu contraseña",
      nextButton: "Siguiente",
      verifyEmailButton: "Verificar correo electrónico",
      firstNameError: "Se requiere el primer nombre",
      lastNameError: "Se requiere apellido",
      emailErrors: ["correo electronico es requerido", "Email inválido"],
      otpError: "Se necesita un código de verificación",
      passwordErrors: [
        "se requiere contraseña",
        "Al menos 8 carácteres",
        "Al menos una mayúscula",
        "Al menos dos números",
        "Al menos un personaje especial",
      ],
      confirmPasswordErrors: [
        "Se requiere confirmación de contraseña",
        "Las contraseñas no coinciden",
      ],
      emailInfoText: "Vérifiez votre e-mail avant la prochaine étape.",
      otpInfoText:
        "Ingrese el código de verificación recibido en su correo electrónico después de hacer clic en el botón de verificación de correo electrónico.",
      passwordInfoText: "8+ caracteres, 1 mayúscula, 2 números, 1 especial.",
      emptyEmailError: "Ingrese un correo electrónico primero",
      wrongEmailFormat: "Introduzca un correo electrónico válido",
      unknownError:
        "Se produjo algún error. Actualiza la página e inténtalo de nuevo.",
      backButton: "atrás",
    },
    [Languages.FRENCH]: {
      first_name: "Prénom",
      first_name_placeholder: "Entrez votre prénom",
      last_name_placeholder: "Entrez votre nom de famille",
      email_name_placeholder: "Entrez votre adresse email",
      verification_code_placeholder: "Entrez le code de vérification",
      last_name: "Nom",
      email: "Adresse e-mail",
      otp: "Code de vérification",
      password: "Mot de passe",
      confirm_password: "Confirmez le mot de passe",
      password_placeholder: "Tapez votre mot de passe",
      confirm_password_placeholder: "Entrez à nouveau votre mot de passe",
      nextButton: "Suivant",
      verifyEmailButton: "Vérifier les courriels",
      firstNameError: "Le prénom est requis",
      lastNameError: "Le nom de famille est requis",
      emailErrors: ["L'e-mail est requis", "Email invalide"],
      otpError: "Le code de vérification est requis",
      passwordErrors: [
        "Mot de passe requis",
        "Au moins 8 caractères",
        "Au moins une lettre majuscule",
        "Au moins deux nombres",
        "Au moins un caractère spécial",
      ],
      confirmPasswordErrors: [
        "Confirmer le mot de passe est requis",
        "Les mots de passe ne correspondent pas",
      ],
      emailInfoText: "Vérifiez votre e-mail avant la prochaine étape.",
      otpInfoText:
        "Entrez le code de vérification reçu sur votre e-mail après avoir cliqué sur le bouton vérifier l'e-mail.",
      passwordInfoText:
        "8 caractères min., 1 majuscule, 2 chiffres, 1 spécial.",
      emptyEmailError: "Saisissez d'abord un e-mail",
      wrongEmailFormat: "Entrer une adresse email valide",
      unknownError:
        "Une erreur s'est produite. Veuillez actualiser la page et réessayer",
      backButton: "Retour",
    },
    [Languages.ITALIAN]: {
      first_name: "Nome",
      first_name_placeholder: "Inserisci il tuo nome di battesimo",
      last_name_placeholder: "Inserire il cognome",
      email_name_placeholder: "Inserisci il tuo indirizzo email",
      verification_code_placeholder: "Inserisci il codice di verifica",
      last_name: "Cognome",
      email: "Indirizzo email",
      otp: "Codice di verifica",
      password: "Parola d'ordine",
      confirm_password: "Conferma password",
      password_placeholder: "Inserisci la tua password",
      confirm_password_placeholder: "Reinserisci la tua password",
      nextButton: "Prossimo",
      verifyEmailButton: "Verifica Email",
      firstNameError: "Il nome è obbligatorio",
      lastNameError: "Il cognome è obbligatorio",
      emailErrors: ["L'e-mail è obbligatoria", "E-mail non valido"],
      otpError: "Il codice di verifica è obbligatorio",
      passwordErrors: [
        "E 'richiesta la password",
        "Almeno 8 caratteri",
        "Almeno una lettera maiuscola",
        "Almeno due numeri",
        "Almeno un carattere speciale",
      ],
      confirmPasswordErrors: [
        "Conferma password è richiesta",
        "le passwords non corrispondono",
      ],
      emailInfoText: "Verifica la tua email prima del passaggio successivo.",
      otpInfoText:
        "Inserisci il codice di verifica ricevuto sulla tua e-mail dopo aver fatto clic sul pulsante di verifica e-mail.",
      passwordInfoText:
        "8+ caratteri, 1 maiuscola, 2 numeri, 1 carattere speciale.",
      emptyEmailError: "Inserisci prima un'e-mail",
      wrongEmailFormat: "Inserisci un'e-mail valida",
      unknownError:
        "Si è verificato un errore. perfavore ricarica la pagina e riprova",
      backButton: "Indietro",
    },
    [Languages.GERMAN]: {
      first_name: "Vorname",
      first_name_placeholder: "Bitte gib deinen Vornamen ein",
      last_name_placeholder: "Bitte gib deinen Nachnamen ein",
      email_name_placeholder: "Gib deine E-Mail-Adresse ein",
      verification_code_placeholder: "Gib den Verifizierungscode ein",
      last_name: "Nachname",
      email: "E-Mail Adresse",
      otp: "Verifizierungs-Code",
      password: "Kennwort",
      confirm_password: "Bestätige das Passwort",
      password_placeholder: "Geben Sie Ihr Passwort ein",
      confirm_password_placeholder: "Wiederhole die Eingabe deines Passwortes",
      nextButton: "Nächste",
      verifyEmailButton: "E-Mail verifizieren",
      firstNameError: "Vorname ist erforderlich",
      lastNameError: "Nachname ist erforderlich",
      otpError: "Verifizierungscode ist erforderlich",
      emailErrors: ["Email ist erforderlich", "Ungültige E-Mail"],
      passwordErrors: [
        "Passwort ist erforderlich",
        "Mindestens 8 Zeichen",
        "Mindestens ein Großbuchstabe",
        "Mindestens zwei Zahlen",
        "Mindestens ein Sonderzeichen",
      ],
      confirmPasswordErrors: [
        "Passwortbestätigung ist erforderlich",
        "Passwörter stimmen nicht überein",
      ],
      emailInfoText: "Bitte überprüfe Deine E-Mail vor dem nächsten Schritt",
      otpInfoText:
        'Gib den Verifizierungscode ein, den du in deiner E-Mail erhalten hast, nachdem du auf die Schaltfläche "E-Mail verifizieren" geklickt hast.',
      passwordInfoText:
        "8+ Zeichen, 1 Großbuchstabe, 2 Zahlen, 1 Sonderzeichen.",
      emptyEmailError: "Bitte zuerst eine E-Mail eingeben",
      wrongEmailFormat: "Bitte gib eine gültige E-Mail ein",
      unknownError:
        "Es ist ein Fehler aufgetreten. Bitte aktualisiere die Seite und versuche es erneut.",
      backButton: "Zurück",
    },
  };

  static RegisterStep2Content = {
    [Languages.ENGLISH]: {
      gender: "Gender",
      age: "Age",
      country: "Country",
      genderError: "Gender is required",
      ageError: "Age is required",
      countryError: "Country is required",
      backButton: "Back",
      nextButton: "Next",
    },
    [Languages.VIETNAMESE]: {
      nextButton: "Tiếp theo",
      gender: "Giới tính",
      age: "Tuổi",
      country: "Quốc gia",
      genderError: "Giới tính là trường bắt buộc",
      ageError: "Tuổi là trường bắt buộc",
      countryError: "Quốc gia là trường bắt buộc",
      backButton: "Quay lại",
    },
    [Languages.SPANISH]: {
      gender: "Género",
      age: "Edad",
      country: "País",
      genderError: "Se requiere género",
      ageError: "Se requiere edad",
      countryError: "El país es obligatorio",
      backButton: "atrás",
      nextButton: "Siguiente",
    },
    [Languages.FRENCH]: {
      gender: "Sexe",
      age: "Âge",
      country: "Pays",
      genderError: "Le sexe est requis",
      ageError: "L'âge est requis",
      countryError: "Le pays est requis",
      backButton: "Retour",
      nextButton: "Suivant",
    },
    [Languages.ITALIAN]: {
      gender: "Genere",
      age: "Età",
      country: "Nazione",
      genderError: "Il sesso è obbligatorio",
      ageError: "L'età è richiesta",
      countryError: "Il paese è obbligatorio",
      backButton: "Indietro",
      nextButton: "Prossimo",
    },
    [Languages.TURKISH]: {
      gender: "Cinsiyet",
      age: "Yaş",
      country: "Ülke",
      genderError: "Cinsiyet gerekli",
      ageError: "Yaş gerekli",
      countryError: "Ülke gerekli",
      backButton: "Geri",
      nextButton: "Sonraki",
    },
    [Languages.GERMAN]: {
      gender: "Geschlecht",
      age: "Alter",
      country: "Land",
      genderError: "Geschlecht ist erforderlich",
      ageError: "Alter ist erforderlich",
      countryError: "Land ist erforderlich",
      backButton: "Zurück",
      nextButton: "Nächste",
    },
  };

  static RegisterStep3Content = {
    [Languages.ENGLISH]: {
      job_function: "Occupation",
      industry_type: "Industry Type",
      job_level: "Job Level",
      work_tenure: "Time at Current Company",
      backButton: "Back",
      finishButton: "Finish",
      termsText: [
        "By completing your registration you agree to AQai's",
        "terms and conditions",
      ],
      termsTextNew: ["Please read and agree to our", "Terms & Conditions"],
      jobFunctionError: "Occupation is required",
      industryTypeError: "Industry type is required",
      jobLevelError: "Job Level is required",
      workTenureError: "Time at current company is required",
      checkBoxError: "Please agree to terms and conditions",
    },
    [Languages.VIETNAMESE]: {
      backButton: "Quay lại",
      job_function: "Nghề nghiệp",
      industry_type: "Lĩnh vực",
      job_level: "Cấp chức danh",
      work_tenure: "Thời gian làm việc tại công ty hiện tại",
      finishButton: "Hoàn thành",
      termsText: [
        "Bằng cách hoàn thành đăng ký của bạn, bạn đồng ý với AQAI",
        "các điều khoản và điều kiện",
      ],
      termsTextNew: ["Hãy đọc và đồng ý với", " Điều khoản và điều kiện"],
      jobFunctionError: "Nghề nghiệp là trường bắt buộc",
      industryTypeError: "Lĩnh vực là trường bắt buộc",
      jobLevelError: "Cấp chức danh là trường bắt buộc",
      workTenureError:
        "Thời gian làm việc ở công ty hiện tại là trường bắt buộc",
      checkBoxError: "Vui lòng đồng ý với các điều khoản và điều kiện",
    },
    [Languages.TURKISH]: {
      job_function: "Meslek",
      industry_type: "Sanayi Tipi",
      job_level: "Meslek seviyesi",
      work_tenure: "Mevcut Şirkette Geçen Süre (yıl)",
      backButton: "Geri",
      finishButton: "Bitiş",
      termsText: [
        "Kaydınızı tamamlayarak AQai'nin hüküm ve koşullarını kabul etmiş olursunuz.",
      ],
      termsTextNew: [
        "Lütfen",
        "Şartlar ve Koşullarımızı okuyun ve kabul edin.",
      ],
      jobFunctionError: "İş Fonksiyonu gerekli",
      industryTypeError: "Endüstri türü gerekli",
      jobLevelError: "İş Seviyesi gerekli",
      workTenureError: "Mevcut şirketteki süre gerekli",
      checkBoxError: "Lütfen şartlar ve koşulları kabul edin",
    },
    [Languages.SPANISH]: {
      job_function: "Ocupación",
      job_level: "Nivel de trabajo",
      industry_type: "Tipo d'industria",
      work_tenure: "Tiempo en la empresa actual",
      backButton: "atrás",
      finishButton: "Finalizar",
      termsText: ["Acepto los ", "Términos y condiciones de AQai"],
      termsTextNew: [
        "Por favor lea y acepte nuestra",
        "Términos y condiciones",
      ],
      jobFunctionError: "Se requiere la función de trabajo",
      jobLevelError: "Se requiere el nivel de trabajo",
      industryTypeError: "Se requiere el tipo de industria",
      workTenureError: "Se requiere tiempo en la empresa actual",
      checkBoxError: "Acepta los términos y condiciones",
    },
    [Languages.FRENCH]: {
      job_function: "Profession",
      job_level: "Niveau du poste",
      industry_type: "type d'industrie",
      work_tenure: "Ancienneté dans l'entreprise actuelle",
      backButton: "Retour",
      finishButton: "Finir",
      termsText: [
        "En complétant votre inscription ",
        "vous acceptez les conditions générales d'AQai",
      ],
      termsTextNew: ["Veuillez lire et accepter notre", "termes et conditions"],
      jobFunctionError: "Les fonctions du poste sont requises",
      jobLevelError: "Le niveau d'emploi est requis",
      workTenureError: "Le temps passé dans l'entreprise actuelle est requis",
      industryTypeError: "Le type d'industrie est requis",
      checkBoxError: "Veuillez accepter les termes et conditions",
    },
    [Languages.ITALIAN]: {
      job_function: "Occupazione",
      job_level: "Livello di lavoro",
      industry_type: "Tipo d'industria",
      work_tenure: "Tenure nell'organizzazione attuale",
      backButton: "Indietro",
      finishButton: "Fine",
      termsText: [
        "Completando la tua",
        "registrazione accetti i termini e le condizioni di AQai",
      ],
      termsTextNew: [
        "Si prega di leggere e accettare il ns",
        "Termini & Condizioni",
      ],
      jobFunctionError: "Le funzioni lavorative sono obbligatorie",
      jobLevelError: "Il livello di lavoro è richiesto",
      industryTypeError: "È richiesto il tipo di settore",
      workTenureError: "È richiesto il tempo presso l'azienda attuale",
      checkBoxError: "Si prega di accettare i termini e le condizioni",
    },
    [Languages.GERMAN]: {
      job_function: "Beruf",
      industry_type: "Branche",
      job_level: "Berufliche Ebene",
      work_tenure: "Zeit im aktuellen Unternehmen",
      backButton: "Zurück",
      finishButton: "Abschluss",
      termsText: [
        "Mit dem Abschluss deiner Registrierung stimmst du AQai's",
        "Nutzungsbedingungen",
      ],
      termsTextNew: ["Bitte lies und akzeptiere unsere", "Nutzungsbedingungen"],
      jobFunctionError: "Beruf ist erforderlich",
      industryTypeError: "Branche ist erforderlich",
      jobLevelError: "Job Level ist erforderlich",
      workTenureError:
        "Betriebszugehörigkeit im derzeitigen Unternehmen ist erforderlich",
      checkBoxError: "Bitte stimme den Allgemeinen Geschäftsbedingungen zu",
    },
  };

  static RegisterStep4Content = {
    [Languages.ENGLISH]: {
      streetAddress: "Street Address",
      streetAddressPlaceholder: "Enter your street address",
      streetAddressError: "Street Address is required",
      townCity: "Town/City",
      townCityPlaceholder: "Enter your town/city",
      townCityError: "Town/City is required",
      region: "Region",
      regionPlaceholder: "Enter your region",
      regionError: "Region is required",
      postalCode: "Postal Code",
      postalCodePlaceholder: "Enter your postal code",
      postalCodeError: "Postal Code is required",
    },
    [Languages.VIETNAMESE]: {
      streetAddress: "Tên đường",
      streetAddressPlaceholder: "Nhập tên đường",
      streetAddressError: "Tên đường là trường bắt buộc",
      townCity: "Thị trấn/Thành phố",
      townCityPlaceholder: "Nhập Thị trấn/Thành phố",
      townCityError: "Thị trấn/Thành phố là trường bắt buộc",
      region: "Khu vực",
      regionPlaceholder: "Nhập khu vực",
      regionError: "Khu vực là trường bắt buộc",
      postalCode: "Mã bưu điện",
      postalCodePlaceholder: "Nhập mã bưu điện",
      postalCodeError: "Mã bưu điện là trường bắt buộc",
    },
    [Languages.SPANISH]: {
      streetAddress: "Dirección",
      streetAddressPlaceholder: "Ingrese su dirección de la calle",
      streetAddressError: "La dirección de la calle es obligatoria",
      townCity: "Pueblo/Ciudad",
      townCityPlaceholder: "Introduce tu pueblo/ciudad",
      townCityError: "Pueblo/Ciudad es requerido",
      region: "Región",
      regionPlaceholder: "Ingrese su región",
      regionError: "La región es obligatoria",
      postalCode: "Código postal",
      postalCodePlaceholder: "Introduce tu código postal",
      postalCodeError: "Se requiere código postal",
    },
    [Languages.FRENCH]: {
      streetAddress: "Adresse de la rue",
      streetAddressPlaceholder: "Entrez votre adresse municipale",
      streetAddressError: "L'adresse municipale est obligatoire",
      townCity: "Ville/Ville",
      townCityPlaceholder: "Entrez votre ville/ville",
      townCityError: "Ville/Ville est requis",
      region: "Région",
      regionPlaceholder: "Entrez votre région",
      regionError: "La région est requise",
      postalCode: "code postal",
      postalCodePlaceholder: "Entrez votre code postal",
      postalCodeError: "Le code postal est requis",
    },
    [Languages.ITALIAN]: {
      streetAddress: "Indirizzo",
      streetAddressPlaceholder: "Inserisci il tuo indirizzo",
      streetAddressError: "L'indirizzo è obbligatorio",
      townCity: "Città/Città",
      townCityPlaceholder: "Inserisci la tua città",
      townCityError: "Città/città è obbligatorio",
      region: "Regione",
      regionPlaceholder: "Inserisci la tua regione",
      regionError: "La regione è obbligatoria",
      postalCode: "Codice Postale",
      postalCodePlaceholder: "Inserisci il tuo codice postale",
      postalCodeError: "Il codice postale è obbligatorio",
    },
    [Languages.TURKISH]: {
      streetAddress: "Sokak adresi",
      streetAddressPlaceholder: "Sokak adresinizi girin",
      streetAddressError: "Sokak Adresi gerekli",
      townCity: "Kasaba/Şehir",
      townCityPlaceholder: "Şehrinizi/şehrinizi girin",
      townCityError: "Kasaba/Şehir gerekli",
      region: "Bölge",
      regionPlaceholder: "bölgenizi girin",
      regionError: "Bölge gerekli",
      postalCode: "Posta Kodu",
      postalCodePlaceholder: "Posta kodunuzu girin",
      postalCodeError: "Posta Kodu gerekli",
    },
    [Languages.GERMAN]: {
      streetAddress: "Straße und Hausnummer",
      streetAddressPlaceholder: "Bitte gib deine Straße und Hausnummer ein.",
      streetAddressError: "Straße und Hausnummer sind erforderlich.",
      townCity: "Stadt",
      townCityPlaceholder: "Bite gib deine Stadt ein.",
      townCityError: "Stadt ist erforderlich",
      region: "Region",
      regionPlaceholder: "Bitte gib deine Region ein",
      regionError: "Region ist erforderllich",
      postalCode: "Postleitzahl",
      postalCodePlaceholder: "Bitte gib deine Postleitzahl ein.",
      postalCodeError: "Postleitzahl ist erforderlich",
    },
  };

  static RegisterStep5Content = {
    [Languages.ENGLISH]: {
      whatYouWillBeCharged: "What you will be charged :",
      haveADiscountCode: "Have a discount code",
      Total: "Total",
      addCode: "add code",
      addDiscountCode: "Add your discount code",
    },
    [Languages.VIETNAMESE]: {
      whatYouWillBeCharged: "Chi phí bao gồm:",
      haveADiscountCode: "Mã giảm giá",
      Total: "Tổng",
      addCode: "Nhập mã",
      addDiscountCode: "Nhập mã giảm giá",
    },
    [Languages.SPANISH]: {
      whatYouWillBeCharged: "lo que se le cobrará :",
      haveADiscountCode: "Tener un código de descuento",
      Total: "Total",
      addCode: "añadir código",
      addDiscountCode: "Agrega tu código de descuento",
    },
    [Languages.FRENCH]: {
      whatYouWillBeCharged: "Ce qui vous sera facturé :",
      haveADiscountCode: "Avoir un code de réduction",
      Total: "Total",
      addCode: "ajouter du code",
      addDiscountCode: "Ajoutez votre code de réduction",
    },
    [Languages.ITALIAN]: {
      whatYouWillBeCharged: "Cosa ti verrà addebitato :",
      haveADiscountCode: "Avere un codice sconto",
      Total: "Totale",
      addCode: "aggiungi codice",
      addDiscountCode: "Aggiungi il tuo codice sconto",
    },
    [Languages.TURKISH]: {
      whatYouWillBeCharged: "Ne ücretlendirileceksiniz :",
      haveADiscountCode: "indirim kodu var",
      Total: "Toplam",
      addCode: "kod ekle",
      addDiscountCode: "İndirim kodunuzu ekleyin",
    },
    [Languages.GERMAN]: {
      whatYouWillBeCharged: "Dir wird folgendes berechnet:",
      haveADiscountCode: "Ich habe einen Rabatt-Code",
      Total: "Gesamt",
      addCode: "Bitte gib deinen Code ein",
      addDiscountCode: "Bitte gib deinen Rabatt-Code ein.",
    },
  };

  static RegisterCompletionStepContent = {
    [Languages.ENGLISH]: {
      explorePlatForm: "Now it’s time to explore our platform.",
      letsExplore: "Let’s Explore",
      continueAssessmentText: "Continue with the assessment",
      timeToTakeAssessmentText: "Now its time to take the assessment",
    },
    [Languages.VIETNAMESE]: {
      explorePlatForm: "Hãy cùng khám phá nền tảng AQ.",
      letsExplore: "Bắt đầu khám phá!",
      continueAssessmentText: "Tiếp tục đánh giá",
      timeToTakeAssessmentText: "Bắt đầu đánh giá",
    },
    [Languages.SPANISH]: {
      explorePlatForm: "Ahora es el momento de explorar nuestra plataforma.",
      letsExplore: "Vamos a explorar",
      continueAssessmentText: "Continuar con la evaluación",
      timeToTakeAssessmentText: "Ahora es el momento de tomar la evaluación.",
    },
    [Languages.FRENCH]: {
      explorePlatForm: "Il est maintenant temps d'explorer notre plateforme.",
      letsExplore: "Explorons",
      continueAssessmentText: "Continuer l'évaluation",
      timeToTakeAssessmentText:
        "Il est maintenant temps de passer à l'évaluation",
    },
    [Languages.ITALIAN]: {
      explorePlatForm: "Ora è il momento di esplorare la nostra piattaforma.",
      letsExplore: "Esploriamo",
      continueAssessmentText: "Continua con la valutazione",
      timeToTakeAssessmentText: "Ora è il momento di fare la valutazione",
    },
    [Languages.TURKISH]: {
      explorePlatForm: "Şimdi platformumuzu keşfetme zamanı.",
      letsExplore: "Hadi keşfedelim",
      continueAssessmentText: "değerlendirmeye devam et",
      timeToTakeAssessmentText: "Şimdi değerlendirme yapma zamanı",
    },
    [Languages.GERMAN]: {
      explorePlatForm: "Jetzt ist es Zeit, die Webseite zu erkunden. ",
      letsExplore: "Lass uns erkunden.",
      continueAssessmentText: "Mache weiter mit der Abfrage.",
      timeToTakeAssessmentText: "Jetzt ist es Zeit für die Abfrage.",
    },
  };

  static RegisterContent = {
    [Languages.ENGLISH]: {
      hi: "Hi",
      registerText: "Register for your AQai account",
      discount:
        "Using a discount code? You will be able to enter this at checkout.",
      welcome: "Welcome to",
      registerTextNew:
        "Register for instant access to the AQme assessment, your AQme profile results, plus a comprehensive downloadable PDF report.",
      terms: "Terms and conditions",
      privacy: "Privacy Policy",
      unprocessable_entity: "Sorry, there seems to be an issue here.",
      email_already_registered:
        "Sorry, this email is already registered with us.",
      otpSuccessFull: "Verification code sent successfully",
      team_not_found: "Sorry, we could'nt find this team.",
      organization_not_found: "Sorry, we could'nt find this organisation.",
      database_error: "Sorry, we encountered a database error",
      invitation_link_expired: "This invitation link is expired",
      organization_limit_reached:
        "Maximum number of users has been reached. Please contact your manager.",
      wrong_otp: "Sorry, the verification code is incorrect.",
      otp_notFound: "Verification code Not Found. Request a new One!",
      lastPageCheckoutText: [
        "This is the last step of checking out.",
        "After this page, the payment will be withdrawn from your card.",
      ],
      changeVisibilityText: [
        "Confirm your profile visibility",
        "Great news, you have been invited to complete your AQ adaptability profile. Your coach/consultant/leader has set up your dashboard to access and view your results. This will enable them to help you understand your personalised report in more detail. (This is our recommended set up).",
        "If you would like to override this setting, please click here on invisible to disable their access to your results - but do note, you will not be able to benefit from their knowledge and debriefing insights.",
        "Invisible",
        "Visible (Recommended)",
        "Submit",
      ],
      email_already_exsits:
        "Email already exists. Please try with another email or reach out to support",
    },
    [Languages.VIETNAMESE]: {
      hi: "Chào bạn!",
      registerText: "Đăng kí tài khoản AQai của bạn",
      discount: "Bạn có mã giảm giá? Hãy nhập mã khi thanh toán",
      welcome: "Chào mừng bạn",
      registerTextNew:
        "Đăng kí để truy cập ngay vào bài đánh giá AQme, kết quả hồ sơ AQme và báo cáo toàn diện có thể được tải xuống dưới dạng PDF.",
      terms: "Điều khoản và điều kiện",
      privacy: "Chính sách bảo mật",
      unprocessable_entity: "Có lỗi xảy ra",
      email_already_registered: "Email đã được sử dụng để đăng kí",
      otpSuccessFull: "Đã gửi mã xác nhận",
      team_not_found: "Không tìm thấy nhóm này",
      organization_not_found: "Không tìm thấy tổ chức này",
      database_error: "Lỗi cơ sở dữ liệu",
      invitation_link_expired: "Liên kết đã hết hạn",
      organization_limit_reached:
        "Số người dùng đã đạt mức tối đa. Hãy liên hệ với quản lý của bạn.",
      wrong_otp: "Mã xác minh không chính xác",
      otp_notFound: "Không tìm thấy mã xác minh. Hãy yêu cầu một mã mới",
      lastPageCheckoutText: [
        "Đây là bước cuối cùng.",
        "Kết thúc bước này, quá trình thanh toán sẽ được thực hiện.",
      ],
      changeVisibilityText: [
        "Xác nhận hiển thị hồ sơ của bạn.",
        "Bạn đang được mời tham gia thực hiện đánh giá năng lực thích ứng (AQ). Người huấn luyện/tư vấn/quản lý của bạn đã thiết lập cấu hình để có thể truy cập và xem kết quả của bạn. Điều này sẽ cho phép họ giúp bạn phân tích chi tiết hơn về báo cáo cá nhân. (Đây là cấu hình đề xuất của chúng tôi). ",
        "Nếu bạn muốn hủy bỏ cấu hình này, vui lòng bấm vào Không hiển thị để gỡ quyền truy cập vào kết quả của bạn. Nhưng lưu ý, bạn sẽ không được hỗ trợ phân tích cũng như diễn giải kết quả đánh giá để có cái nhìn chi tiết hơn.",
        "Không hiển thị ",
        "Hiển thị (đề xuất)",
        "Nộp",
      ],
      email_already_exsits:
        "Email đã tồn tại. Vui lòng thử bằng email khác hoặc liên hệ để được hỗ trợ",
    },
    [Languages.TURKISH]: {
      hi: "Selam",
      registerText: "AQai hesabı için kaydolun",
      discount:
        "İndirim kodu mu kullanıyorsunuz? Bunu kasada girebileceksiniz.",
      welcome: "Hoşgeldiniz",
      registerTextNew:
        "AQme değerlendirmesine, AQme profil sonuçlarınıza ve ayrıca kapsamlı bir indirilebilir PDF raporuna anında erişim için kaydolun.",
      terms: "Şartlar ve koşullar",
      privacy: "Gizlilik Politikası",
      unprocessable_entity: "Üzgünüm, burada bir sorun var gibi görünüyor.",
      email_already_registered: "Üzgünüz, bu e-posta zaten bize kayıtlı.",
      otpSuccessFull: "Doğrulama kodu başarıyla gönderildi",
      team_not_found: "Üzgünüz, bu takımı bulamadık.",
      organization_not_found: "Üzgünüz, bu organizasyonu bulamadık.",
      database_error: "Üzgünüz, bir veritabanı hatasıyla karşılaştık",
      invitation_link_expired: "Bu davet bağlantısının süresi doldu",
      organization_limit_reached:
        "Maksimum kullanıcı sayısına ulaşıldı. Lütfen yöneticinizle iletişime geçin.",
      wrong_otp: "Maalesef doğrulama kodu yanlış.",
      otp_notFound: "Doğrulama kodu bulunamadı. Yeni bir tane isteyin!",
      lastPageCheckoutText: [
        "Bu, kontrol etmenin son adımıdır.",
        "Bu sayfadan sonra ödeme kartınızdan çekilecektir.",
      ],
      changeVisibilityText: [
        "Profil görünürlüğünü onayla",
        "Harika haberler, AQ uyum profili tamamlamanız için davet edildiniz. Koç/danışman/lideriniz, sonuçlarınıza erişmek ve görüntülemek için kontrol panelinizi kurdu. Bu, size kişiselleştirilmiş raporunuzu daha ayrıntılı bir şekilde anlamalarına yardımcı olacaktır. (Bu, önerilen kurulumumuzdur).",
        "Bu ayarı geçersiz kılmak isterseniz, sonuçlarınıza erişimlerini devre dışı bırakmak için buraya tıklayın - ancak dikkat edin, onların bilgilerinden ve değerlendirme içgörülerinden faydalanamayacaksınız.",
        "Görünmez",
        "Görünür (Tavsiye Edilen)",
        "Gönder",
      ],
      email_already_exsits:
        "Bu e-posta zaten var. Lütfen başka bir e-postayla deneyin veya destek ekibiyle iletişime geçin",
    },
    [Languages.SPANISH]: {
      hi: "Hola",
      welcome: "Bienvenido a",
      registerText: "Regístrese para obtener su cuenta AQai",
      discount:
        "Utilizzi un codice sconto? Potrai inserirlo al momento del pagamento.",
      registerTextNew:
        "Regístrese para obtener acceso instantáneo a la evaluación de AQme, los resultados de su perfil de AQme, además de un informe completo en PDF descargable.",
      terms: "Términos y condiciones",
      privacy: "Política de privacidad",
      unprocessable_entity: "Lo siento, parece haber un problema aquí.",
      otpSuccessFull: "Código de verificación enviado con éxito",
      email_already_registered:
        "Lo sentimos, este correo electrónico ya está registrado con nosotros.",
      team_not_found: "Lo sentimos, no pudimos encontrar este equipo.",
      organization_not_found:
        "Lo sentimos, no pudimos encontrar esta organización.",
      database_error: "Lo sentimos, encontramos un error en la base de datos.",
      invitation_link_expired: "Este enlace de invitación caducó",
      organization_limit_reached:
        "Se ha alcanzado el número máximo de usuarios. Comuníquese con su gerente.",
      wrong_otp: "Lo sentimos, el código de verificación es incorrecto.",
      otp_notFound:
        "Código de verificación no encontrado. ¡Solicita uno nuevo!",
      lastPageCheckoutText: [
        "Este es el último paso de la salida.",
        "Después de esta página, el pago se retirará de su tarjeta.",
      ],
      changeVisibilityText: [
        "Confirma la visibilidad de tu perfil",
        "¡Excelentes noticias! Has sido invitado(a) a completar tu perfil de adaptabilidad AQ. Tu coach/consultor/líder ha configurado tu panel de control para acceder y ver tus resultados. Esto les permitirá ayudarte a comprender tu informe personalizado con más detalle. (Esta es nuestra configuración recomendada).",
        "Si deseas anular esta configuración, haz clic aquí en invisible para deshabilitar su acceso a tus resultados, pero ten en cuenta que no podrás beneficiarte de su conocimiento y de las ideas que te brinden en la sesión de revisión.",
        "Invisible",
        "Visible (Recomendado)",
        "Enviar",
      ],
      email_already_exsits:
        "El Email ya existe. Inténtelo con otro correo electrónico o comuníquese con el soporte.",
    },
    [Languages.FRENCH]: {
      hi: "salut",
      welcome: "Bienvenue à",
      registerText: "Inscrivez-vous pour obtenir votre compte AQai",
      discount:
        "Utiliser un code de réduction ? Vous pourrez le saisir à la caisse.",
      registerTextNew:
        "Inscrivez-vous pour un accès instantané à l'évaluation AQme, aux résultats de votre profil AQme et à un rapport PDF complet téléchargeable.",
      terms: "Conditions générales d'utilisation",
      privacy: "Politique de confidentialité",
      unprocessable_entity: "Désolé, il semble y avoir un problème ici.",
      otpSuccessFull: "Code de vérification envoyé avec succès",
      email_already_registered:
        "Désolé, cet e-mail est déjà enregistré chez nous.",
      team_not_found: "Désolé, nous n'avons pas pu trouver cette équipe.",
      organization_not_found:
        "Désolé, nous n'avons pas pu trouver cette organisation.",
      database_error:
        "Désolé, nous avons rencontré une erreur de base de données",
      invitation_link_expired: "Ce lien d'invitation a expiré",
      organization_limit_reached:
        "Le nombre maximum d'utilisateurs a été atteint. Veuillez contacter votre gestionnaire.",
      wrong_otp: "Désolé, le code de vérification est incorrect.",
      otp_notFound: "Code de vérification introuvable. Demandez un nouveau!",
      lastPageCheckoutText: [
        "C'est la dernière étape de vérification.",
        "Après cette page, le paiement sera prélevé sur votre carte.",
      ],
      changeVisibilityText: [
        "Confirmez la visibilité de votre profil",
        "Bonne nouvelle, vous avez été invité(e) à compléter votre profil AQ d'adaptabilité. Votre coach/consultant/leader a configuré votre tableau de bord pour accéder et consulter vos résultats. Cela leur permettra de vous aider à comprendre votre rapport personnalisé en détail. (C'est notre configuration recommandée).",
        "Si vous souhaitez annuler ce paramètre, veuillez cliquer ici sur invisible pour désactiver leur accès à vos résultats - mais veuillez noter que vous ne pourrez pas bénéficier de leurs connaissances et de leurs perspectives lors de la restitution.",
        "Invisible",
        "Visible (Recommandé)",
        "Envoyer",
      ],
      email_already_exsits:
        "L'email existe déjà. Veuillez essayer avec un autre e-mail ou contacter l'assistance",
    },
    [Languages.ITALIAN]: {
      hi: "Ciao",
      welcome: "Benvenuto a",
      registerText: "Registrati per il tuo account AQai",
      discount:
        "Utilizzi un codice sconto? Potrai inserirlo al momento del pagamento.",
      registerTextNew:
        "Registrati per accedere immediatamente alla valutazione AQme, ai risultati del tuo profilo AQme e a un rapporto completo in PDF scaricabile.",
      terms: "Termini e Condizioni",
      privacy: "politica sulla riservatezza",
      unprocessable_entity: "Spiacenti, sembra che ci sia un problema qui.",
      email_already_registered:
        "Spiacenti, questa email è già registrata con noi.",
      otpSuccessFull: "Codice di verifica inviato correttamente",
      team_not_found: "Spiacenti, non siamo riusciti a trovare questa squadra.",
      organization_not_found:
        "Spiacenti, non siamo riusciti a trovare questa organizzazione.",
      database_error: "Spiacenti, abbiamo riscontrato un errore nel database",
      invitation_link_expired: "Questo link di invito è scaduto",
      organization_limit_reached:
        "È stato raggiunto il numero massimo di utenti. Si prega di contattare il proprio responsabile.",
      wrong_otp: "Spiacenti, il codice di verifica non è corretto.",
      otp_notFound: "Codice di verifica non trovato. Richiedine uno nuovo!",
      lastPageCheckoutText: [
        "Questo è l'ultimo passaggio del check-out.",
        "Dopo questa pagina, il pagamento verrà prelevato dalla tua carta.",
      ],
      changeVisibilityText: [
        "Conferma la visibilità del tuo profilo",
        "Ottime notizie, sei stato invitato a completare il tuo profilo di adattabilità AQ. Il tuo coach/consulente/leader ha configurato il tuo cruscotto per accedere e visualizzare i tuoi risultati. Ciò permetterà loro di aiutarti a comprendere il tuo rapporto personalizzato in modo più dettagliato. (Questa è la nostra configurazione consigliata).",
        "Se desideri annullare questa impostazione, fai clic qui su invisibile per disabilitare il loro accesso ai tuoi risultati, ma tieni presente che non potrai beneficiare della loro conoscenza e delle loro intuizioni nella revisione.",
        "Invisibile",
        "Visibile (Consigliato)",
        "Invia",
      ],
      email_already_exsits:
        "Email già esistente. Prova con un'altra email o contatta l'assistenza",
    },
    [Languages.GERMAN]: {
      hi: "Hallo",
      registerText: "Registriere Dich für Dein AQai-Konto:",
      discount:
        "Du hast einen Rabatt-Code? Du kannst ihn im Kassenbereich eingeben.",
      welcome: "Herzlich willkommen zu",
      registerTextNew:
        "Registriere dich für den sofortigen Zugriff auf die AQme-Bewertung, deine AQme-Profilergebnisse und einen umfassenden PDF-Bericht zum Herunterladen.",
      terms: "Bedingungen und Konditionen",
      privacy: "Datenschutzrichtlinien",
      unprocessable_entity:
        "Entschuldigung, hier scheint es ein Problem zu geben.",
      email_already_registered:
        "Sorry, diese E-Mail ist bereits bei uns registriert.",
      otpSuccessFull: "Überprüfungscode erfolgreich gesendet",
      team_not_found: "Sorry, wir konnten dieses Team nicht finden.",
      organization_not_found:
        "Entschuldigung, wir konnten diese Organisation nicht finden.",
      database_error: "Sorry, es ist ein Datenbankfehler aufgetreten",
      invitation_link_expired: "Dieser Einladungslink ist abgelaufen",
      organization_limit_reached:
        "Die maximale Anzahl von Benutzern wurde erreicht. Bitte kontaktiere den Administrator?.",
      wrong_otp: "Entschuldigung, der Verifizierungscode ist falsch.",
      otp_notFound:
        "Verifizierungscode nicht gefunden. Bitte fordere einen neuen an!",
      lastPageCheckoutText: [
        "Dies ist der letzte Schritt im Kassenbereich. ",
        "Nach dieser Seite wird die Zahlung deiner Kreditkarte berechnet.",
      ],
      changeVisibilityText: [
        "Bestätige die Sichtbarkeit deines Profils",
        "Großartige Neuigkeiten! Sie wurden eingeladen, Ihr AQ-Anpassungsprofil zu vervollständigen. Ihr Coach/Berater/Leiter hat Ihr Dashboard eingerichtet, um auf Ihre Ergebnisse zugreifen und sie anzeigen zu können. Dadurch können sie Ihnen helfen, Ihren personalisierten Bericht genauer zu verstehen. (Dies ist unsere empfohlene Einrichtung).",
        "Wenn Sie diese Einstellung überschreiben möchten, klicken Sie hier auf Unsichtbar, um ihren Zugriff auf Ihre Ergebnisse zu deaktivieren - beachten Sie jedoch, dass Sie nicht von ihrem Wissen und ihren Rückmeldungserkenntnissen profitieren können.",
        "Unsichtbar",
        "Sichtbar (Empfohlen)",
        "Absenden",
      ],
      email_already_exsits:
        "E-Mail existiert bereits. Bitte versuchen Sie es mit einer anderen E-Mail oder wenden Sie sich an den Support",
    },
  };
}
