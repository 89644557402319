import React, { useEffect } from "react";
import { StoreProps } from "../../app/App";
import "./SwitchLanguage.scss";
import { inject, observer } from "mobx-react";
import { Languages, LanguagesEnumUtils } from "../../../enums/languages.enum";
import { CommonUtils } from "../../../utils/common-utils";
import { Button, Dropdown, Menu } from "antd";
//@ts-ignore
import ReactCountryFlag from "react-country-flag";

interface Props extends StoreProps {
  isReportLanguageChange?: boolean;
  isHideContainerClass?: boolean;
}

const SwitchLanguage: React.FC<Props> = (props: Props) => {
  const swithLanguages = props.isReportLanguageChange
    ? [Languages.ENGLISH, Languages.FRENCH]
    : CommonUtils.iterateEnum<Languages>(Languages);

  const currentLocale = props.store!.currentLocale;

  useEffect(() => {
    if (
      props.isReportLanguageChange &&
      ![Languages.ENGLISH, Languages.FRENCH].includes(
        props.store!.currentLocale,
      )
    ) {
      props.store!.setCurrentLocale(Languages.ENGLISH);
    }
  }, [props.isReportLanguageChange]);

  const menu = (
    <Menu>
      {swithLanguages.map((v) => {
        return (
          <Menu.Item key={v} onClick={() => props.store!.setCurrentLocale(v)}>
            {LanguagesEnumUtils.getLanguageTitle(v)}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <div
      className={
        props.isReportLanguageChange || props.isHideContainerClass
          ? ""
          : "switch-language-container"
      }
    >
      <Dropdown className="dropDown" overlay={menu} placement="bottomCenter">
        <Button>
          <ReactCountryFlag
            countryCode={
              currentLocale === Languages.ENGLISH
                ? "GB"
                : currentLocale === Languages.VIETNAMESE
                ? "VN"
                : currentLocale
            }
          />

          {LanguagesEnumUtils.getLanguageTitle(props.store!.currentLocale)}
        </Button>
      </Dropdown>
    </div>
  );
};

export default inject("store")(observer(SwitchLanguage));
