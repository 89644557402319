import { action, computed, observable, runInAction } from "mobx";
import Store from "./Store";
import { BotModel } from "../models/BotModel";
import { botService } from "../services/BotService";

export class BotStore extends Store<BotModel> {
  private static _instance: BotStore;

  @observable isLoading = false;
  @observable isLoaded = false;
  @observable convertedQuestions: any;

  constructor() {
    super();
    BotModel._store = this;
  }

  @computed
  get chatbotQuestions(): BotModel[] {
    return [...this.entities];
  }

  static getInstance(): BotStore {
    if (!this._instance) {
      this._instance = new BotStore();
    }

    return this._instance;
  }

  @action
  async fetchAllQuestions(language: string) {
    this.isLoading = true;
    try {
      const conversions = await botService.getAll(language);
      this.isLoaded = true;
      this.isLoading = false;
      this.convertedQuestions = conversions;
      console.log("check loading", this.isLoading);
      console.log("co", this.isLoading);
    } catch (e) {
      this.isLoading = false;
      console.log(e);
    }
  }
}
