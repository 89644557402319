// two extra enums compare to BE is used to distinguish modals.
export enum AqliteUpgradeInviteType {
  UPGRADE_USING_AQ_CREDITS = "upgrade_using_aq_credits",
  UPGRADE_PAID_INVITE = "upgrade_paid_invite",
  COMPLETE_UPGRADE = "complete_upgrade",
  BULK_UPGRADE = "bulk_upgrade",
}

export enum AqliteUpgradeStatus {
  COMPLETE_UPGRADE = "complete_upgrade",
  AWAITING_USER_RESPONSE = "awaiting_user_response",
  UPGRADE_NOW = "upgrade_now",
}
