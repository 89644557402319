import { observable } from "mobx";
import { DimensionStore } from "../stores/DimensionStore";
import Model from "./Model";
import { DimensionLanguages } from "../enums/languages.enum";

export class Dimension extends Model {
  static _store: DimensionStore;

  @observable id!: number;
  @observable parent_dimension_id!: number;
  @observable title!: string;
  @observable minimum_score!: number;
  @observable badge_url!: string;
  @observable badge_description!: string;
  @observable meta!: {
    individual: MetaDescription;
    enterprise: MetaDescription;
    range: MetaRange;
  };
  @observable language_titles!: { [key in DimensionLanguages]: string };
  @observable url!: string;

  getId(): string | number {
    return this.id;
  }
}

interface MetaDescription {
  summary: {
    [key in DimensionLanguages]: { [key: string]: string[] };
  };
  insights: {
    [key in DimensionLanguages]: { [key: string]: string[] };
  };
  advancedInsights?: {
    [key in DimensionLanguages]: { [key: string]: string[] };
  };
  short_name: { [key in DimensionLanguages]: string };
  benchmark_score: number;
  description: {
    [key in DimensionLanguages]: string[];
  };
  footer: {
    [key in DimensionLanguages]: string[];
  };

  header: { [key in DimensionLanguages]: string[] };
  headline: { [key in DimensionLanguages]: string[] };
  strategies_for_action_insight: {
    [key in DimensionLanguages]: { [key: string]: string[] };
  };
  how_does_it_impact_insight: {
    [key in DimensionLanguages]: { [key: string]: string[] };
  };
}

export interface MetaRange {
  upper: number;
  lower: number;
}
