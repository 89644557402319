import React, { FunctionComponent } from "react";
import classNames from "classnames";
import { Tooltip } from "antd";

export interface Props {
  label?: string;
  className?: string;
  htmlFor?: string;
  toolTipTitle?: string;
  isRequiredIndicator?: boolean;
}

const InputLabel: FunctionComponent<Props> = ({
  label,
  className,
  htmlFor,
  toolTipTitle,
  isRequiredIndicator,
}) => {
  const renderLabel = (
    <label
      className={classNames("block flex  leading-none", className)}
      htmlFor={htmlFor}
    >
      {label}
      {isRequiredIndicator && <span className={"text-brandRed"}>*</span>}
    </label>
  );

  return (
    <>
      {toolTipTitle ? (
        <Tooltip title={toolTipTitle}>
          <></>
          {renderLabel}
        </Tooltip>
      ) : (
        renderLabel
      )}
    </>
  );
};

export default InputLabel;
