import { ListResponse } from "../responses/list.response";
import { ShowResponse } from "../responses/show.response";
import { apiService } from "./ApiService";
import { CommunityCardFormData } from "../models/CommunityModel";
import { PlatformContentModal } from "../models/PlatformContentModal";

class PlatformContentService {
  static getInstance(): PlatformContentService {
    return new PlatformContentService();
  }

  getAll(): Promise<PlatformContentModal[]> {
    return apiService
      .get<ListResponse<PlatformContentModal>>("/platform-content", true, true)
      .then((response) => response.data);
  }

  update(
    id: number,
    data: CommunityCardFormData,
  ): Promise<PlatformContentModal> {
    return apiService
      .put<ShowResponse<PlatformContentModal>>(
        `/platform-content/${id}`,
        true,
        false,
        data,
      )
      .then((res) => res.data);
  }
}

export const platformContentService = PlatformContentService.getInstance();
