import { inject, observer } from "mobx-react";
import React from "react";
import { Redirect, Route } from "react-router";
import MaintenancePage from "../components/MaintainancePage/MaintainancePage";
import Loader from "../components/shared/loader/Loader";
import { SettingType } from "../enums/SettingTypeEnum.enum";
import { LoginState } from "../enums/login-state.enum";
import { Role } from "../enums/role.enum";
import { Routes } from "../routes";
import { RouteUtil } from "../utils/route-utils";

const DashboardProtectedRoute: React.FC<any> = ({
  component: Component,
  ...rest
}) => {
  switch (rest.store.user.loginState) {
    case LoginState.LOGGED_IN: {
      switch (rest.store.isBootstrapped) {
        case true:
          // TODO:: ??
          const {
            loggedInProfile,
            loggedInProfile: { o },
          } = rest.store.user;

          // Maintenance Page check
          if (
            loggedInProfile.role !== Role.ADMIN &&
            rest.store!.settingsStore.settingsData &&
            rest.store!.settingsStore.settingsData[SettingType.MAINTENANCE_PAGE]
              .value === "true"
          ) {
            return <Route {...rest} render={(props) => <MaintenancePage />} />;
          }

          // If Admin then redirect to admin dashboard
          if (
            loggedInProfile.role === Role.ADMIN ||
            loggedInProfile.role === Role.CDP ||
            loggedInProfile.role === Role.CORPORATE
          ) {
            return (
              <Route
                {...rest}
                render={(props) => (
                  <Redirect
                    to={{
                      pathname: RouteUtil.getUrl(Routes.adminHome),
                    }}
                  />
                )}
              />
            );
          }

          if (rest.location.pathname.includes("/onboarding/tasks")) {
            return (
              <Route {...rest} render={(props) => <Component {...props} />} />
            );
          }

          return (
            <Route {...rest} render={(props) => <Component {...props} />} />
          );

        case false:
        default:
          return <Route {...rest} render={() => <Loader />} />;
      }
    }

    case LoginState.LOGGED_OUT:
      return (
        <Route
          {...rest}
          render={(props) => (
            <Redirect
              to={{
                pathname: RouteUtil.getUrl(Routes.login),
                state: { from: props.location },
              }}
            />
          )}
        />
      );

    case LoginState.PENDING:
    default:
      return <Route {...rest} render={() => <Loader />} />;
  }
};

export default inject("store")(observer(DashboardProtectedRoute));
