import { Coupon, CouponBaseModel } from "../models/CouponModel";
import { ListResponse } from "../responses/list.response";
import { ShowResponse } from "../responses/show.response";
import { apiService } from "./ApiService";
import { TransactionModel } from "../models/TransactionModel";

class CouponService {
  static getInstance(): CouponService {
    return new CouponService();
  }

  getAll(): Promise<Coupon[]> {
    return apiService
      .get<ListResponse<Coupon>>("/admin/coupons", true, false)
      .then((response) => response.data);
  }

  add(data: CouponBaseModel): Promise<Coupon> {
    return apiService
      .post<ShowResponse<Coupon>>("/admin/coupon", true, false, data)
      .then((response) => response.data);
  }

  delete(id: number): Promise<Coupon> {
    return apiService.delete("/admin/coupons/" + id, true, false);
  }

  updateCoupon(id: number, data: CouponBaseModel): Promise<Coupon> {
    return apiService
      .put<ShowResponse<Coupon>>(`/admin/coupons/${id}`, true, false, data)
      .then((res) => res.data);
  }

  getCouponTransactions(id: number): Promise<TransactionModel[]> {
    return apiService
      .get<ListResponse<TransactionModel>>(
        `/coupon/${id}/transactions`,
        true,
        false,
      )
      .then((res) => res.data);
  }
}

export const couponService = CouponService.getInstance();
