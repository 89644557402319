export enum DashboardTitle {
  AQ_ME = "AQme",
  AQ_LITE = "AQlite",
  AQ_ENTERPRISE = "AQteam",
  TEAMS = "Teams",
  PEOPLE = "People",
  COMMUNITY = "Community",
  ABOUT = "Education",
  ACCOUNT = "Account",
}
