import React from "react";
import BgImageWrapper from "../../shared-components/BgImageWrapper";
import { observer } from "mobx-react";
import { Spin } from "antd";

interface Props {
  children: React.ReactElement;
  imageSrc?: string;
}

const AuthContainerNew: React.FC<Props> = ({ children, imageSrc }) => {
  return (
    <BgImageWrapper>
      <div
        className={
          "flex rounded-40px bg-white  overflow-hidden  h-full shadow-brandShadow relative"
        }
        style={{ zIndex: 10 }}
      >
        <div
          className={
            "bg-brandGrey3 w-12/25 flex flex-col justify-between items-center overflow-y-auto"
          }
        >
          <img
            className="w-full h-full"
            src={
              imageSrc || require("../../../assets/loginDefault.png")?.default
            }
            alt="promotional-image"
          />
        </div>
        <div className={"w-13/25 overflow-y-auto flex flex-wrap"}>
          {children}
        </div>
      </div>
    </BgImageWrapper>
  );
};

export default observer(AuthContainerNew);
