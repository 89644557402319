import classNames from "classnames";
import React from "react";
import { ThemeType } from "../../../enums/theme-type.enum";
import SvgIconComponent from "../svgIconComponent/svgIcon.component";

export interface Props {
  type?: "button" | "submit";
  className?: string;
  onClick?: (e?: any) => void;
  children?: any;
  theme?: ThemeType;
  disabled?: boolean;
  isSubmitting?: boolean;
  buttonStyle?: {
    padding?: string;
    borderRadius?: string;
    fontWeight?: string;
  };
  name?: string;
  trailingSvgIcon?: any;
  leadingSvgIcon?: any;
  leadingSvgIconClassname?: string;
  trailingSvgIconClassname?: string;
}

export const BrandButton: React.FC<Props> = ({
  type,
  className,
  children,
  onClick,
  theme,
  disabled,
  isSubmitting,
  buttonStyle,
  name,
  trailingSvgIcon,
  leadingSvgIcon,
  leadingSvgIconClassname,
  trailingSvgIconClassname,
}) => {
  const classForTheme: any = {
    primary: "text-white bg-brandDarkBlue border border-brandDarkBlue",
    secondary: "text-brandDarkBue  bg-brandYellow border border-brandYellow",
    tertiary: "text-white bg-brandGrey border border-brandGrey",
    white: "text-brandDarkBlue bg-white border border-white",
    orange: "text-brandDarkBlue  border border-transparent bg-brandOrange3",
    green: "text-white border border-transparent bg-brandGreen5",
    secondary_white:
      "text-brandDarkBlue3 bg-white border border-brandDarkBlue3",
    white_bg_with_grey_border:
      "text-brandDarkBlue bg-white border border-brandGrey9",
  };

  return (
    <button
      type={type}
      name={name}
      onClick={onClick}
      disabled={disabled || isSubmitting}
      className={classNames(
        "inline-flex relative",
        "justify-center",
        "items-center",
        "focus:outline-none",
        "transition ease-in-out",
        "duration-150",
        disabled ? "pointer-events-none" : "",
        className,
        classForTheme[theme!],
        buttonStyle && buttonStyle.fontWeight
          ? buttonStyle.fontWeight
          : "font-medium",
        buttonStyle && buttonStyle.padding ? buttonStyle.padding : "px-4 py-4",
        buttonStyle && buttonStyle.borderRadius
          ? buttonStyle.borderRadius
          : "rounded-2xl",
      )}
    >
      {leadingSvgIcon && (
        <SvgIconComponent
          icon={leadingSvgIcon}
          onClick={() => null}
          className={leadingSvgIconClassname}
        />
      )}
      {children}

      {trailingSvgIcon && (
        <SvgIconComponent
          icon={trailingSvgIcon}
          onClick={() => null}
          className={trailingSvgIconClassname}
        />
      )}
    </button>
  );
};

BrandButton.defaultProps = {
  type: "submit",
  disabled: false,
  className: "",
  theme: "primary",
};

export default React.memo(BrandButton);
