import Model from "./Model";
import { CouponStore } from "../stores/CouponStore";
import { observable } from "mobx";
import { TransactionModel } from "./TransactionModel";
import { CouponFeatures } from "../enums/features.enum";

export class Coupon extends Model {
  static _store: CouponStore;

  @observable id!: number;
  @observable code!: string;
  @observable maximum_redemptions!: number;
  @observable discount_percentage!: number;
  @observable minimum_assessments!: number | null;
  @observable maximum_assessments!: number | null;
  @observable redemptions!: number;
  @observable description!: string;
  @observable valid_upto!: string;
  @observable features!: CouponFeatures[];
  @observable transactions?: TransactionModel[];
  @observable offer_volume_discount?: boolean;

  getId(): string | number {
    return this.id;
  }
}

export interface CouponBaseModel {
  code: string;
  maximum_redemptions: number;
  discount_percentage: number;
  description: string;
  valid_upto: string;
  minimum_assessments?: number;
  maximum_assessments?: number;
  features: CouponFeatures[];
}
