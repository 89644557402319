import Model from "./Model";
import { observable } from "mobx";
import { ViewRawdataStore } from "../stores/ViewRawdataStore";

export class ViewRawdataModel extends Model {
  static _store: ViewRawdataStore;

  @observable id!: number;
  @observable data!: string[];
  @observable name!: string;
  @observable document_url!: string;

  getId(): string | number {
    return this.id;
  }
}
