import { ListResponse } from "../responses/list.response";
import { apiService } from "./ApiService";
import { ShowResponse } from "../responses/show.response";
import { SettingsModel } from "../models/SettingsModel";

class SettingsService {
  static getInstance(): SettingsService {
    return new SettingsService();
  }

  getAll(): Promise<SettingsModel[]> {
    return apiService
      .get<ListResponse<SettingsModel>>("/settings", true, false)
      .then((response) => response.data);
  }

  updateSetting(
    id: number,
    data: { key: string; value: string },
  ): Promise<SettingsModel> {
    return apiService
      .put<ShowResponse<SettingsModel>>(`/settings/${id}`, true, false, data)
      .then((res) => res.data);
  }

  findUserToAddTempPassword(
    email: string,
  ): Promise<{ email: string; id: number }> {
    return apiService
      .post<ShowResponse<{ email: string; id: number }>>(
        `/admin/settings/find-user-to-add-temp-password`,
        true,
        false,
        { email },
      )
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }
  updateTempPassword(id: number, password: string): Promise<SettingsModel> {
    return apiService
      .post<ShowResponse<SettingsModel>>(
        `/admin/settings/update-temp-password`,
        true,
        false,
        { id, password },
      )
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  }
}

export const settingService = SettingsService.getInstance();
