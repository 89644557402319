import { ListResponse } from "../responses/list.response";
import { apiService } from "./ApiService";
import { OrganizationStatus } from "../enums/partner-organization-status.enum";
import { PartnerOrganization } from "../models/PartnerOrganizationModel";

class PartnerOrganizationService {
  static getInstance(): PartnerOrganizationService {
    return new PartnerOrganizationService();
  }

  getAll(): Promise<PartnerOrganization[]> {
    return apiService
      .get<ListResponse<PartnerOrganization>>(
        "/admin/partner-organizations",
        true,
        false,
      )
      .then((response) => response.data);
  }

  changeStatus(id: number, status: OrganizationStatus): Promise<any> {
    return apiService.put("/admin/partner-organizations/" + id, true, false, {
      status: status,
    });
  }
}

export const partnerOrganizationService =
  PartnerOrganizationService.getInstance();
