import { action, computed, observable, runInAction } from "mobx";
import Store from "./Store";
import { PushNotificationModel } from "../models/PushNotificationModel";
import { pushNotificationService } from "../services/PushNotificationService";
import { PushNotificationSendTo } from "../enums/push-notification-send-to.enum";

export class PushNotificationStore extends Store<PushNotificationModel> {
  private static _instance: PushNotificationStore;

  @observable isLoading = false;
  @observable isLoaded = false;

  constructor() {
    super();
    PushNotificationModel._store = this;
  }

  @computed
  get pushNotifications(): PushNotificationModel[] {
    return [...this.entities];
  }

  static getInstance(): PushNotificationStore {
    if (!this._instance) {
      this._instance = new PushNotificationStore();
    }

    return this._instance;
  }

  @action
  fetchPushNotifications() {
    this.isLoading = true;
    pushNotificationService
      .getAll()
      .then((pushNotifications: PushNotificationModel[]) => {
        runInAction(() => {
          pushNotifications.map((pushNotifications) =>
            PushNotificationModel.fromJson(pushNotifications),
          );
          this.isLoaded = true;
        });
      })
      .catch((err) => console.log(err))
      .finally(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
  }

  @action
  markReadNotifications(notification_ids: number[]) {
    this.isLoading = true;
    pushNotificationService
      .markReadNotifications(notification_ids)
      .then((pushNotifications: PushNotificationModel[]) => {
        runInAction(() => {
          pushNotifications.map((pushNotifications) =>
            PushNotificationModel.fromJson(pushNotifications),
          );
          this.isLoaded = true;
        });
      })
      .catch((err) => console.log(err))
      .finally(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
  }

  @action
  createPushNotification(data: {
    sent_to: PushNotificationSendTo;
    message: string;
  }) {
    this.isLoading = true;
    return pushNotificationService
      .create(data)
      .then((response) => {
        runInAction(() => {
          PushNotificationModel.fromJson(response);
        });
        this.isLoading = false;
      })
      .catch((e) => console.log(e))
      .finally(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
  }

  @action
  async resend(id: number) {
    this.isLoading = true;
    return pushNotificationService.resend(id).then(() => {
      this.isLoading = false;
    });
  }

  @action
  async unsend(id: number) {
    this.isLoading = true;
    return pushNotificationService
      .unsend(id)
      .catch((err) => console.log(err))
      .finally(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
  }

  //   @action
  //   deletePromotionalImage(id: number) {
  //     this.isLoading = true;
  //     return promotionalImagesService
  //       .delete(id)
  //       .then(() => {
  //         runInAction(() => {
  //           this.remove(id);
  //         });
  //       })
  //       .catch((err) => console.log(err))
  //       .finally(() => {
  //         runInAction(() => {
  //           this.isLoading = false;
  //         });
  //       });
  //   }

  //   @action
  //   async addPromotionalImage(data: PromotionalImagesFormData, file: File) {
  //     this.isLoading = true;

  //     try {
  //       const url = await this.uploadImageAndGetUrl(file, data.type + moment());

  //       if (url) {
  //         const response = await promotionalImagesService.add({
  //           ...data,
  //           image_url: url,
  //         });
  //         runInAction(() => {
  //           PromotionalImagesModel.fromJson(response);
  //         });
  //       }
  //       this.isLoading = false;
  //     } catch (e) {
  //       this.isLoading = false;
  //       throw e;
  //     }
  //   }

  //   @action
  //   async updatePromotionalImage(id: number, data: any, file: any) {
  //     try {
  //       this.isLoading = true;

  //       if (file) {
  //         const url = await this.uploadImageAndGetUrl(file, data.type + moment());
  //         data = { ...data, image_url: url };
  //       }

  //       const response = await promotionalImagesService.update(id, data);
  //       runInAction(() => {
  //         PromotionalImagesModel.fromJson(response);
  //       });
  //       this.isLoading = false;
  //     } catch (e) {
  //       this.isLoading = false;
  //       throw e;
  //     }
  //   }

  //   private async uploadImageAndGetUrl(file: File, imageName: string) {
  //     try {
  //       const response = await promotionalImagesService.getPresignedUrl(
  //         imageName,
  //       );
  //       const url = response.url;
  //       await apiService.upload(url, file);
  //       return url.split("?")[0];
  //     } catch (e) {
  //       throw new Error("Error in uploading the file");
  //     }
  //   }
}
