import cx from "classnames";
import React from "react";
import { isNullOrUndefined } from "util";
import "./ADSimpleButton.scss";

interface Props {
  onClick?: (event: React.MouseEvent) => void;
  label: string;
  theme?: "dark" | "light";
  type?: "submit" | "reset" | "button";
  disabled?: boolean;
  hoverAnimation?: "lightBlue";
  className?: string;
}

const ADSimpleButton: React.FC<Props> = (props: Props) => {
  let disabled = false;
  if (!isNullOrUndefined(props.disabled)) {
    disabled = props.disabled;
  }

  return (
    <button
      type={props.type}
      disabled={disabled}
      onClick={props.onClick}
      className={cx(
        "ad-simple-button",
        props.className,
        props.theme,
        props.hoverAnimation,
        disabled ? "disabled" : "",
      )}
    >
      {props.label}
    </button>
  );
};

export default ADSimpleButton;
