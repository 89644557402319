export enum Age {
  RANGE_18_24 = "18-24",
  RANGE_25_34 = "25-34",
  RANGE_35_44 = "35-44",
  RANGE_45_54 = "45-54",
  RANGE_ABOVE_55 = "55+",
}

export class AgeEnumUtils {
  static getAgeTitle(age: Age): string {
    return age.toString();
  }
}
