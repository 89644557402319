import * as _ from "lodash";
import { action, computed, observable, runInAction } from "mobx";
import { CurrencyEnum } from "../enums/currency.enum";
import { CouponFeatures } from "../enums/features.enum";
import { LoginState } from "../enums/login-state.enum";
import { Role } from "../enums/role.enum";
import { AqliteUpgradeInviteModel } from "../models/AqliteUpgradeInviteModel";
import { People } from "../models/PeopleModel";
import { AdminUserUpdateModel, User } from "../models/UserModel";
import { UserName } from "../models/UserNameModel";
import { PaginatedResponse } from "../responses/paginated.response";
import { apiService } from "../services/ApiService";
import { authService } from "../services/AuthService";
import { userService } from "../services/UserService";
import { RootStore } from "./RootStore";
import Store from "./Store";
import { CommonUtils } from "../utils/common-utils";
import { SsoAttachRequestDto, SsoDetailsDto } from "../models/SSOModel";
import { SSOPROVIDER } from "../enums/SSO.enum";
import { ShowResponse } from "../responses/show.response";
import { RouteUtil } from "../utils/route-utils";
import { Routes } from "../routes";
import { DimensionLanguages, Languages } from "../enums/languages.enum";

export class UserStore extends Store<User> {
  private static _instance: UserStore;
  @observable loggedInProfile: User | undefined;
  @observable loginState: LoginState = LoginState.PENDING;
  @observable userSsoDetails: SsoDetailsDto[] = [];

  @observable isLoading = false;
  @observable isLoaded = false;
  @observable compactUsers: UserName[] = [];
  @observable isCompactUsersLoading = false;
  @observable isCompactUsersLoaded = false;
  @observable myPartners: UserName[] = [];
  @observable isMyPartnersLoading = false;
  @observable isMyPartnersLoaded = false;
  @observable hasMore = true;
  @observable offset = 0;
  @observable dataPerPage = 50;

  @observable private _filter: { [key: string]: any } = {};

  @observable overLaySteps: {
    run: boolean;
    stepIndex: number;
    tourActive: boolean;
  } = {
    run: false,
    stepIndex: 0,
    tourActive: false,
  };

  constructor() {
    super();
    User._store = this;
    this.getLoginStatus();
  }

  @computed
  get filters() {
    return this._filter;
  }

  @computed
  get organizationName() {
    return this.loggedInProfile!.selected_organization?.name ===
      "Your organization"
      ? ""
      : this.loggedInProfile!.selected_organization?.name;
  }

  @computed
  get getParentOrganization() {
    return this.loggedInProfile!.organizations.filter(
      (org) => !org.parent_organization_id,
    ).length
      ? this.loggedInProfile!.organizations.filter(
          (org) => !org.parent_organization_id,
        )[0]
      : this.loggedInProfile!.organizations.sort((a, b) => {
          return new Date(a.linkedToOrgAt) > new Date(b.linkedToOrgAt) ? 1 : -1;
        })[0];
  }

  @computed
  get selectedOrganization() {
    // This will return null in case of admin
    return this.loggedInProfile!.selected_organization;
  }

  @computed
  get organizations() {
    return this.loggedInProfile!.organizations;
  }

  @computed
  get isRetakeAllowed() {
    const isTrainingUser = CommonUtils.isTrainingUser(
      this.loggedInProfile!.email,
    );

    if (isTrainingUser) {
      return false;
    }

    switch (this.loggedInProfile!.role) {
      case Role.OWNER:
      case Role.PARTNER:
        return true;
      case Role.EMPLOYEE:
        if (
          this.loggedInProfile!.organizations.find((org) => org.partner_count)
        ) {
          return !!this.loggedInProfile!.teams.find((t) => t.allow_retake);
        } else {
          return false;
        }
      default:
        return false;
    }
  }

  @computed
  get users(): User[] {
    return [...this.entities];
  }

  static getInstance(): UserStore {
    if (!this._instance) {
      this._instance = new UserStore();
    }

    return this._instance;
  }

  @action
  setFilters(filters: { [key: string]: any }) {
    if (!_.isEqual(this._filter, filters)) {
      this.offset = 0;
      this.entities = [];
      this._filter = { ...this._filter, ...filters };
      this.fetchUsers();
    }
  }

  @action
  setLoggedInUser(user: User, isStopSettingApiCall?: boolean) {
    const store = RootStore.getInstance();
    if (user.organizations.length) {
      let filteredOrganization;
      const selectedOrgId = localStorage.getItem("selected_org_id");
      if (selectedOrgId) {
        filteredOrganization = user.organizations.filter(
          (org) => org.id === +selectedOrgId,
        )[0];
      }
      const isCdpCorporateUser = [Role.CDP, Role.CORPORATE].includes(user.role);
      user.selected_organization = filteredOrganization
        ? filteredOrganization
        : user!.organizations.filter((org) => !org.parent_organization_id)
            .length
        ? user.organizations.filter((org) => !org.parent_organization_id)[0]
        : user.organizations.sort((a, b) => {
            return new Date(a.linkedToOrgAt) > new Date(b.linkedToOrgAt)
              ? 1
              : -1;
          })[0];

      if (isCdpCorporateUser) {
        store.setCdpCorporateParentOrganization(user.selected_organization.id);
      }
    }
    if (user.selected_organization && user.selected_organization.id)
      store.setSelectedOrganization(user.selected_organization.id);

    this.loggedInProfile = user;
    this._setLoginStatus(LoginState.LOGGED_IN);

    const bootstrapApi = [
      store.dimension.fetchDimensions.bind(store.dimension),
      store.advancedInsights.fetchReportMetaData.bind(store.advancedInsights),
    ];

    if (!isStopSettingApiCall) {
      bootstrapApi.push(
        store.settingsStore.fetchSettings.bind(store.settingsStore),
      );
    }

    Promise.all(bootstrapApi.map((val) => val())).then(() => {
      store.isBootstrapped = true;
    });
  }

  @action
  fetchCompactUsers(role?: Role[]) {
    this.isCompactUsersLoading = true;
    userService.getCompactUsers(role).then((res) => {
      runInAction(() => {
        this.compactUsers = res;
        this.isCompactUsersLoading = false;
        this.isCompactUsersLoaded = true;
      });
    });
  }

  @action
  fetchMyPartners() {
    this.isMyPartnersLoading = true;
    userService.getMyPartners().then((res) => {
      runInAction(() => {
        this.myPartners = res;
        this.isMyPartnersLoading = false;
        this.isMyPartnersLoaded = true;
      });
    });
  }

  login(data: { email: string; password: string; recaptchaCode?: string }) {
    return authService.login(data);
  }

  loginSso(
    code: string,
    ssoProvider: SSOPROVIDER,
    preferred_language: DimensionLanguages,
  ) {
    if (code) {
      return (
        (this.isLoading = true),
        authService
          .ssoLogin(code, ssoProvider, preferred_language)
          .then(
            (
              data:
                | { user: User; token: string }
                | { public_auth_token: string },
            ) => {
              if ("user" in data) {
                runInAction(() => {
                  localStorage.setItem("auth_token", data.token);
                  this.setLoggedInUser(data.user);
                  this.isLoading = false;
                });
              } else {
                localStorage.setItem(
                  "public_auth_token",
                  data.public_auth_token,
                );
                window.location.href =
                  RouteUtil.getUrl(Routes.individualSignup, {
                    affiliateKey: "signup",
                  }) + "#org-page";
              }
            },
          )
          .catch((err) => {
            this.isLoading = false;
            throw err;
          })
      );
    }
  }

  me(useMutatedToken: boolean) {
    return authService.me(useMutatedToken);
  }

  updateProfile(data: any) {
    return authService.updateUserDetails(data, "/me").then((user: User) => {
      runInAction(() => {
        this.setLoggedInUser(user);
      });
      return user;
    });
  }

  updateEmail(data: { email: string }) {
    return authService
      .updateUserDetails(data, "/change-email")
      .then((user: User) => {
        runInAction(() => {
          this.setLoggedInUser(user);
        });
        return user;
      });
  }

  updateUserDetails(
    data: AdminUserUpdateModel,
    userId: number,
    isPeoplePage?: boolean,
  ) {
    if (isPeoplePage) {
      RootStore.getInstance().people.isPeopleLoading = true;
    } else {
      this.isLoading = true;
    }
    return userService.updateUserDetails(data, userId).then((user) => {
      runInAction(() => {
        if (isPeoplePage) {
          People.fromJson(user);
          RootStore.getInstance().people.isPeopleLoading = false;
        } else {
          User.fromJson(user);
          this.isLoading = false;
        }
      });
    });
  }

  initiateForgotPassword(email: string) {
    return authService.initiateForgotPassword(email);
  }

  completeForgotPassword(token: string, password: string) {
    return authService.completeForgotPassword(token, password);
  }

  updatePassword(data: { old_password: string; new_password: string }) {
    return authService
      .updateUserDetails(data, "/change-password")
      .then((user: User) => {
        runInAction(() => {
          this.setLoggedInUser(user);
        });
        return user;
      });
  }

  toggleHideOverlay() {
    return userService.toggleHideOverlay().then((user: User) => {
      runInAction(() => {
        this.loggedInProfile!.hide_overlay = user.hide_overlay;
      });
      return user;
    });
  }

  updateVisibility(data: { visibility: string }) {
    return authService
      .updateUserDetails(data, "/change-visibility")
      .then((user: User) => {
        runInAction(() => {
          this.loggedInProfile!.visibility = user.visibility;
        });
        return user;
      });
  }

  getInvitationDetails(token: string): Promise<any> {
    return apiService
      .get<any>("/invites", false, false, { token })
      .then((d) => d.data);
  }

  verifyTeamRegisterToken(token: string) {
    return apiService.get<any>("/verify-team-register-token", false, false, {
      token,
    });
  }

  registerUser(formData: any, isTeamInvitation: boolean): Promise<any> {
    if (isTeamInvitation) {
      return apiService.post("/teams/register", false, false, formData);
    } else {
      return apiService.post<any>("/register", false, false, formData);
    }
  }

  registerUserViaSso(
    data: {
      code: string;
      sso_provider: SSOPROVIDER;
      token: string;
      preferred_language: DimensionLanguages;
    },
    isTeamInvitation: boolean,
  ): Promise<ShowResponse<any>> {
    if (isTeamInvitation) {
      return apiService.post<any>("/sso/teams/register", false, false, data);
    } else {
      return apiService.post<any>("/sso/register", false, false, data);
    }
  }

  requestUpgrade(formdata: AqliteUpgradeInviteModel): Promise<any> {
    return userService.requestUpgrade(formdata);
  }

  completionAfterSelfUpgrade(data: {
    to_organization_id: number;
    from_organization_id: number;
    user_id: number;
  }): Promise<any> {
    return userService.completionAfterSelfUpgrade(data);
  }

  aqliteInviteUser(formData: { name?: string; email: string }): Promise<any> {
    return userService.aqliteInviteUser(formData);
  }

  // Admin
  @action
  fetchUsers() {
    this.isLoading = true;

    userService
      .getAll({ ...this._filter, offset: this.offset, limit: this.dataPerPage })
      .then((response: PaginatedResponse<User>) => {
        runInAction(() => {
          this.hasMore = !(
            !response.data.length || response.data.length < this.dataPerPage
          );
          this.offset = this.offset + this.dataPerPage;
          response.data.map((user) => User.fromJson(user));
        });
        this.isLoading = false;
        this.isLoaded = true;
        return;
      });
  }

  @action
  deleteUser(id: number) {
    return userService.delete(id).then(() => {
      runInAction(() => {
        this.remove(id);
      });
      return;
    });
  }

  exportAnswersAsCSV(userId: number, type?: { [key: string]: string }) {
    return userService.exportAnswersAsCSV(userId, type);
  }

  exportAllAnswersAsCSV() {
    return userService.exportAllAnswersAsCSV();
  }

  individualSignup(data: any) {
    return authService.individualSignup(data);
  }

  individualSsoSignup(
    code: string,
    ssoProvider: SSOPROVIDER,
    preferred_language: DimensionLanguages,
  ) {
    return authService
      .individualSsoSignup({
        code: code,
        sso_provider: ssoProvider,
        preferred_language: preferred_language,
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  }

  initiatePayment(data: {
    discount_code?: string;
    id: number;
    currency: CurrencyEnum;
    billing_details?: any;
  }) {
    return authService.initiatePayment(data);
  }

  completePayment(data: { id: number }) {
    return authService.completePayment(data);
  }

  initiateAssessmentPurchase(data: {
    assessment_purchased: number;
    currency: CurrencyEnum;
    organization_id: number;
    discount_code?: string;
  }) {
    return userService.initiateAssessmentPurchase(data);
  }

  initiateAqliteUpgrade(data: {
    currency: CurrencyEnum;
    token?: string;
    discount_code?: string;
  }) {
    return userService.initiateAqliteUpgrade(data);
  }

  completeAssessmentPurchase(data: {
    stripe_payment_token: string;
    organization_id: number;
  }) {
    return userService.completeAssessmentPurchase(data);
  }

  completeAqliteUpgrade(data: {
    stripe_payment_token: string;
    token?: string;
  }) {
    return userService.completeAqliteUpgrade(data);
  }

  verifyPromocode(
    code: string,
    data: {
      assessment_purchased: number;
      feature: CouponFeatures;
    },
  ) {
    return authService.verifyPromocode(code, data);
  }

  setMutationToken(userId: number): Promise<any> {
    return userService
      .getMutationToken(userId)
      .then((res: { token: string; user: User }) => {
        localStorage.setItem("mutated_user_token", res.token);
        /*
         * Setting selected organization id here. This will not cause any issues because
         * 1. Admin doesn't have an organization id so no effect.
         * 2. An employee can't select mirror view.
         * 3. A champion can't select mirror view for a person who is part of more than one organization. Which means that if a
         * champion is able to see mirror view, the person and champion must belong to same organization and hence same org id
         *
         * Also the Api can just return organization id instead of the entire user object, but that should not cause any issues.
         * Can change later if needed.
         */
        localStorage.setItem(
          "selected_org_id",
          res.user.organizations[0] ? "" + res.user.organizations[0].id : "",
        );
        return;
      });
  }

  getGoodDataLoginClaims(): Promise<any> {
    return userService
      .getGoodDataLoginClaims()
      .then((res: { claims: string }) => {
        return res.claims;
      });
  }

  skipAssessment(): Promise<User> {
    return userService.skipAssessment().then(() => {
      return this.me(true);
    });
  }

  private getLoginStatus() {
    const isTokenPresent = !!localStorage.getItem("auth_token");
    if (isTokenPresent) {
      const user = this.loggedInProfile;
      if (!user) {
        this.me(true)
          .then((res: User) => {
            this.setLoggedInUser(res);
          })
          .catch((err) => {
            localStorage.removeItem("auth_token");
            localStorage.removeItem("mutated_user_token");
            this._setLoginStatus(LoginState.LOGGED_OUT);
          });
      } else {
        this._setLoginStatus(LoginState.LOGGED_IN);
      }
    } else {
      this._setLoginStatus(LoginState.LOGGED_OUT);
    }
  }

  @action
  async sendOTPForEmailUpdateVerify(data: { email: string }) {
    return await userService.sendOTPForEmailUpdateVerify(data);
  }

  @action
  async sendOTP(data: { email: string }) {
    return await userService.sendOTP(data);
  }

  @action
  async rejectUpgradeInvite() {
    return userService.rejectUpgradeInvite().then(() => {
      const updatedUser = this.loggedInProfile;
      updatedUser!.upgradeInvite = undefined;
      runInAction(() => {
        User.fromJson(updatedUser);
      });
    });
  }

  @action
  async fetchUserDetails(userId: number) {
    return userService.fetchUserDetails(userId).then((user) => {
      runInAction(() => {
        User.fromJson(user);
      });
      return User.fromJson(user);
    });
  }

  @action
  private _setLoginStatus(status: LoginState) {
    this.loginState = status;
  }

  @action
  async uploadProfileImageAndGetUrl(file: File, imageName: string) {
    try {
      const response = await userService.getPresignedUrl(imageName);
      const url = response.url;
      await apiService.upload(url, file);
      return url.split("?")[0];
    } catch (e) {
      throw new Error("Error in uploading the file");
    }
  }

  @action
  setOverlaySteps(overLaySteps: {
    run: boolean;
    stepIndex: number;
    tourActive: boolean;
  }) {
    this.overLaySteps = overLaySteps;
  }
  async updateSSO(attachData: SsoAttachRequestDto) {
    return await userService.updateUserSSO(attachData).then((data) => {
      const existingSsoIndex = this.userSsoDetails.findIndex(
        (sso) => sso.sso_provider === data.sso_provider,
      );
      runInAction(() => {
        if (existingSsoIndex !== -1) {
          // Update existing SSO provider details
          this.userSsoDetails[existingSsoIndex] = data;
        } else {
          // Add new SSO provider details
          this.userSsoDetails.push(data);
        }
      });
    });
  }

  fetchUserSsoDetails() {
    try {
      this.isLoading = true;
      return userService.getUserSSODetails().then((data) => {
        runInAction(() => {
          this.userSsoDetails = data;
          this.isLoading = false;
        });
      });
    } catch (error) {
      this.isLoading = false;
      throw error;
    }
  }

  deleteUserSso(ssoProvider: SSOPROVIDER) {
    return userService.deleteUserSso(ssoProvider).then(() => {
      runInAction(() => {
        this.userSsoDetails = this.userSsoDetails.filter(
          (ssoDetails) => ssoDetails.sso_provider !== ssoProvider,
        );
      });
    });
  }
}
