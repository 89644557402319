import { computed, runInAction, observable, action } from "mobx";
import { Dimension } from "../models/DimensionModel";
import { dimensionService } from "../services/DimensionService";
import Store from "./Store";
import { Dimensions } from "../enums/dimension-id.enum";
import { Constants } from "../constants";
import { EnterpriseReport, ScoreRange } from "../models/EmterpriseReportModel";
import { CommonUtils } from "../utils/common-utils";
import { DimensionLanguages } from "../enums/languages.enum";
import { SettingsModel } from "../models/SettingsModel";
import { settingService } from "../services/SettingsService";
import { advancedInsightsService } from "../services/AdvancedInsightsService";
import { ReportMetaData } from "../models/ReportMetaDataModel";
import { LoginState } from "../enums/login-state.enum";

export class SettingsStore extends Store<SettingsModel> {
  private static _instance: SettingsStore;

  @observable settingsData: { [key: string]: SettingsModel } = {};

  @observable isLoading = false;
  @observable isLoaded = false;

  constructor() {
    super();
    SettingsModel._store = this;
  }

  @computed get settings(): SettingsModel[] {
    return [...this.entities];
  }

  @action
  fetchSettings() {
    this.isLoading = true;
    settingService
      .getAll()
      .then((settings: SettingsModel[]) => {
        runInAction(() => {
          settings.map((setting) => SettingsModel.fromJson(setting));
          this.settingsData = this.entities.reduce((prev, current) => {
            prev[current.slug] = current;
            return prev;
          }, {} as any);
          this.isLoading = false;
          this.isLoaded = true;
        });
      })
      .catch((err) => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
  }

  @action
  async updateSettings(id: number, data: { key: string; value: string }) {
    const response = await settingService.updateSetting(id, data);
    runInAction(() => {
      SettingsModel.fromJson(response);
    });
  }

  @action
  async findUserToAddTempPassword(email: string) {
    const response = await settingService.findUserToAddTempPassword(email);
    return runInAction(() => {
      return {
        email: response.email,
        id: response.id,
      };
    });
  }

  @action
  async updateTempPassword(id: number, password: string) {
    const response = await settingService.updateTempPassword(id, password);
    runInAction(() => {
      return response;
    });
  }

  static getInstance(): SettingsStore {
    if (!this._instance) {
      this._instance = new SettingsStore();
    }

    return this._instance;
  }
}
