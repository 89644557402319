import classNames from "classnames";
import React, {
  CSSProperties,
  FocusEvent,
  FunctionComponent,
  useCallback,
  useState,
} from "react";
import withFormikContext from "../withFormikContext";
import useId from "../../../Hooks/useId";
import InputLabel from "../InputLabel/InputLabel";
import InputHelper from "../Inputhelper/InputHelper";
import SvgIconComponent from "../svgIconComponent/svgIcon.component";
import { SvgIconType } from "../svgIconComponent/svgIcons.list";

interface BrandInputProps {
  name?: string;
  onChange: (event: any) => void;
  onBlur?: (event: FocusEvent) => void;
  onClick?: (event: any) => void;
  placeholder?: string;
  error?: string;
  label?: string;
  helperText?: string;
  type?:
    | "email"
    | "password"
    | "text"
    | "date"
    | "radio"
    | "file"
    | "number"
    | "time";
  className?: string;
  placeholderColor?: string;
  min?: number | string;
  max?: string;
  labelClassName?: string;
  style?: CSSProperties;
  disabled?: boolean;
  maxLength?: number;
  onKeyPress?: (event: any) => void;
  trailingSvgIcon?: any;
  bgColor?: string;
  padding?: string;
  isRequiredField?: boolean;
}

const BrandInput: FunctionComponent<BrandInputProps & Partial<any>> = ({
  name,
  type,
  value,
  onBlur,
  onChange,
  onClick,
  placeholder,
  label,
  error,
  className,
  placeholderColor,
  min,
  max,
  labelClassName,
  style,
  disabled,

  maxLength,
  onKeyPress,
  helperText,
  trailingSvgIcon,
  bgColor,
  padding,
  isRequiredField,
}) => {
  const id = useId();

  const [visible, setVisible] = useState<boolean>(false);

  const handleChange = useCallback(() => setVisible(!visible), [visible]);

  return (
    <div className={"w-full"}>
      {label && (
        <InputLabel
          htmlFor={id}
          label={label}
          className={`text-base font-medium  ${
            error ? "text-brandRed" : "text-brandDarkBlue"
          } ${labelClassName}`}
          isRequiredIndicator={isRequiredField}
        />
      )}

      <div className="relative rounded-2xl">
        <>
          <input
            id={id}
            name={name}
            value={value}
            type={type === "password" ? (visible ? "text" : "password") : type}
            onChange={onChange}
            onBlur={onBlur}
            onClick={onClick}
            min={min}
            max={max}
            maxLength={maxLength}
            disabled={disabled}
            placeholder={placeholder}
            onKeyPress={onKeyPress}
            style={style}
            className={classNames(
              `block w-full border rounded-2xl border-brandGrey ${
                bgColor ? bgColor : "bg-transparent"
              } ${
                padding ? padding : "px-4 py-4"
              } text-brandDarkBlue placeholder:text-brandGrey text-base font-medium placeholder:font-normal  focus:outline-none focus:border-brandDarkBlue`,
              {
                "mt-2": !!label,
                "text-brandGrey2": !!disabled,
                "border-brandRed text-brandRed placeholder:text-brandRed focus:border-brandRed":
                  error,
              },
              className,
            )}
          />

          {type === "password" ? (
            <div className="absolute inset-y-0 right-0 flex items-center pr-8 ">
              <SvgIconComponent
                icon={
                  visible ? SvgIconType.eyeSlashSolid : SvgIconType.eyeSolid
                }
                onClick={handleChange}
                className={"opacity-60 w-5 text-brandGrey"}
              />
            </div>
          ) : (
            trailingSvgIcon && (
              <div className="absolute inset-y-0 right-0 flex items-center pr-4">
                <SvgIconComponent
                  icon={trailingSvgIcon}
                  onClick={() => null}
                  className={""}
                />
              </div>
            )
          )}
        </>
      </div>
      <InputHelper type={"error"} text={error} />
      <InputHelper type={"helper"} text={helperText} />
    </div>
  );
};

BrandInput.defaultProps = {
  type: "text",
  disabled: false,
};

export default withFormikContext(BrandInput);

export const BrandInputWithoutFormik = BrandInput;
