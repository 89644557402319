import { action, computed, observable, runInAction } from "mobx";
import { Coupon, CouponBaseModel } from "../models/CouponModel";
import { couponService } from "../services/CouponService";
import Store from "./Store";
import { TransactionModel } from "../models/TransactionModel";

export class CouponStore extends Store<Coupon> {
  private static _instance: CouponStore;

  @observable isLoading = false;
  @observable isLoaded = false;

  constructor() {
    super();
    Coupon._store = this;
  }

  static getInstance(): CouponStore {
    if (!this._instance) {
      this._instance = new CouponStore();
    }

    return this._instance;
  }

  @computed
  get coupons(): Coupon[] {
    return [...this.entities];
  }

  @action
  fetchCoupons() {
    this.isLoading = true;
    couponService.getAll().then((coupons: Coupon[]) => {
      runInAction(() => {
        coupons.map((coupon) => Coupon.fromJson(coupon));
        this.isLoading = false;
        this.isLoaded = true;
      });
      return;
    });
  }

  @action
  updateCoupon(id: number, data: CouponBaseModel) {
    return couponService.updateCoupon(id, data).then((coupon: Coupon) => {
      runInAction(() => {
        Coupon.fromJson(coupon);
      });
      return;
    });
  }

  @action
  addCoupon(data: CouponBaseModel) {
    return couponService.add(data).then((coupon: Coupon) => {
      runInAction(() => {
        Coupon.fromJson(coupon);
      });
      return;
    });
  }

  @action
  deleteCoupon(id: number) {
    return couponService.delete(id).then(() => {
      runInAction(() => {
        this.remove(id);
      });
      return;
    });
  }

  @action
  getCouponTransaction(id: number) {
    return couponService
      .getCouponTransactions(id)
      .then((transactions: TransactionModel[]) => {
        runInAction(() => {
          const coupon = this.get(id);
          if (coupon) {
            coupon.updateFromJson({ transactions: transactions });
          }
        });
      });
  }
}
