import Model from "./Model";
import { AffiliatesStore } from "../stores/AffiliatesStore";
import { Coupon } from "./CouponModel";
import { observable } from "mobx";
import { TransactionModel } from "./TransactionModel";

export class Affiliate extends Model {
  static _store: AffiliatesStore;

  @observable id!: number;
  @observable coupon!: Coupon;
  @observable image_url!: string;
  @observable name!: string;
  @observable transactions?: TransactionModel[];

  getId(): string | number {
    return this.id;
  }
}

export interface AffiliateFormData {
  coupon_id: number;
  image_url?: string;
  name: string;
}
