import * as _ from "lodash";
import { action, computed, observable, runInAction } from "mobx";
import { CompleteSurveysModel } from "../models/CompleteSurveysModel";
import { completedSurveyService } from "../services/CompletedSurveyService";
import Store from "./Store";

export class CompletedSurveyStore extends Store<CompleteSurveysModel> {
  private static _instance: CompletedSurveyStore;

  @observable isLoading = false;
  @observable isLoaded = false;
  @observable private _filter: { [key: string]: any } = {};
  @observable hasMore = true;
  @observable offset = 0;
  @observable dataPerPage = 50;

  @observable liteSurveys: CompleteSurveysModel[] = [];
  @observable isLiteLoading = false;
  @observable isLiteLoaded = false;
  @observable private _filterLite: { [key: string]: any } = {};
  @observable hasMoreLite = true;
  @observable offsetLite = 0;
  @observable dataPerPageLite = 50;

  @observable recentSurveys: CompleteSurveysModel[] = [];
  @observable isLoadingRecent = false;
  @observable isLoadedRecent = false;
  @observable hasMoreRecent = true;
  @observable offsetRecent = 0;
  @observable dataPerPageRecent = 5;

  constructor() {
    super();
    CompleteSurveysModel._store = this;
  }

  @computed
  get filters() {
    return this._filter;
  }

  @computed
  get filtersLite() {
    return this._filterLite;
  }

  @computed
  get completedSurveys(): CompleteSurveysModel[] {
    return [...this.entities];
  }

  static getInstance(): CompletedSurveyStore {
    if (!this._instance) {
      this._instance = new CompletedSurveyStore();
    }

    return this._instance;
  }

  @action
  setFilters(filters: { [key: string]: any }) {
    if (!_.isEqual(this._filter, filters)) {
      this._filter = { ...this._filter, ...filters };
      this.offset = 0;
      this.entities = [];
      this.fetchCompletedSurvey();
    }
  }

  @action
  setFiltersLite(filters: { [key: string]: any }) {
    if (!_.isEqual(this._filterLite, filters)) {
      this._filterLite = { ...this._filterLite, ...filters };
      this.offsetLite = 0;
      this.liteSurveys = [];
      this.fetchCompletedSurveyLite();
    }
  }

  @action
  fetchCompletedSurvey() {
    this.isLoading = true;
    console.log({
      is_lite: false,
      offset: this.offset,
      limit: this.dataPerPage,
      ...this.filters,
    });
    completedSurveyService
      .getAll({
        is_lite: false,
        offset: this.offset,
        limit: this.dataPerPage,
        ...this.filters,
      })
      .then((completedSurveys: CompleteSurveysModel[]) => {
        this.hasMore = !(
          !completedSurveys.length || completedSurveys.length < this.dataPerPage
        );
        runInAction(() => {
          completedSurveys.map((recentActivity) =>
            CompleteSurveysModel.fromJson(recentActivity),
          );

          this.isLoaded = true;
          this.isLoading = false;
        });
        this.offset = this.offset + this.dataPerPage;
      })
      .catch((err) => console.log(err))
      .finally(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
  }

  @action
  fetchCompletedSurveyLite() {
    this.isLiteLoading = true;
    completedSurveyService
      .getAll({
        is_lite: true,
        offset: this.offsetLite,
        limit: this.dataPerPageLite,
        ...this.filtersLite,
      })
      .then((completedSurveysLite: CompleteSurveysModel[]) => {
        this.hasMoreLite = !(
          !completedSurveysLite.length ||
          completedSurveysLite.length < this.dataPerPageLite
        );
        runInAction(() => {
          this.liteSurveys = _.union(this.liteSurveys, completedSurveysLite);
          this.isLiteLoaded = true;
          this.isLiteLoading = false;
        });
        this.offsetLite = this.offsetLite + this.dataPerPageLite;
      })
      .catch((err) => console.log(err))
      .finally(() => {
        runInAction(() => {
          this.isLiteLoading = false;
        });
      });
  }

  exportReportsAsZip(data: {
    survey_ids?: number[];
    user_ids?: number[];
    type: "full" | "short" | "coachOnly";
  }) {
    return completedSurveyService.exportReportsAsZip(data);
  }

  @action
  fetchCompletedSurveyRecent() {
    this.isLoadingRecent = true;
    completedSurveyService
      .getAll({
        offset: this.offsetRecent,
        limit: this.dataPerPageRecent,
      })
      .then((recentActivities: CompleteSurveysModel[]) => {
        this.hasMoreRecent = !(
          !recentActivities.length ||
          recentActivities.length < this.dataPerPageRecent
        );
        runInAction(() => {
          this.recentSurveys = _.union(this.recentSurveys, recentActivities);
          this.isLoadedRecent = true;
          this.isLoadingRecent = false;
        });
        this.offsetRecent = this.offsetRecent + this.dataPerPageRecent;
        this.dataPerPageRecent = 50;
      })
      .catch((err) => console.log(err))
      .finally(() => {
        runInAction(() => {
          this.isLoadingRecent = false;
        });
      });
  }
}
