import { action, computed, observable, runInAction } from "mobx";
import Store from "./Store";
import { TasksFormData, TasksModel } from "../models/TasksModel";
import { tasksService } from "../services/TasksService";

export class TasksStore extends Store<TasksModel> {
  private static _instance: TasksStore;

  @observable isLoading = false;
  @observable isLoaded = false;

  constructor() {
    super();
    TasksModel._store = this;
  }

  @computed
  get tasks(): TasksModel[] {
    return [...this.entities];
  }

  static getInstance(): TasksStore {
    if (!this._instance) {
      this._instance = new TasksStore();
    }

    return this._instance;
  }

  @action
  async fetchTasks() {
    try {
      this.isLoading = true;

      const response = await tasksService.getAll();
      runInAction(() => {
        response.map((task) => TasksModel.fromJson(task));
        this.isLoaded = true;
      });
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
      throw e;
    }
  }

  @action
  async updateTask(id: number, data: TasksFormData) {
    try {
      this.isLoading = true;

      const response = await tasksService.update(id, data);
      runInAction(() => {
        TasksModel.fromJson(response);
      });
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
      throw e;
    }
  }

  @action
  async updateBulkTasks(data: TasksFormData[]) {
    try {
      this.isLoading = true;

      const response = await tasksService.updateBulk(data);
      // #TODO Fix This need for clearing enteties for reordering
      this.entities = [];
      runInAction(() => {
        response.map((task) => TasksModel.fromJson(task));
      });
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
      throw e;
    }
  }
}
