import Model from "./Model";
import { AdvancedInsightsStore } from "../stores/AdvancedInsightsStore";
import { observable } from "mobx";
import { User } from "./UserModel";
import { Survey } from "./Survey";

export class AdvancedInsightsModel extends Model {
  static _store: AdvancedInsightsStore;

  @observable questionAnswers!: {
    question: string;
    questionId: number;
    answer: string;
  }[];
  @observable user!: User;
  @observable survey!: Survey;

  getId(): string | number {
    return this.user && this.user.getId();
  }
}
