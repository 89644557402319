import { action, observable, runInAction, computed } from "mobx";
import Store from "./Store";
import { ViewRawdataModel } from "../models/viewRawDataModel";
import { viewRawdataService } from "../services/ViewRawDataService";

export class ViewRawdataStore extends Store<ViewRawdataModel> {
  private static _instance: ViewRawdataStore;

  @observable isLoading = false;

  constructor() {
    super();
    ViewRawdataModel._store = this;
  }

  @computed
  get rawdataValues() {
    return [...this.entities];
  }

  static getInstance(): ViewRawdataStore {
    if (!this._instance) {
      this._instance = new ViewRawdataStore();
    }
    return this._instance;
  }

  @action
  fetchRawdata() {
    this.isLoading = true;
    viewRawdataService.getAllExportedData().then((data: ViewRawdataModel[]) => {
      runInAction(() => {
        data.map((d) => ViewRawdataModel.fromJson(d));
        this.isLoading = false;
      });
    });
  }
}
