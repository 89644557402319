import { Languages } from "./languages.enum";

export enum JobLevel {
  ENTRY_LEVEL_EARLY_CAREER = "entry_level_early_career",
  INTERMEDIATE_EXPERIENCED_STAFF_SPECIALIST_ADVISOR = "intermediate_experienced_staff_specialist_advisor",
  FIRST_LEVEL_MANAGEMENT = "first_level_management",
  DIRECTOR_MIDDLE_MANAGEMENT = "director_middle_management",
  EXECUTIVE_VP_SENIOR_MANAGEMENT = "executive_vp_senior_management",
  INDEPENDENT_CONTRACTOR = "independent_contractor",
  OTHER = "other",
}

export class JobLevelEnumUtils {
  private static getJobLevelTitleInEnglish(jobLevel: JobLevel): string {
    switch (jobLevel) {
      case JobLevel.ENTRY_LEVEL_EARLY_CAREER: {
        return "Entry-level/Early Career";
      }
      case JobLevel.INTERMEDIATE_EXPERIENCED_STAFF_SPECIALIST_ADVISOR: {
        return "Intermediate, Experienced staff, Specialist/Advisor";
      }
      case JobLevel.FIRST_LEVEL_MANAGEMENT: {
        return "First-level management";
      }
      case JobLevel.DIRECTOR_MIDDLE_MANAGEMENT: {
        return "Director/Middle management";
      }
      case JobLevel.EXECUTIVE_VP_SENIOR_MANAGEMENT: {
        return "Executive, VP,  Senior management";
      }
      case JobLevel.INDEPENDENT_CONTRACTOR: {
        return "Independent/Contractor";
      }
      case JobLevel.OTHER: {
        return "Other";
      }
    }

    return "";
  }
  private static getJobLevelTitleInVetnamese(jobLevel: JobLevel): string {
    switch (jobLevel) {
      case JobLevel.ENTRY_LEVEL_EARLY_CAREER: {
        return "Nhân sự mới/Nhân viên";
      }
      case JobLevel.INTERMEDIATE_EXPERIENCED_STAFF_SPECIALIST_ADVISOR: {
        return "Chuyên viên cao cấp, Chuyên gia/Cố vấn";
      }
      case JobLevel.FIRST_LEVEL_MANAGEMENT: {
        return "Quản lý cấp cơ sở";
      }
      case JobLevel.DIRECTOR_MIDDLE_MANAGEMENT: {
        return "Giám đốc/Quản lý cấp trung";
      }
      case JobLevel.EXECUTIVE_VP_SENIOR_MANAGEMENT: {
        return "Ban điều hành, Phó Chủ tịch, Quản lý cấp cao";
      }
      case JobLevel.INDEPENDENT_CONTRACTOR: {
        return "Cá nhân/Nhà thầu";
      }
      case JobLevel.OTHER: {
        return "Khác";
      }
    }

    return "";
  }

  private static getJobLevelTitleInSpanish(jobLevel: JobLevel): string {
    switch (jobLevel) {
      case JobLevel.ENTRY_LEVEL_EARLY_CAREER: {
        return "Carrera de nivel de entrada/Early";
      }
      case JobLevel.INTERMEDIATE_EXPERIENCED_STAFF_SPECIALIST_ADVISOR: {
        return "Personal intermedio, experimentado, especialista/asesor";
      }
      case JobLevel.FIRST_LEVEL_MANAGEMENT: {
        return "Gestión de primer nivel";
      }
      case JobLevel.DIRECTOR_MIDDLE_MANAGEMENT: {
        return "Director/Gestión Media";
      }
      case JobLevel.EXECUTIVE_VP_SENIOR_MANAGEMENT: {
        return "Ejecutivo, vicepresidente, alta gerencia";
      }
      case JobLevel.INDEPENDENT_CONTRACTOR: {
        return "Contratista independiente";
      }
      case JobLevel.OTHER: {
        return "Otro";
      }
    }

    return "";
  }

  private static getJobLevelTitleInFrench(jobLevel: JobLevel): string {
    switch (jobLevel) {
      case JobLevel.ENTRY_LEVEL_EARLY_CAREER: {
        return "Entrée de gamme / début de carrière";
      }
      case JobLevel.INTERMEDIATE_EXPERIENCED_STAFF_SPECIALIST_ADVISOR: {
        return "Personnel intermédiaire et expérimenté, spécialiste / conseiller";
      }
      case JobLevel.FIRST_LEVEL_MANAGEMENT: {
        return "Gestion de premier niveau";
      }
      case JobLevel.DIRECTOR_MIDDLE_MANAGEMENT: {
        return "Directeur / gestion intermédiaire";
      }
      case JobLevel.EXECUTIVE_VP_SENIOR_MANAGEMENT: {
        return "Executive, vice-président, haute direction";
      }
      case JobLevel.INDEPENDENT_CONTRACTOR: {
        return "Entrepreneur indépendant";
      }
      case JobLevel.OTHER: {
        return "Autre";
      }
    }

    return "";
  }

  private static getJobLevelTitleInItalian(jobLevel: JobLevel): string {
    switch (jobLevel) {
      case JobLevel.ENTRY_LEVEL_EARLY_CAREER: {
        return "Carriera entry-level/prima";
      }
      case JobLevel.INTERMEDIATE_EXPERIENCED_STAFF_SPECIALIST_ADVISOR: {
        return "Personale intermedio, esperto, specialista/consulente";
      }
      case JobLevel.FIRST_LEVEL_MANAGEMENT: {
        return "Gestione di primo livello";
      }
      case JobLevel.DIRECTOR_MIDDLE_MANAGEMENT: {
        return "Direttore/Middle Management";
      }
      case JobLevel.EXECUTIVE_VP_SENIOR_MANAGEMENT: {
        return "Esecutivo, Vicepresidente, Senior Management";
      }
      case JobLevel.INDEPENDENT_CONTRACTOR: {
        return "Indipendente/appaltatore";
      }
      case JobLevel.OTHER: {
        return "Altro";
      }
    }

    return "";
  }

  private static getJobLevelTitleInTurkish(jobLevel: JobLevel): string {
    switch (jobLevel) {
      case JobLevel.ENTRY_LEVEL_EARLY_CAREER: {
        return "Giriş seviyesi/erken kariyer";
      }
      case JobLevel.INTERMEDIATE_EXPERIENCED_STAFF_SPECIALIST_ADVISOR: {
        return "Ara, deneyimli personel, uzman/danışman";
      }
      case JobLevel.FIRST_LEVEL_MANAGEMENT: {
        return "Birinci Seviye Yönetim";
      }
      case JobLevel.DIRECTOR_MIDDLE_MANAGEMENT: {
        return "Yönetmen/Orta Yönetim";
      }
      case JobLevel.EXECUTIVE_VP_SENIOR_MANAGEMENT: {
        return "Yönetici, VP, üst yönetim";
      }
      case JobLevel.INDEPENDENT_CONTRACTOR: {
        return "Bağımsız şirket";
      }
      case JobLevel.OTHER: {
        return "Diğer";
      }
    }

    return "";
  }
  private static getJobLevelTitleInGerman(jobLevel: JobLevel): string {
    switch (jobLevel) {
      case JobLevel.ENTRY_LEVEL_EARLY_CAREER: {
        return "Berufseinsteiger";
      }
      case JobLevel.INTERMEDIATE_EXPERIENCED_STAFF_SPECIALIST_ADVISOR: {
        return "Fortgeschrittene, erfahrene Mitarbeiter, Spezialisten/Berater";
      }
      case JobLevel.FIRST_LEVEL_MANAGEMENT: {
        return "Erste Führungsebene";
      }
      case JobLevel.DIRECTOR_MIDDLE_MANAGEMENT: {
        return "Direktor/Mittleres Management";
      }
      case JobLevel.EXECUTIVE_VP_SENIOR_MANAGEMENT: {
        return "Führungskraft, VP, Senior Management";
      }
      case JobLevel.INDEPENDENT_CONTRACTOR: {
        return "Selbstständig/Auftragnehmer";
      }
      case JobLevel.OTHER: {
        return "Andere";
      }
    }

    return "";
  }

  static getJobLevelTitle(jobLevel: JobLevel, locale?: Languages): string {
    switch (locale) {
      case Languages.ENGLISH:
        return this.getJobLevelTitleInEnglish(jobLevel);
      case Languages.SPANISH:
        return this.getJobLevelTitleInSpanish(jobLevel);
      case Languages.FRENCH:
        return this.getJobLevelTitleInFrench(jobLevel);
      case Languages.ITALIAN:
        return this.getJobLevelTitleInItalian(jobLevel);
      case Languages.TURKISH:
        return this.getJobLevelTitleInTurkish(jobLevel);
      case Languages.GERMAN:
        return this.getJobLevelTitleInGerman(jobLevel);
      case Languages.VIETNAMESE:
        return this.getJobLevelTitleInVetnamese(jobLevel);
      default:
        return this.getJobLevelTitleInEnglish(jobLevel);
    }
  }
}
