import { action, computed, observable, runInAction } from "mobx";
import Store from "./Store";
import { PartnerOrganization } from "../models/PartnerOrganizationModel";
import { partnerOrganizationService } from "../services/PartnerOrganizationService";
import { OrganizationStatus } from "../enums/partner-organization-status.enum";

export class PartnerOrganizationsStore extends Store<PartnerOrganization> {
  private static _instance: PartnerOrganizationsStore;

  @observable isLoading = false;
  @observable isLoaded = false;

  constructor() {
    super();
    PartnerOrganization._store = this;
  }

  @computed
  get partnerOrganizations(): PartnerOrganization[] {
    return this.entities;
  }

  static getInstance(): PartnerOrganizationsStore {
    if (!this._instance) {
      this._instance = new PartnerOrganizationsStore();
    }

    return this._instance;
  }

  @action
  fetchOrganizations() {
    this.isLoading = true;
    this.entities = [];
    partnerOrganizationService
      .getAll()
      .then((organizations: PartnerOrganization[]) => {
        runInAction(() => {
          organizations.map((organization) =>
            PartnerOrganization.fromJson(organization),
          );
          this.isLoaded = true;
        });
        return;
      })
      .catch((err) => console.log(err))
      .finally(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
  }

  @action
  changeStatus(id: number, status: OrganizationStatus) {
    partnerOrganizationService.changeStatus(id, status).then(() => {
      runInAction(() => {
        this.entities = this.entities.filter((org) => org.id !== id);
      });
    });
  }
}
