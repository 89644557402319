import { ListResponse } from "../responses/list.response";
import { apiService } from "./ApiService";
import { PeerModel } from "../models/PeerModel";

class PeerService {
  static getInstance(): PeerService {
    return new PeerService();
  }

  async fetchPeers(filters: { [key: string]: any }): Promise<PeerModel[]> {
    return apiService
      .get<ListResponse<PeerModel>>("/users/my-peers", true, true, filters)
      .then((response) => response.data);
  }

  invitePeer(data: {
    first_name: string;
    last_name: string;
    email: string;
  }): Promise<any> {
    return apiService.post("/invite", true, true, data);
  }
}

export const peerService = PeerService.getInstance();
