import { ListResponse } from "../responses/list.response";
import { ShowResponse } from "../responses/show.response";
import { apiService } from "./ApiService";
import {
  PromotionalImagesFormData,
  PromotionalImagesModel,
} from "../models/PromotionalImagesModel";
import { RecentSurveyCompletedActivityModel } from "../models/RecentSurveyCompletedActivityModel";

class RecentSurveyCompletedActivityService {
  static getInstance(): RecentSurveyCompletedActivityService {
    return new RecentSurveyCompletedActivityService();
  }

  getAll(): Promise<RecentSurveyCompletedActivityModel[]> {
    return apiService
      .get<ListResponse<RecentSurveyCompletedActivityModel>>(
        "/recent-activities",
        true,
        true,
      )
      .then((response) => response as any);
  }
}

export const recentSurveyActivityService =
  RecentSurveyCompletedActivityService.getInstance();
