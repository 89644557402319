import { action, computed, observable, runInAction } from "mobx";
import { Constants } from "../constants";
import { Dimensions } from "../enums/dimension-id.enum";
import { DimensionLanguages } from "../enums/languages.enum";
import { Dimension } from "../models/DimensionModel";
import { EnterpriseReport, ScoreRange } from "../models/EmterpriseReportModel";
import { dimensionService } from "../services/DimensionService";
import { CommonUtils } from "../utils/common-utils";
import Store from "./Store";

export class DimensionStore extends Store<Dimension> {
  private static _instance: DimensionStore;

  @observable isLoading = false;

  abilitySubDimensions = [
    Dimensions.GRIT_ID,
    Dimensions.MENTAL_FLEXIBILITY_ID,
    Dimensions.MINDSET_ID,
    Dimensions.RESILIENCE_ID,
    Dimensions.UNLEARN_ID,
  ];

  characterSubDimensions = [
    Dimensions.EMOTIONAL_RANGE_ID,
    Dimensions.EXTRAVERSION_ID,
    Dimensions.HOPE_ID,
    Dimensions.MOTIVATION_STYLE_ID,
    Dimensions.THINKING_STYLE_ID,
  ];

  environmentSubDimensions = [
    Dimensions.COMPANY_SUPPORT_ID,
    Dimensions.EMOTIONAL_HEALTH_ID,
    Dimensions.TEAM_SUPPORT_ID,
    Dimensions.WORK_ENVIRONMENT_ID,
    Dimensions.WORK_STRESS_ID,
  ];
  optimisationIds = [
    Dimensions.CHANGE_READINESS_ID,
    Dimensions.RESKILL_INDEX_ID,
  ];
  advanceMetric = [Dimensions.EXPLORATION_ID, Dimensions.EXPLOITATION_ID];

  allDimensionsArray = [
    Dimensions.OVERVIEW_ID,
    Dimensions.ABILITY_ID,
    ...this.abilitySubDimensions,
    Dimensions.CHARACTER_ID,
    ...this.characterSubDimensions,
    Dimensions.ENVIRONMENT_ID,
    ...this.environmentSubDimensions,
    ...this.advanceMetric,
    ...this.optimisationIds,
  ];
  parentDimensionsIdArray = [
    Dimensions.ABILITY_ID,
    Dimensions.CHARACTER_ID,
    Dimensions.ENVIRONMENT_ID,
  ];

  constructor() {
    super();
    Dimension._store = this;
  }

  @computed get dimensions(): Dimension[] {
    return [...this.entities];
  }

  static dummyEnterpriseReport: EnterpriseReport = {
    positive: 0,
    negative: 0,
    neutral: 0,
    score: 0,
    range: ScoreRange.LOW,
    upperLimit: 0,
    lowerLimit: 0,
  };

  static getInstance(): DimensionStore {
    if (!this._instance) {
      this._instance = new DimensionStore();
    }

    return this._instance;
  }

  fetchDimensions() {
    return dimensionService.getAll().then((dimensions) => {
      runInAction(() =>
        dimensions.map((dimension) => Dimension.fromJson(dimension)),
      );
      return;
    });
  }

  updateDimension(id: number, data: any) {
    return dimensionService.updateDimension(id, data).then((dimension) => {
      runInAction(() => Dimension.fromJson(dimension));
      return;
    });
  }

  getDimensionArticle(id: number) {
    return dimensionService.getDimensionArticle(id);
  }

  syncArticles() {
    return dimensionService.syncArticles();
  }

  @action
  updateDimensionRange() {
    this.isLoading = true;
    return dimensionService
      .updateDimensionRange()
      .then((dimensions: Dimension[]) => {
        runInAction(() => {
          dimensions.map((dimension) => Dimension.fromJson(dimension));
        });
        return;
      })
      .catch((err) => console.log(err))
      .finally(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
  }

  @action
  abilityLabels(locale: DimensionLanguages): string[] {
    return this.abilitySubDimensions.map(
      (subdimension) =>
        this.get(subdimension)!.meta.individual.short_name[locale],
    );
  }

  @action
  enterpriseAbilityLabels(locale: DimensionLanguages): string[] {
    return this.abilitySubDimensions.map(
      (subdimension) =>
        this.get(subdimension)!.meta.enterprise.short_name[locale],
    );
  }

  @action
  abilityTooltip(locale: DimensionLanguages): string[] {
    return this.abilitySubDimensions.map(
      (subdimension) => this.get(subdimension)!.language_titles[locale],
    );
  }

  get abilityBenchmarkingScores() {
    return this.abilitySubDimensions.map(
      (subdimension) =>
        this.get(subdimension)!.meta.individual.benchmark_score *
        Constants.WEIGHT,
    );
  }

  get characterBenchmarkingScores() {
    return this.characterSubDimensions.map(
      (subdimension) =>
        this.get(subdimension)!.meta.individual.benchmark_score *
        Constants.WEIGHT,
    );
  }

  abilityScores = (reports: any) => {
    return this.abilitySubDimensions.map((subdimension) =>
      this.getScore(this.get(subdimension)!.id, reports),
    );
  };

  enterpriseAbilityScores = (reports: any) => {
    return this.abilitySubDimensions.map(
      (subdimension) =>
        this.getEnterpriseReport(this.get(subdimension)!.id, reports).score,
    );
  };

  @action
  characterLabels(locale: DimensionLanguages): string[] {
    return this.characterSubDimensions.map(
      (subdimension) =>
        this.get(subdimension)!.meta.individual.short_name[locale],
    );
  }

  @action
  enterpriseCharacterLabels(locale: DimensionLanguages): string[] {
    return this.characterSubDimensions.map(
      (subdimension) =>
        this.get(subdimension)!.meta.enterprise.short_name[locale],
    );
  }

  characterScores = (reports: any) => {
    return this.characterSubDimensions.map((subdimension) =>
      this.getScore(this.get(subdimension)!.id, reports),
    );
  };

  enterpriseCharacterScores = (reports: any) => {
    return this.characterSubDimensions.map(
      (subdimension) =>
        this.getEnterpriseReport(this.get(subdimension)!.id, reports).score,
    );
  };

  @action
  characterTooltip(locale: DimensionLanguages): string[] {
    return this.characterSubDimensions.map(
      (subdimension) => this.get(subdimension)!.language_titles[locale],
    );
  }

  getScore = (
    id: number | string,
    reports: {
      [key: string]: {
        individual?: { score: number; range: ScoreRange };
        enterprise?: EnterpriseReport;
      };
    },
  ): number => {
    return (
      (reports[id] ? reports[id]?.individual?.score || 0 : 0) * Constants.WEIGHT
    );
  };

  getRange = (
    id: number | string,
    reports: {
      [key: string]: {
        individual?: { score: number; range: ScoreRange };
        enterprise?: EnterpriseReport;
      };
    },
  ): ScoreRange => {
    if (reports[id]?.individual) {
      return reports[id]!.individual!.range;
    }
    return ScoreRange.LOW;
  };

  getLimits = (
    id: number | string,
    reports: {
      [key: string]: {
        individual?: {
          score: number;
          range: ScoreRange;
          lowerLimit: number;
          upperLimit: number;
        };
        enterprise?: EnterpriseReport;
      };
    },
  ): any => {
    return reports[id]
      ? {
          lowerLimit: reports[id]?.individual?.lowerLimit || 0,
          upperLimit: reports[id]?.individual?.upperLimit || 0,
        }
      : {
          lowerLimit: 0,
          upperLimit: 0,
        };
  };

  getAdminReport = (id: number | string, reports: any): EnterpriseReport => {
    const report = reports[id]
      ? CommonUtils.replaceNullKeysWithZero(reports[id])
      : DimensionStore.dummyEnterpriseReport;
    report.score = report.score ? report.score * Constants.WEIGHT : 0;
    return report;
  };

  getEnterpriseReport = (
    id: number | string,
    reports: any,
  ): EnterpriseReport => {
    const report = reports[id]
      ? CommonUtils.replaceNullKeysWithZero(reports[id].enterprise) ||
        DimensionStore.dummyEnterpriseReport
      : DimensionStore.dummyEnterpriseReport;
    report.score = report.score ? report.score * Constants.WEIGHT : 0;
    return report;
  };
  getDimensionTitle = (dimensionId: number) => {
    return this.get(dimensionId)!.language_titles;
  };
}
