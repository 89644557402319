import { Dimension } from "../models/DimensionModel";
import { ListResponse } from "../responses/list.response";
import { apiService } from "./ApiService";
import { ShowResponse } from "../responses/show.response";

class DimensionService {
  static getInstance(): DimensionService {
    return new DimensionService();
  }

  getAll(): Promise<Dimension[]> {
    return apiService
      .get<ListResponse<Dimension>>("/dimensions", true, true)
      .then((response) => response.data);
  }

  updateDimension(id: number, data: any): Promise<Dimension> {
    return apiService
      .put<ShowResponse<Dimension>>(`/dimensions/${id}`, true, false, data)
      .then((res) => res.data);
  }

  getDimensionArticle(id: number): Promise<string> {
    return apiService
      .get<ShowResponse<string>>(`/dimensions/${id}/article`, true, false)
      .then((response) => response.data);
  }

  syncArticles() {
    return apiService.post("/dimensions/articles/sync", true, false);
  }

  async updateDimensionRange(): Promise<Dimension[]> {
    return apiService
      .put<ListResponse<Dimension>>("/dimensions/range", true, false, {})
      .then((response) => response.data);
  }
}

export const dimensionService = DimensionService.getInstance();
