import { message } from "antd";
import * as _ from "lodash";
import { action, computed, observable, runInAction } from "mobx";
import { Age } from "../enums/age.enum";
import { Gender } from "../enums/gender.enum";
import { JobFunction } from "../enums/job-function.enum";
import { JobLevel } from "../enums/job-level.enum";
import { DimensionLanguages } from "../enums/languages.enum";
import { WorkTenure } from "../enums/work-tenure.enum";
import { AdvancedInsightsModel } from "../models/AdvancedInsightsModel";
import { EnterpriseReport, ScoreRange } from "../models/EmterpriseReportModel";
import { Survey } from "../models/Survey";
import { User } from "../models/UserModel";
import { apiService } from "../services/ApiService";
import { reportService } from "../services/ReportService";
import { AdvancedInsightsStore } from "./AdvancedInsightsStore";
import { UserStore } from "./UserStore";

export class ReportStore {
  private static _instance: ReportStore;

  //TODO:: remove observable from reports once loader is added and fixed
  @observable prevReports: {
    [key: string]: {
      individual?: {
        score: number;
        range: ScoreRange;
        lowerLimit: number;
        upperLimit: number;
      };
      enterprise?: EnterpriseReport;
    };
  } = {};
  @observable reports: {
    [key: string]: {
      individual?: {
        score: number;
        range: ScoreRange;
        lowerLimit: number;
        upperLimit: number;
      };
      enterprise?: EnterpriseReport;
    };
  } = {};
  @observable organizationReport: { [key: string]: EnterpriseReport } = {};
  @observable enterpriseReportLoaded = false;
  @observable latestRequestId = 0;
  @observable isDataAvailable = true;
  @observable errorCode = 0;
  @observable userCount = 0;
  @observable reassessmentsCounts: number[] = [];
  @observable isReassessmentsCountsLoading = false;
  @observable isReassessmentsCountsLoaded = false;
  @observable assessmentCount = 0;
  @observable private _filter: { [key: string]: any } = {
    showTrafficLightBar: true,
    showScoreDistributionBar: true,
    includeChampionScore: true,
  };
  @observable prevSurvey: Survey | undefined;
  @observable currentSurvey: Survey | undefined;
  @observable subDimensionIdArray: number[] = [];
  @observable surveyURLGenerationArray: {
    survey_id: number;
    survey_language: DimensionLanguages;
    report_type: "aqme" | "coachOnly";
  }[] = [];
  @observable enterpriseReportDistribution: {
    genders: {
      [key in Gender]: number;
    };
    job_functions: {
      [key in JobFunction]: number;
    };
    countryIds: { [key: number]: number };
    job_levels: {
      [key in JobLevel]: number;
    };
    work_tenures: {
      [key in WorkTenure]: number;
    };
    age_groups: {
      [key in Age]: { [key in Gender]: number };
    };
  } = {
    genders: {},
    job_functions: {},
    countryIds: {},
    job_levels: {},
    work_tenures: {},
    age_groups: {},
  } as any;
  @observable enterpriseUsers: User[] = [];
  @observable reportDownloadProgress = 0;

  @computed
  get filters() {
    return this._filter;
  }

  static getInstance(): ReportStore {
    if (!this._instance) {
      this._instance = new ReportStore();
    }

    return this._instance;
  }

  @action
  resetIsDataAvailable() {
    this.isDataAvailable = true;
  }

  @action
  setReportDownloadProgress(progress: number) {
    this.reportDownloadProgress = progress;
  }

  @action
  setFilters(filters: { [key: string]: any }) {
    if (!_.isEqual(this._filter, filters)) {
      this._filter = filters;
      this.fetchEnterpriseReport();
    }
  }

  @action
  setSelectedSubDimensionsId(id: number) {
    this.subDimensionIdArray.includes(id)
      ? (this.subDimensionIdArray = this.subDimensionIdArray.filter(
          (e) => e !== id,
        ))
      : this.subDimensionIdArray.push(id);
  }

  @action
  setSelectedReport(currentSurvey: Survey, surveyList: Survey[]) {
    if (this.currentSurvey && this.currentSurvey.id === currentSurvey.id) {
      return;
    }
    const tempReport = this.reports;
    this.currentSurvey = currentSurvey;

    this.reports = {};
    const dimension_wise_score = currentSurvey?.dimension_wise_score || {};

    let previousSurvey: Survey | undefined = undefined;

    for (const survey of surveyList) {
      if (
        survey.id !== currentSurvey.id &&
        survey.completed_on <= currentSurvey.completed_on
      ) {
        if (
          previousSurvey === undefined ||
          previousSurvey.completed_on < survey.completed_on
        ) {
          previousSurvey = survey;
        }
      }
    }

    this.prevSurvey = previousSurvey;

    Object.keys(dimension_wise_score).forEach((id) => {
      if (!this.reports.hasOwnProperty(id)) {
        this.reports[id] = {};
      }
      this.reports[id].individual = {
        score:
          dimension_wise_score[id].score <= 98
            ? dimension_wise_score[id].score >= 10
              ? dimension_wise_score[id].score
              : 10
            : 98,
        range: dimension_wise_score[id].range,
        lowerLimit: dimension_wise_score[id].lowerLimit,
        upperLimit: dimension_wise_score[id].upperLimit,
      };
    });

    if (previousSurvey) {
      Object.keys(previousSurvey?.dimension_wise_score || {}).forEach((id) => {
        if (!this.prevReports.hasOwnProperty(id)) {
          this.prevReports[id] = {};
        }
        this.prevReports[id].individual = {
          score:
            previousSurvey?.dimension_wise_score[id].score <= 98
              ? previousSurvey?.dimension_wise_score[id].score >= 10
                ? previousSurvey?.dimension_wise_score[id].score
                : 10
              : 98,
          range: previousSurvey?.dimension_wise_score[id].range,
          lowerLimit: previousSurvey?.dimension_wise_score[id].lowerLimit,
          upperLimit: previousSurvey?.dimension_wise_score[id].upperLimit,
        };
      });
    } else {
      this.prevReports = {};
    }

    Object.keys(tempReport).forEach((id) => {
      if (!this.reports.hasOwnProperty(id)) {
        this.reports[id] = {};
      }
      this.reports[id].enterprise = tempReport[id].enterprise;
    });
  }

  @action
  fetchEnterpriseReport() {
    // to get the latest request id
    const requestId = ++this.latestRequestId;
    this.enterpriseReportLoaded = false;
    this.enterpriseReportDistribution = {
      genders: {},
      job_functions: {},
      countryIds: {},
      job_levels: {},
      work_tenures: {},
      age_groups: {},
    } as any;

    const filterData = {
      showTrafficLightBar: true,
      showScoreDistributionBar: true,
      includeChampionScore: true,
      ...this._filter,
    };
    if (Object.keys(this._filter).length === 0) {
      this.setFilters({
        showTrafficLightBar: true,
        showScoreDistributionBar: true,
        includeChampionScore: true,
      });
    }

    const reportMethod = reportService.getEnterpriseReport(filterData);
    reportMethod
      .then((enterpriseReport) => {
        runInAction(() => {
          //  update the report only if the request id is the latest
          if (requestId === this.latestRequestId) {
            this.organizationReport = enterpriseReport;
            this.assessmentCount = enterpriseReport.count;

            Object.keys(enterpriseReport).forEach((id) => {
              if (!this.reports.hasOwnProperty(id)) {
                this.reports[id] = {};
              }
              this.reports[id].enterprise = enterpriseReport[id];
            });
            this.userCount = enterpriseReport.count;
            this.enterpriseUsers = enterpriseReport.users;
            // Loop through each user and update counts in enterpriseReportDistribution
            enterpriseReport.users.forEach((user: User) => {
              Object.keys(user.dimensionWiseScoreForTeamReport || {}).forEach(
                (id) => {
                  const userScore =
                    user.dimensionWiseScoreForTeamReport[id].score <= 98
                      ? user.dimensionWiseScoreForTeamReport[id].score >= 10
                        ? user.dimensionWiseScoreForTeamReport[id].score
                        : 10
                      : 98;

                  if (
                    user.dimensionWiseScoreForTeamReport[id].range ===
                    ScoreRange.LOW
                  ) {
                    // Create lowRangeUsers array if it doesn't exist
                    if (!this.reports[id]?.enterprise?.lowRangeUsers) {
                      this.reports[id].enterprise = {
                        ...this.reports[id]?.enterprise,
                        lowRangeUsers: [],
                      } as any;
                    }

                    // Push the low range user to the array
                    this.reports[id]!.enterprise!.lowRangeUsers!.push({
                      user_id: +user.id,
                      score: userScore,
                      firstName: user.first_name,
                      lastName: user.last_name,
                      visiblity: user.visibility,
                      profilePic: user.profile_pic,
                      range: ScoreRange.LOW,
                    });
                  } else if (
                    user.dimensionWiseScoreForTeamReport[id].range ===
                    ScoreRange.MEDIUM
                  ) {
                    // Create mediumRangeUsers array if it doesn't exist
                    if (!this.reports[id]?.enterprise?.mediumRangeUsers) {
                      this.reports[id].enterprise = {
                        ...this.reports[id]?.enterprise,
                        mediumRangeUsers: [],
                      } as any;
                    }

                    // Push the medium range user to the array
                    this.reports[id]!.enterprise!.mediumRangeUsers!.push({
                      user_id: +user.id,
                      score: userScore,
                      firstName: user.first_name,
                      lastName: user.last_name,
                      visiblity: user.visibility,
                      profilePic: user.profile_pic,
                      range: ScoreRange.MEDIUM,
                    });
                  } else {
                    // Create highRangeUsers array if it doesn't exist
                    if (!this.reports[id]?.enterprise?.highRangeUsers) {
                      this.reports[id].enterprise = {
                        ...this.reports[id]?.enterprise,
                        highRangeUsers: [],
                      } as any;
                    }

                    // Push the high range user to the array
                    this.reports[id]!.enterprise!.highRangeUsers!.push({
                      user_id: +user.id,
                      score: userScore,
                      firstName: user.first_name,
                      lastName: user.last_name,
                      visiblity: user.visibility,
                      profilePic: user.profile_pic,
                      range: ScoreRange.HIGH,
                    });
                  }
                },
              );

              // Update gender count
              if (user.gender) {
                if (!this.enterpriseReportDistribution.genders[user.gender]) {
                  this.enterpriseReportDistribution.genders[user.gender] = 1;
                } else {
                  this.enterpriseReportDistribution.genders[user.gender]++;
                }
              }

              // Update job function count
              if (user.job_function) {
                if (
                  !this.enterpriseReportDistribution.job_functions[
                    user.job_function
                  ]
                ) {
                  this.enterpriseReportDistribution.job_functions[
                    user.job_function
                  ] = 1;
                } else {
                  this.enterpriseReportDistribution.job_functions[
                    user.job_function
                  ]++;
                }
              }

              // Update countryId count
              if (user.country_id) {
                if (
                  !this.enterpriseReportDistribution.countryIds[user.country_id]
                ) {
                  this.enterpriseReportDistribution.countryIds[
                    user.country_id
                  ] = 1;
                } else {
                  this.enterpriseReportDistribution.countryIds[
                    user.country_id
                  ]++;
                }
              }

              // Update job level count
              if (user.job_level) {
                if (
                  !this.enterpriseReportDistribution.job_levels[user.job_level]
                ) {
                  this.enterpriseReportDistribution.job_levels[
                    user.job_level
                  ] = 1;
                } else {
                  this.enterpriseReportDistribution.job_levels[
                    user.job_level
                  ]++;
                }
              }

              // Update work tenure count
              if (user.work_tenure) {
                if (
                  !this.enterpriseReportDistribution.work_tenures[
                    user.work_tenure
                  ]
                ) {
                  this.enterpriseReportDistribution.work_tenures[
                    user.work_tenure
                  ] = 1;
                } else {
                  this.enterpriseReportDistribution.work_tenures[
                    user.work_tenure
                  ]++;
                }
              }
              if (user.age && user.gender) {
                if (!this.enterpriseReportDistribution.age_groups[user.age]) {
                  this.enterpriseReportDistribution.age_groups[user.age] = {
                    [user.gender]: 1,
                  } as any;
                } else {
                  if (
                    !this.enterpriseReportDistribution.age_groups[user.age][
                      user.gender
                    ]
                  ) {
                    this.enterpriseReportDistribution.age_groups[user.age][
                      user.gender
                    ] = 1;
                  } else {
                    this.enterpriseReportDistribution.age_groups[user.age][
                      user.gender
                    ]++;
                  }
                }
              }
            });
            //TODO:: change this once loader is added
            this.reports = { ...this.reports };
            this.enterpriseReportLoaded = true;
          }
        });
      })
      .catch((err) => {
        runInAction(() => {
          // update the report only if the request id is the latest
          if (requestId === this.latestRequestId) {
            this.errorCode = err && err.code;
            this.userCount = 0;
            this.isDataAvailable = false;
            Object.keys(this.reports).forEach((k) => {
              this.reports[k].enterprise = {
                lowerLimit: 0,
                upperLimit: 0,
                positive: 0,
                negative: 0,
                neutral: 0,
                score: 0,
                range: ScoreRange.LOW,
              };
            });
            this.enterpriseReportDistribution = {
              genders: {},
              job_functions: {},
              countryIds: {},
              job_levels: {},
              work_tenures: {},
              age_groups: {},
            } as any;
            this.enterpriseUsers = [];
            this.enterpriseReportLoaded = true;
          }
        });
      });
  }

  @action
  async fetchReassementsCounts(filters?: {
    team_ids?: number[];
    org_ids?: number[];
  }) {
    this.isReassessmentsCountsLoaded = false;
    try {
      this.isReassessmentsCountsLoading = true;
      const response = await reportService.fetchReassementsCounts(
        filters?.team_ids,
        filters?.org_ids,
      );
      this.reassessmentsCounts = response.reassessmentsCounts?.sort(
        (a, b) => a - b,
      );
      this.isReassessmentsCountsLoading = false;
      this.isReassessmentsCountsLoaded = true;
    } catch (e: any) {
      message.error(e.message || "Something went wrong!");
      this.isReassessmentsCountsLoading = false;
    }
  }

  @action
  async addReportUrl(surveyId: number, file: Blob) {
    const url = await this.uploadImageAndGetUrl(file);
    if (url) {
      return reportService
        .addReportUrl(surveyId, { url: url })
        .then((data: Survey) => {
          runInAction(() => {
            this.currentSurvey = data;
            // TODO:: In the userStore, the related survey should also be updated. It is fine for now as for AQLite we have only one survey.
          });
          return;
        });
    }
  }

  private async uploadImageAndGetUrl(file: Blob) {
    try {
      const response = await reportService.getPresignedUrl();
      const url = response.url;
      await apiService.upload(url, file);
      return url.split("?")[0];
    } catch (error) {
      message.error("Error in uploading the file");
    }
  }

  async updateAQmeFullReportUrlOfSurvey(
    survey_id: number,
    fullReportUrl: string,
    survey_language: DimensionLanguages,
    user_id: number,
  ) {
    if (this.currentSurvey && this.currentSurvey.id === survey_id) {
      this.currentSurvey = {
        ...this.currentSurvey,
        fullReportUrl: {
          ...this.currentSurvey.fullReportUrl,
          [survey_language]: fullReportUrl,
        },
      } as Survey;
    }
    const userStore = UserStore.getInstance();

    if (user_id === +userStore.loggedInProfile!.id) {
      const surveys = userStore.loggedInProfile!.surveys.map((survey) => {
        if (survey.id === survey_id) {
          return {
            ...survey,
            fullReportUrl: {
              ...survey.fullReportUrl,
              [survey_language]: fullReportUrl,
            },
          };
        } else {
          return survey;
        }
      }) as Survey[];
      userStore.loggedInProfile = {
        ...userStore.loggedInProfile,
        surveys: surveys,
      } as User;
    }
  }

  async updateCoachOnlyReportUrl(
    report_url: string,
    survey_language: DimensionLanguages,
    user_id: number,
  ) {
    const advancedInsightsStore = AdvancedInsightsStore.getInstance();

    const user = advancedInsightsStore.get(user_id);

    user!.user!.coachOnlyReportUrl = {
      ...user?.user.coachOnlyReportUrl,
      [survey_language]: report_url,
    } as any;

    AdvancedInsightsModel.fromJson(user);
  }

  async updateSurveyIdsForAqmeReportGenerate(
    survey_id: number,
    survey_language: DimensionLanguages,
    report_type: "aqme" | "coachOnly",
  ) {
    const index = this.surveyURLGenerationArray.findIndex(
      (entry) =>
        entry.survey_id === survey_id &&
        entry.survey_language === survey_language &&
        entry.report_type === report_type,
    );

    if (index === -1) {
      this.surveyURLGenerationArray = [
        ...this.surveyURLGenerationArray,
        { survey_id, survey_language, report_type },
      ];
    } else {
      this.surveyURLGenerationArray = this.surveyURLGenerationArray.filter(
        (s) =>
          !(
            s.survey_id === survey_id &&
            s.survey_language === survey_language &&
            s.report_type === report_type
          ),
      );
    }
  }

  async generateAqmeFullReportUsingSSE(
    survey_id: number,
    survey_language: DimensionLanguages,
    user_id: number,
    sse_token: string,
    report_type: "aqme" | "coachOnly",
  ) {
    this.updateSurveyIdsForAqmeReportGenerate(
      survey_id,
      survey_language,
      report_type,
    );
    const eventSource = new EventSource(
      `${process.env.REACT_APP_BACKEND_URL}/aqme_report_url/sse?token=${sse_token}&user_id=${user_id}&survey_id=${survey_id}&survey_language=${survey_language}&report_type=${report_type}`,
      { withCredentials: true },
    );

    eventSource.onmessage = (event) => {
      const { data } = event;
      const parsedData = JSON.parse(data);
      console.log("Data", parsedData);
      if (parsedData && parsedData.report_url) {
        if (report_type === "aqme") {
          this.updateAQmeFullReportUrlOfSurvey(
            survey_id,
            parsedData.report_url,
            survey_language,
            user_id,
          );
        } else {
          this.updateCoachOnlyReportUrl(
            parsedData.report_url,
            survey_language,
            user_id,
          );
        }
        eventSource.close();
        this.updateSurveyIdsForAqmeReportGenerate(
          survey_id,
          survey_language,
          report_type,
        );
      }
    };
    eventSource.onerror = (error) => {
      eventSource.close();
      this.updateSurveyIdsForAqmeReportGenerate(
        survey_id,
        survey_language,
        report_type,
      );
      console.log("Error", error);
    };
    setTimeout(() => {
      eventSource.close();
    }, 200000);
  }
}
