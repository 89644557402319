import { ListResponse } from "../responses/list.response";
import { apiService } from "./ApiService";
import { Invite } from "../models/InviteModel";
import { InviteType } from "../enums/invite-type.enum";

class InviteService {
  static getInstance(): InviteService {
    return new InviteService();
  }

  fetchInvites(
    filters: { [key: string]: any },
    useMutationToken: boolean,
  ): Promise<Invite[]> {
    return apiService
      .get<ListResponse<Invite>>(
        "/pending-invites",
        true,
        useMutationToken,
        filters,
      )
      .then((res) => res.data);
  }

  resendInvite(id: number, useMutatedToken: boolean): Promise<any> {
    return apiService.post(`/invites/${id}/resend`, true, useMutatedToken);
  }

  cancelInvite(id: number, useMutatedToken: boolean): Promise<any> {
    return apiService.delete(`/invites/${id}`, true, useMutatedToken);
  }

  inviteChampion(
    id: string,
    data: { first_name: string; last_name: string; email: string },
  ): Promise<any> {
    return apiService.post(
      "/organizations/" + id + "/invite",
      true,
      false,
      data,
    );
  }

  invite(
    data: {
      first_name: string;
      last_name: string;
      email: string;
      invite_type: InviteType;
    },
    useMutatedToken: boolean,
  ): Promise<any> {
    return apiService.post("/invite", true, useMutatedToken, data);
  }

  inviteUser(
    teamId: string,
    data: {
      first_name: string;
      last_name: string;
      email: string;
      custom_placeholders?: { organization_name: string };
    },
    useMutatedToken: boolean,
  ): Promise<any> {
    return apiService.post(
      "/teams/" + teamId + "/invite",
      true,
      useMutatedToken,
      data,
    );
  }

  bulkInviteUser(
    data: {
      first_name: string;
      last_name: string;
      email: string;
      organization_id: number;
      team_id: number;
      notificationEnabled: boolean;
    }[],
    includeCC: boolean,
  ): Promise<Invite[]> {
    return apiService
      .post<ListResponse<Invite>>(`/bulk-invite`, true, true, {
        data,
        includeCC,
      })
      .then((res) => res.data);
  }
}

export const inviteService = InviteService.getInstance();
