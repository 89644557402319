import { computed, observable, runInAction } from "mobx";
import { apiService } from "../services/ApiService";

export class AppStore {
  private static _instance: AppStore;

  @observable _countries: any[] = [];

  @computed get countries() {
    return this._countries;
  }

  static getInstance(): AppStore {
    if (!this._instance) {
      this._instance = new AppStore();
    }
    return this._instance;
  }

  fetchCountries() {
    return apiService
      .get<any[]>("/countries", false, false)
      .then((val: any) => {
        runInAction(() => {
          this._countries = val.data;
        });
        return;
      });
  }
}
