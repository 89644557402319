import React from "react";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import BrandButton from "./shared/CustomComponents/BrandButton";
import { SvgIconType } from "./shared/svgIconComponent/svgIcons.list";

interface Props {
  disabled?: boolean;
  leadingSvgIcon: SvgIconType;
  children: string;
  leadingSvgIconClassname?: string;
  className?: string;
  onSucess: (code: string) => void;
  onError: () => void;
}
const LinkedInSSOButton: React.FC<Props> = ({
  disabled,
  leadingSvgIcon,
  children,
  leadingSvgIconClassname,
  className,
  onSucess,
  onError,
}) => {
  const { linkedInLogin } = useLinkedIn({
    clientId: process.env.REACT_APP_LINKEDIN_CLIENT_ID || "",
    redirectUri: process.env.REACT_APP_LINKEDIN_REDIRECT_URI || "",
    scope: "openid,profile,email",
    onSuccess: onSucess,
    onError: onError,
  });
  return (
    <BrandButton
      type="button"
      children={children}
      className={className}
      disabled={disabled}
      leadingSvgIcon={leadingSvgIcon}
      leadingSvgIconClassname={leadingSvgIconClassname}
      onClick={() => linkedInLogin()}
    />
  );
};

export default LinkedInSSOButton;
