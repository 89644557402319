import React from "react";
import { StoreProps } from "../../app/App";
import { inject, observer } from "mobx-react";
import { Languages, LanguagesEnumUtils } from "../../../enums/languages.enum";
import { CommonUtils } from "../../../utils/common-utils";
import SelectMenu from "../CustomComponents/SelectMenu";
//@ts-ignore
import ReactCountryFlag from "react-country-flag";

interface Props extends StoreProps {
  isReportLanguageChange?: boolean;
}

const SwitchLanguageNew: React.FC<Props> = (props: Props) => {
  const swithLanguages = CommonUtils.iterateEnum<Languages>(Languages);

  const items = swithLanguages.map((language) => ({
    label: LanguagesEnumUtils.getLanguageTitle(language),
    value: language,
    leadingLabel: (
      <ReactCountryFlag
        countryCode={
          language === Languages.ENGLISH
            ? "GB"
            : language === Languages.VIETNAMESE
            ? "VN"
            : language
        }
      />
    ),
  }));

  return (
    <SelectMenu
      items={items}
      selected={props.store!.currentLocale}
      onChange={(value) => props.store!.setCurrentLocale(value as Languages)}
      className={"rounded-xl"}
      svgClassName={"w-3 h-3"}
      padding={"px-2 py-1.5 "}
    />
  );
};

export default inject("store")(observer(SwitchLanguageNew));
