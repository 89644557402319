import { action, computed, observable, runInAction } from "mobx";
import Store from "./Store";
import { PeerModel } from "../models/PeerModel";
import { peerService } from "../services/PeerService";

export class PeerStore extends Store<PeerModel> {
  private static _instance: PeerStore;
  @observable isPeersFetched = false;
  @observable isPeersLoading = false;
  @observable hasMore = true;
  @observable offset = 0;
  @observable dataPerPage = 2;

  constructor() {
    super();
    PeerModel._store = this;
  }

  @computed get peers(): PeerModel[] {
    return [...this.entities];
  }

  static getInstance(): PeerStore {
    if (!this._instance) {
      this._instance = new PeerStore();
    }

    return this._instance;
  }

  @action
  fetchPeers() {
    this.isPeersLoading = true;

    peerService
      .fetchPeers({
        offset: this.offset,
        limit: this.dataPerPage,
      })
      .then((peers: PeerModel[]) => {
        this.hasMore = !(!peers.length || peers.length < this.dataPerPage);
        runInAction(() => {
          peers.map((peer) => PeerModel.fromJson(peer));
        });
        this.offset = this.offset + this.dataPerPage;
        this.isPeersLoading = false;
        this.isPeersFetched = true;
      })
      .catch(() => (this.isPeersLoading = false));
  }
}
