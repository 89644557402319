import Model from "./Model";
import { observable } from "mobx";
import { CommunityStore } from "../stores/CommunityStore";

export class CommunityModel extends Model {
  static _store: CommunityStore;

  @observable id!: number;
  @observable title!: string;
  @observable heading!: string;
  @observable description!: string;
  @observable link!: string;
  @observable buttons!: ButtonObject[];
  @observable meta!: any;
  @observable is_active!: boolean;
  @observable slug!: string;

  getId(): string | number {
    return this.id;
  }
}

export interface ButtonObject {
  id?: number;
  title: string;
  link: string;
}

export interface CommunityCardFormData {
  title?: string;
  heading?: string;
  description?: string;
  link?: string;
  buttons?: ButtonObject[];
  meta?: any;
  is_active?: boolean;
}
