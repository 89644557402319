import { ListResponse } from "../responses/list.response";
import { ShowResponse } from "../responses/show.response";
import { apiService } from "./ApiService";
import {
  PromotionalImagesFormData,
  PromotionalImagesModel,
} from "../models/PromotionalImagesModel";
import { EventFormData, EventModel } from "../models/EventModel";

class EventsService {
  static getInstance(): EventsService {
    return new EventsService();
  }

  getAll(): Promise<EventModel[]> {
    return apiService
      .get<ListResponse<EventModel>>("/events", true, true)
      .then((response) => response.data);
  }

  delete(id: number): Promise<any> {
    return apiService.delete("/events/" + id, true, false);
  }

  add(data: EventFormData): Promise<EventModel> {
    return apiService
      .post<ShowResponse<EventModel>>("/events", true, false, data)
      .then((response) => response.data);
  }

  joinEvent(event_id: number): Promise<EventModel> {
    return apiService
      .put<ShowResponse<EventModel>>(`/join-event/${event_id}`, true, false, {})
      .then((response) => response.data);
  }

  update(id: number, data: EventFormData): Promise<EventModel> {
    return apiService
      .put<ShowResponse<EventModel>>(`/events/${id}`, true, false, data)
      .then((res) => res.data);
  }
}

export const eventsService = EventsService.getInstance();
