import Model from "./Model";
import { observable } from "mobx";
import { SettingsStore } from "../stores/SettingsStore";

export class SettingsModel extends Model {
  static _store: SettingsStore;

  @observable id!: number;
  @observable slug!: string;
  @observable key!: string;
  @observable value!: string;

  getId(): string | number {
    return this.id;
  }
}

export interface SettingFormData {
  slug: string;
  key: string;
  value: string;
}
